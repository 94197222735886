import { EWORKORDER_EVENT_STAGE } from '@app/utils/enums'
import React from 'react'
import WorkPackEventList from '../components/WorkPackEventList'
import { ROUTE_WORKPACK_REP } from '@app/utils/constants'

export default function WorkPackReportingPage() {
  return (
    <WorkPackEventList stage={EWORKORDER_EVENT_STAGE.REPORTING} title="label.reporting" route={ROUTE_WORKPACK_REP} />
  )
}
