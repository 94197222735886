import { Document } from '@app/graphql/__types__/graphql'
import { RadButton } from '@holis/react-ui/rad'
import { LuChevronsLeft } from 'react-icons/lu'

type TDocumentListWithViewerHeader = Readonly<{
  selectedDocument: Partial<Document>
  goBack?: () => void
}>
export default function DocumentViewHeader({ selectedDocument, goBack }: TDocumentListWithViewerHeader) {
  return (
    <div className="flex items-center shadow-sm pb-2">

      <RadButton
        variant="outline"
        size="icon"
        className="mr-4"
        onClick={() => goBack?.()}
      >
        <LuChevronsLeft />
      </RadButton>

      <div className="flex gap-2 justify-center">
        <div className="font-bold">
          {selectedDocument?.document}
        </div>

        |

        <div className="">
          {selectedDocument?.description}
        </div>
      </div>
    </div>
  )
}
