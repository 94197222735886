import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { Notification } from '@app/graphql/__types__/graphql'
import { RadButton } from '@holis/react-ui/rad'
import { useTranslation } from 'react-i18next'
import { LuClipboardList, LuPlusSquare } from 'react-icons/lu'
import IanCard from '../../../Ian/IanCard'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import React from 'react'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'

type TNotificationList = Readonly<{
  notifications?: Partial<Notification>[]
  accentuatedIds?: number[]
  createIan?: () => void
  createIanBtnDisabled?: boolean
  isLoading?: boolean
  error?: React.ReactNode
  actions?: React.ReactElement
}>

export default function NotificationList({ notifications, createIanBtnDisabled, createIan, isLoading, error, actions, accentuatedIds }: TNotificationList) {
  const { t } = useTranslation()
  const prms = useUserPermissions()
  const canAddIAN = prms.notifications.create
  return (
    <div className="flex flex-col gap-2 mt-2">
      <FormGroupHeader actions={actions}>
        <div className="flex gap-4 items-center">
          <div className="flex items-center">
            <LuClipboardList className="mr-2" size={20} />
            {' '}
            {t('label.IAN')}
            {' '}
            {/* }({notifications?.length ?? 0}) */}
          </div>
          {(!!createIan && canAddIAN) && (
            <RadButton variant="outline" disabled={createIanBtnDisabled} className="text-foreground" onClick={createIan}>
              <LuPlusSquare className="mr-2" />
              {' '}
              {t('label.addIAN')}
            </RadButton>
          )}
        </div>
      </FormGroupHeader>
      <SpinnerLoaderComponent isLoading={isLoading} error={error}>
        {
          !notifications?.length && (
            <div className=" text-gray-400 p-2">
              {t('label.noNotification')}
            </div>
          )
        }
        <div className="grid grid-cols-2 gap-2">
          {
            notifications?.map(notif => (
              <div key={`ian-${notif.id}`} className={accentuatedIds?.includes(notif.id!) ? '[&_.bg-card.border]:border-primary/50' : ''}>
                <IanCard notification={notif} />
              </div>
            ))
          }
        </div>
      </SpinnerLoaderComponent>
    </div>
  )
}
