import { useMutation } from '@apollo/client'
import { FlocChar, UpdateFlocCharByIdMutation } from '@app/graphql/__types__/graphql'
import { FLOC_CHARS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { RadInput } from '@holis/react-ui/rad'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const cellClassName = 'border'
const inputClassName = 'hover:bg-gray-50 hover:cursor-pointer focus:cursor-text focus:bg-gray-50 border-none outline-none !shadow-none focus-visible:ring-0 rounded-none'

type TFlocCharBloc = Readonly<{
  flocChar: Partial<FlocChar>
}>

export default function FlocCharBlock({ flocChar }: TFlocCharBloc) {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLayoutStore()
  const [flChar, setFlChar] = useState<Partial<FlocChar>>(flocChar)
  const [value, setValue] = useState<string>(flChar.value ?? '')
  const [unit, setUnit] = useState<string>(flChar.value ?? '')
  const [updateFlocCharByIdApi] = useMutation<UpdateFlocCharByIdMutation>(FLOC_CHARS_UPDATE_BY_ID)

  const handleUpdateFlocChar = (field: string, value: string, currentValue: string) => {
    if (value !== currentValue) {
      startLoading()
      updateFlocCharByIdApi({
        variables: {
          id: flocChar.id,
          data: {
            [field]: {
              set: value,
            },
          },
        },
      }).then((result) => {
        AppNotifications.success(t('message.success.flocCharUpdated'))
        setFlChar(result.data?.updateOneFlocChar as Partial<FlocChar>)
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'))
      }).finally(stopLoading)
    }
  }

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    switch (field) {
      case 'value':
        setValue(e.target.value)
        break
      case 'unit':
        setUnit(e.target.value)
        break
      default:
        break
    }
  }

  useEffect(() => {
    setValue(flChar.value ?? '')
    setUnit(flChar.unit ?? '')
  }, [flChar])

  return (
    <div key={flocChar.id} className="col-span-3 grid grid-cols-3">
      <div className={`${cellClassName} flex items-center pl-2 bg-gray-50`}>{flocChar.characteristic?.characteristic}</div>
      <div className={cellClassName}><RadInput className={inputClassName} name={`flocChars[${flocChar.id}][value]`} value={value} onBlur={() => handleUpdateFlocChar('value', value, flChar.value ?? '')} onChange={e => handleFieldChange(e, 'value')} /></div>
      <div className={cellClassName}><RadInput className={inputClassName} name={`flocChars[${flocChar.id}][unit]`} value={unit} onBlur={() => handleUpdateFlocChar('unit', unit, flChar.unit ?? '')} onChange={e => handleFieldChange(e, 'unit')} /></div>
    </div>
  )
}
