import { gql } from '@apollo/client'
import { FLOCS_DETAIL_COMMON_FIELDS } from './flocs'
import { SCHEDULING_REF_PLAN_TYPES_COMMON_FIELDS } from './refSchedulingPlanTypes'
import { SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS } from './refSchedulingPlanStrategies'
import { FLOC_CATALOGS_COMMON_FIELDS } from './refFlocCatalogs'
import { APP_PLAN_STATUSES_COMMON_FIELDS } from './planStatuses'

export const SCHEDULING_PLANS_COMMON_FIELDS = gql`
  ${FLOCS_DETAIL_COMMON_FIELDS}
  ${SCHEDULING_REF_PLAN_TYPES_COMMON_FIELDS}
  ${SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS}
  ${FLOC_CATALOGS_COMMON_FIELDS}
  ${APP_PLAN_STATUSES_COMMON_FIELDS}
  fragment PlanCommonFields on Plan {
    id
    description
    plan
    flocId
    functionalLocation {
      ...FlocDetailFields 
    }
    typeId
    type {
      ...RefPlanTypeCommonFields
    }
    revision
    strategyId
    strategy {
      ...RefPlanStrategyCommonFields
    }
    startDate
    schedulingUnit
    status
    statusPlan {
      ...PlanStatusCommonFields
    }
    notes
    mainWorkCenterId
    mainWorkCenter {
      ...RefFlocCatalogsCommonFields
    }
    plannerGroupId
    plannerGroup {
      ...RefFlocCatalogsCommonFields
    }
  }
`

export const SCHEDULING_PLANS_COUNTER_FIELDS = gql`
  fragment PlanCounterFields on Plan {
    _count {
      planFlocs
    }
  }
`

export const SCHEDULING_PLAN_VIEWS_COMMON_FIELDS = gql`
  fragment PlanViewCommonFields on PlanView {
    id
    description
    plan
    flocId
    floc_floc
    floc_description
    floc_classSece
    floc_grid_grid
    floc_grid_description
    floc_docName
    floc_sector_sector
    floc_sector_description
    floc_sector_site_site
    floc_sector_site_description
    floc_techClass_techClass
    floc_techClass_description
    floc_techClass_class_class
    floc_techClass_class_description
    typeId
    type_type
    type_description
    revision
    strategyId
    strategy_strategy
    strategy_description
    startDate
    schedulingUnit
    status
    status_description
    status_displayColor
    status_workflowDescription
    notes
    mainWorkCenterId
    mainWorkCenter_code
    mainWorkCenter_description
    plannerGroupId
    plannerGroup_code
    plannerGroup_description
  }
`
