import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'devextreme/dist/css/dx.light.css'
import { pdfjs } from 'react-pdf'
import { HolisAuthClientProvider } from '@holis/auth-client-react'
import { HolisAuthClientConfig } from '@holis/auth-client'
import { AuthenticatedApolloProvider } from './graphql/authenticated-apollo-provider'

import '@assets/styles/index.css'
import axios from './services/api/AxiosInstance'
import { fetchClientConfig, OptimusClientConfig } from './utils/clientConfig'
import AppLoading from './components/Layout/AppLoading'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

fetchClientConfig().then(() => {
  console.log(OptimusClientConfig.current)
  const authConfig: HolisAuthClientConfig = {
    serverUrl: OptimusClientConfig.current.authServerUrl,
    callbackUrl: `${import.meta.env.BASE_URL || '/'}oauth-callback`,
    clientId: OptimusClientConfig.current.authClientId,
    scopes: OptimusClientConfig.current.scopes,
  }

  const onAccessTokenUpdated = (token?: string) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  root.render(
    <React.StrictMode>
      <HolisAuthClientProvider config={authConfig} fallback={() => <AppLoading />} onAccessTokenUpdated={onAccessTokenUpdated}>
        <AuthenticatedApolloProvider>
          <App />
        </AuthenticatedApolloProvider>
      </HolisAuthClientProvider>
    </React.StrictMode>,
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})
