import { gql } from '@apollo/client'

export const DWG_IDWG_TECHNIQUE_DELETE_MANY_BY_IDWG_ID_AND_TECHNIQUE_IDS = gql`
  mutation DeleteIdwgTechniquesByIdwgIdAndTechniqueIds($idwgId: Int!, $techniqueIds: [Int!]) {
    deleteManyIdwgTechnique(where: {idwgId: {equals: $idwgId}, techniqueId: {in: $techniqueIds}}) {
      count
    }
  }
`

export const DWG_IDWG_TECHNIQUE_DELETE_BY_IDWG_ID_AND_TECHNIQUE_ID = gql`
  mutation DeleteIdwgTechniqueByIdwgIdAndTechniqueId($idwgId: Int!, $techniqueId: Int!) {
    deleteOneIdwgTechnique(where: {techniqueId_idwgId : {idwgId: $idwgId, techniqueId: $techniqueId}}) {
      idwgId
    }
  }
`

export const DWG_IDWG_TECHNIQUE_CREATE_MANY = gql`
  mutation CreateIdwgTechniques($data: [IdwgTechniqueCreateManyInput!]!) {
    createManyIdwgTechnique(data: $data, skipDuplicates: true) {
      count
    }
  }
`

export const DWG_IDWG_TECHNIQUES_GET_COUNTER = gql`
query GetIdwgTechniquesCounter {
  aggregateIdwgTechnique {
    _count {
      _all
    }
  }
}
`
