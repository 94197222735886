import EventListPage, { TEventListPage } from '@app/components/Common/Block/Event/EventListPage'
import { WorkPackBadge } from '../..'

type TWorkPackEventList = Omit<TEventListPage, 'badge'>

export default function WorkPackEventList({ ...restProps }: TWorkPackEventList) {
  return (
    <EventListPage badge={<WorkPackBadge />} {...restProps} />
  )
}
