import { Notification } from '@app/graphql/__types__/graphql'
import ItemCard, { ItemCardContent } from '../../ItemCard'
import { LuCalendarDays, LuPictureInPicture2 } from 'react-icons/lu'
import { useTranslation } from 'react-i18next'
import { convertDateDefaultStrToDateEuStr } from '@app/utils/functions/dates'
import { RadBadge, RadContextMenu, RadContextMenuContent, RadContextMenuItem, RadContextMenuTrigger } from '@holis/react-ui/rad'
import { ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL } from '@app/utils/constants'
import { generatePath } from 'react-router-dom'
import { generatePathWithBaseUrl, tailwindColorToBgFgStyle } from '@app/utils/functions'

type IanCardProps = Readonly<{
  notification: Partial<Notification>
}>
export default function IanCard({ notification }: IanCardProps) {
  const { t } = useTranslation()

  const openNotif = () => {
    window.open(generatePathWithBaseUrl(generatePath(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL, { number: notification.notif! })), '_blank')
  }

  return (

    <RadContextMenu>

      <RadContextMenuTrigger>

        <ItemCard>
          <ItemCardContent className="text-xs">
            <div className="flex items-center mb-2">
              <div className="flex-1 font-semibold">
                {notification.notif}
                <span className="font-normal">
                  {' '}
                  |
                  {' '}
                  {notification.functionalLocation?.floc}
                </span>
              </div>
              <div className="text-muted-foreground">
                {notification.externalId}
              </div>
            </div>
            <div className="line-clamp-2">
              {notification.description}
            </div>
            <div className="mt-2 flex items-center">
              <div className="flex-1 text-muted-foreground flex items-center">
                <LuCalendarDays className="mr-1" size={18} />
                {' '}
                <span className="text-xs">
                  {t('label.maxEndDate')}
                  :&nbsp;
                  {notification.requiredEndDate ? convertDateDefaultStrToDateEuStr(notification.requiredEndDate) : '-'}
                </span>
              </div>
              <RadBadge style={tailwindColorToBgFgStyle(notification.statusNotif?.displayColor)}>{notification.statusNotif?.description}</RadBadge>
            </div>
          </ItemCardContent>
        </ItemCard>

      </RadContextMenuTrigger>

      <RadContextMenuContent>
        <RadContextMenuItem onClick={openNotif}>
          <LuPictureInPicture2 className="mr-2" />
          {t('label.openNotification')}
        </RadContextMenuItem>
      </RadContextMenuContent>

    </RadContextMenu>
  )
}
