import React from 'react'
import { SchedulingBadge } from '..'
import EventListPage from '@app/components/Common/Block/Event/EventListPage'
import { ROUTE_SCHEDULING_EVENTS } from '@app/utils/constants'

export default function SchedulingEvents() {
  return (
    <EventListPage allowAddNew title="label.events" badge={<SchedulingBadge />} route={ROUTE_SCHEDULING_EVENTS} />
  )
}
