import { Portal } from '@headlessui/react'
import Tooltip from 'devextreme-react/tooltip'
import { useTranslation } from 'react-i18next'
import { LuAlertTriangle } from 'react-icons/lu'

type ReportValidationWarningProps = {
  readonly warningsOrErrors: string[]
  readonly type: 'warning' | 'error'
  readonly label?: string
}

export default function ValidationWarning({ warningsOrErrors, type, label }: ReportValidationWarningProps) {
  const { t } = useTranslation()
  return (
    <>
      <div id={`report-validation-${type}-alert`} className={`flex items-center gap-2 py-2 px-4 rounded-md cursor-default ${type === 'warning' ? 'bg-orange-100 text-orange-900' : 'bg-red-100 text-red-900'}`}>
        <LuAlertTriangle size={20} />
        <p>
          {
            label
            ?? (type === 'warning' ? t('message.warning.someInformationsAreMissing') : t('message.error.someRequirementsAreMissing'))
          }
        </p>
      </div>
      <Portal>
        <Tooltip target={`#report-validation-${type}-alert`} showEvent="mouseenter" hideEvent="mouseleave">
          <ul>
            {[...new Set(warningsOrErrors)].map(woe => (
              <li key={woe}>{woe}</li>
            ))}
          </ul>
        </Tooltip>
      </Portal>
    </>
  )
}
