import { useLazyQuery } from '@apollo/client'
import NotificationList from '@app/components/Common/Block/Notification/NotificationBlock/NotificationList'
import { GetNotificationsByEvtIdQuery, GetNotificationsByFlocIdsQuery, Notification } from '@app/graphql/__types__/graphql'
import { NOTIFICATIONS_GET_BY_EVT_ID, NOTIFICATIONS_GET_BY_FLOC_IDS } from '@app/graphql/requests'
import { useEventStore } from '@app/stores/event'
import useNotificationStore from '@app/stores/notification'
import { OBJ_NEW_ID, APP_AUTO_NAMING } from '@app/utils/constants'
import { ENOTIFICATION_NOTIF_STATUS } from '@app/utils/enums'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { RadTabs, RadTabsList, RadTabsTrigger } from '@holis/react-ui/rad'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type IanListProps = {
  className?: string
  readonly?: boolean
}

export default function IanList({ readonly }: Readonly<IanListProps>) {
  const config = useOptimusConfig()
  const { t } = useTranslation()

  const [getAllFlocNotifications, setGetAllFlocNotifications] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const { activeEvent, setEventNotifications, eventNotifications, setFetchEventNotifications, eventFlocIds, eventFlocNotifications, setEventFlocNotifications } = useEventStore()
  const { setActiveNotification } = useNotificationStore()

  const [getEventNotificationsApi] = useLazyQuery<GetNotificationsByEvtIdQuery>(NOTIFICATIONS_GET_BY_EVT_ID)
  const [getFlocsNotificationsApi] = useLazyQuery<GetNotificationsByFlocIdsQuery>(NOTIFICATIONS_GET_BY_FLOC_IDS)

  const handleNewNotif = () => {
    setActiveNotification({
      id: OBJ_NEW_ID,
      status: ENOTIFICATION_NOTIF_STATUS.INIT,
      notif: APP_AUTO_NAMING,
      creationDate: new Date(),
      __typename: 'Notification',
      origin: activeEvent ? `event:${activeEvent?.id}` : undefined,
    })
  }

  const getEventNotifications = async () => {
    setLoading(true)
    setError(undefined)

    try {
      const queryResult = await getEventNotificationsApi({
        variables: {
          origin: activeEvent ? `event:${activeEvent?.id}` : undefined,
        },
        fetchPolicy: 'no-cache',
      })

      setEventNotifications((queryResult.data?.notifications ?? []) as Partial<Notification>[])
    } catch (e) {
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const getFlocsNotifications = async () => {
    if (!eventFlocIds) return
    if (eventFlocIds.length === 0) {
      setEventFlocNotifications([])
      return
    }
    setLoading(true)
    setError(undefined)

    try {
      const queryResult = await getFlocsNotificationsApi({
        variables: {
          flocIds: eventFlocIds,
        },
        fetchPolicy: 'no-cache',
      })
      setEventFlocNotifications((queryResult.data?.notifications ?? []) as Partial<Notification>[])
    } catch (e) {
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (getAllFlocNotifications) {
      getFlocsNotifications()
    } else {
      getEventNotifications()
    }
  }, [activeEvent, getAllFlocNotifications])

  useEffect(() => {
    setFetchEventNotifications(() => {
      getEventNotifications()
      getFlocsNotifications()
    })
  }, [])

  const listedNotifications = getAllFlocNotifications ? eventFlocNotifications : eventNotifications
  const accentuatedIds = activeEvent ? (listedNotifications?.filter(n => n.origin === `event:${activeEvent?.id}`).map(n => n.id!) ?? []) : []

  return (
    <div>
      <NotificationList
        error={!!error}
        createIanBtnDisabled={readonly || config.getActionIsDisabled('event', 'notificationAdd', activeEvent?.status)}
        createIan={handleNewNotif}
        isLoading={loading}
        notifications={listedNotifications}
        accentuatedIds={accentuatedIds}
        actions={(
          <div>
            <RadTabs value={getAllFlocNotifications ? 'all' : 'event'} onValueChange={v => setGetAllFlocNotifications(v === 'all')}>
              <RadTabsList>
                <RadTabsTrigger value="event" className="uppercase">{t('label.event')}</RadTabsTrigger>
                <RadTabsTrigger value="all" className="uppercase">{t('label.all')}</RadTabsTrigger>
              </RadTabsList>
            </RadTabs>
          </div>
        )}
      />
    </div>

  )
}
