import AppGrid from '@app/components/Common/AppGrid'
import RoleModal from '@app/components/Modal/Admin/Role'
import { Role } from '@app/graphql/__types__/graphql'
import useUsersStore, { RoleWithUsers } from '@app/stores/users'
import { CellRenderer, GridProps } from '@holis/react-ui'
import { RadButton } from '@holis/react-ui/rad'
import { useTranslation } from 'react-i18next'
import { LuPlusCircle } from 'react-icons/lu'
import { MdOpenInNew } from 'react-icons/md'

export default function Roles() {
  const { roles, editingRole, setEditingRole } = useUsersStore()
  const { t } = useTranslation()

  const actionCellRenderer: CellRenderer<RoleWithUsers> = (val, node) => (
    <RadButton
      size="icon"
      variant="outline"
      className="size-fit p-1"
      onClick={() => setEditingRole(node.data as Role)}
    >
      <MdOpenInNew size={18} />
    </RadButton>
  )

  const gridProps: GridProps<RoleWithUsers> = {
    columns: [
      {
        field: undefined,
        title: '',
        cellRenderer: actionCellRenderer,
        filter: false,
        cannotHide: true,
        draggable: false,
        resizable: false,
        disableOptions: true,
        defaultState: {
          width: 60,
        },
      },
      {
        field: 'role',
        title: 'Role',
        type: 'string',
        defaultState: {
          width: 1000,
        },
      },
    ],
    data: roles ?? [],
  }

  return (
    <div className="h-full">
      <AppGrid
        name="admin-rolesGrid"
        isLoading={!roles}
        {...gridProps}
        actions={(
          <RadButton
            size="sm"
            className="flex gap-1 text-sm"
            onClick={() => setEditingRole({ id: -1, role: '', permissions: [] })}
          >
            <LuPlusCircle />
            {' '}
            {t('label.addRole')}
          </RadButton>
        )}
      />

      {editingRole && <RoleModal open onClose={() => setEditingRole(undefined)} />}
    </div>
  )
}
