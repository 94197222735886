import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Cml, Event, EventCml, FunctionalLocation, GetEventCmlByEvtCmlIdQuery, GetLatestEventCmlByCmlIdQuery, GetLatestEventCmlWithQualReadingNonEmptyByCmlIdQuery, GetLatestPictureByQuery, InspectionDrawing, Notification, Picture, PictureWhereInput, RefCmlValCode } from '@app/graphql/__types__/graphql'
import { PICTURES_GET_LATEST_BY, WORKORDER_EVENT_CMLS_GET_BY_EVT_CML_ID, WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID, WORKORDER_EVENT_CMLS_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_CML_ID } from '@app/graphql/requests'
import { useTranslation } from 'react-i18next'
import { LuExternalLink } from 'react-icons/lu'
import { RadContextMenu, RadContextMenuTrigger, RadContextMenuContent, RadContextMenuItem } from '@holis/react-ui/rad'
import useCmlStore from '@app/stores/cml'
import CmlIdwgCard from '../../ItemCard/CmlIdwgCard'
import { QUALREADING_COLOR } from '@app/utils/constants'
import CmlCardDetails from './CmlCardDetails'

type TCmlCard = Readonly<{
  eventCml: Partial<EventCml> | Partial<Cml>
  event?: Partial<Event>
  objectItem?: Partial<Event | Notification | InspectionDrawing | FunctionalLocation>
  className?: string
  hasMenuContext?: boolean
  removeItemTitle?: string
  readonly?: boolean
  handleRemoveCml?: () => void
  onDisplay2dChanged?: (newValue?: boolean | null) => void
  drawingStoreId?: string
  detailsShown?: boolean
  displayLastQualReadingWithColor?: boolean
}>

export default function CmlCard({ eventCml, className, hasMenuContext, event, onDisplay2dChanged, drawingStoreId, objectItem, detailsShown, displayLastQualReadingWithColor }: TCmlCard) {
  const [cml, setCml] = useState<Partial<Cml>>()
  const [evtCml, setEvtCml] = useState<Partial<EventCml> | null | undefined>(eventCml.__typename === 'EventCml' ? eventCml : undefined)
  const { setActiveCml } = useCmlStore()
  const [hasPicture, setHasPicture] = useState<boolean>()
  const [qualReading, setQualReading] = useState<Partial<RefCmlValCode> | null>()

  const { t } = useTranslation()
  const [getEventCmlByEvtCmlIdApi] = useLazyQuery<GetEventCmlByEvtCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_BY_EVT_CML_ID)
  const [getLatestEventCmlByCmlIdApi] = useLazyQuery<GetLatestEventCmlByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID)
  const [getLatestPictureApi] = useLazyQuery<GetLatestPictureByQuery>(PICTURES_GET_LATEST_BY)
  const [getLatestEventCmlWithQualReadingNonEmptyByCmlIdApi] = useLazyQuery<GetLatestEventCmlWithQualReadingNonEmptyByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_CML_ID)

  const getLatestPic = () => {
    const data: PictureWhereInput = {
      cmlId: { equals: cml!.id! },
    }
    const wrkoId = event?.id
    if (wrkoId) {
      data.wrkoId = {
        equals: wrkoId,
      }
    }

    return getLatestPictureApi({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
    }).then((queryResult) => {
      if ((queryResult.data?.findFirstPicture as Picture)?.id) {
        setHasPicture(!!queryResult.data?.findFirstPicture)
      }
    })
  }

  const getEventCml = () => {
    getEventCmlByEvtCmlIdApi({
      variables: {
        evtId: event!.id!,
        cmlId: cml!.id!,
      },
      fetchPolicy: 'no-cache',
    }).then((result) => {
      setEvtCml(result.data?.eventCml as (Partial<EventCml> | undefined) ?? null)
    })
  }

  const getLatestEventCml = () => {
    getLatestEventCmlByCmlIdApi({
      variables: {
        cmlId: cml!.id!,
      },
      fetchPolicy: 'no-cache',
    }).then((result) => {
      setEvtCml(result.data?.findFirstEventCml as (Partial<EventCml> | undefined) ?? null)
    })
  }

  useEffect(() => {
    if (typeof evtCml === 'undefined' && cml?.id && !['InspectionDrawing', 'FunctionalLocation'].includes(objectItem?.__typename ?? '')) {
      if (event && !displayLastQualReadingWithColor) {
        getEventCml()
      } else {
        getLatestEventCml()
      }
    } else if (cml?.id && evtCml?.id && (!evtCml?.qualReadingId || evtCml?.qualReading?.codeCond === '00') && (displayLastQualReadingWithColor || (objectItem?.__typename !== 'Event' && !event && eventCml.__typename !== 'EventCml'))) {
      if (['InspectionDrawing', 'FunctionalLocation'].includes(objectItem?.__typename ?? '')) {
        setEvtCml(null)
      } else {
        getLatestEventCmlWithQualReadingNonEmptyByCmlIdApi({
          variables: {
            cmlId: cml!.id,
          },
          fetchPolicy: 'no-cache',
        }).then((queryResult) => {
          setEvtCml(queryResult.data?.lastEventCmlWithQualReading as EventCml)
        })
      }
    } else if (typeof evtCml !== 'undefined') {
      setQualReading(evtCml?.qualReading ?? null)
    }
  }, [evtCml, cml])

  useEffect(() => {
    setCml(eventCml.__typename === 'EventCml' ? eventCml.cml : (eventCml.__typename === 'Cml' ? eventCml as Partial<Cml> : undefined))
    setEvtCml(eventCml.__typename === 'EventCml' ? eventCml as Partial<EventCml> : undefined)
  }, [eventCml])

  useEffect(() => {
    if (cml?.id) {
      getLatestPic()
    }
  }, [cml])

  return (
    <RadContextMenu>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <CmlIdwgCard
          detailPanelComponentShown={detailsShown}
          className={className}
          drawingStoreId={drawingStoreId}
          id={cml?.id}
          idwgId={cml?.idwgId as number | undefined}
          hasPicture={hasPicture}
          display2d={cml?.display2d}
          class={cml?.codeGroup?.class?.class}
          position={cml?.position}
          title={[`${cml?.diameter ? `${cml?.diameter} ''` : ''} ${cml?.part?.part ?? ''}`.trim(), cml?.codeGroup?.codeGroup ?? ''].filter(Boolean).join(' - ').toUpperCase() || '-'}
          description={cml?.description ?? '-'}
          technique={cml?.technique?.technique}
          floc={cml?.functionalLocation?.floc}
          measurementColor={(qualReading?.integrityCondition?.color ?? QUALREADING_COLOR) as string}
          measurementValue={null}
          measurementReadingValue={qualReading?.valCode}
          detailPanelComponent={cml ? <CmlCardDetails cml={cml!} /> : undefined}
          onClick={() => setActiveCml(cml!)}
          onDisplay2dChanged={onDisplay2dChanged}
        />
      </RadContextMenuTrigger>
      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveCml(cml!)}>
          <LuExternalLink className="mr-2" />
          {' '}
          {t('label.open')}
        </RadContextMenuItem>
      </RadContextMenuContent>
    </RadContextMenu>
  )
}
