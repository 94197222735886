import { useEventStore } from '@app/stores/event'
import { RightPanelPage } from '../..'
import React, { useEffect } from 'react'
import PictureTab from '@app/components/Common/Block/Picture/Block/PictureTab'

export default function PicturePage({ visible, readonly }: RightPanelPage) {
  const { pictures, activeEvent, fetchPictures } = useEventStore()

  useEffect(() => {
    fetchPictures?.()
  }, [fetchPictures])

  return (
    <PictureTab
      isLoading={typeof pictures === 'undefined'}
      pictures={pictures}
      className={visible ? '' : 'hidden'}
      uploadParameters={{ wrkoId: activeEvent?.id, flocId: activeEvent?.schedulingTagId }}
      canManage={!readonly}
      onPicturesChanged={fetchPictures}
    />
  )
}
