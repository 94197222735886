import { gql } from '@apollo/client'

export const SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS = gql`
    fragment RefPlanStrategyCommonFields on RefPlanStrategy {
        id
        strategy
        description
        isActive
        rbiUpdate
    }
`
