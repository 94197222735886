export enum EDOCUMENTATION_CATALOGS_CATEGORY {
  DISCIPLINE = 'DISCIPLINE',
  STAGE = 'STAGE',
  ORIGINATOR = 'ORIGINATOR',
  PHASE = 'PHASE',
  TYPE = 'TYPE',
  CATALOG = 'CATALOG',
}

export enum EFLOC_CATALOGS_CATEGORY {
  PLANT_WORK_CENTER = 'PLANTWRKC',
  PLAN_PLANT = 'PLANPLANT',
  PLAN_GROUP = 'PLANGRP',
  MAIN_WORK_CENTER = 'MAINWRKC',
  CATALOG = 'CATALOG',
  SHUTDOWN = 'SHUTDOWN',
  CONSEQUENCE_PRODUCTION = 'CONSPROD',
  STRATEGY = 'STRATEGY',
  ACCESSIBILITY = 'ACCESSIBILITY',
  LEGISLATION = 'LEGISLATION',
  CLASSIFICATION = 'CLASSIFICATION',
  CORROSION_LOOP = 'CORRLOOP',
  INTEGRITY_SYSTEM = 'INTEGSYSTEM',
}

export enum ENOTIFICATION_CATALOGS_CATEGORY {
  JOB_TYPE = 'JOB_TYPE',
  RESSOURCE = 'RESSOURCES',
  REPAIR_SYSTEM = 'REPAIR_SYSTEM',
  USER_RESPONSIBLE = 'USER_RESPONSIBLE',
}

export enum ENOTIFICATION_ADDITIONAL_DATA_CATEGORY {
  DETECT_METHOD = 'DETECTMETHOD',
  OBJECT = 'OBJECTPART',
  DAMAGE = 'DAMAGE',
  CAUSE = 'CAUSE',
  EFFECT = 'EFFECT',
}

export enum EINTEGRITY_REF_CONDITION_CODE {
  NOMINAL = '01',
  ALARM1 = '02',
  ALARM2 = '03',
  ALARM3 = '04',
}

/*
    Event Stages:
    PEND - Pending
    PREP - Preparation
    EXEC - Execution
    REP - Reporting
    COMP - Completed
    BIN - Deleted
    IMP - Import
*/
export enum EWORKORDER_EVENT_STAGE {
  PENDING = 'PEND',
  PREPARATION = 'PREP',
  EXECUTION = 'EXEC',
  REPORTING = 'REP',
  COMPLETED = 'COMP',
  DELETED = 'BIN',
  IMPORT = 'IMP',
}

/*
    Event Status:
    PREP_PROC - Processing
    PREP_REVI - Approving
    PREP_APPR - Reviewing
*/
export enum EWORKORDER_EVENT_STATUS {
  PEND_WAITING_RELEASE = 'PEND_WREL',
  PREP_PROCESSING = 'PREP_PROC',
  PREP_REVIEWING = 'PREP_REVI',
  PREP_ADDITIONAL_REVIEWING = 'PREP_REV2',
  PREP_APPROVING = 'PREP_APPR',
  EXEC_ASSIGN_WORKPACK_TECHNICIAN = 'EXEC_HOLD',
  EXEC_SEND_EXECUTION = 'EXEC_WREL',
  EXEC_INSPECTION_EXECUTION = 'EXEC_WIPP',
  EXEC_AWAITING_EXPORT_TABLET = 'EXEC_WEXP',
  EXEC_INSPECTION_EXECUTION_TABLET = 'EXEC_WIPT',
  REP_PROCESSING = 'REP_PROC',
  REP_REVIEWING = 'REP_REVI',
  REP_APPROVING = 'REP_APPR',
  COMPLETED = 'COMP',
  CANCELLED = 'CANC',
  WACK = 'WACK',
  EXER = 'EXER',
}

/*
    Plan status:
    INIT - Creation of inspection plan
    WSCHED - Scheduling of inspection plan
    SCHED - Event running
    CANCEL - Cancel inspection plan
*/
export enum ESCHEDULING_PLAN_STATUS {
  INIT = 'INIT',
  WSCHED = 'WSCHED',
  SCHED = 'SCHED',
  CANCEL = 'CANCEL',
}

export enum ENOTIFICATION_NOTIF_STATUS {
  INIT = 'INIT',
  WAPP = 'WAPP',
  WACK = 'WACK',
  EXER = 'EXER',
  APPR = 'APPR',
  CANCEL = 'CANCEL',
  QAQC = 'QAQC',
  CLSD = 'CLSD',
}

export enum EWORKORDER_EVENT_PRIORITY {
  UNKNOWN = '',
  LOW = 'LOW',
  MEDIUM = 'MED',
  HIGH = 'HIG',
}

export enum EWORKORDER_REF_EVENT_TASK_CATEGORY {
  INSPECTION = 'INSPECTION',
  SAFETY = 'SAFETY',
}

export enum EDB_SCHEMA {
  APP = 'APP',
  ASSET = 'ASSET',
  DAMAGE = 'DAMAGE',
  DOCUMENTATION = 'DOCUMENTATION',
  FILE = 'FILE',
  FLOC = 'FLOC',
  CML = 'CML',
  INTEGRITY = 'INTEGRITY',
  MEASPOINT = 'MEASPOINT',
  NOTIFICATION = 'NOTIFICATION',
  SCHEDULING = 'SCHEDULING',
  USER = 'USER',
  WORKORDER = 'WORKORDER',
  DWG = 'DWG',
}

export enum EDB_TABLE {
  DOCUMENTATION_REF_DOCS_CATALOG = 'RefDocsCatalog',
  DOCUMENTATION_DOCUMENT = 'Document',
  DOCUMENTATION_DOCUMENT_FLOC = 'FlocDoc',
  DWG_REF_DWG_TYPE = 'RefDwgType',
  DWG_INSPECTION_DRAWING = 'InspectionDrawing',
  DWG_IDWG_FLOC = 'IdwgFloc',
  DWG_IDWG_GRID = 'IdwgGrid',
  DWG_IDWG_TECHNIQUE = 'IdwgTechnique',
  FLOC_FUNCTIONAL_LOCATION = 'FunctionalLocation',
  FLOC_FLOC_CHAR = 'FlocChar',
  FLOC_REF_FLOC_CATALOGS = 'RefFlocCatalogs',
  FLOC_REF_FLOC_CHARACTERISTIC = 'RefFlocCharacteristic',
  FLOC_REF_FLOC_CLAS_TO = 'RefFlocClasTo',
  FLOC_REF_FLOC_CLASS = 'RefFlocClass',
  FLOC_REF_FLOC_CATALOG_PROFILE = 'RefFlocCatalogProfile',
  FLOC_REF_FLOC_PART = 'RefFlocPart',
  FLOC_REF_FLOC_PROCESS = 'RefFlocProcess',
  FLOC_REF_FLOC_SECE = 'RefFlocSece',
  FILE_DOC = 'Doc',
  FILE_DRAWING = 'Drawing',
  FILE_PICTURE = 'Picture',
  FILE_ATTACHMENT = 'Attachment',
  ASSET_GRID = 'Grid',
  ASSET_SITE = 'Site',
  ASSET_SECTOR = 'Sector',
  DAMAGE_DAMAGE = 'Damage',
  DAMAGE_REF_DAMAGE_CLASS = 'RefDamageClass',
  DAMAGE_REF_DAMAGE_CODE_GROUP = 'RefDamageCodeGroup',
  DAMAGE_REF_DAMAGE_VAL_CODE = 'RefDamageValCode',
  DAMAGE_REF_CODE_GROUP_VAL_CODE = 'RefDamageCodeGroupValCode',
  DAMAGE_REF_CODE_GROUP_FLOC = 'RefDamageCodeGroupFloc',
  INTEGRITY_STATUS = 'IntegrityStatus',
  INTEGRITY_REF_INTEGRITY_CONDITION = 'RefIntegrityCondition',
  INTEGRITY_REF_INTEGRITY_CONSEQUENCE = 'RefIntegrityConsequence',
  INTEGRITY_REF_INTEGRITY_MATRIX = 'RefIntegrityMatrix',
  CML_CML = 'Cml',
  CML_REF_CML_CLASS = 'RefCmlClass',
  CML_REF_CML_CODE_GROUP = 'RefCmlCodeGroup',
  CML_REF_CML_VAL_CODE = 'RefCmlValCode',
  CML_REF_CODE_GROUP_VAL_CODE = 'RefCmlCodeGroupValCode',
  CML_REF_CLASS_CODE_GROUP = 'RefCmlClassCodeGroup',
  MEASPOINT_INSPECTION_POINT = 'InspectionPoint',
  MEASPOINT_REF_MEASPOINT_CHAR = 'RefMeasPointChar',
  MEASPOINT_REF_MEASPOINT_CLASS = 'RefMeasPointClass',
  MEASPOINT_REF_MEASPOINT_CODE_GROUP = 'RefMeasPointCodeGroup',
  MEASPOINT_REF_MEASPOINT_VAL_CODE = 'RefMeasPointValCode',
  MEASPOINT_REF_MEASPOINT_CODE_GROUP_VAL_CODE = 'RefMeasPointCodeGroupValCode',
  MEASPOINT_REF_MEASPOINT_CLASS_CODE_GROUP = 'RefMeasPointClassCodeGroup',
  NOTIFICATION_NOTIFICATION = 'Notification',
  NOTIFICATION_DAMAGE = 'NotificationDamage',
  NOTIFICATION_WORKFLOW = 'NotificationWorkflow',
  NOTIFICATION_REF_NOTIF_ADDITIONAL_DATA = 'RefNotifAdditionalData',
  NOTIFICATION_REF_NOTIF_CATALOG = 'RefNotifCatalog',
  NOTIFICATION_REF_NOTIF_CONSEQUENCE = 'RefNotifConsequence',
  NOTIFICATION_REF_NOTIF_LIKELIHOOD = 'RefNotifLikelihood',
  NOTIFICATION_REF_NOTIF_PRIORITY = 'RefNotifPriority',
  NOTIFICATION_REF_NOTIF_SEVERITY = 'RefNotifSeverity',
  NOTIFICATION_REF_NOTIF_TYPE = 'RefNotifType',
  NOTIFICATION_REF_NOTIF_MATRIX = 'RefNotifMatrix',
  SCHEDULING_ITEM = 'Item',
  SCHEDULING_ITEM_TASK = 'ItemTask',
  SCHEDULING_PLAN = 'Plan',
  SCHEDULING_PLAN_FLOC = 'PlanFloc',
  SCHEDULING_PLAN_STRATEGY = 'RefPlanStrategy',
  SCHEDULING_PLAN_TYPE = 'RefPlanType',
  SCHEDULING_PLAN_WORKFLOW = 'PlanWorkflow',
  WORKORDER_EVENT = 'Event',
  WORKORDER_EVENT_CMLS = 'EventCmls',
  WORKORDER_EVENT_DAMAGE = 'EventDamage',
  WORKORDER_EVENT_TASK = 'EventTask',
  WORKORDER_EVENT_INTEGRITY_STATUS = 'EventIntegrityStatus',
  WORKORDER_EVENT_INSPECTION_POINT = 'EventInspectionPoint',
  WORKORDER_EVENT_FLOC = 'EventFloc',
  WORKORDER_EVENT_INSPECTION_DRAWING = 'EventInspectionDrawing',
  WORKORDER_REF_EVENT_TECHNIQUE = 'RefEventTechnique',
  WORKORDER_REF_EVENT_TASK = 'RefEventTask',
  WORKORDER_REF_EVENT_TYPE = 'RefEventType',
}

export enum EDB_TABLE_REF {
  /* File Name Suffixes */
  // Schema FILE
  TABLE_FILE_DOC_FILE_NAME = 'FILE_DOC',
  TABLE_FILE_DRAWING_FILE_NAME = 'FILE_DRAWING',
  TABLE_FILE_DOC_IMG_FILE_NAME = 'FILE_IMG',
  TABLE_FILE_PICTURE_FILE_NAME = 'FILE_PIC',
  TABLE_FILE_ATTACHMENT_FILE_NAME = 'FILE_ATM',
  // Schema ASSET
  TABLE_ASSET_SITE_FILE_NAME = 'SITE',
  TABLE_ASSET_SECTOR_FILE_NAME = 'SECTOR',
  TABLE_ASSET_GRID_FILE_NAME = 'GRID',
  // Schema SCHEDULING
  TABLE_SCHEDULING_REF_PLAN_STRATEGY_FILE_NAME = 'RSCH_PLST',
  TABLE_SCHEDULING_REF_PLAN_TYPE_FILE_NAME = 'RSCH_PLTY',
  TABLE_SCHEDULING_PLAN_FILE_NAME = 'PLAN',
  TABLE_SCHEDULING_ITEM_FILE_NAME = 'SCH_ITEM',
  TABLE_SCHEDULING_ITEM_TASK_FILE_NAME = 'SCH_ITEM_TASK',
  TABLE_SCHEDULING_PLAN_FLOC_FILE_NAME = 'PLAN_FLOC',
  TABLE_SCHEDULING_PLAN_WORKFLOW_FILE_NAME = 'PLAN_WORKFLOW',
  // Schema DOCUMENTATION
  TABLE_DOCUMENTATION_REF_DOCS_CATALOG_FILE_NAME = 'RDOC_CTLG',
  TABLE_DOCUMENTATION_DOCUMENT_FILE_NAME = 'DOCS',
  // Schema DWG
  TABLE_DWG_REF_DWG_TYPE_FILE_NAME = 'RDWG_TYPE',
  TABLE_DWG_INSPECTION_DRAWING_FILE_NAME = 'IDWG',
  TABLE_DWG_IDWG_FLOC_FILE_NAME = 'IFLOC',
  TABLE_DWG_IDWG_GRID_FILE_NAME = 'IGRID',
  TABLE_DWG_IDWG_TECHNIQUE_FILE_NAME = 'ITECHNIQUE',
  // Schema FLOC
  TABLE_FLOC_REF_FLOC_CATALOGS_FILE_NAME = 'RFLO_CTLG',
  TABLE_FLOC_REF_FLOC_CHARACTERISTIC_FILE_NAME = 'RFLO_CHAR',
  TABLE_FLOC_REF_FLOC_CLASS_FILE_NAME = 'RFLO_CLAS',
  TABLE_FLOC_REF_FLOC_CLASS_TO_FILE_NAME = 'RFLO_CLASTO',
  TABLE_FLOC_REF_FLOC_CATALOG_PROFILE_FILE_NAME = 'RFLO_CTL_PRO',
  TABLE_FLOC_REF_FLOC_PART_FILE_NAME = 'RFLO_PART',
  TABLE_FLOC_REF_FLOC_PROCESS_FILE_NAME = 'RFLO_PROC',
  TABLE_FLOC_REF_FLOC_SECE_FILE_NAME = 'RFLO_SECE',
  TABLE_FLOC_FUNCTIONAL_LOCATION_FILE_NAME = 'FLOC',
  TABLE_FLOC_DOC_FILE_NAME = 'FDOC',
  TABLE_FLOC_CHAR_FILE_NAME = 'FCHR',
  // Schema INTEGRITY
  TABLE_INTEGRITY_STATUS_FILE_NAME = 'INTS',
  TABLE_INTEGRITY_REF_CONDITION_FILE_NAME = 'RINT_COND',
  TABLE_INTEGRITY_REF_CONSEQUENCE_FILE_NAME = 'RINT_CONS',
  TABLE_INTEGRITY_REF_MATRIX_FILE_NAME = 'RINT_MATRIX',
  // Schema MEASPOINT
  TABLE_MEASPOINT_REF_CHAR_FILE_NAME = 'RMPT_CHAR',
  TABLE_MEASPOINT_REF_CLASS_FILE_NAME = 'RMPT_CLAS',
  TABLE_MEASPOINT_REF_CODE_GROUP_FILE_NAME = 'RMPT_CODG',
  TABLE_MEASPOINT_REF_VAL_CODE_FILE_NAME = 'RMPT_VALC',
  TABLE_MEASPOINT_REF_CODE_GROUP_VAL_CODE_FILE_NAME = 'RMPT_CGVC',
  TABLE_MEASPOINT_REF_CLASS_CODE_GROUP_FILE_NAME = 'RMPT_CLCG',
  TABLE_MEASPOINT_INSPECTION_FILE_NAME = 'INSP',
  // Schema DAMAGE
  TABLE_DAMAGE_REF_CLASS_FILE_NAME = 'RDMG_CLAS',
  TABLE_DAMAGE_REF_CODE_GROUP_FILE_NAME = 'RDMG_COGR',
  TABLE_DAMAGE_REF_VAL_CODE_FILE_NAME = 'RDMG_VALC',
  TABLE_DAMAGE_REF_CODE_GROUP_VAL_CODE_FILE_NAME = 'RDMG_CGVC',
  TABLE_DAMAGE_REF_CODE_GROUP_FLOC_CLASS_FILE_NAME = 'RDMG_CGFL',
  TABLE_DAMAGE_DAMAGE_FILE_NAME = 'DMG',
  // Schema CML
  TABLE_CML_REF_VAL_CODE_FILE_NAME = 'RCML_VALC',
  TABLE_CML_REF_CLASS_FILE_NAME = 'RCML_CLAS',
  TABLE_CML_REF_CODE_GROUP_FILE_NAME = 'RCML_CODG',
  TABLE_CML_REF_CODE_GROUP_VAL_CODE_FILE_NAME = 'RCML_CGVC',
  TABLE_CML_REF_CLASS_CODE_GROUP_FILE_NAME = 'RCML_CLCG',
  TABLE_CML_CML_FILE_NAME = 'CMLS',
  // Schema WORKORDER
  TABLE_WORKORDER_REF_TECHNIQUE_FILE_NAME = 'RWRK_RTYP',
  TABLE_WORKORDER_REF_TASK_FILE_NAME = 'RWRK_TASK',
  TABLE_WORKORDER_REF_TYPE_FILE_NAME = 'RWRK_TYPE',
  TABLE_WORKORDER_EVENT_CMLS_FILE_NAME = 'EVENT_CMLS',
  TABLE_WORKORDER_EVENT_CML_FILE_NAME = 'EVENT_CML',
  TABLE_WORKORDER_EVENT_DAMAGE_FILE_NAME = 'EVENT_DAMAGE',
  TABLE_WORKORDER_EVENT_FLOC_FILE_NAME = 'EVENT_FLOC',
  TABLE_WORKORDER_EVENT_IDWG_FILE_NAME = 'EVENT_IDWG',
  TABLE_WORKORDER_EVENT_INSP_FILE_NAME = 'EVENT_INSP',
  TABLE_WORKORDER_EVENT_INTS_FILE_NAME = 'EVENT_INTS',
  TABLE_WORKORDER_EVENT_TASK_FILE_NAME = 'EVENT_TASK',
  TABLE_WORKORDER_EVENT_FILE_NAME = 'EVENT',
  // Schema NOTIFCATION
  TABLE_NOTIFICATION_REF_NOTIF_TYPE_FILE_NAME = 'RNOTIF_TYPE',
  TABLE_NOTIFICATION_REF_NOTIF_ADDITIONAL_DATA_FILE_NAME = 'RNOTIF_ADATA',
  TABLE_NOTIFICATION_REF_NOTIF_CATALOG_FILE_NAME = 'RNOTIF_CTLG',
  TABLE_NOTIFICATION_REF_NOTIF_PRIORITY_FILE_NAME = 'RNOTIF_PRRT',
  TABLE_NOTIFICATION_REF_NOTIF_SEVERITY_FILE_NAME = 'RNOTIF_SVRT',
  TABLE_NOTIFICATION_REF_NOTIF_MATRIX_FILE_NAME = 'NOTIF_MTRX',
  TABLE_NOTIFICATION_REF_NOTIF_LIKELIHOOD_FILE_NAME = 'RNOTIF_LLIH',
  TABLE_NOTIFICATION_REF_NOTIF_CONSEQUENCE_FILE_NAME = 'RNOTIF_CONS',
  TABLE_NOTIFICATION_DAMAGE_FILE_NAME = 'NOTIF_DMGE',
  TABLE_NOTIFICATION_NOTIF_FILE_NAME = 'NOTIF',
  TABLE_NOTIFICATION_WORKFLOW_FILE_NAME = 'NOTIF_WRKFL',
}

export enum EAppFlagStatus {
  ACTIVE = 'A',
  // CREATING = 'B',
  CREATING = 'Y',
  CLOSED = 'C',
  DELETED = 'D',
  FIXED = 'F',
}

export enum EInspectionStatus {
  HOLD = 'H',
  PROCESSING = 'P',
  COMPLETED = 'C',
}
