import { gql } from '@apollo/client'

export const INTEGRITY_REF_CONDITIONS_COMMON_FIELDS = gql`
    fragment RefIntegrityConditionCommonFields on RefIntegrityCondition {
        condition
        description
        color
        matrixValue
    }
`
