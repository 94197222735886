import { useEventStore } from '@app/stores/event'
import DocumentSelectionModal from './DocumentSelectionModal'
import { useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateEventDocumentsMutation, EventDocument, Document, GetDocumentsByFlocIdsQuery, DeleteEventDocumentsMutation } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_DOCUMENTS_GET_BY_FLOC_IDS, WORKORDER_EVENT_DOCUMENTS_CREATE_MANY, WORKORDER_EVENT_DOCUMENTS_DELETE_MANY } from '@app/graphql/requests'
import { TDbId } from '@app/types/app'
import { useLayoutStore } from '@app/stores/layout'
import AppNotifications from '@app/services/notification'
import { t } from 'i18next'

export default function EventDocumentSelectionModal() {
  const { activeEvent, eventFlocDocuments, fetchEventFlocDocuments, setFetchEventFlocDocuments, eventDocuments, fetchEventDocuments, setEventFlocDocuments, changeDocumentSelectionModalDisplay, eventFlocIds } = useEventStore()
  const [addEventDocumentsApi] = useMutation<CreateEventDocumentsMutation>(WORKORDER_EVENT_DOCUMENTS_CREATE_MANY)
  const [deleteEventDocumentsApi] = useMutation<DeleteEventDocumentsMutation>(WORKORDER_EVENT_DOCUMENTS_DELETE_MANY)
  const { startLoading, stopLoading } = useLayoutStore()
  const [getEventFlocDocumentsApi] = useLazyQuery<GetDocumentsByFlocIdsQuery>(DOCUMENTATION_DOCUMENTS_GET_BY_FLOC_IDS)

  const addItems = (ids: TDbId[]) => addEventDocumentsApi({
    variables: {
      data: ids.map((docId: TDbId) => ({
        wrkoId: activeEvent!.id!,
        docId,
      })),
    },
  })

  const deleteItems = (eventDocIds: TDbId[]) => deleteEventDocumentsApi({
    variables: {
      ids: eventDocIds,
    },
  })

  const handleValidateSelection = async (selectedItems: Partial<Document>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const eventDocIds = (eventDocuments?.filter((item: Partial<EventDocument>) => item.docId && oldSelectedIds.includes(item.docId)).map((item: Partial<EventDocument>) => item.id) ?? []) as TDbId[]
      if (eventDocIds.length) {
        promises.push(deleteItems(eventDocIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        fetchEventDocuments?.()
        changeDocumentSelectionModalDisplay(false)
        AppNotifications.success(t('message.success.eventCmlsUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    }
  }

  const getEventFlocDocuments = () => {
    if (Array.isArray(eventFlocIds) && eventFlocIds.length) {
      getEventFlocDocumentsApi({
        variables: {
          flocIds: eventFlocIds,
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        setEventFlocDocuments((queryResult.data?.documents ?? []) as Partial<Document>[])
      })
    } else {
      setEventFlocDocuments([])
    }
  }

  useEffect(() => {
    setFetchEventFlocDocuments(getEventFlocDocuments)
  }, [eventFlocIds])

  useEffect(() => {
    fetchEventFlocDocuments?.()
  }, [fetchEventFlocDocuments])

  useEffect(() => {
    fetchEventDocuments?.()
  }, [fetchEventDocuments])

  return (
    <DocumentSelectionModal hasItems open filterSites={typeof activeEvent?.schedulingTag?.sector.site.site === 'string' ? [activeEvent?.schedulingTag?.sector.site.site] : undefined} isLoading={typeof eventFlocDocuments === 'undefined'} description={t('label.manageEventDocumentDescription')} selectedIds={eventDocuments?.map(evtDoc => evtDoc.docId!) ?? []} items={eventFlocDocuments} onClose={() => changeDocumentSelectionModalDisplay(false)} onValidate={handleValidateSelection} />
  )
}
