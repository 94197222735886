import SelectionConfirmModal, { TSelectionConfirmModalWithoutListProps } from '@app/components/Modal/Confirm/Selection'
import { InspectionPoint } from '@app/graphql/__types__/graphql'
import { TDataRowTreeList } from '@holis/react-ui'
import { useTranslation } from 'react-i18next'

export default function InspectionPointSelectionModal({ items, ...restProps }: TSelectionConfirmModalWithoutListProps<Partial<InspectionPoint>>) {
  const { t } = useTranslation()

  return (
    <SelectionConfirmModal<Partial<InspectionPoint>>
      title={t('label.inspectionPoint')}
      description={t('label.selectInspectionPointDescription')}
      listProps={{
        listProps: {
          data: (items ?? []) as unknown as TDataRowTreeList[],
          columns: [
            {
              field: 'functionalLocation.floc',
              title: t('label.functionalLocation'),
              type: 'string',
              width: 250,
            },
            {
              field: 'position',
              title: t('label.position'),
              type: 'string',
              width: 250,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 250,
            },
            {
              field: 'class.class',
              title: t('label.class'),
              type: 'string',
              width: 60,
            },
          ],
        },
      }}
      {...restProps}
    />
  )
}
