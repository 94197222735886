import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { UpdateEventByIdMutation } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENTS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useEventStore } from '@app/stores/event'
import { useLayoutStore } from '@app/stores/layout'
import { EWORKORDER_EVENT_STATUS } from '@app/utils/enums'
import { useHolisAuth } from '@holis/auth-client-react'
import { useTranslation } from 'react-i18next'

type TDeleteEventModal = IConfirmModal

export default function DeleteEventModal({ ...restProps }: TDeleteEventModal) {
  const { changeDeleteEventModalDisplay, deleteEvent, activeEvent } = useEventStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID)
  const { createEventWorkflowFunc } = useEventStore()
  const { t } = useTranslation()
  const { user } = useHolisAuth()

  const onConfirm = () => {
    const currEventStatus = activeEvent?.status
    startLoading()
    updateEventByIdApi({
      variables: {
        id: activeEvent!.id,
        data: {
          status: {
            set: EWORKORDER_EVENT_STATUS.CANCELLED,
          },
        },
      },
    }).then(() => {
      deleteEvent()
      AppNotifications.success(t('message.success.deleteEvent'))
      createEventWorkflowFunc?.({
        variables: {
          data: {
            userLogin: user?.username,
            actionDate: new Date(),
            status: `${currEventStatus}|${EWORKORDER_EVENT_STATUS.CANCELLED}`,
            wrkoId: activeEvent!.id,
            description: t('label.eventActions.descriptions.delete_event'),
          },
        },
      })
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteEvent'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteEvent')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeleteEventModalDisplay(false)}
      {...restProps}
    />
  )
}
