import { useQuery } from '@apollo/client'
import SelectionConfirmModal from '@app/components/Modal/Confirm/Selection'
import { GetRegisteredUsersQuery, UserInfo, UserRole } from '@app/graphql/__types__/graphql'
import { USER_GET_REGISTERED } from '@app/graphql/requests/usersRoles'
import { useTranslation } from 'react-i18next'

type TUserAssignModal = Readonly<{
  readonly opened?: boolean
  readonly onClose?: () => void
  readonly onValidate?: (user: UserInfo) => void
}>

export default function UserAssignModal({ opened, onClose, onValidate }: TUserAssignModal) {
  const { t } = useTranslation()
  const { data, loading } = useQuery<GetRegisteredUsersQuery>(USER_GET_REGISTERED)

  return (
    <SelectionConfirmModal
      isRequired
      open={opened}
      title={t('label.assignUser')}
      description={t('label.assignUserDescription')}
      isMultiple={false}
      listProps={{
        listProps: {
          data: (data?.userRoles as UserRole[])?.map(({ userInfo }) => ({ id: userInfo?.userId, ...userInfo })).filter(Boolean) || [],
          isLoading: loading,
          columns: [
            {
              field: 'username',
              title: t('label.username'),
              type: 'string',
            },
            {
              field: 'firstName',
              title: t('label.firstName'),
              type: 'string',
            },
            {
              field: 'lastName',
              title: t('label.lastName'),
              type: 'string',
            },
            {
              field: 'email',
              title: t('label.email'),
              type: 'string',
            },
          ],
        },
      }}
      onClose={onClose}
      onValidate={selectedUsers => onValidate?.(selectedUsers[0] as UserInfo)}
    />
  )
}
