import { gql } from '@apollo/client'

export const NOTIFICATION_REF_CONSEQUENCE_COMMON_FIELDS = gql`
    fragment RefNotifConsequenceCommonFields on RefNotifConsequence {
        consequence
        description
        displayColor
        matrixValue
    }
`
