import { useTranslation } from 'react-i18next'
import AppNotifications from '@app/services/notification'
import React from 'react'
import { useMutation } from '@apollo/client'
import { CreateInspectionPointMutation, InspectionPoint, UpdateInspectionPointByIdMutation } from '@app/graphql/__types__/graphql'
import { OBJ_NEW_ID } from '@app/utils/constants'
import ActionButtons from '@app/components/Common/Form/ActionButtons'
import { EAdditionalAction, EApiOperator } from '@app/utils/enums'
import { TAdditionalActions } from '@app/types/app'
import useInspectionPointStore from '@app/stores/insp'
import { INSPECTION_POINTS_CREATE, INSPECTION_POINTS_UPDATE_BY_ID } from '@app/graphql/requests'
import { LuTrash2 } from 'react-icons/lu'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'

type TActions = Readonly<{
  inspectionPoint: Partial<InspectionPoint>
  canDelete?: boolean
  onCreatedOrUpdated?: (inspectionPoint: Partial<InspectionPoint>, operator?: EApiOperator) => void
}>

export default function Actions({ inspectionPoint, onCreatedOrUpdated, canDelete }: TActions) {
  const { t } = useTranslation()
  const { isSaved, hasError, setUpdateData, changeDeleteInspectionPointModalDisplay, updateInspectionPoint, editInspectionPoint, updateData, cancelEditData, setActiveInspectionPoint } = useInspectionPointStore()
  const [updateInspectionPointById] = useMutation<UpdateInspectionPointByIdMutation>(INSPECTION_POINTS_UPDATE_BY_ID)
  const [createInspectionPoint] = useMutation<CreateInspectionPointMutation>(INSPECTION_POINTS_CREATE)

  const handleCancelEditDataClick = () => {
    cancelEditData()
  }

  const submitChange = () => {
    const inspectionPointId = inspectionPoint.id
    if (inspectionPointId === OBJ_NEW_ID) {
      createInspectionPoint({
        variables: {
          data: updateData,
        },
      }).then((newData) => {
        setActiveInspectionPoint()
        onCreatedOrUpdated?.(newData.data?.createOneInspectionPoint as Partial<InspectionPoint>, EApiOperator.CREATE)
        AppNotifications.success(t('message.success.inspectionPointCreated'))
      }).catch(() => {
        const errorMessage: string = t('message.error.default.title')

        AppNotifications.error(errorMessage)
      })
    } else {
      updateInspectionPointById({ variables: { id: inspectionPoint.id, data: updateData } }).then((newData) => {
        const newInspectionPoint = {
          ...editInspectionPoint,
          ...newData?.data?.updateOneInspectionPoint,
        } as InspectionPoint
        updateInspectionPoint(newInspectionPoint)
        setUpdateData({})
        onCreatedOrUpdated?.(newInspectionPoint, EApiOperator.UPDATE)
        AppNotifications.success(t('message.success.inspectionPointUpdated'))
      }).catch(() => {
        const errorMessage: string = t('message.error.default.title')

        AppNotifications.error(errorMessage)
      })
    }
  }

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DELETE]: (
      <RadDropdownMenuItem disabled={canDelete === false} className="item-destructive gap-1 cursor-pointer" onClick={() => changeDeleteInspectionPointModalDisplay(true)}>
        <LuTrash2 />
        {' '}
        {t('label.deleteInspectionPoint')}
      </RadDropdownMenuItem>
    ),
  }

  return (
    <ActionButtons
      isSaved={isSaved}
      dropdownClassName="action-dropdown"
      hasError={hasError}
      handleCancelEditDataClick={handleCancelEditDataClick}
      additionalActions={inspectionPoint.id !== OBJ_NEW_ID ? additionalActions : undefined}
      submitChange={submitChange}
    />
  )
}
