import Astro from '@assets/images/Astro_Checkis.svg?url'

import {
  RadAvatar,
  RadAvatarImage,
  RadDropdownMenu,
  RadDropdownMenuContent,
  RadDropdownMenuItem,
  RadDropdownMenuLabel,
  RadDropdownMenuRadioGroup,
  RadDropdownMenuRadioItem,
  RadDropdownMenuSeparator,
  RadDropdownMenuTrigger,
} from '@holis/react-ui/rad'

import { useHolisAuth } from '@holis/auth-client-react'
import { LuExternalLink, LuFile, LuLogOut, LuMonitor, LuMoon, LuQrCode, LuSun } from 'react-icons/lu'

import MobileConfigQrCodeModal from '@app/components/Modal/MobileConfigQrCodeModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TernaryDarkMode, useTernaryDarkMode } from '@holis/react-ui/hooks'

export default function UserDropdown() {
  const { ternaryDarkMode, setTernaryDarkMode } = useTernaryDarkMode()
  const { t } = useTranslation()
  const { user, logout } = useHolisAuth()
  const [showMobileConfig, setShowMobileConfig] = useState(false)

  return (
    <>
      <RadDropdownMenu>
        <RadDropdownMenuTrigger className="rounded-full">
          <RadAvatar className="h-8 w-8">
            <RadAvatarImage src={Astro} />
          </RadAvatar>
        </RadDropdownMenuTrigger>

        <RadDropdownMenuContent align="end" className="w-48 border-border">
          <RadDropdownMenuLabel className="text-muted-foreground">
            {user?.username}
          </RadDropdownMenuLabel>

          <RadDropdownMenuSeparator />

          <RadDropdownMenuLabel>
            {t('label.menu.userMenu.action')}
          </RadDropdownMenuLabel>

          <RadDropdownMenuItem className="gap-2 cursor-pointer" onClick={() => window.open('https://docs.holis.cloud/AIM-Asset-Integrity-Management-17591369912880dc89e2c4fe793f4cdf?pvs=73')}>
            <div className="flex gap-2 items-center flex-1">
              <LuFile />
              {' '}
              {t('label.menu.userMenu.documentation')}
            </div>
            <div className="opacity-50"><LuExternalLink /></div>
          </RadDropdownMenuItem>

          <RadDropdownMenuItem className="gap-2 cursor-pointer" onClick={() => setShowMobileConfig(true)}>
            <LuQrCode />
            {' '}
            {t('label.menu.userMenu.mobileConfiguration')}
          </RadDropdownMenuItem>

          <RadDropdownMenuSeparator />

          <RadDropdownMenuLabel>Themes</RadDropdownMenuLabel>
          <RadDropdownMenuRadioGroup value={ternaryDarkMode} onValueChange={mode => setTernaryDarkMode(mode as TernaryDarkMode)}>
            <RadDropdownMenuRadioItem value="light">
              <LuSun className="mr-2 size-4" />
              {' ' + t('label.menu.userMenu.light')}
            </RadDropdownMenuRadioItem>
            <RadDropdownMenuRadioItem value="dark">
              <LuMoon className="mr-2 size-4" />
              {' ' + t('label.menu.userMenu.dark')}
            </RadDropdownMenuRadioItem>
            <RadDropdownMenuRadioItem value="system">
              <LuMonitor className="mr-2 size-4" />
              {' ' + t('label.menu.userMenu.system')}
            </RadDropdownMenuRadioItem>
          </RadDropdownMenuRadioGroup>

          <RadDropdownMenuSeparator />

          <RadDropdownMenuItem className="gap-2 text-red-400 cursor-pointer" onClick={logout}>
            <LuLogOut />
            {t('label.menu.userMenu.logout')}
          </RadDropdownMenuItem>
        </RadDropdownMenuContent>
      </RadDropdownMenu>
      { showMobileConfig && <MobileConfigQrCodeModal isOpen onClose={() => setShowMobileConfig(false)} />}
    </>
  )
}
