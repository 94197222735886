import { useQuery } from '@apollo/client'
import { FileUpload } from '@app/components/Common/FileDropZone'
import AppCombobox from '@app/components/Common/Form/AppCombobox'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper'
import { AppListItem } from '@app/components/Common/Form/list-item'
import { GetAllRefDwgTypesQuery, GetAllSectorsQuery, RefDwgType, Sector } from '@app/graphql/__types__/graphql'
import { DWG_REF_TYPES_GET_MANY, SECTORS_GET_ALL } from '@app/graphql/requests'
import { useInspectionDrawingStore } from '@app/stores/methodEngineering/inspectionDrawing'
import { getFileNameWithoutExtension } from '@app/utils/functions'
import { RadBadge, RadFormField, RadFormMessage, RadInput } from '@holis/react-ui/rad'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuFileBox } from 'react-icons/lu'

export default function Form() {
  const { t } = useTranslation()
  const { uploadFile, setUploadFile } = useInspectionDrawingStore()
  const [sectors, setSectors] = useState<AppListItem<Sector>[]>([])
  const [types, setTypes] = useState<AppListItem<RefDwgType>[]>([])

  const { loading: sectorsLoading } = useQuery<GetAllSectorsQuery>(SECTORS_GET_ALL, {
    onCompleted(data) {
      setSectors(data.sectors.map((sector: Partial<Sector>) => ({
        label: `[${sector.sector}] - ${sector.description}`,
        value: sector.id,
      })))
    },
  })

  const { loading: typesLoading } = useQuery<GetAllRefDwgTypesQuery>(DWG_REF_TYPES_GET_MANY, {
    onCompleted(data) {
      setTypes(data.refDwgTypes.map((item: Partial<RefDwgType>) => ({
        label: `[${item.type}] - ${item.description}`,
        value: item.id,
      })))
    },
  })

  return (
    <div className="flex flex-col gap-6 pl-0.5 pr-4 pb-0.5">
      {/* Identification */}
      <div className="flex flex-col gap-4">
        <FormGroupHeader>
          {t('label.identification')}
        </FormGroupHeader>

        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            {/* Reference */}
            <RadFormField
              name="idwg"
              render={({ field, fieldState }) => (
                <InputLabelWrapper
                  isRequired
                  fieldState={fieldState}
                  label={t('label.reference')}
                  className="w-full"
                >
                  <RadInput {...field} className="uppercase" placeholder="-" />
                </InputLabelWrapper>
              )}
            />

            {/* Revision */}
            <RadFormField
              name="revision"
              render={({ field, fieldState }) => (
                <InputLabelWrapper
                  fieldState={fieldState}
                  label={t('label.revision')}
                  className="w-40"
                >
                  <RadInput {...field} placeholder="-" />
                </InputLabelWrapper>
              )}
            />
          </div>

          {/* Description */}
          <RadFormField
            name="description"
            render={({ field, fieldState }) => (
              <InputLabelWrapper
                isRequired
                fieldState={fieldState}
                label={t('label.description')}
              >
                <RadInput
                  placeholder={t('label.description')}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                  }}
                />
              </InputLabelWrapper>
            )}
          />

          <div className="grid grid-cols-2 gap-2">
            {/* Type */}
            <RadFormField
              name="typeId"
              render={({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.type')}>
                  <AppCombobox
                    items={types}
                    placeholder={t('label.selectType')}
                    loading={typesLoading}
                    {...field}
                    onSelectedItemChanged={selectedItem => field.onChange(selectedItem?.value)}
                  />
                </InputLabelWrapper>
              )}
            />

            {/* Sector */}
            <RadFormField
              name="sectorId"
              render={({ field, fieldState }) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.sector')}>
                  <AppCombobox
                    required
                    items={sectors}
                    loading={sectorsLoading}
                    placeholder={t('label.selectSector')}
                    {...field}
                    onSelectedItemChanged={selectedItem => field.onChange(selectedItem?.value)}
                  />
                </InputLabelWrapper>
              )}
            />
          </div>
        </div>
      </div>

      {/* Document file */}
      <div className="flex flex-col gap-4">
        <FormGroupHeader>
          {t('label.documentFile')}
        </FormGroupHeader>

        <RadFormField
          name="docName"
          render={({ field, fieldState }) => {
            const onFileCb = (file: File) => {
              setUploadFile(file)
              field.onChange(getFileNameWithoutExtension(file.name))
            }

            return (
              <div className="flex flex-col gap-1">
                <FileUpload
                  accept="application/pdf, .pdf"
                  onFileCb={onFileCb}
                />

                {fieldState.error && (
                  <RadFormMessage className="text-xs">
                    {fieldState.error.message}
                  </RadFormMessage>
                )}

                {uploadFile && (
                  <div className="my-0.5 flex items-center justify-between">
                    <span className="flex items-center">
                      <LuFileBox className="mr-2 text-muted-foreground" />

                      <span className="text-sm font-semibold tracking-tighter text-muted-foreground">
                        {uploadFile.name}
                      </span>
                    </span>

                    <RadBadge variant="secondary">
                      {uploadFile.size > 1024 * 1024
                        ? (
                            `${(uploadFile.size / (1024 * 1024)).toFixed(2)} MB`
                          )
                        : (
                            `${(uploadFile.size / 1024).toFixed(2)} KB`
                          )}
                    </RadBadge>
                  </div>
                )}
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}
