import { gql } from '@apollo/client'

export const DAMAGE_REF_CLASSES_COMMON_FIELDS = gql`
  fragment RefDamageClassCommonFields on RefDamageClass {
    id
    class
    description
    category
    isActive
}`
