import Footer from './Components/Footer'
import PermissionForm from './Components/PermissionForm'
import useUsersStore from '@app/stores/users'
import { ApolloError, useMutation } from '@apollo/client'
import { CreateRoleMutation, DeleteRoleMutation, RoleUncheckedUpdateInput, UpdateUserRoleMutation } from '@app/graphql/__types__/graphql'
import { ROLE_CREATE, ROLE_DELETE, ROLE_UPDATE } from '@app/graphql/requests/usersRoles'
import AppNotifications from '@app/services/notification'
import { useTranslation } from 'react-i18next'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { useState } from 'react'
import SingleContentModal from '../../SingleFormModal'
import { OBJ_NEW_ID } from '@app/utils/constants'
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper'
import { RadInput, RadTabs, RadTabsContent, RadTabsList, RadTabsTrigger } from '@holis/react-ui/rad'
import MenuPermission from './Components/MenuPermission'

type TUserModal = Readonly<{
  open?: boolean
  onClose?: () => void
}>

export default function RoleModal({ open, onClose }: TUserModal) {
  const { t } = useTranslation()
  const [warnDelete, setWarnDelete] = useState<boolean>(false)
  const { editingRole, setEditingRole, updateRole, addRole, removeRole } = useUsersStore()
  const [gqlUpdateRole] = useMutation<UpdateUserRoleMutation>(ROLE_UPDATE)
  const [gqlCreateRole] = useMutation<CreateRoleMutation>(ROLE_CREATE)
  const [gqlDeleteRole] = useMutation<DeleteRoleMutation>(ROLE_DELETE)

  const handleGqlUpdateRole = async () => {
    // Update role datas
    const data: RoleUncheckedUpdateInput = {
      role: { set: editingRole!.role },
      permissions: {
        upsert: [
          ...editingRole!.permissions.map(permission => ({
            where: {

              roleId_resource: { roleId: editingRole!.id!, resource: permission.resource },
            },
            update: {
              permissions: { set: permission.permissions },
            },
            create: {
              resource: permission.resource,
              permissions: permission.permissions,
            },
          })),
        ],
      },
    }
    await gqlUpdateRole({ variables: { id: editingRole!.id, data } })
    AppNotifications.success(t('message.success.role.saveSuccess', { role: editingRole!.role }))
  }

  const handleGqlCreateRole = async () => {
    // Create role datas
    const data = {
      role: editingRole!.role,
      permissions: {
        create: editingRole!.permissions.map(permission => ({
          resource: permission.resource,
          permissions: permission.permissions,
        })),
      },
    }
    const newRole = await gqlCreateRole({ variables: { id: editingRole!.id, data } })
    AppNotifications.success(t('message.success.role.createSuccess', { role: editingRole!.role }))
    return newRole
  }

  const handleSaveRole = async () => {
    const isNewRole = editingRole!.id! === -1
    const theRole = { ...editingRole! }

    // Update role in db
    try {
      if (isNewRole) {
        theRole!.id = (await handleGqlCreateRole()).data!.createOneRole.id
      } else {
        await handleGqlUpdateRole()
      }
    } catch (e) {
      if (e instanceof ApolloError
        && e.message.includes('Unique constraint failed on the fields: (`role`)')) {
        AppNotifications.error(t('message.error.role.roleAlreadyExists', { role: editingRole!.role }))
        return
      }

      AppNotifications.error(t('message.error.role.saveFailed'))
      return
    }

    // Update role in store
    if (isNewRole) {
      addRole(theRole!)
    } else {
      updateRole(theRole!)
    }

    // Close modal
    setEditingRole(undefined)
  }

  const handleDeleteRole = async () => {
    // Delete role in db
    try {
      await gqlDeleteRole({ variables: { id: editingRole!.id } })
      AppNotifications.success(t('message.success.role.deleteSuccess', { role: editingRole!.role }))
    } catch {
      AppNotifications.error(t('message.error.role.deleteFailed'))
      return
    }

    // Delete role in store
    removeRole(editingRole!.id!)

    // Close modal
    setEditingRole(undefined)
  }

  return (
    <>
      <SingleContentModal
        title={editingRole?.id === OBJ_NEW_ID ? t('label.newRole') : t('label.role')}
        itemDesctiption={editingRole?.role?.toUpperCase()}
        footer={<Footer onSaveClicked={handleSaveRole} onDeleteClicked={() => setWarnDelete(true)} />}
        containerClassName="max-w-[1000px] min-h-[90%]"
        isOpen={open}
        contentClassName="flex flex-col overflow-hidden"
        onOpenChange={(opened) => {
          if (!opened) {
            onClose?.()
          }
        }}
      >
        <div className="flex flex-col flex-1 overflow-hidden">
          <form className="p-4 text-sm flex flex-col flex-1 overflow-hidden" id="mdl-role-edit-form">

            <InputLabelWrapper label={t('label.rolename')}>
              <RadInput
                required
                label={t('label.rolename')}
                value={editingRole!.role ?? ''}
                onChange={(e) => {
                  setEditingRole({ ...editingRole!, role: e.target.value })
                }}
              />
            </InputLabelWrapper>

            <RadTabs defaultValue="menu" className="mt-2 flex-1 flex flex-col overflow-hidden">
              <RadTabsList>
                <RadTabsTrigger value="menu">{t('label.menuPermissions')}</RadTabsTrigger>
                <RadTabsTrigger value="permissions">{t('label.permissions')}</RadTabsTrigger>
              </RadTabsList>
              <div className="mt-4 flex-1 overflow-y-auto">
                <RadTabsContent value="permissions">
                  <PermissionForm />
                </RadTabsContent>
                <RadTabsContent value="menu">
                  <MenuPermission />
                </RadTabsContent>
              </div>
            </RadTabs>
          </form>
        </div>
      </SingleContentModal>
      <WarningConfirmModal
        open={warnDelete}
        onCancel={() => setWarnDelete(false)}
        onClose={() => setWarnDelete(false)}
        onConfirm={handleDeleteRole}
      >
        {t('message.questions.deleteRole', { role: editingRole?.role })}
      </WarningConfirmModal>
    </>
  )
}
