import { gql } from '@apollo/client'

export const NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS = gql`
  fragment RefNotifAdditionalDataCommonFields on RefNotifAdditionalData {
    id
    code
    category
    shortText
    shortTextCode
    codeGroup
    isActive
}`
