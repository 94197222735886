import { useQuery, FetchResult, useLazyQuery } from '@apollo/client'
import AppAutocomplete from '@app/components/Common/Form/Autocomplete'
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock'
import BlockTitle from '@app/components/Common/Text/BlockTitle'
import { Event, EventDamage, EventDamageUncheckedUpdateInput, GetAllRefDamageValCodesQuery, GetEventDamageByIdQuery, Damage, RefDamageValCode, NotificationDamage, NotificationDamageUncheckedUpdateInput } from '@app/graphql/__types__/graphql'
import { DAMAGE_REF_VAL_CODES_GET_MANY, WORKORDER_EVENT_DAMAGES_GET_BY_ID } from '@app/graphql/requests'
import { TFieldsBlock, TRenderAutocompleteProps } from '@app/types/app'
import { EFieldType, ENOTIFICATION_NOTIF_STATUS } from '@app/utils/enums'
import { renderQualReadingItem, setObjValueByPath } from '@app/utils/functions'
import React, { TextareaHTMLAttributes, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import { LuFileEdit } from 'react-icons/lu'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { useHolisAuth } from '@holis/auth-client-react'
type TMeasurementBlock = Readonly<{
  eventDamage?: Partial<EventDamage>
  notificationDamage?: Partial<NotificationDamage>
  event: Partial<Event>
  damage: Partial<Damage>
  readonly?: boolean
  useCurrentDateAndUser?: boolean
  onFieldUpdated?: (field: string, value: unknown) => void
  onFieldBlur?: (field: string, value: unknown) => void
}>

const MAX_LENGTH_VALIDATORS = {
  notes: 600,
  quantReading: 100,
}

export default function MeasurementBlock({ event, eventDamage, notificationDamage, damage, onFieldUpdated, onFieldBlur, readonly, useCurrentDateAndUser }: TMeasurementBlock) {
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const { user } = useHolisAuth()
  const [submitRequested, setSubmitRequested] = useState<boolean>(false)
  const [updateData, setUpdateData] = useState<EventDamageUncheckedUpdateInput | NotificationDamageUncheckedUpdateInput>({})
  const refDamageValCodesResult = useQuery<GetAllRefDamageValCodesQuery>(DAMAGE_REF_VAL_CODES_GET_MANY)
  const [activeEventDamage, setActiveEventDamage] = useState<Partial<EventDamage> | undefined>(eventDamage)
  const [initEventDamage, setInitEventDamage] = useState<Partial<EventDamage | undefined>>(eventDamage)
  const [activeNotificationDamage, setActiveNotificationDamage] = useState<Partial<NotificationDamage> | undefined>(notificationDamage)

  const [getEventDamageByIdApi] = useLazyQuery<GetEventDamageByIdQuery>(WORKORDER_EVENT_DAMAGES_GET_BY_ID)
  const zodFormObject = z.object({
    notes: z.string().max(MAX_LENGTH_VALIDATORS.notes),
  })
  const form = useForm<z.infer<typeof zodFormObject>>(
    { resolver: zodResolver(zodFormObject), values: {
      notes: activeEventDamage?.notes?.toString() ?? '',
    }, mode: 'onBlur' })
  const htmlForm = useRef<HTMLFormElement | null>(null)

  const isNotification = !!notificationDamage

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit()

      setSubmitRequested(false)
    }
  }, [submitRequested])

  useEffect(() => {
    if (!!damage.codeGroup && !damage.codeGroup?.codeGroupValCodes.length && (activeEventDamage?.qualReading !== null || activeNotificationDamage?.qualReading !== null)) {
      const editedDamage = { ...(activeNotificationDamage ?? activeEventDamage) }
      setObjValueByPath(editedDamage, 'qualReading', null)
      setObjValueByPath(editedDamage, 'qualReadingId', null)
      setActiveEventDamage(editedDamage)
      setUpdateData({
        qualReadingId: {
          set: null,
        },
      })
      handleFieldChange?.('qualReadingId', null)
    }
  }, [damage.codeGroup])

  const renderAutocomplete = (props: TRenderAutocompleteProps): React.ReactNode => {
    const { setInputValue, renderMenuItemLabel, dbValue, field, foreignField } = props ?? {}
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((updateData && Object.keys(updateData).includes(field!)) || (!(updateData && Object.keys(updateData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string)
            }

            const editedDamage = { ...(activeNotificationDamage ?? activeEventDamage) }
            setObjValueByPath(editedDamage, foreignField!, item)
            setObjValueByPath(editedDamage, field!, item?.id ?? null)
            if (notificationDamage) {
              setActiveNotificationDamage(editedDamage)
            } else {
              setActiveEventDamage(editedDamage)
            }

            setUpdateData({
              [field!]: {
                set: item?.id ?? null,
              },
            })
            onFieldUpdated?.(field, item?.id ?? null)
            onFieldBlur?.(field, item?.id ?? null)
          }
        }}
        {...props}
      />
    )
  }

  const fields: TFieldsBlock['fields'] = [
    {
      label: 'label.reading',
      field: 'qualReadingId',
      foreignField: 'qualReading',
      dbValue: (notificationDamage ?? initEventDamage)?.qualReading?.id,
      foreignObject: activeNotificationDamage?.qualReading ?? activeEventDamage?.qualReading,
      initialValue: renderQualReadingItem(notificationDamage?.qualReading ?? initEventDamage?.qualReading),
      value: renderQualReadingItem(activeNotificationDamage?.qualReading ?? activeEventDamage?.qualReading),
      fieldType: EFieldType.autocomplete,
      itemsQueryResult: refDamageValCodesResult,
      getItemsFromResult: (result: FetchResult) => (((result as FetchResult<GetAllRefDamageValCodesQuery>)?.data?.refDamageValCodes ?? []) as Partial<RefDamageValCode>[]).filter((valC: Partial<RefDamageValCode>) => !damage.codeGroup || !!damage.codeGroup.codeGroupValCodes?.map(cgVal => cgVal.valCodeId).includes(valC.id!)),
      renderMenuItemLabel: field => renderQualReadingItem(field as Partial<RefDamageValCode>),
      renderInput: renderAutocomplete,
      className: 'w-full flex',
    },
  ]

  if (!notificationDamage) {
    fields.push({
      label: 'label.date',
      field: 'reportingDate',
      fieldType: EFieldType.date,
      initialValue: initEventDamage?.reportingDate,
      value: useCurrentDateAndUser ? Date.now : activeEventDamage?.reportingDate,
      className: 'w-auto mr-24 flex',
      isDisabled: true,
    },
    {
      label: 'label.reader',
      field: 'reader',
      fieldType: EFieldType.text,
      initialValue: initEventDamage?.reader,
      value: useCurrentDateAndUser ? user?.username : activeEventDamage?.reader,
      className: 'w-auto',
      isDisabled: true,
    },
    {
      label: 'label.notes',
      field: 'notes',
      fieldType: EFieldType.text,
      initialValue: initEventDamage?.notes,
      value: activeEventDamage?.notes,
      className: 'flex w-full',
      inputComponent: 'textarea',
      inputProps: {
        rows: 2,
        maxLength: MAX_LENGTH_VALIDATORS.notes,
      } as TextareaHTMLAttributes<HTMLTextAreaElement>,
    })
  }

  const fieldBlocks: TFieldsBlock[] = [
    {
      title: false,
      fields,
      fieldsClassName: 'w-full flex flex-row flex-wrap gap-0',
      fieldClassName: 'inline-flex w-auto',
    },
  ]
  const handleFieldChange = (field: string, value: unknown) => {
    const editedDamage = { ...(activeNotificationDamage ?? activeEventDamage) }
    setObjValueByPath(editedDamage, field, value)
    if (notificationDamage) {
      setActiveNotificationDamage(editedDamage)
    } else {
      setActiveEventDamage(editedDamage)
    }

    setUpdateData({
      [field!]: {
        set: value,
      },
    })
    onFieldUpdated?.(field, value)
  }

  const getEventDamageDetail = () => {
    if (eventDamage?.id) {
      getEventDamageByIdApi({
        variables: {
          id: eventDamage?.id,
        },
        fetchPolicy: 'no-cache',
      }).then((fetchResult) => {
        setInitEventDamage({ ...fetchResult.data?.eventDamage as Partial<EventDamage> })
        setActiveEventDamage({ ...fetchResult.data?.eventDamage as Partial<EventDamage> })
      })
    }
  }

  useEffect(() => {
    getEventDamageDetail()
  }, [eventDamage?.id])

  return (
    <div className="flex flex-col w-full">
      <BlockTitle className="uppercase text-primary px-4 font-bold">
        <LuFileEdit size={20} className="mr-2" />
        {' '}
        {t('label.measurement')}
        {' '}
        (
        {isNotification ? t('label.notification') : `${t('label.event')} ${event?.event}`}
        )
      </BlockTitle>
      <RadForm {...form}>
        <form
          ref={htmlForm}
        >
          <FormFieldsBlock
            isDisabled={
              readonly
              || (!isNotification && config.getActionIsDisabled('event', 'measurement', event?.status))
              || (isNotification && notificationDamage?.notification?.status === ENOTIFICATION_NOTIF_STATUS.APPR)
            }
            className="px-2"
            fieldsBlocks={fieldBlocks}
            onFieldChange={handleFieldChange}
            onFieldBlur={onFieldBlur}
          />
        </form>
      </RadForm>
    </div>
  )
}
