import DrawingViewer from '@app/components/Common/Block/InspectionDrawing/DrawingViewer'
import { Event, EventCml, EventDamage, FunctionalLocation, InspectionDrawing, Notification } from '@app/graphql/__types__/graphql'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DrawingPageHeader from './Header'

export type TDrawingViewers = Readonly<{
  drawings?: Partial<InspectionDrawing>[]
  selectedDrawing?: Partial<InspectionDrawing>
  setSelectedDrawing: (drawing?: Partial<InspectionDrawing>) => void
  renderDrawingViewer?: (drawing: Partial<InspectionDrawing>, props?: TDrawingViewers) => React.ReactElement
  visible: boolean
  objectItem?: Partial<FunctionalLocation | Event | Notification | InspectionDrawing>
  hideTechniquesBlock?: boolean
  techniqueCreateBtnHidden?: boolean
  hideCreateDamage?: boolean
  hideActions?: boolean
  hideCreate?: boolean
  latestEventItems?: Record<number, Partial<EventDamage | EventCml> | null>
  onBack?: () => void
}>

export default function DrawingViewers(props: TDrawingViewers) {
  const { t } = useTranslation()
  const { hideCreateDamage, drawings, visible, selectedDrawing, setSelectedDrawing, objectItem, hideTechniquesBlock, renderDrawingViewer, hideActions, hideCreate, onBack, techniqueCreateBtnHidden, latestEventItems } = props
  useEffect(() => {
    if (drawings?.length === 1) {
      setSelectedDrawing(drawings[0])
    } else {
      setSelectedDrawing()
    }
  }, [drawings])

  return (
    <div className={`h-full overflow-hidden flex flex-col ${!visible ? 'hidden' : ''}`}>
      <DrawingPageHeader nbDrawings={drawings?.length} selectedDrawing={selectedDrawing} hideCreate={hideCreate} goBack={typeof onBack === 'undefined' && drawings?.length && drawings.length > 1 ? setSelectedDrawing : onBack} hideActions={hideActions ?? objectItem?.__typename === 'Notification'} />

      <div className={selectedDrawing || !drawings?.length ? 'h-full w-full' : 'grid grid-cols-2 w-full h-full gap-4 overflow-auto'}>
        {drawings?.length ? drawings.map((item: Partial<InspectionDrawing>) => (renderDrawingViewer?.(item, props) ?? <DrawingViewer key={`drawing-viewer-${item.id!}`} latestEventItems={latestEventItems} techniqueCreateBtnHidden={techniqueCreateBtnHidden} setSelectedDrawing={setSelectedDrawing} hideTechniquesBlock={hideTechniquesBlock} hideCreateDamage={hideCreateDamage} objectItem={objectItem} idwg={item} />)) : (typeof drawings === 'undefined' ? t('label.loading') : t('label.noDrawingsFound'))}
      </div>
    </div>
  )
}
