import { Badge } from '@holis/react-ui'
import { ChangeEvent, InputHTMLAttributes } from 'react'
import { cn } from '@holis/react-ui/utils'

type TBadgeInput = Readonly<{
  onChange?: (_e: ChangeEvent<HTMLInputElement>) => void
  className?: string
  inputProps?: InputHTMLAttributes<HTMLInputElement>
}>

export default function BadgeInput({ onChange, className, inputProps }: TBadgeInput) {
  return (
    <Badge className={cn('border font-poppins font-bold  justify-center flex-col min-w-[40px] min-h-[40px] w-[40px] h-[40px] px-0 py-0', className)} shapePreset={Badge.ShapePresets.Square}>
      <input {...inputProps} className={cn('outline-none bg-transparent  h-full w-full !text-center', inputProps?.className)} onChange={onChange ?? inputProps?.onChange} />
    </Badge>

  )
}
