import { CreateEventFlocsMutation, DeleteEventFlocsMutation, Event, EventFloc, FunctionalLocation, SyncSchedulingTagChildrenByIdMutation, UpdateOneEventFlocByIdMutation } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import { useLayoutStore } from '@app/stores/layout'
import { useMutation } from '@apollo/client'
import { WORKORDER_EVENT_FLOCS_CREATE_MANY, WORKORDER_EVENT_FLOCS_DELETE_MANY, WORKORDER_EVENT_FLOCS_UPDATE_BY_ID, WORKORDER_EVENTS_SYNC_SCHEDULING_TAG_CHILDREN_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useEffect, useMemo, useState } from 'react'
import DeleteEventFlocModal from './DeleteEventFlocModal'
import { useEventStore } from '@app/stores/event'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import SearchBar from '@app/components/Common/SearchBar'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuBox, LuPlusSquare, LuRefreshCcw } from 'react-icons/lu'
import FlocCard from '@app/components/Common/Block/Floc/FlocBlock/FlocCard'
import FlocSelectionModal from '@app/components/Common/Block/Floc/FlocBlock/FlocSelectionModal'
import SortableList, { SortableItem } from '@app/components/Common/List/SortableList'
import { TDbId } from '@app/types/app'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import _ from 'lodash'

type TFlocBlock = Readonly<{
  event: Partial<Event>
  readonly?: boolean
}>

export default function FlocBlock({ event, readonly }: TFlocBlock) {
  const config = useOptimusConfig()
  const { flocToDelete, setFlocToDelete, eventFlocs, fetchEventFlocs, setEventFlocIds, pointFlocIds } = useEventStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [searchInput, setSearchInput] = useState<string>('')
  const [addEventFlocsApi] = useMutation<CreateEventFlocsMutation>(WORKORDER_EVENT_FLOCS_CREATE_MANY)
  const [deleteEventFlocsApi] = useMutation<DeleteEventFlocsMutation>(WORKORDER_EVENT_FLOCS_DELETE_MANY)
  const { t } = useTranslation()
  const [noSchedulingTagFlocList, setNoSchedulingTagFlocList] = useState<Partial<EventFloc>[]>()
  const [flocSelectionModalDisplayed, changeFlocSelectionModalDisplay] = useState<boolean>(false)
  const [updateEventFlocApi] = useMutation<UpdateOneEventFlocByIdMutation>(WORKORDER_EVENT_FLOCS_UPDATE_BY_ID)
  const [syncSchedulingTagChildrenApi] = useMutation<SyncSchedulingTagChildrenByIdMutation>(WORKORDER_EVENTS_SYNC_SCHEDULING_TAG_CHILDREN_BY_ID)
  const [selectedIds, setSelectedIds] = useState<number[]>()
  const addItems = async (flocIds: TDbId[]) => addEventFlocsApi({
    variables: {
      data: flocIds.map((flocId: TDbId) => ({
        wrkoId: event.id,
        flocId,
      })),
    },
  })

  const deleteItems = async (eventFlocIds: TDbId[]) => deleteEventFlocsApi({
    variables: {
      ids: eventFlocIds,
    },
  })

  // Validate seletion modal (Add or delete floc )
  const onValidateSelection = async (selectedItems: Partial<FunctionalLocation>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const eventFlocIds = (eventFlocs?.filter((item: Partial<EventFloc>) => item.flocId && oldSelectedIds.includes(item.flocId)).map((item: Partial<EventFloc>) => item.id) ?? []) as TDbId[]
      if (eventFlocIds.length) {
        promises.push(deleteItems(eventFlocIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        changeFlocSelectionModalDisplay(false)
        fetchEventFlocs?.()
        AppNotifications.success(t('message.success.eventFlocsUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    }
  }

  const syncSchedulingTagChildren = () => {
    startLoading()
    syncSchedulingTagChildrenApi({
      variables: {
        id: event.id,
      },
    }).then((queryResult) => {
      if (queryResult.data?.syncSchedulingTagChildren === 0) {
        fetchEventFlocs?.()
      } else {
        AppNotifications.error(t('message.error.default.title'))
      }
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    }).finally(stopLoading)
  }

  const updateSortHandler = (item: Partial<EventFloc>, newSortVal: number) => {
    startLoading()
    updateEventFlocApi({
      variables: {
        id: item.id!,
        data: {
          sort: {
            set: newSortVal,
          },
        },
      },
    }).then(() => {
      AppNotifications.success(t('message.success.eventFlocUpdated'))
      fetchEventFlocs?.()
    }).catch((error: Error) => {
      console.log(error)
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  const handleSortChange = (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => {
    updateSortHandler(dragItem as Partial<EventFloc>, (dropItem as Partial<EventFloc>).sort ?? (dropIndex + 2))
  }

  useEffect(() => {
    const flocIds = eventFlocs?.map((item: Partial<EventFloc>) => item.flocId!)
    // Add floc id to store
    setEventFlocIds(flocIds)
    setNoSchedulingTagFlocList(eventFlocs?.filter((floc: Partial<EventFloc>) => floc.flocId !== event.schedulingTagId) ?? [])
    setSelectedIds(eventFlocs?.map((item: Partial<EventFloc>) => item.flocId!) ?? [])
  }, [eventFlocs])

  const schedulingFloc: Partial<EventFloc> = { event: event as Event, wrkoId: event.id, functionalLocation: event.schedulingTag, flocId: event.schedulingTagId }

  const filtrtedNoSchedulingTagFlocList = useMemo(() => (searchInput
    ? noSchedulingTagFlocList?.filter((floc: Partial<EventFloc>) => {
      const token = searchInput.trim().toLowerCase()
      return floc.functionalLocation?.floc.toLowerCase().includes(token)
        || floc.functionalLocation?.description?.toLowerCase().includes(token)
        || floc.functionalLocation?.techClass?.techClass.toLowerCase().includes(token)
    })
    : noSchedulingTagFlocList), [noSchedulingTagFlocList, searchInput])
  return (
    <div>
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem key="sync-children" disabled={readonly || config.getActionIsDisabled('event', 'flocAdd', event.status)} onClick={syncSchedulingTagChildren}>
            <LuRefreshCcw className="mr-2" />
            {' '}
            {t('label.synchChildren')}
          </RadDropdownMenuItem>,
          <RadDropdownMenuItem key="manage-object" disabled={readonly || config.getActionIsDisabled('event', 'flocAdd', event.status)} onClick={() => changeFlocSelectionModalDisplay(true)}>
            <LuPlusSquare className="mr-2" />
            {' '}
            {t('label.manageObject')}
          </RadDropdownMenuItem>,
        ]}
        actions={(
          <SearchBar
            className="w-[300px]"
            onChange={e => setSearchInput(e.target?.value)}
          />
        )}
      >
        <div className="flex items-center">
          <LuBox size={20} className="mr-2" />
          {' '}
          {t('label.floc')}
          {' '}
          (
          {eventFlocs?.length ?? 0}
          )
        </div>
      </FormGroupHeader>

      <div className="mt-3 flex flex-col gap-2">
        <FlocCard
          key={`floc-item-${schedulingFloc.id!}`}
          hasContextMenu
          isDeleteButtonDisabled={!!flocToDelete}
          item={schedulingFloc}
          deleteText={t('label.removeFromEvent')}
        />
        <SortableList
          items={(filtrtedNoSchedulingTagFlocList ?? []) as SortableItem[]}
          renderItem={(item: SortableItem) => (
            <FlocCard
              key={`floc-item-${item.id!}`}
              hasContextMenu
              hasDragBtn={!readonly}
              isDeleteButtonDisabled={readonly || config.getActionIsDisabled('event', 'flocRemove', event.status)}
              item={item as Partial<EventFloc>}
              deleteText={t('label.removeFromEvent')}
              onDeleteClick={f => setFlocToDelete(f as Partial<EventFloc>)}
            />
          )}
          onSortChange={handleSortChange}
        />

        {flocSelectionModalDisplayed && <FlocSelectionModal open filterSites={event.schedulingTag?.sector.site.site ? [event.schedulingTag?.sector.site.site] : undefined} disabledIds={_.concat(_.intersection(pointFlocIds, selectedIds ?? []), [schedulingFloc.flocId!])} selectedIds={selectedIds} closeOnConfirm={false} onClose={() => changeFlocSelectionModalDisplay(false)} onValidate={onValidateSelection} />}

      </div>

      <DeleteEventFlocModal open={!!flocToDelete} />
    </div>
  )
}
