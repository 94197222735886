import { gql } from '@apollo/client'

export const INTEGRITY_REF_MATRICES_GET_COUNTER = gql`
query GetRefIntegrityMatricesCounter {
  aggregateRefIntegrityMatrix {
    _count {
      _all
    }
  }
}
`
