import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { InspectionPoint, DeleteInspectionPointByIdMutation } from '@app/graphql/__types__/graphql'
import { INSPECTION_POINTS_DELETE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
type TDeleteInspectionPointModal = IConfirmModal & Readonly<{
  inspectionPoint: Partial<InspectionPoint>
  onInspectionPointDeleted?: (inspectionPoint?: Partial<InspectionPoint>) => void
  changeDeleteInspectionPointModalDisplay: (isOpen: boolean) => void
}>

export default function DeleteInspectionPointModal({ inspectionPoint, onInspectionPointDeleted, changeDeleteInspectionPointModalDisplay, ...restProps }: TDeleteInspectionPointModal) {
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteInspectionPointApi, { data, loading, error }] = useMutation<DeleteInspectionPointByIdMutation>(INSPECTION_POINTS_DELETE_BY_ID)
  const { t } = useTranslation()
  useEffect(() => {

  }, [data, loading, error])
  const onConfirm = () => {
    startLoading()
    deleteInspectionPointApi({
      variables: { id: inspectionPoint!.id },
    }).then(() => {
      onInspectionPointDeleted?.(inspectionPoint)
      AppNotifications.success(t('message.success.deleteInspectionPoint'))
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteInspectionPoint')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeleteInspectionPointModalDisplay(false)}
      {...restProps}
    />
  )
}
