import SelectionConfirmModal, { TSelectionConfirmModalWithoutListProps } from '@app/components/Modal/Confirm/Selection'
import { Damage } from '@app/graphql/__types__/graphql'
import { TDataRowTreeList } from '@holis/react-ui'
import { useTranslation } from 'react-i18next'
import DamageCard from './DamageCard'
import React from 'react'

export default function DamageSelectionModal({ items, ...restProps }: TSelectionConfirmModalWithoutListProps<Partial<Damage>>) {
  const { t } = useTranslation()
  return (
    <SelectionConfirmModal
      title={t('label.damage')}
      className="[&_td]:h-fit [&_td>div]:h-full [&_td>div]:border-0"
      items={items}
      listProps={{
        listProps: {
          data: (items ?? []) as unknown as TDataRowTreeList[],
          cellRenderers: {
            damage(_value: unknown, rowData: unknown) {
              return (
                <div className="pointer-events-none">
                  <DamageCard key={(rowData as TDataRowTreeList).id} display2dDisabled displayLastQualReadingWithColor dmgObject={rowData as Partial<Damage>} />
                </div>
              )
            },
          },
          columns: [
            {
              field: 'damage',
              title: t('label.damage'),
              type: 'string',
              width: 250,
              cellRenderer: 'damage',
              class: 'border-0',
            },
          ],
        },
      }}
      {...restProps}
    />
  )
}
