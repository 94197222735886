import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteInspectionDrawingByIdMutation, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { INSPECTION_DRAWINGS_DELETE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import useIdwgStore, { IDWG_PREFIX } from '@app/stores/idwg'
import { useLayoutStore } from '@app/stores/layout'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
type TDeleteIdwgModal = IConfirmModal & Readonly<{
  idwg: Partial<InspectionDrawing>
}>

export default function DeleteIdwgModal({ idwg, ...restProps }: TDeleteIdwgModal) {
  const { changeDeleteInspectionDrawingModalDisplay, deleteInspectionDrawing, activeInspectionDrawing } = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`)
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteIdwgApi, { data, loading, error }] = useMutation<DeleteInspectionDrawingByIdMutation>(INSPECTION_DRAWINGS_DELETE_BY_ID)
  const { t } = useTranslation()
  useEffect(() => {

  }, [data, loading, error])
  const onConfirm = () => {
    startLoading()
    deleteIdwgApi({
      variables: { id: activeInspectionDrawing!.id },
    }).then(() => {
      deleteInspectionDrawing()
      AppNotifications.success(t('message.success.deleteIdwg'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteIdwg'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteIdwg')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeleteInspectionDrawingModalDisplay(false)}
      {...restProps}
    />
  )
}
