import { gql } from '@apollo/client'
import { NOTIFICATION_REF_LIKELIHOOD_COMMON_FIELDS } from '../fragments'

export const NOTIFICATION_REF_LIKELIHOODS_GET_MANY = gql`
${NOTIFICATION_REF_LIKELIHOOD_COMMON_FIELDS}
query GetAllRefNotifLikelihoods($orderBy: [RefNotifLikelihoodOrderByWithRelationInput!], $where: RefNotifLikelihoodWhereInput) {
    refNotifLikelihoods (orderBy: $orderBy, where: $where) {
      ...RefNotifLikelihoodCommonFields
    }
}`

export const NOTIFICATION_REF_LIKELIHOODS_GET_COUNTER = gql`
query GetRefNotifLikelihoodsCounter {
  aggregateRefNotifLikelihood {
    _count {
      _all
    }
  }
}
`
