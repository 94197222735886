import { FetchResult, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import FormIcon, { FIRST_OBJ_INVISIBLE_LIST } from '@app/components/Common/Markup/FormIcon'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import { Cml, CmlUncheckedUpdateInput, Damage, DamageUncheckedUpdateInput, Event, EventCml, EventDamage, FunctionalLocation, GetAllRefCmlValCodesQuery, GetAllRefDamageValCodesQuery, GetAllRefIntegrityConditionsQuery, GetLatestEventCmlByCmlIdQuery, GetLatestEventCmlWithQualReadingNonEmptyByCmlIdQuery, GetLatestEventDamageByDmgIdQuery, GetLatestEventDamageWithQualReadingNonEmptyByDmgIdQuery, InspectionDrawing, Notification, NotificationDamage, RefCmlValCode, RefDamageValCode, RefIntegrityCondition, UpdateCmlByIdMutation, UpdateDamageByIdMutation } from '@app/graphql/__types__/graphql'
import { CML_REF_VAL_CODES_GET_MANY, DAMAGES_UPDATE_BY_ID, DAMAGE_REF_VAL_CODES_GET_MANY, INTEGRITY_REF_CONDITIONS_GET_MANY, WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID, WORKORDER_EVENT_CMLS_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_CML_ID, WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID, WORKORDER_EVENT_DAMAGES_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_DMG_ID } from '@app/graphql/requests'
import { CMLS_UPDATE_BY_ID } from '@app/graphql/requests/cmls'
import AppNotifications from '@app/services/notification'
import useCmlStore from '@app/stores/cml'
import useDamageStore from '@app/stores/damage'
import { EventState, useEventStore } from '@app/stores/event'
import useIdwgStore, { CML_PREFIX, DAMAGE_PREFIX, deleteIdwgStore, IDWG_PREFIX, NO_TECHNIQUE_ID, TAB_CMLS, TAB_DAMAGES } from '@app/stores/idwg'
import useNotificationStore, { NotificationState } from '@app/stores/notification'
import { TDimension, TFabricObjectWithPrivate, TMarkupObjects } from '@app/types/app'
import { DEFAULT_SHAPE_COLOR, MARKUP_BACKGROUND_ID } from '@app/utils/constants'
import { EDMGMarkupForm, EDownloadFileType, EDrawingFileContentType, EEventFlocObjectFilter, EFlocRightSideTab, EIanRightSideTab, EInspectionStatus, EVerticalPosition, EWorkpackRightSideTab } from '@app/utils/enums'
import { createBase64FromUrl, reactElementToString, tailwindColorToHex } from '@app/utils/functions'
import { LOCK_RESIZE_ROTATE, createSvgAnnotation, handleObjectMoved, updateLastMarkupPosition } from '@app/utils/functions/fabric'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useHolisAuth } from '@holis/auth-client-react'
import { Badge, TListItem } from '@holis/react-ui'
import { RadCommand, RadCommandGroup, RadCommandItem } from '@holis/react-ui/rad'
import * as fabric from 'fabric'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuPlusSquare } from 'react-icons/lu'
import { ReactZoomPanPinchRef, ReactZoomPanPinchState } from 'react-zoom-pan-pinch'
import { cn } from '@holis/react-ui/utils'
import colors from 'tailwindcss/colors'
import DeleteIdwgModal from './DeleteIdwgModal'
import DeleteIdwgFlocModal from './Floc/DeleteIdwgFlocModal'
import DeleteIdwgGridModal from './Grid/DeleteIdwgGridModal'
import { FlocState, useFlocStore } from '@app/stores/methodEngineering/floc'
import { useLocation } from 'react-router-dom'
import { OptimusClientConfig } from '@app/utils/clientConfig'
import { AxiosError } from 'axios'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
import IdwgTechniques from './IdwgTechniques'
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader'
import { NO_DRAWING_ID } from '@app/utils/constants'

type TDrawingViewer = Readonly<{
  idwg: Partial<InspectionDrawing>
  canvasRef?: React.RefObject<HTMLCanvasElement>
  canvasContainerRef?: React.RefObject<HTMLDivElement>
  onViewerInit?: (ref: ReactZoomPanPinchRef) => void
  onMarkupMouseUp?: (item: Partial<Cml | Damage>) => void
  setSelectedDrawing?: (idwg?: Partial<InspectionDrawing>) => void
  latestEventItems?: Record<number, Partial<EventDamage | EventCml> | null>
  sortableId?: UniqueIdentifier
  objectItem?: Partial<Event | FunctionalLocation | Notification | InspectionDrawing>
  hasTechniqueList?: boolean
  size?: number
  containerWidth?: number
  cmls?: Partial<Cml>[]
  damages?: Partial<Damage>[]
  isSelected?: boolean
  selectedDrawing?: Partial<InspectionDrawing> | null
  storeId?: string
  canvasDisabled?: boolean
  locateMarkupDisabled?: boolean
  menuContextDisabled?: boolean
  hideTechniquesBlock?: boolean
  techniqueCreateBtnHidden?: boolean
  deleteTechniqueDisabled?: boolean
  statusHidden?: boolean
  hideCreateDamage?: boolean
  cmlHidden?: boolean
  dmgHidden?: boolean
  displayLastQualReadingWithColor?: boolean
  fadedItems?: string[]
}>

const originPoint = new fabric.Point({ x: 0, y: 0 })
const FADED_ITEM_OPACITY = 0.3
const FADED_ITEM_OPACITY_HOVER = 0.8

type TPanningInfo = {
  isDragging: boolean
  x: number
  y: number
}
fabric.FabricObject.prototype.noScaleCache = true
fabric.FabricObject.prototype.objectCaching = false
fabric.FabricObject.prototype.dirty = false

/**
 * Drawing viewer (add or show markup for damage, cml)
 */
export default function DrawingViewer({ hideCreateDamage, hideTechniquesBlock, idwg, canvasRef, canvasContainerRef, sortableId, objectItem, size, cmls: idwgCmls, damages: idwgDamages, isSelected, storeId, canvasDisabled, hasTechniqueList, deleteTechniqueDisabled, statusHidden, containerWidth, locateMarkupDisabled, onMarkupMouseUp, menuContextDisabled, cmlHidden, dmgHidden, setSelectedDrawing: customSetSelectedDrawing, selectedDrawing: customSelectedDrawing, techniqueCreateBtnHidden, displayLastQualReadingWithColor, latestEventItems, fadedItems }: TDrawingViewer) {
  const prms = useUserPermissions()
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const location = useLocation()
  const lastMarkupPositions = useRef<Record<string, Partial<Record<keyof TMarkupObjects, fabric.Point>>>>({})
  const canvasMenuContextRef = React.createRef<HTMLDivElement>()
  const eventState = useEventStore()
  const notificationState = useNotificationStore()
  const flocState = useFlocStore()
  const [techniquesBlockShown, setTechniquesBlockShown] = useState<boolean>(false)
  const { notificationDamages, lastDamageUpdated: lastNotifDamageUpdated, lastDamageUpdatedAt: lastNotifDamageUpdatedAt } = notificationState
  const { cmls: flocCmls, damages: flocDamages, latestEventCmls: flocLatestEventCmls, latestEventDamages: flocLatestEventDamages } = flocState
  const { filteredEventCmls, filteredEventDamages, eventCmls, eventDamages, eventFlocCmlFilter, filteredEventFlocCmls, filteredEventFlocDamages, eventFlocDamageFilter, lastCmlUpdated, lastDamageUpdated, lastDamageUpdatedAt, lastCmlUpdatedAt, eventInspectionDrawings } = eventState
  const clickedMarkupPosition = useRef<fabric.Point>()
  const [storeInit, setStoreInit] = useState<boolean>()
  const { setCanvasInfos, setCanvas, setBgInfos, canvas, bgImg, allMarkups, viewerState, bgDimension, setViewerState, setScale, scale, uploadFile, flocToDelete, gridToDelete, selectedTab, cmls, setCmls, damages, setDamages, addCmlMarkup, addDamageMarkup, setActiveMarkupId, deleteInspectionDrawingModalOpen, activeInspectionDrawing, activeMarkupId, removeCmlMarkup, removeDamageMarkup, lastCmlUpdated: lastIdwgCmlUpdated, lastDamageUpdated: lastIdwgDamageUpdated, lastDamageUpdatedAt: lastIdwgDamageUpdatedAt, lastCmlUpdatedAt: lastIdwgCmlUpdatedAt, rightSideWidth: idwgContainerWidth, setIdwgTechniqueIdsHidden, idwgTechniqueIdsHidden, latestEventCmls, latestEventDamages } = useIdwgStore(storeId ?? `${IDWG_PREFIX}${idwg.id}`, {
    activeInspectionDrawing: idwg,
  })
  const objectItemId = objectItem && objectItem.__typename !== 'InspectionDrawing' ? `${objectItem?.__typename}${objectItem?.id}` : null
  const objectItemState: EventState | NotificationState | FlocState | undefined = useMemo(() => {
    if (objectItem?.__typename === 'Event') {
      return eventState
    }

    if (objectItem?.__typename === 'Notification') {
      return notificationState
    }

    if (objectItem?.__typename === 'FunctionalLocation') {
      return flocState
    }

    return undefined
  }, [objectItemId, eventState, notificationState, flocState])

  const eventIdwg = eventInspectionDrawings?.find(evtIdwg => evtIdwg.idwgId === idwg.id)

  const [selectedDrawing, rightSideWidth, rightSideTabSelectedValue, setSelectedDrawing] = useMemo(() => [isSelected ? idwg : typeof customSelectedDrawing === 'undefined' ? objectItemState?.selectedDrawing : customSelectedDrawing, containerWidth ?? objectItemState?.rightSideWidth, objectItemState?.rightSideTabSelectedValue, customSetSelectedDrawing ?? objectItemState?.setSelectedDrawing, objectItemState?.nextSelectedDrawing, objectItemState?.prevSelectedDrawing], [objectItemState, isSelected, idwg, customSetSelectedDrawing, customSelectedDrawing, containerWidth])

  const idwgViewerWidth = useMemo(() => rightSideWidth ?? idwgContainerWidth, [rightSideWidth, idwgContainerWidth])

  const [isDrawingsTabSelected, isSelectedDrawing] = useMemo(() => [!!objectItem && ((objectItem.__typename === 'Event' && rightSideTabSelectedValue === EWorkpackRightSideTab.DRAWINGS) || (objectItem.__typename === 'Notification' && rightSideTabSelectedValue === EIanRightSideTab.DRAWINGS) || objectItem.__typename === 'InspectionDrawing' || (objectItem.__typename === 'FunctionalLocation' && rightSideTabSelectedValue === EFlocRightSideTab.DRAWINGS)), isSelected || selectedDrawing?.id === idwg.id! || (typeof selectedDrawing === 'undefined' && !objectItem)], [rightSideTabSelectedValue, objectItemState, isSelected, idwg, objectItemState, objectItemId])
  const { cmlMarkupsShown, damageMarkupsShown, changeCmlMarkupsDisplay, changeDamageMarkupsDisplay } = useIdwgStore()
  const { updateDamage, createNewDamageMarkup, setActiveDamage } = useDamageStore()
  const { updateCml, createNewCmlMarkup, setActiveCml } = useCmlStore()
  const [canvasMenuContextShown, setCanvasMenuContextShown] = useState<boolean>(false)
  const [viewerSize, setViewerSize] = useState<number | undefined>()
  const { docName } = activeInspectionDrawing ?? idwg ?? {}
  const panningInfo = useRef<TPanningInfo>({ isDragging: false, x: 0, y: 0 })
  const canvasObj = useRef<fabric.Canvas>()
  const viewerRef = useRef<ReactZoomPanPinchRef>()
  canvasRef = canvasRef ?? React.createRef<HTMLCanvasElement>()
  canvasContainerRef = canvasContainerRef ?? React.createRef<HTMLDivElement>()
  const { getAccessToken } = useHolisAuth()
  const [svgSrc, setSvgSrc] = useState<string | null>()
  const refNewMarkupPosition = useRef<fabric.Point>()
  const refCreateNewMarkup = useRef<boolean>(false)
  const [error, setError] = useState<React.ReactNode>()
  const [loading, setLoading] = useState<boolean>(false)
  const [updateCmlByIdApi] = useMutation<UpdateCmlByIdMutation>(CMLS_UPDATE_BY_ID)
  const [updateDamageByIdApi] = useMutation<UpdateDamageByIdMutation>(DAMAGES_UPDATE_BY_ID)
  const refDamageValCodesResult = useQuery<GetAllRefDamageValCodesQuery>(DAMAGE_REF_VAL_CODES_GET_MANY)
  const [getLatestEventCml] = useLazyQuery<GetLatestEventCmlByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID)
  const [getLatestEventDamage] = useLazyQuery<GetLatestEventDamageByDmgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID)
  const [getLatestEventCmlWithQualReadingNonEmptyByCmlIdApi] = useLazyQuery<GetLatestEventCmlWithQualReadingNonEmptyByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_CML_ID)
  const [getLatestEventDamageWithQualReadingNonEmptyByDmgIdApi] = useLazyQuery<GetLatestEventDamageWithQualReadingNonEmptyByDmgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_DMG_ID)

  const updateDamageDataField = async (id: number, field: keyof DamageUncheckedUpdateInput, value: number | string): Promise<boolean> => updateDamageByIdApi({
    variables: {
      id,
      data: {
        [field]: {
          set: value,
        },
      },
    },
  }).then((res: FetchResult<UpdateDamageByIdMutation>) => {
    if (!res.errors) {
      updateDamage(res.data?.updateOneDamage as Partial<Damage>)
      AppNotifications.success(t('message.success.damageUpdated'))
      return true
    }

    const errorMessage: string = t('message.error.default.title')
    AppNotifications.error(errorMessage)
    return false
  }).catch(() => {
    const errorMessage: string = t('message.error.default.title')
    AppNotifications.error(errorMessage)
    return false
  })
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: sortableId ?? `${idwg.id}` })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const refCmlValCodesResult = useQuery<GetAllRefCmlValCodesQuery>(CML_REF_VAL_CODES_GET_MANY)
  const refIntegrityConditionsResult = useQuery<GetAllRefIntegrityConditionsQuery>(INTEGRITY_REF_CONDITIONS_GET_MANY)
  const updateCmlDataField = async (id: number, field: keyof CmlUncheckedUpdateInput, value: number | string): Promise<boolean> => updateCmlByIdApi({
    variables: {
      id,
      data: {
        [field]: {
          set: value,
        },
      },
    },
  }).then((res: FetchResult<UpdateCmlByIdMutation>) => {
    if (!res.errors) {
      updateCml(res.data?.updateOneCml as Partial<Cml>)
      AppNotifications.success(t('message.success.cmlUpdated'))
      return true
    }

    const errorMessage: string = t('message.error.default.title')
    AppNotifications.error(errorMessage)
    return false
  }).catch(() => {
    const errorMessage: string = t('message.error.default.title')
    AppNotifications.error(errorMessage)
    return false
  })

  const fitToScreen = (state?: ReactZoomPanPinchState) => {
    setTimeout(() => {
      setViewerState({
        ...state,
        zoomPoint: originPoint,
        scale: 1,
      })
    }, 100)
  }

  const getInspectionStatusBgColor = (status?: string | null) => {
    switch (status) {
      case EInspectionStatus.COMPLETED:
        return 'bg-green-500'
      case EInspectionStatus.HOLD:
        return 'bg-gray-500'
      case EInspectionStatus.PROCESSING:
        return 'bg-blue-500'
      default:
        return 'bg-transparent'
    }
  }

  const handleScreenFitClick = (canvasRef?: React.RefObject<HTMLCanvasElement>, transformComponentRef?: ReactZoomPanPinchRef) => {
    panningInfo.current.isDragging = false
    if (transformComponentRef) {
      canvas?.absolutePan(originPoint)
      transformComponentRef.setTransform(0, 0, 1)
    }

    fitToScreen(transformComponentRef?.state)
  }

  const loadBgImg = async (svgUrl: string, originalDimension?: TDimension, isCurrentBg?: boolean) => {
    setLoading(true)
    const imgDoc = new Image()
    imgDoc.onload = () => {
      setBgInfos(imgDoc, { width: originalDimension?.width ?? imgDoc.width, height: originalDimension?.height ?? imgDoc.height }, !!isCurrentBg)
    }

    imgDoc.onerror = (_event: unknown, _source?: string, _lineno?: number, _colno?: number, error?: Error) => {
      hideLoading(() => {
        setError(error?.message ?? true)
      })
    }

    const imgContent = await createBase64FromUrl(svgUrl, { getAccessToken, onFail(err) {
      setError(err instanceof AxiosError && err.status === 404 ? t('message.error.fileNotFound') : true)
    } })
    if (imgContent) {
      imgDoc.src = imgContent
    } else {
      imgDoc.src = ''
    }
  }

  const handleMouseDownClickOnFabricCanvas = (cEvent: fabric.CanvasPointerEvents['mouse:down']) => {
    const mouseType = (cEvent.e as { button?: number }).button
    const position = cEvent.scenePoint
    panningInfo.current.x = (cEvent.e as MouseEvent).clientX
    panningInfo.current.y = (cEvent.e as MouseEvent).clientY
    if (mouseType === 2 && canvas?.backgroundImage) {
      // Disable menu context if out of box
      if (position.x < 0 || position.x > canvas.backgroundImage?.width || position.y < 0 || position.y > canvas.backgroundImage?.height) {
        return
      }

      cEvent.e.preventDefault()
      cEvent.e.stopPropagation()
      cEvent.e.stopImmediatePropagation()
    } else if (cEvent.target) {
      cEvent.e.preventDefault()
      cEvent.e.stopPropagation()
      cEvent.e.stopImmediatePropagation()
    } else if (panningInfo.current.isDragging) {
      panningInfo.current.isDragging = false
    } else {
      panningInfo.current.isDragging = true
    }

    if (refCreateNewMarkup.current === true) {
      refCreateNewMarkup.current = false
    }

    if (mouseType === 2) {
      refNewMarkupPosition.current = cEvent.scenePoint

      if (canvas) {
        canvas.setCursor('crosshair')
        canvas.renderAll()
      }
    }

    setCanvasMenuContextShown(false)
    clickedMarkupPosition.current = cEvent.viewportPoint
  }

  const handleContextMenuDrawing = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.preventDefault()
    e.nativeEvent.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }

  // Canculation panning position + boundary zone to pan
  const handleMouseMoveOnFabricCanvas = (cEvent: fabric.CanvasPointerEvents['mouse:move']) => {
    if (panningInfo.current.isDragging && scale !== canvas?.getZoom()) {
      const { e } = cEvent
      const vpt = canvas!.viewportTransform
      const currentPosX = panningInfo.current.x
      const currentPosY = panningInfo.current.y
      const currentZoom = canvas!.getZoom()
      let newPosX = (e as MouseEvent).clientX
      let newPosY = (e as MouseEvent).clientY
      let newX = vpt[4] + newPosX - currentPosX
      let newY = vpt[5] + newPosY - currentPosY
      // Check horizontal boundary
      if (newX > 20) {
        newX = 20
      } else if (newX < (canvas!.getWidth() * (1 - (currentZoom / scale))) - 20) {
        newX = (canvas!.getWidth() * (1 - (currentZoom / scale))) - 20
      }

      newPosX = newX + currentPosX - vpt[4]

      // Check vertical boundary
      if (newY > 20) {
        newY = 20
      } else if (newY < (canvas!.getHeight() * (1 - (currentZoom / scale))) - 20) {
        newY = (canvas!.getHeight() * (1 - (currentZoom / scale))) - 20
      }

      newPosY = newY + currentPosY - vpt[5]
      vpt[4] = newX
      vpt[5] = newY
      canvas!.renderAll()
      panningInfo.current.x = newPosX
      panningInfo.current.y = newPosY
    }
  }

  const handleMouseUpClickOnFabricCanvas = (cEvent: fabric.CanvasPointerEvents['mouse:up']) => {
    if ((cEvent.e as { button?: number }).button === 2 && canvas?.backgroundImage) {
      const position = cEvent.scenePoint
      // Disable menu context if out of box
      if (position.x < 0 || position.x > canvas.backgroundImage.width || position.y < 0 || position.y > canvas.backgroundImage.height) {
        return
      }

      cEvent.e.preventDefault()
      cEvent.e.stopPropagation()
      cEvent.e.stopImmediatePropagation()
      if (refCreateNewMarkup.current === false) {
        refCreateNewMarkup.current = true
        if (canvasMenuContextRef.current) {
          canvasMenuContextRef.current.style.position = 'fixed'
          canvasMenuContextRef.current.style.left = `${(cEvent.e as { clientX: number }).clientX}px`
          canvasMenuContextRef.current.style.top = `${(cEvent.e as { clientY: number }).clientY}px`
        }

        setCanvasMenuContextShown(true)
      }

      if (canvas) {
        canvas.setCursor('inherit')
        canvas.renderAll()
      }
    }

    if ((cEvent.e as { button?: number }).button === 0) {
      panningInfo.current.isDragging = false
      if (activeMarkupId && clickedMarkupPosition.current?.eq(cEvent.viewportPoint)) {
        // Open item modal.
        const [itemType, itemIdStr] = activeMarkupId.split('-')
        const itemId = Number(itemIdStr)
        switch (itemType) {
          case 'dmg':
          { const damage = damages?.find((item: Partial<Damage>) => item.id === itemId)
            if (damage) {
              (onMarkupMouseUp ?? setActiveDamage)(damage)
            }

            break }
          case 'cml':
          { const cml = cmls?.find((item: Partial<Cml>) => item.id === itemId)
            if (cml) {
              (onMarkupMouseUp ?? setActiveCml)(cml)
            }

            break }
          default:
            break
        }
      }
    }

    clickedMarkupPosition.current = undefined
  }

  const handleMouseWheelOnFabricCanvas = (cEvent: fabric.CanvasPointerEvents['mouse:wheel']) => {
    const delta = cEvent.e.deltaY
    const zoomSpeed = 0.035
    let zoom = canvas!.getZoom()
    const minScaleUp = delta < 0 ? 1 + zoomSpeed : 1 - zoomSpeed
    zoom *= minScaleUp
    const maxScale = Math.max(scale * 4, 16)

    if (zoom > maxScale) {
      zoom = maxScale
    }

    if (zoom <= scale) {
      zoom = scale
      resetCanvasTransform()
    } else {
      canvas!.zoomToPoint(new fabric.Point(cEvent.e.offsetX, cEvent.e.offsetY), zoom)
    }

    cEvent.e.preventDefault()
    cEvent.e.stopPropagation()
    const vpt = canvas!.viewportTransform
    if (vpt[4] > 20) {
      vpt[4] = 20
    } else if (vpt[4] < (canvas!.getWidth() * (1 - (zoom / scale))) - 20) {
      vpt[4] = (canvas!.getWidth() * (1 - (zoom / scale))) - 20
    }

    if (vpt[5] > 20) {
      vpt[5] = 20
    } else if (vpt[5] < (canvas!.getHeight() * (1 - (zoom / scale))) - 20) {
      vpt[5] = (canvas!.getHeight() * (1 - (zoom / scale))) - 20
    }
  }

  const handleDblClickOnFabricCanvas = () => {
    resetCanvasTransform()
  }

  const resetCanvasTransform = () => {
    canvas?.setViewportTransform([scale, 0, 0, scale, 0, 0])
  }

  const hideLoading = (callback?: () => void, timeInMs?: number) => {
    setTimeout(() => {
      setLoading(false)
      callback?.()
    }, timeInMs ?? 500)
  }

  const showOrAddCmlMarkup = async (item: Partial<Cml>, markupsDisplayed?: boolean, replaced?: boolean) => {
    if (canvas && refCmlValCodesResult.data && !refCmlValCodesResult.loading && !refCmlValCodesResult.error && refIntegrityConditionsResult.data && !refIntegrityConditionsResult.loading && !refIntegrityConditionsResult.error) {
      const markupId = `${CML_PREFIX}${item.id!}`
      let markups: TMarkupObjects | null = null
      if (allMarkups && Object.keys(allMarkups).includes(markupId)) {
        markups = allMarkups[markupId]
        if (replaced) {
          canvas.remove(...Object.values(markups))
          markups = null
        }
      }

      if (!markups) {
        const coordinates = item.coordinates2d ? JSON.parse(item.coordinates2d) : null
        let markupColor: string = DEFAULT_SHAPE_COLOR
        let latestEventCml: Partial<EventCml> | null | undefined = null
        if (objectItem && objectItem.__typename === 'Event' && !displayLastQualReadingWithColor) {
          latestEventCml = eventCmls?.find((evtCml: Partial<EventCml>) => evtCml.cmlId === item.id!)
        } else {
          if (typeof latestEventItems !== 'undefined') {
            latestEventCml = latestEventItems?.[item.id!]?.__typename === 'EventCml' ? (latestEventItems?.[item.id!] as Partial<EventCml>) : null
          } else if (objectItem?.__typename === 'InspectionDrawing') {
            latestEventCml = latestEventCmls?.[item.id!] ?? null
          } else if (objectItem?.__typename === 'FunctionalLocation') {
            latestEventCml = flocLatestEventCmls?.[item.id!] ?? null
          } else {
            const latestEventCmlResult = await getLatestEventCml({
              variables: {
                cmlId: item.id!,
              },
              fetchPolicy: 'no-cache',
            })
            latestEventCml = latestEventCmlResult.data?.findFirstEventCml as Partial<EventCml> | null
          }

          if (latestEventCml?.id && (!latestEventCml?.qualReadingId || latestEventCml.qualReading?.codeCond === '00')) {
            const latestEventCmlWithQualReadingResult = await getLatestEventCmlWithQualReadingNonEmptyByCmlIdApi({
              variables: {
                cmlId: item.id!,
              },
              fetchPolicy: 'no-cache',
            })
            latestEventCml = latestEventCmlWithQualReadingResult.data?.lastEventCmlWithQualReading as Partial<EventCml> | null
          }
        }

        if (latestEventCml) {
          const { qualReading } = latestEventCml ?? {}
          if (qualReading) {
            const codeCond = (refCmlValCodesResult.data.refCmlValCodes as Partial<RefCmlValCode>[]).find((itemValCode: Partial<RefCmlValCode>) => itemValCode.id === qualReading.id)?.codeCond
            if (codeCond) {
              const shapeColor = (refIntegrityConditionsResult.data.refIntegrityConditions as Partial<RefIntegrityCondition>[]).find((itemInteCond: Partial<RefIntegrityCondition>) => itemInteCond.condition === codeCond)?.color
              if (typeof shapeColor === 'string') {
                markupColor = tailwindColorToHex(shapeColor, '400')
              }
            }
          }
        }

        const position = coordinates && coordinates.x && coordinates.y ? new fabric.Point(coordinates.x, coordinates.y) : new fabric.Point(0, 0)
        const markerCoordinates = item.markerCoordinates2d ? JSON.parse(item.markerCoordinates2d) : null
        const markerPosition = markerCoordinates && markerCoordinates.x && markerCoordinates.y ? new fabric.Point(markerCoordinates.x, markerCoordinates.y) : new fabric.Point(0, 0)

        // Get Icon svg content from icon component
        const markupsProps = {
          markerProps: [
            {
              visible: !FIRST_OBJ_INVISIBLE_LIST.includes(item.codeGroup?.shape ?? ''),
              evented: !FIRST_OBJ_INVISIBLE_LIST.includes(item.codeGroup?.shape ?? ''),
              fill: markupColor,
              stroke: markupColor,
              ...LOCK_RESIZE_ROTATE,
            },
            {
              fill: markupColor,
              stroke: markupColor,
              ...LOCK_RESIZE_ROTATE,
            },
          ],
          markerGroupProps: {
            fill: markupColor,
            stroke: markupColor,
            ...LOCK_RESIZE_ROTATE,
          },
          tooltipProps: {
            fill: colors.gray[700],
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 7,
            fontFamily: 'Montserrat',
            ...LOCK_RESIZE_ROTATE,
            verticalPosition: EVerticalPosition.MIDDLE,
          },
          positionerProps: {
            fill: 'transparent',
            stroke: 'transparent',
            ...LOCK_RESIZE_ROTATE,
          },
          lineProps: {
            ...LOCK_RESIZE_ROTATE,
          },
        }
        const markerSvg = reactElementToString(<FormIcon size={30} form={item.codeGroup?.shape ?? undefined} />)
        markups = await createSvgAnnotation(canvas, position, 10, markerSvg, markerPosition, 35, 35, item.position ?? '', {
          props: markupsProps,
          events: {
            onObjectMouseOver(_, o) {
              setActiveMarkupId(`${CML_PREFIX}${item.id!}`)

              const isItemFaded = o?.opacity && o.opacity !== 1
              if (locateMarkupDisabled && onMarkupMouseUp && isItemFaded) {
                o?.set('opacity', FADED_ITEM_OPACITY_HOVER)
                canvas?.renderAll()
              }
            },
            onObjectMouseOut(_, o) {
              setActiveMarkupId(undefined)

              const isItemFaded = o?.opacity && o.opacity !== 1
              if (locateMarkupDisabled && onMarkupMouseUp && isItemFaded) {
                o?.set('opacity', FADED_ITEM_OPACITY)
                canvas?.renderAll()
              }
            },
            onObjectMoved(e: fabric.ObjectPointerEvents['mouseup:before'], o?: fabric.FabricObject) {
              if (locateMarkupDisabled) {
                return
              }

              handleObjectMoved(item, canvas, markups!, lastMarkupPositions, e, o, CML_PREFIX, 'markerCoordinates2d', 'coordinates2d', (id: unknown, field: unknown, value: unknown) => updateCmlDataField(id as number, field as keyof CmlUncheckedUpdateInput, value as (number | string)), updateLastMarkupPosition, markupsProps)
            },
          },
        }, false, locateMarkupDisabled)
      }

      if (markups) {
        Object.values(markups).forEach((o: TFabricObjectWithPrivate) => {
          o.visible = markupsDisplayed !== false && !!item.display2d && !idwgTechniqueIdsHidden?.includes(item.techniqueId ?? NO_TECHNIQUE_ID)
        })
        addCmlMarkup(item, markups)

        Object.entries(markups).forEach(([key, value]) => {
          updateLastMarkupPosition(lastMarkupPositions, `${CML_PREFIX}${item.id!}`, key as keyof TMarkupObjects, value.getCenterPoint())

          const isItemFaded = fadedItems?.includes(`${item.__typename}_${item.id}`)
          value.set('opacity', isItemFaded ? FADED_ITEM_OPACITY : undefined)
          if (key === 'marker') {
            value.hoverCursor = locateMarkupDisabled && onMarkupMouseUp ? 'pointer' : null
          }
        })
      }
    }
  }

  const showOrAddCmlMarkups = async (markupsDisplayed?: boolean) => {
    if (cmls && canvas && refCmlValCodesResult.data && !refCmlValCodesResult.loading && !refCmlValCodesResult.error && refIntegrityConditionsResult.data && !refIntegrityConditionsResult.loading && !refIntegrityConditionsResult.error) {
      for (let i = 0; i < cmls.length; i++) {
        const item: Partial<Cml> = cmls[i]
        await showOrAddCmlMarkup(item, markupsDisplayed)
      }
    }
  }

  const showOrAddDamageMarkup = async (item: Partial<Damage>, markupsDisplayed?: boolean, replaced?: boolean) => {
    if (canvas && refDamageValCodesResult.data && !refDamageValCodesResult.loading && !refDamageValCodesResult.error && refIntegrityConditionsResult.data && !refIntegrityConditionsResult.loading && !refIntegrityConditionsResult.error) {
      const markupId = `${DAMAGE_PREFIX}${item.id!}`
      let markups: TMarkupObjects | null = null
      if (allMarkups && Object.keys(allMarkups).includes(markupId)) {
        markups = allMarkups[markupId]
        if (replaced) {
          canvas.remove(...Object.values(markups))
          markups = null
        }
      }

      let qualReading: Partial<RefDamageValCode> | null | undefined = null
      if (!markups) {
        let markupColor: string = DEFAULT_SHAPE_COLOR
        if (objectItem && objectItem.__typename === 'Event' && !displayLastQualReadingWithColor) {
          qualReading = eventDamages?.find((evtDamage: Partial<EventDamage>) => evtDamage.dmgeId === item.id!)?.qualReading
        } else if (objectItem && objectItem.__typename === 'Notification' && !displayLastQualReadingWithColor) {
          qualReading = notificationDamages?.find((evtDamage: Partial<NotificationDamage>) => evtDamage.dmgeId === item.id!)?.qualReading
        } else {
          let latestEventDamage
          if (typeof latestEventItems !== 'undefined') {
            latestEventDamage = latestEventItems?.[item.id!]?.__typename === 'EventDamage' ? (latestEventItems?.[item.id!] as Partial<EventDamage>) : null
          } else if (objectItem?.__typename === 'InspectionDrawing') {
            latestEventDamage = latestEventDamages?.[item.id!] ?? null
          } else if (objectItem?.__typename === 'FunctionalLocation') {
            latestEventDamage = flocLatestEventDamages?.[item.id!] ?? null
          } else {
            const latestEventDamageResult = await getLatestEventDamage({
              variables: {
                dmgId: item.id!,
              },
              fetchPolicy: 'no-cache',
            })
            latestEventDamage = latestEventDamageResult.data?.findFirstEventDamage as Partial<EventDamage> | null
          }

          if (latestEventDamage?.id && (!latestEventDamage.qualReadingId || latestEventDamage.qualReading?.codeCond === '00')) {
            const latestEventDamageWithQualReadingResult = await getLatestEventDamageWithQualReadingNonEmptyByDmgIdApi({
              variables: {
                dmgId: item.id!,
              },
              fetchPolicy: 'no-cache',
            })
            latestEventDamage = (latestEventDamageWithQualReadingResult.data?.lastEventDamageWithQualReading as EventDamage | null)
          }

          qualReading = latestEventDamage?.qualReading
        }

        if (qualReading) {
          const codeCond = (refDamageValCodesResult.data!.refDamageValCodes as Partial<RefDamageValCode>[]).find((itemValCode: Partial<RefDamageValCode>) => itemValCode.id === qualReading!.id)?.codeCond
          if (codeCond) {
            const shapeColor = (refIntegrityConditionsResult.data!.refIntegrityConditions as Partial<RefIntegrityCondition>[]).find((itemInteCond: Partial<RefIntegrityCondition>) => itemInteCond.condition === codeCond)?.color
            if (typeof shapeColor === 'string') {
              markupColor = tailwindColorToHex(shapeColor, '400')
            }
          }
        }

        const markerElemId = Object.keys(EDMGMarkupForm).includes(item.codeGroup?.shape ?? '') ? MARKUP_BACKGROUND_ID : undefined
        const coordinates = item.coordinates2d ? JSON.parse(item.coordinates2d) : null
        const position = coordinates && coordinates.x && coordinates.y ? new fabric.Point(coordinates.x, coordinates.y) : new fabric.Point(0, 0)
        const markerCoordinates = item.markerCoordinates2d ? JSON.parse(item.markerCoordinates2d) : null
        const markerPosition = markerCoordinates && markerCoordinates.x && markerCoordinates.y ? new fabric.Point(markerCoordinates.x, markerCoordinates.y) : new fabric.Point(0, 0)

        // Get Icon svg content from icon component
        const markerSvg = reactElementToString(<FormIcon size={25} form={item.codeGroup?.shape ?? undefined} />)
        const markupsProps = {
          markerElemId,
          markerProps: [
            {
              visible: !FIRST_OBJ_INVISIBLE_LIST.includes(item.codeGroup?.shape ?? ''),
              evented: !FIRST_OBJ_INVISIBLE_LIST.includes(item.codeGroup?.shape ?? ''),
              fill: markupColor,
              stroke: markupColor,
              ...LOCK_RESIZE_ROTATE,
            },
            {
              fill: markupColor,
              stroke: markupColor,
              ...LOCK_RESIZE_ROTATE,
            },
          ],
          markerGroupProps: {
            fill: markupColor,
            stroke: markupColor,
            ...LOCK_RESIZE_ROTATE,
          },
          tooltipProps: {
            fill: colors.gray[700],
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 7,
            fontFamily: 'Montserrat',
            ...LOCK_RESIZE_ROTATE,
            verticalPosition: EVerticalPosition.BOTTOM,
          },
          positionerProps: {
            fill: 'transparent',
            stroke: 'transparent',
            ...LOCK_RESIZE_ROTATE,
          },
          lineProps: {
            ...LOCK_RESIZE_ROTATE,
          },
        }

        markups = await createSvgAnnotation(canvas!, position, 10, markerSvg, markerPosition, 35, 35, item.position ?? '', {
          props: markupsProps,
          events: {
            onObjectMouseOver(_, o) {
              setActiveMarkupId(`${DAMAGE_PREFIX}${item.id!}`)

              const isItemFaded = o?.opacity && o.opacity !== 1
              if (locateMarkupDisabled && onMarkupMouseUp && isItemFaded) {
                o?.set('opacity', FADED_ITEM_OPACITY_HOVER)
                canvas?.renderAll()
              }
            },
            onObjectMouseOut(_, o) {
              setActiveMarkupId(undefined)

              const isItemFaded = o?.opacity && o.opacity !== 1
              if (locateMarkupDisabled && onMarkupMouseUp && isItemFaded) {
                o?.set('opacity', FADED_ITEM_OPACITY)
                canvas?.renderAll()
              }
            },
            onObjectMoved(e: fabric.ObjectPointerEvents['mouseup:before'], o?: fabric.FabricObject) {
              if (locateMarkupDisabled) {
                return
              }

              handleObjectMoved(item, canvas!, markups!, lastMarkupPositions, e, o, DAMAGE_PREFIX, 'markerCoordinates2d', 'coordinates2d', (id: unknown, field: unknown, value: unknown) => updateDamageDataField(id as number, field as keyof DamageUncheckedUpdateInput, value as (number | string)), updateLastMarkupPosition, markupsProps)
            },
          },
        }, false, locateMarkupDisabled)
      }

      if (markups) {
        Object.values(markups).forEach((o: TFabricObjectWithPrivate) => {
          o.visible = markupsDisplayed !== false && !!item.display2d && !idwgTechniqueIdsHidden?.includes(item.techniqueId ?? NO_TECHNIQUE_ID)
        })
        addDamageMarkup(item, markups)
        Object.entries(markups).forEach(([key, value]) => {
          updateLastMarkupPosition(lastMarkupPositions, `${DAMAGE_PREFIX}${item.id!}`, key as keyof TMarkupObjects, value.getCenterPoint())

          const isItemFaded = fadedItems?.includes(`${item.__typename}_${item.id}`)
          value.set('opacity', isItemFaded ? FADED_ITEM_OPACITY : undefined)
          if (key === 'marker') {
            value.hoverCursor = locateMarkupDisabled && onMarkupMouseUp ? 'pointer' : null
          }
        })
      }
    }
  }

  const showOrAddDamageMarkups = async (markupsDisplayed?: boolean) => {
    if (damages && canvas && refDamageValCodesResult.data && !refDamageValCodesResult.loading && !refDamageValCodesResult.error && refIntegrityConditionsResult.data && !refIntegrityConditionsResult.loading && !refIntegrityConditionsResult.error) {
      for (let i = 0; i < damages.length; i++) {
        const item: Partial<Damage> = damages[i]
        await showOrAddDamageMarkup(item, markupsDisplayed)
      }
    }
  }

  const handleCanvasMenuContextClick = (type: number) => {
    if (type === TAB_CMLS) {
      createNewCmlMarkup(refNewMarkupPosition.current!, idwg, objectItem?.__typename === 'Event')
    } else if (type === TAB_DAMAGES) {
      createNewDamageMarkup(refNewMarkupPosition.current!, idwg)
    }

    setCanvasMenuContextShown(false)
  }

  const calculateFitScaleAndSize = (bgDimension: TDimension, canvasContainer: HTMLDivElement, bgImg?: HTMLImageElement | HTMLCanvasElement) => {
    const bgWidth = bgDimension.width!
    const bgHeight = bgDimension.height!
    let newWidth = canvasContainer.clientWidth - (techniquesBlockShown ? 60 : 10)
    let newHeight = canvasContainer.clientHeight
    const bgRatio = bgWidth / bgHeight
    if (newWidth < newHeight * bgRatio) {
      newHeight = Math.floor(newWidth / bgRatio)
    }

    newWidth = newHeight * bgRatio
    const bgScale = bgImg ? Math.min(bgWidth / bgImg.width, bgHeight / bgImg.height) : Math.min(newWidth / bgWidth, newHeight / bgHeight)
    return { newWidth, newHeight, bgScale }
  }

  const menuContextItems: TListItem[] = useMemo(() => [
    ...(objectItem?.__typename !== 'Notification'
      ? [{
          value: TAB_CMLS,
          label: t('label.createCml'),
          disabled: (objectItem?.__typename === 'Event' && config.getActionIsDisabled('event', 'cmlCreate', objectItem.status))
            || !prms.cmls.create,
        }]
      : []),
    ...((!objectItem || hideCreateDamage)
      ? []
      : [{
          value: TAB_DAMAGES,
          label: t('label.createDamage'),
          disabled: (objectItem?.__typename === 'Event' && config.getActionIsDisabled('event', 'damageCreate', objectItem.status))
            || !prms.damages.create,
        }]),
  ], [objectItemId, prms.cmls.create, prms.damages.create])

  const onBackgroundImgChanged = () => {
    if (storeInit && canvas && canvasContainerRef?.current && viewerSize) {
      if (bgImg && bgDimension) {
        // Scale background fit to current width, height
        const { newWidth, newHeight, bgScale } = calculateFitScaleAndSize(bgDimension, canvasContainerRef.current, bgImg)
        const backgroundImg = new fabric.FabricImage(bgImg, {
          selectable: false,
          evented: false,
          selection: false,
          scaleX: bgScale,
          scaleY: bgScale,
          imageSmoothing: false,
          objectCaching: false,
          noScaleCache: true,
        })
        canvas.set('backgroundImage', backgroundImg)
        setScale(bgScale)
        setCanvasInfos(canvas, newWidth, newHeight)
        hideLoading(() => {
          setError(null)
          handleScreenFitClick(canvasRef, viewerRef.current)
        })
      } else if (error) {
        hideLoading(() => {
          canvas?.set('backgroundImage', null)
        })
      }
    }
  }

  useEffect(() => {
    if (objectItemId) {
      deleteIdwgStore(storeId ?? `${IDWG_PREFIX}${idwg.id}`)
    }

    setStoreInit(true)
  }, [location, storeId])

  useEffect(() => {
    if (storeInit && !dmgHidden && !loading) {
      if (!objectItemId || isDrawingsTabSelected) {
        if (selectedTab === TAB_DAMAGES) {
          showOrAddDamageMarkups()
        } else if (!damageMarkupsShown) {
          showOrAddDamageMarkups(false)
        } else {
          showOrAddDamageMarkups()
        }
      }
    }
  }, [damages, canvas, refIntegrityConditionsResult, refDamageValCodesResult, selectedTab, damageMarkupsShown, isDrawingsTabSelected, objectItemId, storeInit, loading, idwgTechniqueIdsHidden, dmgHidden, latestEventItems, fadedItems])

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'Event') {
      if (eventFlocCmlFilter === EEventFlocObjectFilter.FLOC) {
        setCmls(filteredEventFlocCmls?.filter((item: Partial<Cml>) => item.idwgId === idwg.id) ?? [])
      } else {
        setCmls((filteredEventCmls?.filter((item: Partial<EventCml>) => item.cml?.idwgId === idwg.id).map((item: Partial<EventCml>) => item.cml) ?? []) as Partial<Cml>[])
      }
    }
  }, [filteredEventCmls, filteredEventFlocCmls, eventFlocCmlFilter, objectItemId, storeInit])

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'FunctionalLocation') {
      setCmls(flocCmls?.filter((item: Partial<Cml>) => item.idwgId === idwg.id) ?? [])
    }
  }, [flocCmls, storeInit, objectItemId])

  useEffect(() => {
    if ((!objectItemId || idwgCmls?.length) && storeInit && objectItem?.__typename !== 'InspectionDrawing') {
      setCmls(idwgCmls ?? [])
    }
  }, [idwgCmls, objectItemId, storeInit])

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'Event') {
      if (eventFlocDamageFilter === EEventFlocObjectFilter.FLOC) {
        setDamages(filteredEventFlocDamages?.filter((item: Partial<Damage>) => item.idwgId === idwg.id) ?? [])
      } else {
        setDamages((filteredEventDamages?.filter((item: Partial<EventDamage>) => item.damage?.idwgId === idwg.id).map((item: Partial<EventDamage>) => item.damage) ?? []) as Partial<Damage>[])
      }
    }
  }, [filteredEventDamages, eventFlocDamageFilter, filteredEventFlocDamages, objectItemId, storeInit])

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'Notification') {
      setDamages(notificationDamages?.map((item: Partial<NotificationDamage>) => item.damage as Partial<Damage>)?.filter((item: Partial<Damage>) => item.idwgId === idwg.id) ?? [])
    }
  }, [notificationDamages, objectItemId, storeInit])

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'FunctionalLocation') {
      setDamages(flocDamages?.filter((item: Partial<Damage>) => item.idwgId === idwg.id) ?? [])
    }
  }, [flocDamages, objectItemId, storeInit])

  useEffect(() => {
    if ((!objectItemId || idwgDamages?.length) && objectItem?.__typename !== 'InspectionDrawing' && storeInit) {
      setDamages(idwgDamages ?? [])
    }
  }, [idwgDamages, objectItemId, storeInit])

  // On cml / damage tabs changed
  useEffect(() => {
    if (storeInit && !cmlHidden && !loading && (!objectItem || (objectItem.__typename === 'Event' && rightSideTabSelectedValue === EWorkpackRightSideTab.DRAWINGS) || objectItem.__typename === 'InspectionDrawing' || (objectItem.__typename === 'FunctionalLocation' && rightSideTabSelectedValue === EFlocRightSideTab.DRAWINGS))) {
      if (selectedTab === TAB_CMLS) {
        showOrAddCmlMarkups()
      } else if (!cmlMarkupsShown) {
        showOrAddCmlMarkups(false)
      } else {
        showOrAddCmlMarkups()
      }
    }
  }, [cmls, canvas, refCmlValCodesResult, refIntegrityConditionsResult, selectedTab, cmlMarkupsShown, rightSideTabSelectedValue, objectItemId, storeInit, loading, idwgTechniqueIdsHidden, cmlHidden, latestEventItems, fadedItems])

  // Update svg src when docName has been changed
  useEffect(() => {
    const src = docName ? `${OptimusClientConfig.current.fileBaseUrl}/${docName}?type=${EDownloadFileType.DRAWING}&contentType=${EDrawingFileContentType.SVG}` : null
    setSvgSrc(src)
  }, [docName])

  // Load svg file as background if exists
  useEffect(() => {
    if (storeInit) {
      if (svgSrc) {
        loadBgImg(svgSrc, undefined, true)
      } else if (svgSrc === null) {
        hideLoading(() => {
          canvas?.set('backgroundImage', null)
          setError(t(idwg.id === NO_DRAWING_ID ? 'label.noDrawing' : 'message.error.fileNotFound'))
        })
      }
    }
  }, [svgSrc, storeInit])

  // Render canvas on markups changed and ready to show
  useEffect(() => {
    if (canvas && allMarkups && storeInit && (!objectItemId || isDrawingsTabSelected)) {
      canvas.requestRenderAll()
    }
  }, [canvas, allMarkups, rightSideTabSelectedValue, objectItemId, storeInit])

  useEffect(() => {
    resetCanvasTransform()
  }, [isSelectedDrawing])

  useEffect(() => {
    if (storeInit && canvas && isSelectedDrawing) {
      canvas.on('mouse:down', handleMouseDownClickOnFabricCanvas)
      canvas.on('mouse:up', handleMouseUpClickOnFabricCanvas)
      canvas.on('mouse:wheel', handleMouseWheelOnFabricCanvas)
      canvas.on('mouse:dblclick', handleDblClickOnFabricCanvas)
      canvas.on('mouse:move', handleMouseMoveOnFabricCanvas)
    }

    return () => {
      canvas?.off('mouse:down', handleMouseDownClickOnFabricCanvas)
      canvas?.off('mouse:up', handleMouseUpClickOnFabricCanvas)
      canvas?.off('mouse:wheel', handleMouseWheelOnFabricCanvas)
      canvas?.off('mouse:dblclick', handleDblClickOnFabricCanvas)
      canvas?.off('mouse:move', handleMouseMoveOnFabricCanvas)
    }
  }, [canvas, allMarkups, isSelectedDrawing, canvasMenuContextRef, rightSideTabSelectedValue, storeInit, viewerSize])

  // Init canvas object and load svg file if exists
  useEffect(() => {
    if (storeInit) {
      if (!canvasObj.current && canvasContainerRef?.current && canvasRef?.current) {
        const newWidth = canvasContainerRef.current.clientWidth
        const newHeight = canvasContainerRef.current.clientHeight
        const cv: fabric.Canvas = new fabric.Canvas(canvasRef.current!, {
          preserveObjectStacking: true,
          enableRetinaScaling: true,
          uniformScaling: true,
          centeredScaling: true,
          width: newWidth,
          height: newHeight,
          selection: false,
          defaultCursor: 'inherit',
          fireRightClick: true,
          stopContextMenu: true,
          imageSmoothingEnabled: true,
          enablePointerEvents: !canvasDisabled,
        })
        canvasObj.current = cv
        viewerRef?.current?.setTransform(0, 0, 1)
        setCanvasInfos(cv, newWidth, newHeight)
      } else if (canvasObj.current) {
        viewerRef?.current?.setTransform(0, 0, 1)
        hideLoading()
      }
    }
  }, [storeInit])

  useEffect(() => {
    setCanvas(canvasObj.current ?? null)
  }, [canvasObj])

  useEffect(() => {
    if (objectItem?.__typename === 'Notification') {
      changeCmlMarkupsDisplay(false)
      changeDamageMarkupsDisplay(true)
    } else {
      changeCmlMarkupsDisplay(true)
      changeDamageMarkupsDisplay(true)
    }
  }, [objectItemId])

  useEffect(() => {
    if (canvas && storeInit) {
      canvas.set({ fireRightClick: (!objectItemId || isDrawingsTabSelected) && isSelectedDrawing })
      canvas.renderAll()
    }
  }, [canvas, selectedTab, rightSideTabSelectedValue, isSelectedDrawing, isDrawingsTabSelected, objectItemId, storeInit])

  // On background changed
  useEffect(() => {
    if (storeInit) {
      onBackgroundImgChanged()
    }
  }, [bgImg, bgDimension, viewerSize, canvas, storeInit])

  // add loading on drawing file changed
  useEffect(() => {
    if (uploadFile && storeInit && (!objectItem || isDrawingsTabSelected)) {
      setError(null)
    }
  }, [uploadFile, storeInit, objectItemId])

  // set object zoom on scale changed
  useEffect(() => {
    if (canvas && storeInit && scale && canvasRef?.current && viewerState && (!objectItem || isDrawingsTabSelected)) {
      const zoomPoint = viewerState.zoomPoint ?? originPoint
      canvas.zoomToPoint(zoomPoint, scale)
      canvas.getContext().imageSmoothingEnabled = false
    }
  }, [scale, storeInit])

  useEffect(() => {
    if (lastCmlUpdated && lastCmlUpdated.idwgId === idwg.id && storeInit && canvas) {
      removeCmlMarkup(lastCmlUpdated)
    }
  }, [lastCmlUpdated, lastCmlUpdatedAt, eventCmls])

  useEffect(() => {
    if (lastIdwgCmlUpdated && lastIdwgCmlUpdated.idwgId === idwg.id && storeInit && canvas) {
      removeCmlMarkup(lastIdwgCmlUpdated)
    }
  }, [lastIdwgCmlUpdated, lastIdwgCmlUpdatedAt])

  useEffect(() => {
    if (storeInit && objectItem?.__typename === 'Event' && lastDamageUpdated && lastDamageUpdated.idwgId === idwg.id && canvas) {
      removeDamageMarkup(lastDamageUpdated)
    }
  }, [lastDamageUpdated, lastDamageUpdatedAt])

  useEffect(() => {
    if (storeInit && objectItem?.__typename === 'Notification' && lastNotifDamageUpdated && lastNotifDamageUpdated.idwgId === idwg.id && canvas) {
      removeDamageMarkup(lastNotifDamageUpdated)
    }
  }, [lastNotifDamageUpdated, lastNotifDamageUpdatedAt])

  useEffect(() => {
    if (storeInit && lastIdwgDamageUpdated && lastIdwgDamageUpdated.idwgId === idwg.id && !objectItem && canvas) {
      removeDamageMarkup(lastIdwgDamageUpdated)
    }
  }, [lastIdwgDamageUpdated, lastIdwgDamageUpdatedAt])

  // // Change viewer scale and canvas dimension on zoom change
  useEffect(() => {
    if (storeInit && viewerState && canvas && canvasContainerRef?.current && bgDimension && bgImg && bgDimension.width && bgDimension.height && (!objectItem || isDrawingsTabSelected)) {
      const { newWidth, newHeight, bgScale } = calculateFitScaleAndSize(bgDimension, canvasContainerRef.current)
      // Change scale for canvas
      const newScale: number = bgScale * viewerState.scale
      if (newScale !== scale) {
        canvas.setDimensions({
          width: newWidth,
          height: newHeight,
        })
        canvas.renderAll()
        setScale(newScale)
      }
    }
  }, [viewerState, storeInit])

  useEffect(() => {
    if (storeInit) {
      if (objectItem && isDrawingsTabSelected) {
        if (idwgViewerWidth) {
          if (!selectedDrawing) {
            setViewerSize((idwgViewerWidth - 70) / 2)
          } else if (isSelectedDrawing) {
            setViewerSize(idwgViewerWidth - 60)
          } else {
            setViewerSize(0)
          }
        }
      } else if (!objectItem) {
        setViewerSize((size ?? (canvasContainerRef?.current?.clientWidth ? canvasContainerRef!.current!.clientWidth : 0)) - 60)
      }
    }
  }, [idwgViewerWidth, selectedDrawing, isSelectedDrawing, rightSideTabSelectedValue, size, storeInit])

  useEffect(() => {
    if (storeInit && (!objectItem || isDrawingsTabSelected) && viewerSize) {
      fitToScreen()
    }
  }, [viewerSize, rightSideTabSelectedValue, selectedDrawing, isSelectedDrawing, objectItemId, storeInit])

  useEffect(() => {
    setTechniquesBlockShown(hideTechniquesBlock !== true && isSelectedDrawing && hasTechniqueList !== false)
  }, [isSelectedDrawing && hasTechniqueList && hideTechniquesBlock])

  if (!storeInit) {
    return <SpinnerLoader isLoading />
  }

  return (
    <div className={`flex flex-row w-full h-full ${objectItem && selectedDrawing && !isSelectedDrawing ? '!w-0 !h-0' : ''}`}>
      <div
        ref={sortableId ? setNodeRef : undefined}
        className={cn(`w-full h-full z-10 overflow-auto relative flex flex-col ${objectItem && !selectedDrawing ? 'border rounded cursor-pointer' : (isSelectedDrawing ? 'selected-drawing' : '')}`)}
        style={objectItem && !selectedDrawing ? { width: viewerSize, height: viewerSize, ...(sortableId ? style : {}) } : {}}
      >
        {!isSelectedDrawing && sortableId && (
          <div className={`py-1 flex justify-center opacity-50 hover:opacity-100 ${objectItem?.__typename === 'Event' && !isSelectedDrawing ? ' cursor-move' : ''}`} {...(!isSelectedDrawing && sortableId ? listeners : undefined)}>
            <div className="h-1 rounded-full w-[20%] bg-gray-200 " />
          </div>
        )}
        {eventIdwg && !statusHidden && <Badge className={`absolute top-3 right-2 z-10 ${getInspectionStatusBgColor(eventIdwg.status)} text-white`}>{eventIdwg.status !== null ? t(`label.inspectionStatuses.${eventIdwg.status}`) : ''}</Badge>}
        {isSelectedDrawing && (
          <>
            <DeleteIdwgFlocModal idwg={idwg} open={!!flocToDelete} />
            <DeleteIdwgGridModal idwg={idwg} open={!!gridToDelete} />
            {deleteInspectionDrawingModalOpen && <DeleteIdwgModal open idwg={idwg} />}
          </>
        )}
        <div
          {...(!isSelectedDrawing && sortableId ? attributes : undefined)}
          className={`h-full w-full flex-grow ${!isSelectedDrawing ? 'hover:bg-gray-500 hover:bg-opacity-5' : ''}`}
          onContextMenu={handleContextMenuDrawing}
          onClick={() => setSelectedDrawing?.(idwg)}
        >
          <SpinnerLoaderComponent contentRef={canvasContainerRef} error={!loading && storeInit ? error : null} contentClassName={cn(`h-full w-full flex overflow-hidden ${objectItem && !selectedDrawing ? ' relative' : ''}`, !loading && storeInit && error ? 'max-h-[300px]' : '')} isLoading={loading || !storeInit}>
            <div className={`w-full h-full [&_.canvas-container]:m-auto [&_.canvas-container]:overflow-hidden [&_.canvas-container]:top-[50%] [&_.canvas-container]:translate-y-[-50%] [&_.canvas-container]:bg-white bg-gray-50 overflow-hidden${canvasDisabled || !isSelectedDrawing ? ' pointer-events-none' : ''}`}><canvas ref={canvasRef} /></div>
          </SpinnerLoaderComponent>
        </div>
        {isSelectedDrawing && !canvasDisabled && !menuContextDisabled && menuContextItems.length > 0
          && (
            <div ref={canvasMenuContextRef} className={`${canvasMenuContextShown ? 'w-fit relative' : 'hidden'}`}>
              <span
                className="w-3 h-3 absolute -top-1.5 -left-1.5 border bg-gray-500 rounded-full inline-block"
                onContextMenuCapture={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  return false
                }}
              />
              <RadCommand className="shadow">
                <RadCommandGroup>
                  {menuContextItems.map((item: TListItem) => (
                    <RadCommandItem key={item.value as number} className="px-3 py-2" disabled={item.disabled} onSelect={() => handleCanvasMenuContextClick(item.value as number)}>
                      <LuPlusSquare className="mr-2" />
                      {item.label as string}
                    </RadCommandItem>
                  ))}
                </RadCommandGroup>
              </RadCommand>
            </div>
          )}
        {!isSelectedDrawing && (
          <div className="text-center flex justify-center p-1 text-2xs font-semibold border-t border-gray-100">
            <Badge className="bg-blue-50 rounded-md p-1 text-blue-700">{idwg.idwg}</Badge>
          </div>
        )}
      </div>
      {techniquesBlockShown && isSelectedDrawing && <div className="w-[50px] h-full"><IdwgTechniques setIdwgTechniqueIdsHidden={setIdwgTechniqueIdsHidden} techniqueCreateBtnHidden={techniqueCreateBtnHidden} idwgTechniqueIdsHidden={idwgTechniqueIdsHidden} damages={damages} cmls={cmls} deleteTechniqueDisabled={deleteTechniqueDisabled} cmlsDisplayed={cmlMarkupsShown && !cmlHidden} damagesDisplayed={damageMarkupsShown && !dmgHidden} idwg={idwg} /></div>}
    </div>
  )
}
