import { gql } from '@apollo/client/core'
import { INSPECTION_DRAWINGS_COMMON_FIELDS, WORKORDER_EVENT_INSPECTION_DRAWINGS_COMMON_FIELDS } from '../fragments'

export const WORKORDER_EVENT_INSPECTION_DRAWINGS_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_INSPECTION_DRAWINGS_COMMON_FIELDS}
${INSPECTION_DRAWINGS_COMMON_FIELDS}
query GetEventInspectionDrawingsByEvtId($evtId: Int!) {
  eventInspectionDrawings (where: {wrkoId: {equals: $evtId}}, orderBy:  [{sort: {sort: asc}}]) {
    ...EventInspectionDrawingCommonFields
    inspectionDrawing {
      ...InspectionDrawingCommonFields
    }
  }
}`

export const WORKORDER_EVENT_INSPECTION_DRAWINGS_UPDATE_BY_ID = gql`
${WORKORDER_EVENT_INSPECTION_DRAWINGS_COMMON_FIELDS}
${INSPECTION_DRAWINGS_COMMON_FIELDS}
mutation UpdateOneEventInspectionDrawingById($id: Int!, $data: EventInspectionDrawingUncheckedUpdateInput!) {
  updateOneEventInspectionDrawing(where: {id: $id}, data: $data) {
    ...EventInspectionDrawingCommonFields
    inspectionDrawing {
      ...InspectionDrawingCommonFields
    }
  }
}
`

export const WORKORDER_EVENT_INSPECTION_DRAWINGS_CREATE_MANY = gql`
mutation CreateEventInspectionDrawings($data: [EventInspectionDrawingCreateManyInput!]!) {
  createManyEventInspectionDrawing(data: $data) {
    count
  }
}`

export const WORKORDER_EVENT_INSPECTION_DRAWINGS_DELETE_MANY = gql`
mutation DeleteEventInspectionDrawings($ids: [Int!]) {
  deleteManyEventInspectionDrawing (where: {id: {in: $ids}}) {
    count
  }
}`

export const WORKORDER_EVENT_INSPECTION_DRAWINGS_GET_COUNTER = gql`
query GetWorkorderEventInspectionDrawingsCounter {
  aggregateEventInspectionDrawing {
    _count {
      id
    }
  }
}
`
