import ElevatedButton from '@app/components/Common/Button/ElevatedButton'
import { Picture } from '@app/graphql/__types__/graphql'
import { FORMAT_DATETIME } from '@app/utils/constants'
import { createBase64FromUrl } from '@app/utils/functions'
import { useHolisAuth } from '@holis/auth-client-react'
import { RadBadge, RadCard, RadCardContent } from '@holis/react-ui/rad'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { LuCamera, LuClipboardList, LuMapPin, LuTrash2, LuZap } from 'react-icons/lu'
import { cn } from '@holis/react-ui/utils'
import { OptimusClientConfig } from '@app/utils/clientConfig'

type TPictureCard = Readonly<{
  picture: Partial<Picture>
  caption?: React.ReactNode
  captionClassName?: string
  className?: string
  contentClassName?: string
  pictureClassName?: string
  readonly?: boolean
  showOnlyPicture?: boolean
  onDeleteBtnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, deletedPicture?: Partial<Picture>) => void
  onUpdateBtnClick?: () => void
}>

export default function PictureCard({ picture, caption, onDeleteBtnClick, className, captionClassName, readonly, onUpdateBtnClick, contentClassName, showOnlyPicture, pictureClassName }: TPictureCard) {
  const [pictureSrc, setPictureSrc] = useState<string | null | HTMLImageElement>()
  const { getAccessToken } = useHolisAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const generatePicSrc = (picSrc?: string) => {
    if (picture.id && (!loading || picSrc)) {
      if (!loading) {
        setLoading(true)
      }

      const picDefault = `${OptimusClientConfig.current.pictureBaseUrl}/${picture.id}?compressed=1`
      createBase64FromUrl(picDefault, { getAccessToken }).then(url => setPictureSrc(url)).catch(() => setPictureSrc(null)).finally(() => setLoading(false))
    }
  }

  const handleDeleteBtnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onDeleteBtnClick?.(event, picture)
  }

  const getPictureType = () => {
    let ico = <LuCamera size={18} />
    if (picture.cmlId) {
      ico = <LuMapPin size={18} />
    } else if (picture.dmgeId) {
      ico = <LuZap size={18} />
    } else if (picture.inspId) {
      ico = <LuClipboardList size={18} />
    }

    return (
      <>
        {ico}
        {' '}
        {picture.cml?.position ?? picture.damage?.position ?? picture.inspectionPoint?.position}
      </>
    )
  }

  useEffect(() => {
    if (typeof pictureSrc !== 'undefined') {
      setLoading(false)
    }
  }, [pictureSrc])

  useEffect(() => {
    generatePicSrc()
  }, [picture.updatedAt])

  return (
    <RadCard
      className={cn(
        'w-full h-[200px] max-h-full font-roboto relative border-gray-200 border bg-white',
        className,
      )}
    >
      <RadCardContent className={cn('group flex flex-col justify-start w-full h-full p-0', contentClassName)}>
        <SpinnerLoaderComponent error={pictureSrc === null} isLoading={typeof pictureSrc === 'undefined' || loading} className={cn('h-full picture relative rounded-t-xl', pictureClassName)} style={typeof pictureSrc === 'string' ? { backgroundImage: `url(${pictureSrc})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' } : undefined}>
          {!showOnlyPicture && (
            <>
              <RadBadge variant="outline" className={cn('absolute top-1 left-1 font-normal text-sm bg-blue-50 text-blue-700 gap-1', captionClassName)}>
                {caption ?? getPictureType()}
              </RadBadge>
              <div className="transition-all absolute right-1 top-1 flex flex-col gap-1 opacity-0 group-hover:opacity-100">
                {onDeleteBtnClick && (
                  <ElevatedButton
                    variant="ghost"
                    className="bg-white text-destructive hover:text-destructive rounded-full w-8 h-8 px-0 py-0"
                    size="sm"
                    disabled={readonly}
                    onClick={handleDeleteBtnClick}
                  >
                    <LuTrash2 size={16} />
                  </ElevatedButton>
                )}

                {onUpdateBtnClick && pictureSrc && (
                  <ElevatedButton
                    variant="ghost"
                    className="bg-white text-primary hover:text-primary rounded-full w-8 h-8 px-0 py-0"
                    disabled={readonly}
                    onClick={onUpdateBtnClick}
                  >
                    <FaPencilAlt />
                  </ElevatedButton>
                )}
              </div>
              <span className="font-medium text-xs text-white absolute bottom-1 right-1">{picture.date ? moment(picture.date).format(FORMAT_DATETIME) : ''}</span>
            </>
          )}
        </SpinnerLoaderComponent>
        {!showOnlyPicture && (
          <div className="h-[80px] flex flex-grow flex-col justify-between gap-1 text-xs p-2">
            <div className="line-clamp-2 font-light">
              {picture.description}
              {' '}
&nbsp;
            </div>

            <div className="line-clamp-1 text-gray-600 font-medium">
              {picture.functionalLocation?.floc}
              {' '}
&nbsp;
            </div>
          </div>
        )}
      </RadCardContent>
    </RadCard>
  )
}
