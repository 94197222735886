import { RadFormLabel } from '@holis/react-ui/rad'
import { HTMLAttributes, ReactElement } from 'react'
import { cn } from '@holis/react-ui/utils'
import { ControllerFieldState } from 'react-hook-form'

export type TInputLabelProps = {
  readonly label: string
  readonly prepend?: ReactElement
  readonly isMuted?: boolean
  readonly isRequired?: boolean
  readonly fieldState?: ControllerFieldState
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>

export default function InputLabel({ prepend, label, isMuted, isRequired, fieldState, ...divProps }: TInputLabelProps) {
  const labelContent = (
    <>
      {prepend}
      <span>
        {label}
        {isRequired ? '*' : ''}
      </span>
    </>
  )
  const className = cn('flex items-center gap-2 font-semibold text-xs', isMuted ? 'text-muted-foreground' : '', divProps.className ?? '')
  return fieldState
    ? <RadFormLabel className={className}>{labelContent}</RadFormLabel>
    : (
        <div {...divProps} className={className}>
          {labelContent}
        </div>
      )
}
