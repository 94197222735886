import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteOneEventFlocByIdMutation } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENT_FLOCS_DELETE_ONE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useEventStore } from '@app/stores/event'
import { useLayoutStore } from '@app/stores/layout'
import { useTranslation } from 'react-i18next'
type TDeleteEventFlocModal = IConfirmModal

export default function DeleteEventFlocModal({ ...restProps }: TDeleteEventFlocModal) {
  const { setFlocToDelete, flocToDelete, deleteEventFloc } = useEventStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteEventFlocApi] = useMutation<DeleteOneEventFlocByIdMutation>(WORKORDER_EVENT_FLOCS_DELETE_ONE_BY_ID)
  const { t } = useTranslation()
  const onConfirm = () => {
    if (flocToDelete) {
      startLoading()
      deleteEventFlocApi({
        variables: {
          id: flocToDelete!.id!,
        },
      }).then(() => {
        deleteEventFloc()

        AppNotifications.success(t('message.success.deleteEventFloc'))
      }).catch(() => {
        AppNotifications.error(t('message.error.deleteEventFloc'))
      }).finally(() => {
        stopLoading()
      })
    }
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteEventFloc')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => setFlocToDelete(undefined)}
      {...restProps}
    />
  )
}
