import { useLazyQuery } from '@apollo/client'
import DrawingViewer from '@app/components/Common/Block/InspectionDrawing/DrawingViewer'
import PanelFilters from '@app/components/Common/Panel/PanelFilters'
import { EventCml, EventDamage, GetLatestEventCmlsByIdwgIdQuery, GetLatestEventDamagesByIdwgIdQuery, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENT_CMLS_GET_LATEST_BY_IDWG_ID, WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_IDWG_ID } from '@app/graphql/requests'
import useIdwgStore, { IDWG_PREFIX } from '@app/stores/idwg'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type TRightSide = Readonly<{
  idwg: Partial<InspectionDrawing>
}>

export default function RightSide({ idwg }: TRightSide) {
  const canvasContainerRef = React.createRef<HTMLDivElement>()
  const { t } = useTranslation()
  const [filters, setFilters] = useState<string[]>(['cml', 'damage'])
  const { changeCmlMarkupsDisplay, changeDamageMarkupsDisplay, cmlMarkupsShown, damageMarkupsShown } = useIdwgStore()
  const { setLatestEventCmls, latestEventCmls, setLatestEventDamages, latestEventDamages, cmls, damages } = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`)

  const [getEvtCmlListApi] = useLazyQuery<GetLatestEventCmlsByIdwgIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_BY_IDWG_ID, {
    variables: {
      idwgId: idwg!.id,
    },
    fetchPolicy: 'no-cache',
  })

  const [getEvtDamageListApi] = useLazyQuery<GetLatestEventDamagesByIdwgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_IDWG_ID, {
    variables: {
      idwgId: idwg!.id,
    },
    fetchPolicy: 'no-cache',
  })

  const handleFilterChanged = (values: string[]) => {
    changeCmlMarkupsDisplay(values.includes('cml'))
    changeDamageMarkupsDisplay(values.includes('damage'))
  }

  const getData = () => {
    getEvtCmlListApi().then((queryResults) => {
      const latestEvtCmls: Record<number, Partial<EventCml> | null> = {};
      (queryResults.data?.lastEventCmlsWithQualReadingByIdwgId as Partial<EventCml>[]).forEach((evtCml) => {
        latestEvtCmls[evtCml!.cmlId!] = evtCml
      })
      setLatestEventCmls(latestEvtCmls)
    })
    getEvtDamageListApi().then((queryResults) => {
      const latestEvtDamages: Record<number, Partial<EventDamage> | null> = {};
      (queryResults.data?.lastEventDamagesWithQualReadingByIdwgId as Partial<EventDamage>[]).forEach((evtDmg) => {
        latestEvtDamages[evtDmg!.dmgeId!] = evtDmg
      })
      setLatestEventDamages(latestEvtDamages)
    })
  }

  useEffect(() => {
    const filters
      = Object.entries({
        cml: cmlMarkupsShown,
        damage: damageMarkupsShown,
      }).filter(([, value]) => value).map(([key]) => key)
    setFilters(filters)
  }, [cmlMarkupsShown, damageMarkupsShown])

  useEffect(() => {
    changeCmlMarkupsDisplay(true)
    changeDamageMarkupsDisplay(true)
    getData()
  }, [])

  console.log(latestEventDamages, latestEventCmls)

  return (
    <div className="w-full h-full overflow-auto flex flex-col gap-1 p-3">
      <div className="flex justify-end mb-1">
        <PanelFilters<string>
          isMultiple
          items={[
            { label: t('label.displayCmls'), value: 'cml' },
            { label: t('label.displayDamages'), value: 'damage' },
          ]}
          value={filters}
          onChange={values => handleFilterChanged(values.map(v => v.value))}
        />
      </div>
      <div ref={canvasContainerRef} className="flex-1 w-full">
        {typeof latestEventCmls !== 'undefined' && typeof latestEventDamages !== 'undefined' && Array.isArray(cmls) && Array.isArray(damages) && (
          <DrawingViewer
            isSelected
            storeId={`${IDWG_PREFIX}${idwg.id}`}
            canvasContainerRef={canvasContainerRef}
            objectItem={idwg}
            idwg={idwg}
            cmls={cmls}
            damages={damages}
          />
        )}
      </div>
    </div>
  )
}
