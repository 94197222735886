import { gql } from '@apollo/client'
import { NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS } from '../fragments'

export const NOTIFICATION_REF_ADDITIONAL_DATA_GET_MANY = gql`
${NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS}
query GetAllRefNotifAdditionalData($orderBy: [RefNotifAdditionalDataOrderByWithRelationInput!], $where: RefNotifAdditionalDataWhereInput) {
    findManyRefNotifAdditionalData (orderBy: $orderBy, where: $where) {
      ...RefNotifAdditionalDataCommonFields
    }
}`

export const NOTIFICATION_REF_ADDITIONAL_DATA_GET_COUNTER = gql`
query GetRefNotifAdditionalDataCounter {
  aggregateRefNotifAdditionalData {
    _count {
      id
    }
  }
}
`
