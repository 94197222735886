import { useEventPerformanceDashboardStore } from '@app/stores/event/performanceDashboard'
import { EEventPerformanceDashboardPlannedDateFilter } from '@app/utils/enums'
import { RadButton } from '@holis/react-ui/rad'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export default function PlannedDateFilters() {
  const { t } = useTranslation()
  const { plannedDateFilters, setPlannedDateFilters } = useEventPerformanceDashboardStore()
  const plannedDateFilterOptions = useMemo(() => (Object.values(EEventPerformanceDashboardPlannedDateFilter).map(val => ({
    value: val,
    label: t(`label.eventPerformanceDashboard.plannedDateFilter.${val}`),
  }))), [t])

  const changeFilter = (value: EEventPerformanceDashboardPlannedDateFilter) => {
    let newFilters = [...(plannedDateFilters ?? [])]
    if (plannedDateFilters?.includes(value)) {
      newFilters = plannedDateFilters.filter(filter => filter !== value)
    } else {
      if (value !== EEventPerformanceDashboardPlannedDateFilter.OVERDUE) {
        newFilters = newFilters.filter(filter => filter === EEventPerformanceDashboardPlannedDateFilter.OVERDUE)
      }

      newFilters.push(value)
    }

    setPlannedDateFilters(newFilters)
  }

  return (
    <div className="gap-2 flex">
      {plannedDateFilterOptions.map(({ label, value }) => <RadButton key={value} className={`${plannedDateFilters?.includes(value) ? 'bg-cyan-700 text-white' : 'bg-white  text-cyan-700'} hover:bg-cyan-600 hover:text-white border-cyan-700`} variant={`${plannedDateFilters?.includes(value) ? 'default' : 'outline'}`} onClick={() => changeFilter(value)}>{label}</RadButton>)}
    </div>
  )
}
