import { gql } from '@apollo/client'
import { DAMAGE_REF_VAL_CODES_COMMON_FIELDS } from '../fragments'

export const DAMAGE_REF_VAL_CODES_GET_MANY = gql`
${DAMAGE_REF_VAL_CODES_COMMON_FIELDS}
query GetAllRefDamageValCodes($orderBy: [RefDamageValCodeOrderByWithRelationInput!], $where: RefDamageValCodeWhereInput) {
    refDamageValCodes(orderBy: $orderBy, where: $where) {
      ...RefDamageValCodeCommonFields
      codeGroupValCodes {
        id
        codeGroupId
      }
    }
}`

export const DAMAGE_REF_VAL_CODES_GET_COUNTER = gql`
query GetRefDamageValCodesCounter {
  aggregateRefDamageValCode {
    _count {
      id
    }
  }
}
`
