import { useMutation } from '@apollo/client'
import SingleFormModal from '@app/components/Modal/SingleFormModal'
import { CreateDamageMutation, Damage, FunctionalLocation } from '@app/graphql/__types__/graphql'
import { DAMAGES_CREATE } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { ZOD_CML_DATAS } from '@app/stores/cml'
import useDamageStore from '@app/stores/damage'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import Footer from './components/Footer'
import Form from './components/Form'

type TDamageCreateModal = Readonly<{
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onCreated: (dmg: Damage) => void
  item: Partial<Damage>
  floc?: Partial<FunctionalLocation>
}>

export function DamageCreateModal(props: TDamageCreateModal) {
  const { updateDamageDataField, updateDamageData, updateDamage, setActiveDamage } = useDamageStore()

  const [createDamageApi] = useMutation<CreateDamageMutation>(DAMAGES_CREATE)
  const { floc } = props
  const htmlForm = useRef<HTMLFormElement>(null)
  const cmlValidationDatas = ZOD_CML_DATAS(t)
  const zodFormObject = z.object(cmlValidationDatas)
  const form = useForm<z.infer<typeof zodFormObject>>(
    { resolver: zodResolver(zodFormObject), mode: 'onSubmit' })

  const handleCreateDamage = async () => {
    try {
      const newDmgResult = await createDamageApi({
        variables: {
          data: updateDamageData,
        },
      })
      const newDamage = newDmgResult.data?.createOneDamage as Damage
      updateDamage(newDamage, true)
      setActiveDamage()
      AppNotifications.success(t('message.success.damageCreated'))
      props.onCreated?.(newDamage)
    } catch {
      AppNotifications.error(t('message.error.default.title'))
    }
  }

  form.watch((datas, args) => {
    updateDamageDataField(args.name!, datas[args.name!])
  })

  return (
    <SingleFormModal
      isOpen={props.isOpen}
      title={t('label.damageCreation')}
      description={t('label.itemCreateDescription')}
      footer={<Footer onSaveClick={() => htmlForm.current?.requestSubmit()} />}
      containerClassName="max-w-[1134px] w-full"
      onOpenChange={props.onOpenChange}
    >
      <RadForm {...form}>
        <form ref={htmlForm} onSubmit={form.handleSubmit(handleCreateDamage, data => console.log('form is invalid', data))}>
          <Form form={form} floc={floc} idwgId={props.item.idwgId as number} />
        </form>
      </RadForm>
    </SingleFormModal>
  )
}
