import { IConfirmModal } from '..'
import { TDataRowTreeList } from '@holis/react-ui'
import { useTranslation } from 'react-i18next'
import { TBorderedTable } from '@app/components/Common/TreeList/BorderedTable'
import { useEffect, useState } from 'react'
import { QueryResult } from '@apollo/client'
import DataTable from '@app/components/Common/Datatable'
import SingleContentModal from '../../SingleFormModal'
import { RadButton } from '@holis/react-ui/rad'
import _, { ListIterator, NotVoid } from 'lodash'
import { TDbId, TObjId } from '@app/types/app'
import { cn } from '@holis/react-ui/utils'

export type TSelectionConfirmModalWithoutListProps<TItem extends TObjId = TObjId> = Omit<TSelectionConfirmModal<TItem>, 'listProps'>

type TSelectionConfirmModal<TItem extends TObjId = TObjId> = IConfirmModal & Readonly<{
  listProps: TBorderedTable
  hiddenIds?: TDbId[]
  disabledIds?: TDbId[]
  selectedIds?: TDbId[]
  onValidate?: (selectedItems: TItem[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[], otherData?: Record<string, unknown>) => void
  isMultiple?: boolean
  items?: TItem[]
  isRequired?: boolean
  hasItems?: boolean
  sortFields?: (ListIterator<TDataRowTreeList, NotVoid> | string)[]
  sortOrders?: (boolean | 'asc' | 'desc')[]
  isLoading?: boolean
  queryResult?: QueryResult
  description?: string
  searchFilter?: (data: TDataRowTreeList[], searchText: string) => TDataRowTreeList[]
  className?: string
  cancelBtnLabel?: string
  validateBtnLabel?: string
}>

export default function SelectionConfirmModal<TItem extends TObjId>({ title, description, listProps, queryResult, hiddenIds, onValidate, isMultiple, searchFilter, selectedIds, items, sortFields, sortOrders, className, isRequired, disabledIds, cancelBtnLabel, validateBtnLabel, ...restProps }: TSelectionConfirmModal<TItem>) {
  const { listProps: listPropsInit, ...restListPops } = listProps ?? {}
  const [selectedRows, setSelectedRows] = useState<TItem[]>([])
  const [newSelectedIds, setNewSelectedIds] = useState<TDbId[]>([])
  const [oldSelectedIds, setOldSelectedIds] = useState<TDbId[]>([])
  const [dataSource, setDataSource] = useState<TItem[]>([])
  const [rows, setRows] = useState<TItem[]>([])
  const handleRowSelectionChanged = (rowData: TItem[]) => {
    setSelectedRows(rowData)
  }

  useEffect(() => {
    const currentSelectedIds = selectedRows.filter((item: TItem) => !!item.id).map((item: TItem) => item.id) as (TDbId)[]
    setNewSelectedIds(currentSelectedIds.filter((id: TDbId) => !selectedIds?.includes(id)))
    setOldSelectedIds(selectedIds?.filter((id: TDbId) => !currentSelectedIds.includes(id)) ?? [])
  }, [selectedRows])

  useEffect(() => {
    setRows((items ?? listPropsInit.data ?? []) as TItem[])
  }, [items, listPropsInit.data])

  useEffect(() => {
    setDataSource(_.orderBy<TItem>(rows, [(o: TDataRowTreeList) => !!selectedIds?.includes(o.id), ...(sortFields ?? [])], ['desc', ...(sortOrders ?? [])]))
    setSelectedRows(rows?.filter((item: TItem) => item.id && selectedIds?.includes(item.id)) ?? [])
  }, [rows, selectedIds])

  const { t } = useTranslation()
  return (

    <SingleContentModal
      isOpen={restProps.open}
      containerClassName="max-w-[1000px] h-[90%]"
      title={title}
      description={description}
      footer={(
        <div className="w-full flex items-center gap-2">
          <div className="flex-1 text-gray-600">
            {t('label.selected')}
            :
            {selectedRows.length}
            {' '}
            /
            {rows.length - (hiddenIds?.length ?? 0)}
          </div>
          <RadButton variant="outline" onClick={() => restProps.onClose?.()}>{cancelBtnLabel ?? t('label.cancel')}</RadButton>
          <RadButton disabled={!!isRequired && !selectedRows.length} onClick={() => onValidate?.(selectedRows, newSelectedIds, oldSelectedIds)}>{validateBtnLabel ?? t('label.validate')}</RadButton>
        </div>
      )}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          restProps.onClose?.()
        }
      }}
    >
      <div className={cn('h-full w-full overflow-hidden min-h-[100px]', className)}>
        <DataTable<TItem>
          hasSearchBar
          singleRowSelection={isMultiple === false}
          searchBarClassName="w-[400px]"
          searchFilter={searchFilter}
          hiddenIds={hiddenIds}
          disabledIds={disabledIds}
          selectedIds={selectedIds}
          listProps={{ ...listPropsInit, data: dataSource as TDataRowTreeList[], isLoading: listPropsInit.isLoading || queryResult?.loading || restProps.isLoading }}
          {...restListPops}
          onRowSelectionChanged={handleRowSelectionChanged}
        />
      </div>
    </SingleContentModal>

  )
}
