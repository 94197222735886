import { Plan } from '@app/graphql/__types__/graphql'
import ItemList from './ItemList'
import EventList from './EventList'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { ESCHEDULING_PLAN_STATUS } from '@app/utils/enums'

type TRightSide = Readonly<{
  plan: Partial<Plan>
}>

export default function RightSide({ plan }: TRightSide) {
  return (
    <div className="h-full w-full flex flex-col">
      {plan.id && plan.id !== OBJ_NEW_ID && plan.status !== ESCHEDULING_PLAN_STATUS.INIT && (
        <div className="flex-grow w-full overflow-auto -mt-2">
          <ItemList plan={plan} />
          <EventList plan={plan} />
        </div>
      )}
    </div>
  )
}
