import { useLazyQuery, useMutation } from '@apollo/client'
import TaskList from './TaskList'
import { CreateItemTasksMutation, DeleteItemTasksByTaskIdsAndItemIdMutation, GetItemTasksByItemIdQuery, ItemTask, RefEventTask } from '@app/graphql/__types__/graphql'
import { SCHEDULING_ITEM_TASKS_CREATE_MANY, SCHEDULING_ITEM_TASKS_DELETE_BY_TASK_IDS_AND_ITEM_ID, SCHEDULING_ITEM_TASKS_GET_BY_ITEM_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EWORKORDER_REF_EVENT_TASK_CATEGORY } from '@app/utils/enums'
import DeleteItemTaskModal from './DeleteItemTaskModal'
import useItemStore from '@app/stores/item'
import { Badge } from '@holis/react-ui'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { RadButton } from '@holis/react-ui/rad'
import { LuPlusSquare } from 'react-icons/lu'
import TaskSelectionModal from '@app/components/Common/Block/Task/TaskBlock/TaskSelectionModal'
import { TDbId } from '@app/types/app'

export default function TaskBlock() {
  const { t } = useTranslation()
  const { taskToDelete, editItem, setTaskToDelete, fetchItemTasks, setFetchItemTasks } = useItemStore()
  const [tasks, setTasks] = useState<Partial<ItemTask>[]>()
  const [inspectionTasks, setInspectionTasks] = useState<Partial<ItemTask>[]>()
  const [safetyTasks, setSafetyTasks] = useState<Partial<ItemTask>[]>()
  const { startLoading, stopLoading } = useLayoutStore()
  const [manHoursTotal, setManHoursTotal] = useState<number | undefined>()
  const [addItemTasksApi] = useMutation<CreateItemTasksMutation>(SCHEDULING_ITEM_TASKS_CREATE_MANY)
  const [deleteItemTasksApi] = useMutation<DeleteItemTasksByTaskIdsAndItemIdMutation>(SCHEDULING_ITEM_TASKS_DELETE_BY_TASK_IDS_AND_ITEM_ID)
  const [getItemTasksByItemId] = useLazyQuery<GetItemTasksByItemIdQuery>(SCHEDULING_ITEM_TASKS_GET_BY_ITEM_ID)
  const [selectedIds, setSelectedIds] = useState<number[]>()
  const [taskSelectionModalDisplayed, changeTaskSelectionModalDisplay] = useState<boolean>(false)

  const getTasks = () => {
    getItemTasksByItemId({
      variables: {
        itemId: editItem!.id!,
      },
      fetchPolicy: 'no-cache',
    }).then((queryResults) => {
      console.log('loaded new tasks', queryResults.data?.itemTasks)
      setTasks((queryResults.data?.itemTasks ?? []) as Partial<ItemTask>[])
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    })
  }

  const addTasks = async (taskIds: TDbId[]) => {
    await addItemTasksApi({
      variables: {
        data: taskIds.map((taskId: TDbId) => ({
          itemId: editItem!.id,
          taskId,
        })),
      },
      fetchPolicy: 'no-cache',
    })
  }

  const deleteTasks = async (taskIds: TDbId[]) => {
    await deleteItemTasksApi({
      variables: {
        itemId: editItem!.id,
        taskIds,
      },
      fetchPolicy: 'no-cache',
    })
  }

  const handleValidateSelection = async (items: Partial<RefEventTask>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    console.log(oldSelectedIds, newSelectedIds, items)
    if (oldSelectedIds.length) {
      const deletedIds = (tasks?.filter((item: Partial<ItemTask>) => oldSelectedIds.includes(item.taskId!)).map((item: Partial<ItemTask>) => item.taskId!) ?? []) as TDbId[]
      if (deletedIds.length) {
        promises.push(deleteTasks(deletedIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addTasks(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      console.log(promises)
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        changeTaskSelectionModalDisplay(false)
        fetchItemTasks?.()
        AppNotifications.success(t('message.success.itemTasksUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    } else {
      changeTaskSelectionModalDisplay(false)
    }
  }

  useEffect(() => {
    if (editItem?.id) {
      setFetchItemTasks(getTasks)
    }
  }, [editItem?.id])

  useEffect(() => {
    fetchItemTasks?.()
  }, [fetchItemTasks])

  useEffect(() => {
    setSafetyTasks(tasks?.filter((task: Partial<ItemTask>) => task.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY))
    setInspectionTasks(tasks?.filter((task: Partial<ItemTask>) => task.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.INSPECTION))
    setSelectedIds(tasks?.map((itemTask: Partial<ItemTask>) => itemTask.task!.id!))
  }, [tasks])

  useEffect(() => {
    const total = 0
    setManHoursTotal(inspectionTasks?.map((task: Partial<ItemTask>) => Number((task.duration ?? 0) * (task.nbWorkers ?? 0)))?.reduce((allTaskManHours: number, taskManHours: number) => allTaskManHours + taskManHours, total) ?? undefined)
  }, [inspectionTasks])

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-col overflow-visible">
        <FormGroupHeader
          actions={(
            <div className="flex gap-2 items-stretch">
              <Badge className="bg-blue-100 p-2 px-3 h-9 text-blue-500">
                {t('label.workloadInHour')}
                {' '}
                {manHoursTotal}
              </Badge>
              <RadButton type="button" variant="outline" onClick={() => changeTaskSelectionModalDisplay(true)}>
                <LuPlusSquare className="mr-2" />
                {' '}
                {t('label.addTask')}
              </RadButton>
            </div>
          )}
        >
          {t('label.taskList')}
        </FormGroupHeader>
        <div className="pt-2 relative">
          <TaskList
            hasActionButtons
            item={editItem!}
            tasks={[
              ...(safetyTasks ?? []),
              ...(inspectionTasks ?? []),
            ]}
            isDeleteButtonDisabled={!!taskToDelete}
            onDeleteClick={setTaskToDelete}
          />
        </div>
        <DeleteItemTaskModal open={!!taskToDelete} />
      </div>
      {tasks?.length === 0 && <div className="text-center text-gray-300 text-base flex-1 flex flex-col justify-center">{t('label.taskListEmpty')}</div>}
      {taskSelectionModalDisplayed && <TaskSelectionModal open selectedIds={selectedIds} closeOnConfirm={false} onClose={() => changeTaskSelectionModalDisplay(false)} onValidate={handleValidateSelection} />}
    </div>
  )
}
