import DrawingObjectSelectionConfirmModal from '@app/components/Modal/Confirm/DrawingObjectSelection'
import { Damage, CreateEventDamagesMutation, DeleteEventDamagesMutation, EventDamage, EventInspectionDrawing, GetDamagesByFlocIdsQuery, InspectionDrawing, Cml, GetLatestEventDamagesByFlocIdsQuery } from '@app/graphql/__types__/graphql'
import { useEventStore } from '@app/stores/event'
import { NO_DRAWING_ID } from '@app/utils/constants'
import { useEffect, useState } from 'react'
import DamageCard from '../DamageBlock/DamageCard'
import { useTranslation } from 'react-i18next'
import { LuMapPin } from 'react-icons/lu'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DAMAGES_GET_BY_FLOC_IDS, WORKORDER_EVENT_DAMAGES_CREATE_MANY, WORKORDER_EVENT_DAMAGES_DELETE_MANY, WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_FLOC_IDS } from '@app/graphql/requests'
import { TDbId, TObjId } from '@app/types/app'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'

export default function EventDamageSelectionModal() {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLayoutStore()
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>()
  const { editEvent, eventFlocDamages, activeEvent, eventDamages, eventInspectionDrawings, changeDamageSelectionModalDisplay, damageSelectionModalDisplayed, eventFlocIds, setEventFlocDamages, fetchEventDamages, latestEventFlocEventDamages, setLatestEventFlocEventDamages } = useEventStore()

  const [addEventDamagesApi] = useMutation<CreateEventDamagesMutation>(WORKORDER_EVENT_DAMAGES_CREATE_MANY)
  const [deleteEventDamagesApi] = useMutation<DeleteEventDamagesMutation>(WORKORDER_EVENT_DAMAGES_DELETE_MANY)

  const [getEventFlocDamagesApi] = useLazyQuery<GetDamagesByFlocIdsQuery>(DAMAGES_GET_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  })

  const [getLatestFlocEventDamagesApi] = useLazyQuery<GetLatestEventDamagesByFlocIdsQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  })
  const addItems = (ids: TDbId[]) => addEventDamagesApi({
    variables: {
      data: ids.map((dmgeId: TDbId) => ({
        wrkoId: activeEvent!.id!,
        dmgeId,
      })),
    },
  })

  const deleteItems = (eventDamageIds: TDbId[]) => deleteEventDamagesApi({
    variables: {
      ids: eventDamageIds,
    },
  })

  const handleValidateSelection = async (selectedItems: Partial<TObjId>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const eventDamageIds = (eventDamages?.filter((item: Partial<EventDamage>) => item.dmgeId && oldSelectedIds.includes(item.dmgeId)).map((item: Partial<EventDamage>) => item.id) ?? []) as TDbId[]
      if (eventDamageIds.length) {
        promises.push(deleteItems(eventDamageIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        fetchEventDamages?.()
        changeDamageSelectionModalDisplay(false)
        AppNotifications.success(t('message.success.eventDamagesUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    } else {
      changeDamageSelectionModalDisplay(false)
    }
  }

  const getEventFlocDamages = () => {
    if (Array.isArray(eventFlocIds) && eventFlocIds.length) {
      getEventFlocDamagesApi({
        variables: {
          flocIds: eventFlocIds,
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        setEventFlocDamages((queryResult.data?.damages ?? []) as Partial<Damage>[])
      })
    } else {
      setEventFlocDamages([])
    }
  }

  const getLatestFlocEventDamages = () => {
    if (Array.isArray(eventFlocIds) && eventFlocIds.length) {
      getLatestFlocEventDamagesApi({
        variables: {
          flocIds: eventFlocIds,
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        const latestEventDamages: Record<number, Partial<EventDamage> | null> = {};
        ((queryResult.data?.lastEventDamagesWithQualReadingByFlocIds ?? []) as Partial<EventDamage>[]).forEach((evtDamage) => {
          latestEventDamages[evtDamage.dmgeId!] = evtDamage
        })
        setLatestEventFlocEventDamages(latestEventDamages)
      })
    } else {
      setLatestEventFlocEventDamages({})
    }
  }

  useEffect(() => {
    const idwgs = eventInspectionDrawings?.map((evtDrawing: Partial<EventInspectionDrawing>) => evtDrawing.inspectionDrawing!) ?? []
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      ...idwgs,
    ])
  }, [eventInspectionDrawings])

  useEffect(() => {
    getEventFlocDamages()
    getLatestFlocEventDamages()
  }, [eventFlocIds])

  return (
    <DrawingObjectSelectionConfirmModal
      hasItems
      isMultiple
      cmlHidden
      latestEventItems={latestEventFlocEventDamages}
      isLoading={typeof latestEventFlocEventDamages === 'undefined' || !Array.isArray(eventFlocDamages)}
      objectItem={editEvent}
      title={t('label.manageDamages')}
      description={t('label.manageDamagesDescription')}
      headerTitle={(
        <div className="flex items-center text-primary">
          <LuMapPin />
          {' '}
          {t('label.damage')}
        </div>
      )}
      drawings={drawings}
      open={damageSelectionModalDisplayed}
      items={eventFlocDamages ?? []}
      drawingStoreIdSuffix="damage-selection-modal"
      selectedIds={eventDamages?.map(item => item.dmgeId!) ?? []}
      renderItem={(item: Partial<Damage | Cml>, drawingStoreId: string) => (
        <DamageCard
          display2dDisabled
          displayLastQualReadingWithColor
          objectItem={editEvent}
          drawingStoreId={drawingStoreId}
          dmgObject={latestEventFlocEventDamages?.[item.id!] ?? (item as Partial<Damage>)}
        />
      )}
      onValidate={handleValidateSelection}
      onClose={() => changeDamageSelectionModalDisplay(false)}
    />
  )
}
