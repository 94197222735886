import { MENU_GROUP_COLORS } from '@app/utils/constants'
import { useTranslation } from 'react-i18next'
import { MENU_ITEMS } from '@app/components/Menu'
import FirstLetterBadge from '@app/components/Common/FirstLetterBadge'
import { ILinkGroup } from '@holis/react-ui'

export function WorkPackBadge() {
  const { t } = useTranslation()
  const index = MENU_ITEMS.findIndex((item: ILinkGroup) => item.groupName === t('label.operationWorkpack'))
  return (
    <FirstLetterBadge
      iconColor={MENU_GROUP_COLORS[index > -1 ? index : 0]}
      title={t('label.operationWorkpack')}
    />
  )
}

export default function WorkPackPage() {
  return (
    <>
      workpack
    </>
  )
}
