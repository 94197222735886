import { FlocChar } from '@app/graphql/__types__/graphql'
import { RadCollapsible, RadCollapsibleTrigger, RadCollapsibleContent } from '@holis/react-ui/rad'
import React, { useState } from 'react'
import { LuChevronDown, LuChevronUp } from 'react-icons/lu'
import FlocCharBlock from './FlocCharBlock'

const groupNameClassName = 'p-3 border border-gray-300 bg-gray-200 font-bold col-span-3 text-left flex items-center'

type TGroupingFlocChars = Readonly<{
  group: React.ReactNode
  flocChars: Array<Partial<FlocChar>>
}>

export default function GroupingFlocChars({ group, flocChars }: TGroupingFlocChars) {
  const [open, setOpen] = useState<boolean>(true)

  return (
    <RadCollapsible open={open} className="col-span-3 grid grid-cols-3">
      <RadCollapsibleTrigger className={groupNameClassName} onClick={() => setOpen(!open)}>
        {open ? <LuChevronUp className="w-4 mr-2 h-4 text-gray-700" /> : <LuChevronDown className="w-4 h-4 text-gray-700 mr-2" />}
        {' '}
        {group}
      </RadCollapsibleTrigger>
      <RadCollapsibleContent className="col-span-3 grid grid-cols-3 ">
        {flocChars.map(flocChar => (
          <FlocCharBlock key={flocChar.id} flocChar={flocChar} />
        ))}
      </RadCollapsibleContent>
    </RadCollapsible>
  )
}
