import ConfirmModal from '@app/components/Modal/Confirm'
import { EVENT_WORKFLOW, EventWorkflowActionKey, WorkflowConfirmType } from '@app/utils/functions/workflows'
import { RadTextarea } from '@holis/react-ui/rad'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'node_modules/i18next/typescript/helpers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuAlertCircle } from 'react-icons/lu'

type TEventActionConfirmModal = Readonly<{
  actionKey: EventWorkflowActionKey
  params?: TOptionsBase & $Dictionary
  onClose?: (reason?: 'cancel' | 'confirm', comment?: string) => void
}>

export default function EventActionConfirmModal({ actionKey, params, onClose }: TEventActionConfirmModal) {
  const { t } = useTranslation()
  const [comment, setComment] = useState<string>()
  const [error, setError] = useState<string>()

  const action = EVENT_WORKFLOW.find(action => action.key === actionKey)
  if (!action) {
    throw new Error(`Action ${actionKey} not found`)
  }

  if (!action.confirm) {
    console.error(`Action ${actionKey} does not have a confirm`)
    onClose?.('confirm')
    return null
  }

  const handleConfirm = () => {
    if (action.confirm === WorkflowConfirmType.reason && !comment?.trim()) {
      setError(t('message.error.form.required'))
      return
    }

    onClose?.('confirm', comment)
  }

  return (
    <ConfirmModal
      open
      title={t(`label.eventActions.descriptions.${actionKey}`, params)}
      description={t(`label.eventActions.confirm.${action.confirm.toString()}`)}
      onConfirm={handleConfirm}
      onCancel={() => onClose?.('cancel')}
    >
      <>
        {action.confirm === WorkflowConfirmType.confirm ? '' : <RadTextarea rows={4} onChange={e => setComment(e.target.value)} />}
        {error && (
          <div className="text-destructive px-1 -mt-2 flex gap-1 items-center">
            <LuAlertCircle />
            {' '}
            {error}
          </div>
        )}
      </>
    </ConfirmModal>
  )
}
