import { UserSite } from '@app/graphql/__types__/graphql'
import UploadDownloadService from '@app/services/api/UploadDownloadService'
import { USER_SELECTED_SITES } from '@app/utils/constants'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type State = {
  uploadDownloadService?: UploadDownloadService
  sites?: Array<Partial<UserSite>>
  selectedSites?: Array<string>
}

type Actions = {
  setUploadDownloadService: (uploadDownloadService?: UploadDownloadService) => void
  setSites: (sites?: Array<Partial<UserSite>>) => void
  setSelectedSites: (selectedSites?: Array<string>) => void
}

let initSelectedSites: Array<string> = []
try {
  const initSelectedSitesData = localStorage.getItem(USER_SELECTED_SITES)
  if (initSelectedSitesData) {
    initSelectedSites = JSON.parse(initSelectedSitesData) as Array<string>
  }
} catch {
  // ignore
}

const initialState = {
  uploadDownloadService: undefined,
  sites: undefined,
  selectedSites: initSelectedSites,
}

export const useDataStore = create<State & Actions>()(
  immer(set => ({
    ...initialState,
    setUploadDownloadService(uploadDownloadService?: UploadDownloadService) {
      set({ uploadDownloadService })
    },
    setSites(sites) {
      set({ sites })
    },
    setSelectedSites(selectedSites) {
      localStorage.setItem(USER_SELECTED_SITES, JSON.stringify(selectedSites))
      set({ selectedSites })
    },
  })),
)
