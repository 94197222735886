import { gql } from '@apollo/client'

export const FLOC_CHARS_COMMON_FIELDS = gql`
  fragment FlocCharCommonFields on FlocChar {
    id
    flocId
    charId
    value
    unit
  }
`
