import SingleFormModal, { TSingleContentModal } from '..'
import Footer from './components/Footer'

type TCreateForm = TSingleContentModal & Readonly<{
  onSaveClick: () => void
}>

export default function CreateForm({ children, onSaveClick, ...restProps }: TCreateForm) {
  return (
    <SingleFormModal
      footer={<Footer onSaveClick={onSaveClick} />}
      {...restProps}
    >
      {children}
    </SingleFormModal>
  )
}
