import { gql } from '@apollo/client'
import { GRIDS_AUTOCOMPLETE_COMMON_FIELDS } from './grids'
import { SECTORS_COMMON_FIELDS } from './sectors'
import { FLOC_CLASSSES_COMMON_FIELDS } from './refFlocClasses'
import { FLOC_REF_CLAS_TOS_COMMON_FIELDS } from './refFlocClasTos'
import { SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS } from './refSchedulingPlanStrategies'
import { FLOC_REF_PROCESSES_COMMON_FIELDS } from './refFlocProcesses'
import { FLOC_CATALOG_PROFILES_COMMON_FIELDS } from './refFlocCatalogProfiles'
import { FLOC_CATALOGS_COMMON_FIELDS } from './refFlocCatalogs'

export const FLOCS_AUTOCOMPLETE_COMMON_FIELDS = gql`
  ${FLOC_CLASSSES_COMMON_FIELDS}
  ${FLOC_REF_CLAS_TOS_COMMON_FIELDS}
  fragment FlocAutocompleteCommonFields on FunctionalLocation {
    id
    floc
    description
    longDescription
    referenceDocument
    catalogId
    parentId
    sectorId
    gridId
    techClassId
    classSece
    integritySystem
    planningPlantId
    plantWorkCenterId
    strategyId
    classification
    legislation
    docName
    processFunctionId
    corrosionLoop
    consequenceProdId
    shutdownId
    externalRef
    flagStatus
    catalogProfileId
    integrityStatus {
      id
    }
    techClass {
      ...RefFlocClasToCommonFields
      techClass
      classId
      class {
        ...RefFlocClassCommonFields
      }
      id
    }
    _count {
      idwgFlocs
    }
  }
`

export const FLOCS_DETAIL_COMMON_FIELDS = gql`
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  ${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
  ${SECTORS_COMMON_FIELDS}
  ${FLOC_REF_PROCESSES_COMMON_FIELDS}
  ${SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS}
  ${FLOC_CATALOG_PROFILES_COMMON_FIELDS}
  ${FLOC_CATALOGS_COMMON_FIELDS}
  fragment FlocDetailFields on FunctionalLocation {
    ...FlocAutocompleteCommonFields
    catalogProfile {
      ...RefFlocCatalogProfileCommonFields
    }
    sector {
      ...SectorCommonFields
    }
    grid {
      ...GridAutocompleteCommonFields
    }
    strategy {
      ...RefPlanStrategyCommonFields
    }
    processFunction {
      ...RefFlocProcessCommonFields
    }
    planningPlant {
      ...RefFlocCatalogsCommonFields
    }
    plantWorkCenter {
      ...RefFlocCatalogsCommonFields
    }
    plannerGroup {
      ...RefFlocCatalogsCommonFields
    }
    mainWorkCenter {
      ...RefFlocCatalogsCommonFields
    }
    consequenceProd {
      ...RefFlocCatalogsCommonFields
    }
    shutdown {
      ...RefFlocCatalogsCommonFields
    }
  }
`

export const FLOCS_COUNTER_FIELDS = gql`
  fragment FlocCounterFields on FunctionalLocation {
    _count {
      cmls
      damages
      inspectionPoints
      flocDocs
      idwgFlocs
    }
  }
`

export const FLOC_VIEWS_COMMON_FIELDS = gql`
  fragment FlocViewCommonFields on FlocView {
    id
    floc
    description
    longDescription
    referenceDocument
    catalogId
    parentId
    sectorId
    gridId
    techClassId
    classSece
    integritySystem
    planningPlantId
    plantWorkCenterId
    strategyId
    classification
    legislation
    docName
    processFunctionId
    corrosionLoop
    consequenceProdId
    shutdownId
    externalRef
    flagStatus
    catalogProfileId
    techClass_techClass             
    techClass_description           
    techClass_class_description     
    techClass_class_class
    damages_count
  }
`

export const FLOC_VIEWS_DETAIL_FIELDS = gql`
  ${FLOC_VIEWS_COMMON_FIELDS}
  fragment FlocViewDetailFields on FlocView {
    ...FlocViewCommonFields
    catalogProfile_catalog          
    catalogProfile_description      
    processFunction_processFunction 
    processFunction_description     
    strategy_strategy               
    strategy_description
    catalog_category                
    catalog_code                    
    catalog_description             
    consequenceProd_category        
    consequenceProd_code            
    consequenceProd_description     
    shutdown_category               
    shutdown_code                   
    shutdown_description            
    planningPlant_category          
    planningPlant_code              
    planningPlant_description       
    plantWorkCenter_category        
    plantWorkCenter_code            
    plantWorkCenter_description     
    plannerGroup_category           
    plannerGroup_code               
    plannerGroup_description        
    mainWorkCenter_category         
    mainWorkCenter_code             
    mainWorkCenter_description      
    grid_grid                       
    grid_description                
    parent_floc                     
    parent_description              
    sector_sector                   
    sector_description              
    site_site                       
    site_description                
    site_longDescription            
  }
`
