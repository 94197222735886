import { gql } from '@apollo/client/core'
import { WORKORDER_EVENT_TASKS_COMMON_FIELDS, WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS } from '../fragments'

export const WORKORDER_EVENT_TASKS_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_TASKS_COMMON_FIELDS}
${WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS}
query GetEventTasksByEvtId($evtId: Int!) {
  eventTasks (where: {wrkoId: {equals: $evtId}}, orderBy:  [{sort: {sort: asc}}]) {
    ...EventTaskCommonFields
    task {
      ...RefEventTaskCommonFields
    }
  }
}`

export const WORKORDER_EVENT_TASKS_CREATE_MANY = gql`
mutation CreateEventTasks($data: [EventTaskCreateManyInput!]!) {
  createManyEventTask(data: $data) {
    count
  }
}`

export const WORKORDER_EVENT_TASKS_DELETE_MANY = gql`
mutation DeleteEventTasks($ids: [Int!]) {
  deleteManyEventTask (where: {id: {in: $ids}}) {
    count
  }
}`

export const WORKORDER_EVENT_TASKS_DELETE_ONE_BY_ID = gql`
mutation DeleteOneEventTaskById($id: Int!) {
  deleteOneEventTask(where: {id: $id}) {
    id
  }
}
`

export const WORKORDER_EVENT_TASKS_UPDATE_BY_ID = gql`
${WORKORDER_EVENT_TASKS_COMMON_FIELDS}
${WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS}
mutation UpdateOneEventTaskById($id: Int!, $data: EventTaskUncheckedUpdateInput!) {
  updateOneEventTask(where: {id: $id}, data: $data) {
    ...EventTaskCommonFields
    task {
      ...RefEventTaskCommonFields
    }
  }
}
`

export const WORKORDER_EVENT_TASKS_GET_COUNTER = gql`
query GetWorkorderEventTasksCounter {
  aggregateEventTask {
    _count {
      id
    }
  }
}
`
