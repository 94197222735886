import React from 'react'

type TPageContent = Readonly<{
  readonly header?: React.ReactNode
  readonly footer?: React.ReactNode
  readonly children?: React.ReactNode
}>

export default function PageContent({ children, header, footer }: TPageContent) {
  return (
    <div className="flex flex-col flex-grow h-full">
      {header}
      <div className="flex w-full flex-grow flex-col flex-auto overflow-auto">
        {children}
      </div>
      {footer}
    </div>
  )
}
