import { OptimusClientConfig } from '@app/utils/clientConfig'

export type ObjectType = 'event' | 'plan' | 'item' | 'ian'

type EventActionType = 'eventGenerate' | 'flocAdd' | 'flocRemove' | 'taskSelect' | 'drawingSelect' | 'drawingAdd' | 'cmlSelect' | 'cmlCreate' | 'damageSelect' | 'damageCreate' | 'pointSelect' | 'docSelect' | 'docAdd' | 'pictureAddDelete' | 'attachmentAddDelete' | 'notificationAdd' | 'measurement'

type IanMandatoryfieldKeys = 'plannerGroup' | 'plantWorkCenter' | 'mainWorkCenter' | 'userResponsible' | 'reportedBy' | 'creationDate' | 'consequence' | 'severity' | 'likelihood' | 'priority' | 'requiredStartDate' | 'requiredEndDate' | 'shutdown' | 'jobType' | 'ressource' | 'repairSystem' | 'longDescription' | 'deckLevel' | 'detection' | 'object' | 'damage' | 'damageText' | 'workorderId' | 'effect'
type IanActionType = 'updateIANdata' | 'manageDamage' | 'manageAttachment' | 'deleteIAN' | 'generateIANReport'

type ActionTypes = {
  event: EventActionType
  plan: never
  item: never
  ian: IanActionType
}

type ActionsAndFields = {
  [k in ObjectType]: {
    fields: { [key: string]: boolean | string[] }
    actions: {
      [key in ActionTypes[k]]: boolean | string[];
    }
  };
} & {
  ian: {
    mandatoryFields: Record<IanMandatoryfieldKeys, boolean>
  }
}

export class OptimusConfig {
  actionsAndFields?: ActionsAndFields

  getFieldIsDisabled(fieldName: string, type: ObjectType, status?: string) {
    if (!status) {
      return true
    }

    if (!this.actionsAndFields) {
      return true
    }

    const typeConfig = this.actionsAndFields[type].fields
    if (!typeConfig) {
      return true
    }

    const val = typeConfig[fieldName as keyof typeof typeConfig]
    if (val === true || val === false) {
      return !val
    }

    if ((val as string[])?.includes(status)) {
      return false
    }

    return true
  }

  getActionIsDisabled<T extends ObjectType>(type: T, action: ActionTypes[T], status?: string) {
    if (!status) {
      return true
    }

    if (!this.actionsAndFields) {
      return true
    }

    const typeConfig = this.actionsAndFields[type].actions
    if (!typeConfig) {
      return true
    }

    const val = typeConfig[action as keyof typeof typeConfig]
    if (val === true || val === false) {
      return !val
    }

    if ((val as string[])?.includes(status)) {
      return false
    }

    return true
  }

  getActionIsEnabled<T extends ObjectType>(type: T, action: ActionTypes[T], status?: string) {
    return !this.getActionIsDisabled(type, action, status)
  }

  checkMadatoryFields(o: unknown, type: 'ian') {
    if (!o) {
      return []
    }

    const { mandatoryFields } = this.actionsAndFields![type]
    const missingFields = Object.entries(o as Record<string, unknown>)
      .filter(([key, value]) => mandatoryFields[key as keyof typeof mandatoryFields] && !value)
      .map(([key]) => key)
    return missingFields.length ? missingFields : undefined
  }
}

export default class ConfigService extends EventTarget {
  // Singleton
  private static _instance: ConfigService | undefined
  static get instance() {
    return ConfigService._instance
  }

  config?: OptimusConfig

  private constructor(private getAccessToken: () => Promise<string | undefined>) {
    super()
  }

  static async init(getAccessToken: () => Promise<string | undefined>) {
    if (this._instance) {
      return
    }

    this._instance = new ConfigService(getAccessToken)
    this._instance.fetchConfig()
  }

  private async fetchConfig() {
    if (!this.config) {
      const token = await this.getAccessToken()
      const r = await fetch(`${OptimusClientConfig.current.apiRestUrl}/config`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const conf = await r.json()
      this.config = Object.assign(new OptimusConfig(), conf)
      this.dispatchEvent(new Event('loaded'))
    }

    return this.config
  }
}
