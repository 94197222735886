import { gql } from '@apollo/client'

export const FLOC_CLASSSES_COMMON_FIELDS = gql`
    fragment RefFlocClassCommonFields on RefFlocClass {
        id
        description
        icoLabel
        icoColor
        moduleDmge
        moduleCml
        class
        isActive
    }
`
