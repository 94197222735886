import { gql } from '@apollo/client'
import { MEASPOINT_REF_CLASSES_COMMON_FIELDS } from '../fragments/refMeasPointClasses'

export const MEASPOINT_REF_CLASSES_GET_MANY = gql`
${MEASPOINT_REF_CLASSES_COMMON_FIELDS}
query GetAllRefMeasPointClasses($orderBy: [RefMeasPointClassOrderByWithRelationInput!], $where: RefMeasPointClassWhereInput) {
    refMeasPointClasses(orderBy: $orderBy, where: $where) {
      ...RefMeasPointClassCommonFields
    }
}`

export const MEASPOINT_REF_CLASSES_GET_COUNTER = gql`
query GetRefMeasPointClassesCounter {
  aggregateRefMeasPointClass {
    _count {
      id
    }
  }
}
`
