import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteNotificationByIdMutation, UpdateNotificationByIdMutation } from '@app/graphql/__types__/graphql'
import { NOTIFICATIONS_DELETE_BY_ID, NOTIFICATIONS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import useNotificationStore from '@app/stores/notification'
import { useLayoutStore } from '@app/stores/layout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION } from '@app/utils/constants'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { ENOTIFICATION_NOTIF_STATUS } from '@app/utils/enums'

type TDeleteNotificationModal = IConfirmModal

export default function DeleteNotificationModal({ ...restProps }: TDeleteNotificationModal) {
  const config = useOptimusConfig()
  const { changeDeleteNotificationModalDisplay, deleteNotification, activeNotification } = useNotificationStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteNotificationApi] = useMutation<DeleteNotificationByIdMutation>(NOTIFICATIONS_DELETE_BY_ID)
  const [updateNotificationByIdApi] = useMutation<UpdateNotificationByIdMutation>(NOTIFICATIONS_UPDATE_BY_ID)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onConfirm = async () => {
    startLoading()

    try {
      if (config.getActionIsEnabled('ian', 'deleteIAN', activeNotification!.status)) {
        deleteNotification()
        await deleteNotificationApi({
          variables: { id: activeNotification!.id },
        })
        AppNotifications.success(t('message.success.deleteNotification'))
      } else {
        await updateNotificationByIdApi({
          variables: {
            id: activeNotification!.id,
            data: {
              status: {
                set: ENOTIFICATION_NOTIF_STATUS.CANCEL,
              },
            },
          },
        })
        AppNotifications.success(t('message.success.cancelNotification'))
      }
      navigate(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION)
    } catch {
      AppNotifications.error(t('message.error.deleteNotification'))
    }

    stopLoading()
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteNotification')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeleteNotificationModalDisplay(false)}
      {...restProps}
    />
  )
}
