import { gql } from '@apollo/client'

export const INTEGRITY_REF_CONSEQUENCES_GET_COUNTER = gql`
query GetRefIntegrityConsequencesCounter {
  aggregateRefIntegrityConsequence {
    _count {
      consequence
    }
  }
}
`

export const INTEGRITY_REF_CONSEQUENCES_GET_ALL = gql`
query GetAllRefIntegrityConsequences {
  refIntegrityConsequences {
    consequence
    description
    color
    matrixValue
  }
}
`
