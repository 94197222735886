import { Event, GenerateEventWorkpackByIdMutation, GetOneParameterByCodeQuery, Parameter, UpdateEventByIdMutation } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { APP_PARAMTERS_GET_ONE_BY_CODE, WORKORDER_EVENTS_GENERATE_WORKPACK_BY_ID, WORKORDER_EVENTS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { EWORKORDER_EVENT_STATUS } from '@app/utils/enums'
import React, { useEffect, useMemo, useState } from 'react'
import { useLayoutStore } from '@app/stores/layout'
import Separator from '@app/components/Common/Separator'
import { useEventStore } from '@app/stores/event'
import { useHolisAuth } from '@holis/auth-client-react'
import { getWorkflowAvailableActions, WorkflowActionDirection, WorkflowAction, EventWorkflowActionKey } from '@app/utils/functions/workflows'
import DetailsPageFooter from '@app/components/Layout/DetailsPagefooter'
import { RadBadge, RadButton, RadDropdownMenuItem } from '@holis/react-ui/rad'
import { useLocation, useNavigate } from 'react-router-dom'
import { LuXCircle, LuCheckCircle, LuInfo } from 'react-icons/lu'
import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu'
import { navigateOneSegmentBelow } from '@app/utils/functions/navigation'
import ValidationWarning from '@app/pages/Scheduling/Events/Details/components/ReportValidationWarning'
import { DataExportService } from '@app/services/api/DataExportService'
import ExportStatusModal from '@app/components/Common/ExportStatusModal'
import { tailwindColorToBgFgStyle } from '@app/utils/functions'
import Tooltip from 'devextreme-react/tooltip'
import { MdOutlineRefresh } from 'react-icons/md'
import ConfirmModal from '@app/components/Modal/Confirm'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
type TFooter = Readonly<{
  event: Partial<Event>
  ref?: React.RefObject<HTMLDivElement>
  readonly?: boolean
  validationErrors?: string[]
  validationWarnings?: string[]
}>

export default function Footer({ event, readonly, validationErrors, validationWarnings }: TFooter) {
  const [regenerateConfirmModalOpened, setRegenerateConfirmModalOpened] = useState(false)
  const prms = useUserPermissions()
  const { startLoading, stopLoading } = useLayoutStore()
  const { getAccessToken } = useHolisAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const workflowBtnContainerRef = React.createRef<HTMLButtonElement>()
  const [generateWorkpackById] = useMutation<GenerateEventWorkpackByIdMutation>(WORKORDER_EVENTS_GENERATE_WORKPACK_BY_ID)
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID)
  const { user } = useHolisAuth()
  const { t, i18n } = useTranslation()
  const { getGenerateWorkpackBtnDisabledError, activeEvent, eventTasks, eventFlocs, updateEvent, createEventWorkflowFunc, setAssignModalOpened, setActiveEvent, setActionConfirmModalOpened } = useEventStore()
  const generateWorkpackBtnDisabledError = useMemo(() => getGenerateWorkpackBtnDisabledError(), [activeEvent, eventTasks, eventFlocs])
  const [exportStatusModalOpened, setExportStatusModalOpened] = useState(false)
  const [getParam] = useLazyQuery<GetOneParameterByCodeQuery>(APP_PARAMTERS_GET_ONE_BY_CODE)

  const generateButtonErrorToolTipMessage = useMemo(() => {
    if (generateWorkpackBtnDisabledError) {
      return t(`message.error.generateWorkpackBtnDisabled.${generateWorkpackBtnDisabledError}`)
    }
  }, [generateWorkpackBtnDisabledError])

  const generateWorkpackHandler = (action: WorkflowAction) => generateWorkpackById({ variables: { id: event.id! } }).then((res) => {
    if (res.data?.generateWorkpack === 0) {
      createWorkflow(action).then(() => {
        localStorage.setItem('workpackGenerated', '1')
        navigate(0)
      })
      return
    }

    let message = t('message.error.workpackGenerationFailed')
    if (i18n.exists(`message.error.generateWorkpack.${res.data?.generateWorkpack}`)) {
      message = t(`message.error.generateWorkpack.${res.data?.generateWorkpack}`)
    }

    throw new Error(message, {
      cause: 'generate_workpack_failed',
    })
  }).catch((err: Error) => {
    let errorMessage: string = t('message.error.default.title')
    if (err.cause === 'generate_workpack_failed') {
      errorMessage = err.message
    }

    AppNotifications.error(errorMessage)
  })

  const updateEventStatus = async (status: EWORKORDER_EVENT_STATUS) => {
    startLoading()
    let queryResult
    let inspectorId
    let reviewerId
    let approverId
    switch (status) {
      // if unassign, set inspectorId to null
      case EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN:
        inspectorId = { set: null }
        break
      case EWORKORDER_EVENT_STATUS.REP_REVIEWING:
        inspectorId = { set: user?.username }
        break
      case EWORKORDER_EVENT_STATUS.REP_APPROVING:
        reviewerId = { set: user?.username }
        break
      case EWORKORDER_EVENT_STATUS.COMPLETED:
      case EWORKORDER_EVENT_STATUS.WACK:
        approverId = { set: user?.username }
        break
      default:
        break
    }

    try {
      queryResult = await updateEventByIdApi({
        variables: {
          id: event.id!,
          data: {
            status: {
              set: status,
            },
            inspectorId,
            reviewerId,
            approverId,
          },
        },
      })
      AppNotifications.success(t('message.success.eventUpdated'))
      const newEvent = { ...(queryResult!.data?.updateOneEvent ?? {}) } as Partial<Event>

      updateEvent(newEvent)
    } catch {
      AppNotifications.error(t('message.error.default.title'))
    } finally {
      stopLoading()
    }
  }

  const promptForConfirmation = async (action: WorkflowAction) => new Promise<{ cancel: boolean, comment?: string }>((resolve) => {
    setActionConfirmModalOpened(true, action.key as EventWorkflowActionKey, (cancel: boolean, comment?: string) => {
      resolve({ cancel, comment })
    })
  })

  const createWorkflow = async (action: WorkflowAction, comment?: string) => createEventWorkflowFunc?.({
    variables: {
      data: {
        userLogin: user?.username,
        actionDate: new Date(),
        status: `${action.from}|${action.to}`,
        wrkoId: event.id!,
        comment,
        description: t(`label.eventActions.descriptions.${action.key}`),
      },
    },
  })

  const onWorkpackAction = async (action: WorkflowAction) => {
    let workflowComment

    if (action.confirm
      && !['assign_workpack', 'reassign_workpack'].includes(action.key) // assign wrko confirm modal appears after assign prompt
    ) {
      const r = await promptForConfirmation(action)
      if (r.cancel) {
        return
      }

      workflowComment = r.comment
    }

    switch (action.key) {
      case 'generation_of_workpack':
        await generateWorkpackHandler(action)
        return
      case 'assign_workpack':
      case 'reassign_workpack':
        setAssignModalOpened(true)
        return
      default:
        break
    }

    // Double Event review
    if (action.from === EWORKORDER_EVENT_STATUS.PREP_REVIEWING) {
      const reviewLevel = ((await getParam({ variables: { code: 'WORKPACK_PREP_REVIEW_LEVEL' } }))?.data?.parameter as Parameter | undefined)?.value
      if (reviewLevel && Number(reviewLevel) > 1) {
        action.to = EWORKORDER_EVENT_STATUS.PREP_ADDITIONAL_REVIEWING
      }
    }

    const nextStatus = action.to

    if (nextStatus) {
      await updateEventStatus(nextStatus as EWORKORDER_EVENT_STATUS)
    }

    await createWorkflow(action, workflowComment)

    if (nextStatus === EWORKORDER_EVENT_STATUS.WACK) {
      const exportService = new DataExportService(getAccessToken)
      await exportService.launchExport('workorders', event.id!.toString())
    }

    if (action.closeForm) {
      // Close event
      setActiveEvent()
      navigateOneSegmentBelow(location, navigate)
    }
  }

  const onGenerateWorkpackClick = async () => {
    if (activeEvent?.status === EWORKORDER_EVENT_STATUS.PREP_REVIEWING || activeEvent?.status === EWORKORDER_EVENT_STATUS.PREP_APPROVING) {
      setRegenerateConfirmModalOpened(true)
      return
    }

    await generateWorkpackHandler({ key: 'generation_of_workpack', from: event.status, to: event.status } as WorkflowAction)
  }

  const availableActions = useMemo(() => getWorkflowAvailableActions('event', event.status), [event.status])
  const backAction = availableActions.backward
  const forwardAction = availableActions.forward

  useEffect(() => {
    // Workpack regenerated?
    if (localStorage.getItem('workpackGenerated')) {
      AppNotifications.success(t('message.success.workpackGenerated'))
      localStorage.removeItem('workpackGenerated')
    }
  }, [])

  const showExportStatusButton = [EWORKORDER_EVENT_STATUS.EXER, EWORKORDER_EVENT_STATUS.COMPLETED, EWORKORDER_EVENT_STATUS.WACK].includes(event.status as EWORKORDER_EVENT_STATUS)

  const showRegenerateButton = prms.events.prepare && activeEvent?.status && [EWORKORDER_EVENT_STATUS.PREP_REVIEWING.toString(), EWORKORDER_EVENT_STATUS.PREP_APPROVING.toString()].includes(activeEvent.status)

  return (
    <>
      <DetailsPageFooter
        actionButtons={(
          <>
            {/* Validation warnings and errors */}
            {!!validationErrors?.length && <ValidationWarning type="error" warningsOrErrors={validationErrors} />}
            {!!validationWarnings?.length && <ValidationWarning type="warning" warningsOrErrors={validationWarnings} />}

            {/* Action buttons */}
            {showExportStatusButton && (
              <RadButton className="bg-primary gap-2" onClick={() => setExportStatusModalOpened(true)}>
                <LuInfo />
                {' '}
                {t('label.showExportStatus')}
              </RadButton>
            )}
            {backAction && (
              <RadButton className="bg-destructive hover:bg-destructive/90 gap-2" disabled={readonly} onClick={() => onWorkpackAction(backAction)}>
                {backAction.icon ? <backAction.icon /> : <LuXCircle />}
                {' '}
                {t(`label.eventActions.buttons.${backAction.key}`)}
              </RadButton>
            )}
            {forwardAction && (
              <RadButton ref={workflowBtnContainerRef} className="bg-primary gap-2" disabled={readonly || !!validationErrors?.length} onClick={() => onWorkpackAction(forwardAction)}>
                {forwardAction.icon ? <forwardAction.icon /> : <LuCheckCircle />}
                {' '}
                {t(`label.eventActions.buttons.${forwardAction.key}`)}
              </RadButton>
            )}
            { // Secondary actions
              (availableActions.secondary.length > 0 || showRegenerateButton) && (
                <ActionThreeDotsMenu>
                  {
                    availableActions.secondary.map(action => (
                      <RadDropdownMenuItem key={action.key} className="gap-2" onClick={() => onWorkpackAction(action)}>
                        {
                          action.icon
                            ? <action.icon />
                            : (
                                action.dir === WorkflowActionDirection.forward
                                  ? <LuCheckCircle />
                                  : <LuXCircle />
                              )
                        }
                        {t(`label.eventActions.buttons.${action.key}`)}
                      </RadDropdownMenuItem>
                    ))
                  }
                  {showRegenerateButton && (
                    <RadDropdownMenuItem className="gap-2" onClick={onGenerateWorkpackClick}>
                      <MdOutlineRefresh size={16} />
                      {' '}
                      {t('label.regenerateWorkpack')}
                    </RadDropdownMenuItem>
                  )}
                </ActionThreeDotsMenu>
              )
            }

            {/* Generate error tooltip */}
            {workflowBtnContainerRef.current && forwardAction?.key === 'generation_of_workpack' && (!!generateButtonErrorToolTipMessage) && (
              <Tooltip
                position="top"
                target={workflowBtnContainerRef.current}
              >
                {generateButtonErrorToolTipMessage}
              </Tooltip>
            )}
          </>
        )}
      >
        <RadBadge variant="outline" className="py-1 font-normal" style={tailwindColorToBgFgStyle(event.statusWorkOrder?.displayColor)}>{event.statusWorkOrder?.description ?? '-'}</RadBadge>
        <Separator />
        <span className="text-foreground">
          ID: #
          {event.id}
        </span>
        {event.inspectorId
          && (event.status === EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET
            || event.status === EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION)
          && (
            <>
              <Separator />
              <span className="text-foreground">
                {t('label.assignedTo').toUpperCase()}
                {' '}
                <b>{event.inspectorId}</b>
              </span>
            </>
          )}
      </DetailsPageFooter>
      {exportStatusModalOpened && (
        <ExportStatusModal
          opened
          title={event.event}
          itemType="wrko"
          itemId={event.id!}
          onClose={() => setExportStatusModalOpened(false)}
        />
      )}
      {regenerateConfirmModalOpened
        && (
          <ConfirmModal
            open
            destructiveAction
            title={t('label.regenerateWorkpack')}
            description={t('message.warning.regenerateWorkpackDataLoss')}
            onConfirm={() => generateWorkpackHandler({ key: 'generation_of_workpack', from: event.status, to: event.status } as WorkflowAction)}
            onClose={() => setRegenerateConfirmModalOpened(false)}
          />
        )}
    </>
  )
}
