import { useTranslation } from 'react-i18next'

export type TSummaryItem = Readonly<{
  label?: string
  title?: string
  value: number
}>

type TItemsSummary = Readonly<{
  items: TSummaryItem[]
}>

export default function ItemsSummary({ items }: TItemsSummary) {
  const { t } = useTranslation()

  return (
    <div className="flex justify-end">
      <div className="p-2 bg-blue-50 text-gray-800 rounded h-10 flex items-center">
        {items.map(({ label, value, title }, index) => (
          <span key={`summary-${label}`}>
            {title ?? (typeof label === 'string' ? t(`label.${label}${value !== 1 ? 's' : ''}`) : '')}
            {' '}
            {value}
            { index < items.length - 1 && <>&nbsp;|&nbsp;</>}
          </span>
        ))}
      </div>
    </div>
  )
}
