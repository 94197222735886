import { Event, EventInspectionDrawing } from '@app/graphql/__types__/graphql'
import SortableList, { SortableItem } from '@app/components/Common/List/SortableList'
import React, { useEffect } from 'react'
import DrawingViewer from '@app/components/Common/Block/InspectionDrawing/DrawingViewer'
import { useEventStore } from '@app/stores/event'
import { useTranslation } from 'react-i18next'

type TDrawingViewers = Readonly<{
  event: Partial<Event>
}>

export default function DrawingViewers({ event }: TDrawingViewers) {
  const { eventInspectionDrawings, selectedDrawing, inspectionDrawingSortChangeCallback, setSelectedDrawing } = useEventStore()
  const { t } = useTranslation()
  useEffect(() => {
    if (eventInspectionDrawings?.length === 1) {
      setSelectedDrawing(eventInspectionDrawings[0].inspectionDrawing)
    } else {
      setSelectedDrawing()
    }
  }, [eventInspectionDrawings])

  return (
    <div className={`${selectedDrawing || !eventInspectionDrawings?.length ? 'h-full w-full' : 'grid grid-cols-2 gap-4 overflow-auto'}`}>
      {Array.isArray(eventInspectionDrawings)
        ? (
            <SortableList
              items={eventInspectionDrawings as SortableItem[]}
              renderItem={(item: SortableItem) => (
                <DrawingViewer
                  key={`drawing-viewer-${(item as Partial<EventInspectionDrawing>).id!}`}
                  idwg={(item as Partial<EventInspectionDrawing>).inspectionDrawing!}
                  objectItem={event}
                  sortableId={item.id}
                />
              )}
              onSortChange={inspectionDrawingSortChangeCallback}
            />
          )
        : t('label.loading')}
    </div>
  )
}
