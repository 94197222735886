import { gql } from '@apollo/client'

export const SCHEDULING_PLAN_FLOCS_COMMON_FIELDS = gql`
  fragment PlanFlocCommonFields on PlanFloc {
    id
    planId
    flocId
    sort
  }
`
