import React from 'react'
import { PlanFloc, Plan, UpdateOnePlanFlocByIdMutation, FunctionalLocation } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { SCHEDULING_PLAN_FLOCS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import SortableList, { SortableItem } from '@app/components/Common/List/SortableList'
import FlocSelectionModal from '@app/components/Common/Block/Floc/FlocBlock/FlocSelectionModal'
import FlocCard from '@app/components/Common/Block/Floc/FlocBlock/FlocCard'
import usePlanStore from '@app/stores/plan'
import _ from 'lodash'
import { TDbId } from '@app/types/app'
type TFLocList = Readonly<{
  refreshList?: () => void
  plan: Partial<Plan>
  flocs?: Partial<PlanFloc>[]
  onValidateSelection?: (selectedItems: Partial<FunctionalLocation>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[], setFlocsSelectionDisplayed?: (displayed: boolean) => void) => void
  isDeleteButtonDisabled?: boolean
  hasActionButtons?: boolean
  onDeleteClick?: (floc: Partial<PlanFloc>) => void
  filterString?: string
}>

export default function FlocList({ flocs, plan, refreshList, onValidateSelection, isDeleteButtonDisabled, onDeleteClick, filterString }: TFLocList) {
  const [disabledIds, setDisabledIds] = useState<number[]>()
  const [selectedIds, setSelectedIds] = useState<number[]>()
  const [noSchedulingTagFlocList, setNoSchedulingTagFlocList] = useState<Partial<PlanFloc>[]>()
  const { startLoading, stopLoading } = useLayoutStore()
  const { flocsSelectionDisplayed, setFlocsSelectionDisplayed } = usePlanStore()
  const [updatePlanFlocApi] = useMutation<UpdateOnePlanFlocByIdMutation>(SCHEDULING_PLAN_FLOCS_UPDATE_BY_ID)
  const { t } = useTranslation()

  const handleDeleteItemClick = (floc: unknown) => {
    onDeleteClick?.(floc as Partial<PlanFloc>)
  }

  const updateSortHandler = (item: Partial<PlanFloc>, newSortVal: number) => {
    startLoading()
    updatePlanFlocApi({
      variables: {
        id: item.id!,
        data: {
          sort: {
            set: newSortVal,
          },
        },
      },
    }).then(() => {
      AppNotifications.success(t('message.success.planFlocUpdated'))
      refreshList?.()
    }).catch((error: Error) => {
      console.log(error)
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  const handleSortChange = (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => {
    updateSortHandler(dragItem as Partial<PlanFloc>, (dropItem as Partial<PlanFloc>).sort ?? (dropIndex + 2))
  }

  const handleValidateSelection = (selectedItems: Partial<FunctionalLocation>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    onValidateSelection?.(selectedItems, newSelectedIds, oldSelectedIds, setFlocsSelectionDisplayed)
  }

  useEffect(() => {
    setSelectedIds(_.uniq(_.concat(plan.flocId ? [plan.flocId!] : [], flocs?.map((item: Partial<PlanFloc>) => item.flocId!) ?? [])))
    if (plan.flocId) {
      setDisabledIds([plan.flocId!])
    }

    setNoSchedulingTagFlocList(flocs?.filter((floc: Partial<PlanFloc>) => floc.flocId !== plan.flocId) ?? [])
  }, [flocs])

  const schedulingFloc: Partial<PlanFloc> = { plan: plan as Plan, planId: plan.id, functionalLocation: plan.functionalLocation, flocId: plan.flocId }

  // Filter results
  const filtrtedNoSchedulingTagFlocList = filterString
    ? noSchedulingTagFlocList?.filter((floc: Partial<PlanFloc>) => {
      const token = filterString.trim().toLowerCase()
      return floc.functionalLocation?.floc.toLowerCase().includes(token)
        || floc.functionalLocation?.description?.toLowerCase().includes(token)
        || floc.functionalLocation?.techClass?.techClass.toLowerCase().includes(token)
    })
    : noSchedulingTagFlocList

  return (
    <div className="flex flex-col gap-2">
      <FlocCard
        key={`floc-item-${schedulingFloc.id!}`}
        hasContextMenu
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        deleteText={t('label.removeFromPlan')}
        item={schedulingFloc}
      />
      <SortableList
        items={(filtrtedNoSchedulingTagFlocList ?? []) as SortableItem[]}
        renderItem={(item: SortableItem) => (
          <FlocCard
            key={`floc-item-${item.id!}`}
            hasDragBtn
            hasContextMenu
            isDeleteButtonDisabled={isDeleteButtonDisabled}
            item={item as Partial<PlanFloc>}
            deleteText={t('label.removeFromPlan')}
            onDeleteClick={handleDeleteItemClick}
          />
        )}
        onSortChange={handleSortChange}
      />
      {flocsSelectionDisplayed && <FlocSelectionModal open filterSites={typeof plan.functionalLocation?.sector?.site.site === 'string' ? [plan.functionalLocation?.sector?.site.site] : undefined} disabledIds={disabledIds} selectedIds={selectedIds} closeOnConfirm={false} onClose={() => setFlocsSelectionDisplayed(false)} onValidate={handleValidateSelection} />}
    </div>
  )
}
