import { gql } from '@apollo/client'
import { SECTORS_COMMON_FIELDS } from '../fragments/sectors'

export const SECTORS_GET_ALL = gql`
${SECTORS_COMMON_FIELDS}
query GetAllSectors($orderBy: [SectorOrderByWithRelationInput!]) {
    sectors(orderBy: $orderBy) {
        ...SectorCommonFields
    }
}
`

export const SECTORS_GET_COUNTER = gql`
query GetSectorsCounter {
  aggregateSector {
    _count {
      id
    }
  }
}
`
