import { gql } from '@apollo/client'

export const WORKORDER_EVENT_TASKS_COMMON_FIELDS = gql`
    fragment EventTaskCommonFields on EventTask {
      id
      comment
      duration
      nbWorkers
      sort
      taskId
      wrkoId
    }
`
