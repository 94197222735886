import React, { Children, PropsWithChildren, useEffect } from 'react'
import { RadResizablePanel, RadResizablePanelGroup, RadResizableHandle } from '@holis/react-ui/rad'

type TMultipleResizablePanels = Readonly<PropsWithChildren & {
  header?: React.ReactNode
  footer?: React.ReactNode
  panelsMinSize?: number[]
  panelsMaxSize?: number[]
  panelDirection?: 'vertical' | 'horizontal'
  panelsOnResize?: (((size: number, prevSize: number | undefined, refObject: React.RefObject<HTMLDivElement>) => void) | undefined)[]
  panelsDefaultSize?: number[]
  resizerClassName?: string
  autoSaveId?: string | null
  onRender?: (refObject: React.RefObject<HTMLDivElement>) => void
}>

export default function MultipleResizablePanels({ header, footer, panelDirection, panelsOnResize, panelsDefaultSize, resizerClassName, children, onRender, panelsMinSize, panelsMaxSize, autoSaveId }: TMultipleResizablePanels) {
  const nbPanels = Children.count(children)
  const containerRef = React.createRef<HTMLDivElement>()

  useEffect(() => {
    onRender?.(containerRef)
  }, [])

  return (
    <div ref={containerRef} className="flex flex-col h-full w-full">
      {header}
      <RadResizablePanelGroup autoSaveId={autoSaveId} className="w-full flex-grow" direction={panelDirection ?? 'horizontal'}>
        {Children.map(children, (child, index) => (
          <>
            {index > 0 && (panelsDefaultSize?.[0] ?? 0) < 100 && <RadResizableHandle className={resizerClassName} />}
            {((panelsDefaultSize?.[index]) ?? 100 / nbPanels) > 0 && (
              <RadResizablePanel maxSize={panelsMaxSize?.[index]} minSize={panelsMinSize?.[index]} defaultSize={(panelsDefaultSize?.[index]) ?? 100 / nbPanels} className="flex h-full w-full overflow-auto" onResize={(size: number, prevSize: number | undefined) => panelsOnResize?.[index]?.(size, prevSize, containerRef)}>
                {child}
              </RadResizablePanel>
            )}
          </>
        ))}
      </RadResizablePanelGroup>
      {footer}
    </div>
  )
}
