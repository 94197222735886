import React from 'react'

import UserDropdown from '../UserDropdown'

export function Topbar({ children }: React.PropsWithChildren) {
  return (
    <div className="w-full bg-background">
      <div className="flex items-center justify-between p-3">
        <div className="flex items-center w-full ml-3">
          {children}
        </div>

        <div className="ml-3 flex items-center">
          <UserDropdown />
        </div>
      </div>
    </div>
  )
}
