import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { ComponentProps, HTMLAttributes, ReactElement, ReactNode } from 'react'
import { cn } from '@holis/react-ui/utils'
import ActionThreeDotsMenu from '../ActionThreeDotsMenu'

type TFormGroupHeader = Readonly<{
  children?: ReactNode
  actions?: ReactElement
  menuItems?: ReactElement<ComponentProps<typeof RadDropdownMenuItem>>[]
}> & HTMLAttributes<HTMLDivElement>

export default function FormGroupHeader({ children, actions, menuItems, ...restProps }: TFormGroupHeader) {
  return (
    <div {...restProps} className={cn('flex flex-row w-full items-center', restProps.className)}>
      <div className="text-primary text-sm font-bold flex-1 flex uppercase">
        {children}
        { !!menuItems?.length && <div className="ml-2"><ActionThreeDotsMenu menuAlign="start">{menuItems}</ActionThreeDotsMenu></div> }
      </div>
      { actions && <div>{actions}</div> }
    </div>
  )
}
