import { gql } from '@apollo/client/core'
import { FLOCS_AUTOCOMPLETE_COMMON_FIELDS, FLOCS_COUNTER_FIELDS, FLOCS_DETAIL_COMMON_FIELDS, FLOC_VIEWS_COMMON_FIELDS, FLOC_VIEWS_DETAIL_FIELDS } from '../fragments'

export const FLOCS_GET_ALL_AUTOCOMPLETE = gql`
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetAllFlocsAutocomplete {
    functionalLocations {
      ...FlocAutocompleteCommonFields
    }
  }
`

export const FLOC_VIEWS_GET_ALL_AUTOCOMPLETE_BY_SITES = gql`
  ${FLOC_VIEWS_COMMON_FIELDS}
  query GetAllFlocViewsAutocompleteBySites($sites: [String!]) {
    flocViews(where:  {
       site_site:  {
          in: $sites
       }
    }) {
      ...FlocViewCommonFields
    }
  }
`

export const FLOC_VIEWS_GET_ALL_WITH_DAMAGES = gql`
  ${FLOC_VIEWS_COMMON_FIELDS}
  query GetAllFlocViewsWithDamages {
    flocViews(where: {damages_count: {gt: 0}}) {
      ...FlocViewCommonFields
    }
  }
`

export const FLOC_VIEWS_GET_ALL_WITH_DAMAGES_AND_CHILDREN_DAMAGES = gql`
  ${FLOC_VIEWS_COMMON_FIELDS}
  query GetAllFlocViewsWithDamagesAndChildrenDamages {
    flocViews(where: {damages_count_including_children: {gt: 0}}) {
      ...FlocViewCommonFields
    }
  }
`

export const FLOCS_GET_VIEW_ALL_WITH_DETAIL_BY_SITES = gql`
  ${FLOC_VIEWS_DETAIL_FIELDS}
  query GetAllFlocsViewWithDetailBySites($sites: [String!]) {
    flocViews(where:  {
       site_site:  {
          in: $sites
       }
    }) {
      ...FlocViewDetailFields
    }
  }
`

export const FLOCS_GET_BY_IDWG_ID = gql`
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetFlocsByIdwgId($idwgId: Int!) {
    functionalLocations(where: {idwgFlocs: {some: {idwgId: {equals: $idwgId}}}}) {
      ...FlocAutocompleteCommonFields
    }
  }
`

export const FLOCS_GET_BY_DOC_ID = gql`
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetFlocsByDocId($docId: Int!) {
    functionalLocations(where: {flocDocs: {some: {docId: {equals: $docId}}}}) {
      ...FlocAutocompleteCommonFields
    }
  }
`

export const FLOCS_GET_DETAIL_BY_NUMBER = gql`
${FLOCS_DETAIL_COMMON_FIELDS}
${FLOCS_COUNTER_FIELDS}
query GetFlocDetailByNumber($number: String!) {
  functionalLocation(where: {floc: $number}) {
    ...FlocDetailFields
    ...FlocCounterFields
  }
}`

export const FLOCS_CREATE = gql`
${FLOCS_DETAIL_COMMON_FIELDS}
mutation CreateFloc($data: FunctionalLocationUncheckedCreateInput!) {
  createOneFunctionalLocation(data: $data) {
    ...FlocDetailFields
  }
}`

export const FLOCS_DELETE_BY_ID = gql`
mutation DeleteFlocById($id: Int!) {
  deleteOneFunctionalLocation(where: {id: $id}) {
    id
  }
}`

export const FLOCS_UPDATE_BY_ID = gql`
${FLOCS_DETAIL_COMMON_FIELDS}
mutation UpdateFlocById($id: Int!, $data: FunctionalLocationUncheckedUpdateInput!) {
  updateOneFunctionalLocation(data: $data, where: {id: $id}) {
    ...FlocDetailFields
  }
}`

export const FLOCS_GET_COUNTER = gql`
query GetFlocsCounter {
  aggregateFunctionalLocation {
    _count {
      id
    }
  }
}
`
