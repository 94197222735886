import { useMutation } from '@apollo/client'
import { CreatePlanMutation, CreatePlanWorkflowMutation, Plan } from '@app/graphql/__types__/graphql'
import { SCHEDULING_PLAN_WORKFLOWS_CREATE, SCHEDULING_PLANS_CREATE } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import usePlanStore, { ZOD_PLAN_DATAS } from '@app/stores/plan'
import useSchedulingInspectionPlanStore from '@app/stores/scheduling/inspectionPlan'
import { setObjValueByPath } from '@app/utils/functions'
import { useHolisAuth } from '@holis/auth-client-react'

import { useTranslation } from 'react-i18next'
import Form from './components/Form'
import Footer from './components/Footer'
import SingleFormModal from '../../SingleFormModal'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useRef } from 'react'

type TPlanModal = {
  readonly isOpen: boolean
  readonly onOpenChange: (isOpen: boolean) => void
}

export default function PlanModal(props: TPlanModal) {
  const { t } = useTranslation()

  const { editPlan, setEditPlan, updatePlanDataField, updatePlanData, setUpdatePlanData, updatePlan, createPlanWorkflowFunc, updatePlanFieldError, hasError, setCreatePlanWorkflowFunc } = usePlanStore()
  const { fetchPlans } = useSchedulingInspectionPlanStore()
  const [createPlanApi] = useMutation<CreatePlanMutation>(SCHEDULING_PLANS_CREATE)
  const [createPlanWorkflow] = useMutation<CreatePlanWorkflowMutation>(SCHEDULING_PLAN_WORKFLOWS_CREATE)
  const { user } = useHolisAuth()

  const zodFormObject = z.object(ZOD_PLAN_DATAS(t))
  const form = useForm<z.infer<typeof zodFormObject>>(
    {
      resolver: zodResolver(zodFormObject), mode: 'onSubmit', defaultValues: {
        notes: '',
        revision: '',
      },
    })
  const htmlForm = useRef<HTMLFormElement>(null)

  // Update plan value in store (will update updatePlanData)
  const updatePlanDataValue = (field: string, value: unknown) => {
    const editedPlan = { ...editPlan }
    setObjValueByPath(editedPlan, field, value)
    updatePlanDataField(field, value)

    setEditPlan(editedPlan)
  }

  // Watch form value changes
  form.watch((datas, { name, type }) => {
    if (type === 'change') {
      updatePlanDataValue(name!, datas[name!])
    }
  })

  // Handle plan creation error.
  const handlePlanCreateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title')
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`plan`)')) {
        errorMessage = t('message.error.unique.scheduling.plan.plan')
        updatePlanFieldError('plan', true)
      }
    }

    AppNotifications.error(errorMessage)
  }

  // Update plan state in store and create workflow record.
  const updatePlanState = (newData: Partial<Plan>, actionDate: Date, isNew?: boolean) => {
    const newPlan = {
      ...editPlan,
      ...newData,
    } as Partial<Plan>
    if (newPlan.status !== editPlan?.status) {
      createPlanWorkflowFunc?.({
        variables: {
          data: {
            planId: newPlan.id,
            status: `*|${newPlan.status}`,
            actionDate,
            userLogin: user?.username,
            description: t('label.planActions.descriptions.creation_of_plan'),
          },
        },
      })
    }

    updatePlan(newPlan, isNew)
    setUpdatePlanData({})
    fetchPlans?.()
    props.onOpenChange?.(false)
  }

  // Handle actual plan creation.
  const handleCreatePlan = () => {
    if (
      hasError() // Check if any field in updatePlanData has an error.
    ) {
      AppNotifications.error(t('message.error.requiredFields'))
      return
    }

    const actionDate = new Date()
    createPlanApi({ variables: { data: updatePlanData } }).then((newData) => {
      updatePlanState(newData?.data?.createOnePlan as Partial<Plan>, actionDate, true)

      AppNotifications.success(t('message.success.planCreated'))
    })
      .catch((err: Error) => {
        handlePlanCreateError(err)
      })
  }

  useEffect(() => {
    setCreatePlanWorkflowFunc(createPlanWorkflow)
  }, [])

  return (
    <SingleFormModal
      isOpen={props.isOpen}
      title={t('label.planCreation')}
      description={t('label.itemCreateDescription')}
      footer={<Footer onSaveClick={() => htmlForm.current?.requestSubmit()} />}
      onOpenChange={props.onOpenChange}
    >
      <RadForm {...form}>
        <form ref={htmlForm} onSubmit={form.handleSubmit(handleCreatePlan)}>
          <Form />
        </form>
      </RadForm>
    </SingleFormModal>
  )
}
