import { DeleteEventDamageMutation, Event, EventDamage, EventInspectionDrawing, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEventStore } from '@app/stores/event'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import SearchBar from '@app/components/Common/SearchBar'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuCornerDownRight, LuPlusSquare, LuList, LuZap } from 'react-icons/lu'
import InspectionDrawingAccordion from '../../../../../InspectionDrawing/Accordion'
import { searchArray } from '@app/utils/functions'
import AppNotifications from '@app/services/notification'
import { useMutation } from '@apollo/client'
import { WORKORDER_EVENT_DAMAGES_DELETE } from '@app/graphql/requests'
import { EWorkpackRightSideTab } from '@app/utils/enums'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import EventDamageListModal from '@app/components/Common/Block/Damage/DamageBlock/EventDamageListModal'
import { NO_DRAWING_ID } from '@app/utils/constants'
import DamageList from '@app/components/Common/Block/Damage/DamageBlock/DamageList'

type TDamageBlock = Readonly<{
  event: Partial<Event>
  inspectionDrawings?: Partial<InspectionDrawing>[]
  readonly?: boolean
  canAddIAN?: boolean
}>

export default function DamageBlock({ event, readonly, canAddIAN }: TDamageBlock) {
  const { setSelectedDrawing, fetchEventFlocDamages, damageSearchInput, eventDamages, setFilteredEventDamages, fetchEventDamages, eventInspectionDrawings, filteredEventDamages, setDamageSearchInput, setRightSideTabSelectedValue, changeDamageSelectionModalDisplay, selectedDrawing } = useEventStore()
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const [grouped, setGrouped] = useState<boolean>(true)
  const [inspectionDamageListModalDisplayed, changeInspectionDamageListModalDisplay] = useState<boolean>(false)
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>()
  const [deleteEventDamageApi] = useMutation<DeleteEventDamageMutation>(WORKORDER_EVENT_DAMAGES_DELETE)

  const handleRemoveDamage = (damageId: number) => {
    deleteEventDamageApi({
      variables: {
        dmgId: damageId,
        evtId: event.id,
      },
    }).then(() => {
      fetchEventDamages?.()
      fetchEventFlocDamages?.()
      AppNotifications.success(t('message.success.deleteEventDamage'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteEventDamage'))
    })
  }

  const handleDisplayDrawing = (idwg: Partial<InspectionDrawing>) => {
    setRightSideTabSelectedValue(EWorkpackRightSideTab.DRAWINGS)
    setSelectedDrawing(idwg)
  }

  useEffect(() => {
    fetchEventDamages?.()
  }, [fetchEventDamages])

  useEffect(() => {
    const idwgs = eventInspectionDrawings?.map((evtDrawing: Partial<EventInspectionDrawing>) => evtDrawing.inspectionDrawing!) ?? []
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      ...idwgs,
    ])
  }, [eventInspectionDrawings])

  useEffect(() => {
    setFilteredEventDamages(
      searchArray(eventDamages ?? [], damageSearchInput, undefined, ['__typename', 'event']),
    )
  }, [eventDamages, damageSearchInput])

  const dmgsByIdw = useMemo(() => (filteredEventDamages?.reduce((acc, oDmg) => {
    const idwgId = oDmg.damage?.idwgId ?? NO_DRAWING_ID

    if (!acc[idwgId]) {
      acc[idwgId] = []
    }

    acc[idwgId].push(oDmg)
    return acc
  }, {} as Record<number, Partial<EventDamage>[]>) ?? {}), [filteredEventDamages])

  return (
    (
      <>
        <FormGroupHeader
          menuItems={[
            <RadDropdownMenuItem key="group-ungroup" onClick={() => setGrouped(!grouped)}>
              <LuCornerDownRight className="mr-2" />
              {' '}
              {t('label.groupUngroup')}
            </RadDropdownMenuItem>,
            <RadDropdownMenuItem key="manage-damage" disabled={readonly || config.getActionIsDisabled('event', 'damageSelect', event.status)} onClick={() => changeDamageSelectionModalDisplay(true)}>
              <LuPlusSquare className="mr-2" />
              {' '}
              {t('label.manageDamage')}
            </RadDropdownMenuItem>,
            <RadDropdownMenuItem key="display-list" onClick={() => changeInspectionDamageListModalDisplay(true)}>
              <LuList className="mr-2" />
              {' '}
              {t('label.displayList')}
            </RadDropdownMenuItem>,
          ]}
          actions={(
            <SearchBar
              className="w-[300px]"
              onChange={e => setDamageSearchInput(e.target?.value)}
            />
          )}
        >
          <div className="flex items-center">
            <LuZap size={20} className="mr-2" />
            {' '}
            {t('label.damage')}
          </div>
        </FormGroupHeader>

        <div className="mt-3">

          {
            grouped

              ? (
                  <InspectionDrawingAccordion
                    selectedDrawing={selectedDrawing}
                    drawings={drawings}
                    getDrawingElementsCount={idwg => dmgsByIdw?.[idwg.id!]?.length ?? 0}
                    renderDrawingElements={idwg => <DamageList hasMenuContext canAddIAN={canAddIAN} objectItem={event} damages={dmgsByIdw?.[idwg.id!]} handleRemoveDamage={handleRemoveDamage} readonly={readonly} />}
                    onDisplayDrawingClick={handleDisplayDrawing}
                  />
                )

              : <DamageList hasMenuContext objectItem={event} canAddIAN={canAddIAN} damages={filteredEventDamages} handleRemoveDamage={handleRemoveDamage} readonly={readonly} />
          }
        </div>
        {inspectionDamageListModalDisplayed && <EventDamageListModal open hasItems description={`${t('label.event').toUpperCase()} ${event.event}`} sortOrders={['asc']} items={eventDamages} onClose={() => changeInspectionDamageListModalDisplay(false)} />}

      </>
    )
  )
}
