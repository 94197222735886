import { useMutation, useQuery } from '@apollo/client'
import { Cml, EventCml, RefMeasPointValCode, GetEventCmlsByCmlIdQuery, UpdateEventCmlByIdMutation, Event } from '@app/graphql/__types__/graphql'
import { FORMAT_DATE_EU } from '@app/utils/constants'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, IColumn } from '@holis/react-ui'
import { WORKORDER_EVENT_CMLS_GET_BY_CML_ID, WORKORDER_EVENT_CMLS_UPDATE_BY_ID } from '@app/graphql/requests/eventCmls'
import { useLayoutStore } from '@app/stores/layout'
import AppNotifications from '@app/services/notification'
import { EApiOperator } from '@app/utils/enums'
import { useHolisAuth } from '@holis/auth-client-react'
import EventMeasurementQualReading from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementQualReading'
import EventMeasurementTable from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementTable'
import { RadButton } from '@holis/react-ui/rad'
import { LuInfo } from 'react-icons/lu'
import { isTHKPoint, isValidUnit } from '@app/utils/functions'
import EventMeasurementNotes from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementNotes'
import CmlCalculationBlock from './CmlCalculationBlock'
import useCmlStore from '@app/stores/cml'

type TEventCml = Readonly<{
  cml: Partial<Cml>
  eventCml?: Partial<EventCml>
  event?: Partial<Event>
  onCreatedOrUpdated?: (cml: Partial<Cml>, operator?: EApiOperator) => void
}>

export default function EventCmlHistory({ cml, eventCml, event }: TEventCml) {
  const [valCodeModalOpenRow, setValCodeModalOpenRow] = useState<Partial<EventCml>>()
  const { t } = useTranslation()
  const { user } = useHolisAuth()
  const { startLoading, stopLoading } = useLayoutStore()
  const { setActiveCml } = useCmlStore()
  const { data, error, loading, refetch } = useQuery<GetEventCmlsByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_BY_CML_ID, {
    variables: {
      cmlId: cml.id!,
      orderBy: [
        { reportingDate: { sort: 'desc' } },
        { id: 'desc' },
      ],
    },
    fetchPolicy: 'no-cache',
  })

  const [updateEventCmlByIdApi] = useMutation<UpdateEventCmlByIdMutation>(WORKORDER_EVENT_CMLS_UPDATE_BY_ID)

  const handleCancelledChange = (checked: boolean, item: Partial<EventCml>) => {
    startLoading()
    updateEventCmlByIdApi({
      variables: {
        id: item.id!,
        data: {
          cancelled: {
            set: checked,
          },
        },
      },
    }).then((result) => {
      if (result.data?.updateOneEventCml) {
        setActiveCml((result.data?.updateOneEventCml as Partial<EventCml>).cml!)
        refetch()
        AppNotifications.success(t('message.success.eventCmlUpdated'))
      } else {
        AppNotifications.error(t('message.error.default.title'))
      }
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  const updateHandler = (item: Partial<EventCml>, field: string, value: unknown, htmlElem?: HTMLElement) => {
    startLoading()
    updateEventCmlByIdApi({
      variables: {
        id: item.id!,
        data: {
          [field]: {
            set: value,
          },
          reportingDate: {
            set: new Date(),
          },
          reader: {
            set: user?.username,
          },
        },
      },
    })
      .then((result) => {
        if (result.data?.updateOneEventCml) {
          setActiveCml((result.data?.updateOneEventCml as Partial<EventCml>).cml!)
          refetch()
          AppNotifications.success(t('message.success.eventCmlUpdated'))
        } else {
          AppNotifications.error(t('message.error.default.title'))
        }
      })
      .catch(() => {
        if (htmlElem) {
          htmlElem.style.border = '1px solid red'
        }

        AppNotifications.error(t('message.error.default.title'))
      })
      .finally(() => {
        stopLoading()
      })
  }

  const isEditableRow = (rowData: unknown) => !!event?.id && (rowData as Partial<EventCml>).wrkoId === event?.id

  const cellsRenderer = () => ({
    cancelled: (val: string, rowData: unknown) => <Checkbox checkedIconClassName="text-primary" checked={!val} onChange={(checked: boolean) => handleCancelledChange(!checked, rowData as Partial<EventCml>)} />,
    quantReading: (val: string) => <span>{val}</span>,
    notes(val: string) {
      return (
        <EventMeasurementNotes notes={val} />
      )
    },
    reading(_val: string, rowData: unknown) {
      return (
        <EventMeasurementQualReading<EventCml, RefMeasPointValCode> updateHandler={updateHandler} dataRow={rowData} isEditableRow={isEditableRow} />
      )
    },
    reportingDate(_val: string, rowData: unknown) {
      const dataRow = rowData as Partial<EventCml>
      return <span>{dataRow.reportingDate ? moment(dataRow.reportingDate).format(FORMAT_DATE_EU) : ''}</span>
    },

    event(_val: string, rowData: unknown) {
      const dataRow = rowData as Partial<EventCml>
      return dataRow.event
        ? (
            <span className={`flex justify-between flex-row items-center pr-2 ${dataRow.wrkoId === event?.id ? 'font-bold' : ''}`}>
              {dataRow.event.event}
              {' '}
              <RadButton variant="ghost" size="icon"><LuInfo className="h-4 w-4" /></RadButton>
            </span>
          )
        : ''
    },
  })
  const columns: IColumn[] = [
    {
      field: 'cancelled',
      title: t('label.calc'),
      type: 'boolean',
      width: 60,
      cellRenderer: 'cancelled',
      filter: false,
    },
    {
      field: 'reportingDate',
      title: t('label.date'),
      type: 'date',
      cellRenderer: 'reportingDate',
      width: 100,
      filter: false,
      sort: 'desc',
    },
    {
      field: 'reading',
      title: t('label.reading'),
      type: 'string',
      width: 250,
      cellRenderer: 'reading',
      filter: false,
    },
    {
      field: 'quantReading',
      title: <>
        {t('label.measurement')}
        {' '}
        {isValidUnit(cml.codeGroup?.unit) && <span className="lowercase">{`(${cml.codeGroup!.unit!.toLowerCase()})`}</span>}
      </>,
      type: 'string',
      width: 150,
      cellRenderer: 'quantReading',
      filter: false,
    },
    {
      field: 'notes',
      title: t('label.notes'),
      type: 'string',
      width: 50,
      cellRenderer: 'notes',
      filter: false,
    },
    {
      field: 'event',
      title: t('label.event'),
      type: 'string',
      cellRenderer: 'event',
      width: 140,
      filter: false,
    },
  ]

  return (
    <EventMeasurementTable<EventCml, RefMeasPointValCode>
      className="flex flex-col w-full px-2"
      beforeTableComponent={isTHKPoint(cml) ? <CmlCalculationBlock cml={cml} /> : undefined}
      selectedRow={valCodeModalOpenRow}
      updateHandler={updateHandler}
      eventCml={eventCml}
      items={((data?.eventCmls ?? []) as Partial<EventCml>[]).filter((item: Partial<EventCml>) => item.wrkoId !== event?.id)}
      isLoading={loading}
      error={!!error}
      cellRenderers={cellsRenderer()}
      columns={columns}
      onQualReadingSelectionModalClose={() => setValCodeModalOpenRow(undefined)}
    />

  )
}
