import { DeepPartial } from '@apollo/client/utilities'
import { AssetSite, Permission, Role, UserRole } from '@app/graphql/__types__/graphql'
import { AuthUser } from '@holis/auth-client'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type RoleWithUsers = DeepPartial<Role> & { usersIds: string[] }
type EditedRole = Omit<Partial<Role>, 'permissions'> & { permissions: Omit<Permission, 'role' | 'id' | 'roleId'>[] }
export type UserWithRole = AuthUser & { userRole?: Partial<UserRole> }
type State = {
  users?: UserWithRole[]
  editingUser?: UserWithRole
  editingUserRoleId?: number
  editingUserNotifyNewReport?: boolean
  editingUserSites?: AssetSite[]
  roles?: RoleWithUsers[]
  editingRole?: EditedRole
  fetchAppUsers?: () => void
}

const initialState: State = {}

type Actions = {
  setUsers: (users: UserWithRole[]) => void
  setEditingUser: (user: UserWithRole | undefined) => void
  setEditingUserRoleId: (roleId: number | undefined) => void
  setEditingUserNotifyNewReport: (editingUserNotifyNewReport: boolean) => void
  setEditingUserSites: (sites: AssetSite[]) => void
  setRoles: (roles: RoleWithUsers[]) => void
  setUserRole: (userId: string, roleId?: number) => void
  setEditingRole: (role: EditedRole | undefined) => void
  updateRole: (role: EditedRole) => void
  addRole: (role: EditedRole) => void
  removeRole: (roleId: number) => void
  setFetchAppUsers: (fetchAppUsers?: () => void) => void
}

type UsersState = State & Actions

const useUsersStore = create<UsersState>()(
  immer(set => ({
    ...initialState,
    setUsers(users) {
      set({ users })
    },
    setEditingUser(user) {
      set({ editingUser: user })
      if (!user) {
        set({ editingUserRoleId: undefined })
      }
    },
    setEditingUserRoleId(roleId) {
      set({ editingUserRoleId: roleId })
    },
    setRoles(roles) {
      set({ roles })
    },
    setUserRole(userId, roleId) {
      set((state) => {
        // Remove user from all existing roles
        state.roles?.forEach((role) => {
          role.usersIds = role.usersIds.filter(id => id !== userId)
        })

        if (!roleId) {
          return
        }

        // Add user to the new role
        const role = state.roles?.find(role => role.id === roleId)
        if (role) {
          role.usersIds.push(userId)
        }
      })
    },
    setEditingRole(role) {
      set({ editingRole: role })
    },
    updateRole(role) {
      set((state) => {
        const roles = state.roles?.map((r) => {
          if (r.id === role.id) {
            return { ...r, ...role }
          }

          return r
        })

        if (roles) {
          state.roles = roles
        }
      })
    },
    addRole(role) {
      set((state) => {
        if (state.roles) {
          state.roles.push(
            { ...role, usersIds: [] },
          )
        }
      })
    },
    removeRole(roleId) {
      set((state) => {
        if (state.roles) {
          state.roles = state.roles.filter(role => role.id !== roleId)
        }
      })
    },
    setEditingUserSites(sites) {
      set((state) => {
        state.editingUserSites = sites
      })
    },
    setEditingUserNotifyNewReport(editingUserNotifyNewReport) {
      set({ editingUserNotifyNewReport })
    },
    setFetchAppUsers(fetchAppUsers) {
      set({ fetchAppUsers })
    },
  })),
)

export default useUsersStore
