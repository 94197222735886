import { LuRefreshCw } from 'react-icons/lu'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type TReportGeneratingMessageWrapper = {
  readonly isReportGenerating: boolean
  readonly children?: ReactNode
}
export default function ReportGeneratingMessageWrapper({ isReportGenerating, children }: TReportGeneratingMessageWrapper) {
  const { t } = useTranslation()
  return (
    <div className="flex w-full">
      <div className="flex-1 flex flex-row items-center">
        {
          isReportGenerating && (
            <div className="flex items-center gap-2 ml-4 border-l pl-4">
              <LuRefreshCw className="animate-spin" />
              {' '}
              {t('label.generatingReport')}
            </div>
          )
        }
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}
