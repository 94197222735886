import AppNotifications from '@app/services/notification'

import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateFlocMutation, FunctionalLocation, GetFlocDetailByNumberQuery } from '@app/graphql/__types__/graphql'
import { FLOCS_CREATE, FLOCS_GET_DETAIL_BY_NUMBER } from '@app/graphql/requests'
import { setObjValueByPath } from '@app/utils/functions'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import Form from './components/Form'
import { useLayoutStore } from '@app/stores/layout'
import { useNavigate } from 'react-router-dom'
import { ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL } from '@app/utils/constants'
import { useFlocStore, ZOD_FLOC_DATAS } from '@app/stores/methodEngineering/floc'
import CreateForm from '@app/components/Modal/SingleFormModal/CreateForm'

type TFlocModal = Readonly<{
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onCreated?: (floc: Partial<FunctionalLocation>) => void
}>

export default function CreateFlocModal({ isOpen, onOpenChange, onCreated }: TFlocModal) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLayoutStore()
  const { editFloc, setEditFloc, updateDataField, updateData, updateFieldError, resetData, fetchFlocs } = useFlocStore()
  const [createFlocApi] = useMutation<CreateFlocMutation>(FLOCS_CREATE)
  const [getFlocByNumberApi] = useLazyQuery<GetFlocDetailByNumberQuery>(FLOCS_GET_DETAIL_BY_NUMBER)

  const zodFormObject = z.object(ZOD_FLOC_DATAS())
  const form = useForm<z.infer<typeof zodFormObject>>(
    {
      resolver: zodResolver(zodFormObject),
      mode: 'onSubmit',
    })
  const htmlForm = useRef<HTMLFormElement>(null)

  // Update floc value in store (will update updateData)
  const updateFlocDataValue = (field: string, value: unknown) => {
    if (field === 'floc') {
      value = (value as string).toUpperCase()
    }

    const editedFloc = { ...editFloc }
    setObjValueByPath(editedFloc, field, value)
    updateDataField(field, value)

    setEditFloc(editedFloc)
  }

  // Watch form value changes
  form.watch((datas, { name, type }) => {
    if (type === 'change') {
      updateFlocDataValue(name!, datas[name!])
    }
  })

  // Handle floc creation error.
  const handleFlocCreateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title')
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`floc`)')) {
        if (AppNotifications.timeoutId) {
          clearTimeout(AppNotifications.timeoutId)
        }

        errorMessage = t('message.error.unique.methodEngineering.functionalLocation.floc')
        updateFieldError('floc', true)
        form.setError('floc', {
          message: errorMessage,
        })
        return
      }
    }

    AppNotifications.error(errorMessage)
  }

  const checkNumberExists = async (): Promise<boolean> => {
    const result = await getFlocByNumberApi({
      variables: {
        number: updateData?.floc,
      },
    })
    return !!result.data?.functionalLocation
  }

  // Handle actual document creation.
  const handleCreateFloc = async () => {
    // Check number exists
    const numberExists = await checkNumberExists()
    console.log(numberExists)
    if (numberExists) {
      if (AppNotifications.timeoutId) {
        clearTimeout(AppNotifications.timeoutId)
      }

      updateFieldError('floc', true)
      form.setError('floc', {
        message: t('message.error.unique.documentation.inspectionDrawing.floc'),
      })
      return
    }

    updateFloc()
  }

  // Handle actual floc creation.
  const updateFloc = () => {
    startLoading()
    createFlocApi({ variables: { data: updateData } }).then((queryResult) => {
      const newFloc = queryResult.data?.createOneFunctionalLocation as Partial<FunctionalLocation>
      if (!onCreated) {
        AppNotifications.success(t('message.success.flocCreated'))
        fetchFlocs?.()
        resetData()
        navigate(ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL.replace(/:number/, encodeURIComponent(newFloc.floc!)))
        onOpenChange(false)
      } else {
        onCreated!(queryResult.data?.createOneFunctionalLocation as Partial<FunctionalLocation>)
      }
    }).catch((err: Error) => {
      handleFlocCreateError(err)
    }).finally(stopLoading)
  }

  return (
    <CreateForm
      isOpen={isOpen}
      title={t('label.flocCreation')}
      description={t('label.itemCreateDescription')}
      onSaveClick={() => htmlForm.current?.requestSubmit()}
      onOpenChange={onOpenChange}
    >
      <RadForm {...form}>
        <form ref={htmlForm} onSubmit={form.handleSubmit(() => handleCreateFloc())}>
          <Form />
        </form>
      </RadForm>
    </CreateForm>
  )
}
