import { gql } from '@apollo/client'
import { CML_REF_CLASSES_COMMON_FIELDS } from '../fragments/refCmlClasses'

export const CML_REF_CLASSES_GET_MANY = gql`
${CML_REF_CLASSES_COMMON_FIELDS}
query GetAllRefCmlClasses($orderBy: [RefCmlClassOrderByWithRelationInput!], $where: RefCmlClassWhereInput) {
    refCmlClasses(orderBy: $orderBy, where: $where) {
      ...RefCmlClassCommonFields
    }
}`

export const CML_REF_CLASSES_GET_COUNTER = gql`
query GetRefCmlClassesCounter {
  aggregateRefCmlClass {
    _count {
      id
    }
  }
}
`
