import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Cml, Picture, Event, GetPicturesByQuery } from '@app/graphql/__types__/graphql'
import { PICTURES_GET_MANY_BY } from '@app/graphql/requests'
import { TCarouselCallback } from '@holis/react-ui'
import BasePictureCarousel from '@app/components/Common/Block/Picture/Block/PictureCarousel'
import { EApiOperator } from '@app/utils/enums'

type TPictureCarousel = Readonly<{
  onCarouselRender?: TCarouselCallback
  cml: Partial<Cml>
  event?: Partial<Event>
  readonly?: boolean
  onChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void
}>

export default function PictureCarousel({ cml, onCarouselRender, event, onChanged, readonly }: TPictureCarousel) {
  const [getPicturesApi, { data, loading, error, refetch }] = useLazyQuery<GetPicturesByQuery>(PICTURES_GET_MANY_BY)
  const [isLoading, setLoading] = useState<boolean>(loading)

  useEffect(() => {
    getPicturesApi({
      variables: {
        data: {
          cmlId: {
            equals: cml.id!,
          },
          ...(event?.id ? { wrkoId: { equals: event.id! } } : {}),
        },
        orderBy: [
          { date: { sort: 'desc' } },
          { id: 'desc' },
        ],
      },
      fetchPolicy: 'no-cache',
    })
  }, [cml.id, cml.flocId, event?.id])

  const handleImagesChanged = (images?: Partial<Picture>[], operator?: EApiOperator) => {
    refetch()
    onChanged?.(images, operator)
  }

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  return (
    <div className="flex flex-col w-full">
      <BasePictureCarousel readonly={readonly} pictures={data?.pictures as Partial<Picture>[]} isLoading={isLoading} uploadParameters={{ cmlId: cml!.id!, ...(event?.id ? { wrkoId: event?.id } : {}), flocId: cml.flocId }} error={error} onChanged={handleImagesChanged} onCarouselRender={onCarouselRender} />
    </div>
  )
}
