import { useMutation } from '@apollo/client'
import { FunctionalLocation, Event, CreateEventMutation } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENTS_CREATE } from '@app/graphql/requests'
import { setObjValueByPath } from '@app/utils/functions'
import { useHolisAuth } from '@holis/auth-client-react'

import { useTranslation } from 'react-i18next'
import Form from './components/Form'
import Footer from './components/Footer'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRef } from 'react'
import SingleFormModal from '@app/components/Modal/SingleFormModal'
import FlocSelectionModal from '../../Floc/FlocBlock/FlocSelectionModal'
import { useEventStore, ZOD_CREATE_EVENT_DATAS } from '@app/stores/event'
import AppNotifications from '@app/services/notification'

type TEventModal = Readonly<{
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onCreated?: (event: Partial<Event>) => void
}>

export default function EventNewModal({ onOpenChange, isOpen, onCreated }: TEventModal) {
  const { t } = useTranslation()

  const { editEvent, setEditEvent, updateDataField, updateData, updateEvent, createEventWorkflowFunc, updateFieldError, hasError, activeEvent, setActiveEvent, setUpdateData, fetchEvents } = useEventStore()
  const [createEventApi] = useMutation<CreateEventMutation>(WORKORDER_EVENTS_CREATE)
  const { user } = useHolisAuth()

  const zodFormObject = z.object(ZOD_CREATE_EVENT_DATAS(t))
  const form = useForm<z.infer<typeof zodFormObject>>(
    {
      resolver: zodResolver(zodFormObject), mode: 'onSubmit', defaultValues: {
        description: '',
        typeId: undefined,
        schedulingTagId: editEvent?.schedulingTagId,
      },
    })
  const htmlForm = useRef<HTMLFormElement>(null)

  // Update event value in store (will update updateEventData)
  const updateEventDataValue = (field: string, value: unknown) => {
    const editedEvent = { ...editEvent }
    setObjValueByPath(editedEvent, field, value)
    updateDataField(field, value)

    setEditEvent(editedEvent)
  }

  // Watch form value changes
  form.watch((datas, { name, type }) => {
    if (type === 'change') {
      updateEventDataValue(name!, datas[name!])
    }
  })

  // Handle event creation error.
  const handleEventCreateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title')
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`event`)')) {
        errorMessage = t('message.error.unique.anomalyManagement.event.event')
        updateFieldError('event', true)
      }
    }

    AppNotifications.error(errorMessage)
  }

  const onSelectFloc = (selectedItems: Partial<FunctionalLocation>[]) => {
    if (selectedItems.length) {
      const floc = selectedItems[0]
      form.setValue('schedulingTagId', floc.id!)
      setActiveEvent({
        ...activeEvent,
        schedulingTag: floc as FunctionalLocation,
        schedulingTagId: floc.id,
      })
    } else {
      setActiveEvent()
    }
  }

  // Update event state in store and create workflow record.
  const updateEventState = async (newData: Partial<Event>) => {
    const actionDate = new Date()

    const newEvent = {
      ...editEvent,
      ...newData,
    } as Partial<Event>

    await createEventWorkflowFunc?.({
      variables: {
        data: {
          eventId: newEvent.id,
          status: `*|${newEvent.status}`,
          date: actionDate,
          userLogin: user?.username,
          description: t('label.eventActions.descriptions.creation_of_event'),
        },
      },
    })

    updateEvent(newEvent)
    setUpdateData({})
    fetchEvents?.()
    onOpenChange?.(false)
  }

  // Handle actual event creation.
  const handleCreateEvent = async () => {
    if (
      hasError(undefined, ['description', 'typeId']) // Check if any field in updateEventData has an error.
    ) {
      AppNotifications.error(t('message.error.requiredFields'))
      return
    }

    try {
      const newData = await createEventApi({
        variables: {
          data: updateData,
        },
      })

      const event = newData.data?.createOneEvent as Partial<Event>
      await updateEventState(event)

      onCreated?.(event as Partial<Event>)

      AppNotifications.success(t('message.success.addEvent'))
    } catch (err) {
      console.log(err)
      handleEventCreateError(err as Error)
    }
  }

  return (
    <>
      {!!editEvent?.schedulingTag && (
        <SingleFormModal
          isOpen={isOpen}
          title={t('label.eventCreation')}
          description={(
            <>
              <div className="text-lg">
                <span className="text-primary font-semibold">
                  {editEvent?.schedulingTag?.floc ?? ''}
                  {' '}
                  -
                  {' '}
                </span>
                <span>{editEvent?.schedulingTag?.description ?? ''}</span>
              </div>
              <div>{t('label.itemCreateDescription')}</div>
            </>
          )}
          footer={<Footer onSaveClick={() => htmlForm.current?.requestSubmit()} />}
          onOpenChange={onOpenChange}
        >
          <RadForm {...form}>
            <form ref={htmlForm} onSubmit={form.handleSubmit(handleCreateEvent, console.log)}>
              <Form />
            </form>
          </RadForm>
        </SingleFormModal>
      )}
      <FlocSelectionModal isRequired open={!editEvent?.schedulingTag} isMultiple={false} onClose={() => setActiveEvent()} onValidate={onSelectFloc} />
    </>
  )
}
