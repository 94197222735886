export default class PermissionEvents {
  public static resourceName = 'events'

  public import = false
  public create = false
  public release = false
  public prepare = false
  public review = false
  public secondreview = false
  public approve = false
  public assign = false
  public sendtoexec = false
  public execinsp = false
  public preparereport = false
  public reviewreport = false
  public approvereport = false
  public publishreport = false
}
