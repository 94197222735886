import { useQuery } from '@apollo/client'
import AppCombobox from '@app/components/Common/Form/AppCombobox'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper'
import { GetAllRefEventTypesQuery, GetAllRefFlocCatalogsQuery, RefFlocCatalogsCommonFieldsFragment, RefEventTypeCommonFieldsFragment } from '@app/graphql/__types__/graphql'
import { WORKORDER_REF_EVENT_TYPES_GET_MANY } from '@app/graphql/requests'
import { FLOC_CATALOGS_GET_MANY } from '@app/graphql/requests/refFlocCatalogs'
import useItemStore, { MAX_LENGTH_VALIDATORS } from '@app/stores/item'
import { EFLOC_CATALOGS_CATEGORY } from '@app/utils/enums'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { RadFormField, RadInput, RadTextarea, RadCheckbox, RadLabel } from '@holis/react-ui/rad'
import { t } from 'i18next'
import { ChangeEvent, useMemo } from 'react'

export default function Form() {
  const config = useOptimusConfig()
  const { activeItem } = useItemStore()

  const refEventTypeResult = useQuery<GetAllRefEventTypesQuery>(WORKORDER_REF_EVENT_TYPES_GET_MANY)
  const flocCatalogResult = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY)

  const parseNumberFieldVal = (field: { onChange: (val: number | undefined) => void }) =>
    (e: ChangeEvent<HTMLInputElement>) => field.onChange(e.target.value ? Number(e.target.value) : undefined)

  const flocCatalogItemToComboboxItem = (item: Partial<RefFlocCatalogsCommonFieldsFragment>) => (
    { label: `[${item.code}] - ${item.description!}`, value: item.id }
  )

  const flocCatalogItemsPlannerGroup = useMemo(
    () => flocCatalogResult.data?.findManyRefFlocCatalogs
      ?.filter((item: Partial<RefFlocCatalogsCommonFieldsFragment>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_GROUP)
      .map(flocCatalogItemToComboboxItem)
      ?? [], [flocCatalogResult.data])

  const flocCatalogItemsMainWorkCenter = useMemo(
    () => flocCatalogResult.data?.findManyRefFlocCatalogs
      ?.filter((item: Partial<RefFlocCatalogsCommonFieldsFragment>) => item.category === EFLOC_CATALOGS_CATEGORY.MAIN_WORK_CENTER)
      .map(flocCatalogItemToComboboxItem)
      ?? [], [flocCatalogResult.data])

  const itemEventTypes = useMemo(
    () => refEventTypeResult.data?.refEventTypes?.map(
      (t: Partial<RefEventTypeCommonFieldsFragment>) => (
        {
          label: `[${t.type!}] - ${t.description!}`,
          value: t.id!,
        })) ?? [], [refEventTypeResult.data])

  const fieldIsDisabled = (f: { name: string }) => config.getFieldIsDisabled(f.name, 'item', activeItem?.plan?.status)

  return (
    <div className="grid grid-cols-2 gap-2">

      <div>
        { /* Identification */}
        <FormGroupHeader>{t('label.identification')}</FormGroupHeader>
        <div className="p-2 flex flex-wrap">
          <RadFormField
            name="itemNumber"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.code')} className="w-1/2 pr-8">
                  <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.ITEM_NUMBER} disabled={fieldIsDisabled(field)} />
                </InputLabelWrapper>
              )
            }
          />
          <RadFormField
            name="typeId"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.type')} className="w-1/2">
                  <AppCombobox
                    required
                    {...field}
                    items={itemEventTypes}
                    disabled={fieldIsDisabled(field)}
                  />
                </InputLabelWrapper>
              )
            }
          />
          <RadFormField
            name="description"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.description')} className="w-full pt-2">
                  <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.DESCRIPTION} disabled={fieldIsDisabled(field)} />
                </InputLabelWrapper>
              )
            }
          />
          <RadFormField
            name="longDescription"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.longDescription')} className="w-full pt-2">
                  <RadTextarea rows={6} {...field} maxLength={MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION} disabled={fieldIsDisabled(field)} />
                </InputLabelWrapper>
              )
            }
          />
        </div>
      </div>

      <div>
        { /* Scheduling */}
        <FormGroupHeader>{t('label.scheduling')}</FormGroupHeader>
        <div className="p-2 flex flex-wrap">
          <RadFormField
            name="period"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.period')} className="pr-12">
                  <RadInput {...field} className="max-w-[110px]" type="number" disabled={fieldIsDisabled(field)} onChange={parseNumberFieldVal(field)} />
                </InputLabelWrapper>
              )
            }
          />
          <RadFormField
            name="callHorizon"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.callHorizon')} className="pr-12">
                  <RadInput {...field} className="max-w-[110px]" type="number" disabled={fieldIsDisabled(field)} onChange={parseNumberFieldVal(field)} />
                </InputLabelWrapper>
              )
            }
          />
          <RadFormField
            name="schedPriority"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.schedulingPriority')}>
                  <div className="flex gap-2 h-full items-start pt-2">
                    <RadCheckbox id="isPriority" {...field} checked={field.value} disabled={fieldIsDisabled(field)} onCheckedChange={e => field.onChange(e)} />
                    <RadLabel htmlFor="isPriority" className="text-gray-600 text-xs">{t('label.isPriorityQuestion')}</RadLabel>
                  </div>
                </InputLabelWrapper>
              )
            }
          />
        </div>

        <div className="mt-2">
          { /* Responsabilities */}
          <FormGroupHeader>{t('label.responsabilities')}</FormGroupHeader>
          <div className="p-2 flex flex-wrap">
            <RadFormField
              name="plannerGroupId"
              render={
                ({ field, fieldState }) => (
                  <InputLabelWrapper fieldState={fieldState} label={t('label.plannerGroup')} className="w-1/2 pr-2">
                    <AppCombobox
                      {...field}
                      items={flocCatalogItemsPlannerGroup}
                      disabled={fieldIsDisabled(field)}
                    />
                  </InputLabelWrapper>
                )
              }
            />
            <RadFormField
              name="mainWorkCenterId"
              render={
                ({ field, fieldState }) => (
                  <InputLabelWrapper fieldState={fieldState} label={t('label.mainWorkCenter')} className="w-1/2">
                    <AppCombobox
                      {...field}
                      items={flocCatalogItemsMainWorkCenter}
                      disabled={fieldIsDisabled(field)}
                    />
                  </InputLabelWrapper>
                )
              }
            />
          </div>
        </div>
      </div>

    </div>
  )
}
