import { useEventPerformanceDashboardStore } from '@app/stores/event/performanceDashboard'
import { RadButton } from '@holis/react-ui/rad'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuFilterX, LuListChecks } from 'react-icons/lu'

export default function ClassFilters() {
  const { data, classFilters, setClassFilters } = useEventPerformanceDashboardStore()
  const [classOptions, setClassOptions] = useState<string[]>()
  const [t] = useTranslation()

  const changeFilter = (value: string) => {
    let newFilters: string[] | undefined = [...(classFilters ?? [])]
    if (classFilters?.includes(value)) {
      newFilters = classFilters.filter(filter => filter !== value)
    } else {
      newFilters.push(value)
    }

    setClassFilters(newFilters)
  }

  const clearFilters = () => {
    setClassFilters([])
  }

  const selectAll = () => {
    setClassFilters(classOptions)
  }

  useEffect(() => {
    if (typeof data !== 'undefined') {
      const classes = _.uniq((data ?? []).map(item => item.class).filter(_class => typeof _class === 'string' && _class !== null)) as string[]
      setClassOptions(classes)
    }
  }, [data])

  useEffect(() => {
    if (typeof classFilters === 'undefined' && Array.isArray(classOptions)) {
      setClassFilters(classOptions)
    }
  }, [classOptions])

  return (
    <div className="flex flex-col p-2 border border-cyan-700 w-full">
      <h2 className="flex items-center font-semibold text-sm pb-2 mb-4 border-b justify-between h-8">
        {t('label.class')}
        <div className="flex items-center">
          <RadButton variant="ghost" size="sm" disabled={classFilters?.length === classOptions?.length} onClick={selectAll}><LuListChecks className="w-4 h-4 text-gray-700" /></RadButton>
          <RadButton variant="ghost" size="sm" disabled={!classFilters?.length} onClick={clearFilters}><LuFilterX className="w-4 h-4 text-gray-700" /></RadButton>
        </div>
      </h2>
      <div className="flex flex-col gap-2">
        {classOptions?.map(value => <RadButton key={value} className={`${classFilters?.includes(value) ? 'bg-cyan-700 text-white' : 'bg-white  text-cyan-700'} hover:bg-cyan-600 hover:text-white border-cyan-700`} variant={`${classFilters?.includes(value) ? 'default' : 'outline'}`} onClick={() => changeFilter(value)}>{value}</RadButton>)}
      </div>
    </div>
  )
}
