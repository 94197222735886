import { RadAlertDialog, RadAlertDialogContent, RadAlertDialogHeader, RadAlertDialogTitle, RadAlertDialogDescription, RadAlertDialogAction, RadAlertDialogCancel, RadAlertDialogFooter } from '@holis/react-ui/rad'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { cn } from '@holis/react-ui/utils'

export type IConfirmModal = Readonly<{
  title?: string
  description?: string
  onConfirm?: () => void
  onCancel?: () => void
  onClose?: () => void
  closeOnConfirm?: boolean
  confirmButtonLabel?: string | false
  cancelButtonLabel?: string | false
  closeOnCancel?: boolean
  open?: boolean
  children?: React.ReactNode
  destructiveAction?: boolean
  className?: string
}>

export default function ConfirmModal({ open, onCancel, onConfirm, onClose, destructiveAction, closeOnCancel, closeOnConfirm, title, description, confirmButtonLabel, cancelButtonLabel, children, className }: IConfirmModal) {
  const { t } = useTranslation()
  const onCloseClick = () => {
    onClose?.()
  }

  const onCancelClick = () => {
    onCancel?.()
    if (closeOnCancel !== false) {
      onCloseClick()
    }
  }

  const onConfirmClick = () => {
    onConfirm?.()
    if (closeOnConfirm !== false) {
      onCloseClick()
    }
  }

  return (
    <RadAlertDialog
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onCloseClick()
        }
      }}
    >
      <RadAlertDialogContent className={cn('flex flex-col min-w-[40%] max-h-[90%] border-border', className)}>
        <RadAlertDialogHeader className="w-full text-start">
          <RadAlertDialogTitle>
            {title}
          </RadAlertDialogTitle>
          <RadAlertDialogDescription>{description}</RadAlertDialogDescription>
        </RadAlertDialogHeader>

        {children}

        <RadAlertDialogFooter>
          {cancelButtonLabel !== false && <RadAlertDialogCancel onClick={onCancelClick}>{cancelButtonLabel ?? t('label.cancel')}</RadAlertDialogCancel>}
          {confirmButtonLabel !== false && <RadAlertDialogAction className={destructiveAction ? 'bg-destructive hover:bg-destructive/90' : undefined} onClick={onConfirmClick}>{confirmButtonLabel ?? t('label.confirm')}</RadAlertDialogAction>}
        </RadAlertDialogFooter>

      </RadAlertDialogContent>

    </RadAlertDialog>

  )
}
