import { Doc, Document, DocumentUncheckedCreateInput, DocumentUncheckedUpdateInput, DocumentView, FunctionalLocation } from '@app/graphql/__types__/graphql'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { TFunction } from 'i18next'
import { z } from 'zod'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type State = {
  updateData?: DocumentUncheckedUpdateInput | DocumentUncheckedCreateInput
  deleteDocModalOpen: boolean
  fieldErrors: Record<string, boolean>
  creatingDoc: boolean
  editDoc?: Partial<Document> | null
  activeDoc?: Partial<Document> | null
  docs?: Partial<Document>[]
  docViews?: Partial<DocumentView>[]
  uploadFile?: File
  flocs?: Partial<FunctionalLocation>[]
  flocToDelete?: Partial<FunctionalLocation>
  flocsSelectionDisplayed: boolean
  fetchDocs?: () => void
  fileDoc?: Partial<Doc> | null
  fetchFileDoc?: () => void
}

type DocState = State & Actions

type Actions = {
  updateDataField: (field: string, value: unknown) => void
  setUpdateData: (updateData?: DocumentUncheckedUpdateInput | DocumentUncheckedCreateInput) => void
  setEditDoc: (doc: Partial<Document> | null) => void
  setDocs: (docs?: Partial<Document>[]) => void
  setDocViews: (docViews?: Partial<DocumentView>[]) => void
  setActiveDoc: (doc?: Partial<Document> | null, isNew?: boolean) => void
  updateDoc: (doc: Partial<Document>, isNew?: boolean) => void
  deleteDoc: (doc?: Partial<Document>) => void
  changeDeleteDocModalDisplay: (isOpen: boolean) => void
  isSaved: () => boolean
  hasError: () => boolean
  hasFieldError: (field: string) => boolean
  updateFieldError: (field: string, value: boolean) => void
  resetData: () => void
  setFlocs: (flocs?: Partial<FunctionalLocation>[]) => void
  setFlocToDelete: (flocToDelete?: Partial<FunctionalLocation>) => void
  setFlocsSelectionDisplayed: (displayed: boolean) => void
  setFieldErrors: (fieldErrors?: Record<string, boolean>) => void
  setUploadFile: (uploadFile?: File) => void
  cancelEditData: () => void
  setFetchDocs: (fetchDocs?: () => void) => void
  setFileDoc: (fileDoc?: Partial<Doc> | null) => void
  setFetchFileDoc: (fetchFileDoc?: () => void) => void
}

const initialState: State = {
  updateData: {},
  deleteDocModalOpen: false,
  fieldErrors: {},
  creatingDoc: false,
  editDoc: null,
  activeDoc: null,
  uploadFile: undefined,
  flocToDelete: undefined,
  flocsSelectionDisplayed: false,
  flocs: undefined,
  fetchDocs: undefined,
  fetchFileDoc: undefined,
}

export const MAX_LENGTH_VALIDATORS = {
  DOCUMENT: 100,
  REVISION: 5,
  DOCNAME: 100,
  CLASS: 10,
  DESCRIPTION: 255,
}

export const ZOD_DOCUMENT_DATAS = (t: TFunction) => ({

  document: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.DOCUMENT).min(1, { message: t('message.error.form.required') }),
  revision: z.string().max(MAX_LENGTH_VALIDATORS.REVISION),

  docName: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.DOCNAME).min(1, { message: t('message.error.form.required') }),

  description: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.DESCRIPTION).min(1, { message: t('message.error.form.required') }),
})

export const useDocumentStore = create<DocState>()(
  immer((set, get) => ({
    ...initialState,
    setFetchFileDoc(fetchFileDoc) {
      set({ fetchFileDoc })
    },
    setFileDoc(fileDoc) {
      set({ fileDoc })
    },
    setFetchDocs(fetchDocs) {
      set({ fetchDocs })
    },
    cancelEditData: () => set(state => ({
      updateData: {},
      uploadFile: undefined,
      fieldErrors: {},
      editDoc: { ...state.activeDoc },
    })),
    setUploadFile: (uploadFile?: File) => set({ uploadFile }),
    setFieldErrors(fieldErrors?: Record<string, boolean>) {
      set({
        fieldErrors: fieldErrors ?? {},
      })
    },
    resetData() {
      set({
        ...initialState,
      })
    },
    setFlocs(flocs) {
      set({ flocs })
    },
    setFlocToDelete: (flocToDelete?: Partial<FunctionalLocation>) => set({
      flocToDelete,
    }),
    setFlocsSelectionDisplayed: (displayed: boolean) => set({
      flocsSelectionDisplayed: displayed,
    }),
    setUpdateData: (updateData?: DocumentUncheckedUpdateInput | DocumentUncheckedCreateInput) => set({ updateData }),
    setActiveDoc: (doc?: Partial<Document> | null, isNew?: boolean) => set((state) => {
      state.activeDoc = doc
      state.editDoc = doc ? { ...doc } : undefined
      state.creatingDoc = (doc && isNew) === true
      state.updateData = undefined
      state.uploadFile = undefined
    }),
    setEditDoc(editDoc) {
      set({ editDoc })
    },
    isSaved() {
      const { updateData } = get()
      return !(updateData && Object.keys(updateData).length > 0)
    },
    updateFieldError: (field: string, value: boolean) => set((state) => {
      state.fieldErrors = {
        ...state.fieldErrors,
        [field]: value,
      }
    }),
    hasFieldError(field: string) {
      const state = get()
      if (state.fieldErrors[field] === true) {
        return true
      }

      switch (field) {
        case 'document':
        { const document = (state.editDoc?.document ?? '').trim()
          return document === '' || document.length > MAX_LENGTH_VALIDATORS.DOCUMENT }
        case 'description':
        { const description = (state.editDoc?.description ?? '').trim()
          return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '' }
        default:
          break
      }

      return false
    },
    hasError() {
      const state = get()
      return ['document', 'description', 'revision', 'sector'].some((field: string) => state.hasFieldError(field))
    },
    setDocs: (docs?: Partial<Document>[]) => set({
      docs,
    }),
    setDocViews: (docViews?: Partial<DocumentView>[]) => set({
      docViews,
    }),
    updateDataField: (field: string, value: unknown) => set((state) => {
      state.updateData = {
        ...state.updateData,
        [field]: state.activeDoc?.id === OBJ_NEW_ID
          ? value
          : {
              set: value,
            },
      }
      state.fieldErrors = {
        ...state.fieldErrors,
        [field]: false,
      }
    }),
    updateDoc: (doc: Partial<Document>, isNew?: boolean) => set((state) => {
      const docs = [
        ...(state.docs ?? []),
      ]
      const newState: Partial<State> = {}
      if (docs) {
        const index = docs.findIndex((item: Partial<Document>) => item.id === doc.id)
        if (index > -1) {
          docs[index] = doc
          newState.docs = [...docs]
        }
      }

      if (state.activeDoc && state.activeDoc.id === isNew ? OBJ_NEW_ID : doc.id) {
        newState.activeDoc = doc
      }

      return newState
    }),
    deleteDoc: (doc?: Partial<Document>) => set((state) => {
      const docs = [
        ...(state.docs ?? []),
      ]
      const deletedDoc: Partial<Document> | undefined | null = doc ?? state.activeDoc

      if (docs && deletedDoc) {
        state.deleteDocModalOpen = false
        if (deletedDoc.id === state.activeDoc?.id) {
          state.activeDoc = null
        }

        state.docs = docs.filter((item: Partial<Document>) => item.id !== deletedDoc.id)
      }
    }),
    changeDeleteDocModalDisplay: (isOpen: boolean) => set({
      deleteDocModalOpen: isOpen,
    }),
  })),
)
