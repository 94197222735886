import { TLabelValueRow } from '@app/types/app'
import { FORMAT_DATE_EU } from '@app/utils/constants'
import { EFieldType } from '@app/utils/enums'
import { searchArray } from '@app/utils/functions'
import { convertDateEuToDefault } from '@app/utils/functions/dates'
import { LabelInput } from '@holis/react-ui'
import { Option, RadCheckbox, RadDatePicker, RadFormControl, RadFormLabel, RadFormMessage, RadInput, RadTextarea } from '@holis/react-ui/rad'
import { TFunction } from 'i18next'
import _ from 'lodash'
import moment from 'moment'
import React, { ChangeEvent, InputHTMLAttributes, TextareaHTMLAttributes, useEffect, useState } from 'react'
import { ControllerFieldState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LuAlignLeft, LuCalendarDays, LuCheckSquare, LuList, LuLock } from 'react-icons/lu'
import { cn } from '@holis/react-ui/utils'
import { AppMultipleSelector } from '../../Block/MultipleSelector'

function renderTextInput(props: TLabelValueRow) {
  const InputComponent = props.inputComponent === 'textarea' ? RadTextarea : RadInput
  const inputProps = props.inputProps as InputHTMLAttributes<HTMLInputElement> & TextareaHTMLAttributes<HTMLTextAreaElement>

  if (props.inputRestrictDecimal) {
    inputProps.onKeyDown = (e) => {
      // If enter is pressed, blur the input
      if (e.key === 'Enter') {
        e.currentTarget.blur()
      }

      // Allow only numbers and one decimal point and control keys and copy and paste
      if ((e.key === '.' && e.currentTarget.value.includes('.'))
        || (!/^[0-9]*\.?[0-9]*$/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) && !e.ctrlKey && !e.metaKey)) {
        e.preventDefault()
      }
    }

    inputProps.onPaste = (e) => {
      const clipboardData = e.clipboardData?.getData('text')
      const numberOfDots = clipboardData?.match(/\./g)?.length ?? 0
      if (!/^[0-9]*\.?[0-9]*$/.test(clipboardData) || numberOfDots > 1) {
        e.preventDefault()
      }
    }
  }

  return (
    <InputComponent
      {...inputProps}
      value={props.value as string ?? ''}
      variant="holis"
      className={cn('cursor-pointer focus:cursor-auto', props.inputProps?.className)}
      rows={props.inputComponent === 'textarea' ? (inputProps?.rows ?? 30) : undefined}
      required={props.isRequired}
      onChange={(e) => {
        props.handleFieldChange?.(props.fieldRow?.field ?? inputProps.name ?? '', e.target.value, true)
        inputProps.onChange?.(e as ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>)
      }}
    />
  )
}

function multiValueStringToOptions(str?: string): Option[] {
  if (!str) {
    return []
  }

  return str.split(';').map(s => ({ value: s, label: s }))
}

function optionsToMultiValueString(options: Option[]): string {
  return options.map(option => option.value).join(';')
}

function renderMultiValueInput(props: TLabelValueRow, t: TFunction) {
  const inputProps = props.inputProps as InputHTMLAttributes<HTMLInputElement>
  const { multiValueProps, items, value } = props
  const sortedItems = items ? _.sortBy(items as Option[], ['label']) : undefined
  return (
    <AppMultipleSelector
      {...inputProps}
      triggerSearchOnFocus
      placeholder={Array.isArray(items) && items.length && !value ? t('label.select') : undefined}
      value={typeof props.value === 'string' ? multiValueStringToOptions(props.value) : (props.value as Option[])}
      variant="holis"
      className={cn('cursor-pointer focus:cursor-auto', props.inputProps?.className)}
      badgeClassName="!bg-muted !text-muted-foreground"
      inputProps={{
        className: 'cursor-pointer focus:cursor-auto',
      }}
      required={props.isRequired}
      options={sortedItems ? sortedItems as Option[] : undefined}
      onSearch={value => new Promise((resolve) => {
        let result: Option[] = sortedItems as Option[]
        console.log(value)
        if (value.trim() !== '') {
          result = searchArray(sortedItems as Option[], value)
        }

        if (!result.length) {
          result = [{
            value: '',
            label: t('label.noResult'),
            disable: true,
          }]
        }

        resolve(result)
      })}
      onChange={(e) => {
        props.handleFieldChange?.(
          props.fieldRow?.field ?? inputProps.name ?? '',
          optionsToMultiValueString(e),
          true,
        )

        props.handleFieldBlur?.(
          props.fieldRow?.field ?? inputProps.name ?? '',
          optionsToMultiValueString(e),
          true,
        )
      }}
      {...multiValueProps}
    />
  )
}

function renderDateInput(props: TLabelValueRow) {
  const dateVal = (props.value
    ? (typeof props.value === 'string' ? new Date(props.value) : props.value as Date)
    : undefined)
  return (
    <RadDatePicker
      onDateSelected={(date?: Date) => {
        if (date?.getDate() !== dateVal?.getDate()) {
          props.handleFieldChange?.(props.fieldRow!.field, date, true)
          props.inputProps?.onBlur?.({ target: { value: date?.toISOString() } } as React.FocusEvent<HTMLInputElement> & React.FocusEvent<HTMLTextAreaElement>)
        }
      }}
      {...props.inputProps}
      value={dateVal}
      variant="holis"
    />
  )
}

export default function LabelValueRow(props: TLabelValueRow & { readonly fieldState?: ControllerFieldState }) {
  const { t } = useTranslation()
  const {
    icon,
    itemsQueryResult,
    label,
    labelPrefix,
    labelSuffix,
    value,
    renderInput,
    inputComponent,
    isRequired: required,
    inputProps,
    isDisabled: disabled,
    onTextInputChange,
    getItemsFromResult,
    fieldRow,
    fieldType,
    className,
    labelClassName,
    hasError: error,
    labelInputProps,
    handleFieldChange,
  } = props
  const [currentValue, setCurrentValue] = useState<string>(String(value ?? ''))
  const [initDateValue, setInitDateValue] = useState<string>(String(value ?? ''))
  const [dateValue, setDateValue] = useState<string>(String(value ?? ''))
  const [menuItems, setMenuItems] = useState<unknown[]>()
  const onInputChange = (e: ChangeEvent<HTMLElement>) => {
    if (disabled || inputProps?.disabled) {
      e.preventDefault?.()
      e.stopPropagation?.()
      return
    }

    if (fieldType === EFieldType.date) {
      const { value } = e.target as HTMLInputElement
      if (value.length >= 10) {
        const dateDefault = convertDateEuToDefault(value)
        if (dateDefault !== false) {
          if (dateDefault !== dateValue) {
            setDateValue(dateDefault)
          }

          (e.target as HTMLInputElement).value = dateDefault
        }
      }
    }

    setCurrentValue((e.target as (HTMLInputElement | HTMLTextAreaElement)).value)
    onTextInputChange?.(e)
  }

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement> & React.FocusEvent<HTMLTextAreaElement>) => {
    if (disabled || inputProps?.disabled) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    props.inputProps?.onFocus?.(e)
  }

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement> & React.FocusEvent<HTMLTextAreaElement>) => {
    if (disabled || inputProps?.disabled) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (fieldType === EFieldType.date) {
      const newValue = (e.target as HTMLInputElement).value
      if (typeof newValue === 'string' && newValue.length >= 10) {
        const dateDefault = convertDateEuToDefault(newValue)
        if (dateDefault !== false) {
          if (dateDefault === initDateValue) {
            e.preventDefault()
            e.stopPropagation()
            return
          }

          (e.target as HTMLInputElement).value = dateDefault
        }
      }
    }

    props.inputProps?.onBlur?.(e)
  }

  useEffect(() => {
    let newValue = value
    if (fieldType === EFieldType.date && typeof newValue === 'string' && newValue.length >= 10) {
      const dateMoment = moment(newValue)
      if (dateMoment.isValid()) {
        newValue = dateMoment.format(FORMAT_DATE_EU)
        setDateValue(dateMoment.format(FORMAT_DATE_EU))
        setInitDateValue(dateMoment.format(FORMAT_DATE_EU))
      }
    }

    if (newValue !== currentValue) {
      setCurrentValue(String(newValue ?? ''))
    }
  }, [value])

  useEffect(() => {
    if (!!itemsQueryResult?.data && getItemsFromResult) {
      setMenuItems(getItemsFromResult(itemsQueryResult) ?? [])
    }
  }, [itemsQueryResult?.data, getItemsFromResult])

  useEffect(() => {
    if (props.items) {
      setMenuItems(props.items)
    }
  }, [props.items])

  const rndrInput = renderInput
    ? () => renderInput({
        ...props,
        onInputChange,
        setInputValue: setCurrentValue,
        isRequired: required,
        isDisabled: disabled,
        field: fieldRow!.field,
        hasError: error,
        inputProps: {
          required,
          ...inputProps,
          disabled: inputProps?.disabled || disabled,
          className: cn('field-input w-full h-full', inputProps?.className),
          value: currentValue,
          onFocus: onInputFocus,
          onBlur: onInputBlur,
        },
        fieldRow,
        items: menuItems ?? [],
      })
    : () => {
        switch (fieldType) {
          case EFieldType.boolean: {
            return <RadCheckbox disabled={disabled} checked={!!value} onCheckedChange={(checked: boolean) => handleFieldChange?.(fieldRow!.field, checked, true)} />
          }

          case EFieldType.text: {
            return renderTextInput(props)
          }

          case EFieldType.date: {
            return renderDateInput(props)
          }

          case EFieldType.multivalue: {
            return renderMultiValueInput(props, t)
          }

          default: {
            return renderTextInput(props)
          }
        }
      }

  const LabelComponentType = props.fieldState ? RadFormLabel : 'label'

  const MaybeFormControl = props.fieldState ? RadFormControl : React.Fragment

  return (
    <LabelInput
      inputComponent={inputComponent}
      renderInput={() => (
        <div className="text-sm grid">
          <MaybeFormControl>
            {rndrInput()}
          </MaybeFormControl>
          {props.fieldState && <RadFormMessage className="mt-2 h-4">{props.fieldState.error?.message}</RadFormMessage>}
        </div>
      )}
      error={error}
      disabled={disabled}
      inputProps={{
        required,
        placeholder: fieldType === EFieldType.date ? FORMAT_DATE_EU : undefined,
        pattern: fieldType === EFieldType.date ? '\\d{2}/\\d{2}/\\d{4}' : undefined,
        ...inputProps,
        disabled: inputProps?.disabled || disabled,
        className: cn('field-input w-full', inputProps?.className),
        value: currentValue,
        onFocus: onInputFocus,
        onBlur: onInputBlur,
      }}
      value={currentValue}
      className={cn(`flex-row w-full items-start ${inputComponent === 'textarea' ? 'items-start' : 'items-center'}`, className)}
      onChange={onInputChange}
      {...labelInputProps}
    >
      {label !== false
        && (
          <LabelComponentType className={cn('flex justify-start gap-1 capitalize pt-1 px-0 w-[155px] text-xs items-center font-bold h-9', labelClassName, fieldType === EFieldType.autocomplete ? '-mt-1.5' : '')}>
            <span className="mr-2">
              {
                icon === 'invisible'
                  ? <LuAlignLeft size={18} className="invisible" />
                  : (
                      icon === false
                        ? undefined
                        : icon
                          ?? (
                            fieldType === EFieldType.autocomplete
                              ? <LuList size={18} className="" />
                              : (
                                  disabled
                                    ? <LuLock size={18} className="" />
                                    : (
                                        fieldType === EFieldType.boolean
                                          ? <LuCheckSquare size={18} className="" />
                                          : (
                                              fieldType === EFieldType.date
                                                ? <LuCalendarDays size={18} className="" />
                                                : <LuAlignLeft size={18} className="" />)))))
              }
            </span>
            {labelPrefix}
            <span>
              {
                typeof label === 'string'
                  ? t(label)
                  : label
              }
            </span>
            {
              !!required
              && <span className="text-gray-500 ml-[-2px]">*</span>
            }
            {labelSuffix}
          </LabelComponentType>
        )}

      {/* Add a space if field has error to prevent the label to move down */}
      {props.fieldState?.error && <div className="mt-2 h-4" />}
    </LabelInput>
  )
}
