import { useLazyQuery } from '@apollo/client'
import DamageCard from '@app/components/Common/Block/Damage/DamageBlock/DamageCard'
import DamageListModal from '@app/components/Common/Block/Damage/DamageBlock/DamageListModal'
import ObjectItemListByDrawing from '@app/components/Common/Block/InspectionDrawing/ObjectItemListByDrawing'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import { Cml, Damage, EventCml, EventDamage, FunctionalLocation, GetDamagesByFlocIdQuery, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { DAMAGES_GET_BY_FLOC_ID } from '@app/graphql/requests'
import { useFlocStore } from '@app/stores/methodEngineering/floc'
import { NO_DRAWING_ID } from '@app/utils/constants'
import { EAppFlagStatus } from '@app/utils/enums'
import { searchArray } from '@app/utils/functions'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuZap } from 'react-icons/lu'

type TCmlList = Readonly<{
  floc: Partial<FunctionalLocation>
}>

/** Floc damage list */
export default function DamageList({ floc }: TCmlList) {
  const [filteredDamages, setFilteredDamages] = useState<Partial<Damage>[]>()
  const [filterString, setSearchInput] = useState<string>('')
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>()
  const { t } = useTranslation()
  const [listModalDisplayed, changeListModalDisplay] = useState<boolean>(false)

  const {
    damages,
    setDamages,
    fetchDamages,
    setFetchDamages,
    selectedDrawing,
    setSelectedDrawing,
    flocInspectionDrawings,
    latestEventDamages,
  } = useFlocStore()

  const [getDamageListApi] = useLazyQuery<GetDamagesByFlocIdQuery>(DAMAGES_GET_BY_FLOC_ID)

  useEffect(() => {
    setFetchDamages(getDamages)
  }, [floc.id])

  useEffect(() => {
    fetchDamages?.()
  }, [fetchDamages])

  useEffect(() => {
    if (Array.isArray(damages)) {
      setFilteredDamages(
        filterString !== ''
          ? searchArray(
              damages,
              filterString,
              undefined,
              ['__typename'],
            ).filter((damage: Partial<Damage>) => damage.flagStatus === EAppFlagStatus.ACTIVE.toString())
          : damages,
      )
    }
  }, [damages, filterString])

  const getDamages = () => {
    if (floc.id) {
      getDamageListApi({
        variables: {
          flocId: floc.id,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResults => setDamages(_.sortBy(queryResults.data?.damages as Damage[] ?? [], [item => item.position])))
    } else {
      setDamages([])
    }
  }

  useEffect(() => {
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      ...(flocInspectionDrawings ?? []),
    ])
  }, [flocInspectionDrawings])

  const itemsByIdwg = useMemo(() => (filteredDamages?.reduce((acc, item) => {
    const idwgId = item.idwgId ?? NO_DRAWING_ID

    if (!acc[idwgId]) {
      acc[idwgId] = []
    }

    acc[idwgId].push(item)
    return acc
  }, {} as Record<number, Partial<Damage>[]>) ?? {}), [filteredDamages])

  return (
    <>
      <SpinnerLoaderComponent isLoading={typeof latestEventDamages === 'undefined' || !Array.isArray(filteredDamages)}>
        <ObjectItemListByDrawing
          drawings={drawings}
          changeItemListModalDisplay={changeListModalDisplay}
          itemsByIdwg={itemsByIdwg}
          selectedDrawing={selectedDrawing}
          title={(
            <>
              <LuZap className="mr-2" size={20} />
              {t('label.damage')}
            </>
          )}
          items={filteredDamages}
          searchInput={setSearchInput}
          handleDisplayDrawing={setSelectedDrawing}
          objectItem={floc}
          renderItem={(item: Partial<Cml | EventCml | Damage | EventDamage>) => (
            <DamageCard key={item.id} hasMenuContext hideAddNotifBtn objectItem={floc} dmgObject={item as Partial<Damage>} />
          )}
        />
      </SpinnerLoaderComponent>
      {listModalDisplayed && <DamageListModal open hasItems title={t('label.damageList')} description={`${t('label.functionalLocation').toUpperCase()} ${floc.floc}`} sortOrders={['asc']} items={damages} onClose={() => changeListModalDisplay(false)} />}
    </>
  )
}
