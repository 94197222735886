import { gql } from '@apollo/client'
import { INTEGRITY_REF_CONDITIONS_COMMON_FIELDS } from './refIntegrityConditions'
import { INTEGRITY_REF_CONSEQUENCES_COMMON_FIELDS } from './refIntegrityConsequences'
import { FLOCS_AUTOCOMPLETE_COMMON_FIELDS } from './flocs'

const INTEGRITY_STATUSES_COMMON_FIELDS = gql`
  fragment IntegrityStatusCommonFields on IntegrityStatus {
    id
    consequence
    condition
    reportingDate
    flocId
    corrRateExt
    corrRateInt
    maxEndDate
    externalId
    notes
  }
`

export const INTEGRITY_STATUSES_DETAIL_FIELDS = gql`
  ${INTEGRITY_STATUSES_COMMON_FIELDS}
  ${INTEGRITY_REF_CONDITIONS_COMMON_FIELDS}
  ${INTEGRITY_REF_CONSEQUENCES_COMMON_FIELDS}
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  fragment IntegrityStatusDetailFields on IntegrityStatus {
    ...IntegrityStatusCommonFields,
    integrityCondition {
      ...RefIntegrityConditionCommonFields
    }
    integrityConsequence {
      ...RefIntegrityConsequenceCommonFields
    }
    functionalLocation {
      ...FlocAutocompleteCommonFields
    }
  }
`
