import { useQuery } from '@apollo/client'
import { FileUpload } from '@app/components/Common/FileDropZone'
import AppCombobox from '@app/components/Common/Form/AppCombobox'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper'
import { AppListItem } from '@app/components/Common/Form/list-item'
import { GetAllRefDocsCatalogsQuery, GetAllSectorsQuery, RefDocsCatalog, Sector } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_CATALOGS_GET_MANY, SECTORS_GET_ALL } from '@app/graphql/requests'
import { useDocumentStore } from '@app/stores/document'
import { EDOCUMENTATION_CATALOGS_CATEGORY } from '@app/utils/enums'
import { getFileNameWithoutExtension } from '@app/utils/functions'
import { RadBadge, RadFormField, RadFormMessage, RadInput } from '@holis/react-ui/rad'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuFileBox } from 'react-icons/lu'

export default function Form() {
  const { t } = useTranslation()

  const [refDocsCatalogs, setRefDocsCatalogs] = useState<GetAllRefDocsCatalogsQuery>()
  const { uploadFile, setUploadFile } = useDocumentStore()
  const [stages, setStages] = useState<AppListItem[]>([])
  const [sectors, setSectors] = useState<AppListItem[]>([])
  const [disciplines, setDisciplines] = useState<AppListItem[]>([])
  const [types, setTypes] = useState<AppListItem[]>([])
  const [phases, setPhases] = useState<AppListItem[]>([])
  const [originators, setOriginators] = useState<AppListItem[]>([])
  const [catalogs, setCatalogs] = useState<AppListItem[]>([])

  const { loading: refDocsCatalogsLoading } = useQuery<GetAllRefDocsCatalogsQuery>(DOCUMENTATION_CATALOGS_GET_MANY, {
    onCompleted(data) {
      setRefDocsCatalogs(data)
    },
  })

  const filterRefDocsCatalogsByCategory = (category: EDOCUMENTATION_CATALOGS_CATEGORY): AppListItem[] => {
    if (refDocsCatalogs === undefined) {
      return []
    }

    return refDocsCatalogs.refDocsCatalogs.filter((doc: Partial<RefDocsCatalog>) => doc.category === category).map((doc: Partial<RefDocsCatalog>) => ({
      label: `[${doc.code}] - ${doc.description}`,
      value: doc.id,
      render: (
        <span className="truncate">
          [
          {doc.code}
          ] -
          {doc.description}
        </span>
      ),
    }))
  }

  useEffect(() => {
    if (refDocsCatalogs === undefined) {
      return
    }

    setStages(filterRefDocsCatalogsByCategory(EDOCUMENTATION_CATALOGS_CATEGORY.STAGE))
    setDisciplines(filterRefDocsCatalogsByCategory(EDOCUMENTATION_CATALOGS_CATEGORY.DISCIPLINE))
    setTypes(filterRefDocsCatalogsByCategory(EDOCUMENTATION_CATALOGS_CATEGORY.TYPE))
    setPhases(filterRefDocsCatalogsByCategory(EDOCUMENTATION_CATALOGS_CATEGORY.PHASE))
    setOriginators(filterRefDocsCatalogsByCategory(EDOCUMENTATION_CATALOGS_CATEGORY.ORIGINATOR))
    setCatalogs(filterRefDocsCatalogsByCategory(EDOCUMENTATION_CATALOGS_CATEGORY.CATALOG))
  }, [refDocsCatalogs])

  const { loading: sectorsLoading } = useQuery<GetAllSectorsQuery>(SECTORS_GET_ALL, {
    onCompleted(data) {
      setSectors(data.sectors.map((sector: Partial<Sector>) => ({
        label: `[${sector.sector}] - ${sector.description}`,
        value: sector.id,
      })))
    },
  })

  return (
    <div className="flex flex-col gap-6 pl-0.5 pr-4 pb-0.5">
      {/* Identification */}
      <div className="flex flex-col gap-4">

        <FormGroupHeader>
          {t('label.identification')}
        </FormGroupHeader>

        <div className="grid grid-cols-2 gap-2">
          {/* Reference */}
          <RadFormField
            name="document"
            render={({ field, fieldState }) => (
              <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.reference')}>
                <RadInput {...field} className="uppercase" placeholder="-" />
              </InputLabelWrapper>
            )}
          />

          {/* Revision */}
          <RadFormField
            name="revision"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.revision')}>
                <RadInput {...field} placeholder="-" />
              </InputLabelWrapper>
            )}
          />

          {/* Description */}
          <RadFormField
            name="description"
            render={({ field, fieldState }) => (
              <InputLabelWrapper
                isRequired
                fieldState={fieldState}
                label={t('label.description')}
                style={{ gridColumn: '1 / 3' }}
              >
                <RadInput
                  placeholder={t('label.description')}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                  }}
                />
              </InputLabelWrapper>
            )}
          />

          {/* Stage */}
          <RadFormField
            name="stageId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.stage')}>
                <AppCombobox
                  items={stages}
                  placeholder={t('label.selectStage')}
                  loading={refDocsCatalogsLoading}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* Sector */}
          <RadFormField
            name="sectorId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.sector')}>
                <AppCombobox
                  items={sectors}
                  placeholder={t('label.selectSector')}
                  loading={sectorsLoading}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />
        </div>
      </div>

      {/* Document file */}
      <div className="flex flex-col gap-4">
        <FormGroupHeader>
          {t('label.documentFile')}
        </FormGroupHeader>

        <RadFormField
          name="docName"
          render={({ field, fieldState }) => {
            const onFileCb = (file: File) => {
              setUploadFile(file)
              field.onChange(getFileNameWithoutExtension(file.name))
            }

            return (
              <div className="flex flex-col gap-1">
                <FileUpload
                  accept="application/pdf, .pdf, image/*"
                  onFileCb={onFileCb}
                />

                {fieldState.error && (
                  <RadFormMessage className="text-xs">
                    {fieldState.error.message}
                  </RadFormMessage>
                )}

                {uploadFile && (
                  <div className="my-0.5 flex items-center justify-between">
                    <span className="flex items-center">
                      <LuFileBox className="mr-2 text-muted-foreground" />

                      <span className="text-sm font-semibold tracking-tighter text-muted-foreground">
                        {uploadFile.name}
                      </span>
                    </span>

                    <RadBadge variant="secondary">
                      {uploadFile.size > 1024 * 1024
                        ? (
                            `${(uploadFile.size / (1024 * 1024)).toFixed(2)} MB`
                          )
                        : (
                            `${(uploadFile.size / 1024).toFixed(2)} KB`
                          )}
                    </RadBadge>
                  </div>
                )}
              </div>
            )
          }}
        />
      </div>

      {/* Attributes */}
      <div className="flex flex-col gap-4">
        <FormGroupHeader>
          {t('label.attributes')}
        </FormGroupHeader>

        <div className="grid grid-cols-3 gap-2">
          {/* Discipline */}
          <RadFormField
            name="disciplineId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.discipline')}>
                <AppCombobox
                  items={disciplines}
                  placeholder={t('label.selectDiscipline')}
                  loading={refDocsCatalogsLoading}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* Type */}
          <RadFormField
            name="typeId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.type')}>
                <AppCombobox
                  items={types}
                  placeholder={t('label.selectType')}
                  loading={refDocsCatalogsLoading}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* Phase */}
          <RadFormField
            name="phaseId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.phase')}>
                <AppCombobox
                  items={phases}
                  placeholder={t('label.selectPhase')}
                  loading={refDocsCatalogsLoading}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* Originator */}
          <RadFormField
            name="originatorId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.originator')}>
                <AppCombobox
                  items={originators}
                  placeholder={t('label.selectOriginator')}
                  loading={refDocsCatalogsLoading}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* Catalog */}
          <RadFormField
            name="catalogId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.catalog')}>
                <AppCombobox
                  items={catalogs}
                  placeholder={t('label.selectCatalog')}
                  loading={refDocsCatalogsLoading}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />
        </div>
      </div>
    </div>
  )
}
