import { Cml, EventCml } from '@app/graphql/__types__/graphql'
import CmlCard from './CmlCard'

type TCmlList = Readonly<{
  cmls?: Partial<Cml | EventCml>[]
  readonly?: boolean
  handleRemoveCml?: (id: number) => void
  hasMenuContext?: boolean
  detailsShown?: boolean
}>

export default function CmlList({ cmls, readonly, handleRemoveCml, hasMenuContext, detailsShown }: TCmlList) {
  return (
    <div className="flex flex-col gap-1">
      {cmls?.map(ecml => <CmlCard key={ecml.id} detailsShown={detailsShown} hasMenuContext={hasMenuContext} readonly={readonly} eventCml={ecml} handleRemoveCml={() => handleRemoveCml?.(ecml.__typename === 'EventCml' ? ecml!.cml!.id! : ecml.id!)} />)}
    </div>
  )
}
