import { gql } from '@apollo/client'
import { SCHEDULING_PLAN_WORKFLOWS_COMMON_FIELDS } from '../fragments'

export const SCHEDULING_PLAN_WORKFLOWS_GET_BY_PLAN_ID = gql`
${SCHEDULING_PLAN_WORKFLOWS_COMMON_FIELDS}
query GetPlanWorkflowsByPlanId($planId: Int!) {
  planWorkflows(where: {planId: {equals: $planId}}, orderBy: [{actionDate: {sort: desc}}, {id: desc}]) {
    ...PlanWorkflowCommonFields
  }
}
`

export const SCHEDULING_PLAN_WORKFLOWS_CREATE = gql`
${SCHEDULING_PLAN_WORKFLOWS_COMMON_FIELDS}
mutation CreatePlanWorkflow($data: PlanWorkflowUncheckedCreateInput!) {
  createOnePlanWorkflow(data: $data) {
    ...PlanWorkflowCommonFields
  }
}
`

export const SCHEDULING_PLAN_WORKFLOWS_GET_COUNTER = gql`
query GetPlanWorkflowsCounter {
  aggregatePlanWorkflow {
    _count {
      id
    }
  }
}
`
