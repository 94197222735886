import { TSelectionConfirmModalWithoutListProps } from '@app/components/Modal/Confirm/Selection'
import { CreateEventInspectionDrawingsMutation, DeleteEventInspectionDrawingsMutation, EventInspectionDrawing, GetInspectionDrawingsByFlocIdsQuery, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import InspectionDrawingSelectionModal from './InspectionDrawingSelectionModal'
import { useEventStore } from '@app/stores/event'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { useLazyQuery, useMutation } from '@apollo/client'
import { INSPECTION_DRAWINGS_GET_BY_FLOC_IDS, WORKORDER_EVENT_INSPECTION_DRAWINGS_CREATE_MANY, WORKORDER_EVENT_INSPECTION_DRAWINGS_DELETE_MANY } from '@app/graphql/requests'
import { TDbId } from '@app/types/app'
import { useLayoutStore } from '@app/stores/layout'
import AppNotifications from '@app/services/notification'

export default function EventInspectionDrawingSelectionModal(props: TSelectionConfirmModalWithoutListProps<Partial<InspectionDrawing>>) {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLayoutStore()
  const [selectedIds, setSelectedIds] = useState<number[]>()
  const [idwgs, setIdwgs] = useState<Partial<InspectionDrawing>[]>()
  const [flocIdwgs, setFlocIdwgs] = useState<Partial<InspectionDrawing>[]>()
  const { activeEvent, eventFlocIds, eventInspectionDrawings, changeIdwgSelectionModalDisplay, fetchEventInspectionDrawings, pointIdwgIds } = useEventStore()
  const [getIdwgsByFlocIdsApi] = useLazyQuery<GetInspectionDrawingsByFlocIdsQuery>(INSPECTION_DRAWINGS_GET_BY_FLOC_IDS)
  const [addEventIdwgsApi] = useMutation<CreateEventInspectionDrawingsMutation>(WORKORDER_EVENT_INSPECTION_DRAWINGS_CREATE_MANY)
  const [deleteEventIdwgsApi] = useMutation<DeleteEventInspectionDrawingsMutation>(WORKORDER_EVENT_INSPECTION_DRAWINGS_DELETE_MANY)

  const addItems = async (idwgIds: TDbId[]) => addEventIdwgsApi({
    variables: {
      data: idwgIds.map((idwgId: TDbId) => ({
        wrkoId: activeEvent!.id,
        idwgId,
      })),
    },
  })

  const deleteItems = async (ids: TDbId[]) => deleteEventIdwgsApi({
    variables: {
      ids,
    },
  })

  const handleValidateSelection = async (selectedItems: Partial<InspectionDrawing>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const eventIdwgIds = (eventInspectionDrawings?.filter((item: Partial<EventInspectionDrawing>) => oldSelectedIds.includes(item.idwgId!)).map((item: Partial<EventInspectionDrawing>) => item.id) ?? []) as TDbId[]
      if (eventIdwgIds.length) {
        promises.push(deleteItems(eventIdwgIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        changeIdwgSelectionModalDisplay(false)
        fetchEventInspectionDrawings?.()
        AppNotifications.success(t('message.success.eventIdwgsUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    }
  }

  const getFlocIdwgs = () => {
    if (Array.isArray(eventFlocIds) && eventFlocIds.length) {
      getIdwgsByFlocIdsApi({
        variables: {
          flocIds: eventFlocIds,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setFlocIdwgs((queryResult.data?.inspectionDrawings ?? []) as Partial<InspectionDrawing>[]))
    } else {
      setFlocIdwgs([])
    }
  }

  useEffect(() => {
    getFlocIdwgs()
  }, [eventFlocIds])

  useEffect(() => {
    setSelectedIds(eventInspectionDrawings?.map(item => item.idwgId!))
  }, [eventInspectionDrawings])

  useEffect(() => {
    if (Array.isArray(eventInspectionDrawings) && Array.isArray(flocIdwgs)) {
      setIdwgs(_.uniqBy(_.concat(flocIdwgs, eventInspectionDrawings.map(item => item.inspectionDrawing!) ?? []), item => item.id))
    }
  }, [eventInspectionDrawings, flocIdwgs])

  return (
    <InspectionDrawingSelectionModal description={`${t('label.event')} - ${activeEvent?.event}`} items={idwgs} disabledIds={_.intersection(pointIdwgIds, selectedIds ?? [])} selectedIds={selectedIds} isLoading={typeof idwgs === 'undefined'} onClose={() => changeIdwgSelectionModalDisplay(false)} onValidate={handleValidateSelection} {...props} />
  )
}
