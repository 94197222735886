import { CreateEventWorkflowMutation, Event, Plan, ScheduleInspectionPlanEventsMutation, UpdatePlanByIdMutation } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import { ESCHEDULING_PLAN_STATUS } from '@app/utils/enums'
import usePlanStore from '@app/stores/plan'
import { useMutation } from '@apollo/client'
import { SCHEDULING_PLANS_SCHEDULE_PLAN_EVENTS, SCHEDULING_PLANS_UPDATE_BY_ID, WORKORDER_EVENT_WORKFLOWS_CREATE } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import EditCheckIcon from '@app/assets/images/icons/edit-check.svg'
import { useLayoutStore } from '@app/stores/layout'
import { useHolisAuth } from '@holis/auth-client-react'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
import Separator from '@app/components/Common/Separator'
import { RadBadge, RadButton } from '@holis/react-ui/rad'
import DetailsPageFooter from '@app/components/Layout/DetailsPagefooter'
import { LuCheckCircle2, LuRefreshCcwDot, LuRocket, LuXCircle } from 'react-icons/lu'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { tailwindColorToBgFgStyle } from '@app/utils/functions'

type TFooter = Readonly<{
  readonly plan: Partial<Plan>
}>

export default function Footer({ plan }: TFooter) {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLayoutStore()
  const { user } = useHolisAuth()
  const { items, setActivePlan, isSimulated, fetchPlan, changeSimulated, createPlanWorkflowFunc, editPlan } = usePlanStore()
  const [updatePlanByIdApi] = useMutation<UpdatePlanByIdMutation>(SCHEDULING_PLANS_UPDATE_BY_ID)
  const [schedulePlanApi] = useMutation<ScheduleInspectionPlanEventsMutation>(SCHEDULING_PLANS_SCHEDULE_PLAN_EVENTS)
  const [createEventWorkflowApi] = useMutation<CreateEventWorkflowMutation>(WORKORDER_EVENT_WORKFLOWS_CREATE)
  const prms = useUserPermissions()

  const handleChangeStatus = (status: string) => {
    const actionDate = new Date()
    const currStatus = plan.status
    startLoading()
    updatePlanByIdApi({
      variables: {
        id: plan.id,
        data: {
          status: {
            set: status,
          },
        },
      },
      fetchPolicy: 'no-cache',
    }).then((queryResult) => {
      const newPlan = {
        ...plan,
        ...queryResult.data?.updateOnePlan as Plan,
      }
      if (newPlan.status !== editPlan?.status) {
        let actionDescription: string
        switch (newPlan.status) {
          case ESCHEDULING_PLAN_STATUS.SCHED:
            actionDescription = t('label.planActions.descriptions.schedule_plan')
            break
          case ESCHEDULING_PLAN_STATUS.CANCEL:
            actionDescription = t('label.planActions.descriptions.cancel_plan')
            break
          case ESCHEDULING_PLAN_STATUS.WSCHED:
            if (currStatus === ESCHEDULING_PLAN_STATUS.CANCEL) {
              actionDescription = t('label.planActions.descriptions.reactivate_plan')
            } else {
              actionDescription = t('label.planActions.descriptions.reschedule_plan')
            }

            break
          default:
            actionDescription = ''
        }

        createPlanWorkflowFunc?.({
          variables: {
            data: {
              planId: newPlan.id,
              status: `${currStatus}|${newPlan.status}`,
              actionDate,
              userLogin: user?.username,
              description: actionDescription,
            },
          },
        })
      }

      setActivePlan(newPlan)

      AppNotifications.success(t('message.success.planUpdated'))
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  const handleSchedulePlan = () => {
    const actionDate = new Date()
    const currStatus = plan.status
    startLoading()
    schedulePlanApi({
      variables: {
        id: plan.id,
        isSimulation: false,
      },
      fetchPolicy: 'no-cache',
    }).then((result) => {
      changeSimulated(false)
      fetchPlan?.().then((newPlan: Partial<Plan>) => {
        if (newPlan.status !== editPlan?.status) {
          createPlanWorkflowFunc?.({
            variables: {
              data: {
                planId: newPlan.id,
                status: `${currStatus}|${newPlan.status}`,
                actionDate,
                userLogin: user?.username,
                description: t('label.planActions.descriptions.schedule_plan'),
              },
            },
          })
        }
      })
      for (const item of result.data?.scheduleInspectionPlan as Partial<Event>[]) {
        createEventWorkflowApi({
          variables: {
            data: {
              wrkoId: item.id,
              actionDate,
              userLogin: user?.username,
              status: `*|${item.status}`,
              description: t('label.eventActions.descriptions.generated_from_scheduling'),
              comment: plan.plan,
            },
          },
        })
      }

      AppNotifications.success(t('message.success.planUpdated'))
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  const handleSimulateEventsClick = () => {
    startLoading()
    schedulePlanApi({
      variables: {
        id: plan.id!,
        isSimulation: true,
      },
      fetchPolicy: 'no-cache',
    }).then((queryResult) => {
      changeSimulated(true, (queryResult.data?.scheduleInspectionPlan as Partial<Event>[]).map((item: Partial<Event>, idx: number) => ({
        ...item,
        id: -idx,
      })))
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  const cancelSimulatEvents = () => {
    changeSimulated(false)
  }

  return (
    <DetailsPageFooter
      actionButtons={(
        <>
          {plan.status === ESCHEDULING_PLAN_STATUS.WSCHED && (
            <>
              {!!items?.length && !isSimulated && (
                <RadButton disabled={!items?.length || isSimulated} className="gap-2 bg-blue-600 hover:bg-blue-500" onClick={handleSimulateEventsClick}>
                  <LuRocket />
                  {' '}
                  {t('label.simulateEvents')}
                </RadButton>
              )}
              {isSimulated && (
                <RadButton className="mr-1 bg-gray-800 hover:bg-gray-700 gap-2" onClick={cancelSimulatEvents}>
                  <LuXCircle />
                  {' '}
                  {t('label.cancelSimulateEvents')}
                </RadButton>
              )}
            </>
          )}
          { plan.status === ESCHEDULING_PLAN_STATUS.INIT && plan.id && plan.id !== OBJ_NEW_ID && (
            <RadButton className="bg-teal-500 hover:bg-teal-400 text-white gap-2" disabled={!prms.schedulingPlans.create} onClick={() => handleChangeStatus(ESCHEDULING_PLAN_STATUS.WSCHED)}>
              <EditCheckIcon />
              {t('label.toSchedule')}
            </RadButton>
          )}
          { plan.status === ESCHEDULING_PLAN_STATUS.CANCEL && (
            <RadButton className="bg-teal-500 hover:bg-teal-400 text-white gap-2" disabled={!prms.schedulingPlans.reactivate} onClick={() => handleChangeStatus(ESCHEDULING_PLAN_STATUS.WSCHED)}>
              <LuRefreshCcwDot />
              {t('label.reactivate')}
            </RadButton>
          )}
          { plan.status === ESCHEDULING_PLAN_STATUS.WSCHED && plan.id && plan.id !== OBJ_NEW_ID && (
            <RadButton className="bg-teal-500 hover:bg-teal-400 text-white gap-2" disabled={!isSimulated || !prms.schedulingPlans.schedule} onClick={handleSchedulePlan}>
              <LuCheckCircle2 />
              {' '}
              {t('label.schedule')}
            </RadButton>
          )}
          { plan.status === ESCHEDULING_PLAN_STATUS.SCHED && (
            <RadButton className="bg-teal-500 hover:bg-teal-400 text-white gap-2" disabled={!prms.schedulingPlans.reschedule} onClick={() => handleChangeStatus(ESCHEDULING_PLAN_STATUS.WSCHED)}>
              <LuCheckCircle2 />
              {t('label.reschedule')}
            </RadButton>
          )}
        </>
      )}
    >
      <RadBadge variant="outline" className="py-1 font-normal" style={tailwindColorToBgFgStyle(plan.statusPlan?.displayColor)}>{plan.statusPlan?.description ?? '-'}</RadBadge>
      <Separator />
      <span className="text-black">
        ID: #
        {plan.id}
      </span>
    </DetailsPageFooter>
  )
}
