import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteOneEventInspectionPointByIdMutation } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENT_INSPECTION_POINTS_DELETE_ONE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useEventStore } from '@app/stores/event'
import { useLayoutStore } from '@app/stores/layout'
import { useTranslation } from 'react-i18next'
type TDeleteEventInspectionPointModal = IConfirmModal

export default function DeleteEventInspectionPointModal({ ...restProps }: TDeleteEventInspectionPointModal) {
  const { setInspectionPointToDelete, inspectionPointToDelete, fetchEventInspectionPoints } = useEventStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteEventInspectionPointApi] = useMutation<DeleteOneEventInspectionPointByIdMutation>(WORKORDER_EVENT_INSPECTION_POINTS_DELETE_ONE_BY_ID)
  const { t } = useTranslation()
  const onConfirm = () => {
    if (inspectionPointToDelete) {
      startLoading()
      deleteEventInspectionPointApi({
        variables: {
          id: inspectionPointToDelete!.id!,
        },
      }).then(() => {
        fetchEventInspectionPoints?.()
        AppNotifications.success(t('message.success.deleteEventInspectionPoint'))
      }).catch(() => {
        AppNotifications.error(t('message.error.deleteEventInspectionPoint'))
      }).finally(() => {
        stopLoading()
      })
    }
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteInspectionPoint')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => setInspectionPointToDelete(undefined)}
      {...restProps}
    />
  )
}
