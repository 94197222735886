import { useMutation } from '@apollo/client'
import { CreateEventTasksMutation, DeleteEventTasksMutation, Event, EventTask, RefEventTask, UpdateOneEventTaskByIdMutation } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENT_TASKS_CREATE_MANY, WORKORDER_EVENT_TASKS_DELETE_MANY, WORKORDER_EVENT_TASKS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { useEventStore } from '@app/stores/event'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EWORKORDER_REF_EVENT_TASK_CATEGORY } from '@app/utils/enums'
import DeleteEventTaskModal from './DeleteEventTaskModal'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { LuClipboardType, LuPlusSquare } from 'react-icons/lu'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import TaskSelectionModal from '@app/components/Common/Block/Task/TaskBlock/TaskSelectionModal'
import SortableList, { SortableItem } from '@app/components/Common/List/SortableList'
import TaskItem from './TaskList/TaskItem'
import { TDbId } from '@app/types/app'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'

type TTaskBlock = Readonly<{
  event: Partial<Event>
  readonly?: boolean
}>

export default function TaskBlock({ event, readonly }: TTaskBlock) {
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const { taskToDelete, setTaskToDelete, eventTasks, fetchEventTasks } = useEventStore()
  const [inspectionTasks, setInspectionTasks] = useState<Partial<EventTask>[]>()
  const [safetyTasks, setSafetyTasks] = useState<Partial<EventTask>[]>()
  const { startLoading, stopLoading } = useLayoutStore()
  const [manHoursTotal, setManHoursTotal] = useState<number | undefined>()
  const [addEventTasksApi] = useMutation<CreateEventTasksMutation>(WORKORDER_EVENT_TASKS_CREATE_MANY)
  const [deleteEventTasksApi] = useMutation<DeleteEventTasksMutation>(WORKORDER_EVENT_TASKS_DELETE_MANY)
  const [taskSelectionModalDisplayed, changeTaskSelectionModalDisplay] = useState<boolean>(false)
  const [updateEventTaskApi] = useMutation<UpdateOneEventTaskByIdMutation>(WORKORDER_EVENT_TASKS_UPDATE_BY_ID)

  const addItems = (taskIds: TDbId[]) => addEventTasksApi({
    variables: {
      data: taskIds.map((taskId: TDbId) => ({
        wrkoId: event.id,
        taskId,
      })),
    },
  })

  const deleteItems = (eventTaskIds: TDbId[]) => deleteEventTasksApi({
    variables: {
      ids: eventTaskIds,
    },
  })

  // Validate seletion modal (Add or delete task )
  const onValidateSelection = async (selectedItems: Partial<RefEventTask>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []

    if (oldSelectedIds.length) {
      const eventTaskIds = (eventTasks?.filter((item: Partial<EventTask>) => item.taskId && oldSelectedIds.includes(item.taskId)).map((item: Partial<EventTask>) => item.id) ?? []) as TDbId[]
      if (eventTaskIds.length) {
        promises.push(deleteItems(eventTaskIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        changeTaskSelectionModalDisplay(false)
        fetchEventTasks?.()
        AppNotifications.success(t('message.success.eventTasksUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    }
  }

  const updateSortHandler = (item: Partial<EventTask>, newSortVal: number) => {
    startLoading()
    updateEventTaskApi({
      variables: {
        id: item.id!,
        data: {
          sort: {
            set: newSortVal,
          },
        },
      },
    }).then(() => {
      AppNotifications.success(t('message.success.eventTaskUpdated'))
      fetchEventTasks?.()
    }).catch((error: Error) => {
      console.log(error)
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  const handleSortChange = (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => {
    updateSortHandler(dragItem as Partial<EventTask>, (dropItem as Partial<EventTask>).sort ?? dropIndex)
  }

  useEffect(() => {
    fetchEventTasks?.()
  }, [fetchEventTasks])

  useEffect(() => {
    setSafetyTasks(eventTasks?.filter((task: Partial<EventTask>) => task.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY))
    setInspectionTasks(eventTasks?.filter((task: Partial<EventTask>) => task.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.INSPECTION))
  }, [eventTasks])

  useEffect(() => {
    const total = inspectionTasks?.map((task: Partial<EventTask>) => Number((task.duration ?? 0) * (task.nbWorkers ?? 0)))?.reduce((allTaskManHours: number, taskManHours: number) => allTaskManHours + taskManHours, 0) ?? undefined
    setManHoursTotal(total ? Math.round(total * 100) / 100 : 0)
  }, [inspectionTasks])

  return (
    <div className="flex flex-col">
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem key="manage-task" disabled={readonly || config.getActionIsDisabled('event', 'taskSelect', event.status)} onClick={() => changeTaskSelectionModalDisplay(true)}>
            <LuPlusSquare className="mr-2" />
            {' '}
            {t('label.manageTask')}
          </RadDropdownMenuItem>,
        ]}
        actions={(
          <div className="border rounded bg-blue-200 px-4 py-2 text-sm font-semibold">
            <div className="text-primary">
              {t('label.totalManHours')}
              :
              {' '}
              {manHoursTotal}
            </div>
          </div>
        )}
      >
        <div className="flex items-center">
          <LuClipboardType size={20} className="mr-2" />
          {' '}
          {t('label.task')}
        </div>
      </FormGroupHeader>
      <div className="flex flex-col gap-1 mt-2">
        {
          safetyTasks?.map((task: Partial<EventTask>) => (
            <TaskItem key={`task-item-${task.id}`} isDeleteButtonDisabled item={task} />
          ))
        }
        <SortableList
          items={(inspectionTasks ?? []) as SortableItem[]}
          renderItem={(item: SortableItem) => (
            <TaskItem
              key={`task-item-${item.id!}`}
              hasDragBtn={!readonly}
              isDeleteButtonDisabled={readonly || config.getActionIsDisabled('event', 'taskSelect', event.status)}
              item={item as Partial<EventTask>}
              onDeleteClick={setTaskToDelete}
            />
          )}
          onSortChange={handleSortChange}
        />
      </div>
      <DeleteEventTaskModal open={!!taskToDelete} />
      {taskSelectionModalDisplayed && <TaskSelectionModal open selectedIds={eventTasks?.map((item: Partial<EventTask>) => item.task!.id!) ?? []} closeOnConfirm={false} onClose={() => changeTaskSelectionModalDisplay(false)} onValidate={onValidateSelection} />}
    </div>
  )
}
