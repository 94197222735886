import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateDocFlocsMutation, DeleteDocFlocsByDocIdAndFlocIdsMutation, Document, FunctionalLocation, GetFlocsByDocIdQuery } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_DOCUMENTS_FLOCS_CREATE_MANY, DOCUMENTATION_DOCUMENTS_FLOCS_DELETE_BY_DOC_ID_AND_FLOC_IDS, FLOCS_GET_BY_DOC_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useDocumentStore } from '@app/stores/document'
import { useLayoutStore } from '@app/stores/layout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import SearchBar from '@app/components/Common/SearchBar'
import FlocCard from '@app/components/Common/Block/Floc/FlocBlock/FlocCard'
import FlocSelectionModal from '@app/components/Common/Block/Floc/FlocBlock/FlocSelectionModal'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuPlusSquare, LuBox } from 'react-icons/lu'
import { TDbId } from '@app/types/app'
import { searchArray } from '@app/utils/functions'
import DeleteDocFlocModal from './DeleteDocFlocModal'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'

type TFlocBlock = Readonly<{
  doc: Partial<Document>
}>

export default function FlocBlock({ doc }: TFlocBlock) {
  const prms = useUserPermissions()
  const [searchInput, setSearchInput] = useState<string>('')
  const [filteredFlocs, setFilteredFlocs] = useState<Partial<FunctionalLocation>[]>()
  const { flocToDelete, flocs, setFlocs, setFlocsSelectionDisplayed, flocsSelectionDisplayed } = useDocumentStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [addDocFlocsApi] = useMutation<CreateDocFlocsMutation>(DOCUMENTATION_DOCUMENTS_FLOCS_CREATE_MANY)
  const [deleteDocFlocsApi] = useMutation<DeleteDocFlocsByDocIdAndFlocIdsMutation>(DOCUMENTATION_DOCUMENTS_FLOCS_DELETE_BY_DOC_ID_AND_FLOC_IDS)
  const [getFlocsByDocIdApi, { refetch, called }] = useLazyQuery<GetFlocsByDocIdQuery>(FLOCS_GET_BY_DOC_ID, {
    variables: {
      docId: doc.id!,
    },
    fetchPolicy: 'no-cache',
  })
  const { t } = useTranslation()

  const refreshDocFlocsList = () => {
    (called ? refetch : getFlocsByDocIdApi)().then((queryResults) => {
      const newFlocs = (queryResults.data?.functionalLocations ?? []) as FunctionalLocation[]
      setFlocs(newFlocs)
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    })
  }

  const addItems = async (flocIds: TDbId[]) => addDocFlocsApi({
    variables: {
      data: flocIds.map((flocId: TDbId) => ({
        docId: doc.id,
        flocId,
      })),
    },
  })

  const deleteItems = async (flocIds: TDbId[]) => deleteDocFlocsApi({
    variables: {
      flocIds,
      docId: doc.id!,
    },
  })

  // Validate seletion modal (Add or delete floc )
  const onValidateSelection = async (selectedItems: Partial<FunctionalLocation>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const idwgFlocIds = (flocs?.filter((item: Partial<FunctionalLocation>) => oldSelectedIds.includes(item.id!)).map((item: Partial<FunctionalLocation>) => item.id) ?? []) as TDbId[]
      if (idwgFlocIds.length) {
        promises.push(deleteItems(idwgFlocIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        setFlocsSelectionDisplayed(false)
        refreshDocFlocsList()
        AppNotifications.success(t('message.success.docFlocsUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    }
  }

  useEffect(() => {
    refreshDocFlocsList()
  }, [])

  useEffect(() => {
    setFilteredFlocs(searchArray(flocs ?? [], searchInput))
  }, [flocs, searchInput])

  useEffect(() => {
    setFilteredFlocs(searchArray(flocs ?? [], searchInput))
  }, [flocs, searchInput])

  return (
    <div className="flex flex-col gap-2">
      <FormGroupHeader
        menuItems={
          (!prms.docs.create && !prms.docs.update)
            ? []
            : [
                (
                  <RadDropdownMenuItem key="manage-cml" onClick={() => setFlocsSelectionDisplayed(true)}>
                    <LuPlusSquare className="mr-2" />

                    {t('label.manageFloc')}
                  </RadDropdownMenuItem>
                ),
              ]
        }
        actions={(
          <div className="flex items-center gap-2">
            <SearchBar
              className="w-[300px]"
              onChange={e => setSearchInput(e.target?.value)}
            />
          </div>
        )}
      >
        <div className="flex items-center gap-1">
          <LuBox />

          {t('label.floc')}
        </div>
      </FormGroupHeader>

      <div className="flex flex-col gap-2 pl-0">
        {filteredFlocs?.map(item => (
          <FlocCard
            key={`floc-${item.id!}`}
            hasContextMenu
            hideCreateIanButton
            floc={item as Partial<FunctionalLocation>}
            deleteText={t('label.removeFromDrawing')}
            isSchedulingTag={false}
          />
        ))}
      </div>
      {flocsSelectionDisplayed && <FlocSelectionModal open filterSites={typeof doc.sector?.site.site === 'string' ? [doc.sector?.site.site] : undefined} selectedIds={flocs?.map(item => item.id!) ?? []} closeOnConfirm={false} onClose={() => setFlocsSelectionDisplayed(false)} onValidate={onValidateSelection} />}
      <DeleteDocFlocModal open={!!flocToDelete} onDeleted={() => refreshDocFlocsList()} />
    </div>
  )
}
