import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { Attachment } from '@app/graphql/__types__/graphql'
import { TFormAction, TFormState } from '@app/types/app'
import { generateCommonFormStore } from '@app/utils/functions/stores'
import { TFunction } from 'i18next'
import { z } from 'zod'

type State = TFormState<Partial<Attachment>> & {
  fetchAttachments?: () => void
}

const initialState: State = {
  itemToDelete: undefined,
  autoSave: false,
  isModal: true,
  fetchAttachments: undefined,
}

export const MAX_LENGTH_VALIDATORS = {
  DESCRIPTION: 50,
  ATTACHMENT_NAME: 50,
  LONG_DESCRIPTION: 5000,
  NOTIF: 45,
}

export const ZOD_ATTACHMENT_DATAS = (t: TFunction) => ({
  description: z.string().max(MAX_LENGTH_VALIDATORS.DESCRIPTION).optional(),

  attachmentName: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.ATTACHMENT_NAME).min(1, { message: t('message.error.form.required') }),
})

type Actions = TFormAction<Partial<Attachment>> & {
  setFetchAttachments: (fetchAttachments?: () => void) => void
}

type AttachmentState = State & Actions

const useAttachmentStore = create<AttachmentState>()(
  immer(set => ({
    ...initialState,
    ...generateCommonFormStore<Partial<Attachment>>(set),
    setFetchAttachments(fetchAttachments) {
      set({ fetchAttachments })
    },
  })),
)

export default useAttachmentStore
