import { GetAllRefIntegrityConditionsQuery, GetAllRefIntegrityConsequencesQuery, IntegrityStatus, RefIntegrityCondition, UpdateIntegrityStatusByIdMutation } from '@app/graphql/__types__/graphql'
import { FORMAT_DATE_EU, INTEGRITY_CONDITION_COLOR, ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL } from '@app/utils/constants'
import { RadContextMenu, RadContextMenuTrigger, RadContextMenuContent, RadContextMenuItem, RadBadge, RadDropdownMenu, RadDropdownMenuTrigger, RadDropdownMenuContent, RadDropdownMenuItem } from '@holis/react-ui/rad'
import moment from 'moment'
import { LuExternalLink, LuCalendarDays, LuLineChart } from 'react-icons/lu'
import ItemCard, { ItemCardContent } from '../../ItemCard'
import LabelValueRow from '@app/components/Common/Form/LabelValueRow'
import { TextareaHTMLAttributes, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { INTEGRITY_REF_CONDITIONS_GET_MANY, INTEGRITY_REF_CONSEQUENCES_GET_ALL, INTEGRITY_STATUSES_UPDATE_BY_ID } from '@app/graphql/requests'
import _ from 'lodash'
import AppNotifications from '@app/services/notification'
import { useTranslation } from 'react-i18next'
import { useLayoutStore } from '@app/stores/layout'
import MeasurementValueContainer from '../../ItemCard/MeasurementValueContainer'
import { generatePathWithBaseUrl, tailwindColorToBgFgStyle } from '@app/utils/functions'
import { cn } from '@holis/react-ui/utils'
import { generatePath } from 'react-router-dom'

type TIntegrityStatusCard = Readonly<{
  integrityStatus: Partial<IntegrityStatus>
  hasMenuContext?: boolean
  className?: string
  readonly?: boolean
  onIntegrityStatusUpdated?: (integrityStatus: Partial<IntegrityStatus>) => void
}>
export default function IntegrityStatusCard({ integrityStatus, className, hasMenuContext, readonly, onIntegrityStatusUpdated }: TIntegrityStatusCard) {
  const floc = integrityStatus.functionalLocation!
  const [ints, setInts] = useState<Partial<IntegrityStatus>>(integrityStatus)
  const [notes, setNotes] = useState<string>()
  const { startLoading, stopLoading } = useLayoutStore()
  const { t } = useTranslation()
  const [updateIntegrityStatusApi] = useMutation<UpdateIntegrityStatusByIdMutation>(INTEGRITY_STATUSES_UPDATE_BY_ID)
  const getAllRefIntegrityConditionsApi = useQuery<GetAllRefIntegrityConditionsQuery>(INTEGRITY_REF_CONDITIONS_GET_MANY)
  const { data: refIntegrityConsequences } = useQuery<GetAllRefIntegrityConsequencesQuery>(INTEGRITY_REF_CONSEQUENCES_GET_ALL)

  const handleUpdateIntsField = (field: string, value: unknown) => {
    if (!_.isEqual(value ?? '', ints?.[field as keyof IntegrityStatus] ?? '')) {
      startLoading()
      updateIntegrityStatusApi({
        variables: {
          id: integrityStatus.id,
          data: {
            [field]: {
              set: value,
            },
          },
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        AppNotifications.success(t('message.success.intsUpdated'))
        setInts(queryResult.data?.updateOneIntegrityStatus as Partial<IntegrityStatus>)
        onIntegrityStatusUpdated?.(queryResult.data?.updateOneIntegrityStatus as Partial<IntegrityStatus>)
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'))
      }).finally(stopLoading)
    }
  }

  const openFlocDetail = () => {
    window.open(generatePathWithBaseUrl(generatePath(ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL, { number: encodeURIComponent(floc.floc) })))
  }

  useEffect(() => {
    setInts(integrityStatus)
  }, [integrityStatus])

  useEffect(() => {
    setNotes(ints?.notes ?? '')
  }, [ints])

  return (
    <RadContextMenu>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <ItemCard className={cn('', className)}>

          <ItemCardContent className="flex items-center gap-2">

            <div className="flex-1 flex flex-col py-1 gap-1 self-stretch">
              <div className="flex gap-1 items-center">
                <div className="flex gap-2 items-center">
                  <RadBadge className="text-nowrap">
                    {ints?.functionalLocation?.techClass?.techClass}
                  </RadBadge>
                  <div className="font-semibold text-nowrap">{floc.floc}</div>
                  <div>|</div>
                  <div className="line-clamp-1">{floc.description}</div>
                </div>
              </div>
              <div className="min-h-[1em]">{ints?.notes}</div>
              <LabelValueRow
                field="notes"
                className="my-2 hidden"
                inputComponent="textarea"
                value={notes}
                label={false}
                isDisabled={readonly}
                inputProps={{
                  rows: 2,
                  disabled: readonly,
                  className: 'min-h-[24px] border-0 p-[10px] hover:border hover:p-[9px] focus:p-[9px] hover:bg-gray-50 focus:border text-xs',
                  onBlur: () => handleUpdateIntsField('notes', notes),
                } as TextareaHTMLAttributes<HTMLTextAreaElement>}
                handleFieldChange={(field, value) => setNotes(String(value))}
              />
              <div className="flex flex2 text-gray-500">
                <div className="flex flex-col gap-1">
                  <div className="flex gap-1 items-center">
                    <LuCalendarDays />
                    {t('label.maxEndDate')}
                  </div>
                  <span className="font-bold text-xs">{integrityStatus.maxEndDate ? moment.parseZone(integrityStatus.maxEndDate!).format(FORMAT_DATE_EU) : '-'}</span>
                </div>
                <div className="flex flex-col gap-1 ml-12">
                  <div className="flex gap-1 items-center">
                    <LuLineChart />
                    {t('label.corrRateExt')}
                  </div>
                  <span className="font-bold text-xs">{integrityStatus.corrRateExt ?? '-'}</span>
                </div>
                <div className="flex flex-col gap-1 ml-12">
                  <div className="flex gap-1 items-center">
                    <LuLineChart />
                    {t('label.corrRateInt')}
                  </div>
                  <span className="font-bold text-xs">{integrityStatus.corrRateInt ?? '-'}</span>
                </div>
                <div className="flex flex-col gap-1 ml-12 min-w-[130px]">
                  <div className="flex gap-1 items-center">
                    <LuLineChart />
                    {t('label.consequence')}
                  </div>
                  <RadDropdownMenu>
                    <RadDropdownMenuTrigger disabled={readonly} className="focus:outline-none">
                      <div style={{ ...tailwindColorToBgFgStyle(ints?.integrityConsequence?.color as string), backgroundColor: 'transparent' }} className={`${readonly ? 'opacity-70' : 'hover:opacity-80 cursor-pointer'}`}>
                        <span className="font-bold text-xs ">
                          [
                          {ints?.integrityConsequence?.consequence ?? '-'}
                          ]
                          {ints?.integrityConsequence?.description ?? '-'}
                        </span>
                      </div>
                    </RadDropdownMenuTrigger>
                    <RadDropdownMenuContent>
                      {refIntegrityConsequences?.refIntegrityConsequences.map(consequence => (
                        <RadDropdownMenuItem key={consequence.consequence} className="cursor-pointer" onClick={() => handleUpdateIntsField('consequence', consequence.consequence)}>
                          <RadBadge style={tailwindColorToBgFgStyle(consequence.color ?? undefined)} className="w-full">
                            <span className="font-bold text-sm">
                              [
                              {consequence.consequence}
                              ]
                              {consequence.description}
                            </span>
                          </RadBadge>
                        </RadDropdownMenuItem>
                      ))}
                    </RadDropdownMenuContent>
                  </RadDropdownMenu>
                </div>
              </div>
            </div>

            <MeasurementValueContainer
              measurementValue={ints?.integrityCondition?.condition}
              measurementDescription={ints?.integrityCondition?.description ?? undefined}
              measurementColor={ints?.integrityCondition?.color ?? INTEGRITY_CONDITION_COLOR as string}
              measurementList={getAllRefIntegrityConditionsApi.data?.refIntegrityConditions as RefIntegrityCondition[]}
              onMeasurementUpdated={(val) => {
                handleUpdateIntsField('condition', val)
              }}
            />
          </ItemCardContent>

        </ItemCard>
      </RadContextMenuTrigger>
      <RadContextMenuContent>
        <RadContextMenuItem onClick={openFlocDetail}>
          <LuExternalLink className="mr-2" />
          {' '}
          {t('label.openFloc')}
        </RadContextMenuItem>
      </RadContextMenuContent>
    </RadContextMenu>
  )
}
