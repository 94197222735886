import { gql } from '@apollo/client'
import { DWG_REF_TYPES_COMMON_FIELDS } from '../fragments'

export const DWG_REF_TYPES_GET_MANY = gql`
${DWG_REF_TYPES_COMMON_FIELDS}
query GetAllRefDwgTypes($orderBy: [RefDwgTypeOrderByWithRelationInput!], $where: RefDwgTypeWhereInput) {
    refDwgTypes(orderBy: $orderBy, where: $where) {
      ...RefDwgTypeCommonFields
    }
}`

export const DWG_REF_TYPES_GET_COUNTER = gql`
query GetRefDwgTypesCounter {
  aggregateRefDwgType {
    _count {
      id
    }
  }
}
`
