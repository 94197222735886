import { gql } from '@apollo/client'
import { FILE_ATTACHMENTS_COMMON_FIELDS } from '../fragments'

export const FILE_ATTACHMENTS_CREATE = gql`
${FILE_ATTACHMENTS_COMMON_FIELDS}
mutation createAttachment($data: AttachmentUncheckedCreateInput!) {
  createOneAttachment (data: $data) {
    ...AttachmentCommonFields
    event {
      id
      event
    }
  }
}`

export const FILE_ATTACHMENTS_UPDATE_BY_ID = gql`
${FILE_ATTACHMENTS_COMMON_FIELDS}
mutation updateAttachmentById($id: Int!, $data: AttachmentUncheckedUpdateInput!) {
  updateOneAttachment (where: {id: $id}, data: $data) {
    ...AttachmentCommonFields
    event {
      id
      event
    }
  }
}`

export const FILE_ATTACHMENTS_GET_BY_EVT_ID = gql`
${FILE_ATTACHMENTS_COMMON_FIELDS}
query getAttachmentsByEvtId($evtId: Int!) {
  attachments(where: {wrkoId: {equals: $evtId}}, orderBy: {sort: {sort: asc}}) {
    ...AttachmentCommonFields
    event {
      id
      event
    }
  }
}`

export const FILE_ATTACHMENTS_GET_BY_NOTIF_ID = gql`
${FILE_ATTACHMENTS_COMMON_FIELDS}
query getAttachmentsByNotifId($notifId: Int!) {
  attachments(where: {notifId: {equals: $notifId}}, orderBy: {sort: {sort: asc}}) {
    ...AttachmentCommonFields
    notification {
      id 
      notif 
    }
  }
}`

export const FILE_ATTACHMENTS_DELETE_BY_ID = gql`
mutation DeleteAttachmentById($id: Int!) {
  deleteOneAttachment(where: {id: $id}) {
    id
  }
}`
