import AppNotifications from '@app/services/notification'

import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateInspectionDrawingMutation, GetInspectionDrawingDetailByNumberQuery, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { INSPECTION_DRAWINGS_CREATE, INSPECTION_DRAWINGS_GET_DETAIL_BY_NUMBER } from '@app/graphql/requests'
import { ZOD_IDWG_DATAS } from '@app/stores/idwg'
import { useInspectionDrawingStore } from '@app/stores/methodEngineering/inspectionDrawing'
import { getFileNameWithoutExtension, setObjValueByPath } from '@app/utils/functions'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import Form from './components/Form'
import { useLayoutStore } from '@app/stores/layout'
import { useDataStore } from '@app/stores/data'
import { EDrawingFileContentType, EImportService } from '@app/utils/enums'
import WarningConfirmModal from '../../Confirm/Warning'
import { useNavigate } from 'react-router-dom'
import { ROUTE_DOCUMENTATION_IDWG_DETAIL } from '@app/utils/constants'
import CreateForm from '../../SingleFormModal/CreateForm'

type TIdwgModal = Readonly<{
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onCreated?: (idwg: Partial<InspectionDrawing>) => void
}>

export default function IdwgModal({ isOpen, onOpenChange, onCreated }: TIdwgModal) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLayoutStore()
  const [confirmDocNameExistsModalDisplayed, changeConfirmDocNameExistsModalDisplay] = useState<boolean>(false)
  const { editIdwg, setEditIdwg, updateDataField, updateData, updateFieldError, fetchInspectionDrawings, uploadFile, resetData } = useInspectionDrawingStore()
  const [createIdwgApi] = useMutation<CreateInspectionDrawingMutation>(INSPECTION_DRAWINGS_CREATE)
  const [getIdwgByNumberApi] = useLazyQuery<GetInspectionDrawingDetailByNumberQuery>(INSPECTION_DRAWINGS_GET_DETAIL_BY_NUMBER)
  const { uploadDownloadService } = useDataStore()

  const zodFormObject = z.object(ZOD_IDWG_DATAS(t))
  const form = useForm<z.infer<typeof zodFormObject>>(
    {
      resolver: zodResolver(zodFormObject), mode: 'onSubmit', defaultValues: {
        revision: '',
      },
    })
  const htmlForm = useRef<HTMLFormElement>(null)

  const handleOverrideDoc = (confirmed: boolean) => {
    if (confirmed) {
      handleCreateIdwg(true)
    } else {
      updateIdwg()
    }
  }

  // Update idwg value in store (will update updateData)
  const updateIdwgDataValue = (field: string, value: unknown) => {
    if (field === 'idwg') {
      value = (value as string).toUpperCase()
    }

    const editedIdwg = { ...editIdwg }
    setObjValueByPath(editedIdwg, field, value)
    updateDataField(field, value)

    setEditIdwg(editedIdwg)
  }

  // Watch form value changes
  form.watch((datas, { name, type }) => {
    if (type === 'change') {
      updateIdwgDataValue(name!, datas[name!])
    }
  })

  // Handle idwg creation error.
  const handleIdwgCreateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title')
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`idwg`)')) {
        if (AppNotifications.timeoutId) {
          clearTimeout(AppNotifications.timeoutId)
        }

        errorMessage = t('message.error.unique.documentation.inspectionDrawing.idwg')
        updateFieldError('idwg', true)
        form.setError('idwg', {
          message: errorMessage,
        })
        return
      }
    }

    AppNotifications.error(errorMessage)
  }

  const checkNumberExists = async (): Promise<boolean> => {
    const result = await getIdwgByNumberApi({
      variables: {
        number: updateData?.idwg,
      },
    })
    return !!result.data?.inspectionDrawing
  }

  // Handle actual document creation.
  const handleCreateIdwg = async (updateIfExists = false) => {
    // Check number exists
    const numberExists = await checkNumberExists()
    if (numberExists) {
      if (AppNotifications.timeoutId) {
        clearTimeout(AppNotifications.timeoutId)
      }

      updateFieldError('idwg', true)
      form.setError('idwg', {
        message: t('message.error.unique.documentation.inspectionDrawing.idwg'),
      })
      return
    }

    if (uploadFile) {
      startLoading()
      uploadDownloadService!.uploadFile(uploadFile, {
        contentType: EDrawingFileContentType.PDF,
        updateIfExists: updateIfExists ? 1 : 0,
        async: 0,
      }, EImportService.UPLOAD_DRAWING).then((response) => {
        if (response.status !== 200) {
          const responseJson = JSON.parse(response.data)
          throw new Error(typeof responseJson === 'object' && uploadFile.name && responseJson.errors?.files?.[uploadFile.name] ? responseJson.errors.files![uploadFile.name] : response.data.message ?? response.statusText)
        }

        updateIdwg()
      }).catch((err: Error) => {
        console.log(err)
        let errorMessage = t('message.error.uploadDocumentationDoc')
        if (typeof err?.message === 'string') {
          errorMessage = err.message
          if (err.message.includes(`File ${getFileNameWithoutExtension(uploadFile.name)} already exists`)) {
            errorMessage = t('message.error.unique.file.doc.docName')
            updateFieldError('docName', true)
            form.setError('docName', {
              message: errorMessage,
            })
            changeConfirmDocNameExistsModalDisplay(true)
            return
          }
        }

        AppNotifications.error(errorMessage)
      }).finally(stopLoading)
    } else {
      updateIdwg()
    }
  }

  // Handle actual idwg creation.
  const updateIdwg = () => {
    startLoading()
    createIdwgApi({ variables: { data: updateData } }).then((queryResult) => {
      if (!onCreated) {
        AppNotifications.success(t('message.success.idwgCreated'))
        fetchInspectionDrawings?.()
        resetData()
        navigate(ROUTE_DOCUMENTATION_IDWG_DETAIL.replace(/:number/, editIdwg!.idwg!))
        onOpenChange(false)
      } else {
        onCreated!(queryResult.data?.createOneInspectionDrawing as Partial<InspectionDrawing>)
      }
    }).catch((err: Error) => {
      handleIdwgCreateError(err)
    }).finally(stopLoading)
  }

  return (
    <CreateForm
      isOpen={isOpen}
      title={t('label.idwgCreation')}
      description={t('label.itemCreateDescription')}
      onSaveClick={() => htmlForm.current?.requestSubmit()}
      onOpenChange={onOpenChange}
    >
      <RadForm {...form}>
        <form ref={htmlForm} onSubmit={form.handleSubmit(() => handleCreateIdwg(false))}>
          <Form />
        </form>
      </RadForm>
      <WarningConfirmModal closeOnConfirm closeOnCancel cancelButtonLabel={t('label.no')} confirmButtonLabel={t('label.yes')} className="min-w-[auto] min-h-[150px] w-[600px]" open={confirmDocNameExistsModalDisplayed} description={t('message.questions.fileNameExistsWantToOverrideDoc')} onConfirm={() => handleOverrideDoc(true)} onClose={() => changeConfirmDocNameExistsModalDisplay(false)} onCancel={() => handleOverrideDoc(false)} />
    </CreateForm>
  )
}
