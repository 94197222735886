import { RefCmlValCode, RefDamageValCode, RefMeasPointValCode } from '@app/graphql/__types__/graphql'
import { RadBadge } from '@holis/react-ui/rad'
import { RADIAN } from '../constants'
import { PieSectorDataItem, Props } from 'recharts/types/polar/Pie'
import { tailwindColorFromHex, tailwindColorToBgFgStyle, tailwindColorToHex } from './strings'
import { ReactNode } from 'react'
import { getTextFromReactNode } from '@holis/react-ui/utils'

export function renderQualReadingItem(item?: Partial<RefMeasPointValCode> | Partial<RefCmlValCode> | Partial<RefDamageValCode> | null) {
  return item
    ? (
        <div className="flex flex-row items-center">
          <RadBadge className="min-w-[60px] justify-center text-center mr-2" style={tailwindColorToBgFgStyle(item?.integrityCondition?.color)}>{item.valCode}</RadBadge>
          {item.description}
        </div>
      )
    : ''
}

export const renderCustomizedPieChartLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, className, payload }: Props & PieSectorDataItem) => {
  const radius = innerRadius! + ((outerRadius! - innerRadius!) * 0.5)
  const x = cx! + (radius * Math.cos(-midAngle! * RADIAN))
  const y = cy! + (radius * Math.sin(-midAngle! * RADIAN))

  const pld = payload as unknown as { name: string, fill: string }
  const tailwindColor = tailwindColorFromHex(pld.fill)
  const textColor = tailwindColorToHex(tailwindColor, '800')

  return (
    <text x={x} y={y - 8} fill={textColor} textAnchor={x > cx! ? 'start' : 'end'} dominantBaseline="central" className={className}>
      <tspan x={x} y={y - 8}>{pld.name}</tspan>
      <tspan x={x} y={y + 8}>{`${(percent! * 100).toFixed(0)}%`}</tspan>
    </text>
  )
}

export const textEllipsisWrapper = (content: ReactNode) => <div className="text-ellipsis overflow-hidden text-nowrap" title={getTextFromReactNode(content)}>{content}</div>
export const textEllipsisCellRenderer = (val: unknown) => textEllipsisWrapper(val as ReactNode)
