import { gql } from '@apollo/client'
import { CML_REF_CODE_GROUPS_COMMON_FIELDS } from '../fragments/refCmlCodeGroups'

export const CML_REF_CODE_GROUPS_GET_MANY = gql`
${CML_REF_CODE_GROUPS_COMMON_FIELDS}
query GetAllRefCmlCodeGroups($orderBy: [RefCmlCodeGroupOrderByWithRelationInput!], $where: RefCmlCodeGroupWhereInput) {
    refCmlCodeGroups(orderBy: $orderBy, where: $where) {
      ...RefCmlCodeGroupCommonFields
    }
}`

export const CML_REF_CODE_GROUPS_GET_COUNTER = gql`
query GetRefCmlCodeGroupsCounter {
  aggregateRefCmlCodeGroup {
    _count {
      id
    }
  }
}
`
