import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteOneEventTaskByIdMutation } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENT_TASKS_DELETE_ONE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { useEventStore } from '@app/stores/event'
import { EWORKORDER_REF_EVENT_TASK_CATEGORY } from '@app/utils/enums'
import { useTranslation } from 'react-i18next'
type TDeleteEventTaskModal = IConfirmModal

export default function DeleteEventTaskModal({ ...restProps }: TDeleteEventTaskModal) {
  const { setTaskToDelete, taskToDelete, fetchEventTasks } = useEventStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteEventTaskApi] = useMutation<DeleteOneEventTaskByIdMutation>(WORKORDER_EVENT_TASKS_DELETE_ONE_BY_ID)
  const { t } = useTranslation()
  const onConfirm = () => {
    if (taskToDelete) {
      startLoading()
      deleteEventTaskApi({
        variables: {
          id: taskToDelete!.id!,
        },
      }).then(() => {
        fetchEventTasks?.()
        AppNotifications.success(t(`message.success.deleteEventTask${taskToDelete.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}`))
      }).catch((error: Error) => {
        console.log(error)
        AppNotifications.error(t(`message.error.deleteEventTask${taskToDelete.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}`))
      }).finally(() => {
        stopLoading()
      })
    }
  }

  return (
    <WarningConfirmModal
      description={t(`message.questions.deleteEventTask${taskToDelete?.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}`)}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => setTaskToDelete(undefined)}
      {...restProps}
    />
  )
}
