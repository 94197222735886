export enum EMarkupForm {
  HEXAGONE_SOLID = 'HEXAGONE_SOLID',
  SQUARE_SOLID = 'SQUARE_SOLID',
  CIRCLE_SOLID = 'CIRCLE_SOLID',
  SPLASH_SOLID = 'SPLASH_SOLID',
  DCIRCLE_SOLID = 'DCIRCLE_SOLID',
  DIAMOND_SOLID = 'DIAMOND_SOLID',
  TRIANGLE_SOLID = 'TRIANGLE_SOLID',
  CYLINDER_SOLID = 'CYLINDER_SOLID',
  TRIANGLERECT_SOLID = 'TRIANGLERECT_SOLID',
  TRIANGLERECT_DOTTED = 'TRIANGLERECT_DOTTED',
  DIAMOND_DOTTED = 'DIAMOND_DOTTED',
  SQUARE_DOTTED = 'SQUARE_DOTTED',
  CIRCLE_DOTTED = 'CIRCLE_DOTTED',
  COMMENT = 'COMMENT',
}

export enum EDMGMarkupForm {
  DMG_PAINTBRUSH = 'DMG_PAINTBRUSH',
  DMG_CIRCLE = 'DMG_CIRCLE',
  DMG_BOLT = 'DMG_BOLT',
  DMG_CRACK = 'DMG_CRACK',
  DMG_CYLINDER = 'DMG_CYLINDER',
  DMG_SUPP = 'DMG_SUPP',
  DMG_MECH = 'DMG_MECH',
  DMG_TOOL = 'DMG_TOOL',
  DMG_LAYER = 'DMG_LAYER',
  DMG_THIN = 'DMG_THIN',
}

export enum EFieldType {
  text,
  autocomplete,
  boolean,
  date,
  datetime,
  number,
  multivalue,
}

export const EInputFieldType = [
  EFieldType.boolean,
  EFieldType.text,
  EFieldType.date,
  EFieldType.datetime,
  EFieldType.number,
]

export enum EAdditionalAction {
  NATIVE = 'NATIVE',
  DOWNLOAD = 'DOWNLOAD_ACTION',
  DOWNLOAD_NATIVE = 'DOWNLOAD_NATIVE_ACTION',
  UPLOAD = 'UPLOAD_ACTION',
  UPLOAD_NATIVE = 'UPLOAD_NATIVE_ACTION',
  DELETE = 'DELETE_ACTION',
  COPY = 'COPY_ACTION',
  OPEN_BLANK_PAGE = 'OPEN_BLANK_PAGE_ACTION',
  GENERATE_WORKPACK = 'GENERATE_WORKPACK',
  DISPLAY_WORKFLOW = 'DISPLAY_WORKFLOW',
  PRINT_PLAN = 'PRINT_PLAN',
  PRINT_WORKPACK = 'PRINT_WORKPACK',
  PRINT_IAN = 'PRINT_IAN',
  GENERATE_REPORT = 'GENERATE_REPORT',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
}

export enum EGenerateWorkpackBtnError {
  FLOC_LIST_EMPTY = 'flocListEmpty',
  INSPECTION_TASK_LIST_EMPTY = 'inspectionTaskListEmpty',
  FLOC_INTS_NO_INSPECTION_DRAWING = 'flocIntsNoInspectionDrawing',
}

export enum EWorkpackLeftSideTab {
  EVENT_INFO,
  OBJECT_TASK_LIST,
  LONG_DESCRIPTION,
  CMLS,
  DAMAGES,
  INPSECTION_POINTS,
  IAN,
  DOCS,
}

export enum EWorkpackRightSideTab {
  EVENT = 'event',
  DRAWINGS = 'drawings',
  PICTURES = 'pictures',
  OVERVIEW = 'overview',
  DOCUMENTATION = 'documentation',
  REPORT = 'report',
}

export enum EFlocRightSideTab {
  DRAWINGS = 'drawings',
  PICTURES = 'pictures',
  OVERVIEW = 'overview',
  EVENTS = 'events',
  INTEGRITY = 'integrity',
  DOCUMENTATION = 'documentation',
}

export enum EIanLeftSideTab {
  HEADER,
  PRIORITY_COMPLIANCE,
  DAMAGE,
  SPECIFIC_DATA,
  ATTACHMENTS,
}

export enum EIanRightSideTab {
  DRAWINGS = 'drawings',
  LONG_DESCRIPTION = 'longDescription',
  THREE_D = '3D',
}

export enum ECmlFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum EEventFlocObjectFilter {
  EVENT = 'event',
  FLOC = 'floc',
}

export enum EEventMeasurementFilter {
  LIST = 'list',
  TREND = 'trend',
}

export enum EEventFilter {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum EDamageFilter {
  ACTIVE = 'active',
  FIXED = 'fixed',
}

export enum EImportService {
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPLOAD_REPORT = 'UPLOAD_REPORT',
  UPLOAD_DRAWING = 'UPLOAD_DRAWING',
  UPLOAD_PICTURE = 'UPLOAD_PICTURE',
  UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT',
  IMPORT_DATA = 'IMPORT_DATA',
  IMPORT_CHECK_DATA = 'IMPORT_CHECK_DATA',
  UPLOAD_CHECK_FILE = 'UPLOAD_CHECK_FILE',
  UPLOAD_PICTURE_CHECK = 'UPLOAD_CHECK_PICTURE',
}

export enum EDownloadFileType {
  PICTURE = 'picture',
  REPORT = 'report',
  ATTACHMENT = 'attachment',
  FILE = 'file',
  DRAWING = 'drawing',
  DOCUMENT = 'doc',
}

export enum EApiOperator {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum EDrawingFileContentType {
  PDF = 'pdf',
  SVG = 'svg',
  NATIVE = 'native',
}

export enum EPictureContentType {
  ORIGIN = 'origin',
  ANNOTATION = 'annotation',
}

export enum REPORT_GENERATION_STATUS {
  NOT_STARTED = 'report-generation-not-started',
  IN_PROCESS = 'report-generation-in-process',
  IN_PROGRESS = 'report-generation-in-progress',
  COMPLETED = 'report-generation-completed',
  FAILED = 'report-generation-failed',
}

export enum EVerticalPosition {
  TOP = -1,
  MIDDLE = 0,
  BOTTOM = 1,
}

export enum EEventPerformanceDashboardPlannedDateFilter {
  OVERDUE = 'overdue',
  ONE_MONTH = '1m',
  THREE_MONTH = '3m',
  SIX_MONTH = '6m',
  TWELVE_MONTH = '12m',
}
