import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteOnePlanFlocByIdMutation } from '@app/graphql/__types__/graphql'
import { SCHEDULING_PLAN_FLOCS_DELETE_ONE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import usePlanStore from '@app/stores/plan'
import { useTranslation } from 'react-i18next'
type TDeletePlanFlocModal = IConfirmModal & Readonly<{
  onDeleted?: () => void
}>

export default function DeletePlanFlocModal({ onDeleted, ...restProps }: TDeletePlanFlocModal) {
  const { setFlocToDelete, flocToDelete, deletePlanFloc } = usePlanStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [deletePlanFlocApi] = useMutation<DeleteOnePlanFlocByIdMutation>(SCHEDULING_PLAN_FLOCS_DELETE_ONE_BY_ID)
  const { t } = useTranslation()
  const onConfirm = () => {
    if (flocToDelete) {
      startLoading()
      deletePlanFlocApi({
        variables: {
          id: flocToDelete!.id!,
        },
      }).then(() => {
        deletePlanFloc()
        onDeleted?.()
        AppNotifications.success(t('message.success.deletePlanFloc'))
      }).catch(() => {
        AppNotifications.error(t('message.error.deletePlanFloc'))
      }).finally(() => {
        stopLoading()
      })
    }
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deletePlanFloc')}
      title={t('message.questions.areYouAbsolutlySure')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => setFlocToDelete(undefined)}
      {...restProps}
    />
  )
}
