import { useTranslation } from 'react-i18next'
import { Plan } from '@app/graphql/__types__/graphql'
import PlanInfo from './PlanInfo'
import ObjectList from './ObjectList'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { RadDropdownMenuItem, RadSeparator } from '@holis/react-ui/rad'
import { LuBox, LuPlusSquare } from 'react-icons/lu'
import usePlanStore from '@app/stores/plan'
import SearchBar from '@app/components/Common/SearchBar'
import React from 'react'

type TLeftSide = Readonly<{
  plan: Partial<Plan>
}>

export default function LeftSide({ plan }: TLeftSide) {
  const { t } = useTranslation()
  const { setFlocsSelectionDisplayed, flocs } = usePlanStore()
  const [filterString, setSearchInput] = React.useState<string>('')

  return (
    <div className="h-full w-full p-4 overflow-auto">
      <FormGroupHeader>{t('label.header')}</FormGroupHeader>
      <PlanInfo plan={plan} />
      <RadSeparator className="my-6" />
      <FormGroupHeader
        menuItems={[
          (
            <RadDropdownMenuItem key="manage-floc" onClick={() => setFlocsSelectionDisplayed(true)}>
              <LuPlusSquare className="mr-2" />

              {t('label.manageFloc')}
            </RadDropdownMenuItem>
          ),
        ]}
        actions={<SearchBar value={filterString} onChange={e => setSearchInput(e.target?.value)} />}
      >
        <div className="flex items-center gap-1">
          <LuBox />

          {t('label.objectList')}
          {' '}
          (
          {flocs?.length ?? plan._count?.planFlocs ?? 0}
          )
        </div>
      </FormGroupHeader>
      <ObjectList plan={plan} />
    </div>
  )
}
