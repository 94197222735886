import { gql } from '@apollo/client'

export const DAMAGE_REF_CODE_GROUP_VAL_CODES_GET_COUNTER = gql`
query GetRefDamageCodeGroupValCodesCounter {
  aggregateRefDamageCodeGroupValCode {
    _count {
      id
    }
  }
}
`
