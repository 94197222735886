import { gql } from '@apollo/client'

export const SECTORS_COMMON_FIELDS = gql`
    fragment SectorCommonFields on Sector {
        id
        sector
        site {
            description
            site
            id
        }
        siteId
        description
    }
`
