import DocumentSelectionModal from './DocumentSelectionModal'
import { useMutation } from '@apollo/client'
import { Document, CreateDocFlocsMutation, DeleteFlocDocsByFlocIdAndDocIdsMutation } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_DOCUMENTS_FLOCS_CREATE_MANY, DOCUMENTATION_DOCUMENTS_FLOCS_DELETE_BY_FLOC_ID_AND_DOC_IDS } from '@app/graphql/requests'
import { TDbId } from '@app/types/app'
import { useLayoutStore } from '@app/stores/layout'
import AppNotifications from '@app/services/notification'
import { t } from 'i18next'
import { useFlocStore } from '@app/stores/methodEngineering/floc'

export default function FlocDocumentSelectionModal() {
  const { activeFloc, changeDocumentSelectionModalDisplay, documents, fetchDocuments } = useFlocStore()
  const [addFlocDocsApi] = useMutation<CreateDocFlocsMutation>(DOCUMENTATION_DOCUMENTS_FLOCS_CREATE_MANY)
  const [deleteFlocDocsApi] = useMutation<DeleteFlocDocsByFlocIdAndDocIdsMutation>(DOCUMENTATION_DOCUMENTS_FLOCS_DELETE_BY_FLOC_ID_AND_DOC_IDS)
  const { startLoading, stopLoading } = useLayoutStore()

  const addItems = (ids: TDbId[]) => addFlocDocsApi({
    variables: {
      data: ids.map((docId: TDbId) => ({
        flocId: activeFloc!.id!,
        docId,
      })),
    },
  })

  const deleteItems = (docIds: TDbId[]) => deleteFlocDocsApi({
    variables: {
      flocId: activeFloc!.id,
      docIds,
    },
  })

  const handleValidateSelection = async (selectedItems: Partial<Document>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const docIds = (documents?.filter((item: Partial<Document>) => oldSelectedIds.includes(item.id!)).map((item: Partial<Document>) => item.id) ?? []) as TDbId[]
      if (docIds.length) {
        promises.push(deleteItems(docIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        fetchDocuments?.()
        changeDocumentSelectionModalDisplay(false)
        AppNotifications.success(t('message.success.flocDocsUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    }
  }

  return (
    <DocumentSelectionModal open filterSites={typeof activeFloc?.sector?.site.site === 'string' ? [activeFloc?.sector?.site.site] : undefined} isLoading={typeof documents === 'undefined'} description={t('label.manageFlocDocumentDescription')} selectedIds={documents?.map(doc => doc.id!) ?? []} onClose={() => changeDocumentSelectionModalDisplay(false)} onValidate={handleValidateSelection} />
  )
}
