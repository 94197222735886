import { gql } from '@apollo/client'
import { APP_NOTIFICATION_STATUSES_COMMON_FIELDS } from '../fragments'

export const APP_NOTIFICATION_STATUSES_GET_MANY = gql`
${APP_NOTIFICATION_STATUSES_COMMON_FIELDS}
query GetAllNotificationStatuses($orderBy: [StatusNotifOrderByWithRelationInput!], $where: StatusNotifWhereInput) {
    statusNotifs(orderBy: $orderBy, where: $where) {
      ...NotificationStatusCommonFields
    }
}`
