import { useLazyQuery } from '@apollo/client'
import { GetEventsByPlanIdQuery, Event, Plan } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENTS_GET_BY_PLAN_ID } from '@app/graphql/requests'
import { FORMAT_DATE_EU, ROUTE_SCHEDULING_EVENT_DETAIL } from '@app/utils/constants'
import { IColumn, TCellRenderer, TDataRowTreeList } from '@holis/react-ui'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import usePlanStore from '@app/stores/plan'
import { RadBadge, RadButton } from '@holis/react-ui/rad'
import BaseEventList from '@app/components/Common/Block/Event/EventBlock/EventList'
import { generatePathWithBaseUrl, tailwindColorToBgFgStyle } from '@app/utils/functions'
import { generatePath } from 'react-router-dom'
import { LuExternalLink } from 'react-icons/lu'

type TEventList = Readonly<{
  plan: Partial<Plan>
}>

export default function EventList({ plan }: TEventList) {
  const { t } = useTranslation()
  const { simulatingEvents, events, setEvents } = usePlanStore()
  const [getPlanEventsApi, { data, error, loading, called, refetch }] = useLazyQuery<GetEventsByPlanIdQuery>(WORKORDER_EVENTS_GET_BY_PLAN_ID, {
    variables: {
      planId: plan.id,
    },
    fetchPolicy: 'no-cache',
  })

  const openEventDetail = (event: Partial<Event>) => {
    window.open(generatePathWithBaseUrl(generatePath(ROUTE_SCHEDULING_EVENT_DETAIL, { number: `${event.event}` })))
  }

  const cellRenderers: Record<string, TCellRenderer<TDataRowTreeList>> = {
    action: (_val: string, rowData: unknown) =>
      <RadButton size="icon" className="h-7 w-7 text-xs" variant="outline" onClick={() => openEventDetail(rowData as Partial<Event>)}><LuExternalLink /></RadButton>,
    date: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
    status(val: string, rowData: unknown) {
      if (!val) {
        return ''
      }

      const viewDatas = rowData as Event

      return (
        <RadBadge variant="outline" className="py-1 font-normal items-center justify-center" style={tailwindColorToBgFgStyle(viewDatas.statusWorkOrder?.displayColor)}>
          {viewDatas.statusWorkOrder?.description}
        </RadBadge>
      )
    },
    stage: (val: string) => val ? t(`label.eventStages.${val.toLowerCase()}`) : '',
  }

  const columns: IColumn[] = [
    {
      field: 'action',
      title: t('label.action'),
      cellRenderer: 'action',
      type: 'string',
      filter: false,
      class: 'w-full absolute -left-[4px]',
      width: 60,
    },
    {
      field: 'event',
      title: t('label.eventNum'),
      type: 'string',
      filter: false,
      width: 100,
    },
    {
      field: 'type.type',
      title: t('label.type'),
      type: 'string',
      filter: false,
      width: 50,
    },
    {
      field: 'description',
      title: t('label.description'),
      type: 'string',
      filter: false,
    },
    {
      field: 'plannedDate',
      title: t('label.plannedDate'),
      type: 'string',
      filter: false,
      width: 100,
      cellRenderer: 'date',
    },
    {
      field: 'inspectionDate',
      title: t('label.inspectionDate'),
      type: 'string',
      filter: false,
      width: 100,
      cellRenderer: 'date',
    },
    {
      field: 'status',
      title: t('label.status'),
      type: 'string',
      filter: false,
      width: 120,
      cellRenderer: 'status',
    },
  ]

  const getEvents = () => {
    (called ? refetch : getPlanEventsApi)()
  }

  useEffect(() => {
    setEvents(
      [
        ...(data?.events ?? []),
        ...(simulatingEvents ?? []),
      ] as Partial<Event>[],
    )
  }, [simulatingEvents, data])

  useEffect(() => {
    getEvents()
  }, [plan])

  return (
    <BaseEventList events={events} simulatingEvents={simulatingEvents} columns={columns} cellRenderers={cellRenderers} error={!!error} isLoading={loading} />
  )
}
