import { useMutation, useQuery } from '@apollo/client'
import LabelValueRow from '@app/components/Common/Form/LabelValueRow'
import BorderedTable from '@app/components/Common/TreeList/BorderedTable'
import { GetItemsByPlanIdQuery, Item, ItemTask, Plan, RefEventTask, UpdateItemByIdMutation } from '@app/graphql/__types__/graphql'
import { SCHEDULING_ITEMS_GET_BY_PLAN_ID, SCHEDULING_ITEMS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import useItemStore from '@app/stores/item'
import { useLayoutStore } from '@app/stores/layout'
import usePlanStore from '@app/stores/plan'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { ESCHEDULING_PLAN_STATUS } from '@app/utils/enums'
import { setObjValueByPath } from '@app/utils/functions'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { IColumn, TCellRenderer, TDataRowTreeList } from '@holis/react-ui'
import { RadBadge, RadButton, RadCheckbox } from '@holis/react-ui/rad'
import _ from 'lodash'
import React, { KeyboardEvent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { LuPictureInPicture2, LuPlusSquare } from 'react-icons/lu'

type TItemList = Readonly<{
  plan: Partial<Plan>
}>

export default function ItemList({ plan }: TItemList) {
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const { startLoading, stopLoading } = useLayoutStore()
  const { setActiveItem, activeItem } = useItemStore()
  const { setItems, items } = usePlanStore()
  const initialItemsValues = useRef<Partial<Item>[]>([])
  const { data, error, loading, refetch } = useQuery<GetItemsByPlanIdQuery>(SCHEDULING_ITEMS_GET_BY_PLAN_ID, {
    variables: {
      planId: plan.id,
    },
    fetchPolicy: 'no-cache',
  })

  const [updateItemByIdApi] = useMutation<UpdateItemByIdMutation>(SCHEDULING_ITEMS_UPDATE_BY_ID)

  const openItemDetail = (item: Partial<Item>) => {
    setActiveItem(item)
  }

  const handleTextFieldBlur = (e: React.FocusEvent<HTMLInputElement>, item: Partial<Item>) => {
    const { type, value, required, name } = e.target
    let initialValue = e.target.defaultValue
    const initialItemValues = initialItemsValues.current.find((i: Partial<Item>) => i.id === item.id) ?? {}
    switch (name) {
      case 'itemNumber':
        initialValue = initialItemValues.itemNumber ?? ''
        break
      case 'description':
        initialValue = initialItemValues.description ?? ''
        break
      case 'callHorizon':
        initialValue = initialItemValues.callHorizon ? String(initialItemValues.callHorizon) : ''
        break
      case 'period':
        initialValue = initialItemValues.period ? String(initialItemValues.period) : ''
        break
      default:
        break
    }

    const newValue = type === 'number' ? (value !== '' ? Number(value) : null) : value
    if (String(newValue) === initialValue || (initialValue === '' && newValue === null)) {
      return
    }

    e.target.style.border = ''
    if (required && newValue === '') {
      AppNotifications.error(t('message.error.form.required'))
      e.target.style.border = '1px solid red'
      e.target.focus()
      return
    }

    startLoading()
    updateItemByIdApi({
      variables: {
        id: item.id!,
        data: {
          [name]: {
            set: newValue,
          },
        },
      },
    })
      .then(() => {
        AppNotifications.success(t('message.success.itemUpdated'))
        refetch()
      })
      .catch((error: Error) => {
        e.target.style.border = '1px solid red'
        let errorMessage = t('message.error.default.title')
        if (error.message.includes('Unique constraint failed on the fields: (`planId`,`itemNumber`)')) {
          errorMessage = t('message.error.unique.scheduling.item.itemNumber')
        }

        AppNotifications.error(errorMessage)
      })
      .finally(() => {
        stopLoading()
        initialItemsValues.current = _.cloneDeep(items ?? [])
      })
  }

  const handleSchedPriorityChange = (checked: boolean, item: Partial<Item>) => {
    startLoading()
    updateItemByIdApi({
      variables: {
        id: item.id!,
        data: {
          schedPriority: {
            set: checked,
          },
        },
      },
    })
      .then(() => {
        AppNotifications.success(t('message.success.itemUpdated'))
        refetch()
      })
      .catch(() => {
        AppNotifications.error(t('message.error.default.title'))
      })
      .finally(() => {
        stopLoading()
      })
  }

  const renderTaskItem = (item: RefEventTask) => <RadBadge key={item.id} className="mr-1 bg-gray-100 font-normal" variant="outline" color="primary">{item.task}</RadBadge>

  const handleCellInputValueChange = (item: Partial<Item>, field: string, value: unknown, updated?: boolean) => {
    if (!updated) {
      return
    }

    const _items = items ? [...items] : []

    for (const i of _items) {
      if (i.id === item.id) {
        setObjValueByPath(i, field, value)
        break
      }
    }

    setItems(_items)
  }

  const handleTextFieldKeyDown = (e: KeyboardEvent<HTMLPictureElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
    }
  }

  const textInputClass = 'text-xs h-7'
  const renderTextInput = (fieldName: string, val: unknown, rowData: Partial<Item>) => (
    <LabelValueRow
      value={val}
      handleFieldChange={(f, v, u) => handleCellInputValueChange(rowData, f, v, u)}
      label={false}
      className="w-full pr-1.5"
      inputProps={{
        name: fieldName,
        className: textInputClass,
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => handleTextFieldBlur(e, rowData),
        onKeyDown: handleTextFieldKeyDown,
        disabled: config.getFieldIsDisabled(fieldName, 'item', plan.status),
      }}
    />
  )
  const cellRenderers: Record<string, TCellRenderer<TDataRowTreeList>> = {
    description: (val: string, rowData: Partial<Item>) => renderTextInput('description', val, rowData),
    itemNumber: (val: string, rowData: Partial<Item>) => renderTextInput('itemNumber', val, rowData),
    period: (val: string, rowData: Partial<Item>) => renderTextInput('period', val, rowData),
    callHorizon: (val: string, rowData: Partial<Item>) => renderTextInput('callHorizon', val, rowData),
    schedPriority: (val: string, rowData: Partial<Item>) => <RadCheckbox checked={!!val} disabled={config.getFieldIsDisabled('schedPriority', 'item', plan.status)} onCheckedChange={(checked: boolean) => handleSchedPriorityChange(checked, rowData as Partial<Item>)} />,
    action: (val: string, rowData: Partial<Item>) => <RadButton size="icon" className="h-7 w-7 text-xs" variant="outline" onClick={() => openItemDetail(rowData as Partial<Item>)}><LuPictureInPicture2 /></RadButton>,
    tasks(_: unknown, rowData: Partial<Item>) {
      const tasks: RefEventTask[] = (rowData as Partial<Item>).itemTasks?.map((iTask: Partial<ItemTask>) => iTask.task!) ?? []
      return <div className="flex">{tasks.map(renderTaskItem)}</div>
    },
  }

  const columns: IColumn[] = [
    {
      field: 'action',
      title: t('label.action'),
      cellRenderer: 'action',
      type: 'string',
      filter: false,
      width: 60,
    },
    {
      field: 'itemNumber',
      title: t('label.code'),
      type: 'string',
      cellRenderer: 'itemNumber',
      filter: false,
      width: 100,
    },
    {
      field: 'type.type',
      title: t('label.type'),
      type: 'string',
      filter: false,
      width: 100,
    },
    {
      field: 'description',
      title: t('label.description'),
      type: 'string',
      filter: false,
      cellRenderer: 'description',
      width: 120,
    },
    {
      field: 'period',
      title: t('label.period'),
      type: 'string',
      cellRenderer: 'period',
      filter: false,
      width: 60,
    },
    {
      field: 'schedPriority',
      title: t('label.priority'),
      type: 'boolean',
      width: 60,
      cellRenderer: 'schedPriority',
      filter: false,
    },
    {
      field: 'callHorizon',
      title: t('label.callHorizon'),
      cellRenderer: 'callHorizon',
      type: 'string',
      filter: false,
      width: 65,
    },
    {
      field: 'tasks',
      title: t('label.tasks'),
      type: 'string',
      width: 300,
      cellRenderer: 'tasks',
      filter: false,
    },
  ]

  const renderTitle = (data: TDataRowTreeList[]) => (
    <span className="">
      {t('label.items')}
      {!loading ? ` (${(data ?? []).length})` : ''}
    </span>
  )

  const handleAddItemClick = () => {
    setActiveItem({
      id: OBJ_NEW_ID,
      planId: plan.id,
      plan: plan as Plan,
      plannerGroupId: plan.plannerGroupId,
      plannerGroup: plan.plannerGroup,
      mainWorkCenterId: plan.mainWorkCenterId,
      mainWorkCenter: plan.mainWorkCenter,
    })
  }

  useEffect(() => {
    if (!activeItem) {
      refetch()
    }
  }, [activeItem])

  useEffect(() => {
    setItems(data?.items as Partial<Item>[])
  }, [data])

  useEffect(() => {
    if (items && !initialItemsValues.current.length) {
      initialItemsValues.current = _.cloneDeep(items)
    }
  }, [items])

  const filterItems = (data: TDataRowTreeList[], searchText: string) => {
    const token = searchText.trim().toLowerCase()
    return data.filter((item: TDataRowTreeList) => {
      const i = item as Partial<Item>
      return i.itemNumber?.toLowerCase().includes(token)
        || i.description?.toLowerCase().includes(token)
        || i.type?.type?.toLowerCase().includes(token)
    })
  }

  return (
    <div className="flex flex-col w-full px-4">
      <BorderedTable
        hasSearchBar
        searchFilter={filterItems}
        title={renderTitle}
        className="flex-grow overflow-hidden w-full min-h-[150px]"
        listProps={{
          data: items as unknown as TDataRowTreeList[],
          columns,
          cellRenderers,
          isLoading: loading,
          error: !!error,
        }}
        leftSearchBarComponent={
          plan.status === ESCHEDULING_PLAN_STATUS.WSCHED
            ? (
                <RadButton variant="outline" onClick={handleAddItemClick}>
                  <LuPlusSquare className="mr-2" />
                  {' '}
                  {t('label.item')}
                </RadButton>
              )
            : undefined
        }
      />
    </div>
  )
}
