import { gql } from '@apollo/client/core'
import { FLOCS_AUTOCOMPLETE_COMMON_FIELDS, WORKORDER_EVENT_FLOCS_COMMON_FIELDS } from '../fragments'

export const WORKORDER_EVENT_FLOCS_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_FLOCS_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
query GetEventFlocsByEvtId($evtId: Int!) {
  eventFlocs (where: {wrkoId: {equals: $evtId}}, orderBy:  [{sort: {sort: asc}}]) {
    ...EventFlocCommonFields
    functionalLocation {
      ...FlocAutocompleteCommonFields
      idwgFlocs {
        idwgId
      }
    }
  }
}`

export const WORKORDER_EVENT_FLOCS_CREATE = gql`
${WORKORDER_EVENT_FLOCS_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
mutation CreateEventFloc($data: EventFlocUncheckedCreateInput!) {
  createOneEventFloc(data: $data) {
    ...EventFlocCommonFields
    functionalLocation {
      ...FlocAutocompleteCommonFields
    }
  }
}`

export const WORKORDER_EVENT_FLOCS_CREATE_MANY = gql`
mutation CreateEventFlocs($data: [EventFlocCreateManyInput!]!) {
  createManyEventFloc(data: $data) {
    count
  }
}`

export const WORKORDER_EVENT_FLOCS_DELETE_MANY = gql`
mutation DeleteEventFlocs($ids: [Int!]) {
  deleteManyEventFloc (where: {id: {in: $ids}}) {
    count
  }
}`

export const WORKORDER_EVENT_FLOCS_DELETE_ONE_BY_ID = gql`
mutation DeleteOneEventFlocById($id: Int!) {
  deleteOneEventFloc(where: {id: $id}) {
    id
  }
}
`

export const WORKORDER_EVENT_FLOCS_UPDATE_BY_ID = gql`
${WORKORDER_EVENT_FLOCS_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
mutation UpdateOneEventFlocById($id: Int!, $data: EventFlocUncheckedUpdateInput!) {
  updateOneEventFloc(where: {id: $id}, data: $data) {
    ...EventFlocCommonFields
    functionalLocation {
      ...FlocAutocompleteCommonFields
    }
  }
}
`

export const WORKORDER_EVENT_FLOCS_GET_COUNTER = gql`
query GetWorkorderEventFlocsCounter {
  aggregateEventFloc {
    _count {
      id
    }
  }
}
`
