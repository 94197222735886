import { RightPanelPage } from '../..'
import { useEventStore } from '@app/stores/event'
import { Attachment, CreateAttachmentMutation, Doc, EventDocument, GetDocWithContentByNameQuery } from '@app/graphql/__types__/graphql'
import { createBlobFromUrl } from '@app/utils/functions'
import { OptimusClientConfig } from '@app/utils/clientConfig'
import { useLazyQuery, useMutation } from '@apollo/client'
import { FILE_ATTACHMENTS_CREATE, FILE_DOCS_GET_WITH_CONTENT_BY_NAME } from '@app/graphql/requests'
import { useLayoutStore } from '@app/stores/layout'
import AppNotifications from '@app/services/notification'
import { t } from 'i18next'
import { useDataStore } from '@app/stores/data'
import DocumentListWithViewer from '@app/components/Common/Block/Document/DocumentBlock/DocumentListWithViewer'
import { EImportService } from '@app/utils/enums'
import { useHolisAuth } from '@holis/auth-client-react'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { useEffect } from 'react'

export default function DocumentationPage({ visible, readonly }: RightPanelPage) {
  const { startLoading, stopLoading } = useLayoutStore()
  const [getDocWithContentApi] = useLazyQuery<GetDocWithContentByNameQuery>(FILE_DOCS_GET_WITH_CONTENT_BY_NAME)
  const [createAttachmentApi] = useMutation<CreateAttachmentMutation>(FILE_ATTACHMENTS_CREATE)
  const { fetchEventAttachments, activeEvent, eventDocuments, changeDocumentSelectionModalDisplay, fetchEventDocuments } = useEventStore()
  const { uploadDownloadService } = useDataStore()
  const { getAccessToken } = useHolisAuth()
  const config = useOptimusConfig()

  const uploadDocToAttachment = (file: File, attachment: Attachment) => uploadDownloadService!.uploadFile(file, { id: attachment.id }, EImportService.UPLOAD_ATTACHMENT).catch((err: Error) => {
    console.log(err)
    AppNotifications.error(t('message.error.importService.attachmentUploadFailed'))
  }).finally(() => {
    stopLoading()
  })

  const addToAttachments = async (evtDoc?: Partial<EventDocument>) => {
    if (!evtDoc) {
      return
    }

    startLoading()
    let doc: Doc | null = null
    let file: File | null = null
    if (evtDoc.document!.docName) {
      const queryResult = await getDocWithContentApi({
        variables: {
          docName: evtDoc.document!.docName,
        },
      })
      doc = queryResult.data?.doc as Doc
      const dataBlob = await createBlobFromUrl(`${OptimusClientConfig.current.fileBaseUrl}/${evtDoc.document!.docName}`, { getAccessToken, onFail() {
        AppNotifications.error(t('message.error.default.title'))
        return null
      } }).catch(() => null)
      if (dataBlob) {
        file = new File([dataBlob], doc.docName)
      }
    }

    createAttachmentApi({
      variables: {
        data: {
          wrkoId: activeEvent?.id,
          attachmentName: evtDoc.document?.docName?.substring(0, Math.min(50, evtDoc.document?.docName?.length ?? 0)),
          description: evtDoc.document?.description?.substring(0, Math.min(50, evtDoc.document?.description.length ?? 0)),
          contentType: doc?.contentType,
          extension: doc?.extension,
        },
      },
    }).then(async (queryResult) => {
      const attachment = queryResult.data?.createOneAttachment as Attachment
      if (file && attachment) {
        await uploadDocToAttachment(file, attachment)
      }

      fetchEventAttachments?.()
      AppNotifications.success(t('message.success.addAttachment'))
    }).finally(() => {
      stopLoading()
    })
  }

  const canManageDocuments = !readonly && config.getActionIsEnabled('event', 'docSelect', activeEvent?.status)

  useEffect(() => {
    fetchEventDocuments?.()
  }, [fetchEventDocuments])

  return (
    <div className={(visible ? '' : 'hidden') + ' h-full'}>
      <DocumentListWithViewer
        documents={eventDocuments?.map(evtDoc => evtDoc.document!) ?? []}
        onAddToAttachmentsClick={doc => addToAttachments(eventDocuments?.find(evtDoc => evtDoc.document!.id === doc.id))}
        onManageDocumentsClick={canManageDocuments ? () => changeDocumentSelectionModalDisplay(true) : undefined}
      />
    </div>
  )
}
