import ActionButtons, { TActionButtons } from '@app/components/Common/Form/ActionButtons'
import { ReactNode } from 'react'

type TDetailsPageTitleBar = Readonly<{
  title: string
  subtitle?: string
  children?: ReactNode | string
  actionButtons?: TActionButtons
}>

export default function DetailsPageTitleBar({ title, subtitle, children, actionButtons }: TDetailsPageTitleBar) {
  return (
    <div className="flex flex-initial border-y px-4 items-center">
      <div className="flex flex-col my-1">
        <div className="flex items-center">
          <div className="font-inter text-sm uppercase font-bold mr-4">{title}</div>
          { actionButtons && <ActionButtons height={subtitle ? 5 : undefined} menuAlign="start" {...actionButtons} />}
        </div>
        { subtitle && <div className="text-xs text-gray-900">{subtitle}</div> }
      </div>

      <div className="ml-4 flex-1">{children}</div>
    </div>
  )
}
