import { EventView } from '@app/graphql/__types__/graphql'
import { ILinkChild, ILinkGroup } from '@holis/react-ui'
import { RadSideBar, RadSideBarMainContent, RadSideBarGroup, RadSideBarGroupContent, RadSideBarLink } from '@holis/react-ui/rad'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { NO_YEAR } from '..'
import { useTranslation } from 'react-i18next'
import { LuClipboardList, LuFolder } from 'react-icons/lu'
import _ from 'lodash'

type TYearClassFilterSideBar = Readonly<{
  items: Array<Partial<EventView>>
  filterItemsByYearClasses: (yearClasses?: Record<number, Array<string | null>>) => void
}>

interface TMenuClass extends ILinkChild {
  class: string | null
}

interface TMenuYear extends ILinkGroup {
  id?: string
  year: number
  children: TMenuClass[]
}

export default function YearClassFilterSideBar({ items, filterItemsByYearClasses }: TYearClassFilterSideBar) {
  const [yearClasses, setYearClasses] = useState<Record<number, Array<string | null>>>()
  const [yearClassItemCounters, setYearClassItemCounters] = useState<Record<string, number>>()
  const [selectedYearClasses, setSelectedYearClasses] = useState<Record<number, Array<string | null>>>()
  const [menuItems, setMenuItems] = useState<TMenuYear[]>([])
  const { t } = useTranslation()

  const toggleYearChanged = (year: number) => {
    const newSelectedYearClasses = selectedYearClasses ? _.cloneDeep(selectedYearClasses) : {}
    if (newSelectedYearClasses[year]) {
      delete newSelectedYearClasses[year]
    } else {
      newSelectedYearClasses[year] = yearClasses?.[year] ?? []
    }

    setSelectedYearClasses(newSelectedYearClasses)
  }

  const toggleClassChanged = (year: number, className: string | null) => {
    const newSelectedYearClasses = selectedYearClasses ? _.cloneDeep(selectedYearClasses) : {}
    const yearClasses = newSelectedYearClasses[year] ?? []
    if (yearClasses.includes(className)) {
      newSelectedYearClasses[year] = yearClasses.filter(classNameItem => classNameItem !== className)
    } else {
      newSelectedYearClasses[year] = [
        ...yearClasses,
        className,
      ]
    }

    setSelectedYearClasses(newSelectedYearClasses)
  }

  const generateYearClasses = () => {
    const result: Record<number, Array<string | null>> = {}
    const counters: Record<string, number> = {}
    items.forEach((item: Partial<EventView>) => {
      const evtYear = item.inspectionDate ? moment(item.inspectionDate!).toDate().getFullYear() : NO_YEAR
      const evtClass = item.schedulingTag_techClass_class_class ?? null
      const evtClassDesc = item.schedulingTag_techClass_class_description ?? ''
      const evtClassTitle = evtClass ? `${evtClass} - ${evtClassDesc}` : null
      const evtYearClass = `${evtYear} -${evtClassTitle !== null ? ` ${evtClassTitle}` : ''}`
      if (!result[evtYear]) {
        result[evtYear] = []
      }

      if (!counters[evtYearClass]) {
        counters[evtYearClass] = 0
      }

      if (!counters[evtYear]) {
        counters[evtYear] = 0
      }

      counters[evtYearClass]++
      counters[evtYear]++

      if (!result[evtYear].includes(evtClassTitle)) {
        result[evtYear].push(evtClassTitle)
        result[evtYear].sort()
      }
    })
    setYearClasses(result)
    setYearClassItemCounters(counters)
  }

  const generateMenuItems = () => {
    const years = yearClasses ? Object.keys(yearClasses).map(year => parseInt(year, 10)) : []
    years.sort().reverse()
    setMenuItems(years.map((year: number) => {
      const classes = yearClasses![year]
      return {
        id: year.toString(),
        year,
        active: !selectedYearClasses || !Object.keys(selectedYearClasses).length || !!selectedYearClasses?.[year],
        groupName: `${year === NO_YEAR ? t('label.noInspectionDate') : year} (${yearClassItemCounters?.[year] ?? 0})`,
        children: classes.map((className: string | null) => {
          const yearClassCounterTitle = `${year} -${className ? ` ${className}` : ''}`
          return {
            title: `${className ?? t('label.noClass')} (${yearClassItemCounters?.[yearClassCounterTitle] ?? 0})`,
            class: className,
            active: !selectedYearClasses || !Object.keys(selectedYearClasses).length || selectedYearClasses[year]?.includes(className),
          }
        }),
      }
    }))
  }

  useEffect(() => {
    filterItemsByYearClasses(selectedYearClasses)
  }, [selectedYearClasses])

  useEffect(() => {
    generateYearClasses()
  }, [items])

  useEffect(() => {
    if (!(selectedYearClasses && Object.keys(selectedYearClasses).length)) {
      const currentYear = (new Date()).getFullYear()
      if (yearClasses?.[currentYear]) {
        setSelectedYearClasses({
          [currentYear]: yearClasses?.[currentYear],
        })
      } else {
        setSelectedYearClasses(yearClasses)
      }
    }
  }, [yearClasses])

  useEffect(() => {
    generateMenuItems()
  }, [yearClasses, yearClassItemCounters, selectedYearClasses])

  return (
    <RadSideBar hidden={!menuItems?.length} className="bg-white h-full min-w-[100px] [&_nav]:max-w-none">
      <RadSideBarMainContent>
        <div className="flex flex-col gap-1 w-full px-3">
          {menuItems.map(menuItem => (
            <RadSideBarGroup
              key={`sideBarGroup-${menuItem.groupName}`}
              collapsed={false}
              id={`sideBarGroup-${menuItem.groupName}`}
              className="hover:cursor-pointer"
            >
              <div className={`flex items-center justify-betwwen gap-2 pl-0.5 truncate text-blue-500 hover:bg-blue-50 ${menuItem.active ? 'font-semibold' : ''}`} onClick={() => toggleYearChanged(menuItem.year)}>
                <LuClipboardList className="size-5 p-0.5" />

                {menuItem.groupName}

              </div>

              <RadSideBarGroupContent className="border-none" dropdownLabel={menuItem.groupName}>
                {menuItem.children.map(child => (
                  <RadSideBarLink
                    key={`sideBarGroupItem-${child.title}`}
                    active={child.active}
                    className={`h-7 hover:bg-blue-100 hover:cursor-pointer bg-transparent ${child.active ? 'font-semibold' : ''}`}
                    onClick={() => toggleClassChanged(menuItem.year, child.class)}
                  >
                    <div className="gap-2">
                      <LuFolder className="size-5" />
                      {' '}
                      {child.title}
                    </div>
                  </RadSideBarLink>
                ))}
              </RadSideBarGroupContent>
            </RadSideBarGroup>
          ))}
        </div>
      </RadSideBarMainContent>
    </RadSideBar>
  )
}
