import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { Cml, DeleteCmlByIdMutation } from '@app/graphql/__types__/graphql'
import { CMLS_DELETE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
type TDeleteCmlModal = IConfirmModal & Readonly<{
  cml: Partial<Cml>
  onCmlDeleted?: (cml?: Partial<Cml>) => void
  changeDeleteCmlModalDisplay: (isOpen: boolean) => void
}>

export default function DeleteCmlModal({ cml, onCmlDeleted, changeDeleteCmlModalDisplay, ...restProps }: TDeleteCmlModal) {
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteCmlApi, { data, loading, error }] = useMutation<DeleteCmlByIdMutation>(CMLS_DELETE_BY_ID)
  const { t } = useTranslation()
  useEffect(() => {

  }, [data, loading, error])
  const onConfirm = () => {
    startLoading()
    deleteCmlApi({
      variables: { id: cml!.id },
    }).then(() => {
      onCmlDeleted?.()
      AppNotifications.success(t('message.success.deleteCml'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteCml'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteCml')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeleteCmlModalDisplay(false)}
      {...restProps}
    />
  )
}
