import { RadSelect, RadSelectContent, RadSelectItem, RadSelectTrigger } from '@holis/react-ui/rad'
import { t } from 'i18next'
import { AppListItem } from '../list-item'
import { useEffect, useState } from 'react'

type TAppSelect<ItemType = unknown> = Readonly<{
  items: AppListItem<ItemType>[]
  value?: string | number
  disabled?: boolean
  required?: boolean
  placeholder?: string
  noDataLabel?: string
  loading?: boolean
  loadingLabel?: string
  emptyValue?: string | number
  variant?: 'holis'
  onSelectedItemChanged?: (item?: AppListItem<ItemType>) => void
  onChange?: (value: string | number | undefined) => void
}>

const holisVariantClassName = 'border-0 bg-transparent shadow-none transition-all hover:bg-muted'

export default function AppSelect<ItemType = unknown>({
  items,
  value,
  disabled,
  required,
  placeholder,
  noDataLabel,
  loading,
  loadingLabel,
  onSelectedItemChanged,
  onChange,
  emptyValue = -1,
  variant,
}: TAppSelect<ItemType>) {
  const [selected, setSelected] = useState<AppListItem>()

  useEffect(() => {
    const selected = items.find(i => i.value === value)
    setSelected(selected)
  }, [value, items])

  const handleValueChange = (v: string) => {
    if (v == value) {
      return
    }

    const selectedItem = items.find(i => i.value == v)
    const newSelected = !required && v === emptyValue ? undefined : selectedItem
    setSelected(newSelected)
    onSelectedItemChanged?.(newSelected)
    onChange?.((newSelected as { value?: string | number | undefined })?.value)
  }

  return (
    <div className="p-[1px]">
      <RadSelect
        value={selected?.value as string ?? emptyValue}
        onValueChange={handleValueChange}
      >
        <RadSelectTrigger
          disabled={disabled || loading}
          className={variant === 'holis' ? holisVariantClassName : undefined}
        >
          <div className="overflow-hidden text-ellipsis">
            { loading
              ? <span className="text-muted-foreground">{loadingLabel ?? t('label.loading')}</span>
              : selected
                ? <span title={selected?.label}>{selected?.render ?? selected?.label}</span>
                : (
                    <span className="text-muted-foreground">
                      {placeholder ?? t('label.select')}
                    </span>
                  )}
          </div>
        </RadSelectTrigger>
        <RadSelectContent>
          {!required && <RadSelectItem value={emptyValue as string} className="text-muted-foreground italic">{t('label.empty')}</RadSelectItem>}
          {items.map(item => (
            <RadSelectItem key={item.value as string} value={item.value as string} disabled={item.disabled}>
              {item?.render ?? item?.label}
            </RadSelectItem>
          ))}
          {items.length === 0 && <div className="text-muted-foreground p-2">{noDataLabel ?? t('label.noData')}</div>}
        </RadSelectContent>
      </RadSelect>
    </div>
  )
}
