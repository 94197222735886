import { useQuery } from '@apollo/client'
import SelectionConfirmModal, { TSelectionConfirmModalWithoutListProps } from '@app/components/Modal/Confirm/Selection'
import { RefEventTask, GetAllRefEventTasksQuery } from '@app/graphql/__types__/graphql'
import { WORKORDER_REF_EVENT_TASKS_GET_MANY } from '@app/graphql/requests'
import { EWORKORDER_REF_EVENT_TASK_CATEGORY } from '@app/utils/enums'
import { TDataRowTreeList } from '@holis/react-ui'
import { useTranslation } from 'react-i18next'

type TTaskSelectionModal = TSelectionConfirmModalWithoutListProps<Partial<RefEventTask>> & Readonly<{
  category?: EWORKORDER_REF_EVENT_TASK_CATEGORY
}>

export default function TaskSelectionModal({ category, ...restProps }: TTaskSelectionModal) {
  const { t } = useTranslation()
  const { data, error, loading } = useQuery<GetAllRefEventTasksQuery>(WORKORDER_REF_EVENT_TASKS_GET_MANY, {
    variables: category
      ? {
          where: {
            category: {
              equals: category,
            },
          },
        }
      : {},
  })
  return (
    <SelectionConfirmModal
      title={t('label.task')}
      description={t('label.selectTaskDescription')}
      sortFields={['category', 'task']}
      sortOrders={['desc', 'asc']}
      listProps={{
        listProps: {
          isLoading: loading,
          error: error ? t('message.error.default.title') : undefined,
          data: (data?.refEventTasks ?? []) as TDataRowTreeList[],
          cellRenderers: {
          },
          columns: [
            {
              field: 'task',
              title: t('label.task'),
              type: 'string',
              width: 250,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 250,
            },
            {
              field: 'category',
              title: t('label.category'),
              type: 'string',
              width: 150,
            },
          ],
        },
      }}
      {...restProps}
    />
  )
}
