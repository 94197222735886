import { TLineError } from '@app/types/app'
import { EDB_TABLE_REF } from '@app/utils/enums'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type State = {
  searchInput: string
  activeFilter: string
  tableDataImportErrorsModalShown: boolean
  tableDataImportConfirmModalShown: boolean
  tableDataImportErrors?: Record<string, TLineError[]>
  onConfirmDataImportCallback?: () => void
  dataUploading?: Partial<Record<EDB_TABLE_REF, boolean>>
  dataDownloading?: Partial<Record<EDB_TABLE_REF, boolean>>
}

const initialState: State = {
  activeFilter: 'all',
  searchInput: '',
  tableDataImportErrorsModalShown: false,
  tableDataImportErrors: undefined,
  tableDataImportConfirmModalShown: false,
  onConfirmDataImportCallback: undefined,
  dataUploading: undefined,
  dataDownloading: undefined,
}

type Actions = {
  setSearchInput: (searchInput: string) => void
  setActiveFilter: (activeFilter?: string) => void
  changeTableDataImportErrorsModalDisplay: (tableDataImportErrorsModalShown: boolean, tableDataImportErrors?: Record<string, TLineError[]>) => void
  changeTableDataImportConfirmModalDisplay: (tableDataImportConfirmModalShown: boolean, onConfirmDataImportCallback?: () => void) => void
  changeDataUploading: (ref: EDB_TABLE_REF, uploading: boolean) => void
  changeDataDownloading: (ref: EDB_TABLE_REF, downloading: boolean) => void
}

export const useAdminImportExportStore = create<State & Actions>()(
  immer((set, get) => ({
    ...initialState,
    changeDataDownloading(ref: EDB_TABLE_REF, downloading: boolean) {
      const { dataDownloading } = get() ?? {}
      set({ dataDownloading: { ...dataDownloading, [ref]: downloading } })
    },
    changeDataUploading(ref: EDB_TABLE_REF, uploading: boolean) {
      const { dataUploading } = get() ?? {}
      set({ dataUploading: { ...dataUploading, [ref]: uploading } })
    },
    changeTableDataImportConfirmModalDisplay(tableDataImportConfirmModalShown, onConfirmDataImportCallback) {
      set({ tableDataImportConfirmModalShown, onConfirmDataImportCallback })
    },
    changeTableDataImportErrorsModalDisplay(tableDataImportErrorsModalShown, tableDataImportErrors) {
      set({ tableDataImportErrorsModalShown, tableDataImportErrors })
    },
    setSearchInput(searchInput) {
      set({ searchInput })
    },
    setActiveFilter(activeFilter) {
      set({ activeFilter: activeFilter ?? initialState.activeFilter })
    },
  })),
)
