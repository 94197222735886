import ImgViewer from '@app/components/Common/Viewer/ImgViewer'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import { FunctionalLocation, UpdateFlocByIdMutation } from '@app/graphql/__types__/graphql'
import { OptimusClientConfig } from '@app/utils/clientConfig'
import { EAdditionalAction, EDownloadFileType, EDrawingFileContentType, EImportService } from '@app/utils/enums'
import { addTokenToUrl, createBase64FromUrl, downloadFileFromUrl, getFileNameWithoutExtension } from '@app/utils/functions'
import { useHolisAuth } from '@holis/auth-client-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../InspectionDrawing/DrawingViewer/Header'
import { TAdditionalActions } from '@app/types/app'
import { RadButton, RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuDownload, LuMenu, LuUpload, LuX } from 'react-icons/lu'
import { useLayoutStore } from '@app/stores/layout'
import { useDataStore } from '@app/stores/data'
import AppNotifications from '@app/services/notification'
import { AxiosError } from 'axios'
import React from 'react'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { useMutation } from '@apollo/client'
import { FLOCS_UPDATE_BY_ID } from '@app/graphql/requests'
import FlocCharsBlock from './FlocCharsBlock'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'

type TFlocOverviewDrawing = Readonly<{
  floc: Partial<FunctionalLocation>
  visible: boolean
}>

export default function FlocOverviewDrawing({ floc, visible }: TFlocOverviewDrawing) {
  const { t } = useTranslation()
  const prms = useUserPermissions()
  const { startLoading, stopLoading } = useLayoutStore()
  const [svgSrc, setSvgSrc] = useState<string | null>()
  const { getAccessToken } = useHolisAuth()
  const [imgUrl, setImgUrl] = useState<string | null>()
  const [uploadFile, setUploadFile] = useState<File | null>()
  const { uploadDownloadService } = useDataStore()
  const fileRef = React.createRef<HTMLInputElement>()
  const [confirmDocNameExistsModalDisplayed, changeConfirmDocNameExistsModalDisplay] = useState<boolean>(false)
  const [updateFlocByIdApi] = useMutation<UpdateFlocByIdMutation>(FLOCS_UPDATE_BY_ID)
  const [docName, setDocName] = useState<string | null>()
  const [flocCharsDisplayed, changeFlocCharsDisplay] = useState<boolean>(false)

  const updateDocNameField = (docName: string) => {
    updateFlocByIdApi({
      variables: {
        id: floc.id,
        data: {
          docName: {
            set: docName,
          },
        },
      },
    }).then(() => {
      setDocName(docName)
      setUploadFile(null)
      AppNotifications.success(t('message.success.overviewUpdated'))
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    })
  }

  const handleOverrideDoc = (confirmed: boolean) => {
    if (confirmed) {
      handleUploadFile(true)
    } else {
      const docName = getFileNameWithoutExtension(uploadFile!.name)
      updateDocNameField(docName)
    }
  }

  const handleUploadFile = (updateIfExists = false) => {
    if (uploadFile) {
      startLoading()
      uploadDownloadService!.uploadFile(uploadFile, {
        contentType: EDrawingFileContentType.PDF,
        updateIfExists: updateIfExists ? 1 : 0,
        async: 0,
      }, EImportService.UPLOAD_DRAWING).then((response) => {
        if (response.status !== 200) {
          const responseJson = JSON.parse(response.data)
          throw new Error(typeof responseJson === 'object' && uploadFile.name && responseJson.errors?.files?.[uploadFile.name] ? responseJson.errors.files![uploadFile.name] : response.data.message ?? response.statusText)
        }

        const docName = getFileNameWithoutExtension(uploadFile.name)
        updateDocNameField(docName)
      }).catch((err: Error) => {
        let errorMessage = t('message.error.uploadIdwgationIdwg')
        if (typeof err?.message === 'string') {
          errorMessage = err.message
          if (err.message.includes(`File ${getFileNameWithoutExtension(uploadFile.name)} already exists`)) {
            if (AppNotifications.timeoutId) {
              clearTimeout(AppNotifications.timeoutId)
            }

            errorMessage = t('message.error.unique.file.drawing.docName')
            changeConfirmDocNameExistsModalDisplay(true)
            return
          }
        }

        AppNotifications.error(errorMessage)
      }).finally(stopLoading)
    }
  }

  useEffect(() => {
    if (uploadFile) {
      handleUploadFile()
    }
  }, [uploadFile])

  const downloadFile = async () => {
    if (!floc || !floc.docName) {
      return
    }

    startLoading()

    const url = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${floc.docName}?type=${EDownloadFileType.DRAWING}&contentType=${EDrawingFileContentType.PDF}`, getAccessToken)

    downloadFileFromUrl(url, `${floc.docName!}.pdf`, {
      onEnd() {
        stopLoading()
      },
      onFail(error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          AppNotifications.error(t('message.error.fileNotFound'))
        } else {
          AppNotifications.error(t('message.error.default.title'))
        }
      },
    })
  }

  const onFileUploadClick = () => {
    fileRef.current?.click()
  }

  const onFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const uploadFile = e.target.files[0]
      setUploadFile(uploadFile)
    }
  }

  const toggleShowFlocChars = () => {
    changeFlocCharsDisplay(!flocCharsDisplayed)
  }

  const additionalActions: TAdditionalActions = useMemo(() => ({
    [EAdditionalAction.DOWNLOAD]: (
      <RadDropdownMenuItem disabled={!floc?.docName} className="gap-2" onClick={downloadFile}>
        <LuDownload />

        {t('label.downloadFile')}
      </RadDropdownMenuItem>
    ),
    [EAdditionalAction.UPLOAD]: (
      <RadDropdownMenuItem className="gap-2" disabled={!prms.flocs.create && !prms.flocs.update} onClick={onFileUploadClick}>
        <LuUpload />

        {t('label.uploadFile')}
      </RadDropdownMenuItem>
    ),
  }), [floc, fileRef])

  useEffect(() => {
    if (svgSrc) {
      setImgUrl(undefined)
      createBase64FromUrl(svgSrc, { getAccessToken }).then((objUrl) => {
        setImgUrl(objUrl)
      }).catch(() => {
        setImgUrl(null)
      })
    }
  }, [svgSrc, getAccessToken])

  useEffect(() => {
    if (typeof docName !== 'undefined') {
      setSvgSrc(docName ? `${OptimusClientConfig.current.fileBaseUrl}/${docName}?type=${EDownloadFileType.DRAWING}&contentType=${EDrawingFileContentType.SVG}` : null)
    }
  }, [docName])

  useEffect(() => {
    setDocName(floc.docName ?? null)
  }, [floc.docName])
  return (
    <>
      <input
        ref={fileRef}
        accept="application/pdf,.pdf"
        className="hidden"
        type="file"
        onChange={onFileUploadChange}
      />
      <div className={`h-full w-full flex flex-col overflow-hidden relative ${visible ? '' : 'hidden'}`}>
        <div className="flex items-center justify-between">
          <Header
            title={docName}
            description={floc.floc}
            actions={Object.keys(additionalActions).map(key => (
              <React.Fragment key={key}>
                {additionalActions[key]}
              </React.Fragment>
            ))}
          />
          <RadButton size="sm" variant="secondary" onClick={toggleShowFlocChars}>
            {flocCharsDisplayed ? <LuX /> : <LuMenu />}
          </RadButton>
        </div>
        <SpinnerLoaderComponent error={!floc.docName && !docName ? t('message.error.fileNotFound') : imgUrl === null} isLoading={typeof imgUrl === 'undefined' && !!docName} className={visible ? 'flex-grow w-full overflow-auto' : 'hidden'}>
          {!!imgUrl && (
            <ImgViewer
              key={`overview-pdf-${imgUrl}`}
              isExternalSrc
              className="p-4"
              contentClassName="w-full h-full"
              src={imgUrl}
            />
          )}
        </SpinnerLoaderComponent>
        {flocCharsDisplayed && <div className="absolute right-0 top-8 bg-white h-full max-h-[calc(100%-2rem)] w-1/2 min-w-[600px] border overflow-auto"><FlocCharsBlock floc={floc} /></div>}
      </div>
      <WarningConfirmModal closeOnConfirm closeOnCancel cancelButtonLabel={t('label.no')} confirmButtonLabel={t('label.yes')} className="min-w-[auto] min-h-[150px] w-[600px]" open={confirmDocNameExistsModalDisplayed} description={t('message.questions.fileNameExistsWantToOverrideDoc')} onConfirm={() => handleOverrideDoc(true)} onClose={() => changeConfirmDocNameExistsModalDisplay(false)} onCancel={() => handleOverrideDoc(false)} />
    </>
  )
}
