import { CreateEventInspectionPointsMutation, DeleteEventInspectionPointsMutation, Event, EventInspectionPoint, GetInspectionPointsByFlocIdsQuery, InspectionPoint } from '@app/graphql/__types__/graphql'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { searchArray } from '@app/utils/functions'
import { useEventStore } from '@app/stores/event'
import { useLayoutStore } from '@app/stores/layout'
import { WORKORDER_EVENT_INSPECTION_POINTS_CREATE_MANY, WORKORDER_EVENT_INSPECTION_POINTS_DELETE_MANY } from '@app/graphql/requests/eventInspectionPoints'
import { useLazyQuery, useMutation } from '@apollo/client'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import SearchBar from '@app/components/Common/SearchBar'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuPlusSquare, LuClipboardList, LuList } from 'react-icons/lu'
import InspectionPointSelectionModal from '@app/components/Common/Block/InspectionPoint/InspectionPointBlock/InspectionPointSelectionModal'
import DeleteEventInspectionPointModal from './DeleteEventInspectionPointModal'
import { TDbId } from '@app/types/app'
import AppNotifications from '@app/services/notification'
import EventInspectionPointListModal from '@app/components/Common/Block/InspectionPoint/InspectionPointBlock/EventInspectionPointListModal'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { INSPECTION_POINTS_GET_BY_FLOC_IDS } from '@app/graphql/requests'
import _ from 'lodash'
import InspectionPointItem from '@app/components/Common/Block/InspectionPoint/InspectionPointItem'

type TInspectionPointBlock = Readonly<{
  event: Partial<Event>
  deleteText?: string
  readonly?: boolean
}>

export default function InspectionPointBlock({ event, readonly }: TInspectionPointBlock) {
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const { startLoading, stopLoading } = useLayoutStore()
  const { eventInspectionPoints, eventFlocInspectionPoints, fetchEventFlocInspectionPoints, eventFlocIds, activeEvent, fetchEventInspectionPoints, inspectionPointToDelete, setEventFlocInspectionPoints, setFetchEventFlocInspectionPoints } = useEventStore()
  const [inspectionPoints, setInspectionPoints] = useState<Partial<InspectionPoint>[]>()
  const [filteredInspectionPoints, setFilteredInspectionPoints] = useState<Partial<InspectionPoint>[]>()
  const { status } = activeEvent ?? {}
  const [addEventInspectionPointsApi] = useMutation<CreateEventInspectionPointsMutation>(WORKORDER_EVENT_INSPECTION_POINTS_CREATE_MANY)
  const [deleteEventInspectionPointsApi] = useMutation<DeleteEventInspectionPointsMutation>(WORKORDER_EVENT_INSPECTION_POINTS_DELETE_MANY)
  const [inspectionPointSelectionModalDisplayed, changeInspectionPointSelectionModalDisplay] = useState<boolean>(false)
  const [inspectionPointListModalDisplayed, changeInspectionPointListModalDisplay] = useState<boolean>(false)
  const [searchInput, setSearchInput] = useState<string>('')
  const [getEventFlocInspectionPointsApi] = useLazyQuery<GetInspectionPointsByFlocIdsQuery>(INSPECTION_POINTS_GET_BY_FLOC_IDS)

  const addItems = (inspIds: TDbId[]) => addEventInspectionPointsApi({
    variables: {
      data: inspIds.map((inspId: TDbId) => ({
        wrkoId: event.id,
        inspId,
      })),
    },
  })

  const getEventFlocInspectionPoints = () => {
    if (Array.isArray(eventFlocIds) && eventFlocIds.length) {
      getEventFlocInspectionPointsApi({
        variables: {
          flocIds: eventFlocIds,
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        setEventFlocInspectionPoints((queryResult.data?.inspectionPoints ?? []) as Partial<InspectionPoint>[])
      })
    } else {
      setEventFlocInspectionPoints([])
    }
  }

  const deleteItems = (eventInspIds: TDbId[]) => deleteEventInspectionPointsApi({
    variables: {
      ids: eventInspIds,
    },
  })

  // Validate seletion modal (Add or delete points )
  const onValidateSelection = async (selectedItems: Partial<InspectionPoint>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const eventInspIds = (eventInspectionPoints?.filter((item: Partial<EventInspectionPoint>) => item.inspId && oldSelectedIds.includes(item.inspId)).map((item: Partial<EventInspectionPoint>) => item.id) ?? []) as TDbId[]
      if (eventInspIds.length) {
        promises.push(deleteItems(eventInspIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        fetchEventInspectionPoints?.()
        changeInspectionPointSelectionModalDisplay(false)
        AppNotifications.success(t('message.success.eventInspectionPointsUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    }
  }

  useEffect(() => {
    setFetchEventFlocInspectionPoints(getEventFlocInspectionPoints)
  }, [eventFlocIds])

  useEffect(() => {
    setInspectionPoints(_.sortBy(eventInspectionPoints?.map((item: Partial<EventInspectionPoint>) => item.inspectionPoint!), ['position']))
  }, [eventInspectionPoints])

  useEffect(() => {
    setFilteredInspectionPoints(
      searchArray(inspectionPoints ?? [], searchInput, undefined, ['__typename']),
    )
  }, [inspectionPoints, searchInput])

  useEffect(() => {
    fetchEventFlocInspectionPoints?.()
  }, [status, fetchEventFlocInspectionPoints])

  useEffect(() => {
    fetchEventInspectionPoints?.()
  }, [fetchEventInspectionPoints, status])

  return (
    <div>
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem
            key="manage-object"
            disabled={readonly || config.getActionIsDisabled('event', 'pointSelect', event.status)}
            onClick={() => changeInspectionPointSelectionModalDisplay(true)}
          >
            <LuPlusSquare className="mr-2" />
            {' '}
            {t('label.manageChecklist')}
          </RadDropdownMenuItem>,
          <RadDropdownMenuItem key="display-list" onClick={() => changeInspectionPointListModalDisplay(true)}>
            <LuList className="mr-2" />
            {' '}
            {t('label.displayList')}
          </RadDropdownMenuItem>,
        ]}
        actions={(
          <SearchBar
            className="w-[300px]"
            onChange={e => setSearchInput(e.target?.value)}
          />
        )}
      >
        <div className="flex items-center">
          <LuClipboardList size={20} className="mr-2" />
          {' '}
          {t('label.checklist')}
        </div>
      </FormGroupHeader>

      <div className="flex flex-col w-full gap-1 mb-2 mt-2">
        {filteredInspectionPoints?.map((item: Partial<InspectionPoint>) => {
          const eventInspectionPoint = eventInspectionPoints?.find((evtInspectionPoint: Partial<EventInspectionPoint>) => evtInspectionPoint.inspId === item.id)

          return (
            <InspectionPointItem key={item.id} hasMenuContext readonly={readonly || config.getActionIsDisabled('event', 'pointSelect', event.status)} inspectionPoint={item} eventInspectionPoint={eventInspectionPoint} />
          )
        })}
      </div>
      {inspectionPointSelectionModalDisplayed && <InspectionPointSelectionModal open hasItems sortFields={[(value: Partial<InspectionPoint>) => value.position]} sortOrders={['asc']} items={eventFlocInspectionPoints} selectedIds={eventInspectionPoints?.map((item: Partial<EventInspectionPoint>) => item.inspectionPoint!.id!) ?? []} closeOnConfirm={false} onClose={() => changeInspectionPointSelectionModalDisplay(false)} onValidate={onValidateSelection} />}
      {inspectionPointListModalDisplayed && <EventInspectionPointListModal open hasItems description={`${t('label.event').toUpperCase()} ${event.event}`} sortOrders={['asc']} items={eventInspectionPoints} onClose={() => changeInspectionPointListModalDisplay(false)} />}
      <DeleteEventInspectionPointModal open={!!inspectionPointToDelete} />
    </div>
  )
}
