import { RadCard, RadCardContent } from '@holis/react-ui/rad'
import { cn } from '@holis/react-ui/utils'
import { HTMLProps, ReactNode } from 'react'

type TItemCard = Readonly<{
  className?: string
  children?: ReactNode
  onClick?: () => void
}>

export default function ItemCard(props: TItemCard) {
  return (
    <RadCard
      className={cn('rounded-md flex items-stretch min-h-[66px] shadow-none', props.onClick ? 'cursor-pointer hover:bg-muted' : '', props.className)}
      onClick={props.onClick}
    >
      <RadCardContent className="w-full flex px-2 py-1.5 gap-2 items-center">
        {props.children}
      </RadCardContent>
    </RadCard>
  )
}

export function ItemCardContent({ children, ...restProps }: HTMLProps<HTMLDivElement>) {
  return (
    <div {...restProps} className={cn('flex-1', restProps.className)}>
      {children}
    </div>
  )
}
