import { gql } from '@apollo/client'
import { FILE_PICTURES_DETAIL_FIELDS } from '../fragments/pictures'

export const PICTURES_GET_MANY_BY = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesBy($data: PictureWhereInput!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: $data, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`

export const PICTURES_GET_LATEST_BY = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetLatestPictureBy($data: PictureWhereInput!) {
    findFirstPicture(where: $data, orderBy: {date: {sort: desc}}, skip:0, take: 1){
        ...FilePictureDetailFields
    }
}`

export const PICTURES_DELETE_BY_ID = gql`
mutation DeletePictureById($id: Int!) {
  deleteOnePicture(where: {id: $id}) {
    id
  }
}`

export const PICTURES_UPDATE_BY_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
mutation UpdatePictureById($id: Int!, $data: PictureUncheckedUpdateInput!) {
  updateOnePicture(data: $data, where: {id: $id}) {
    ...FilePictureDetailFields
  }
}`

export const PICTURES_GET_BY_EVT_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesByEvtId($evtId: Int!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: {wrkoId: {equals: $evtId}}, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`

export const PICTURES_GET_BY_FLOC_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesByFlocId($flocId: Int!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: {flocId: {equals: $flocId}}, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`

export const PICTURES_GET_COUNTER = gql`
query GetPicturesCounter {
  aggregatePicture {
    _count {
      id
    }
  }
}
`
