import { gql } from '@apollo/client'

export const DWG_IDWG_FLOCS_DELETE_MANY_BY_IDWG_ID_AND_FLOC_IDS = gql`
  mutation DeleteIdwgFlocsByIdwgIdAndFlocIds($idwgId: Int!, $flocIds: [Int!]) {
    deleteManyIdwgFloc(where: {idwgId: {equals: $idwgId}, flocId: {in: $flocIds}}) {
      count
    }
  }
`

export const DWG_IDWG_FLOCS_CREATE_MANY = gql`
  mutation CreateIdwgFlocs($data: [IdwgFlocCreateManyInput!]!) {
    createManyIdwgFloc(data: $data, skipDuplicates: true) {
      count
    }
  }
`

export const DWG_IDWG_FLOCS_GET_COUNTER = gql`
query GetIdwgFlocsCounter {
  aggregateIdwgFloc {
    _count {
      _all
    }
  }
}
`
