import { PropsWithChildren } from 'react'
import React from 'react'

interface ILoaderProps extends PropsWithChildren {
  readonly isLoading: boolean
  readonly display?: string
}

const LoaderMinimalist = ({ isLoading, display, children }: ILoaderProps) => {
  return (
    <div className="h-full w-full overflow-hidden">
      {isLoading && (
        <div className={`opacity-80 ${display ? display : 'fixed'} inset-0 h-full w-full z-90 flex justify-center items-center align-middle`}>
          <div>
            <ul className="loaderr">
              <li className="loader__item bg-foreground" />
              <li className="loader__item bg-foreground" />
              <li className="loader__item bg-foreground" />
            </ul>
          </div>
        </div>
      )}
      {children}

      <style>
        {`
          .loaderr {
            display: flex;
          }
          .loader__item {
            width: 16px;
            height: 16px;
            margin: 4px;
            border-radius: 50%;
            list-style: none;
            -webkit-animation: animation-loading 1.2s infinite;
            animation: animation-loading 1.2s infinite;
          }
          .loader__item:nth-child(1) {
            -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
          }
          .loader__item:nth-child(2) {
            -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
          }
          @-webkit-keyframes animation-loading {
            0% {
              transform: scale(0);
            }
            40% {
              transform: scale(1);
            }
            80% {
              transform: scale(0);
            }
            100% {
              transform: scale(0);
            }
          }
          @keyframes animation-loading {
            0% {
              transform: scale(0);
            }
            40% {
              transform: scale(1);
            }
            80% {
              transform: scale(0);
            }
            100% {
              transform: scale(0);
            }
          }
        `}
      </style>
    </div>
  )
}

export default LoaderMinimalist
