import { TRenderAutocompleteProps } from '@app/types/app'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import React, { ReactElement, useEffect, useState } from 'react'
import AppCombobox from '../AppCombobox'

type AutoCompleteMaybeComparableObjects = {
  id?: unknown
  value?: unknown
}

export default function AppAutocomplete({ items, onInputChange, renderMenuItemLabel, foreignField, required: propRequired, isRequired, foreignObject, onSelect, itemIdField, field, isDisabled, className }: TRenderAutocompleteProps) {
  const { t } = useTranslation()
  const required = propRequired || isRequired
  const [itemsWithId, setItemsWithId] = useState<AutoCompleteMaybeComparableObjects[]>([])

  const getItemValue = (item?: unknown) => (
    itemIdField
      ? (item as Record<string, unknown>)?.[itemIdField!]
      : (item as AutoCompleteMaybeComparableObjects)?.id
        ?? (item as AutoCompleteMaybeComparableObjects)?.value ?? null) as string | number | null

  const getSelectedValue = (obj?: unknown) => {
    const acObj = obj as AutoCompleteMaybeComparableObjects
    const selectedItem = items.find((item: AutoCompleteMaybeComparableObjects) => (
      _.isEqual(item, obj)
      || (itemIdField && (item as Record<string, unknown>)[itemIdField] === (acObj as Record<string, unknown> | undefined)?.[itemIdField!])
      || (item.id !== undefined && item.id === acObj?.id)
      || (item.value !== undefined && item.value === acObj?.value)
    ))
    return getItemValue(selectedItem)
  }

  // Set selected value if only one item in list and field is required
  useEffect(() => {
    if (itemIdField || !items[0]?.id) {
      setItemsWithId(items.map(item => ({ ...(item as AutoCompleteMaybeComparableObjects), id: getItemValue(item) })))
    } else {
      setItemsWithId(items as AutoCompleteMaybeComparableObjects[])
    }

    if (required && items.length === 1 && !_.isEqual(getItemValue(foreignObject), getItemValue(items[0]))) {
      onSelect?.(items[0], false)
    }
  }, [items])
  if (!items) {
    return ''
  }

  return (
    <AppCombobox
      key={field}
      variant="holis"
      className={className}
      items={itemsWithId.map(item => ({
        label: typeof renderMenuItemLabel?.(item) === 'string' ? renderMenuItemLabel?.(item) as string : ((item as { label?: string }).label ?? ''),
        value: getItemValue(item),
        item,
        id: getItemValue(item),
        render: renderMenuItemLabel?.(item) as ReactElement,
      }
      ))}
      disabled={isDisabled}
      required={required}
      value={getSelectedValue(foreignObject)}
      placeholder={t(`label.select${_.upperFirst(foreignField)}`)}
      onSelectedItemChanged={(item) => {
        onInputChange?.({ target: { value: item?.item ?? item?.value } })
        onSelect?.(item?.item ?? item?.value)
      }}
    />
  )
}
