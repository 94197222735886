import { addRadSon, ToastProps } from '@holis/react-ui/rad'
import React from 'react'
import { ToastContent, TypeOptions } from 'react-toastify'

type TAppNotifications = Partial<Parameters<typeof addRadSon>[1]> & {
  // Hide notification with same message type (check if same content to remove duplication)
  hideOnSameType?: boolean
  type?: 'success' | 'error' | 'warning' | 'info'
} & Partial<ToastProps>

const defaultOptions: TAppNotifications = {
  position: 'bottom-center',
  duration: 1500,
  dismissible: true,
  type: 'info',
  closeButton: false,
}

export default class AppNotifications {
  static message: ToastContent = null
  static type?: TypeOptions | null = null
  static timeoutId: NodeJS.Timeout | null = null
  static hideOnSameType: boolean = false
  static success(content: React.ReactNode, options?: TAppNotifications) {
    return this.notify(content, {
      ...options,
      type: 'success',
    })
  }

  static error(content: React.ReactNode, options?: TAppNotifications) {
    return this.notify(content, {
      ...options,
      type: 'error',
    })
  }

  static warning(content: React.ReactNode, options?: TAppNotifications) {
    return this.notify(content, {
      ...options,
      type: 'warning',
    })
  }

  static notify(content: React.ReactNode, options?: TAppNotifications) {
    const { hideOnSameType, timeout, ...toastOptions } = options ?? {}
    const notifOptions = {
      ...defaultOptions,
      ...toastOptions,
    }

    // stop show notif when other notif with same type is queueing
    if (AppNotifications.timeoutId && notifOptions.type === AppNotifications.type && hideOnSameType) {
      return
    }

    // check other notif with same type, same content is queueing
    if (notifOptions.type === AppNotifications.type && (content === AppNotifications.message || AppNotifications.hideOnSameType)) {
      if (AppNotifications.timeoutId) {
        clearTimeout(AppNotifications.timeoutId)
        AppNotifications.timeoutId = null
      }
    }

    const notifMethod = notifOptions.type === 'success'
      ? addRadSon.success
      : notifOptions.type === 'error'
        ? addRadSon.error
        : notifOptions.type === 'warning'
          ? addRadSon.warning
          : addRadSon.info

    AppNotifications.hideOnSameType = !!hideOnSameType
    AppNotifications.type = notifOptions.type

    AppNotifications.timeoutId = setTimeout(() => {
      notifMethod(content, notifOptions)
    }, timeout ?? 100)
  }
}
