import { useEffect, useRef, useState } from 'react'
import React from 'react'
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef, ReactZoomPanPinchContentRef, ReactZoomPanPinchProps } from 'react-zoom-pan-pinch'
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader'
import { cn } from '@holis/react-ui/utils'

type TLayoutViewerParams = Readonly<{
  zoomContentRef: ReactZoomPanPinchContentRef
  zoomLevel: number
  onChangeZoomLevel: (zoomLevel: number) => void
  transformComponentRef?: ReactZoomPanPinchRef | null
}>

export type TZoomProps = ReactZoomPanPinchProps & { defaultCursor?: string }

export type TLayoutViewer = Readonly<{
  zoomProps?: TZoomProps
  header?: React.ReactNode | ((params: TLayoutViewerParams) => React.ReactNode)
  footer?: React.ReactNode | ((params: TLayoutViewerParams) => React.ReactNode)
  children?: React.ReactNode | ((params: TLayoutViewerParams) => React.ReactNode)
  isLoading?: boolean
  viewerRef?: React.RefObject<HTMLDivElement>
  className?: string
  contentClassName?: string
  onDoubleClick?: (e?: React.MouseEvent<HTMLElement>, transformComponentRef?: ReactZoomPanPinchRef) => void
}>

enum EViewerCursors {
  GRAB = 'cursor-grab',
  ZOOM_IN = 'cursor-zoom-in',
  ZOOM_OUT = 'cursor-zoom-out',
}

export default function LayoutViewer({ children, footer, zoomProps, header, isLoading, viewerRef, className, onDoubleClick, contentClassName }: TLayoutViewer) {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)
  const [zoomLevel, setZoomLevel] = useState<number>(1)
  const [viewerCursor, setViewerCursor] = useState<string>(zoomProps?.defaultCursor ?? EViewerCursors.GRAB)

  useEffect(() => {
    setViewerCursor(zoomProps?.defaultCursor ?? EViewerCursors.GRAB)
  }, [zoomProps?.defaultCursor])

  const onChangeZoomLevel = (newZoomLevel: number) => {
    setZoomLevel(newZoomLevel)
  }

  const handleTransformed = (_ref: ReactZoomPanPinchRef, state: {
    scale: number
    positionX: number
    positionY: number
  }) => {
    if (state.scale === 1) {
      setViewerCursor(EViewerCursors.GRAB)
    }
  }

  const handleZoomStop = (ref: ReactZoomPanPinchRef, event: TouchEvent | MouseEvent) => {
    const currentScale = ref.state.scale
    const { previousScale } = ref.state
    if (currentScale === 1) {
      setViewerCursor(EViewerCursors.GRAB)
    } else if (currentScale > previousScale) {
      setViewerCursor(EViewerCursors.ZOOM_IN)
    } else {
      setViewerCursor(EViewerCursors.ZOOM_OUT)
    }

    onChangeZoomLevel(currentScale)
    zoomProps?.onZoomStop?.(ref, event)
  }

  const handleDblClick = (e: React.MouseEvent<HTMLElement>, transformComponentRef: ReactZoomPanPinchRef) => {
    e.preventDefault()
    e.stopPropagation()
    setZoomLevel(1)
    transformComponentRef.resetTransform()
    onDoubleClick?.(e, transformComponentRef)
  }

  const handlePanningStart = (ref: ReactZoomPanPinchRef, event: TouchEvent | MouseEvent) => {
    setViewerCursor(EViewerCursors.GRAB)
    zoomProps?.onPanningStart?.(ref, event)
  }

  return (
    <div ref={viewerRef} className={cn('relative flex-col justify-center m-auto items-center flex w-full h-full', className)}>
      <SpinnerLoader className="absolute" isLoading={isLoading} />
      <TransformWrapper
        ref={transformComponentRef}
        smooth
        disablePadding
        limitToBounds
        initialScale={1}
        doubleClick={{
          mode: 'reset',
        }}
        minScale={1}
        maxScale={16}
        panning={{
          velocityDisabled: true,
        }}
        {...zoomProps}
        onTransformed={handleTransformed}
        onPanningStart={handlePanningStart}
        onZoomStop={handleZoomStop}
      >
        {(zoomContentRef: ReactZoomPanPinchContentRef) => (
          <>
            {header
              && (
                <div className="absolute w-full flex flex-col items-center top-0 z-10">
                  {typeof header === 'function' ? header({ zoomContentRef, zoomLevel, onChangeZoomLevel, transformComponentRef: transformComponentRef.current }) : header}
                </div>
              )}
            <TransformComponent
              wrapperStyle={{ width: '100%', height: '100%' }}
              contentStyle={{ width: '100%', height: '100%' }}
            >
              <div className={cn(`${viewerCursor} max-h-full max-w-full`, contentClassName)} onDoubleClick={e => handleDblClick(e, transformComponentRef.current!)}>
                {typeof children === 'function' ? children({ zoomContentRef, zoomLevel, onChangeZoomLevel, transformComponentRef: transformComponentRef.current }) : children}
              </div>
            </TransformComponent>
            {footer
              && (
                <div className="absolute bottom-2 flex flex-col items-center w-full">
                  {typeof footer === 'function' ? footer({ zoomContentRef, zoomLevel, onChangeZoomLevel, transformComponentRef: transformComponentRef.current }) : footer}
                </div>
              )}
          </>
        )}
      </TransformWrapper>
    </div>
  )
}
