import { useMutation, useQuery } from '@apollo/client'
import { InspectionPoint, EventInspectionPoint, RefMeasPointValCode, GetEventInspectionPointsByInspIdQuery, UpdateEventInspectionPointByIdMutation, Event } from '@app/graphql/__types__/graphql'
import { FORMAT_DATE_EU } from '@app/utils/constants'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IColumn } from '@holis/react-ui'
import { WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_INSP_ID, WORKORDER_EVENT_INSPECTION_POINTS_UPDATE_BY_ID } from '@app/graphql/requests/eventInspectionPoints'
import { useLayoutStore } from '@app/stores/layout'
import AppNotifications from '@app/services/notification'
import { useHolisAuth } from '@holis/auth-client-react'
import EventMeasurementQualReading from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementQualReading'
import EventMeasurementTable from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementTable'
import { RadButton } from '@holis/react-ui/rad'
import { LuInfo } from 'react-icons/lu'
import { isValidUnit } from '@app/utils/functions'
import EventMeasurementNotes from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementNotes'

type TEventInspectionPoint = Readonly<{
  inspectionPoint: Partial<InspectionPoint>
  event?: Partial<Event>
}>

export default function EventInspectionPointHistory({ inspectionPoint, event }: TEventInspectionPoint) {
  const [valCodeModalOpenRow, setValCodeModalOpenRow] = useState<Partial<EventInspectionPoint>>()
  const { t } = useTranslation()
  const { user } = useHolisAuth()
  const { startLoading, stopLoading } = useLayoutStore()
  const { data, error, loading, refetch } = useQuery<GetEventInspectionPointsByInspIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_INSP_ID, {
    variables: {
      inspId: inspectionPoint.id!,
      orderBy: [
        { reportingDate: { sort: 'desc' } },
        { id: 'desc' },
      ],
    },
    fetchPolicy: 'no-cache',
  })

  const [updateEventInspByIdApi] = useMutation<UpdateEventInspectionPointByIdMutation>(WORKORDER_EVENT_INSPECTION_POINTS_UPDATE_BY_ID)

  const updateHandler = (item: Partial<EventInspectionPoint>, field: string, value: unknown, htmlElem?: HTMLElement) => {
    startLoading()
    updateEventInspByIdApi({
      variables: {
        id: item.id!,
        data: {
          [field]: {
            set: value,
          },
          reportingDate: {
            set: new Date(),
          },
          reader: {
            set: user?.username,
          },
        },
      },
    })
      .then(() => {
        AppNotifications.success(t('message.success.eventDamageUpdated'))
        refetch()
      })
      .catch(() => {
        if (htmlElem) {
          htmlElem.style.border = '1px solid red'
        }

        const errorMessage = t('message.error.default.title')

        AppNotifications.error(errorMessage)
      })
      .finally(() => {
        stopLoading()
      })
  }

  const isEditableRow = (rowData: unknown) => !!event?.id && (rowData as Partial<EventInspectionPoint>).wrkoId === event?.id

  const cellsRenderer = () => ({
    quantReading: (val: string) => <span>{val}</span>,
    notes(val: string) {
      return (
        <EventMeasurementNotes notes={val} />
      )
    },
    reading(_val: string, rowData: unknown) {
      return (
        <EventMeasurementQualReading<EventInspectionPoint, RefMeasPointValCode> updateHandler={updateHandler} dataRow={rowData} isEditableRow={isEditableRow} />
      )
    },
    reportingDate(_val: string, rowData: unknown) {
      const dataRow = rowData as Partial<EventInspectionPoint>
      return <span>{dataRow.reportingDate ? moment(dataRow.reportingDate).format(FORMAT_DATE_EU) : ''}</span>
    },

    event(_val: string, rowData: unknown) {
      const dataRow = rowData as Partial<EventInspectionPoint>
      return dataRow.event
        ? (
            <span className={`flex justify-between flex-row items-center pr-2 ${dataRow.wrkoId === event?.id ? 'font-bold' : ''}`}>
              {dataRow.event.event}
              {' '}
              <RadButton variant="ghost" size="icon"><LuInfo className="h-4 w-4" /></RadButton>
            </span>
          )
        : ''
    },
  })
  const columns: IColumn[] = [
    {
      field: 'reportingDate',
      title: t('label.date'),
      type: 'date',
      cellRenderer: 'reportingDate',
      width: 100,
      filter: false,
      sort: 'desc',
    },
    {
      field: 'reading',
      title: t('label.reading'),
      type: 'string',
      width: 350,
      cellRenderer: 'reading',
      filter: false,
    },
    {
      field: 'quantReading',
      title: <>
        {t('label.measurement')}
        {' '}
        {isValidUnit(inspectionPoint.char?.unit) && <span className="lowercase">{`(${inspectionPoint.char!.unit!.toLowerCase()})`}</span>}
      </>,
      type: 'string',
      width: 150,
      filter: false,
      cellRenderer: 'quantReading',
    },
    {
      field: 'notes',
      title: t('label.notes'),
      type: 'string',
      width: 30,
      filter: false,
      cellRenderer: 'notes',
    },
    {
      field: 'event',
      title: t('label.event'),
      type: 'string',
      cellRenderer: 'event',
      width: 140,
      filter: false,
    },
  ]

  return (
    <EventMeasurementTable<EventInspectionPoint, RefMeasPointValCode>
      selectedRow={valCodeModalOpenRow}
      displayTabs={['list']}
      updateHandler={updateHandler}
      items={((data?.eventInspectionPoints ?? []) as Partial<EventInspectionPoint>[]).filter((item: Partial<EventInspectionPoint>) => item.wrkoId !== event?.id)}
      isLoading={loading}
      error={!!error}
      cellRenderers={cellsRenderer()}
      columns={columns}
      onQualReadingSelectionModalClose={() => setValCodeModalOpenRow(undefined)}
    />

  )
}
