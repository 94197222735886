import { useHolisAuth } from '@holis/auth-client-react'
import { OptimusGraphQLClient } from './client'
import { ApolloProvider } from '@apollo/client'
import { ReactNode } from 'react'

type props = {
  readonly children: ReactNode
}
export function AuthenticatedApolloProvider({ children }: props) {
  const { getAccessToken } = useHolisAuth()
  const { client } = new OptimusGraphQLClient(getAccessToken)
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
