import { gql } from '@apollo/client'
import { DOCUMENTATION_CATALOGS_COMMON_FIELDS } from '../fragments/refDocsCatalogs'

export const DOCUMENTATION_CATALOGS_GET_MANY = gql`
${DOCUMENTATION_CATALOGS_COMMON_FIELDS}
query GetAllRefDocsCatalogs($orderBy: [RefDocsCatalogOrderByWithRelationInput!], $where: RefDocsCatalogWhereInput) {
    refDocsCatalogs(orderBy: $orderBy, where: $where) {
      ...RefDocsCatalogCommonFields
    }
}`

export const DOCUMENTATION_CATALOGS_GET_COUNTER = gql`
query GetRefDocsCatalogsCounter {
  aggregateRefDocsCatalog {
    _count {
      id
    }
  }
}
`
