import { FunctionalLocation } from '@app/graphql/__types__/graphql'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useEventStore } from '@app/stores/event'
import usePlanStore from '@app/stores/plan'
import { RadBadge, RadContextMenu, RadContextMenuContent, RadContextMenuItem, RadContextMenuTrigger } from '@holis/react-ui/rad'
import { useTranslation } from 'react-i18next'
import { LuExternalLink, LuStar, LuTrash2 } from 'react-icons/lu'
import DragHandle from '../../ItemCard/DragHandle'
import ItemCard, { ItemCardContent } from '../../ItemCard'
import MeasurementValueContainer from '../../ItemCard/MeasurementValueContainer'
import { ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL } from '@app/utils/constants'
import { generatePathWithBaseUrl } from '@app/utils/functions'
import { generatePath } from 'react-router-dom'

type TFlocItem = Readonly<{
  id: number
  flocId: number
  functionalLocation: Partial<FunctionalLocation>
}>

type TFlocCard<TItem extends TFlocItem> = Readonly<{
  item?: Partial<TItem>
  floc?: Partial<FunctionalLocation>
  hasDragBtn?: boolean
  onDeleteClick?: (floc: Partial<TItem | FunctionalLocation>) => void
  hideCreateIanButton?: boolean
  isDeleteButtonDisabled?: boolean
  isCreateIanButtonDisabled?: boolean
  isSchedulingTag?: boolean
  indicatorValue?: string
  deleteText?: string
  hasContextMenu?: boolean
}>

export default function FlocCard<TItem extends TFlocItem>({ item, floc, hasDragBtn, indicatorValue, deleteText, isDeleteButtonDisabled, isSchedulingTag, onDeleteClick, hasContextMenu }: TFlocCard<TItem>) {
  const { activeEvent } = useEventStore()
  const { activePlan } = usePlanStore()
  const { t } = useTranslation()
  const functionalLocation = item?.functionalLocation ?? floc
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item?.id ?? -1 })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  const handleDeleteFlocClick = () => {
    onDeleteClick?.(item ?? floc!)
  }

  const openFlocDetail = () => {
    window.open(generatePathWithBaseUrl(generatePath(ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL, { number: encodeURIComponent(item?.functionalLocation?.floc ?? floc!.floc!) })))
  }

  const getIsSchedulingTag = (flocId: number) => isSchedulingTag !== undefined ? isSchedulingTag : (activeEvent?.schedulingTagId === flocId || activePlan?.functionalLocation?.id === flocId)

  return (
    <div ref={setNodeRef} className="h-full" style={style}>
      <RadContextMenu>
        <RadContextMenuTrigger disabled={!hasContextMenu}>

          <ItemCard onClick={openFlocDetail}>

            {!!hasDragBtn
              && <DragHandle attributes={attributes} listeners={listeners} />}

            <ItemCardContent className="flex flex-col gap-1 text-sm">
              <div className="flex gap-2">
                <RadBadge>
                  {functionalLocation?.techClass?.techClass}
                </RadBadge>
                <div className="font-semibold">{functionalLocation?.floc}</div>
                {getIsSchedulingTag(item?.flocId ?? -1) && <LuStar fill="#3c83f6" size={18} className="text-primary" />}
              </div>
              <div className="font-normal">{functionalLocation?.description}</div>
            </ItemCardContent>

            {indicatorValue
              && <MeasurementValueContainer measurementValue={indicatorValue} />}

          </ItemCard>

        </RadContextMenuTrigger>
        <RadContextMenuContent>
          <RadContextMenuItem onClick={openFlocDetail}>
            <LuExternalLink className="mr-2" />
            {' '}
            {t('label.openObject')}
          </RadContextMenuItem>
          {!!onDeleteClick && (
            <RadContextMenuItem disabled={isDeleteButtonDisabled} className="item-destructive" onClick={handleDeleteFlocClick}>
              <LuTrash2 className="mr-2" />
              {' '}
              {deleteText ?? t('label.delete')}
            </RadContextMenuItem>
          )}
        </RadContextMenuContent>
      </RadContextMenu>
    </div>
  )
}
