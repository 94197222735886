type Status = 'pending' | 'success' | 'error' | 'processing'

type StepStatus = {
  timestamp: number
  status: Status
  message: string
}

type ExportStep = {
  name: string
  status?: StepStatus
}

export type ExportStatus = { status: Status, steps: ExportStep[] }

const ExportStatusFromSteps = (steps: ExportStep[]): ExportStatus => {
  const hasError = steps?.some(step => step.status?.status === 'error')
  const isSuccess = steps?.every(step => step.status?.status === 'success')
  const hasProcessing = steps?.some(step => step.status?.status === 'processing')
  return { status: isSuccess ? 'success' : (hasError ? 'error' : (hasProcessing ? 'processing' : 'pending')), steps }
}

export const ExportStatusFromJsonSteps = (json?: string | object): ExportStatus => {
  if (!json) {
    return { status: 'pending', steps: [] }
  }

  if (typeof json === 'object') {
    return ExportStatusFromSteps(json as ExportStep[])
  }

  const steps = JSON.parse(json) as ExportStep[]
  return ExportStatusFromSteps(steps)
}
