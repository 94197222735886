import { gql } from '@apollo/client'
import { INTEGRITY_STATUSES_DETAIL_FIELDS } from '../fragments'

export const INTEGRITY_STATUSES_GET_ONE_BY_FLOC_ID = gql`
${INTEGRITY_STATUSES_DETAIL_FIELDS}
query GetIntegrityStatusByFlocId($flocId: Int!) {
  integrityStatus(where: {flocId: $flocId}) {
    ...IntegrityStatusDetailFields
  }
}
`

export const INTEGRITY_STATUSES_GET_BY_FLOC_IDS = gql`
${INTEGRITY_STATUSES_DETAIL_FIELDS}
query GetIntegrityStatusesByFlocIds($flocIds: [Int!]) {
  integrityStatuses(where: {flocId: {in: $flocIds}}) {
    ...IntegrityStatusDetailFields
  }
}
`

export const INTEGRITY_STATUSES_DELETE_BY_FLOC_ID = gql`
mutation DeleteIntsByFlocId($flocId: Int!) {
  deleteOneIntegrityStatus(where: {flocId: $flocId}) {
    id
  }
}
`

export const INTEGRITY_STATUSES_ADD_BY_FLOC_ID = gql`
mutation AddAndCalculateIntsByFlocId($flocId: Int!) {
  addAndCalculateFlocIntegrityStatus(id: $flocId)
}
`

export const INTEGRITY_STATUSES_UPDATE_BY_ID = gql`
${INTEGRITY_STATUSES_DETAIL_FIELDS}
mutation UpdateIntegrityStatusById($id: Int!, $data: IntegrityStatusUncheckedUpdateInput!) {
  updateOneIntegrityStatus(where: {id: $id}, data: $data) {
    ...IntegrityStatusDetailFields
  }
}
`

export const INTEGRITY_STATUSES_GET_COUNTER = gql`
query GetIntegrityStatusesCounter {
  aggregateIntegrityStatus {
    _count {
      id
    }
  }
}
`
