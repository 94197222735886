import ElevatedButton from '@app/components/Common/Button/ElevatedButton'
import Separator from '@app/components/Common/Separator'
import { Grid } from '@app/graphql/__types__/graphql'
import { useInspectionDrawingStore } from '@app/stores/methodEngineering/inspectionDrawing'
import { Button } from '@holis/react-ui'
import { RadContextMenu, RadContextMenuContent, RadContextMenuItem, RadContextMenuTrigger } from '@holis/react-ui/rad'
import { useTranslation } from 'react-i18next'
import { FaMinus } from 'react-icons/fa'
import { LuTrash2 } from 'react-icons/lu'

type TGridItem = Readonly<{
  grid: Partial<Grid>
  hasActionButtons?: boolean
  isDeleteButtonDisabled?: boolean
  deleteText?: string
  onDeleteClick?: (grid: Partial<Grid>) => void
}>

export default function GridItem({ grid, hasActionButtons, isDeleteButtonDisabled, deleteText, onDeleteClick }: TGridItem) {
  const { t } = useTranslation()

  const { setGridToDelete, gridToDelete } = useInspectionDrawingStore()

  const handleDeleteFlocClick = () => {
    setGridToDelete(grid)
  }

  return (
    <RadContextMenu>
      <RadContextMenuTrigger disabled={!onDeleteClick}>
        <div className="flex items-center w-full relative text-xs group">
          {!!hasActionButtons && (
            <div className="transition action-buttons absolute -left-8 px-2 opacity-0 group-hover:opacity-100">
              <ElevatedButton
                className="bg-gray-200 p-1"
                shapePreset={Button.ShapePresets.Square}
                disabled={!!gridToDelete}
                onClick={handleDeleteFlocClick}
              >
                <FaMinus />
              </ElevatedButton>
            </div>
          )}

          <div className="flex items-center w-full border rounded border-gray-300 border-w-[0.25px] text-gray-700 py-0.5 px-1">
            <div className="grid grid-flow-col grid-cols-5 flex-grow">
              <div className="col-span-2 font-semibold">
                {grid.grid}
              </div>

              <div className="col-span-3 font-light">
                {grid.description}
              </div>
            </div>

            <div className="col-span1 flex items-center gap-2">
              <Separator className="h-8" />

              <div className="border bg-gray-200 py-1 px-2 rounded font-normal text-center flex-grow">
                {grid.sector?.site?.site}
              </div>
            </div>
          </div>
        </div>
      </RadContextMenuTrigger>

      <RadContextMenuContent>
        {onDeleteClick && (
          <RadContextMenuItem
            disabled={isDeleteButtonDisabled}
            className="item-destructive"
            onClick={handleDeleteFlocClick}
          >
            <LuTrash2 className="mr-2" />

            {deleteText ?? t('label.removeFromDrawing')}
          </RadContextMenuItem>
        )}
      </RadContextMenuContent>
    </RadContextMenu>
  )
}
