import { gql } from '@apollo/client'
import { DWG_REF_TYPES_COMMON_FIELDS } from './refDwgTypes'

export const INSPECTION_DRAWINGS_COMMON_FIELDS = gql`
  ${DWG_REF_TYPES_COMMON_FIELDS}
  fragment InspectionDrawingCommonFields on InspectionDrawing {
    id
    idwg
    sectorId
    sector {
      id
      sector
      site {
        site
      }
      description
    }
    description
    typeId
    flagStatus
    type {
      ...RefDwgTypeCommonFields
    }
    revision
    docName
  }
`

export const INSPECTION_DRAWINGS_COUNTER_FIELDS = gql`
  fragment InspectionDrawingCounterFields on InspectionDrawing {
    _count {
      cmls
      damages
      idwgFlocs
      idwgGrids
      idwgTechniques
    }
  }
`

export const INSPECTION_DRAWING_VIEWS_COMMON_FIELDS = gql`
  fragment InspectionDrawingViewCommonFields on InspectionDrawingView {
    id
    idwg
    sectorId
    sector_sector
    sector_description
    sector_site_site
    sector_site_description
    sector_site_longDescription
    description
    typeId
    flagStatus
    type_type
    type_description
    revision
    docName
  }
`
