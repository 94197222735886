import PictureCard from '@app/components/Common/Block/Picture/Block/PictureCard'
import DeletePictureModal from '@app/components/Common/Block/Picture/Block/PictureCard/components/DeletePictureModal'
import PictureEditionModal from '@app/components/Common/Block/Picture/Block/PictureEdition'
import { Picture } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import PicturePageHeader from './components/PicturePageHeader'
import React, { useEffect, useState } from 'react'
import PictureCarousel from '@app/components/Common/Block/Picture/Block/PictureCarousel'
import { PictureCategory, PictureWithCats, TPictureUploadParameters } from '@app/types/app'
import { pictureCategories } from '@app/utils/constants'
import { cn } from '@holis/react-ui/utils'
import { EApiOperator } from '@app/utils/enums'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import { searchArray } from '@app/utils/functions'

type TPictureTab = Readonly<{
  isLoading?: boolean
  className?: string
  pictures?: Partial<Picture>[]
  uploadParameters?: TPictureUploadParameters
  onPicturesChanged?: (pictures?: Partial<Picture>[], operator?: EApiOperator) => void
  canManage?: boolean
}>

export default function PictureTab({ className, pictures, uploadParameters, onPicturesChanged, isLoading, canManage }: TPictureTab) {
  const { t } = useTranslation()
  const [uploadFileInputRef, setUploadFileInputRef] = useState<React.RefObject<HTMLInputElement>>()
  const [pics, setPics] = useState<Partial<Picture>[]>()
  const [picToDelete, setPicToDelete] = useState<Partial<Picture>>()
  const [picToUpdate, setPicToUpdate] = useState<Picture>()
  const [filters, setFilters] = useState<PictureCategory[]>(pictureCategories)
  const [search, setSearch] = useState<string>('')
  const [filteredPictures, setFilteredPictures] = useState<PictureWithCats[]>()
  const [firstRendered, setFirstRendered] = useState<boolean>(!className?.includes('hidden'))
  const handlePictureUpdated = (pic: Partial<Picture>) => {
    setPics(pics?.map(item => item.id === pic.id ? pic : item))
    onPicturesChanged?.([pic], EApiOperator.UPDATE)
  }

  useEffect(() => {
    if (typeof pics === 'undefined') {
      return
    }

    const searchValue = search.trim()
    let _filteredPictures: PictureWithCats[] = pics?.map((p) => {
      const pic = p as Picture
      let category: PictureCategory
      if (pic.inspId) {
        category = 'inspectionPoint'
      } else if (pic.dmgeId) {
        category = 'damage'
      } else if (pic.cmlId) {
        category = 'cml'
      } else {
        category = 'general'
      }

      return { ...pic, category }
    }) ?? []

    if (!filters.includes('general')) {
      _filteredPictures = _filteredPictures.filter(p => p.category !== 'general')
    }

    if (!filters.includes('inspectionPoint')) {
      _filteredPictures = _filteredPictures.filter(p => p.category !== 'inspectionPoint')
    }

    if (!filters.includes('damage')) {
      _filteredPictures = _filteredPictures.filter(p => p.category !== 'damage')
    }

    if (!filters.includes('cml')) {
      _filteredPictures = _filteredPictures.filter(p => p.category !== 'cml')
    }

    if (searchValue) {
      _filteredPictures = searchArray(_filteredPictures, searchValue, undefined, ['__typename'])
    }

    setFilteredPictures(_filteredPictures)
  }, [filters, search, pics])

  useEffect(() => {
    setPics(pictures)
  }, [pictures])

  const handlePictureEditionModalOpenChange = (open: boolean) => {
    if (!open) {
      setPicToUpdate(undefined)
    }
  }

  const handleUpdatePictureBtnClicked = async (picture: Picture) => {
    setPicToUpdate(picture)
  }

  const handlePictureDeleted = (deletedPicture?: Partial<Picture>) => {
    if (deletedPicture) {
      setPics(pics?.filter(item => item.id !== deletedPicture.id))
    }
  }

  const renderPictureCard = (item: Partial<Picture>) => (
    <PictureCard
      key={item.id}
      className="max-w-[350px]"
      picture={item}
      onDeleteBtnClick={canManage ? () => setPicToDelete(item) : undefined}
      onUpdateBtnClick={() => handleUpdatePictureBtnClicked(item as Picture)}
    />
  )

  useEffect(() => {
    if (!firstRendered) {
      setFirstRendered(!className?.includes('hidden'))
    }
  }, [className])

  return (
    <SpinnerLoaderComponent isLoading={isLoading} className={className} contentClassName={cn('w-full h-full gap-2 flex flex-col')}>

      <PicturePageHeader canAdd={canManage} uploadInputRef={uploadFileInputRef} filters={filters} setFilters={setFilters} search={setSearch} />

      {firstRendered && (
        <PictureCarousel
          headerHidden
          className="flex-1"
          canDelete={canManage}
          pictures={filteredPictures}
          renderPictures={pics => (pics?.length
            ? <div className="w-full flex flex-row justify-center space-between margin-auto flex-wrap gap-4">{pics.map(renderPictureCard)}</div>
            : (
                <div className="text-muted-foreground py-24 text-center">
                  {t('label.noPicture')}
                </div>
              )
          )}
          uploadParameters={uploadParameters}
          onInputInit={setUploadFileInputRef}
          onChanged={onPicturesChanged}
        />
      )}
      {picToDelete && (
        <DeletePictureModal
          open
          picture={picToDelete!}
          onClose={() => setPicToDelete(undefined)}
          onPictureDeleted={handlePictureDeleted}
        />
      )}

      {picToUpdate && (
        <PictureEditionModal
          open
          picture={picToUpdate}
          allPictures={pics}
          onSaved={handlePictureUpdated}
          onOpenChange={handlePictureEditionModalOpenChange}
        />
      )}
    </SpinnerLoaderComponent>
  )
}
