import { OptimusClientConfig } from '@app/utils/clientConfig'
import UserPermissions from './UserPermissions'

export class UserPermissionsService extends EventTarget {
  // Singleton
  private static _instance: UserPermissionsService | null = null
  static get instance() {
    return UserPermissionsService._instance
  }

  permissions: UserPermissions | null = null

  constructor(private getAccessToken: () => Promise<string | undefined>) {
    super()
  }

  public static init(getAccessToken: () => Promise<string | undefined>) {
    if (this._instance) {
      return
    }

    this._instance = new UserPermissionsService(getAccessToken)
    this._instance.fetchPermissions()
  }

  private async fetchPermissions() {
    if (!this.permissions) {
      const token = await this.getAccessToken()
      let r
      try {
        r = await fetch(`${OptimusClientConfig.current.apiRestUrl}/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        if (!r.ok) {
          throw new Error(r.statusText)
        }
      } catch (e) {
        this.permissions = new UserPermissions()
        this.permissions.state.error = (e as Error).message
        this.permissions.state.loaded = true
        this.dispatchEvent(new Event('loaded'))
        return
      }

      this.permissions = await r.json()
      this.permissions!.state = { loaded: true }
      this.dispatchEvent(new Event('loaded'))
    }

    return this.permissions
  }
}
