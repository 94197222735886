import SinglePageVerticalTabs from '@app/components/Common/Tabs/SinglePageVerticalTabs'
import FlocInfo from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/FlocInfo'
import { EventCml, EventDamage, FunctionalLocation, GetLatestEventCmlsByFlocIdQuery, GetLatestEventDamagesByFlocIdQuery } from '@app/graphql/__types__/graphql'
import { TAppTabItem } from '@app/types/app'
import IanList from './IanList'
import { useTranslation } from 'react-i18next'
import IntegrityStatusBlock from './IntegrityStatus'
import InspectionPointList from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/InspectionPointList'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
import CmlList from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/CmlList'
import DamageList from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/DamageList'
import { useFlocStore } from '@app/stores/methodEngineering/floc'
import { WORKORDER_EVENT_CMLS_GET_LATEST_BY_FLOC_ID, WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_FLOC_ID } from '@app/graphql/requests'
import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

type TLeftSideProps = Readonly<{
  floc: Partial<FunctionalLocation>
}>

export default function LeftSide({ floc }: TLeftSideProps) {
  const { t } = useTranslation()
  const prms = useUserPermissions()
  const { inspectionPoints, cmls, damages, notifications, integrityStatus, setLatestEventCmls, setLatestEventDamages } = useFlocStore()

  const [getEvtCmlListApi] = useLazyQuery<GetLatestEventCmlsByFlocIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_BY_FLOC_ID, {
    variables: {
      flocId: floc!.id,
    },
    fetchPolicy: 'no-cache',
  })

  const [getEvtDamageListApi] = useLazyQuery<GetLatestEventDamagesByFlocIdQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_FLOC_ID, {
    variables: {
      flocId: floc!.id,
    },
    fetchPolicy: 'no-cache',
  })

  const getData = () => {
    getEvtCmlListApi().then((queryResults) => {
      const latestEvtCmls: Record<number, Partial<EventCml> | null> = {};
      (queryResults.data?.lastEventCmlsWithQualReadingByFlocId as Partial<EventCml>[]).forEach((evtCml) => {
        latestEvtCmls[evtCml!.cmlId!] = evtCml
      })
      setLatestEventCmls(latestEvtCmls)
    })
    getEvtDamageListApi().then((queryResults) => {
      const latestEvtDamages: Record<number, Partial<EventDamage> | null> = {};
      (queryResults.data?.lastEventDamagesWithQualReadingByFlocId as Partial<EventDamage>[]).forEach((evtDmg) => {
        latestEvtDamages[evtDmg!.dmgeId!] = evtDmg
      })
      setLatestEventDamages(latestEvtDamages)
    })
  }

  const items: TAppTabItem[] = [
    {
      content: <FlocInfo floc={floc} />,
      title: t('label.header'),
      value: 'header',
    },
    {
      content: <IanList />,
      title: t('label.IAN'),
      value: 'ian',
      counterValue: notifications?.length ?? floc?._count?.notifications ?? 0,
    },
    ...(!integrityStatus
      ? []
      : [
          {
            content: <IntegrityStatusBlock />,
            title: t('label.integrityStatus'),
            value: 'integrity',
          },
        ]),
    {
      content: <InspectionPointList floc={floc!} readonly={!prms.flocs.create && !prms.flocs.update} />,
      title: t('label.checklist'),
      value: 'checklist',
      counterValue: inspectionPoints?.length ?? floc?._count?.inspectionPoints ?? 0,
    },
    {
      content: <CmlList floc={floc!} />,
      title: t('label.cml'),
      value: 'cml',
      counterValue: cmls?.length ?? floc?._count?.cmls ?? 0,
    },
    {
      content: <DamageList floc={floc!} />,
      title: t('label.damage'),
      value: 'damage',
      counterValue: damages?.length ?? floc?._count?.damages ?? 0,
    },
  ]

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="w-full relative h-full">
      <SinglePageVerticalTabs
        items={items}
      />
    </div>
  )
}
