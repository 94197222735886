import EventBlock from './EventBlock'
import BackgroundBlock from './BackgroundBlock'

type TEventPage = Readonly<{
  visible: boolean
}>

export default function EventPage({ visible }: TEventPage) {
  return (
    <div className={`flex flex-col gap-2 ${visible ? '' : 'hidden'}`}>
      <EventBlock />
      <BackgroundBlock />
    </div>
  )
}
