import { useLazyQuery, useQuery } from '@apollo/client'
import AppCombobox from '@app/components/Common/Form/AppCombobox'
import AppSelect from '@app/components/Common/Form/AppSelect'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper'
import { AppListItem } from '@app/components/Common/Form/list-item'
import { FunctionalLocation, GetFlocsByIdwgIdQuery, GetAllFlocsAutocompleteQuery, RefCmlCodeGroup, GetAllRefDamageCodeGroupsQuery, RefDamageCodeGroup, GetAllRefFlocPartsQuery, RefFlocPart, GetAllRefFlocCatalogsQuery, Grid, RefFlocCatalogs, GetGridsByIdwgIdQuery, GetGridsBySectorIdQuery, GetAllRefEventTechniquesQuery, RefEventTechnique } from '@app/graphql/__types__/graphql'
import { FLOCS_GET_BY_IDWG_ID, FLOCS_GET_ALL_AUTOCOMPLETE, DAMAGE_REF_CODE_GROUPS_GET_MANY, FLOC_REF_PARTS_GET_MANY, GRIDS_GET_BY_IDWG_ID, GRIDS_GET_BY_SECTOR_ID, WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY } from '@app/graphql/requests'
import { FLOC_CATALOGS_GET_MANY } from '@app/graphql/requests/refFlocCatalogs'
import useDamageStore, { MAX_LENGTH_VALIDATORS } from '@app/stores/damage'
import { EFLOC_CATALOGS_CATEGORY } from '@app/utils/enums'
import { isFalsyOrWhiteSpace, isStringNotEmpty } from '@app/utils/functions'
import { RadFormField, RadInput, RadTextarea } from '@holis/react-ui/rad'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

type TForm = Readonly<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>
  idwgId?: number
  floc?: Partial<FunctionalLocation>
}>

export default function Form({ idwgId, floc, form }: TForm) {
  const [flocs, setFlocs] = useState<AppListItem[]>(floc
    ? [
        {
          label: `${floc.floc} - ${floc.description?.replace('"', '\'\'')}`,
          value: floc.id,
        },
      ]
    : [])
  const [classes, setClasses] = useState<AppListItem[]>([])
  const [selectedTechniqueId, setSelectedTechniqueId] = useState<number>()
  const [techniques, setTechniques] = useState<AppListItem[]>([])
  const [codeGroups, setCodeGroups] = useState<AppListItem<RefDamageCodeGroup>[]>([])
  const [selectedClass, setSelectedClass] = useState<string>()
  const [parts, setParts] = useState<AppListItem[]>([])
  const [grids, setGrids] = useState<AppListItem[]>([])
  const [accessibilities, setAccessibilities] = useState<AppListItem[]>([])
  const { editDamage } = useDamageStore()
  const [getGridsByIdwgIdApi] = useLazyQuery<GetGridsByIdwgIdQuery>(GRIDS_GET_BY_IDWG_ID)
  const [getGridsBySectorIdApi] = useLazyQuery<GetGridsBySectorIdQuery>(GRIDS_GET_BY_SECTOR_ID)
  const setFlocsFromQueryDatas = (flocs: Partial<FunctionalLocation>[]) => {
    setFlocs(flocs.map(floc => ({
      label: `${floc.floc} - ${floc.description?.replace('"', '\'\'')}`,
      value: floc.id,
    })).sort((a, b) => a.label.localeCompare(b.label)))
  }
  const [getFlocsByIdwgIdApi] = useLazyQuery<GetFlocsByIdwgIdQuery>(FLOCS_GET_BY_IDWG_ID)
  const [getAllFlocsAutocompleteApi] = useLazyQuery<GetAllFlocsAutocompleteQuery>(FLOCS_GET_ALL_AUTOCOMPLETE)

  const { loading: techniquesLoading } = useQuery<GetAllRefEventTechniquesQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY, {
    onCompleted(data) {
      setTechniques(data.refEventTechniques.map((technique: Partial<RefEventTechnique>) => ({
        label: `[${technique.technique}]${technique.description ? ` - ${technique.description}` : ''}`,
        value: technique.id,
      } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)))
    },
  })

  const { loading: codeGroupsLoading, data: codeGroupsQuery } = useQuery<GetAllRefDamageCodeGroupsQuery>(DAMAGE_REF_CODE_GROUPS_GET_MANY, {
    onCompleted(data) {
      const classes = data.refDamageCodeGroups.reduce((obj, codeGroup) => {
        const codeGrp = codeGroup as RefCmlCodeGroup
        obj[codeGrp.class.class] = {
          label: `[${codeGrp.class.class}]${codeGrp.class.description ? ` - ${codeGrp.class.description}` : ''}`,
          value: codeGrp.class.class,
        }
        return obj
      }, {} as Record<string, AppListItem>)
      setClasses(Object.values(classes).sort((a, b) => a.label.localeCompare(b.label)))
    },
  })

  const { loading: partsLoading } = useQuery<GetAllRefFlocPartsQuery>(FLOC_REF_PARTS_GET_MANY, {
    onCompleted(data) {
      setParts(data.refFlocParts.map((part: Partial<RefFlocPart>) => ({
        label: `[${part.part}]${part.description ? ` - ${part.description}` : ''}`,
        value: part.id,
      } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)))
    },
  })

  const { loading: catalogsLoading } = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY, {
    onCompleted(data) {
      setAccessibilities(data.findManyRefFlocCatalogs
        .filter(c => (c as RefFlocCatalogs).category === EFLOC_CATALOGS_CATEGORY.ACCESSIBILITY)
        .map((catalog: Partial<RefFlocCatalogs>) => ({
          label: `[${catalog.code}]${catalog.description ? ` - ${catalog.description}` : ''}`,
          value: catalog.id,
        } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)))
    },

  })

  const setFormFieldValue = (name: string, value: unknown) => {
    form.clearErrors(name)
    form.setValue(name, value)
  }

  const [flocsLoading, setFlocsLoading] = useState(false)

  useEffect(() => {
    if (!floc) {
      setFlocsLoading(false)
    }

    setFlocsLoading(true)
    if (idwgId) {
      getFlocsByIdwgIdApi({
        variables: { idwgId },
        fetchPolicy: 'no-cache',
      }).then((r) => {
        setFlocsFromQueryDatas(r.data?.functionalLocations ?? [])
        setFlocsLoading(false)
      })
    } else {
      getAllFlocsAutocompleteApi()
        .then((r) => {
          setFlocsFromQueryDatas(r.data?.functionalLocations ?? [])
          setFlocsLoading(false)
        })
    }
  }, [idwgId, floc])

  useEffect(() => {
    if (selectedTechniqueId) {
      setFormFieldValue('techniqueId', selectedTechniqueId)
    }
  }, [selectedTechniqueId])

  useEffect(() => {
    const codeGrps = codeGroupsQuery?.refDamageCodeGroups
      .filter(cg => (cg as RefCmlCodeGroup).class.class === selectedClass)
      .map((cg: Partial<RefDamageCodeGroup>) => ({
        label: `[${cg.codeGroup}]${cg.description ? ` - ${cg.description}` : ''}`,
        value: cg.id,
        item: cg as RefDamageCodeGroup,
      }))?.sort((a, b) => a.label.localeCompare(b.label)) ?? []

    if (codeGrps.length === 1) {
      setFormFieldValue('codeGroupId', codeGrps[0].value)
      const currDesc = form.getValues('description')
      const descIsDirty = !!form.formState.dirtyFields.description
      if (!isStringNotEmpty(editDamage?.description)
        && (isFalsyOrWhiteSpace(currDesc) || !descIsDirty)) {
        setFormFieldValue('description', codeGrps[0]?.item?.defaultDesc ?? '')
      }

      if (codeGrps[0].item.defaultTechniqueId) {
        setSelectedTechniqueId(codeGrps[0].item.defaultTechniqueId)
      }
    } else {
      setFormFieldValue('codeGroupId', undefined)
    }

    setCodeGroups(codeGrps)
  }, [codeGroupsQuery, selectedClass])

  useEffect(() => {
    if (flocs.length === 1) {
      setFormFieldValue('flocId', flocs[0].value)
    }
  }, [flocs])

  useEffect(() => {
    if (editDamage?.idwgId) {
      getGridsByIdwgIdApi({
        variables: {
          idwgId: editDamage?.idwgId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => setGrids((result.data?.grids ?? []).map((grid: Partial<Grid>) => ({
        label: `[${grid.grid}]${grid.description ? ` - ${grid.description}` : ''}`,
        value: grid.id,
      } as AppListItem))))
    } else if (editDamage?.flocId) {
      getGridsBySectorIdApi({
        variables: {
          flocId: editDamage?.flocId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => setGrids((result.data?.grids ?? []).map((grid: Partial<Grid>) => ({
        label: `[${grid.grid}]${grid.description ? ` - ${grid.description}` : ''}`,
        value: grid.id,
      } as AppListItem))))
    } else {
      setGrids([])
    }
  }, [editDamage?.idwgId, editDamage?.flocId])

  return (
    <div className="flex flex-col px-0.5 mb-6">

      <div className="flex gap-12">

        <div className="w-[60%] flex flex-col gap-4">

          {/* ANCHOR Title - Identification */}
          <FormGroupHeader>
            {t('label.identification')}
          </FormGroupHeader>

          <div className="flex gap-4">

            <div className="w-[80%] overflow-hidden">
              {/* ANCHOR Floc */}
              <RadFormField
                name="flocId"
                render={({ field, fieldState }) => (
                  <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.functionalLocation')}>
                    <AppSelect
                      required
                      items={flocs}
                      placeholder={t('label.selectFloc')}
                      loading={flocsLoading}
                      {...field}
                    />
                  </InputLabelWrapper>
                )}
              />
            </div>

            <div className="flex-grow">
              {/* ANCHOR Position */}
              <RadFormField
                name="position"
                render={({ field, fieldState }) => (
                  <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.position')}>
                    <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.POSITION} />
                  </InputLabelWrapper>
                )}
              />
            </div>

          </div>

          {/* ANCHOR Description */}
          <RadFormField
            name="description"
            render={({ field, fieldState }) => (
              <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.description')}>
                <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.DESCRIPTION} />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR Long Description */}
          <RadFormField
            name="longDescription"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} className="w-full" label={t('label.longDescription')}>
                  <RadTextarea
                    {...field}
                    placeholder={t('label.longDescription')}
                    className="h-[225px]"
                    maxLength={MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION}
                  />
                </InputLabelWrapper>
              )
            }
          />

        </div>

        <div className="flex-1 flex flex-col gap-4">
          {/* ANCHOR Title - Classification */}
          <FormGroupHeader>
            {t('label.classification')}
          </FormGroupHeader>

          {/* ANCHOR Class */}
          <InputLabelWrapper isRequired label={t('label.class')}>
            <AppSelect
              required
              items={classes}
              value={selectedClass}
              placeholder={t('label.selectClass')}
              loading={codeGroupsLoading}
              onChange={val => setSelectedClass(val as string)}
            />
          </InputLabelWrapper>

          {/* ANCHOR Codegroup */}
          <RadFormField
            name="codeGroupId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.codeGroup')}>
                <AppSelect
                  key={`codegroup-${selectedClass}`}
                  required
                  items={codeGroups}
                  placeholder={selectedClass ? t('label.selectCodeGroup') : t('label.selectClass')}
                  loading={codeGroupsLoading}
                  onSelectedItemChanged={(selectedItem) => {
                    if (selectedItem?.item?.defaultTechniqueId) {
                      setSelectedTechniqueId(selectedItem?.item?.defaultTechniqueId)
                    }

                    if (!isStringNotEmpty(editDamage?.description)) {
                      setFormFieldValue('description', selectedItem?.item?.defaultDesc ?? '')
                    }
                  }}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR technique */}
          <RadFormField
            name="techniqueId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.technique')}>
                <AppSelect
                  placeholder={t('label.selectTechnique')}
                  loading={techniquesLoading}
                  items={techniques}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR Part */}
          <RadFormField
            name="partId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.part')}>
                <AppSelect
                  placeholder={t('label.selectPart')}
                  loading={partsLoading}
                  items={parts}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR Title - Location */}
          <FormGroupHeader>
            {t('label.location')}
          </FormGroupHeader>

          {/* ANCHOR Grid */}
          <RadFormField
            name="gridId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.grid')}>
                <AppCombobox
                  items={grids}
                  placeholder={t('label.selectGrid')}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR Accessibility */}
          <RadFormField
            name="accessibilityId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.accessibility')}>
                <AppSelect
                  placeholder={t('label.selectAccessibility')}
                  loading={catalogsLoading}
                  items={accessibilities}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

        </div>

      </div>

    </div>
  )
}
