import DrawingObjectSelectionConfirmModal from '@app/components/Modal/Confirm/DrawingObjectSelection'
import { Cml, Damage, EventCml, GetCmlsByFlocIdsQuery, GetLatestEventCmlsByFlocIdsQuery, InspectionDrawing, UpdateIdwgCmlsMutation } from '@app/graphql/__types__/graphql'
import { NO_DRAWING_ID } from '@app/utils/constants'
import { useEffect, useState } from 'react'
import CmlCard from '../CmlBlock/CmlCard'
import { useTranslation } from 'react-i18next'
import { LuMapPin } from 'react-icons/lu'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CMLS_GET_BY_FLOC_IDS, CMLS_UPDATE_IDWG_MANY, WORKORDER_EVENT_CMLS_GET_LATEST_BY_FLOC_IDS } from '@app/graphql/requests'
import { TDbId, TObjId } from '@app/types/app'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import useIdwgStore, { IDWG_PREFIX } from '@app/stores/idwg'

type TIdwgCmlSelectionModal = Readonly<{
  idwg: Partial<InspectionDrawing>
}>

export default function IdwgCmlSelectionModal({ idwg }: TIdwgCmlSelectionModal) {
  const { t } = useTranslation()
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>()
  const { startLoading, stopLoading } = useLayoutStore()
  const { cmls, flocs, fetchCmls, idwgFlocCmls, setIdwgFlocCmls, changeCmlsSelectionModalDisplay, cmlsSelectionDisplayed, setLatestIdwgFlocEventCmls, latestIdwgFlocEventCmls } = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`)

  const [updateIdwgCmlsApi] = useMutation<UpdateIdwgCmlsMutation>(CMLS_UPDATE_IDWG_MANY)

  const [getIdwgFlocCmlsApi] = useLazyQuery<GetCmlsByFlocIdsQuery>(CMLS_GET_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  })

  const [getIdwgFlocEventCmlsApi] = useLazyQuery<GetLatestEventCmlsByFlocIdsQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  })

  const addItems = (ids: TDbId[]) => updateIdwgCmlsApi({
    variables: {
      ids,
      idwg: idwg.id!,
    },
  })

  const deleteItems = (ids: TDbId[]) => updateIdwgCmlsApi({
    variables: {
      ids,
      idwg: null,
    },
  })

  const handleValidateSelection = async (selectedItems: Partial<TObjId>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const cmlIdsToDelete = (cmls?.filter((item: Partial<Cml>) => oldSelectedIds.includes(item.id!)).map((item: Partial<Cml>) => item.id) ?? []) as TDbId[]
      if (cmlIdsToDelete.length) {
        promises.push(deleteItems(cmlIdsToDelete))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        fetchCmls?.()
        changeCmlsSelectionModalDisplay(false)
        AppNotifications.success(t('message.success.eventCmlsUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    } else {
      changeCmlsSelectionModalDisplay(false)
    }
  }

  const getIdwgFlocCmls = () => {
    if (Array.isArray(flocs) && flocs.length) {
      getIdwgFlocCmlsApi({
        variables: {
          flocIds: flocs.map(item => item.id!),
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        setIdwgFlocCmls((queryResult.data?.cmls ?? []) as Partial<Cml>[])
      })
    } else {
      setIdwgFlocCmls([])
    }
  }

  const getIdwgFlocEventCmls = () => {
    if (Array.isArray(flocs) && flocs.length) {
      getIdwgFlocEventCmlsApi({
        variables: {
          flocIds: flocs.map(item => item.id!),
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        const latestEventCmls: Record<number, Partial<EventCml> | null> = {};
        ((queryResult.data?.lastEventCmlsWithQualReadingByFlocIds ?? []) as Partial<EventCml>[]).forEach((evtCml) => {
          latestEventCmls[evtCml.cmlId!] = evtCml
        })
        setLatestIdwgFlocEventCmls(latestEventCmls)
      })
    } else {
      setLatestIdwgFlocEventCmls({})
    }
  }

  useEffect(() => {
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      idwg,
    ])
  }, [idwg])

  useEffect(() => {
    getIdwgFlocCmls()
    getIdwgFlocEventCmls()
  }, [flocs])

  return (
    <DrawingObjectSelectionConfirmModal
      hasItems
      isMultiple
      dmgHidden
      latestEventItems={latestIdwgFlocEventCmls}
      isLoading={!Array.isArray(cmls) || typeof latestIdwgFlocEventCmls === 'undefined'}
      title={t('label.manageCMLs')}
      description={t('label.manageCMLsDescription')}
      headerTitle={(
        <div className="flex items-center text-primary">
          <LuMapPin />
          {' '}
          {t('label.cml')}
        </div>
      )}
      drawings={drawings}
      open={cmlsSelectionDisplayed}
      items={idwgFlocCmls ?? []}
      drawingStoreIdSuffix="cml-selection-modal"
      selectedIds={cmls?.map(item => item.id!)}
      renderItem={(item: Partial<Cml | Damage>, drawingStoreId: string) => <CmlCard hasMenuContext displayLastQualReadingWithColor drawingStoreId={drawingStoreId} eventCml={latestIdwgFlocEventCmls?.[item.id!] ?? (item as Partial<Cml>)} objectItem={idwg} onDisplay2dChanged={getIdwgFlocCmls} />}
      onValidate={handleValidateSelection}
      onClose={() => changeCmlsSelectionModalDisplay(false)}
    />
  )
}
