import SelectionConfirmModal, { TSelectionConfirmModalWithoutListProps } from '@app/components/Modal/Confirm/Selection'
import { InspectionDrawing } from '@app/graphql/__types__/graphql'
import { TDataRowTreeList } from '@holis/react-ui'
import { useTranslation } from 'react-i18next'

export default function InspectionDrawingSelectionModal({ items, ...restProps }: TSelectionConfirmModalWithoutListProps<Partial<InspectionDrawing>>) {
  const { t } = useTranslation()

  return (
    <SelectionConfirmModal<Partial<InspectionDrawing>>
      title={t('label.idwg')}
      listProps={{
        listProps: {
          data: (items ?? []) as unknown as TDataRowTreeList[],
          columns: [
            {
              field: 'id',
              title: 'ID',
              type: 'number',
              width: 50,
            },
            {
              field: 'sector.site.site',
              title: t('label.site'),
              type: 'string',
              width: 100,
            },
            {
              field: 'idwg',
              title: t('label.reference'),
              type: 'string',
              width: 200,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 200,
            },
            {
              field: 'type.type',
              title: t('label.type'),
              type: 'string',
              width: 100,
            },
          ],
        },
      }}
      {...restProps}
    />
  )
}
