import Header from '@app/components/Common/Block/InspectionDrawing/DrawingViewer/Header'
import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu'
import PanelFilters from '@app/components/Common/Panel/PanelFilters'
import { useEventStore } from '@app/stores/event'
import useIdwgStore from '@app/stores/idwg'
import { useInspectionDrawingStore } from '@app/stores/methodEngineering/inspectionDrawing'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuPlusSquare } from 'react-icons/lu'

type DrawingPageHeaderProps = Readonly<{
  readonly?: boolean
}>

export default function DrawingPageHeader({ readonly }: DrawingPageHeaderProps) {
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const [filters, setFilters] = useState<string[]>(['cml', 'damage'])
  const { setSelectedDrawing, selectedDrawing, activeEvent, changeIdwgSelectionModalDisplay } = useEventStore()
  const { setActiveInspectionDrawing } = useInspectionDrawingStore()
  const { changeCmlMarkupsDisplay, changeDamageMarkupsDisplay, cmlMarkupsShown, damageMarkupsShown } = useIdwgStore()

  const handleFilterChanged = (values: string[]) => {
    changeCmlMarkupsDisplay(values.includes('cml'))
    changeDamageMarkupsDisplay(values.includes('damage'))
  }

  useEffect(() => () => {
    setActiveInspectionDrawing(undefined)
  }, [])

  useEffect(() => {
    const filters
      = Object.entries({
        cml: cmlMarkupsShown,
        damage: damageMarkupsShown,
      }).filter(([, value]) => value).map(([key]) => key)
    setFilters(filters)
  }, [cmlMarkupsShown, damageMarkupsShown])

  useEffect(() => {
    changeCmlMarkupsDisplay(true)
    changeDamageMarkupsDisplay(true)
  }, [])

  return (
    <div className="flex items-center mb-2">

      <div className="text-gray-700 text-sm font-semibold">
        {
          selectedDrawing
            ? (
                <Header idwg={selectedDrawing} goBack={() => setSelectedDrawing()} />
              )
            : (
                <div className="flex gap-2 items-center">
                  {t('label.drawingList')}
                  <ActionThreeDotsMenu>
                    <RadDropdownMenuItem
                      disabled={readonly || config.getActionIsDisabled('event', 'drawingSelect', activeEvent?.status)}
                      onClick={() => changeIdwgSelectionModalDisplay(true)}
                    >
                      <LuPlusSquare className="mr-2" />
                      {' '}
                      {t('label.manageList')}
                    </RadDropdownMenuItem>

                  </ActionThreeDotsMenu>
                </div>
              )
        }
      </div>

      <div className="flex-1 flex justify-end">
        <PanelFilters<string>
          isMultiple
          items={[
            { label: t('label.displayCmls'), value: 'cml' },
            { label: t('label.displayDamages'), value: 'damage' },
          ]}
          value={filters}
          onChange={values => handleFilterChanged(values.map(v => v.value))}
        />
      </div>

    </div>
  )
}
