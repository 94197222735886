import { gql } from '@apollo/client'

export const NOTIFICATION_REF_TYPES_COMMON_FIELDS = gql`
  fragment RefNotifTypeCommonFields on RefNotifType {
    id
    notifType
    description
    longText
    isActive
}`
