import { EventDocument, Document, Event } from '@app/graphql/__types__/graphql'
import { RadContextMenu, RadContextMenuTrigger, RadContextMenuContent, RadContextMenuItem } from '@holis/react-ui/rad'
import { useTranslation } from 'react-i18next'
import { LuPictureInPicture2, LuLink2 } from 'react-icons/lu'
import ItemCard, { ItemCardContent } from '../../ItemCard'
import { ROUTE_DOCUMENTATION_DOC_DETAIL } from '@app/utils/constants'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { generatePath } from 'react-router-dom'
import { generatePathWithBaseUrl } from '@app/utils/functions'

type TDocumentCard = Readonly<{
  eventDocument?: Partial<EventDocument>
  doc: Partial<Document>
  event?: Partial<Event>
  readonly?: boolean
  hasContextMenu?: boolean
  addToAttachments?: () => void
  onOpen?: () => void
}>

export default function DocumentCard({ doc, readonly, hasContextMenu, addToAttachments, event, onOpen }: TDocumentCard) {
  const { t } = useTranslation()
  const config = useOptimusConfig()

  const openDoc = (doc: Partial<Document>) => {
    window.open(generatePathWithBaseUrl(generatePath(ROUTE_DOCUMENTATION_DOC_DETAIL, { number: doc.document! })), '_blank')
  }

  return (
    <RadContextMenu key={doc.id}>
      <RadContextMenuTrigger disabled={!hasContextMenu}>
        <ItemCard onClick={onOpen ?? (() => openDoc(doc))}>
          <ItemCardContent>
            <div className="font-bold text-sm">{doc.document}</div>
            <div className="line-clamp-1">{doc.description}</div>
          </ItemCardContent>
        </ItemCard>
      </RadContextMenuTrigger>
      <RadContextMenuContent>
        <RadContextMenuItem onClick={onOpen ?? (() => openDoc(doc))}>
          <LuPictureInPicture2 className="mr-2" />
          {' '}
          {t('label.open')}
        </RadContextMenuItem>
        {!!addToAttachments && (
          <RadContextMenuItem disabled={readonly || (event && config.getActionIsDisabled('event', 'attachmentAddDelete', event?.status))} onClick={addToAttachments}>
            <LuLink2 className="mr-2" />
            {' '}
            {t('label.addToAttachment')}
          </RadContextMenuItem>
        )}
      </RadContextMenuContent>
    </RadContextMenu>
  )
}
