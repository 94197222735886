import ConfirmModal, { IConfirmModal } from '..'
import { useTranslation } from 'react-i18next'

export default function WarningConfirmModal(props: IConfirmModal) {
  const { t } = useTranslation()
  return (
    <ConfirmModal
      closeOnConfirm
      destructiveAction
      title={t('label.warning')}
      {...props}
    />
  )
}
