import { gql } from '@apollo/client'
import { FLOCS_DETAIL_COMMON_FIELDS } from './flocs'
import { FLOC_CATALOGS_COMMON_FIELDS } from './refFlocCatalogs'
import { WORKORDER_REF_EVENT_TYPES_COMMON_FIELDS } from './refEventTypes'
import { SCHEDULING_PLANS_COMMON_FIELDS } from './plans'

export const SCHEDULING_ITEMS_COMMON_FIELDS = gql`
  ${FLOCS_DETAIL_COMMON_FIELDS}
  ${WORKORDER_REF_EVENT_TYPES_COMMON_FIELDS}
  ${FLOC_CATALOGS_COMMON_FIELDS}
  ${SCHEDULING_PLANS_COMMON_FIELDS}
  fragment ItemCommonFields on Item {
    id
    itemNumber
    planId
    plan {
      ...PlanCommonFields
    }
    description
    longDescription
    period
    schedPriority
    callHorizon
    levelReporting
    levelPreparation
    typeId
    type {
      ...RefEventTypeCommonFields
    }
    mainWorkCenterId
    mainWorkCenter {
      ...RefFlocCatalogsCommonFields
    }
    plannerGroupId
    plannerGroup {
      ...RefFlocCatalogsCommonFields
    }
  }
`
