import { useLazyQuery, useMutation } from '@apollo/client'
import { ControlExtWorkordersMutation, EventControlDataExtResponse, ExtWorkorder, GetAllExtWorkordersQuery, ImportExtWorkordersMutation } from '@app/graphql/__types__/graphql'
import { EXT_WORKORDERS_CONTROL, EXT_WORKORDERS_GET_ALL, EXT_WORKORDERS_IMPORT } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { FORMAT_DATE_EU } from '@app/utils/constants'
import { CellRenderer, GridProps } from '@holis/react-ui'
import { RadBadge, RadButton, RadCheckbox, RadTabs, RadTabsList, RadTabsTrigger } from '@holis/react-ui/rad'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuBadgeCheck, LuBadgeX } from 'react-icons/lu'
import { MdOpenInNew } from 'react-icons/md'
import SummaryModal from './components/SummaryModal'
import AppGrid from '@app/components/Common/AppGrid'
import Tooltip from 'devextreme-react/tooltip'

export default function ExternalWorkorderListPage() {
  const [events, setExtWorkorders] = useState<Partial<ExtWorkorder>[]>()
  const { t } = useTranslation()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [isListToControl, setIsListToControl] = useState<boolean>(true)
  const { startLoading, stopLoading } = useLayoutStore()
  const [importControlSummary, setImportCotrolSummary] = useState<EventControlDataExtResponse[]>()

  const [getExtWorkordersApi, { loading, error }] = useLazyQuery<GetAllExtWorkordersQuery>(EXT_WORKORDERS_GET_ALL, {
    variables: {
      orderBy: [
        {
          id: 'desc',
        },
      ],
    },
    fetchPolicy: 'no-cache',
  })

  const [controlExtWorkordersApi] = useMutation<ControlExtWorkordersMutation>(EXT_WORKORDERS_CONTROL)
  const [importExtWorkordersApi] = useMutation<ImportExtWorkordersMutation>(EXT_WORKORDERS_IMPORT)

  const getExtWorkorders = () => {
    getExtWorkordersApi().then(queryResults => setExtWorkorders(((queryResults.data?.extWorkorders ?? []) as Partial<ExtWorkorder>[]).filter((item) => {
      const { controlStatus } = item as ExtWorkorder

      let { controlStatus: status } = (controlStatus as Record<string, unknown>) ?? {}
      if (!status) {
        status = 'NEW'
      }

      return (isListToControl && status !== 'OK') || (!isListToControl && status === 'OK')
    })))
  }

  const onCheckboxChanged = (checked: boolean, id: number) => {
    const ids = [...selectedIds]
    const idIdx = ids.indexOf(id)
    console.log(checked, idIdx)
    if (checked) {
      if (idIdx === -1) {
        ids.push(id)
      }
    } else if (idIdx > -1) {
      ids.splice(idIdx, 1)
    }

    setSelectedIds(ids)
  }

  const controlRowsData = () => {
    const ids = [...selectedIds]
    if (ids.length) {
      startLoading()
      controlExtWorkordersApi({
        variables: {
          ids,
        },
      }).then((res) => {
        console.log(res.data?.controlExtWorkpackData)
        setImportCotrolSummary(res.data?.controlExtWorkpackData as EventControlDataExtResponse[])
        setSelectedIds([])
        getExtWorkorders()
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'))
      }).finally(stopLoading)
    }
  }

  const importRowsData = () => {
    const ids = [...selectedIds]
    if (ids.length) {
      startLoading()
      importExtWorkordersApi({
        variables: {
          ids,
        },
      }).then((res) => {
        console.log(res.data?.importExtWorkpackData)
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'))
      }).finally(() => {
        stopLoading()
        getExtWorkorders()
      })
    }
  }

  useEffect(() => {
    getExtWorkorders()
  }, [isListToControl])

  const actionCellRenderer: CellRenderer<Partial<ExtWorkorder>> = (val, node) => (
    <RadCheckbox
      aria-label="Select row"
      className="w-4 h-4 align-bottom"
      checked={selectedIds.includes(node.data.id!)}
      onCheckedChange={checked => onCheckboxChanged(!!checked, node.data.id!)}
    >
      <MdOpenInNew size={18} />
    </RadCheckbox>
  )

  const gridProps: GridProps<Partial<ExtWorkorder>> = {
    columns: [
      {
        field: undefined,
        title: '',
        cellRenderer: actionCellRenderer,
        filter: false,
        cannotHide: true,
        draggable: false,
        resizable: false,
        disableOptions: true,
        defaultState: {
          width: 60,
        },
      },
      {
        field: 'controlStatus',
        title: t('label.status'),
        type: 'string',
        defaultState: {
          width: 117,
          hidden: false,
          pinned: false,
        },
        cellRenderer: 'status',
        cannotHide: true,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {
          width: 80,
          hidden: true,
        },
      },
      {
        field: 'woNumber',
        title: t('label.event'),
        type: 'string',
        defaultState: {
          width: 129,
        },
        cellRenderer: 'textBold',
        cannotHide: true,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {
          width: 289,
        },
        cannotHide: true,
      },
      {
        field: 'type',
        title: t('label.type'),
        type: 'set',
        defaultState: {
          width: 289,
        },
        cannotHide: true,
      },
      {
        field: 'schedulingTag',
        title: t('label.schedulingTag'),
        type: 'string',
        defaultState: {
          width: 294,
        },
        cannotHide: true,
      },
      {
        field: 'reportNumber',
        title: t('label.reportNumber'),
        type: 'string',
        defaultState: {
          width: 100,
          hidden: true,
        },
      },
      {
        field: 'packId',
        title: t('label.packId'),
        type: 'number',
        defaultState: {
          width: 77.5,
          hidden: true,
        },
      },
      {
        field: 'dueDate',
        title: t('label.dueDate'),
        type: 'date',
        defaultState: {
          width: 160,
        },
        cellRenderer: 'date',
        cannotHide: true,
      },
    ],
    cellRenderers: {
      status(_val, node) {
        const { controlStatus } = node.data

        let { controlStatus: status } = (controlStatus as Record<string, unknown>) ?? {}
        const { controlMeasPoint, controlWorkorder } = (controlStatus as Record<string, unknown>) ?? {}
        const { schedulingTag, type: eventType, dueDate } = (controlWorkorder as Record<string, boolean | null>) ?? {}
        const { isOk, measuringPointStatuses } = (controlMeasPoint as Record<string, unknown>) ?? {}
        let bgColor = 'bg-gray-200'
        let textColor = 'text-gray-700'
        let tooltip
        if (!status) {
          status = 'NEW'
        }

        switch (status) {
          case 'UPDATED':
            bgColor = 'bg-light-blue-200'
            textColor = 'text-light-blue-700'
            break
          case 'ERROR':
            bgColor = 'bg-red-200'
            textColor = 'text-red-700'
            tooltip = (
              <Tooltip
                hideOnOutsideClick
                showEvent="click"
                position="right"
                target={`#control-status-ext-workorder-${node.data.id}`}
              >
                <div className="p-0 border-1">
                  <div className="p-4 gap-2 flex flex-col items-start text-sm justify-start flex-wrap overflow-auto list-disc max-h-[300px]">
                    <div className="flex items-center gap-4 justify-start flex-wrap">
                      <span className="font-bold text-gray-700 w-[150px] text-left">
                        {t('label.schedulingTag')}
                        :
                      </span>
                      {' '}
                      {schedulingTag ? <LuBadgeCheck className="text-green-700" /> : <LuBadgeX className="text-red-700" />}
                    </div>
                    <div className="flex items-center gap-4 justify-start flex-wrap">
                      <span className="font-bold text-gray-700 w-[150px] text-left">
                        {t('label.eventType')}
                        :
                      </span>
                      {' '}
                      {eventType ? <LuBadgeCheck className="text-green-700" /> : <LuBadgeX className="text-red-700" />}
                    </div>
                    <div className="flex items-center gap-4 justify-start flex-wrap">
                      <span className="font-bold text-gray-700 w-[150px] text-left">
                        {t('label.dueDate')}
                        :
                      </span>
                      {' '}
                      {dueDate ? <LuBadgeCheck className="text-green-700" /> : <LuBadgeX className="text-red-700" />}
                    </div>
                    <div className="flex items-center gap-4 justify-start flex-wrap">
                      <span className="font-bold text-gray-700 w-[150px] text-left">
                        {t('label.controlMeasPoints')}
                        :
                        {' '}
                      </span>
                      {isOk ? <LuBadgeCheck className="text-green-700" /> : <LuBadgeX className="text-red-700" />}
                    </div>
                    {Array.isArray(measuringPointStatuses) && measuringPointStatuses.length && (
                      <div className="flex flex-col items-start w-full text-xs -mt-1">
                        {
                          measuringPointStatuses.map((measuringPointStatus) => {
                            const { location, ptrid, flocExists, integrityStatusExists } = measuringPointStatus ?? {}
                            return (
                              <div key={`${location}-${ptrid}`} className="flex w-full items-center justify-between text-gray-700 gap-2">
                                <div className="flex-1 text-left">
                                  -
                                  <span className={`whitespace-pre-wrap ${flocExists ? '' : 'line-through'}`}>{`${location}`}</span>
                                  {' '}
                                  |
                                  {`${ptrid}`}
                                  :
                                  {' '}
                                </div>
                                {integrityStatusExists ? <LuBadgeCheck className="text-green-700" /> : <LuBadgeX className="text-red-700 w-100 text-right" />}
                              </div>
                            )
                          })
                        }
                      </div>
                    )}
                  </div>
                </div>
              </Tooltip>
            )
            break
          case 'OK':
            bgColor = 'bg-green-200'
            textColor = 'text-green-700'
            break
          default:
            bgColor = 'bg-gray-200'
            textColor = 'text-gray-700'
            break
        }

        return (
          <>
            <RadBadge id={`control-status-ext-workorder-${node.data.id}`} variant="outline" className={`py-1 font-normal ${bgColor} ${textColor} ${status === 'ERROR' ? 'hover:cursor-pointer' : ''}`}>
              {t(`label.extWorkorderStatuses.${status}`)}
            </RadBadge>
            {tooltip}
          </>
        )
      },
      date: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
      textBold: (val: string) => val ? <span className="font-bold">{val}</span> : '',
    },
    canSelect: true,
    data: events ?? [],
  }

  return (
    <>
      <div
        className="h-full"
      >
        { (error && <div>{error.message}</div>)
          || (
            <AppGrid
              name="importExternalWorkorder"
              isLoading={loading}
              {...gridProps}
              title={(
                <RadTabs className="pt-2 px-4" value={isListToControl ? 'control' : 'import'}>
                  <RadTabsList>
                    <RadTabsTrigger key="control-data-list" value="control" onClick={() => setIsListToControl(true)}>
                      {t('label.control')}
                    </RadTabsTrigger>
                    <RadTabsTrigger key="import-data-list" value="import" onClick={() => setIsListToControl(false)}>
                      {t('label.import')}
                    </RadTabsTrigger>
                  </RadTabsList>
                </RadTabs>
              )}
              actions={(
                <RadButton className="h-8 rounded-md px-3 gap-2 text-sm" disabled={!selectedIds.length} onClick={isListToControl ? controlRowsData : importRowsData}>{t(isListToControl ? 'label.controlData' : 'label.import')}</RadButton>
              )}
            />
          )}
      </div>
      {importControlSummary && <SummaryModal isOpen results={importControlSummary} onClose={() => setImportCotrolSummary(undefined)} />}
    </>
  )
}
