import { gql } from '@apollo/client'
import { MEASPOINT_REF_CHARS_COMMON_FIELDS } from '../fragments'

export const MEASPOINT_REF_CHARS_GET_COUNTER = gql`
query GetRefMeasPointCharsCounter {
  aggregateRefMeasPointChar {
    _count {
      id
    }
  }
}
`

export const MEASPOINT_REF_CHARS_GET_MANY = gql`
${MEASPOINT_REF_CHARS_COMMON_FIELDS}
query GetAllRefMeasPointChars($orderBy: [RefMeasPointCharOrderByWithRelationInput!], $where: RefMeasPointCharWhereInput) {
    refMeasPointChars(orderBy: $orderBy, where: $where) {
      ...RefMeasPointCharCommonFields
    }
}`
