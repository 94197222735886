import { Notification } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import { TAdditionalActions } from '@app/types/app'
import { EAdditionalAction, ENOTIFICATION_NOTIF_STATUS } from '@app/utils/enums'
import useNotificationStore from '@app/stores/notification'
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar'
import { RadButton, RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuGitFork, LuRefreshCw, LuTrash2 } from 'react-icons/lu'
import useReportGeneration, { ItemType } from '@app/utils/hooks/useReportGeneration'
import ReportGeneratingMesaggeWrapper from '../../../Reporting/ReportGeneratingMesaggeWrapper'
import { FaFilePdf } from 'react-icons/fa'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'

type THeader = Readonly<{
  notification: Partial<Notification>
  readonly?: boolean
}>

export default function Header({ notification, readonly }: THeader) {
  const { t } = useTranslation()
  const { isReportGenerating, launchReportGeneration } = useReportGeneration(ItemType.notif, notification.id, notification.notif)
  const { changeDeleteNotificationModalDisplay, activeNotification, changeWorkflowModalDisplay } = useNotificationStore()
  const config = useOptimusConfig()
  const prms = useUserPermissions()
  const canGenerateReport = (prms.notifications.create || prms.notifications.approve) && config.getActionIsEnabled('ian', 'generateIANReport', notification.status)

  const handleShowWorkflow = () => {
    changeWorkflowModalDisplay(true)
  }

  const canDelete = notification.status !== ENOTIFICATION_NOTIF_STATUS.CANCEL
  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DISPLAY_WORKFLOW]: (
      <RadDropdownMenuItem className="gap-2" onClick={handleShowWorkflow}>
        <LuGitFork />
        {' '}
        {t('label.workflow')}
      </RadDropdownMenuItem>
    ),
    ...(canDelete
      ? { [EAdditionalAction.DELETE]: (
          <RadDropdownMenuItem disabled={readonly} className="gap-2 item-destructive" onClick={() => changeDeleteNotificationModalDisplay(true)}>
            <LuTrash2 />
            {' '}
            {t('label.deleteNotification')}
          </RadDropdownMenuItem>
        ) }
      : {}),
  }

  return (
    <DetailsPageTitleBar
      title={`${activeNotification?.notif}`}
      subtitle={`${activeNotification?.functionalLocation?.floc}`}
      actionButtons={{
        additionalActions,
      }}
    >
      <div className="flex items-center gap-2 mr-4">
        <ReportGeneratingMesaggeWrapper isReportGenerating={isReportGenerating} />
        {canGenerateReport && (
          <RadButton className="gap-2" onClick={() => launchReportGeneration()} disabled={isReportGenerating} variant="outline">
            {
              isReportGenerating
                ? <LuRefreshCw className="text-sm animate-spin" />
                : <FaFilePdf className="text-sm" />
            }
            {t('label.generateReport')}
          </RadButton>
        )}
      </div>
    </DetailsPageTitleBar>
  )
}
