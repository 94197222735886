import { useLazyQuery } from '@apollo/client'
import SelectionConfirmModal, { TSelectionConfirmModalWithoutListProps } from '@app/components/Modal/Confirm/Selection'
import { FunctionalLocation, GetAllFlocViewsAutocompleteBySitesQuery } from '@app/graphql/__types__/graphql'
import { FLOC_VIEWS_GET_ALL_AUTOCOMPLETE_BY_SITES } from '@app/graphql/requests'
import { useDataStore } from '@app/stores/data'
import { TDataRowTreeList } from '@holis/react-ui'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type TFlocSelectionModal = TSelectionConfirmModalWithoutListProps<Partial<FunctionalLocation>> & Readonly<{
  filterSites?: string[]
}>

export default function FlocSelectionModal({ items, hasItems, filterSites, ...restProps }: TFlocSelectionModal) {
  const { t } = useTranslation()
  const { sites } = useDataStore()
  const [getFlocsApi, { data, loading, error }] = useLazyQuery<GetAllFlocViewsAutocompleteBySitesQuery>(FLOC_VIEWS_GET_ALL_AUTOCOMPLETE_BY_SITES)
  useEffect(() => {
    if (!hasItems) {
      getFlocsApi({
        variables: {
          sites: filterSites ?? sites?.map(site => site.site?.site) ?? [],
        },
      })
    }
  }, [sites, filterSites])

  return (
    <SelectionConfirmModal
      title={t('label.functionalLocation')}
      description={t('label.selectFloc')}
      listProps={{
        listProps: {
          isLoading: loading,
          error: error ? t('message.error.default.title') : undefined,
          data: (items ?? data?.flocViews ?? []) as TDataRowTreeList[],
          cellRenderers: {
          },
          columns: [
            {
              field: 'floc',
              title: t('label.functionalLocation'),
              type: 'string',
              width: 250,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 250,
            },
            {
              field: !hasItems ? 'techClass_techClass' : 'techClass.techClass',
              title: t('label.techClass'),
              type: 'string',
              width: 150,
            },
          ],
        },
      }}
      {...restProps}
    />
  )
}
