import { useLazyQuery } from '@apollo/client'
import AppGrid from '@app/components/Common/AppGrid'
import DocumentModal from '@app/components/Modal/Documentation/document'
import { DocumentView, GetAllDocumentViewsQuery } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_DOCUMENT_VIEWS_GET_ALL } from '@app/graphql/requests/documents'
import { useDataStore } from '@app/stores/data'
import { useDocumentStore } from '@app/stores/document'
import { OBJ_NEW_ID, ROUTE_DOCUMENTATION_DOC_DETAIL } from '@app/utils/constants'
import { generatePathWithBaseUrl, textEllipsisCellRenderer, textEllipsisWrapper } from '@app/utils/functions'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
import { CellRenderer, GridProps } from '@holis/react-ui'
import { RadButton } from '@holis/react-ui/rad'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LuPlusCircle } from 'react-icons/lu'
import { MdOpenInNew } from 'react-icons/md'
import { generatePath, useNavigate } from 'react-router-dom'

const GRID_NAME = 'documentation-assetGrid'

export default function DocumentationAssetPage() {
  const navigate = useNavigate()
  const prms = useUserPermissions()
  const { docViews, setDocViews, setActiveDoc, creatingDoc, setFetchDocs, resetData } = useDocumentStore()
  const { t } = useTranslation()
  const { selectedSites } = useDataStore()
  const [getDocsApi, { loading, error }] = useLazyQuery<GetAllDocumentViewsQuery>(DOCUMENTATION_DOCUMENT_VIEWS_GET_ALL)

  const getDocs = () => {
    if (selectedSites?.length) {
      getDocsApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setDocViews(queryResult.data?.documentViews ?? []))
    } else {
      setDocViews([])
    }
  }

  const handleNewDoc = () => {
    setActiveDoc({
      id: OBJ_NEW_ID,
    }, true)
  }

  useEffect(() => {
    getDocs()
    setFetchDocs(getDocs)
    return () => {
      resetData()
    }
  }, [selectedSites])

  const actionCellRenderer: CellRenderer<Partial<DocumentView>> = (_, node) => (
    <RadButton
      size="icon"
      variant="outline"
      className="size-fit p-1"
      onClick={(e) => {
        const docNumber = node.data.document!
        if (e.ctrlKey || e.metaKey) {
          window.open(generatePathWithBaseUrl(generatePath(ROUTE_DOCUMENTATION_DOC_DETAIL.replace(':number', docNumber))), '_blank')
          return
        }

        return navigate(ROUTE_DOCUMENTATION_DOC_DETAIL.replace(':number', docNumber))
      }}
    >
      <MdOpenInNew size={18} />
    </RadButton>
  )

  const gridProps: GridProps<Partial<DocumentView>> = {
    columns: [
      {
        field: undefined,
        title: '',
        defaultState: {
          width: 91,
        },
        cellRenderer: actionCellRenderer,
        filter: false,
        cannotHide: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'sector_site_site',
        title: t('label.site'),
        type: 'set',
        defaultState: {
          width: 109,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'sector_sector',
        title: t('label.sector'),
        type: 'set',
        defaultState: {
          width: 97,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'document',
        title: t('label.document'),
        type: 'string',
        defaultState: {
          width: 261,
        },
        cellRenderer: 'document',
        cannotHide: false,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {
          width: 975,
        },
        cannotHide: false,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'type_code',
        title: t('label.type'),
        type: 'set',
        defaultState: {
          width: 103,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'discipline_code',
        title: t('label.discipline'),
        type: 'set',
        defaultState: {
          width: 108,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'catalog_code',
        title: t('label.catalog'),
        type: 'string',
        defaultState: {
          width: 122,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'phase_code',
        title: t('label.phase'),
        type: 'set',
        defaultState: {
          width: 117,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'stage_code',
        title: t('label.stage'),
        type: 'set',
        defaultState: {
          width: 110,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'docName',
        title: t('label.docName'),
        type: 'string',
        defaultState: {
          width: 279,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'revision',
        title: t('label.revision'),
        type: 'string',
        defaultState: {
          width: 155,
        },
        cannotHide: false,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'originator_code',
        title: t('label.originator'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'flagStatus',
        title: t('label.status'),
        type: 'set',
        defaultState: {
          width: 91,
          hidden: true,
        },
        cellRenderer: 'flagStatus',
      },
    ],
    cellRenderers: {
      document: val => textEllipsisWrapper(<span className="font-bold">{val}</span>),
      flagStatus: val => textEllipsisWrapper(val ? t(`label.flagStatus.${val}`) : ''),
    },
    data: docViews ?? [],
  }

  return (
    <div className="h-full">
      {creatingDoc && (
        <DocumentModal
          isOpen={creatingDoc}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setActiveDoc(undefined)
            }
          }}
        />
      )}

      { (error && <div>{error.message}</div>) || (
        <AppGrid
          name={GRID_NAME}
          fileNameOnExport={t('label.asset')}
          title={t('label.asset')}
          isLoading={loading}
          {...gridProps}
          actions={prms.docs.create && (
            <RadButton
              size="sm"
              className="flex gap-1 text-sm"
              onClick={handleNewDoc}
            >
              <LuPlusCircle />
              {' '}
              {t('label.addDocument')}
            </RadButton>
          )}
        />
      )}
    </div>
  )
}
