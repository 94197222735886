import { gql } from '@apollo/client'
import { PARAMETERS_COMMON_FIELDS } from '../fragments'

export const APP_PARAMTERS_GET_ONE_BY_CODE = gql`
${PARAMETERS_COMMON_FIELDS}
query GetOneParameterByCode($code: String!) {
    parameter(where: {code: $code}) {
      ...ParameterCommonFields
    }
}`
