import { useLazyQuery } from '@apollo/client'
import SelectionConfirmModal, { TSelectionConfirmModalWithoutListProps } from '@app/components/Modal/Confirm/Selection'
import { Document, GetAllDocumentViewsQuery } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_DOCUMENT_VIEWS_GET_ALL } from '@app/graphql/requests'
import { useDataStore } from '@app/stores/data'
import { TDataRowTreeList } from '@holis/react-ui'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type TDocumentSelectionModal = TSelectionConfirmModalWithoutListProps<Partial<Document>> & Readonly<{
  filterSites?: string[]
}>

export default function DocumentSelectionModal({ items, hasItems, filterSites, ...restProps }: TDocumentSelectionModal) {
  const { t } = useTranslation()
  const { sites } = useDataStore()

  const [getDocumentsApi, { data, loading, error }] = useLazyQuery<GetAllDocumentViewsQuery>(DOCUMENTATION_DOCUMENT_VIEWS_GET_ALL)
  useEffect(() => {
    if (!hasItems) {
      getDocumentsApi({
        variables: {
          sites: filterSites ?? sites?.map(site => site.site?.site) ?? [],
        },
      })
    }
  }, [sites, filterSites])

  return (
    <SelectionConfirmModal<Partial<Document>>
      title={t('label.document')}
      description={t('label.selectDocumentDescription')}
      listProps={{
        listProps: {
          isLoading: loading,
          error: error ? t('message.error.default.title') : undefined,
          data: (items ?? data?.documentViews ?? []) as unknown as TDataRowTreeList[],
          columns: [
            {
              field: 'document',
              title: t('label.document'),
              type: 'string',
              width: 250,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 250,
            },
            {
              field: 'docName',
              title: t('label.docName'),
              type: 'string',
              width: 100,
            },
          ],
        },
      }}
      {...restProps}
    />
  )
}
