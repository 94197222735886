import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeletePictureByIdMutation, Picture } from '@app/graphql/__types__/graphql'
import { PICTURES_DELETE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { useTranslation } from 'react-i18next'

type TDeletePictureModal = IConfirmModal & Readonly<{
  picture: Partial<Picture>
  onPictureDeleted?: (deletedPicture?: Partial<Picture>) => void
}>

export default function DeletePictureModal({ picture, onPictureDeleted, ...restProps }: TDeletePictureModal) {
  const { startLoading, stopLoading } = useLayoutStore()
  const [deletePictureApi] = useMutation<DeletePictureByIdMutation>(PICTURES_DELETE_BY_ID)
  const { t } = useTranslation()
  const onConfirm = () => {
    startLoading()
    deletePictureApi({
      variables: {
        id: picture!.id!,
      },
    }).then(() => {
      onPictureDeleted?.(picture)
      AppNotifications.success(t('message.success.deletePicture'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deletePicture'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deletePicture')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      {...restProps}
    />
  )
}
