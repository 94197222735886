import PermissionEvents from '@app/services/permissions/PermissionEvents'
import PermissionSchedulingPlans from '@app/services/permissions/PermissionSchedulingPlans'
import PermissionAdmin from '@app/services/permissions/PermissionAdmin'
import useUsersStore from '@app/stores/users'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PermissionInspectionPoints from '@app/services/permissions/PermissionInspectionPoints'
import PermissionCmls from '@app/services/permissions/PermissionCmls'
import PermissionDamages from '@app/services/permissions/PermissionDamages'
import PermissionFlocs from '@app/services/permissions/PermissionFlocs'
import PermissionNotifications from '@app/services/permissions/PermissionNotifications'
import PermissionDocs from '@app/services/permissions/PermissionDocs'
import PermissionDrawings from '@app/services/permissions/PermissionDrawings'

export default function PermissionForm() {
  const { t } = useTranslation()
  const { editingRole, setEditingRole } = useUsersStore()

  const perms: Record<string, Record<string, boolean | undefined>> = {}
  for (const perm of editingRole!.permissions || []) {
    perms[perm!.resource!] = JSON.parse(perm!.permissions!)
  }

  const [permissions, setPermissions] = useState<Record<string, Record<string, boolean | undefined>>>(perms)

  const updateEditingRolePermissions = () => {
    const newPermissions = Object.keys(permissions).map(resource => ({
      resource,
      permissions: JSON.stringify(permissions[resource]),
    }))

    setEditingRole({ ...editingRole!, permissions: newPermissions })
  }

  useEffect(() => {
    updateEditingRolePermissions()
  }, [permissions])

  const checkUncheckAllPermissions = (allPermKeys: string[], resource: string) => {
    let allChecked = true
    for (const k of allPermKeys) {
      if (!permissions[resource][k]) {
        allChecked = false
        break
      }
    }

    const newPermissions = { ...permissions[resource] }
    for (const k of allPermKeys) {
      newPermissions[k] = !allChecked
    }

    setPermissions({ ...permissions, [resource]: newPermissions })
  }

  const renderPermissionsEditorRow = (obj: unknown, label: string, resource: string) => {
    const permKeys = Object.keys(obj as Record<string, unknown>)
    if (!permissions[resource]) {
      setPermissions({ ...permissions, [resource]: {} })
    }

    return (
      <div key={`permissions-${resource}`} className="mt-4">
        <div className="flex gap-3 items-center">
          <span className="font-bold">{label}</span>
          <span><a className="underline cursor-pointer text-xs text-gray-500 hover:text-black" onClick={() => checkUncheckAllPermissions(permKeys, resource)}>{t('label.checkuncheckall')}</a></span>
        </div>
        <div className="ms-4 mt-2 grid grid-cols-10 gap-1">
          { permKeys.map(k => (
            <label key={`perm-${k}`} htmlFor={`permissions-${resource}-${k}`} className="flex flex-col items-center gap-1 text-xs border rounded-md p-2 bg-slate-50 cursor-pointer hover:bg-slate-100">
              <input
                type="checkbox"
                id={`permissions-${resource}-${k}`}
                checked={permissions[resource]?.[k] ?? false}
                onChange={(e) => {
                  setPermissions({ ...permissions, [resource]: { ...permissions[resource], [k]: e.target.checked || undefined } })
                }}
              />
              <span className="select-none">{k}</span>
            </label>
          )) }
        </div>
      </div>
    )
  }

  return (
    <>
      {renderPermissionsEditorRow(new PermissionFlocs(), 'Flocs', PermissionFlocs.resourceName)}
      {renderPermissionsEditorRow(new PermissionSchedulingPlans(), 'Scheduling Plans', PermissionSchedulingPlans.resourceName)}
      {renderPermissionsEditorRow(new PermissionEvents(), 'Events', PermissionEvents.resourceName)}
      {renderPermissionsEditorRow(new PermissionNotifications(), 'Notifications', PermissionNotifications.resourceName)}
      {renderPermissionsEditorRow(new PermissionDocs(), 'Documents', PermissionDocs.resourceName)}
      {renderPermissionsEditorRow(new PermissionDrawings(), 'Drawings', PermissionDrawings.resourceName)}
      {renderPermissionsEditorRow(new PermissionInspectionPoints(), 'Inspection Points', PermissionInspectionPoints.resourceName)}
      {renderPermissionsEditorRow(new PermissionCmls(), 'Cmls', PermissionCmls.resourceName)}
      {renderPermissionsEditorRow(new PermissionDamages(), 'Damages', PermissionDamages.resourceName)}
      {renderPermissionsEditorRow(new PermissionAdmin(), 'Admin', PermissionAdmin.resourceName)}
    </>
  )
}
