export default class PermissionNotifications {
  public static resourceName = 'notifications'

  public create = false
  public review = false
  public approve = false
  public correctiveRepair = false
  public qaqc = false
  public relaunchExport = false
}
