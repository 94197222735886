import { gql } from '@apollo/client'
import { DOCUMENTATION_DOCUMENTS_FLOCS_COMMON_FIELDS, DOCUMENTATION_DOCUMENTS_COMMON_FIELDS } from '../fragments'

export const DOCUMENTATION_DOCUMENTS_FLOCS_GET_BY_FLOC_ID = gql`
${DOCUMENTATION_DOCUMENTS_FLOCS_COMMON_FIELDS}
${DOCUMENTATION_DOCUMENTS_COMMON_FIELDS}
query GetFlocDocsByFlocId($flocId: Int!) {
  flocDocs(where: {flocId: {equals: $flocId}}) {
    ...DocumentFlocCommonFields
    document {
      ...DocumentCommonFields
    }
  }
}`

export const DOCUMENTATION_DOCUMENTS_FLOCS_CREATE_MANY = gql`
mutation CreateDocFlocs($data: [FlocDocCreateManyInput!]!) {
  createManyFlocDoc(data: $data) {
    count
  }
}`

export const DOCUMENTATION_DOCUMENTS_FLOCS_DELETE_BY_DOC_ID_AND_FLOC_IDS = gql`
mutation DeleteDocFlocsByDocIdAndFlocIds($docId: Int!, $flocIds: [Int!]) {
  deleteManyFlocDoc(where: {docId: {equals: $docId}, flocId: {in: $flocIds}}) {
    count
  }
}`

export const DOCUMENTATION_DOCUMENTS_FLOCS_DELETE_BY_FLOC_ID_AND_DOC_IDS = gql`
mutation DeleteFlocDocsByFlocIdAndDocIds($flocId: Int!, $docIds: [Int!]) {
  deleteManyFlocDoc(where: {flocId: {equals: $flocId}, docId: {in: $docIds}}) {
    count
  }
}`

export const DOCUMENTATION_DOCUMENTS_FLOCS_DELETE = gql`
mutation DeleteOneFlocDoc($docId: Int!, $flocId: Int!) {
  deleteOneFlocDoc(where: {flocId_docId: {docId: $docId, flocId: $flocId}}) {
    docId,
    flocId,
  }
}
`

export const DOCUMENTATION_DOCUMENTS_FLOCS_GET_COUNTER = gql`
query GetDocumentationDocumentFlocsCounter {
  aggregateFlocDoc {
    _count {
      _all
    }
  }
}
`
