import { gql } from '@apollo/client'

export const WORKORDER_EVENT_DOCUMENTS_COMMON_FIELDS = gql`
  fragment EventDocumentCommonFields on EventDocument {
    id
    docId
    wrkoId
    sort
  }
`
