import React from 'react'
import { TFieldsBlock, TLabelValueField } from '@app/types/app'
import BlockTitle from '../../Text/BlockTitle'
import { cn } from '@holis/react-ui/utils'
import { useTranslation } from 'react-i18next'
import { handleFormInputBlur, handleFormInputKeydown } from '@app/utils/functions'

import { RadFormField, RadFormItem } from '@holis/react-ui/rad'
import LabelValueRow from '../LabelValueRow'
import { ControllerRenderProps, FieldValues, ControllerFieldState } from 'react-hook-form'

import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import { ObjectType } from '@app/services/config'

type TFormFieldsBlock = Readonly<{
  fieldsBlocks: TFieldsBlock[]
  prefixNode?: React.ReactNode
  suffixNode?: React.ReactNode
  className?: string
  blockTitleClassName?: string
  blockClassName?: string
  isDisabled?: boolean
  onFieldChange?: (field: string, value: unknown, updated?: boolean) => void
  onFieldBlur?: (field: string, value: unknown, updated?: boolean) => void
  isFormContext?: boolean
  objectType?: ObjectType
  objectStatus?: string
}>

export default function FormFieldsBlock({ isFormContext: ifFormContext, fieldsBlocks, isDisabled, className, blockClassName, blockTitleClassName, onFieldChange, onFieldBlur, prefixNode, suffixNode, objectType, objectStatus }: TFormFieldsBlock) {
  const { t } = useTranslation()
  const config = useOptimusConfig()
  return (
    <div className={cn('min-w-[250px] flex-grow flex-col flex', className)}>
      {prefixNode}
      {fieldsBlocks.map(({ fields, title, className: fieldBlockClassName, fieldsClassName, fieldClassName, labelClassName }: TFieldsBlock) => (
        <div key={`fields-block-${title}-${fields.map(fieldRow => fieldRow.field).join('-')}`} className={cn('w-full inline-flex flex-col gap-1 px-2', blockClassName, fieldBlockClassName)}>
          {title !== null && title !== false && <BlockTitle className={cn('', blockTitleClassName)}>{typeof title === 'string' ? t(title) : title}</BlockTitle>}
          <div className={cn('flex flex-col w-full flex-grow gap-1', fieldsClassName)}>
            {fields.map((fieldRow: TLabelValueField) => {
              // Force disabled regading fieldsConfig
              const configFieldDisabled = !!objectType && !!objectStatus && config.getFieldIsDisabled(fieldRow.field, objectType, objectStatus)

              const { label, field, inputProps, renderContent, className: fieldRowClassName, isDisabled: isFieldDisabled, ...restProps } = fieldRow
              const disabled = isDisabled || isFieldDisabled || inputProps?.disabled || configFieldDisabled
              if (renderContent) {
                return renderContent({ ...fieldRow, isDisabled: disabled })
              }

              const mainContainerClassName = cn('label-value-row flex w-full', fieldClassName, fieldRowClassName)

              const renderComponent = (formField?: ControllerRenderProps<FieldValues, string>, fieldState?: ControllerFieldState) => (
                <LabelValueRow
                  key={`lbl - ${title} - ${field} - ${label}`}
                  className={mainContainerClassName}
                  fieldRow={fieldRow}
                  handleFieldChange={(f, v, u) => {
                    onFieldChange?.(f, v, u)
                    formField?.onChange?.(v)
                  }}
                  handleFieldBlur={(f, v, u) => {
                    onFieldBlur?.(f, v, u)
                    formField?.onBlur?.()
                  }}
                  onTextInputChange={(e) => {
                    const inputEl = e.target as HTMLInputElement
                    // Set value to id prop if value is an object (foreign key)
                    const val = (inputEl.value as unknown as { id: number })?.id ?? inputEl.value
                    onFieldChange?.(field, val)
                    formField?.onChange?.(val)
                  }}
                  {...restProps}
                  labelClassName={cn(labelClassName, restProps.labelClassName)}
                  fieldState={fieldState}
                  isDisabled={disabled}
                  inputProps={{
                    ...inputProps,
                    ...formField,
                    disabled,
                    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputBlur(e, fieldRow, (fieldRow: TLabelValueField, value: unknown) => {
                      onFieldBlur?.(fieldRow.field, value, value !== fieldRow.initialValue)
                      onFieldChange?.(fieldRow.field, value, value !== fieldRow.initialValue)
                      formField?.onBlur?.()
                    }),
                    onKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
                      handleFormInputKeydown(e, fieldRow, undefined, onFieldChange)
                      formField?.onChange?.(e)
                    },
                  }}
                  label={label}
                />
              )

              return (
                ifFormContext
                  ? (
                      <RadFormField
                        key={field}
                        name={field}
                        render={({ field: formField, fieldState }) => (
                          <RadFormItem className={mainContainerClassName}>
                            {renderComponent(formField, fieldState)}
                          </RadFormItem>
                        )}
                      />
                    )
                  : renderComponent()
              )
            })}
          </div>
        </div>
      ))}
      {suffixNode}
    </div>
  )
}
