import { useTranslation } from 'react-i18next'
import { handleFormInputKeydown, renderCodeAndDescription, setObjValueByPath } from '@app/utils/functions'
import React, { InputHTMLAttributes, TextareaHTMLAttributes, useEffect, useRef, useState } from 'react'
import { TFieldsBlock, TMaybeCodeDescriptionDatas, TRenderAutocompleteProps } from '@app/types/app'
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock'
import { MAX_LENGTH_VALIDATORS, ZOD_PLAN_DATAS } from '@app/stores/plan'
import { Plan, GetAllRefFlocCatalogsQuery, RefFlocCatalogs, GetAllRefPlanTypesQuery, GetAllRefPlanStrategiesQuery, RefPlanType, RefPlanStrategy, UpdatePlanByIdMutation, GetAllFlocsAutocompleteQuery, FunctionalLocation } from '@app/graphql/__types__/graphql'
import { EFieldType, EFLOC_CATALOGS_CATEGORY } from '@app/utils/enums'
import { QueryResult, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { FLOCS_GET_ALL_AUTOCOMPLETE, SCHEDULING_PLANS_UPDATE_BY_ID, SCHEDULING_REF_PLAN_STRATEGIES_GET_MANY, SCHEDULING_REF_PLAN_TYPES_GET_MANY } from '@app/graphql/requests'
import AppAutocomplete from '@app/components/Common/Form/Autocomplete'
import { FLOC_CATALOGS_GET_MANY } from '@app/graphql/requests/refFlocCatalogs'
import usePlanStore from '@app/stores/plan'
import { useLayoutStore } from '@app/stores/layout'
import AppNotifications from '@app/services/notification'
import useSchedulingInspectionPlanStore from '@app/stores/scheduling/inspectionPlan'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { useHolisAuth } from '@holis/auth-client-react'
import FlocSelectionModal from '@app/components/Common/Block/Floc/FlocBlock/FlocSelectionModal'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { RadForm, RadSeparator } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import _ from 'lodash'

type TPlanInfo = Readonly<{
  plan: Partial<Plan>
}>

export default function PlanInfo({ plan }: TPlanInfo) {
  const { t } = useTranslation()
  const isNewForm = plan.id === OBJ_NEW_ID
  const [flocSelectionModalDisplayed, changeFlocSelectionModalDisplay] = useState<boolean>(false)
  const { stopLoading } = useLayoutStore()
  const [getFlocsApi, flocsResult] = useLazyQuery<GetAllFlocsAutocompleteQuery>(FLOCS_GET_ALL_AUTOCOMPLETE)
  const { editPlan, setEditPlan, hasFieldError, updatePlanDataField, updatePlanData, setUpdatePlanData, updatePlan, createPlanWorkflowFunc, updatePlanFieldError } = usePlanStore()
  const { fetchPlans } = useSchedulingInspectionPlanStore()
  const flocCatalogResult = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY)
  const refPlanTypeResult = useQuery<GetAllRefPlanTypesQuery>(SCHEDULING_REF_PLAN_TYPES_GET_MANY)
  const refPlanStrategyResult = useQuery<GetAllRefPlanStrategiesQuery>(SCHEDULING_REF_PLAN_STRATEGIES_GET_MANY)
  const [updatePlanByIdApi] = useMutation<UpdatePlanByIdMutation>(SCHEDULING_PLANS_UPDATE_BY_ID)
  const { user } = useHolisAuth()
  const [submitRequested, setSubmitRequested] = useState<boolean>(false)

  const zodFormObject = z.object(_.omit(ZOD_PLAN_DATAS(t), 'flocId', 'typeId'))
  const form = useForm<z.infer<typeof zodFormObject>>(
    { resolver: zodResolver(zodFormObject), values: {
      plan: editPlan?.plan ?? '',
      description: editPlan?.description?.toString() ?? '',
      notes: editPlan?.notes?.toString() ?? '',
      revision: editPlan?.revision?.toString() ?? '',
    }, mode: 'onBlur' })

  const htmlForm = useRef<HTMLFormElement | null>(null)

  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    const editedPlan = { ...editPlan }
    setObjValueByPath(editedPlan, field, value)
    setEditPlan(editedPlan)
    if (update) {
      updatePlanDataField(field, value)
    }
  }

  const handleSelectFloc = (items: Partial<FunctionalLocation>[]) => {
    const item = items.length ? items[0] : null
    const editedPlan = { ...editPlan }
    setObjValueByPath(editedPlan, 'functionalLocation', item)
    setObjValueByPath(editedPlan, 'flocId', item?.id ?? null)
    setEditPlan(editedPlan)
    updatePlanDataField('flocId', item?.id ?? null)
  }

  const renderAutocomplete = (props: TRenderAutocompleteProps): React.ReactNode => {
    const { fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps, isDisabled } = props ?? {}
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((updatePlanData && Object.keys(updatePlanData).includes(field!)) || (!(updatePlanData && Object.keys(updatePlanData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string)
            }

            const editedPlan = _.cloneDeep(editPlan)
            if (foreignField) {
              setObjValueByPath(editedPlan!, foreignField!, item)
            }

            if (field) {
              setObjValueByPath(editedPlan!, field!, item?.id ?? null)
            }

            setEditPlan(editedPlan)
            updatePlanDataField(field!, item?.id ?? null)

            handleFieldBlur()
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          disabled: isDisabled || inputProps?.disabled,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
        }}
      />
    )
  }

  const updatePlanState = (newData: Partial<Plan>, actionDate: Date, isNew?: boolean) => {
    const newPlan = {
      ...editPlan,
      ...newData,
    } as Partial<Plan>
    if (newPlan.status !== editPlan?.status) {
      createPlanWorkflowFunc?.({
        variables: {
          data: {
            planId: newPlan.id,
            status: newPlan.status,
            actionDate,
            userLogin: user?.username,
          },
        },
      })
    }

    updatePlan(newPlan, isNew)
    setUpdatePlanData({})
    fetchPlans?.()
  }

  const handlePlanUpdateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title')
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`plan`)')) {
        errorMessage = t('message.error.unique.scheduling.plan.plan')
        updatePlanFieldError('plan', true)
      }
    }

    AppNotifications.error(errorMessage)
  }

  const handleUpdatePlan = () => {
    const actionDate = new Date()
    return updatePlanByIdApi({ variables: { id: plan!.id, data: updatePlanData } }).then((newData) => {
      updatePlanState(newData?.data?.updateOnePlan as Partial<Plan>, actionDate)
      AppNotifications.success(t('message.success.planUpdated'))
    }).catch((err: Error) => {
      handlePlanUpdateError(err)
    }).finally(() => {
      stopLoading()
    })
  }

  const handleFieldBlur = () => {
    setSubmitRequested(true)
  }

  const handleFormSubmitSucess = () => {
    // Submit changes only if there are pending value changes
    if (updatePlanData && Object.keys(updatePlanData).length) {
      const planNumberChanged = updatePlanData.plan && updatePlanData.plan !== plan.plan
      handleUpdatePlan().then(() => {
        // If plan number changed, modify last url segment to set the new plan number
        if (planNumberChanged) {
          const url = window.location.href
          const urlParts = url.split('/')
          urlParts[urlParts.length - 1] = editPlan!.plan!
          window.history.replaceState(null, '', urlParts.join('/'))
        }
      })
    }
  }

  useEffect(() => {
    if (isNewForm) {
      getFlocsApi()
    }
  }, [isNewForm])

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit()

      setSubmitRequested(false)
    }
  }, [submitRequested])

  if (!editPlan) {
    return null
  }

  const fieldBlocks: TFieldsBlock[] = [
    {
      title: 'label.identification',
      fields: [
        {
          label: 'label.planNumber',
          field: 'plan',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: plan!.plan,
          value: editPlan!.plan,
          isRequired: true,
          hasError: hasFieldError('plan'),
          inputProps: {
            className: 'disabled:opacity-100 font-bold',
            maxLength: MAX_LENGTH_VALIDATORS.PLAN,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.type',
          field: 'typeId',
          className: 'w-full',
          isRequired: true,
          initialValue: plan!.type?.type,
          value: editPlan!.type?.type,
          foreignObject: editPlan.type,
          foreignField: 'type',
          dbValue: plan!.typeId,
          fieldType: EFieldType.autocomplete,
          renderMenuItemLabel: field => (field as RefPlanType)!.type,
          itemsQueryResult: refPlanTypeResult,
          getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefPlanTypesQuery>)?.data?.refPlanTypes ?? [],
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.description',
          field: 'description',
          fieldType: EFieldType.text,
          hasError: hasFieldError('description'),
          isRequired: true,
          className: 'flex w-full',
          initialValue: plan!.description,
          value: editPlan.description,
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.startDate',
          field: 'startDate',
          fieldType: EFieldType.date,
          initialValue: plan.startDate,
          value: editPlan.startDate,
        },
        {
          label: 'label.revision',
          field: 'revision',
          fieldType: EFieldType.text,
          hasError: hasFieldError('revision'),
          className: 'flex w-full',
          initialValue: plan!.revision,
          value: editPlan.revision,
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.REVISION,
          },
        },
        {
          label: 'label.strategy',
          field: 'strategyId',
          initialValue: renderCodeAndDescription({ code: plan!.strategy?.strategy, description: plan!.strategy?.description } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({ code: editPlan!.strategy?.strategy, description: editPlan!.strategy?.description } as TMaybeCodeDescriptionDatas),
          foreignObject: editPlan.strategy,
          foreignField: 'strategy',
          className: 'flex w-full',
          dbValue: plan!.strategyId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: refPlanStrategyResult,
          getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefPlanStrategiesQuery>)?.data?.refPlanStrategies ?? [],
          renderMenuItemLabel: field => renderCodeAndDescription({ code: (field as RefPlanStrategy)?.strategy, description: (field as RefPlanStrategy)?.description } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
      ],
      fieldsClassName: 'w-full flex flex-row flex-wrap',
    },
    {
      title: 'label.responsabilities',
      fields: [
        {
          label: 'label.plannerGroup',
          field: 'plannerGroupId',
          initialValue: renderCodeAndDescription(plan!.plannerGroup as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription(editPlan!.plannerGroup as TMaybeCodeDescriptionDatas),
          foreignObject: editPlan.plannerGroup,
          foreignField: 'plannerGroup',
          dbValue: plan!.plannerGroupId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogResult,
          getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs?.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_GROUP) ?? [],
          renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.mainWorkCenter',
          field: 'mainWorkCenterId',
          initialValue: renderCodeAndDescription(plan!.mainWorkCenter as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription(editPlan!.mainWorkCenter as TMaybeCodeDescriptionDatas),
          foreignObject: editPlan.mainWorkCenter,
          foreignField: 'mainWorkCenter',
          dbValue: plan!.mainWorkCenterId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogResult,
          getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs?.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.MAIN_WORK_CENTER) ?? [],
          renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
      ],
    },
  ]

  const noteFieldBlocks: TFieldsBlock[] = [
    {
      title: '',
      fields: [
        {
          label: '',
          labelClassName: 'hidden',
          field: 'notes',
          fieldType: EFieldType.text,
          hasError: hasFieldError('notes'),
          isRequired: false,
          initialValue: plan!.notes,
          value: editPlan.notes,
          inputComponent: 'textarea',
          inputProps: {
            className: 'flex border-0 w-full rounded-md bg-transparent px-3 py-1 text-sm outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 transition-all hover:bg-muted focus-visible:shadow focus-visible:ring-1 focus-visible:ring-muted disabled:hover:bg-transparent cursor-pointer focus:cursor-auto',
            maxLength: MAX_LENGTH_VALIDATORS.NOTES,
            rows: 8,
          } as TextareaHTMLAttributes<HTMLTextAreaElement>,
        },
      ],
    },
  ]

  return (
    <div className="gap-2 w-full flex-col flex">
      <RadForm {...form}>
        <form
          ref={htmlForm}
          onSubmit={form.handleSubmit(handleFormSubmitSucess)}
        >
          <FormFieldsBlock isFormContext objectType="plan" objectStatus={plan.status} className="text-gray-700" fieldsBlocks={fieldBlocks} onFieldChange={handleFieldChange} onFieldBlur={handleFieldBlur} />
          <RadSeparator className="my-4" />
          <FormGroupHeader>{t('label.notes')}</FormGroupHeader>
          <FormFieldsBlock
            objectType="plan"
            objectStatus={plan.status}
            blockTitleClassName="hidden"
            className="text-gray-700"
            fieldsBlocks={noteFieldBlocks}
            onFieldChange={handleFieldChange}
            onFieldBlur={handleFieldBlur}
          />
          {flocSelectionModalDisplayed && <FlocSelectionModal open hasItems queryResult={flocsResult} items={flocsResult.data?.functionalLocations ?? []} isMultiple={false} onValidate={handleSelectFloc} onClose={() => changeFlocSelectionModalDisplay(false)} /> }
        </form>
      </RadForm>
    </div>
  )
}
