import SingleFormModal from '@app/components/Modal/SingleFormModal'
import { InspectionPoint, CreateInspectionPointMutation, Event, FunctionalLocation } from '@app/graphql/__types__/graphql'
import { RadForm } from '@holis/react-ui/rad'
import { t } from 'i18next'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import Footer from './components/Footer'
import Form from './components/Form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import useInspectionPointStore, { ZOD_INSP_DATAS } from '@app/stores/insp'
import { useMutation } from '@apollo/client'
import { INSPECTION_POINTS_CREATE } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'

type TInspectionPointCreateModal = Readonly<{
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onCreated: (insp: InspectionPoint) => void
  item: Partial<InspectionPoint>
  event?: Partial<Event>
  floc?: Partial<FunctionalLocation>
}>

export function InspectionPointCreateModal(props: TInspectionPointCreateModal) {
  const { updateDataField, updateData, updateInspectionPoint, setActiveInspectionPoint } = useInspectionPointStore()
  const [createInspectionPointApi] = useMutation<CreateInspectionPointMutation>(INSPECTION_POINTS_CREATE)
  const floc = props.item.functionalLocation

  const htmlForm = useRef<HTMLFormElement>(null)
  const inspValidationDatas = ZOD_INSP_DATAS(t)
  type validationFieldKeys = keyof typeof inspValidationDatas
  const zodFormObject = z.object(inspValidationDatas)
  const form = useForm<z.infer<typeof zodFormObject>>(
    { resolver: zodResolver(zodFormObject), mode: 'onSubmit' })

  const handleCreateInspectionPoint = async () => {
    try {
      const newInspectionPointResult = await createInspectionPointApi({
        variables: {
          data: updateData,
        },
      })
      const newInspectionPoint = newInspectionPointResult.data?.createOneInspectionPoint as InspectionPoint
      updateInspectionPoint(newInspectionPoint)
      setActiveInspectionPoint()
      AppNotifications.success(t('message.success.inspCreated'))
      props.onCreated?.(newInspectionPoint)
    } catch {
      AppNotifications.error(t('message.error.default.title'))
    }
  }

  form.watch((datas, { name, type }) => {
    if (type === 'change') {
      updateDataField(name!, datas[name!])
    }
  })

  const setFormFieldValue = (name: string, value: unknown) => {
    form.clearErrors(name as validationFieldKeys)
    form.setValue(name as validationFieldKeys, value as string)
    updateDataField(name, value)
  }

  return (
    <SingleFormModal
      isOpen={props.isOpen}
      title={t('label.inspCreation')}
      description={t('label.itemCreateDescription')}
      footer={<Footer onSaveClick={() => htmlForm.current?.requestSubmit()} />}
      containerClassName="max-w-[1134px] w-full"
      onOpenChange={props.onOpenChange}
    >
      <RadForm {...form}>
        <form ref={htmlForm} onSubmit={form.handleSubmit(handleCreateInspectionPoint, data => console.log('form is invalid', data))}>
          <Form setFormValue={setFormFieldValue} floc={floc} />
        </form>
      </RadForm>
    </SingleFormModal>
  )
}
