import { gql } from '@apollo/client'
import { MEASPOINT_REF_VAL_CODES_COMMON_FIELDS } from './refMeasPointValCodes'

export const WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS = gql`
  ${MEASPOINT_REF_VAL_CODES_COMMON_FIELDS}
  fragment EventInspectionPointCommonFields on EventInspectionPoint {
    id
    inspId
    wrkoId
    notes
    qualReadingId
    quantReading
    qualReading{
      ...RefMeasPointValCodeCommonFields
    }
    reader
    reportingDate
  }
`
