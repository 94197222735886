import React from 'react'
import { Plan } from '@app/graphql/__types__/graphql'
import FlocBlock from './FlocBlock'

type TObjectList = Readonly<{
  plan: Partial<Plan>
  filterString?: string
}>

export default function ObjectList({ plan, filterString }: TObjectList) {
  return (
    <div className="flex flex-col gap-2 pl-2 py-2 w-full">
      <FlocBlock plan={plan} filterString={filterString} />
    </div>
  )
}
