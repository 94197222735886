import { EDamageFilter, EIanLeftSideTab, EIanRightSideTab, ENOTIFICATION_NOTIF_STATUS } from '@app/utils/enums'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions, OperationVariables } from '@apollo/client'
import { Notification, NotificationUncheckedCreateInput, NotificationUncheckedUpdateInput, InspectionDrawing, CreateNotificationWorkflowMutation, RefNotifAdditionalData, NotificationDamage, Damage, Attachment, EventDamage } from '@app/graphql/__types__/graphql'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { convertDateDefaultStrToDateObj } from '@app/utils/functions/dates'
import { TFormAction, TFormState, TObjectDrawingViewerAction, TObjectDrawingViewerState, TRenderAutocompleteProps } from '@app/types/app'
import { generateCommonFormStore, generateCommonObjectDrawingStore } from '@app/utils/functions/stores'
import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'
import { TFunction } from 'i18next'
import { handleFormInputKeydown, setObjValueByPath } from '@app/utils/functions'
import React, { ReactNode } from 'react'
import _ from 'lodash'
import AppAutocomplete from '@app/components/Common/Form/Autocomplete'

type State = TFormState<Partial<Notification>> & TObjectDrawingViewerState & {
  damageSelectionModalOpen: boolean
  notificationFieldErrors: Record<string, boolean>
  deleteNotificationModalOpen: boolean
  editNotification?: Partial<Notification> | null
  updateNotificationData?: NotificationUncheckedUpdateInput | NotificationUncheckedCreateInput
  activeNotification?: Partial<Notification>
  activeInspectionDrawing?: Partial<InspectionDrawing>
  fetchNotifications?: () => Promise<Partial<Notification>>
  createNotificationWorkflowFunc?: (options?: MutationFunctionOptions<CreateNotificationWorkflowMutation, OperationVariables, DefaultContext, ApolloCache<unknown>> | undefined) => Promise<FetchResult<CreateNotificationWorkflowMutation>>
  workflowModalDisplayed: boolean
  leftSideTab: EIanLeftSideTab
  objectGroup?: Partial<RefNotifAdditionalData> | null
  causeGroup?: Partial<RefNotifAdditionalData> | null
  damageGroup?: Partial<RefNotifAdditionalData> | null
  detectionGroup?: Partial<RefNotifAdditionalData> | null
  notificationFlocDrawings?: Partial<InspectionDrawing>[]
  notificationDamages?: Partial<NotificationDamage>[]
  selectedDamages?: Partial<Damage>[]
  fetchNotificationDamages?: () => void
  flocDamages?: Partial<Damage>[]
  fetchNotificationFlocDamages?: () => void
  filteredNotificationFlocDamages?: Partial<Damage>[]
  attachments?: Partial<Attachment>[]
  submitRequested: boolean
  notificationDrawings?: Partial<InspectionDrawing>[]
  damageDrawingIds?: number[]
  eventDamages?: Partial<EventDamage>[]
  latestFlocEventDamages?: Record<number, Partial<EventDamage> | null>
}

const initialState: State = {
  damageSelectionModalOpen: false,
  deleteNotificationModalOpen: false,
  editNotification: undefined,
  updateNotificationData: {},
  notificationFieldErrors: {},
  activeNotification: undefined,
  activeInspectionDrawing: undefined,
  workflowModalDisplayed: false,
  leftSideTab: EIanLeftSideTab.HEADER,
  rightSideTabSelectedValue: EIanRightSideTab.DRAWINGS,
  autoSave: false,
  isModal: true,
  itemToDelete: undefined,
  objectGroup: undefined,
  causeGroup: undefined,
  damageGroup: undefined,
  fetchNotificationDamages: undefined,
  detectionGroup: undefined,
  notificationFlocDrawings: undefined,
  fetchObjectDrawings: undefined,
  notificationDamages: undefined,
  fetchNotifications: undefined,
  damageSearchInput: '',
  damageActiveFilter: [EDamageFilter.ACTIVE],
  flocDamages: undefined,
  selectedDamages: undefined,
  fetchNotificationFlocDamages: undefined,
  filteredNotificationFlocDamages: undefined,
  attachments: undefined,
  submitRequested: false,
  damageSelectionModalDisplayed: false,
  notificationDrawings: undefined,
  damageDrawingIds: undefined,
  eventDamages: undefined,
  lastDamageUpdated: undefined,
  lastDamageUpdatedAt: undefined,
  latestFlocEventDamages: undefined,
}

export const MAX_LENGTH_VALIDATORS = {
  DESCRIPTION: 50,
  LONG_DESCRIPTION: 5000,
  NOTIF: 45,
  EXTERNAL_ID: 45,
  EXTERNAL_STATUS: 100,
  REPORTED_BY: 45,
  DAMAGE_TEXT: 40,
  CAUSE_TEXT: 40,
}

export const ZOD_NOTIFICATION_DATAS = (t: TFunction) => ({

  description: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.DESCRIPTION).min(1, { message: t('message.error.form.required') }),

  typeId: z.number({ required_error: t('message.error.form.required') }),

  flocId: z.number({ required_error: t('message.error.form.required') }),
  plantWorkCenterId: z.optional(z.unknown()),
  requiredEndDate: z.coerce.date().optional(),
  requiredStartDate: z.coerce.date().optional(),
})

type Actions = TFormAction<Partial<Notification>> & TObjectDrawingViewerAction & {
  changeDamageSelectionNewModalDisplay: (damageSelectionModalOpen: boolean) => void
  updateNotification: (notification: Partial<Notification>, isNew?: boolean) => void
  deleteNotification: (notification?: Partial<Notification>) => void
  setUpdateNotificationData: (updateNotificationData: NotificationUncheckedUpdateInput | NotificationUncheckedCreateInput) => void
  setEditNotification: (notification?: Partial<Notification> | null) => void
  updateNotificationDataField: (field: string, value: unknown) => void
  setActiveNotification: (activeNotification?: Partial<Notification>, damage?: Partial<Damage>) => void
  changeDeleteNotificationModalDisplay: (isOpen: boolean) => void
  isSaved: () => boolean
  hasError: () => boolean
  hasFieldError: (field: string, forceCheck?: boolean) => boolean
  cancelEditData: () => void
  setFetchNotification: (fetchNotifications?: () => Promise<Partial<Notification>>) => void
  setCreateNotificationWorkflowFunc: (createNotificationWorkflowFunc?: (options?: MutationFunctionOptions<CreateNotificationWorkflowMutation, OperationVariables, DefaultContext, ApolloCache<unknown>> | undefined) => Promise<FetchResult<CreateNotificationWorkflowMutation>>) => void
  changeWorkflowModalDisplay: (workflowModalDisplayed: boolean) => void
  updateNotificationFieldError: (field: string, value: boolean) => void
  changeLeftSideTab: (leftSideTab: EIanLeftSideTab) => void
  changeRightSideTab: (rightSideTabSelectedValue: string) => void
  updateNotificationState: (newData: Partial<Notification>, actionDate: Date, isNew?: boolean) => void
  setObjectGroup: (objectGroup?: Partial<RefNotifAdditionalData>) => void
  setDamageGroup: (damageGroup?: Partial<RefNotifAdditionalData>) => void
  setCauseGroup: (causeGroup?: Partial<RefNotifAdditionalData>) => void
  setDetectionGroup: (detectionGroup?: Partial<RefNotifAdditionalData>) => void
  setNotificationFlocDrawings: (notificationFlocDrawings?: Partial<InspectionDrawing>[]) => void
  setNotificationDamages: (notificationDamages?: Partial<NotificationDamage>[]) => void
  setFetchNotificationDamages: (fetchNotificationDamages?: () => void) => void
  setNotificationFlocDamages: (flocDamages?: Partial<Damage>[]) => void
  setFetchNotificationFlocDamages: (fetchNotificationFlocDamages?: () => void) => void
  setFilteredNotificationFlocDamages: (filteredNotificationFlocDamages?: Partial<Damage>[]) => void
  setAttachments: (attachments?: Partial<Attachment>[]) => void
  setSelectedDamages: (selectedDamages?: Partial<Damage>[]) => void
  setSubmitRequested: (submitRequested: boolean) => void
  handleFieldChange: (field: string, value: unknown, update?: boolean) => void
  renderAutocomplete: (props: TRenderAutocompleteProps) => ReactNode
  setNotificationDrawings: (notificationDrawings?: Partial<InspectionDrawing>[]) => void
  setDamageDrawingIds: (damageDrawingIds?: number[]) => void
  setEventDamages: (eventDamages?: Partial<EventDamage>[]) => void
  addEventDamage: (eventDamage: Partial<EventDamage>, override?: boolean) => void
  setLatestFlocEventDamages: (latestFlocEventDamages?: Record<number, Partial<EventDamage> | null>) => void
}

export type NotificationState = State & Actions

const useNotificationStore = create<NotificationState>()(
  immer((set, get) => ({
    ...initialState,
    ...generateCommonFormStore(set),
    ...generateCommonObjectDrawingStore(set),
    addEventDamage(eventDamage, override) {
      let toUpdate = false
      const eventDamages = [...(get().eventDamages ?? [])]
      const idx = eventDamages?.findIndex(item => item.dmgeId === eventDamage.dmgeId)
      if (typeof idx === 'number' && idx >= 0) {
        if (override !== false) {
          eventDamages![idx] = eventDamage
          toUpdate = true
        }
      } else {
        eventDamages!.push(eventDamage)
        toUpdate = true
      }

      if (toUpdate) {
        set({
          eventDamages: [...eventDamages],
        })
      }
    },
    setEventDamages(eventDamages) {
      set({ eventDamages })
    },
    setDamageDrawingIds(damageDrawingIds) {
      set({ damageDrawingIds })
    },
    setNotificationDrawings(notificationDrawings) {
      set({ notificationDrawings })
    },
    changeDamageSelectionNewModalDisplay(damageSelectionModalOpen) {
      set({ damageSelectionModalOpen })
    },
    renderAutocomplete(props) {
      const { fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps, isDisabled } = props ?? {}
      const { updateNotificationData, editNotification, setDetectionGroup, setEditNotification, updateNotificationDataField, handleFieldChange, setSubmitRequested, setObjectGroup, setCauseGroup, setDamageGroup } = get()
      return (
        <AppAutocomplete
          onSelect={(item: Record<string, unknown> | null) => {
            if ((updateNotificationData && Object.keys(updateNotificationData).includes(field!)) || (!(updateNotificationData && Object.keys(updateNotificationData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
              if (!item && typeof renderMenuItemLabel!(item) === 'string') {
                setInputValue?.(renderMenuItemLabel!(item) as string)
              }

              const editedNotification = _.cloneDeep(editNotification)
              if (foreignField) {
                setObjValueByPath(editedNotification!, foreignField!, item)
              }

              if (field) {
                setObjValueByPath(editedNotification!, field!, item?.id ?? null)
                if (field === 'detectionId') {
                  setDetectionGroup(item ? { codeGroup: (item as Partial<RefNotifAdditionalData>).codeGroup, shortText: (item as Partial<RefNotifAdditionalData>).shortText } : undefined)
                } else if (field === 'objectId') {
                  setObjectGroup(item ? { codeGroup: (item as Partial<RefNotifAdditionalData>).codeGroup, shortText: (item as Partial<RefNotifAdditionalData>).shortText } : undefined)
                } else if (field === 'causeId') {
                  setCauseGroup(item ? { codeGroup: (item as Partial<RefNotifAdditionalData>).codeGroup, shortText: (item as Partial<RefNotifAdditionalData>).shortText } : undefined)
                } else if (field === 'damageId') {
                  setDamageGroup(item ? { codeGroup: (item as Partial<RefNotifAdditionalData>).codeGroup, shortText: (item as Partial<RefNotifAdditionalData>).shortText } : undefined)
                }
              }

              setEditNotification(editedNotification)
              updateNotificationDataField(field!, item?.id ?? null)
              setSubmitRequested(true)
            }
          }}
          {...props}
          inputProps={{
            ...inputProps,
            disabled: isDisabled || inputProps?.disabled,
            onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
          }}
        />
      )
    },
    handleFieldChange(field: string, value: unknown, update?: boolean) {
      const { setEditNotification, updateNotificationDataField, editNotification, setSubmitRequested } = get()
      const editedNotification = { ...editNotification }
      setObjValueByPath(editedNotification, field, value)
      setEditNotification(editedNotification)
      if (update) {
        updateNotificationDataField(field, value)
        if (typeof value === 'boolean') {
          setSubmitRequested(true)
        }
      }
    },
    setSubmitRequested(submitRequested) {
      set({ submitRequested })
    },
    setSelectedDamages(selectedDamages) {
      set({ selectedDamages })
    },
    setAttachments(attachments) {
      set({ attachments })
    },
    setFilteredNotificationFlocDamages(filteredNotificationFlocDamages) {
      set({ filteredNotificationFlocDamages })
    },
    setFetchNotificationFlocDamages(fetchNotificationFlocDamages) {
      set({ fetchNotificationFlocDamages })
    },
    setNotificationFlocDamages(flocDamages) {
      set({ flocDamages })
    },
    setFetchNotificationDamages(fetchNotificationDamages) {
      set({ fetchNotificationDamages })
    },
    setNotificationDamages(notificationDamages) {
      set({ notificationDamages })
    },
    prevSelectedDrawing() {
      const { notificationFlocDrawings, selectedDrawing } = get()
      if (selectedDrawing && notificationFlocDrawings?.length) {
        const currentIndex = notificationFlocDrawings.findIndex((item: Partial<InspectionDrawing>) => item.id === selectedDrawing.id)
        if (currentIndex >= 0) {
          if (currentIndex !== 0) {
            set({ selectedDrawing: notificationFlocDrawings[currentIndex - 1] })
          } else {
            set({ selectedDrawing: notificationFlocDrawings[notificationFlocDrawings.length - 1] })
          }
        }
      }
    },
    nextSelectedDrawing() {
      const { notificationFlocDrawings, selectedDrawing } = get()
      if (selectedDrawing && notificationFlocDrawings?.length) {
        const currentIndex = notificationFlocDrawings.findIndex((item: Partial<InspectionDrawing>) => item.id === selectedDrawing.id)
        if (currentIndex >= 0) {
          if (currentIndex === notificationFlocDrawings.length - 1) {
            set({ selectedDrawing: notificationFlocDrawings[0] })
          } else {
            set({ selectedDrawing: notificationFlocDrawings[currentIndex + 1] })
          }
        }
      }
    },
    setNotificationFlocDrawings(notificationFlocDrawings) {
      set({ notificationFlocDrawings })
    },
    setObjectGroup(objectGroup) {
      const { editNotification, updateNotificationData } = get()
      set({
        objectGroup,
        editNotification: {
          ...editNotification,
          object: null,
          objectId: null,
        },
        updateNotificationData: editNotification?.id !== OBJ_NEW_ID
          ? updateNotificationData
          : {
              ...updateNotificationData,
              objectId: undefined,
            },
      })
    },
    setCauseGroup(causeGroup) {
      const { editNotification, updateNotificationData } = get()
      set({ causeGroup,
        editNotification: {
          ...editNotification,
          cause: null,
          causeId: null,
        },
        updateNotificationData: editNotification?.id !== OBJ_NEW_ID
          ? updateNotificationData
          : {
              ...updateNotificationData,
              causeId: undefined,
            },
      })
    },
    setDamageGroup(damageGroup) {
      const { editNotification, updateNotificationData } = get()
      set({ damageGroup,
        editNotification: {
          ...editNotification,
          damage: null,
          damageId: null,
        },
        updateNotificationData: editNotification?.id !== OBJ_NEW_ID
          ? updateNotificationData
          : {
              ...updateNotificationData,
              damageId: undefined,
            },
      })
    },
    setDetectionGroup(detectionGroup) {
      const { editNotification, updateNotificationData } = get()
      set({ detectionGroup,
        editNotification: {
          ...editNotification,
          detection: null,
          detectionId: null,
        },
        updateNotificationData: editNotification?.id !== OBJ_NEW_ID
          ? updateNotificationData
          : {
              ...updateNotificationData,
              detectionId: undefined,
            } })
    },
    updateNotificationState(newData: Partial<Notification>, actionDate: Date, isNew?: boolean) {
      const { editNotification, updateNotification, setUpdateNotificationData } = get()
      const newNotification = {
        ...editNotification,
        ...newData,
      } as Partial<Notification>

      updateNotification(newNotification, isNew)
      return setUpdateNotificationData({})
    },
    changeRightSideTab(rightSideTabSelectedValue) {
      set({ rightSideTabSelectedValue })
    },
    changeLeftSideTab(leftSideTab) {
      set({ leftSideTab })
    },
    updateNotificationFieldError: (field: string, value: boolean) => set((state) => {
      state.notificationFieldErrors = {
        ...state.notificationFieldErrors,
        [field]: value,
      }
    }),
    changeWorkflowModalDisplay(workflowModalDisplayed) {
      set({ workflowModalDisplayed })
    },
    setCreateNotificationWorkflowFunc(createNotificationWorkflowFunc) {
      set({ createNotificationWorkflowFunc })
    },
    setFetchNotification(fetchNotifications) {
      set({ fetchNotifications })
    },
    cancelEditData: () => set((state) => {
      const newState: Partial<State> = {
        updateNotificationData: {},
        notificationFieldErrors: {},
        editNotification: { ...state.activeNotification },
      }
      if (state.activeNotification?.id === OBJ_NEW_ID) {
        newState.activeNotification = undefined
        newState.editNotification = undefined
      }

      return newState
    }),
    resetData() {
      set({
        ...initialState,
      })
    },
    isSaved() {
      const state = get()
      if (state.activeNotification) {
        return !(state.updateNotificationData && Object.keys(state.updateNotificationData).length > 0)
      }

      return true
    },

    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get()
      if (state.activeNotification) {
        if (!Object.keys(state.updateNotificationData ?? {}).includes(field) && !forceCheck) {
          return false
        }

        if (state.notificationFieldErrors?.[field] === true) {
          return true
        }

        const { editNotification } = state

        switch (field) {
          case 'notif':
          { const notification = (editNotification?.notif ?? '').trim()
            return notification.length > MAX_LENGTH_VALIDATORS.NOTIF || notification === '' }
          case 'longDescription':
          { const longDescription = (editNotification?.longDescription ?? '').trim()
            return longDescription.length > MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION }
          case 'description':
          { const description = (editNotification?.description ?? '').trim()
            return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '' }
          case 'causeText':
          { const causeText = (editNotification?.causeText ?? '').trim()
            return causeText.length > MAX_LENGTH_VALIDATORS.CAUSE_TEXT }
          case 'damageText':
          { const damageText = (editNotification?.damageText ?? '').trim()
            return damageText.length > MAX_LENGTH_VALIDATORS.DAMAGE_TEXT }
          case 'flocId':
          case 'typeId':
          { const id = editNotification?.[field]
            return !id }
          default:
            break
        }
      }

      return false
    },
    hasError() {
      const state = get()
      if (state.activeNotification) {
        return ['description', 'notif', 'longDescription', 'flocId', 'typeId', 'damageText'].some((field: string) => state.hasFieldError(field, true))
      }

      return false
    },
    setActiveNotification(activeNotification, damage?: Partial<Damage>) {
      set({
        activeNotification,
        editNotification: activeNotification ? { ...activeNotification } : undefined,
        updateNotificationData:
          activeNotification?.id === OBJ_NEW_ID
            ? {
                notif: uuidv4().toString(),
                status: ENOTIFICATION_NOTIF_STATUS.INIT,
                creationDate: activeNotification.creationDate ?? new Date(),
                flocId: activeNotification.flocId,
                origin: activeNotification.origin,
                damageId: activeNotification.damageId } as NotificationUncheckedCreateInput
            : undefined,
        notificationFieldErrors: undefined,
        objectGroup: activeNotification?.object,
        causeGroup: activeNotification?.cause,
        damageGroup: activeNotification?.damage,
        detectionGroup: activeNotification?.detection,
        selectedDamages: damage ? [damage] : undefined,
      })
    },
    updateNotificationDataField: (field: string, value: unknown) => set((state) => {
      if (field.endsWith('Date')) {
        if (value) {
          if (typeof value === 'string') {
            value = convertDateDefaultStrToDateObj(value)
          }
        } else {
          value = null
        }
      }

      return {
        updateNotificationData: {
          ...state.updateNotificationData,
          [field]: state.activeNotification?.id === OBJ_NEW_ID
            ? value
            : {
                set: value,
              },
        },
        notificationFieldErrors: {
          ...state.notificationFieldErrors,
          [field]: false,
        },
      }
    }),
    setUpdateNotificationData(updateNotificationData: NotificationUncheckedUpdateInput | NotificationUncheckedCreateInput) {
      set({ updateNotificationData })
    },
    setEditNotification(editNotification) {
      set({ editNotification })
    },
    updateNotification: (notification: Partial<Notification>, isNew?: boolean) => set(() => {
      const { activeNotification } = get() ?? {}
      const notificationId: number = isNew ? OBJ_NEW_ID : notification.id!
      const newState: Partial<State> = {}

      if (activeNotification && notificationId === activeNotification.id!) {
        newState.activeNotification = {
          ...activeNotification,
          ...notification,
        }
        newState.editNotification = { ...newState.activeNotification }
      }

      return newState
    }),
    deleteNotification: (notification?: Partial<Notification>) => set((state) => {
      const deletedNotification: Partial<Notification> | undefined | null = notification ?? state.activeNotification
      const newState: Partial<State> = {}
      if (deletedNotification) {
        newState.deleteNotificationModalOpen = false
        if (deletedNotification === state.activeNotification) {
          newState.activeNotification = undefined
        }
      }

      return newState
    }),
    changeDeleteNotificationModalDisplay: (isOpen: boolean) => set({
      deleteNotificationModalOpen: isOpen,
    }),
    setLatestFlocEventDamages(latestFlocEventDamages) {
      set({ latestFlocEventDamages })
    },
  })),
)

export default useNotificationStore
