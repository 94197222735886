import { Event, EventInspectionPoint, GetLatestEventInspectionPointByInspIdQuery, InspectionPoint, Picture, UpdateEventInspectionPointByIdMutation, UpdateInspectionPointByIdMutation } from '@app/graphql/__types__/graphql'
import MultiplePanelsModal, { TMultiplePanelsModal } from '@app/components/Modal/NewMultiplePanels'
import { TCarouselApi } from '@holis/react-ui'
import React, { useEffect, useRef, useState } from 'react'
import Header from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/Header'
import Footer from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/Footer'
import PictureCarousel from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/pictures/PictureCarousel'
import EventInspectionPointBlock from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/events/EventInspectionPointsBlock'
import GeneralInfo from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/GeneralInfo'
import Actions from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/Actions'
import useInspectionPointStore from '@app/stores/insp'
import DeleteInspectionPointModal from './components/DeleteInspectionPointModal'
import { EApiOperator } from '@app/utils/enums'
import { OBJ_NEW_ID } from '@app/utils/constants'
import MeasurementBlock from './components/measurement'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
import { useLazyQuery, useMutation } from '@apollo/client'
import { INSPECTION_POINTS_UPDATE_BY_ID, WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID, WORKORDER_EVENT_INSPECTION_POINTS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useTranslation } from 'react-i18next'
import { useHolisAuth } from '@holis/auth-client-react'
import ClassPositionItemSelector from '@app/components/Common/ClassPositionItemSelector'
type TInspectionPointModal = TMultiplePanelsModal<Partial<InspectionPoint>> & Readonly<{
  event?: Partial<Event>
  eventInspectionPoint?: Partial<EventInspectionPoint>
  onCreatedOrUpdated?: (inspectionPoint?: Partial<InspectionPoint>, operator?: EApiOperator) => void
  onPicturesChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void
  allItems?: Partial<EventInspectionPoint | InspectionPoint>[]
}>

export default function InspectionPointModal({ item, event, onOpenChange, onCreatedOrUpdated, onPicturesChanged, eventInspectionPoint, allItems, ...restProps }: TInspectionPointModal) {
  const isNew = !item.id || item.id === OBJ_NEW_ID
  const { t } = useTranslation()
  const prms = useUserPermissions()
  const { user } = useHolisAuth()
  const [setCurrentDateAndUser, setSetCurrentDateAndUser] = useState<boolean>(false)
  const { deleteInspectionPointModalOpen, resetData, changeDeleteInspectionPointModalDisplay, editInspectionPoint, setActiveInspectionPoint } = useInspectionPointStore()
  const datasToSaveGi = useRef<Partial<InspectionPoint>>({})
  const datasToSaveMeas = useRef<Partial<EventInspectionPoint>>({})
  const [isModalOpened, setIsModalOpened] = useState(true)
  const [updateInspectionPointByIdApi] = useMutation<UpdateInspectionPointByIdMutation>(INSPECTION_POINTS_UPDATE_BY_ID)
  const [updateEventInspByIdApi] = useMutation<UpdateEventInspectionPointByIdMutation>(WORKORDER_EVENT_INSPECTION_POINTS_UPDATE_BY_ID)
  const [getLatestEventInspApi] = useLazyQuery<GetLatestEventInspectionPointByInspIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID)

  const [pictureCarouselApi, setPictureCarouselApi] = useState<TCarouselApi>()
  const [allItemsFetched, setAllItemsFetched] = useState<Partial<EventInspectionPoint>[]>()
  const [editedInspectionPoint, setEditedInspectionPoint] = useState<Partial<InspectionPoint>>(item)
  const [editedEventInspectionPoint, setEditedEventInspectionPoint] = useState<Partial<EventInspectionPoint | undefined>>(eventInspectionPoint)

  const handlePictureCarouselRender = (api: TCarouselApi) => {
    setPictureCarouselApi(api)
  }

  const handleRightPanelResize = () => {
    pictureCarouselApi?.calculatePagination()
  }

  const handleCloseModal = () => {
    resetData()

    onOpenChange?.(false)
    restProps.onClose?.()
  }

  const handleInspDeleted = () => {
    handleCloseModal()
    onCreatedOrUpdated?.(item, EApiOperator.DELETE)
  }

  const handleFieldUpdated = (type: 'generalInfos' | 'measurement', field: string, value?: unknown) => {
    if ((value as { id: string })?.id) {
      value = (value as { id: string }).id
    }

    if (field === 'qualReadingId') {
      setSetCurrentDateAndUser(true)
    }

    if (type === 'generalInfos') {
      datasToSaveGi.current = {
        ...datasToSaveGi.current,
        [field]: value,
      }
    }

    if (type === 'measurement') {
      datasToSaveMeas.current = {
        ...datasToSaveMeas.current,
        [field]: value,
      }
    }
  }

  const handleSave = async () => {
    const updateInspRequest: Record<string, unknown> = {}
    const updateEvtInspRequest: Record<string, unknown> = {}
    Object.keys(datasToSaveGi.current).forEach((field) => {
      updateInspRequest[field] = { set: datasToSaveGi.current[field as keyof InspectionPoint] ?? null }
    })
    Object.keys(datasToSaveMeas.current).forEach((field) => {
      updateEvtInspRequest[field] = { set: datasToSaveMeas.current[field as keyof EventInspectionPoint] ?? null }
    })

    try {
      if (Object.keys(datasToSaveGi.current).length) {
        const r = await updateInspectionPointByIdApi({ variables: { id: item.id, data: updateInspRequest } })
        setEditedInspectionPoint(r.data?.updateOneInspectionPoint as Partial<InspectionPoint>)
      }

      if (Object.keys(datasToSaveMeas.current).length) {
        updateEvtInspRequest.reader = { set: user?.username }
        updateEvtInspRequest.reportingDate = { set: new Date() }
        const r = await updateEventInspByIdApi({ variables: { id: eventInspectionPoint!.id, data: updateEvtInspRequest } })
        setEditedEventInspectionPoint(r.data?.updateOneEventInspectionPoint as Partial<EventInspectionPoint>)
      }

      datasToSaveGi.current = {}
      datasToSaveMeas.current = {}

      onCreatedOrUpdated?.(item, isNew ? EApiOperator.CREATE : EApiOperator.UPDATE)
      AppNotifications.success(t('message.success.inspectionPointUpdated'))
    } catch {
      AppNotifications.error(t('message.error.default.title'))
    }
  }

  const handleBlur = () => {
    // Save if any data has changed
    for (const key in datasToSaveGi.current) {
      if (datasToSaveGi.current[key as keyof InspectionPoint] !== editedInspectionPoint[key as keyof InspectionPoint]) {
        handleSave()
        return
      }
    }

    for (const key in datasToSaveMeas.current) {
      if (datasToSaveMeas.current[key as keyof EventInspectionPoint] !== editedEventInspectionPoint?.[key as keyof EventInspectionPoint]) {
        handleSave()
        return
      }
    }
  }

  const fetchAllItemsEventInspectionPoints = async () => {
    const fetchedItems = []
    for (const e of allItems ?? []) {
      if (e.__typename === 'InspectionPoint') {
        const r = await getLatestEventInspApi({
          variables: {
            inspId: e.id!,
          },
        })
        if (r.data?.findFirstEventInspectionPoint) {
          fetchedItems.push(
            { ...r.data?.findFirstEventInspectionPoint, inspectionPoint: e } as Partial<EventInspectionPoint>)
        }
      } else {
        fetchedItems.push(e as Partial<EventInspectionPoint>)
      }
    }

    setAllItemsFetched(fetchedItems)
  }

  useEffect(() => {
    if (allItems) {
      fetchAllItemsEventInspectionPoints()
    }
  }, [allItems])

  useEffect(() => {
    setEditedInspectionPoint(item)
  }, [item])

  useEffect(() => {
    setEditedEventInspectionPoint(eventInspectionPoint)
    setSetCurrentDateAndUser(false)
  }, [eventInspectionPoint])

  const renderItemSelector = () => allItemsFetched && (
    <ClassPositionItemSelector<Partial<EventInspectionPoint>>
      items={
        allItemsFetched?.map(e => (
          {
            item: e,
            itemClass: e.inspectionPoint?.class?.class,
            itemPosition: e.inspectionPoint?.position,
            itemColor: e.qualReading?.integrityCondition?.color as string,
            id: e.id!,
          }))
      }
      selectedId={eventInspectionPoint?.id}
      onItemSelect={ip => setActiveInspectionPoint(ip.inspectionPoint)}
    />
  )

  return (
    <MultiplePanelsModal
      autoSaveId="modal-insp"
      header={<Header actions={isNew ? null : <Actions inspectionPoint={item} canDelete={prms.inspectionPoints.delete} onCreatedOrUpdated={onCreatedOrUpdated} />} inspectionPoint={item} />}
      footer={(
        <div className="flex flex-col w-full">
          {renderItemSelector()}
          <Footer inspectionPoint={item} onCancelClick={() => setIsModalOpened(false)} />
        </div>
      )}
      panelsOnResize={[undefined, handleRightPanelResize]}
      panelsDefaultSize={[45, 55]}
      panelsMinSize={[30, 55]}
      item={item}
      isSaved={() => true}
      onOpenChange={(opened) => {
        if (opened) {
          setIsModalOpened(true)
        } else {
          handleCloseModal()
        }
      }}
      {...restProps}
      isOpen={isModalOpened}
      onClose={handleCloseModal}
    >
      <GeneralInfo readonly={!prms.inspectionPoints.update} inspectionPoint={editedInspectionPoint} onFieldChange={(f, v) => handleFieldUpdated('generalInfos', f, v)} onFieldBlur={handleBlur} />

      <div className="flex flex-col w-full overflow-auto">
        {!!editedEventInspectionPoint && <MeasurementBlock readonly={!prms.inspectionPoints.measurement} event={event!} inspectionPoint={editedInspectionPoint!} eventInspectionPoint={editedEventInspectionPoint!} useCurrentDateAndUser={setCurrentDateAndUser} onFieldChange={(f, v) => handleFieldUpdated('measurement', f, v)} onFieldBlur={handleBlur} />}
        <PictureCarousel readonly={!prms.inspectionPoints.measurement && !prms.inspectionPoints.update} inspectionPoint={editedInspectionPoint} event={event} onCarouselRender={handlePictureCarouselRender} onChanged={onPicturesChanged} />
        <EventInspectionPointBlock event={event} inspectionPoint={editInspectionPoint!} />
        <DeleteInspectionPointModal open={deleteInspectionPointModalOpen} inspectionPoint={item} changeDeleteInspectionPointModalDisplay={changeDeleteInspectionPointModalDisplay} onInspectionPointDeleted={handleInspDeleted} />
      </div>
    </MultiplePanelsModal>
  )
}
