import { gql } from '@apollo/client'

export const FILE_DRAWINGS_COMMON_FIELDS = gql`
    fragment FileDrawingCommonFields on Drawing {
        id
        docName
        nativeExt
        createdAt
        updatedAt
        convError
        fsAdapter
        path
        pathSvg
        pathNative
    }
`
