import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { Damage, DeleteDamageByIdMutation } from '@app/graphql/__types__/graphql'
import { DAMAGES_DELETE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
type TDeleteDamageModal = IConfirmModal & Readonly<{
  damage: Partial<Damage>
  onDamageDeleted?: (damage?: Partial<Damage>) => void
  changeDeleteDamageModalDisplay: (isOpen: boolean) => void
}>

export default function DeleteDamageModal({ damage, onDamageDeleted, changeDeleteDamageModalDisplay, ...restProps }: TDeleteDamageModal) {
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteDamageApi, { data, loading, error }] = useMutation<DeleteDamageByIdMutation>(DAMAGES_DELETE_BY_ID)
  const { t } = useTranslation()
  useEffect(() => {

  }, [data, loading, error])
  const onConfirm = () => {
    startLoading()
    deleteDamageApi({
      variables: { id: damage!.id },
    }).then(() => {
      onDamageDeleted?.()
      AppNotifications.success(t('message.success.deleteDamage'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteDamage'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteDamage')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeleteDamageModalDisplay(false)}
      {...restProps}
    />
  )
}
