import { MENU_GROUP_COLORS } from '@app/utils/constants'
import Menu, { MENU_ITEMS } from '@components/Menu'
import { Breadcrumb, TagSelect, TListItem } from '@holis/react-ui'
import {
  RadDropdownMenu,
  RadDropdownMenuContent,
  RadDropdownMenuItem,
  RadDropdownMenuTrigger,
} from '@holis/react-ui/rad'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuChevronDown } from 'react-icons/lu'
import { Link, useLocation } from 'react-router-dom'
import { Topbar } from '../Common/TopBar'
import { useDataStore } from '@app/stores/data'
import { UserSite } from '@app/graphql/__types__/graphql'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'

interface ILayout extends React.PropsWithChildren {
  readonly projectName?: string
  readonly companyLogo?: string
}

type TCrumb = {
  id: string
  element: React.ReactNode
}

type TNavContext = React.Dispatch<React.SetStateAction<TCrumb[] | undefined>>

const NavContext = React.createContext<TNavContext>(
  () => {},
)

export default function Layout({ children, projectName, companyLogo }: ILayout) {
  const { t } = useTranslation()
  const prms = useUserPermissions()

  const { selectedSites, setSelectedSites, sites } = useDataStore()

  const [breadCrumbs, setBreadCrumbs] = useState<TCrumb[]>()

  const location = useLocation()

  const handleSiteSelect = (site: TListItem | null) => {
    if (site) {
      const idx = selectedSites!.indexOf((site as UserSite).site.site)
      if (idx > -1) {
        selectedSites!.splice(idx, 1)
        setSelectedSites([...selectedSites!])
      } else {
        setSelectedSites(
          [
            ...(selectedSites!),
            (site as UserSite).site.site,
          ],
        )
      }
    } else {
      setSelectedSites([])
    }
  }

  useEffect(() => {
    const shards = location.pathname.replace(/^\/|\/$/g, '').split('/')
    const groupId = location.pathname.split('/', 2).join('/')

    const bs: TCrumb[] = []
    const index = MENU_ITEMS.findIndex(item => item.id === groupId)

    let currentPath = '/'

    for (const [i, shard] of shards.entries()) {
      if (!shard) {
        continue
      }

      currentPath += shard + '/'
      if (bs.length === 0) {
        bs.push({
          id: `breadcrumbs-${shard}`,
          element: (
            MENU_ITEMS[index > -1 ? index : 0].children.length > 1
              ? (
                  <RadDropdownMenu>
                    <RadDropdownMenuTrigger className="flex items-center gap-2 hover:text-blue-500 transition-colors">
                      <span className={`size-5 p-0.5 rounded text-white ${MENU_GROUP_COLORS[index > -1 ? index : 0]}`}>
                        {MENU_ITEMS[index > -1 ? index : 0].icon}
                      </span>

                      {t('label.menu.' + shard, { defaultValue: decodeUrlShard(shard) })}

                      <LuChevronDown />
                    </RadDropdownMenuTrigger>

                    <RadDropdownMenuContent>
                      {MENU_ITEMS[index > -1 ? index : 0].children.filter(item => item.isAllowed?.(prms) ?? true).map(item => (
                        <RadDropdownMenuItem
                          key={`breadcrumbs-dropdown-${item.to}`}
                          asChild
                          disabled={!item.to || item.to === '#'}
                        >
                          <Link className="cursor-pointer" to={item.to ?? '#'}>
                            {item.title}
                          </Link>
                        </RadDropdownMenuItem>
                      ))}
                    </RadDropdownMenuContent>
                  </RadDropdownMenu>
                )
              : (
                  <div className="flex items-center gap-2">
                    <span className={`size-5 p-0.5 rounded text-white ${MENU_GROUP_COLORS[index > -1 ? index : 0]}`}>
                      {MENU_ITEMS[index > -1 ? index : 0].icon}
                    </span>

                    <span className="font-medium">
                      {t('label.menu.' + shard, { defaultValue: decodeUrlShard(shard) })}
                    </span>
                  </div>
                )
          ),
        })
      } else if (i === shards.length - 1) {
        bs.push({
          id: `breadcrumbs-${shard}`,
          element: (
            <span className="text-primary font-medium">
              {t('label.menu.' + shard, { defaultValue: decodeUrlShard(shard) })}
            </span>
          ),
        })
      } else {
        bs.push({
          id: `breadcrumbs-${shard}`,
          element: (
            <Link className="font-medium" to={currentPath}>
              {t('label.menu.' + shard, { defaultValue: decodeUrlShard(shard) })}
            </Link>
          ),
        })
      }
    }

    setBreadCrumbs(bs.length ? bs : undefined)
  }, [location.pathname])

  const decodeUrlShard = (shard: string) => {
    const shardWithSpaces = decodeURIComponent(shard.replaceAll('-', ' '))
    return shardWithSpaces.charAt(0).toUpperCase() + shardWithSpaces.slice(1)
  }

  return (
    <div className="flex flex-shrink-0 flex-row w-full relative shadow h-full z-50">
      <NavContext.Provider value={setBreadCrumbs}>
        <Menu projectName={projectName} companyLogo={companyLogo} />

        <div className="flex flex-col flex-grow overflow-hidden h-full">
          <Topbar>
            <div className="flex w-full items-center justify-between">
              <div className="flex-1">
                {breadCrumbs && (
                  <Breadcrumb separator="/">
                    {breadCrumbs.map(breadCrumb => (
                      <React.Fragment key={`${breadCrumb.id}`}>{breadCrumb.element}</React.Fragment>
                    ))}
                  </Breadcrumb>
                )}
              </div>
              {Array.isArray(sites) && sites.length > 0 && (
                <TagSelect
                  multiple
                  value={selectedSites ?? []}
                  items={sites.map(item => ({
                    ...item,
                    value: item.site?.site,
                    label: item.site?.site,
                  }))}
                  onSelect={handleSiteSelect}
                />
              )}
            </div>
          </Topbar>

          <div className="h-full flex-grow overflow-auto">
            {children}
          </div>
        </div>
      </NavContext.Provider>
    </div>
  )
}
