import { QueryResult, useLazyQuery, useMutation } from '@apollo/client'
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar'
import LeftSide from '@app/components/Modal/MultiplePanels/Documentation/idwg/LeftSide'
import DeleteIdwgModal from '@app/components/Modal/MultiplePanels/MethodEngineering/Inspection/components/DeleteIdwgModal'
import { Drawing, GetDrawingByNameQuery, GetInspectionDrawingDetailByNumberQuery, InspectionDrawing, UpdateInspectionDrawingByIdMutation } from '@app/graphql/__types__/graphql'
import { FILE_DRAWINGS_GET_BY_NAME, INSPECTION_DRAWINGS_GET_DETAIL_BY_NUMBER, INSPECTION_DRAWINGS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useDataStore } from '@app/stores/data'
import { TAdditionalActions } from '@app/types/app'
import { EAdditionalAction, EDownloadFileType, EDrawingFileContentType, EImportService } from '@app/utils/enums'
import { RadDropdownMenuItem, RadDropdownMenuPortal, RadDropdownMenuSeparator, RadDropdownMenuSub, RadDropdownMenuSubContent, RadDropdownMenuSubTrigger } from '@holis/react-ui/rad'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuDownload, LuTrash2, LuUpload } from 'react-icons/lu'
import { useLocation, useParams } from 'react-router-dom'
import PageContent from '../../../../components/Layout/PageContent'
import { useLayoutStore } from '../../../../stores/layout'
import Footer from '@app/components/Modal/MultiplePanels/Documentation/idwg/Footer'
import RightSide from '@app/components/Modal/MultiplePanels/Documentation/idwg/RightSide'
import { addTokenToUrl, downloadFileFromUrl, getFileNameWithoutExtension } from '@app/utils/functions'
import { useHolisAuth } from '@holis/auth-client-react'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import useIdwgStore, { IDWG_PREFIX } from '@app/stores/idwg'
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader'
import Error404 from '@app/pages/Errors/NotFound'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { OptimusClientConfig } from '@app/utils/clientConfig'
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'

type routeParams = {
  number: string
}

export default function IdwgDetailsPage() {
  const { number: idwgNumber } = useParams<routeParams>()
  const { state } = useLocation()
  const prms = useUserPermissions()
  const [idwg, setIdwg] = useState<Partial<InspectionDrawing>>()
  const [notFound, setNotFound] = useState<boolean>()
  const { t } = useTranslation()
  const [confirmDocNameExistsModalDisplayed, changeConfirmDocNameExistsModalDisplay] = useState<boolean>(false)

  const fileRef = React.createRef<HTMLInputElement>()
  const nativeFileRef = React.createRef<HTMLInputElement>()

  const { getAccessToken } = useHolisAuth()

  const { startLoading, stopLoading } = useLayoutStore()
  const {
    editIdwg,
    updateDataField,
    setUpdateData,
    setActiveInspectionDrawing,
    hasError,
    setUploadFile,
    deleteInspectionDrawingModalOpen,
    changeDeleteInspectionDrawingModalDisplay,
    uploadFile,
    updateData,
    updateFieldError,
    setFileDrawing,
    fileDrawing,
    nativeUploadFile,
    setNativeUploadFile,
    resetData,
    cmls,
    damages,
    fetchFileDrawing,
    setFetchFileDrawing,
    setPointFlocIds,
    setRightSideWidth,
  } = useIdwgStore(`${IDWG_PREFIX}${idwg?.id}`, {
    activeInspectionDrawing: idwg,
  })
  const onLeftSideResize = (size: number, _prevSize: number | undefined, containerRef: React.RefObject<HTMLDivElement>) => {
    setRightSideWidth((containerRef.current?.getBoundingClientRect().width ?? window.innerWidth - 250) * (100 - size) / 100)
  }

  const { docName } = editIdwg ?? {}
  const [getIdwgDetailByNumberApi, { loading }] = useLazyQuery<GetInspectionDrawingDetailByNumberQuery>(INSPECTION_DRAWINGS_GET_DETAIL_BY_NUMBER)

  const [getFileDrawingByDocNameApi] = useLazyQuery<GetDrawingByNameQuery>(FILE_DRAWINGS_GET_BY_NAME)

  const [updateIdwgById] = useMutation<UpdateInspectionDrawingByIdMutation>(INSPECTION_DRAWINGS_UPDATE_BY_ID)
  const { uploadDownloadService } = useDataStore()

  const getAndSetIdwgDetail = () => getIdwgDetailByNumberApi({
    variables: {
      number: idwgNumber,
    },
    fetchPolicy: 'no-cache',
  }).then((queryResult: QueryResult<GetInspectionDrawingDetailByNumberQuery>) => {
    if (!queryResult.data?.inspectionDrawing) {
      setNotFound(true)
      return
    }

    const newIdwg = { ...(queryResult.data?.inspectionDrawing ?? {}) } as Partial<InspectionDrawing>
    setIdwg(newIdwg)
    return newIdwg
  })

  useEffect(() => {
    if (idwg) {
      setActiveInspectionDrawing(idwg)
    }

    return () => {
      resetData()
    }
  }, [idwg])

  useEffect(() => {
    setPointFlocIds(_.concat(cmls?.map(item => item.flocId!) ?? [], damages?.map(item => item.flocId!) ?? []))
  }, [cmls, damages])

  useEffect(() => {
    if (state?.idwg) {
      setIdwg(state?.idwg)
    } else {
      getAndSetIdwgDetail()
    }
  }, [state])

  useEffect(() => {
    if (loading) {
      startLoading()
    } else {
      stopLoading()
    }
  }, [loading])

  useEffect(() => {
    if (nativeUploadFile) {
      handleUploadNativeFile()
    }
  }, [nativeUploadFile])

  useEffect(() => {
    if (uploadFile) {
      handleUploadFile()
    }
  }, [uploadFile])

  const downloadFile = async () => {
    if (!editIdwg || !editIdwg.docName) {
      return
    }

    startLoading()

    const url = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${editIdwg.docName}?type=${EDownloadFileType.DRAWING}&contentType=${EDrawingFileContentType.PDF}`, getAccessToken)

    downloadFileFromUrl(url, `${editIdwg.docName!}.pdf`, {
      onEnd() {
        stopLoading()
      },
      onFail(error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          AppNotifications.error(t('message.error.fileNotFound'))
        } else {
          AppNotifications.error(t('message.error.default.title'))
        }
      },
    })
  }

  const downloadNativeFile = async () => {
    if (!editIdwg || !editIdwg.docName) {
      return
    }

    startLoading()
    const url = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${editIdwg.docName}?type=${EDownloadFileType.DRAWING}&contentType=${EDrawingFileContentType.NATIVE}`, getAccessToken)

    downloadFileFromUrl(url, `${editIdwg.docName!}${fileDrawing?.nativeExt ? `.${fileDrawing.nativeExt}` : ''}`, {
      onEnd() {
        stopLoading()
      },
      onFail(error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          AppNotifications.error(t('message.error.fileNotFound'))
        } else {
          AppNotifications.error(t('message.error.default.title'))
        }
      },
    })
  }

  const handleOverrideDoc = (confirmed: boolean) => {
    if (confirmed) {
      handleUploadFile(true)
    } else {
      updateIdwg(false)
      fetchFileDrawing?.()
    }
  }

  const updateIdwg = (showSuccessMessage = true) => {
    if (!editIdwg) {
      return
    }

    if (updateData && Object.keys(updateData).length) {
      updateIdwgById({ variables: { id: editIdwg.id, data: updateData } }).then((newData) => {
        const newIdwg = {
          ...newData?.data?.updateOneInspectionDrawing,
        } as InspectionDrawing
        setActiveInspectionDrawing(newIdwg)
        if (showSuccessMessage) {
          AppNotifications.success(t('message.success.idwgUpdated'))
        }
      }).catch((err: Error) => {
        let errorMessage: string = t('message.error.default.title')
        if (typeof err?.message === 'string') {
          if (err.message.includes('Unique constraint failed on the fields: (`idwg`)')) {
            if (AppNotifications.timeoutId) {
              clearTimeout(AppNotifications.timeoutId)
            }

            errorMessage = t('message.error.unique.documentation.inspectionDrawing.idwg')
            updateFieldError('idwg', true)
            return
          }
        }

        AppNotifications.error(errorMessage)
      })
    } else {
      setUpdateData({})
      setUploadFile()
      setNativeUploadFile()
    }
  }

  const handleUploadFile = (updateIfExists = false) => {
    if (uploadFile) {
      startLoading()
      uploadDownloadService!.uploadFile(uploadFile, {
        contentType: EDrawingFileContentType.PDF,
        updateIfExists: updateIfExists ? 1 : 0,
        async: 0,
      }, EImportService.UPLOAD_DRAWING).then((response) => {
        if (response.status !== 200) {
          const responseJson = JSON.parse(response.data)
          throw new Error(typeof responseJson === 'object' && uploadFile.name && responseJson.errors?.files?.[uploadFile.name] ? responseJson.errors.files![uploadFile.name] : response.data.message ?? response.statusText)
        }

        AppNotifications.success(t('message.success.idwgPdfUpdated'))
        updateIdwg(false)
        if (updateIfExists) {
          fetchFileDrawing?.()
        }
      }).catch((err: Error) => {
        let errorMessage = t('message.error.uploadIdwgationIdwg')
        if (typeof err?.message === 'string') {
          errorMessage = err.message
          if (err.message.includes(`File ${getFileNameWithoutExtension(uploadFile.name)} already exists`)) {
            if (AppNotifications.timeoutId) {
              clearTimeout(AppNotifications.timeoutId)
            }

            errorMessage = t('message.error.unique.file.drawing.docName')
            changeConfirmDocNameExistsModalDisplay(true)
            updateFieldError('docName', true)
            return
          }
        }

        AppNotifications.error(errorMessage)
      }).finally(stopLoading)
    }
  }

  const handleUploadNativeFile = () => {
    if (nativeUploadFile && editIdwg?.docName) {
      startLoading()
      uploadDownloadService!.uploadFile(nativeUploadFile, {
        docName: editIdwg?.docName,
        contentType: EDrawingFileContentType.NATIVE,
      }, EImportService.UPLOAD_DRAWING).then((response) => {
        if (response.status !== 200) {
          throw new Error()
        }

        AppNotifications.success(t('message.success.idwgNativeUpdated'))
        fetchFileDrawing?.()
        AppNotifications.success(t('message.success.idwgNativeUpdated'))
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'))
      }).finally(stopLoading)
    }
  }

  const submitChange = () => {
    updateIdwg()
  }

  const onFileUploadClick = () => {
    fileRef.current?.click()
  }

  const onNativeFileUploadClick = () => {
    nativeFileRef.current?.click()
  }

  const onFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const uploadFile = e.target.files[0]
      const docName = getFileNameWithoutExtension(uploadFile.name)
      setUploadFile(uploadFile)
      updateDataField('docName', docName)
    }
  }

  const onNativeFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const uploadFile = e.target.files[0]
      setNativeUploadFile(uploadFile)
    }
  }

  const getDrawing = () => {
    getFileDrawingByDocNameApi({
      variables: {
        docName,
      },
      fetchPolicy: 'no-cache',
    }).then(result => setFileDrawing((result.data?.drawing as Partial<Drawing> | undefined) ?? null)).catch(() => setFileDrawing(null))
  }

  useEffect(() => {
    if (docName) {
      setFetchFileDrawing(getDrawing)
    }
  }, [docName])

  useEffect(() => {
    fetchFileDrawing?.()
  }, [fetchFileDrawing])

  const userCanUpload = prms?.drawings.create || prms?.drawings.update

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DOWNLOAD]: (
      <RadDropdownMenuItem disabled={!editIdwg?.docName} className="gap-2" onClick={downloadFile}>
        <LuDownload />

        {t('label.downloadPdf')}
      </RadDropdownMenuItem>
    ),
    [EAdditionalAction.UPLOAD]: (
      <RadDropdownMenuItem disabled={!userCanUpload} className="gap-2" onClick={onFileUploadClick}>
        <LuUpload />

        {t('label.uploadPdf')}
      </RadDropdownMenuItem>
    ),
    ...(editIdwg?.docName
      ? {
          [EAdditionalAction.NATIVE]: (
            <>
              <RadDropdownMenuSeparator />
              <RadDropdownMenuSub>
                <RadDropdownMenuSubTrigger>
                  {t('label.native')}
                </RadDropdownMenuSubTrigger>
                <RadDropdownMenuPortal>
                  <RadDropdownMenuSubContent>
                    <RadDropdownMenuItem className="gap-2" onClick={downloadNativeFile}>
                      <LuDownload />

                      {t('label.download')}
                    </RadDropdownMenuItem>
                    <RadDropdownMenuItem disabled={!userCanUpload} className="gap-2" onClick={onNativeFileUploadClick}>
                      <LuUpload />

                      {t('label.upload')}
                    </RadDropdownMenuItem>
                  </RadDropdownMenuSubContent>
                </RadDropdownMenuPortal>
              </RadDropdownMenuSub>
            </>
          ),
        }
      : {}),
    [EAdditionalAction.DELETE]: (
      <>
        <RadDropdownMenuSeparator />

        <RadDropdownMenuItem className="gap-2 item-destructive" disabled={!!cmls?.length || !!damages?.length || !prms.drawings.delete} onClick={() => changeDeleteInspectionDrawingModalDisplay(true)}>
          <LuTrash2 />

          {t('label.deleteDrawing')}
        </RadDropdownMenuItem>
      </>
    ),
  }

  if (loading) {
    return <SpinnerLoader />
  }

  if (notFound) {
    return <Error404 />
  }

  return (
    <PageContent
      header={
        editIdwg
          ? (
              <>
                <DetailsPageTitleBar
                  title={editIdwg?.idwg ?? ''}
                  subtitle={editIdwg?.description ?? ''}
                  actionButtons={{ hasError, additionalActions, submitChange }}
                />

                <input
                  ref={fileRef}
                  accept="application/pdf,.pdf"
                  className="hidden"
                  type="file"
                  onChange={onFileUploadChange}
                />
                <input
                  ref={nativeFileRef}
                  className="hidden"
                  type="file"
                  onChange={onNativeFileUploadChange}
                />
              </>
            )
          : undefined
      }
      footer={editIdwg ? <Footer idwg={editIdwg} /> : undefined}
    >
      {editIdwg && (
        <MultipleResizablePanels
          panelsDefaultSize={[50, 50]}
          panelsMinSize={[27, 44]}
          autoSaveId="idwg-detail"
          resizerClassName="left-0 panelResizer"
          panelsOnResize={[onLeftSideResize, undefined]}
        >
          <LeftSide idwg={editIdwg!} />
          <RightSide idwg={editIdwg!} />
        </MultipleResizablePanels>
      )}

      {idwg?.id && <DeleteIdwgModal open={deleteInspectionDrawingModalOpen} idwg={idwg!} onClose={() => changeDeleteInspectionDrawingModalDisplay(false)} />}
      <WarningConfirmModal closeOnConfirm closeOnCancel cancelButtonLabel={t('label.no')} confirmButtonLabel={t('label.yes')} className="min-w-[auto] min-h-[150px] w-[600px]" open={confirmDocNameExistsModalDisplayed} description={t('message.questions.fileNameExistsWantToOverrideDoc')} onConfirm={() => handleOverrideDoc(true)} onClose={() => changeConfirmDocNameExistsModalDisplay(false)} onCancel={() => handleOverrideDoc(false)} />
    </PageContent>
  )
}
