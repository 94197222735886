import { useQuery } from '@apollo/client'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper'
import { Damage, GetAllRefFlocCatalogsQuery, GetAllRefNotifCatalogsQuery, GetAllRefNotifTypesQuery, RefFlocCatalogs, RefNotifCatalog, RefNotifType } from '@app/graphql/__types__/graphql'
import { NOTIFICATION_CATALOGS_GET_MANY, NOTIFICATION_REF_TYPES_GET_MANY } from '@app/graphql/requests'
import { FLOC_CATALOGS_GET_MANY } from '@app/graphql/requests/refFlocCatalogs'
import useNotificationStore from '@app/stores/notification'
import { EFLOC_CATALOGS_CATEGORY, ENOTIFICATION_CATALOGS_CATEGORY } from '@app/utils/enums'
import { RadInput, RadFormField, RadButton, RadFormLabel } from '@holis/react-ui/rad'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuPlusSquare, LuZap } from 'react-icons/lu'
import DamageCard from '../../../Damage/DamageBlock/DamageCard'
import { AppListItem } from '@app/components/Common/Form/list-item'
import AppCombobox from '@app/components/Common/Form/AppCombobox'

export type TNotificationForm = {
  setLongText?: (value: string) => void
}

export default function Form({ setLongText }: TNotificationForm) {
  const { t } = useTranslation()

  const [types, setTypes] = useState<AppListItem[]>([])
  const [plannerGroups, setPlannerGroups] = useState<AppListItem[]>([])
  const [mainWorkCenters, setMainWorkCenters] = useState<AppListItem[]>([])
  const [plantWorkCenters, setPlantWorkCenters] = useState<AppListItem[]>([])
  const [userResponsibilities, setUserResponsibilities] = useState<AppListItem[]>([])
  const { changeDamageSelectionNewModalDisplay, selectedDamages } = useNotificationStore()

  const { loading: typesLoading, data: notificationTypesData } = useQuery<GetAllRefNotifTypesQuery>(NOTIFICATION_REF_TYPES_GET_MANY, {
    onCompleted(data) {
      setTypes(data.refNotifTypes.map((type: Partial<RefNotifType>) => ({
        label: `[${type.notifType}] - ${type.description}`,
        value: type.id,
      })))
    },
    variables: {
      orderBy: [
        { notifType: 'asc' },
        { description: { sort: 'asc' } },
      ],
    },
  })
  const { loading: flocCatalogsLoading } = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY, {
    onCompleted(data) {
      setPlannerGroups(data.findManyRefFlocCatalogs.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_GROUP).map((plannerGroup: Partial<RefFlocCatalogs>) => ({
        label: `[${plannerGroup.code}] - ${plannerGroup.description}`,
        value: plannerGroup.id,
      })))

      setMainWorkCenters(data.findManyRefFlocCatalogs.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.MAIN_WORK_CENTER).map((mainWorkCenter: Partial<RefFlocCatalogs>) => ({
        label: `[${mainWorkCenter.code}] - ${mainWorkCenter.description}`,
        value: mainWorkCenter.id,
      })))

      setPlantWorkCenters(data.findManyRefFlocCatalogs.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLANT_WORK_CENTER).map((mainWorkCenter: Partial<RefFlocCatalogs>) => ({
        label: `[${mainWorkCenter.code}] - ${mainWorkCenter.description}`,
        value: mainWorkCenter.id,
      })))
    },
    variables: {
      orderBy: [
        { code: { sort: 'asc' } },
        { description: { sort: 'asc' } },
      ],
    },
  })

  const { loading: notifCatalogsLoading } = useQuery<GetAllRefNotifCatalogsQuery>(NOTIFICATION_CATALOGS_GET_MANY, {
    onCompleted(data) {
      setUserResponsibilities(data.refNotifCatalogs.filter((item: Partial<RefNotifCatalog>) => item.category === ENOTIFICATION_CATALOGS_CATEGORY.USER_RESPONSIBLE).map((userResponsible: Partial<RefNotifCatalog>) => ({
        label: `[${userResponsible.code}] - ${userResponsible.description}`,
        value: userResponsible.id,
      })))
    },
    variables: {
      orderBy: [
        { code: { sort: 'asc' } },
        { description: { sort: 'asc' } },
      ],
    },
  })

  return (
    <div className="flex flex-col gap-6 pl-0.5 pr-4 pb-0.5">
      {/* Identification */}
      <div className="flex flex-col gap-4">

        <FormGroupHeader>
          {t('label.identification')}
        </FormGroupHeader>
        <div className="grid grid-cols-2 gap-2">
          {/* Description */}
          <RadFormField
            name="description"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.description')}>
                  <RadInput
                    placeholder={t('label.description')}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                    }}
                  />
                </InputLabelWrapper>
              )
            }
          />

          {/* Type */}
          <RadFormField
            name="typeId"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.type')}>
                  <AppCombobox
                    required
                    items={types}
                    loading={typesLoading}
                    placeholder={t('label.selectType')}
                    {...field}
                    onSelectedItemChanged={(item) => {
                      const selectedType = notificationTypesData?.refNotifTypes.find(type => (type as RefNotifType).id === item?.value) as RefNotifType
                      if (selectedType?.longText) {
                        setLongText?.(selectedType.longText)
                      }
                    }}
                  />
                </InputLabelWrapper>
              )
            }
          />
        </div>
        <div>
          {/* Additional */}
          <RadFormLabel className="text-xs">
            {t('label.additional')}
          </RadFormLabel>
          <div className="grid grid-cols-4 gap-1">
            {
              [
                { name: 'isTempRepair', label: 'label.tempRepair' },
                { name: 'isBelowMawt', label: 'label.belowMAWT' },
                { name: 'isLeak', label: 'label.leak' },
                { name: 'ffsRequired', label: t('label.ffs').toUpperCase() },
              ].map(({ name, label }) => (
                <RadFormField
                  key={name}
                  name={name}
                  render={
                    ({ field, fieldState }) => (
                      <InputLabelWrapper fieldState={fieldState}>
                        <div className="flex gap-2 h-full items-center pt-2">
                          <RadInput id={name} type="checkbox" className="w-[15px] h-[15px] data-[state=checked]:bg-sky-800 border-gray-600" {...field} />
                          <RadFormLabel className="cursor-pointer text-xs" htmlFor={name}>{t(label)}</RadFormLabel>
                        </div>
                      </InputLabelWrapper>
                    )
                  }
                />
              ))
            }
          </div>
        </div>
      </div>

      {/* Responsabilities */}
      <div className="flex flex-col gap-4">
        <FormGroupHeader>
          {t('label.responsabilities')}
        </FormGroupHeader>

        <div className="flex gap-2">
          {/* Planner group */}
          <RadFormField
            name="plannerGroupId"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.plannerGroup')} className="w-full">
                  <AppCombobox
                    {...field}
                    items={plannerGroups}
                    loading={flocCatalogsLoading}
                    placeholder={t('label.selectPlannerGroup')}
                  />
                </InputLabelWrapper>
              )
            }
          />

          {/* Main workcenter */}
          <RadFormField
            name="mainWorkCenterId"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.mainWorkCenter')} className="w-full">
                  <AppCombobox
                    {...field}
                    items={mainWorkCenters}
                    loading={flocCatalogsLoading}
                    placeholder={t('label.selectMainWorkCenter')}
                  />
                </InputLabelWrapper>
              )
            }
          />
        </div>
        <div className="flex gap-2">
          {/* Plant workcenter */}
          <RadFormField
            name="plantWorkCenterId"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.plant')} className="w-full">
                  <AppCombobox
                    {...field}
                    items={plantWorkCenters}
                    loading={flocCatalogsLoading}
                    placeholder={t('label.selectPlant')}
                  />
                </InputLabelWrapper>
              )
            }
          />

          {/* User Responsible */}
          <RadFormField
            name="userResponsibilityId"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.userResponsible')} className="w-full">
                  <AppCombobox
                    {...field}
                    items={userResponsibilities}
                    loading={notifCatalogsLoading}
                    placeholder={t('label.selectUserResponsible')}
                  />
                </InputLabelWrapper>
              )
            }
          />
        </div>
      </div>

      {/* Damage */}
      <div className="flex flex-col gap-4">
        <FormGroupHeader>
          <div className="gap-1 flex flex-row items-center">
            <LuZap className="text-primary" />
            <span>{t('label.damage')}</span>
            <RadButton type="button" variant="outline" className="text-black gap-1" onClick={() => changeDamageSelectionNewModalDisplay(true)}>
              <LuPlusSquare />
              {' '}
              {t('label.addDamage')}
            </RadButton>
          </div>
        </FormGroupHeader>
        <div className="flex flex-col gap-1">
          {selectedDamages?.map((item: Partial<Damage>) => <DamageCard key={item.id} display2dDisabled dmgObject={item} />)}
        </div>
      </div>
    </div>
  )
}
