import Separator from '@app/components/Common/Separator'
import IdText from '@app/components/Common/Text/IdText'
import DetailsPageFooter from '@app/components/Layout/DetailsPagefooter'
import { EFlagStatus, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { RadBadge } from '@holis/react-ui/rad'
import { useTranslation } from 'react-i18next'

type TFooter = {
  readonly idwg: Partial<InspectionDrawing>
}

export default function Footer({ idwg }: TFooter) {
  const { t } = useTranslation()

  return (
    <DetailsPageFooter>
      <RadBadge variant="outline" className="py-1 uppercase font-normal text-blue-700 bg-blue-200 border-blue-700">
        {t(`label.flagStatus.${idwg.flagStatus ?? EFlagStatus.A}`)}
      </RadBadge>

      <Separator />

      <IdText id={idwg.id} />
    </DetailsPageFooter>
  )
}
