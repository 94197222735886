import { useMutation } from '@apollo/client'
import DamageList from '@app/components/Common/Block/Damage/DamageBlock/DamageList'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { DeleteNotificationDamageMutation, Notification } from '@app/graphql/__types__/graphql'
import { NOTIFICATION_DAMAGES_DELETE } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import useNotificationStore from '@app/stores/notification'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { t } from 'i18next'
import { LuPlusSquare, LuZap } from 'react-icons/lu'

type TDamageBlock = Readonly<{
  notification: Partial<Notification>
  readonly?: boolean
}>

export default function DamageBlock({ notification, readonly }: TDamageBlock) {
  const { startLoading, stopLoading } = useLayoutStore()
  const { notificationDamages, fetchNotificationDamages, changeDamageSelectionModalDisplay } = useNotificationStore()
  const [deleteNotifDamageApi] = useMutation<DeleteNotificationDamageMutation>(NOTIFICATION_DAMAGES_DELETE)

  const handleRemoveDamage = (damageId: number) => {
    startLoading()
    deleteNotifDamageApi({
      variables: {
        dmgId: damageId,
        notifId: notification.id,
      },
    }).then(() => {
      fetchNotificationDamages?.()
      AppNotifications.success(t('message.success.deleteNotificationDamage'))
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <div className="flex flex-col gap-1">
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem key="manage-cml" disabled={readonly} onClick={() => changeDamageSelectionModalDisplay(true)}>
            <LuPlusSquare className="mr-2" />
            {' '}
            {t('label.manageDamage')}
          </RadDropdownMenuItem>,
        ]}
      >
        <div className="flex items-center">
          <LuZap size={20} className="mr-2" />
          {' '}
          {t('label.damage')}
        </div>
      </FormGroupHeader>
      <DamageList hasMenuContext hideAddNotifBtn objectItem={notification} damages={notificationDamages} handleRemoveDamage={handleRemoveDamage} />
    </div>
  )
}
