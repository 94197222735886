import { gql } from '@apollo/client'

export const INTEGRITY_REF_CONSEQUENCES_COMMON_FIELDS = gql`
    fragment RefIntegrityConsequenceCommonFields on RefIntegrityConsequence {
        consequence
        description
        color
        matrixValue
    }
`
