import PageContent from '@app/components/Layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CreatePlanFlocMutation, CreatePlanWorkflowMutation, GetPlanDetailByNumberQuery, Plan } from '@app/graphql/__types__/graphql'
import { SCHEDULING_PLAN_FLOCS_CREATE, SCHEDULING_PLAN_WORKFLOWS_CREATE, SCHEDULING_PLANS_GET_DETAIL_BY_NUMBER } from '@app/graphql/requests'
import LeftSide from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/LeftSide'
import RightSide from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/RightSide'
import { useLayoutStore } from '@app/stores/layout'
import { useEffect } from 'react'
import usePlanStore from '@app/stores/plan'
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar'
import { RadButton, RadDropdownMenuItem } from '@holis/react-ui/rad'
import Footer from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/Footer'
import useItemStore from '@app/stores/item'
import PlanWorkflowTableModal from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/Workflow'
import { EAdditionalAction, ESCHEDULING_PLAN_STATUS } from '@app/utils/enums'
import { TAdditionalActions } from '@app/types/app'
import DeletePlanModal from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/DeletePlanModal'
import { LuGitFork, LuRefreshCw, LuTrash2 } from 'react-icons/lu'
import ItemModal from '@app/components/Modal/Scheduling/Item'
import ItemsSummary, { TSummaryItem } from '@app/components/Common/ItemSummary'
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels'
import useReportGeneration, { ItemType } from '@app/utils/hooks/useReportGeneration'
import ReportGeneratingMesaggeWrapper from '@app/components/Common/Block/Reporting/ReportGeneratingMesaggeWrapper'
import { ROUTE_SCHEDULING_INSPECTION_PLANS } from '@app/utils/constants'
import { FaFilePdf } from 'react-icons/fa'

type routeParams = {
  number: string
}

export default function SchedulingInspectionPlanDetailsPage() {
  const { number: planNumber } = useParams<routeParams>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLayoutStore()
  const [createPlanWorkflowApi] = useMutation<CreatePlanWorkflowMutation>(SCHEDULING_PLAN_WORKFLOWS_CREATE)
  const { editPlan, setFetchPlan, updatePlan, items, events, flocs, setActivePlan, workflowModalDisplayed, hasError, changeDeletePlanModalDisplay, changeWorkflowModalDisplay, deletePlanModalOpen, activePlan, setCreatePlanWorkflowFunc } = usePlanStore()
  const { activeItem } = useItemStore()
  const [getPlanDetail, { loading }] = useLazyQuery<GetPlanDetailByNumberQuery>(SCHEDULING_PLANS_GET_DETAIL_BY_NUMBER, {
    variables: { number: planNumber },
    fetchPolicy: 'no-cache',
  })
  const [createPlanFlocApi] = useMutation<CreatePlanFlocMutation>(SCHEDULING_PLAN_FLOCS_CREATE)
  const { isReportGenerating, launchReportGeneration } = useReportGeneration(ItemType.plan, editPlan?.id, editPlan?.plan)

  const getAndSetPlanDetail = () => getPlanDetail().then((queryResult) => {
    const newPlan = { ...(queryResult.data?.plan ?? {}) } as Partial<Plan>
    updatePlan(newPlan)

    return newPlan
  })

  const showWorkflowModal = () => {
    changeWorkflowModalDisplay(true)
  }

  const onPlanDeletedOrCancelled = () => {
    navigate(ROUTE_SCHEDULING_INSPECTION_PLANS)
  }

  useEffect(() => {
    if (loading) {
      startLoading()
    } else {
      stopLoading()
    }
  }, [loading])

  useEffect(() => {
    setFetchPlan(getAndSetPlanDetail)
    getAndSetPlanDetail().then(setActivePlan)
    setCreatePlanWorkflowFunc(createPlanWorkflowApi)
  }, [])

  useEffect(() => {
    if (flocs && Array.isArray(flocs) && activePlan && activePlan?.flocId && !flocs.find(planFloc => planFloc.flocId === activePlan.flocId)) {
      createPlanFlocApi({
        variables: {
          data: {
            planId: activePlan.id,
            flocId: activePlan.flocId!,
            sort: 0,
          },
        },
      }).then(() => {
        setActivePlan({
          ...activePlan!,
          _count: {
            ...activePlan._count!,
            planFlocs: (activePlan!._count?.planFlocs ?? 0) + 1,
          },
        })
      })
    }
  }, [flocs])

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DISPLAY_WORKFLOW]: (
      <RadDropdownMenuItem className="gap-2" onClick={showWorkflowModal}>
        <LuGitFork />
        {' '}
        {t('label.workflow')}
      </RadDropdownMenuItem>
    ),
    ...(editPlan?.status !== ESCHEDULING_PLAN_STATUS.CANCEL
      ? { [EAdditionalAction.DELETE]: (
          <RadDropdownMenuItem className="gap-2 item-destructive" onClick={() => changeDeletePlanModalDisplay(true)}>
            <LuTrash2 />
            {' '}
            {t('label.deletePlan')}
          </RadDropdownMenuItem>
        ) }
      : {}),
  }

  const itemsSummary: TSummaryItem[] = [
    { label: 'object', value: flocs?.length ?? 0 },
    { label: 'item', value: items?.length ?? 0 },
    { label: 'event', value: events?.length ?? 0 },
  ]

  return (
    <>
      <PageContent
        header={(
          <DetailsPageTitleBar
            title={`${t('label.inspectionPlan')} ${editPlan?.plan}`}
            actionButtons={{ hasError, additionalActions }}
          >
            <ReportGeneratingMesaggeWrapper isReportGenerating={isReportGenerating}>
              <div className="flex items-center gap-2">
                <RadButton className="gap-2" onClick={() => launchReportGeneration()} disabled={isReportGenerating} variant="outline">
                  {
                    isReportGenerating
                      ? <LuRefreshCw className="text-sm animate-spin" />
                      : <FaFilePdf className="text-sm" />
                  }
                  {t('label.generateReport')}
                </RadButton>
                <ItemsSummary items={itemsSummary} />
              </div>
            </ReportGeneratingMesaggeWrapper>
          </DetailsPageTitleBar>
        )}
        footer={editPlan ? <Footer plan={editPlan} /> : undefined}
      >
        {
          editPlan
          && (
            <MultipleResizablePanels
              panelsDefaultSize={[50, 50]}
              panelsMinSize={[27, 44]}
              autoSaveId="plan-detail"
              resizerClassName="left-0 panelResizer"
            >
              <LeftSide plan={editPlan} />
              <RightSide plan={editPlan} />
            </MultipleResizablePanels>
          )
        }

        <DeletePlanModal open={deletePlanModalOpen} onPlanCancelled={onPlanDeletedOrCancelled} onPlanDeleted={onPlanDeletedOrCancelled} />
      </PageContent>

      {activeItem && <ItemModal open item={activeItem} />}
      {workflowModalDisplayed && <PlanWorkflowTableModal open plan={editPlan!} />}
    </>
  )
}
