import DrawingObjectSelectionConfirmModal from '@app/components/Modal/Confirm/DrawingObjectSelection'
import { Damage, CreateNotificationDamagesMutation, DeleteNotificationDamagesMutation, NotificationDamage, InspectionDrawing, Cml, GetDamagesByFlocIdsAndEventQuery, EventDamage, GetDamagesByFlocIdAndChildrenQuery, GetLatestEventDamagesByFlocIdsQuery } from '@app/graphql/__types__/graphql'
import { NO_DRAWING_ID } from '@app/utils/constants'
import { useEffect, useState } from 'react'
import DamageCard from '../DamageBlock/DamageCard'
import { useTranslation } from 'react-i18next'
import { LuMapPin } from 'react-icons/lu'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DAMAGES_GET_BY_FLOC_ID_AND_CHILDREN, DAMAGES_GET_BY_FLOC_IDS_AND_EVENT, NOTIFICATION_DAMAGES_CREATE_MANY, NOTIFICATION_DAMAGES_DELETE_MANY, WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_FLOC_IDS } from '@app/graphql/requests'
import { TDbId, TObjId } from '@app/types/app'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import useNotificationStore from '@app/stores/notification'

export default function NotificationDamageSelectionModal() {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLayoutStore()
  const [damagesLoading, setDamagesLoading] = useState<boolean>(false)
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>()
  const { editNotification, flocDamages, activeNotification, notificationDamages, notificationFlocDrawings, changeDamageSelectionModalDisplay, damageSelectionModalDisplayed, setNotificationFlocDamages, fetchNotificationDamages, latestFlocEventDamages, setLatestFlocEventDamages } = useNotificationStore()

  const [addNotificationDamagesApi] = useMutation<CreateNotificationDamagesMutation>(NOTIFICATION_DAMAGES_CREATE_MANY)
  const [deleteNotificationDamagesApi] = useMutation<DeleteNotificationDamagesMutation>(NOTIFICATION_DAMAGES_DELETE_MANY)

  const [getNotificationFlocDamagesApi] = useLazyQuery<GetDamagesByFlocIdAndChildrenQuery>(DAMAGES_GET_BY_FLOC_ID_AND_CHILDREN, {
    fetchPolicy: 'no-cache',
  })
  const [getNotificationFlocDamagesByEventApi] = useLazyQuery<GetDamagesByFlocIdsAndEventQuery>(DAMAGES_GET_BY_FLOC_IDS_AND_EVENT, {
    fetchPolicy: 'no-cache',
  })

  const [getLatestFlocEventDamagesApi] = useLazyQuery<GetLatestEventDamagesByFlocIdsQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  })

  const addItems = (ids: TDbId[]) => addNotificationDamagesApi({
    variables: {
      data: ids.map((dmgeId: TDbId) => ({
        notifId: activeNotification!.id!,
        dmgeId,
        qualReadingId: latestFlocEventDamages?.[dmgeId as number]?.qualReadingId ?? null,
      })),
    },
  })

  const deleteItems = (dmgIds: TDbId[]) => deleteNotificationDamagesApi({
    variables: {
      dmgIds,
      notifId: activeNotification!.id!,
    },
  })

  const handleValidateSelection = async (selectedItems: Partial<TObjId>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const dmgIds = (notificationDamages?.filter((item: Partial<NotificationDamage>) => item.dmgeId && oldSelectedIds.includes(item.dmgeId)).map((item: Partial<NotificationDamage>) => item.dmgeId) ?? []) as TDbId[]
      if (dmgIds.length) {
        promises.push(deleteItems(dmgIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        fetchNotificationDamages?.()
        changeDamageSelectionModalDisplay(false)
        AppNotifications.success(t('message.success.notificationDamagesUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    } else {
      changeDamageSelectionModalDisplay(false)
    }
  }

  const getLatestFlocEventDamages = () => {
    const allFlocs = flocDamages?.map((item: Partial<Damage>) => item.flocId!) ?? []
    getLatestFlocEventDamagesApi({
      variables: {
        flocIds: [...new Set(allFlocs)],
      },
      fetchPolicy: 'no-cache',
    }).then((queryResult) => {
      const latestEventDamages: Record<number, Partial<EventDamage> | null> = {};
      ((queryResult.data?.lastEventDamagesWithQualReadingByFlocIds ?? []) as Partial<EventDamage>[]).forEach((evtDamage) => {
        latestEventDamages[evtDamage.dmgeId!] = evtDamage
      })
      setLatestFlocEventDamages(latestEventDamages)
    })
  }

  const getNotificationFlocDamages = () => {
    setDamagesLoading(true)
    if (activeNotification?.origin?.startsWith('event:')) {
      const eventId = Number(activeNotification.origin.split(':')[1])
      getNotificationFlocDamagesByEventApi({
        variables: {
          flocIds: [activeNotification!.flocId!],
          evtId: eventId,
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        setNotificationFlocDamages((queryResult.data?.damages ?? []) as Partial<Damage>[])
      }).finally(() => setDamagesLoading(false))
    } else {
      getNotificationFlocDamagesApi({
        variables: {
          flocId: activeNotification!.flocId!,
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        setNotificationFlocDamages((queryResult.data?.damages ?? []) as Partial<Damage>[])
      }).finally(() => setDamagesLoading(false))
    }
  }

  useEffect(() => {
    const idwgs = notificationFlocDrawings ?? []
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      ...idwgs,
    ])
  }, [notificationFlocDrawings])

  useEffect(() => {
    getLatestFlocEventDamages()
  }, [flocDamages])

  useEffect(() => {
    getNotificationFlocDamages()
  }, [activeNotification?.flocId, activeNotification?.origin])

  return (
    <DrawingObjectSelectionConfirmModal
      hasItems
      isMultiple
      cmlHidden
      objectItem={editNotification!}
      latestEventItems={latestFlocEventDamages}
      isLoading={damagesLoading || typeof latestFlocEventDamages === 'undefined'}
      title={t('label.manageDamages')}
      description={t('label.manageDamagesDescription')}
      headerTitle={(
        <div className="flex items-center text-primary">
          <LuMapPin />
          {' '}
          {t('label.damage')}
        </div>
      )}
      drawings={drawings}
      open={damageSelectionModalDisplayed}
      items={flocDamages ?? []}
      drawingStoreIdSuffix="damage-selection-modal"
      selectedIds={notificationDamages?.map(item => item.dmgeId!) ?? []}
      renderItem={(item: Partial<Damage | Cml>, drawingStoreId: string) => (
        <DamageCard
          display2dDisabled
          displayLastQualReadingWithColor
          objectItem={editNotification!}
          drawingStoreId={drawingStoreId}
          dmgObject={latestFlocEventDamages?.[item.id!] ?? (item as Partial<Damage>)}
        />
      )}
      onValidate={handleValidateSelection}
      onClose={() => changeDamageSelectionModalDisplay(false)}
    />
  )
}
