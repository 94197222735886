export const ROUTE_HOME = '/'
export const ROUTE_METHOD_ENGINEERING = '/method-engineering'
export const ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION = `${ROUTE_METHOD_ENGINEERING}/functional-locations`
export const ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL = `${ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION}/:number`

export const ROUTE_SCHEDULING = '/scheduling'
export const ROUTE_SCHEDULING_INSPECTION_PLANS = `${ROUTE_SCHEDULING}/inspection-plans`
export const ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL = `${ROUTE_SCHEDULING_INSPECTION_PLANS}/:number`
export const ROUTE_SCHEDULING_EVENTS = `${ROUTE_SCHEDULING}/events`
export const ROUTE_SCHEDULING_INSPECTION_HISTORY = `${ROUTE_SCHEDULING}/inspection-history`
export const ROUTE_SCHEDULING_INSPECTION_HISTORY_DETAIL = `${ROUTE_SCHEDULING_INSPECTION_HISTORY}/:number`
export const ROUTE_SCHEDULING_EVENT_DETAIL = `${ROUTE_SCHEDULING_EVENTS}/:number`
export const ROUTE_SCHEDULING_EXTERNAL_IMPORT = `${ROUTE_SCHEDULING_EVENTS}/import`

export const ROUTE_WORKPACK = '/operation-workpack'
export const ROUTE_WORKPACK_DASHBOARD = `${ROUTE_WORKPACK}/dashboard`
export const ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS = `${ROUTE_WORKPACK_DASHBOARD}/filtered-results`
export const ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS_DETAIL = `${ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS}/:number`
export const ROUTE_WORKPACK_PREP = `${ROUTE_WORKPACK}/preparation`
export const ROUTE_WORKPACK_PREP_DETAIL = `${ROUTE_WORKPACK_PREP}/:number`
export const ROUTE_WORKPACK_EXEC = `${ROUTE_WORKPACK}/execution`
export const ROUTE_WORKPACK_EXEC_DETAIL = `${ROUTE_WORKPACK_EXEC}/:number`
export const ROUTE_WORKPACK_REP = `${ROUTE_WORKPACK}/reporting`
export const ROUTE_WORKPACK_REP_DETAIL = `${ROUTE_WORKPACK_REP}/:number`

export const ROUTE_ANOMALY_MANAGEMENT = '/anomaly-management'
export const ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION = `${ROUTE_ANOMALY_MANAGEMENT}/notifications`
export const ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL = `${ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION}/:number`
export const ROUTE_ANOMALY_MANAGEMENT_DAMAGE = `${ROUTE_ANOMALY_MANAGEMENT}/damages`

export const ROUTE_DOCUMENTATION = '/documentation'
export const ROUTE_DOCUMENTATION_DOCS = `${ROUTE_DOCUMENTATION}/assets`
export const ROUTE_DOCUMENTATION_DOC_DETAIL = `${ROUTE_DOCUMENTATION_DOCS}/:number`
export const ROUTE_DOCUMENTATION_IDWG = `${ROUTE_DOCUMENTATION}/inspection-drawings`
export const ROUTE_DOCUMENTATION_IDWG_DETAIL = `${ROUTE_DOCUMENTATION_IDWG}/:number`

export const ROUTE_ADMIN = '/admin'
export const ROUTE_ADMIN_USER_ROLE = `${ROUTE_ADMIN}/users-roles`
export const ROUTE_ADMIN_IMPORT_EXPORT = `${ROUTE_ADMIN}/import-export`

export const ROUTE_OAUTH_CALLBACK = '/oauth-callback'
