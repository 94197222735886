import { FetchResult, useLazyQuery, useMutation } from '@apollo/client'
import AttachmentCard from '@app/components/Common/Block/Attachment/AttachmentBlock/AttachmentCard'
import DeleteAttachmentModal from '@app/components/Common/Block/Attachment/AttachmentBlock/DeleteAttachmentModal'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import SortableList, { SortableItem } from '@app/components/Common/List/SortableList'
import { Attachment, CreateAttachmentMutation, GetAttachmentsByEvtIdQuery, UpdateAttachmentByIdMutation } from '@app/graphql/__types__/graphql'
import { FILE_ATTACHMENTS_CREATE, FILE_ATTACHMENTS_GET_BY_EVT_ID, FILE_ATTACHMENTS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import useAttachmentStore from '@app/stores/attachment'
import { useDataStore } from '@app/stores/data'
import { useEventStore } from '@app/stores/event'
import { useLayoutStore } from '@app/stores/layout'
import { EImportService } from '@app/utils/enums'
import { RadButton } from '@holis/react-ui/rad'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import _ from 'lodash'
import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { LuClipboardList, LuPlusSquare } from 'react-icons/lu'

type AttachmentListProps = {
  className?: string
  readonly?: boolean
}
export default function AttachmentList({ className, readonly }: Readonly<AttachmentListProps>) {
  const { itemToDelete } = useAttachmentStore()
  const { activeEvent, setFetchEventAttachments, eventAttachments, setEventAttachments, fetchEventAttachments } = useEventStore()
  const [getEventAttachmentsApi, { loading, error }] = useLazyQuery<GetAttachmentsByEvtIdQuery>(FILE_ATTACHMENTS_GET_BY_EVT_ID)
  const [createAttachmentApi] = useMutation<CreateAttachmentMutation>(FILE_ATTACHMENTS_CREATE)
  const [updateAttachmentById] = useMutation<UpdateAttachmentByIdMutation>(FILE_ATTACHMENTS_UPDATE_BY_ID)
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, inputRef } = useDropzone({
    accept: ['.png', '.jpeg', '.jpg', '.svg', '.pdf'],
    noClick: true,
    multiple: false,
  })
  const { startLoading, stopLoading } = useLayoutStore()
  const { uploadDownloadService } = useDataStore()

  const getAndSetEventAttachments = async () => {
    const result = await getEventAttachmentsApi({
      variables: {
        evtId: activeEvent?.id,
      },
      fetchPolicy: 'no-cache',
    })
    setEventAttachments?.(_.sortBy((result.data?.attachments ?? []), ['sort', 'attachmentName']) as Partial<Attachment>[])
  }

  const handleNewAttachment = async () => {
    let result: FetchResult<CreateAttachmentMutation>
    try {
      result = await createAttachmentApi({
        variables: {
          data: {
            wrkoId: activeEvent?.id,
          },
        },
      })
    } catch {
      AppNotifications.error(t('message.error.default.title'))
    }

    fetchEventAttachments?.()
    return result!
  }

  const uploadImages = async (file: File) => {
    startLoading()

    inputRef.current?.setAttribute('value', '')

    // Create attachment
    const result = await handleNewAttachment()
    const newAttachment = result.data?.createOneAttachment as Partial<Attachment>

    uploadDownloadService!.uploadFile(file, { id: newAttachment.id }, EImportService.UPLOAD_ATTACHMENT).then((res: AxiosResponse) => {
      if (res.status === 200) {
        fetchEventAttachments?.()
        AppNotifications.success(t('message.success.importService.attachmentUploaded'))
      } else {
        throw new Error(String(res.data))
      }
    }).catch((err: Error) => {
      console.log(err)
      AppNotifications.error(t('message.error.importService.attachmentUploadFailed'))
    }).finally(() => {
      stopLoading()
    })
  }

  const updateSortHandler = (item: Partial<Attachment>, newSortVal: number) => {
    startLoading()
    updateAttachmentById({
      variables: {
        id: item.id!,
        data: {
          sort: {
            set: newSortVal,
          },
        },
      },
    }).then(() => {
      AppNotifications.success(t('message.success.attachmentUpdated'))
      fetchEventAttachments?.()
    }).catch((error: Error) => {
      console.log(error)
      AppNotifications.error(t('message.error.default.title'))
    }).finally(() => {
      stopLoading()
    })
  }

  const handleSortChange = (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => {
    updateSortHandler(dragItem as Partial<Attachment>, (dropItem as Partial<Attachment>).sort ?? dropIndex)
  }

  useEffect(() => {
    if (acceptedFiles.length || fileRejections.length) {
      if (fileRejections.length) {
        const { message } = fileRejections[0].errors[0]
        AppNotifications.error(message ?? t('message.error.default.title'))
      } else if (acceptedFiles.length) {
        uploadImages(acceptedFiles[0])
      }
    }
  }, [acceptedFiles, fileRejections])

  useEffect(() => {
    setFetchEventAttachments(getAndSetEventAttachments)
    getAndSetEventAttachments()
  }, [])

  return (
    <div className={className}>
      <div {...getRootProps()}>
        <FormGroupHeader>
          <div className="flex gap-4 items-center">
            <div className="flex items-center">
              <LuClipboardList className="mr-2" size={20} />
              {' '}
              {t('label.reportAttachment')}
            </div>
            <RadButton variant="outline" disabled={readonly} className="text-foreground" onClick={() => inputRef.current?.click()}>
              <LuPlusSquare className="mr-2" />
              {' '}
              {t('label.addAttachment')}
            </RadButton>
          </div>
        </FormGroupHeader>
        <input ref={inputRef} {...getInputProps()} />
      </div>

      {loading && <div className="p-4 text-muted-foreground">{t('label.loading')}</div>}

      {error && (
        <div className="p-4 text-red-900">
          Error:
          {error.message}
        </div>
      )}

      {!loading && !error && (

        (eventAttachments?.length
          && (
            <div className="grid grid-cols-2 gap-2 mt-2">
              <SortableList
                items={(eventAttachments ?? []) as SortableItem[]}
                renderItem={attachment => (
                  <AttachmentCard key={`attachment-${attachment.id}`} readonly={readonly} fetchAttachments={fetchEventAttachments} hasDragBtn={!readonly} attachment={attachment as Partial<Attachment>} hasActionButtons={!readonly} />
                )}
                onSortChange={handleSortChange}
              />
            </div>
          ))
          || <div className="p-4 text-muted-foreground">{t('label.noAttachment')}</div>
      )}
      {!!itemToDelete && <DeleteAttachmentModal open attachment={itemToDelete} />}
    </div>
  )
}
