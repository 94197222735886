import { RadDialogClose, RadButton } from '@holis/react-ui/rad'
import { useTranslation } from 'react-i18next'

type TFooter = {
  readonly onSaveClick: () => void
  readonly isSaveButtonEnabled?: boolean
}

export default function Footer({ onSaveClick, isSaveButtonEnabled }: TFooter) {
  const { t } = useTranslation()
  return (
    <>
      <RadDialogClose asChild>
        <RadButton variant="outline">
          {t('label.cancel')}
        </RadButton>
      </RadDialogClose>

      <RadButton disabled={isSaveButtonEnabled === false} onClick={onSaveClick}>
        {t('label.create')}
      </RadButton>
    </>
  )
}
