import { Cml } from '@app/graphql/__types__/graphql'
import { FORMAT_DATE_EU } from '@app/utils/constants'
import { RadCard, RadCardContent, RadCardHeader } from '@holis/react-ui/rad'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { LuCalendarDays, LuLineChart } from 'react-icons/lu'

type TCmlCalculationBlock = Readonly<{
  cml: Partial<Cml>
}>

export default function CmlCalculationBlock({ cml }: TCmlCalculationBlock) {
  const { t } = useTranslation()
  const unit = cml.codeGroup?.unit ? t('label.unitPerYear', { unit: cml.codeGroup?.unit }) : ''
  return (
    <div className="flex flex-row gap-x-4 mb-6">
      <RadCard className="p-3">
        <RadCardHeader className="text-gray-500 flex flex-row items-center gap-x-1 capitalize p-1">
          <LuLineChart className="w-4 h-4" />
          {' '}
          <span className="!my-0">{t('label.corrorationRateST')}</span>
        </RadCardHeader>
        <RadCardContent className="flex items-center font-semibold tex-base p-1">
          {cml.corrRateST}
          {' '}
          {cml.corrRateST && <span className="text-sm ml-1">{unit}</span>}
        </RadCardContent>
      </RadCard>
      <RadCard className="p-3">
        <RadCardHeader className="text-gray-500 flex flex-row items-center gap-x-1 capitalize p-1">
          <LuLineChart className="w-4 h-4" />
          {' '}
          <span className="!my-0">{t('label.corrorationRateLT')}</span>
        </RadCardHeader>
        <RadCardContent className="flex items-center font-semibold tex-base p-1">
          {cml.corrRateLT}
          {' '}
          {cml.corrRateLT && <span className="text-sm ml-1">{unit}</span>}
        </RadCardContent>
      </RadCard>
      <RadCard className="p-3">
        <RadCardHeader className="text-gray-500 flex flex-row items-center gap-x-1 capitalize p-1">
          <LuCalendarDays className="w-4 h-4" />
          {' '}
          <span className="!my-0">{t('label.maxEndDate')}</span>
        </RadCardHeader>
        <RadCardContent className="flex items-center font-semibold tex-base p-1">
          {cml.maxEndDate ? moment(cml.maxEndDate!).format(FORMAT_DATE_EU) : ''}
        </RadCardContent>
      </RadCard>
    </div>
  )
}
