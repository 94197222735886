import { EEventPerformanceDashboardPlannedDateFilter } from '../enums'

export const checkPlannedDateFilters = (filters: EEventPerformanceDashboardPlannedDateFilter[], filter: string): boolean => {
  const strFilters = filters.map(_filter => _filter as string)
  if (strFilters.includes(filter)) {
    return true
  }

  const valMonth = parseInt(filter, 10)
  if (!isNaN(valMonth)) {
    strFilters.sort().reverse()
    for (const strFilter of strFilters) {
      const filterVal = parseInt(strFilter, 10)
      if (!isNaN(filterVal) && filterVal >= valMonth) {
        return true
      }
    }
  }

  return false
}
