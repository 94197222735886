import { Damage } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import { OBJ_NEW_ID } from '@app/utils/constants'
import React from 'react'
import IdText from '@app/components/Common/Text/IdText'
import { RadBadge, RadButton } from '@holis/react-ui/rad'
import { tailwindColorToBgFgStyle } from '@app/utils/functions'
import { LuXCircle } from 'react-icons/lu'
type TFooter = Readonly<{
  damage: Partial<Damage>
  onCancelClick?: () => void
}>

export default function Footer({ damage, onCancelClick }: TFooter) {
  const { t } = useTranslation()
  return (
    <div className="flex w-full h-full justify-between font-normal items-center px-2 pt-2">
      <div className="flex items-center gap-2 h-full">
        {damage.id !== OBJ_NEW_ID && (
          <>
            <RadBadge variant="outline" className="py-1 font-normal" style={tailwindColorToBgFgStyle(damage.statusObject?.displayColor)}>{damage.statusObject?.description ?? '-'}</RadBadge>
            <IdText id={damage.id} />
          </>
        )}
      </div>
      <div className="flex gap-2">
        <RadButton variant="outline" className="gap-2" onClick={onCancelClick}>
          <LuXCircle />
          {' '}
          {t('label.close')}
        </RadButton>

      </div>
    </div>
  )
}
