import ListModal, { TListModalWithoutListProps } from '@app/components/Modal/List'
import { EventInspectionPoint, EventTask, RefMeasPointValCode } from '@app/graphql/__types__/graphql'
import { checkEventInspectionPointValidity, useEventStore } from '@app/stores/event'
import { FORMAT_DATE_EU } from '@app/utils/constants'
import { EAppFlagStatus } from '@app/utils/enums'
import { renderQualReadingItem } from '@app/utils/functions'
import { TDataRowTreeList } from '@holis/react-ui'
import Tooltip from 'devextreme-react/tooltip'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons/lib'
import { LuAlertTriangle, LuCheckCircle, LuCircleSlash } from 'react-icons/lu'
import colors from 'tailwindcss/colors'

export default function EventInspectionPointListModal({ items, ...restProps }: TListModalWithoutListProps<Partial<EventInspectionPoint>>) {
  const { t } = useTranslation()
  const { eventTasks } = useEventStore()

  return (
    <ListModal<Partial<EventInspectionPoint>>
      title={t('label.inspectionChecklistPointList')}
      listProps={{
        listProps: {
          data: (items ?? []) as unknown as TDataRowTreeList[],
          cellRenderers: {
            qualitativeReading(value) {
              return renderQualReadingItem(value as Partial<RefMeasPointValCode>)
            },
            measurement(value, rowData) {
              return (rowData as EventInspectionPoint).inspectionPoint.char?.unit && value ? `${value} ${(rowData as EventInspectionPoint).inspectionPoint.char?.unit}` : ''
            },
            date(value) {
              const dateMoment = value ? moment.parseZone(value) : null
              return moment.isMoment(dateMoment) && dateMoment.isValid() ? dateMoment.format(FORMAT_DATE_EU) : ''
            },
            flagStatus(value, rowData) {
              const point = rowData as EventInspectionPoint
              let color: string = colors.green[700]
              let bgColor: string = 'bg-green-100'
              let Icon: IconType = LuCheckCircle

              const validityCheck = checkEventInspectionPointValidity(point, eventTasks as EventTask[], t)

              if (validityCheck.errors.length > 0) {
                color = colors.red[700]
                Icon = LuCircleSlash
                bgColor = 'bg-red-100'
              } else if (validityCheck.warnings.length > 0) {
                color = colors.orange[700]
                Icon = LuAlertTriangle
                bgColor = 'bg-orange-100'
              } else if (value === EAppFlagStatus.DELETED) {
                color = colors.red[700]
                Icon = LuCircleSlash
                bgColor = 'bg-red-100'
              } else if (value === EAppFlagStatus.CLOSED) {
                color = colors.orange[700]
                Icon = LuAlertTriangle
                bgColor = 'bg-orange-100'
              }

              const errorsAndWarnings = [...validityCheck.errors, ...validityCheck.warnings]

              return (
                <div className={`aspect-square rounded w-[30px] flex flex-col justify-center text-center items-center ${bgColor}`}>
                  <Icon id={`insp-status-icon-${point.id}`} color={color} size={20} />
                  {errorsAndWarnings && (
                    <Tooltip
                      target={`#insp-status-icon-${point.id}`}
                      showEvent="mouseenter"
                      hideEvent="mouseleave"
                      hideOnOutsideClick={false}
                    >
                      <ul>
                        {errorsAndWarnings.map(error => (
                          <li key={error}>{error}</li>
                        ))}
                      </ul>
                    </Tooltip>
                  )}
                </div>
              )
            },
          },
          columns: [
            {
              field: 'inspectionPoint.flagStatus',
              title: '-',
              type: 'string',
              width: 20,
              cellRenderer: 'flagStatus',
              class: 'status-col',
            },
            {
              field: 'inspectionPoint.functionalLocation.floc',
              title: t('label.functionalLocation'),
              type: 'string',
              width: 150,
            },
            {
              field: 'inspectionPoint.position',
              title: t('label.position'),
              type: 'string',
              width: 60,
            },
            {
              field: 'inspectionPoint.description',
              title: t('label.description'),
              type: 'string',
              width: 150,
            },
            {
              field: 'inspectionPoint.class.class',
              title: t('label.class'),
              type: 'string',
              width: 60,
            },
            {
              field: 'qualReading',
              title: t('label.qualitativeReading'),
              type: 'string',
              width: 200,
              cellRenderer: 'qualitativeReading',
            },
            {
              field: 'quantReading',
              title: t('label.measurement'),
              type: 'string',
              width: 60,
              cellRenderer: 'measurement',
            },
            {
              field: 'reportingDate',
              title: t('label.date'),
              type: 'date',
              width: 60,
              cellRenderer: 'date',
            },
          ],
        },
      }}
      {...restProps}
    />
  )
}
