import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateNotificationMutation, Damage, FlocView, FunctionalLocation, GetAllFlocViewsWithDamagesAndChildrenDamagesQuery, GetDamagesByFlocIdAndChildrenQuery, GetNotificationDetailByIdQuery, Notification } from '@app/graphql/__types__/graphql'
import { DAMAGES_GET_BY_FLOC_ID_AND_CHILDREN, FLOC_VIEWS_GET_ALL_WITH_DAMAGES_AND_CHILDREN_DAMAGES, NOTIFICATION_DAMAGES_CREATE_MANY, NOTIFICATIONS_CREATE, NOTIFICATIONS_GET_DETAIL_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import useNotificationStore, { ZOD_NOTIFICATION_DATAS } from '@app/stores/notification'
import { setObjValueByPath, textToHtml } from '@app/utils/functions'
import { useHolisAuth } from '@holis/auth-client-react'

import { useTranslation } from 'react-i18next'
import Form from './components/Form'
import Footer from './components/Footer'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useRef, useState } from 'react'
import SingleFormModal from '@app/components/Modal/SingleFormModal'
import { useEventStore } from '@app/stores/event'
import DamageSelectionModal from '../../Damage/DamageBlock/DamageSelectionModal'
import FlocSelectionModal from '../../Floc/FlocBlock/FlocSelectionModal'

type TNotificationModal = Readonly<{
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onCreated: (notification: Partial<Notification>) => void
}>

export default function NotificationNewModal({ onOpenChange, isOpen, onCreated }: TNotificationModal) {
  const { t } = useTranslation()

  const [flocsWithDamage, setFlocsWithDamage] = useState<FlocView[]>([])

  const { editNotification, setEditNotification, updateNotificationDataField, updateNotificationData, setUpdateNotificationData, updateNotification, createNotificationWorkflowFunc, updateNotificationFieldError, hasError, changeDamageSelectionNewModalDisplay, damageSelectionModalOpen, selectedDamages, setSelectedDamages, activeNotification, setActiveNotification, eventDamages } = useNotificationStore()
  const [createNotificationApi] = useMutation<CreateNotificationMutation>(NOTIFICATIONS_CREATE)
  const [createNotificationDamagesApi] = useMutation<CreateNotificationMutation>(NOTIFICATION_DAMAGES_CREATE_MANY)
  const { fetchEventNotifications } = useEventStore()
  const [getNotificationDetailsApi] = useLazyQuery<GetNotificationDetailByIdQuery>(NOTIFICATIONS_GET_DETAIL_BY_ID)
  const [damagesLoading, setDamagesLoading] = useState<boolean>(false)
  const [damages, setDamages] = useState<Partial<Damage>[]>()
  const [selectedIds, setSelectedIds] = useState<number[]>()
  const [getDamagesByFlocIdApi] = useLazyQuery<GetDamagesByFlocIdAndChildrenQuery>(DAMAGES_GET_BY_FLOC_ID_AND_CHILDREN)
  const [getFlocsWithDamageApi] = useLazyQuery<GetAllFlocViewsWithDamagesAndChildrenDamagesQuery>(FLOC_VIEWS_GET_ALL_WITH_DAMAGES_AND_CHILDREN_DAMAGES)
  const { eventFlocIds } = useEventStore()

  const onValidateDamageSelection = (selectedItems: Partial<Damage>[]) => {
    setSelectedDamages(selectedItems)
    changeDamageSelectionNewModalDisplay(false)
  }

  const { user } = useHolisAuth()

  const zodFormObject = z.object(ZOD_NOTIFICATION_DATAS(t))
  const form = useForm<z.infer<typeof zodFormObject>>(
    {
      resolver: zodResolver(zodFormObject), mode: 'onSubmit', defaultValues: {
        description: '',
        typeId: undefined,
        flocId: editNotification?.flocId,
        plantWorkCenterId: editNotification?.functionalLocation?.plantWorkCenterId as number,
      },
    })
  const htmlForm = useRef<HTMLFormElement>(null)

  // Update notification value in store (will update updateNotificationData)
  const updateNotificationDataValue = (field: string, value: unknown) => {
    console.log('update notif')
    const editedNotification = { ...editNotification }
    setObjValueByPath(editedNotification, field, value)
    updateNotificationDataField(field, value)

    setEditNotification(editedNotification)
  }

  // Watch form value changes
  form.watch((datas, { name, type }) => {
    if (type === 'change') {
      updateNotificationDataValue(name!, datas[name!])
    }
  })

  // Handle notification creation error.
  const handleNotificationCreateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title')
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`notif`)')) {
        errorMessage = t('message.error.unique.anomalyManagement.notification.notif')
        updateNotificationFieldError('notif', true)
      }
    }

    AppNotifications.error(errorMessage)
  }

  const onSelectFloc = (selectedItems: Partial<FunctionalLocation>[]) => {
    if (selectedItems.length) {
      const floc = selectedItems[0]
      form.setValue('flocId', floc.id!)
      setActiveNotification({
        ...activeNotification,
        functionalLocation: floc as FunctionalLocation,
        flocId: floc.id,
      })
    } else {
      setActiveNotification()
    }
  }

  // Update notification state in store and create workflow record.
  const updateNotificationState = async (newData: Partial<Notification>) => {
    const actionDate = new Date()

    const newNotification = {
      ...editNotification,
      ...newData,
    } as Partial<Notification>

    await createNotificationWorkflowFunc?.({
      variables: {
        data: {
          notifId: newNotification.id,
          status: `*|${newNotification.status}`,
          date: actionDate,
          userLogin: user?.username,
          description: t('label.ianActions.descriptions.cration_of_ian'),
        },
      },
    })

    updateNotification(newNotification, true)
    setUpdateNotificationData({})
    fetchEventNotifications?.()
    onOpenChange?.(false)
  }

  // Handle actual notification creation.
  const handleCreateNotification = async () => {
    if (
      hasError() // Check if any field in updateNotificationData has an error.
    ) {
      AppNotifications.error(t('message.error.requiredFields'))
      return
    }

    try {
      const newData = await createNotificationApi({
        variables: {
          data: {
            ...updateNotificationData,
            reportedBy: user?.username,
          },
        },
      })

      const notification = newData.data?.createOneNotification as Partial<Notification>
      await updateNotificationState(notification)
      if (selectedDamages?.length) {
        await createNotificationDamagesApi({
          variables: {
            data: selectedDamages!.map((dmg: Partial<Damage>) => ({
              dmgeId: dmg.id!,
              notifId: notification.id!,
              qualReadingId: eventDamages?.find(item => item.dmgeId === dmg.id!)?.qualReadingId ?? null,
            })),
          },
        })
      }

      const notifDetails = await getNotificationDetailsApi({
        variables: {
          id: notification.id,
        },
      })

      onCreated(notifDetails.data?.notification as Partial<Notification>)

      AppNotifications.success(t('message.success.addNotification'))
    } catch (err) {
      handleNotificationCreateError(err as Error)
    }
  }

  useEffect(() => {
    if (editNotification?.flocId) {
      setDamagesLoading(true)
      getDamagesByFlocIdApi({
        variables: {
          flocId: activeNotification?.flocId,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setDamages((queryResult.data?.damages ?? []) as Partial<Damage>[])).finally(() => setDamagesLoading(false))
      // }
    } else {
      getFlocsWithDamageApi({
        fetchPolicy: 'no-cache',
      }).then(queryResult => setFlocsWithDamage((queryResult.data?.flocViews ?? []) as FlocView[]))
    }
  }, [editNotification?.flocId])

  useEffect(() => {
    setSelectedIds(selectedDamages?.map((dmg: Partial<Damage>) => dmg.id!) ?? [])
  }, [selectedDamages])

  const flocItems = useMemo(() => {
    return eventFlocIds ? flocsWithDamage?.filter(floc => eventFlocIds.includes(floc.id!)) : flocsWithDamage
  }, [flocsWithDamage, eventFlocIds])

  return (
    <>
      {!!editNotification?.functionalLocation && (
        <SingleFormModal
          isOpen={isOpen}
          isTransparent={damageSelectionModalOpen}
          title={t('label.notificationCreation')}
          description={(
            <>
              <div className="text-lg">
                <span className="text-primary font-semibold">
                  {editNotification?.functionalLocation?.floc ?? ''}
                  {' '}
                  -
                  {' '}
                </span>
                <span>{editNotification?.functionalLocation?.description ?? ''}</span>
              </div>
              <div>{t('label.itemCreateDescription')}</div>
            </>
          )}
          footer={<Footer isSaveButtonEnabled={!!selectedDamages?.length} onSaveClick={() => htmlForm.current?.requestSubmit()} />}
          onOpenChange={onOpenChange}
        >
          <RadForm {...form}>
            <form ref={htmlForm} onSubmit={form.handleSubmit(handleCreateNotification, console.log)}>
              <Form setLongText={lt => updateNotificationDataValue('longDescription', textToHtml(lt))} />
            </form>
          </RadForm>
        </SingleFormModal>
      )}
      {damageSelectionModalOpen && !damagesLoading && <DamageSelectionModal open selectedIds={selectedIds} items={damages ?? []} onClose={() => changeDamageSelectionNewModalDisplay(false)} onValidate={onValidateDamageSelection} />}
      {!editNotification?.functionalLocation && <FlocSelectionModal open isRequired isMultiple={false} items={flocItems as Partial<FunctionalLocation>[]} onClose={() => setActiveNotification()} onValidate={onSelectFloc} />}
    </>
  )
}
