import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import { EventCountByStatusSitePlannedDateResponse } from '@app/graphql/__types__/graphql'
import { useEventPerformanceDashboardStore } from '@app/stores/event/performanceDashboard'
import { ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS } from '@app/utils/constants'
import { renderCustomizedPieChartLabel, tailwindColorToHex } from '@app/utils/functions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

type TBarChartData = {
  stage: string
  counter: number
}

export default function EventProgressPieChart() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getStageColor, filteredData, classFilters, plannedDateFilters } = useEventPerformanceDashboardStore()
  const [chartData, setChartData] = useState<TBarChartData[]>()

  const onClickPieCell = (stage: string) => {
    navigate(ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS, {
      state: {
        stages: [stage],
        classes: classFilters,
        plannedDateFilters,
      },
    })
  }

  useEffect(() => {
    if (typeof filteredData !== 'undefined') {
      const chartDataToBuild: Record<string, TBarChartData> = {}
      filteredData?.forEach((item: EventCountByStatusSitePlannedDateResponse) => {
        const { stage, counter } = item
        if (!Object.keys(chartDataToBuild).includes(stage)) {
          chartDataToBuild[stage] = {
            stage,
            counter: 0,
          }
        }

        chartDataToBuild[stage].counter += counter
      })
      setChartData(Object.values(chartDataToBuild))
    }
  }, [filteredData])

  return (
    <div className="flex flex-col gap-2 w-full h-full items-center">
      <h2 className="text-sm font-bold">{t('label.progress')}</h2>
      <SpinnerLoaderComponent isLoading={typeof chartData === 'undefined'}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              cx="50%"
              cy="50%"
              data={chartData}
              labelLine={false}
              label={renderCustomizedPieChartLabel}
              outerRadius={180}
              fill="#8884d8"
              dataKey="counter"
              animationDuration={100}
            >
              {chartData?.map(({ stage }) => (
                <Cell key={`cell-${stage}`} className="hover:cursor-pointer hover:opacity-85" name={t(`label.eventStages.${stage.toLowerCase()}`)} fill={tailwindColorToHex(getStageColor(stage), '300')} onClick={() => onClickPieCell(stage)} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </SpinnerLoaderComponent>
    </div>
  )
}
