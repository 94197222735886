import { PropsWithChildren } from 'react'
import { cn } from '@holis/react-ui/utils'

type TBlockTitle = Readonly<PropsWithChildren & {
  className?: string
}>

export default function BlockTitle({ children, className }: TBlockTitle) {
  return <div className={cn('text-sm w-full text-gray-700 mt-4 mb-1 font-bold flex items-center', className)}>{children}</div>
}
