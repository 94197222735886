import { gql } from '@apollo/client'
import { FILE_DRAWINGS_COMMON_FIELDS } from '../fragments'

export const FILE_DRAWINGS_GET_BY_NAME = gql`
${FILE_DRAWINGS_COMMON_FIELDS}
query GetDrawingByName($docName: String!) {
    drawing(where: {docName: $docName}){
        ...FileDrawingCommonFields
    }
}
`

export const FILE_DRAWINGS_GET_COUNTER = gql`
query GetFileDrawingsCounter {
  aggregateDrawing {
    _count {
      id
    }
  }
}
`
