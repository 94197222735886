import PermissionMenu from '@app/services/permissions/PermissionMenu'
import useUsersStore from '@app/stores/users'
import { ucFirst } from '@app/utils/functions'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export default function MenuPermission() {
  const { t } = useTranslation()
  const { editingRole, setEditingRole } = useUsersStore()

  const menuPermissionsAsMenuItems = useMemo(() => {
    const allPermKeys = Object.keys(new PermissionMenu())
    const perms = editingRole?.permissions.find(perm => perm.resource === 'menu')
    const permsObj = (perms ? JSON.parse(perms.permissions) : {}) as PermissionMenu | undefined

    const result: MenuPermissionItem[] = []
    let currRootKey = ''
    let children: MenuPermissionItem[] = []
    for (const prm of allPermKeys) {
      const rootKey = prm.match(/^[a-z]+/)?.[0] ?? ''
      const childName = prm.replace(rootKey, '')

      if (currRootKey && rootKey !== currRootKey) {
        result.push({
          isRoot: true,
          key: currRootKey,
          name: currRootKey,
          children: [...children],
        })

        children = []
      }

      children.push({
        isRoot: false,
        key: prm,
        name: childName,
        allowed: permsObj?.[prm as keyof PermissionMenu] ?? false,
      })

      currRootKey = rootKey
    }

    if (currRootKey) {
      result.push({
        isRoot: true,
        key: currRootKey,
        name: currRootKey,
        children: [...children],
      })
    }

    return result
  }, [editingRole])

  const toggleMenuPermission = (item: MenuPermissionItem) => {
    if (!editingRole) {
      return
    }

    const newPermissions = editingRole.permissions.filter(perm => perm.resource !== 'menu')
    const currMenuPermissions = editingRole.permissions.find(perm => perm.resource === 'menu')
    newPermissions.push({
      resource: 'menu',
      permissions: JSON.stringify({
        ...JSON.parse(currMenuPermissions?.permissions ?? '{}'),
        [item.key]: !item.allowed,
      }),
    })

    setEditingRole({
      ...editingRole!,
      permissions: newPermissions,
    })
  }

  const toggleRootMenuPermission = (root: MenuPermissionItem) => {
    if (!editingRole) {
      return
    }

    const newPermissions = editingRole.permissions.filter(perm => perm.resource !== 'menu')
    const currMenuPermissions = editingRole.permissions.find(perm => perm.resource === 'menu')
    const newMenuPermissions = {
      ...JSON.parse(currMenuPermissions?.permissions ?? '{}'),
    }

    const toggleValue = !root.children?.every(child => child.allowed)

    root.children?.forEach((child) => {
      newMenuPermissions[child.key] = toggleValue
    })

    newPermissions.push({
      resource: 'menu',
      permissions: JSON.stringify(newMenuPermissions),
    })

    setEditingRole({
      ...editingRole!,
      permissions: newPermissions,
    })
  }

  return (
    <div className="px-4 text-gray-800">
      {
        menuPermissionsAsMenuItems.map(root => (
          <div key={root.key} className="px-2">
            <label htmlFor={`role_prm_menu_${root.key}`} className="flex gap-2 items-center cursor-pointer">
              <input
                type="checkbox"
                id={`role_prm_menu_${root.key}`}
                checked={root.children?.every(child => child.allowed)}
                onChange={() => {
                  toggleRootMenuPermission(root)
                }}
              />
              <div className="font-bold">{t(`label.menu.${root.name}`)}</div>
            </label>
            { root.children?.map(child => (
              <div key={child.key} className="ml-4">
                <label
                  htmlFor={`role_prm_menu_${child.key}`}
                  className={`flex gap-2 items-center cursor-pointer hover:text-black hover:font-bold ${child.allowed ? 'text-black' : 'text-gray-400'}`}
                >
                  <input
                    type="checkbox"
                    id={`role_prm_menu_${child.key}`}
                    checked={child.allowed}
                    onChange={() => {
                      toggleMenuPermission(child)
                    }}
                  />
                  {t(`label.menu.${root.name}${ucFirst(child.name)}`)}
                </label>
              </div>
            ))}
          </div>
        ))
      }
    </div>
  )
}

type MenuPermissionItem = {
  isRoot: boolean
  key: string
  name: string
  allowed?: boolean
  children?: MenuPermissionItem[]
}
