import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteOneItemTaskByIdMutation } from '@app/graphql/__types__/graphql'
import { SCHEDULING_ITEM_TASKS_DELETE_ONE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import useItemStore from '@app/stores/item'
import { useLayoutStore } from '@app/stores/layout'
import { EWORKORDER_REF_EVENT_TASK_CATEGORY } from '@app/utils/enums'
import { useTranslation } from 'react-i18next'
type TDeleteItemTaskModal = IConfirmModal

export default function DeleteItemTaskModal({ ...restProps }: TDeleteItemTaskModal) {
  const { setTaskToDelete, taskToDelete, deleteItemTask, fetchItemTasks } = useItemStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteItemTaskApi] = useMutation<DeleteOneItemTaskByIdMutation>(SCHEDULING_ITEM_TASKS_DELETE_ONE_BY_ID)
  const { t } = useTranslation()
  const onConfirm = () => {
    if (taskToDelete) {
      startLoading()
      deleteItemTaskApi({
        variables: {
          id: taskToDelete!.id!,
        },
      }).then(() => {
        deleteItemTask()
        fetchItemTasks?.()
        AppNotifications.success(t(`message.success.deleteItemTask${taskToDelete.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}`))
      }).catch((error: Error) => {
        console.log(error)
        AppNotifications.error(t(`message.error.deleteItemTask${taskToDelete.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}`))
      }).finally(() => {
        stopLoading()
      })
    }
  }

  return (
    <WarningConfirmModal
      description={t(`message.questions.deleteItemTask${taskToDelete?.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}`)}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => setTaskToDelete(undefined)}
      {...restProps}
    />
  )
}
