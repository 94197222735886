import { useLazyQuery } from '@apollo/client'
import { GetReportByNameQuery, Report, ReportFileStatus } from '@app/graphql/__types__/graphql'
import { FILE_REPORTS_GET_BY_NAME } from '@app/graphql/requests'
import { useEffect, useRef, useState } from 'react'
import { EDownloadFileType, REPORT_GENERATION_STATUS } from '../enums'
import { OptimusClientConfig } from '../clientConfig'
import AppNotifications from '@app/services/notification'
import { useHolisAuth } from '@holis/auth-client-react'
import { useTranslation } from 'react-i18next'
import { addTokenToUrl, downloadFileFromUrl } from '../functions'
import { useLayoutStore } from '@app/stores/layout'
import axios from 'axios'

export enum ItemType {
  report = 'REPORT',
  workpack = 'WORKPACK',
  notif = 'NOTIF',
  plan = 'PLAN',
}

export const getFileHeader = (type: ItemType) => {
  switch (type) {
    case ItemType.report:
      return 'REPORT-'
    case ItemType.workpack:
      return 'WRKP-'
    case ItemType.notif:
      return ''
    case ItemType.plan:
      return 'PLAN-'
    default:
      return ''
  }
}

export const getDocNameTypeLabel = (type: ItemType) => {
  switch (type) {
    case ItemType.report:
      return 'REPORT'
    case ItemType.workpack:
      return 'WRKP'
    case ItemType.notif:
      return 'IAN'
    case ItemType.plan:
      return 'PLAN'
    default:
      return ''
  }
}

export const getGenerationUrlTypeLabel = (type: ItemType) => {
  switch (type) {
    case ItemType.report:
    case ItemType.workpack:
      return 'event'
    case ItemType.notif:
      return 'notification'
    case ItemType.plan:
      return 'plan'
    default:
      return ''
  }
}

type TReportGenerationListeners = {
  onReportGenerationCompleted?: () => void
  onReportGenerationFailed?: () => void
  onReportGenerationProcessing?: () => void
}

export default function useReportGeneration(itemType: ItemType, itemId?: number, itemRef?: string, listeners?: TReportGenerationListeners) {
  const [hasReport, setHasReport] = useState<boolean>(false)
  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false)
  const awaitingForDownload = useRef(false)

  const { getAccessToken } = useHolisAuth()
  const { startLoading, stopLoading } = useLayoutStore()

  const { t } = useTranslation()

  const [getReportByDocNameApi] = useLazyQuery<GetReportByNameQuery>(FILE_REPORTS_GET_BY_NAME)

  const updateReportStatus = async () => {
    const response = await getReportByDocNameApi({
      variables: {
        docName: `${getDocNameTypeLabel(itemType)}-${itemRef}`,
      },
    })
    const report = response.data?.report as Report | undefined
    setHasReport(report?.status === ReportFileStatus.Completed)
    setIsReportGenerating(report?.status === ReportFileStatus.InProcess)
  }

  const downloadReport = async () => {
    startLoading()
    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${`${getDocNameTypeLabel(itemType)}-${itemRef}`}?type=${EDownloadFileType.REPORT}&_ts=${Date.now()}`, getAccessToken)
    downloadFileFromUrl(reportUrl, `${getFileHeader(itemType)}${itemRef}.pdf`, {
      onEnd() {
        stopLoading()
      },
      onFail(err) {
        console.log(err)
        stopLoading()
        AppNotifications.error(t('message.error.default.title'))
      },
      getAccessToken,
      noCache: true,
    })
  }

  const openReport = async (inNewTab = true) => {
    startLoading()
    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${`${getDocNameTypeLabel(itemType)}-${itemRef}`}?type=${EDownloadFileType.REPORT}`, getAccessToken)

    if (inNewTab) {
      window.open(reportUrl, '_blank')
    } else {
      // eslint-disable-next-line react-compiler/react-compiler
      window.location.href = reportUrl
    }

    stopLoading()
  }

  const onSocketReportGenerationProcessing = () => {
    setIsReportGenerating(true)
    listeners?.onReportGenerationProcessing?.()
  }

  const onSocketReportGenerationFailed = () => {
    setIsReportGenerating(false)
    listeners?.onReportGenerationFailed?.()
    AppNotifications.error(t('message.report.generation.failed'))
  }

  const onSocketReportGenerationCompleted = () => {
    setHasReport(true)
    setIsReportGenerating(false)
    listeners?.onReportGenerationCompleted?.()
    AppNotifications.success(t('message.report.generation.completed'))
    if (awaitingForDownload.current) {
      downloadReport()
    }

    awaitingForDownload.current = false
  }

  const setSocketListeners = () => {
    OptimusClientConfig.current.socket
      ?.on(REPORT_GENERATION_STATUS.IN_PROCESS, onSocketReportGenerationProcessing)
      .on(REPORT_GENERATION_STATUS.FAILED, onSocketReportGenerationFailed)
      .on(REPORT_GENERATION_STATUS.COMPLETED, onSocketReportGenerationCompleted)
  }

  const unsetSocketListeners = () => {
    OptimusClientConfig.current.socket
      ?.off(REPORT_GENERATION_STATUS.IN_PROCESS, onSocketReportGenerationProcessing)
      .off(REPORT_GENERATION_STATUS.FAILED, onSocketReportGenerationFailed)
      .off(REPORT_GENERATION_STATUS.COMPLETED, onSocketReportGenerationCompleted)
  }

  const launchReportGeneration = async (downloadAfterGeneration = true) => {
    if (downloadAfterGeneration) {
      awaitingForDownload.current = true
    }

    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.reportBaseUrl}/${getGenerationUrlTypeLabel(itemType)}/${itemId}`, getAccessToken)

    axios.get(reportUrl).then(() => {
      setIsReportGenerating(true)
      AppNotifications.success(t('message.report.generation.requestSaved'))
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    }).finally(stopLoading)
  }

  useEffect(() => {
    if (!itemId) {
      return
    }

    updateReportStatus()
    setSocketListeners()

    return () => unsetSocketListeners()
  }, [itemType, itemId])

  return {
    hasReport,
    isReportGenerating,
    launchReportGeneration,
    downloadReport,
    openReport,
  }
}
