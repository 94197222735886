import React, { PropsWithChildren } from 'react'
import { cn } from '@holis/react-ui/utils'
import SpinnerLoader from './SpinnerLoader'
import { useTranslation } from 'react-i18next'

type TSpinnerLoaderComponent = Readonly<PropsWithChildren & {
  isLoading?: boolean
  className?: string
  contentClassName?: string
  spinnerClassName?: string
  ref?: React.RefObject<HTMLDivElement>
  contentRef?: React.RefObject<HTMLDivElement>
  error?: React.ReactNode
  style?: React.CSSProperties
}>

/**
 * Show loader or error or component on ready
 *
 */
export default function SpinnerLoaderComponent({ isLoading: loading, ref, className, children, spinnerClassName, error, contentClassName, style, contentRef }: TSpinnerLoaderComponent) {
  const { t } = useTranslation()
  return (
    <div
      ref={ref}
      className={cn(`w-full h-full relative${loading ? ' bg-gray-300' : ''}`, className)}
      style={style}
    >
      <SpinnerLoader className={cn('w-[50px] h-[50px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center absolute m-auto bg-transparent', spinnerClassName)} isLoading={loading} />
      <div ref={contentRef} className={cn(`w-full h-full ${loading ? 'opacity-80 pointer-events-none' : ''}`, contentClassName)}>
        {!!error && <div className="text-red-700 m-auto flex text-center justify-center items-center text-sm font-medium h-full">{error === true ? t('message.error.default.title') : error}</div>}
        {!error && children}
      </div>
    </div>
  )
}
