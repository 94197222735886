import { gql } from '@apollo/client'
import { INTEGRITY_REF_CONDITIONS_COMMON_FIELDS } from './refIntegrityConditions'

export const DAMAGE_REF_VAL_CODES_COMMON_FIELDS = gql`
  ${INTEGRITY_REF_CONDITIONS_COMMON_FIELDS}
  fragment RefDamageValCodeCommonFields on RefDamageValCode {
    id
    description
    valCode
    codeCond
    isActive
    isFixed
    integrityCondition {
      ...RefIntegrityConditionCommonFields
    }
}`
