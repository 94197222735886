import { useLazyQuery } from '@apollo/client'
import DocumentListWithViewer from '@app/components/Common/Block/Document/DocumentBlock/DocumentListWithViewer'
import FlocDocumentSelectionModal from '@app/components/Common/Block/Document/DocumentBlock/FlocDocumentSelectionModal'
import { FlocDoc, GetFlocDocsByFlocIdQuery } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_DOCUMENTS_FLOCS_GET_BY_FLOC_ID } from '@app/graphql/requests'
import { useFlocStore } from '@app/stores/methodEngineering/floc'
import { useEffect } from 'react'

type TDocumentationPage = Readonly<{
  visible: boolean
}>
export default function DocumentationPage({ visible }: TDocumentationPage) {
  const { documents, setDocuments, activeFloc, changeDocumentSelectionModalDisplay, documentSelectionModalDisplayed, setFetchDocuments } = useFlocStore()
  const [getDocumentsByFlocIdApi] = useLazyQuery<GetFlocDocsByFlocIdQuery>(DOCUMENTATION_DOCUMENTS_FLOCS_GET_BY_FLOC_ID)

  const getDocuments = () => {
    if (activeFloc) {
      getDocumentsByFlocIdApi({
        variables: {
          flocId: activeFloc.id,
        },
        fetchPolicy: 'no-cache',
      })
        .then(result => setDocuments((result.data?.flocDocs as Partial<FlocDoc>[] | undefined)?.map(flocDoc => flocDoc.document!)))
        .catch(() => setDocuments(undefined))
    }
  }

  useEffect(() => {
    setFetchDocuments(getDocuments)
    getDocuments()
  }, [activeFloc])

  if (!documents) {
    return 'no documents'
  }

  return (
    <>
      <div className={`h-full ${visible ? '' : 'hidden'}`}>
        <DocumentListWithViewer
          documents={documents}
          onManageDocumentsClick={() => changeDocumentSelectionModalDisplay(true)}
        />
      </div>
      {documentSelectionModalDisplayed && <FlocDocumentSelectionModal />}
    </>
  )
}
