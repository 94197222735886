import { useTranslation } from 'react-i18next'
import { RadButton, RadInput } from '@holis/react-ui/rad'
import { LuFilterX, LuSearch } from 'react-icons/lu'
import { cn } from '@holis/react-ui/utils'
import { ChangeEvent, useRef, useState } from 'react'

type TSearchbar = Readonly<{
  onClear?: () => void
}> & (typeof RadInput)['defaultProps']

export default function SearchBar({ className, onClear, ...restProps }: TSearchbar) {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState('')

  return (
    <div className={cn('flex flex-row items-center min-w-[200px]', className)}>
      <LuSearch size={18} className="relative left-2 text-gray-300" />
      <RadInput
        ref={inputRef}
        placeholder={t('label.search')}
        className="w-full !pl-9 relative -left-[18px] -mr-[17px] h-10 min-w-0 mt-[1px]"
        {...restProps}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          restProps.onChange?.(e)
        }}
      />
      { inputValue
        && (
          <RadButton
            variant="icon"
            className="text-destructive border-destructive border rounded px-3 ml-2 hover:bg-red-50"
            onClick={() => {
              setInputValue('')
              restProps.onChange?.({ target: { value: '' } } as unknown as ChangeEvent<HTMLInputElement>)
              onClear?.()
            }}
          >
            <LuFilterX size={18} />
          </RadButton>
        )}
    </div>
  )
}
