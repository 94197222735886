import { TAppTabItem } from '@app/types/app'
import React, { ReactNode, RefObject, UIEvent, useEffect, useRef, useState } from 'react'
import SimplePageSelector from '../SimplePageSelector'

type TSinglePageVerticalTabs = Readonly<{
  items: TAppTabItem[]
  renderTitle?: (item: TAppTabItem) => React.ReactNode
  renderPanel?: (item: TAppTabItem) => React.ReactNode
  value?: ReactNode
}>

export default function SinglePageVerticalTabs({ items, renderPanel, value }: TSinglePageVerticalTabs) {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [tabAnchRefs, setTabAnchRefs] = useState<RefObject<HTMLDivElement>[]>([])

  const handleSetAnchor = (pos: number) => {
    if (tabAnchRefs[pos]?.current) {
      const elPos = tabAnchRefs[pos]!.current!.offsetTop - scrollContainerRef.current!.offsetTop
      scrollContainerRef.current?.scrollTo({
        top: elPos,
        behavior: 'smooth',
      })
    }

    setSelectedTab(pos)
  }

  const handleInnerPanelScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollPos = e.currentTarget.scrollTop
    let newPos = 0
    for (let i = items.length - 1; i >= 0; i--) {
      const anchorRef = tabAnchRefs[i]
      if (anchorRef?.current) {
        const elPos = anchorRef!.current!.offsetTop - e.currentTarget.offsetTop - 75
        if (elPos <= scrollPos) {
          newPos = i
          break
        }
      }
    }

    setSelectedTab(newPos)
  }

  useEffect(() => {
    const _tabAnchRefs: RefObject<HTMLDivElement>[] = []

    if (items) {
      items.forEach((item: TAppTabItem, index: number) => {
        _tabAnchRefs[index] = React.createRef<HTMLDivElement>()
      })
    }

    setTabAnchRefs(_tabAnchRefs)
  }, [items])

  useEffect(() => {
    if (typeof value !== 'undefined') {
      const index = items.findIndex((item: TAppTabItem) => item.value === value)
      if (index > -1) {
        setSelectedTab(index)
      } else if (typeof value === 'number') {
        setSelectedTab(value)
      }
    }
  }, [value])

  return (
    <div className="flex flex-col h-full">
      <div className="min-h-14 flex flex-col justify-center p-2 shadow-sm mb-2">
        <div className="-mb-1">
          <SimplePageSelector
            items={
              items.map((item: TAppTabItem, index: number) => ({
                label: item.title as string,
                value: index.toString(),
                counterValue: item.counterValue,
              }))
            }
            selectedValue={selectedTab.toString()}
            onSelectedValueChanged={v => handleSetAnchor(Number(v))}
          />
        </div>
      </div>
      <div ref={scrollContainerRef} className="flex-1 px-4 pb-32 overflow-y-auto" onScroll={handleInnerPanelScroll}>
        {items.map((item: TAppTabItem, index: number) => (
          <div ref={tabAnchRefs[index]} key={item.value} className="mt-3">
            {renderPanel?.(item) ?? item.content}
          </div>
        ))}
      </div>
    </div>
  )
}
