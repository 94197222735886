import { useLazyQuery, useQuery } from '@apollo/client'
import AppSelect from '@app/components/Common/Form/AppSelect'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper'
import { AppListItem } from '@app/components/Common/Form/list-item'
import { GetAllFlocsAutocompleteQuery, FunctionalLocation, GetAllRefEventTechniquesQuery, RefEventTechnique, GetAllRefMeasPointCodeGroupsQuery, RefMeasPointCodeGroup, GetAllRefMeasPointClassesQuery, RefMeasPointClass, GetAllRefMeasPointCharsQuery, RefMeasPointChar } from '@app/graphql/__types__/graphql'
import { FLOCS_GET_ALL_AUTOCOMPLETE, MEASPOINT_REF_CHARS_GET_MANY, MEASPOINT_REF_CLASSES_GET_MANY, MEASPOINT_REF_CODE_GROUPS_GET_MANY, WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY } from '@app/graphql/requests'
import { MAX_LENGTH_VALIDATORS } from '@app/stores/cml'
import useInspectionPointStore from '@app/stores/insp'
import { RadFormField, RadInput, RadTextarea } from '@holis/react-ui/rad'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

type TForm = Readonly<{
  setFormValue?: (name: string, value: unknown) => void
  floc?: Partial<FunctionalLocation>
}>

export default function Form({ setFormValue, floc }: TForm) {
  const { updateDataField } = useInspectionPointStore()
  const [flocs, setFlocs] = useState<AppListItem[]>(floc
    ? [{
        label: `${floc.floc} - ${floc.description?.replace('"', '\'\'')}`,
        value: floc.id,
      }]
    : [])
  const [classes, setClasses] = useState<AppListItem[]>([])
  const [codeGroups, setCodeGroups] = useState<AppListItem[]>([])
  const [selectedClass, setSelectedClass] = useState<Partial<RefMeasPointClass>>()
  const [techniques, setTechniques] = useState<AppListItem[]>([])
  const [chars, setChars] = useState<AppListItem[]>([])
  const [getAllFlocsAutocompleteApi] = useLazyQuery<GetAllFlocsAutocompleteQuery>(FLOCS_GET_ALL_AUTOCOMPLETE)
  const setFlocsFromQueryDatas = (flocs: Partial<FunctionalLocation>[]) => {
    setFlocs(flocs.map(floc => ({
      label: `${floc.floc} - ${floc.description?.replace('"', '\'\'')}`,
      value: floc.id,
    })).sort((a, b) => a.label.localeCompare(b.label)))
  }

  const { loading: codeGroupsLoading, data: codeGroupsQuery } = useQuery<GetAllRefMeasPointCodeGroupsQuery>(MEASPOINT_REF_CODE_GROUPS_GET_MANY)
  const { loading: classesLoading } = useQuery<GetAllRefMeasPointClassesQuery>(MEASPOINT_REF_CLASSES_GET_MANY, {
    onCompleted(data) {
      setClasses(data.refMeasPointClasses.map((cl: Partial<RefMeasPointClass>) => ({
        item: cl,
        label: `[${cl.class}]${cl.description ? ` - ${cl.description}` : ''}`,
        value: cl.id,
      } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)))
    },
  })
  const { loading: techniquesLoading } = useQuery<GetAllRefEventTechniquesQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY, {
    onCompleted(data) {
      setTechniques(data.refEventTechniques.map((technique: Partial<RefEventTechnique>) => ({
        label: `[${technique.technique}]${technique.description ? ` - ${technique.description}` : ''}`,
        value: technique.id,
      } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)))
    },
  })
  const { loading: charsLoading } = useQuery<GetAllRefMeasPointCharsQuery>(MEASPOINT_REF_CHARS_GET_MANY, {
    onCompleted(data) {
      setChars(data.refMeasPointChars.map((char: Partial<RefMeasPointChar>) => ({
        label: `[${char.char}]${char.description ? ` - ${char.description}` : ''}`,
        value: char.id,
      } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)))
    },
  })

  const [flocsLoading, setFlocsLoading] = useState(false)

  useEffect(() => {
    if (!floc) {
      setFlocsLoading(false)
    }

    setFlocsLoading(true)
    getAllFlocsAutocompleteApi()
      .then((r) => {
        setFlocsFromQueryDatas(r.data?.functionalLocations ?? [])
        setFlocsLoading(false)
      })
  }, [floc])

  useEffect(() => {
    if (flocs.length === 1) {
      updateDataField('flocId', flocs[0].value)
    }
  }, [flocs])

  useEffect(() => {
    const codeGrps = codeGroupsQuery?.refMeasPointCodeGroups
      .filter((cg: Partial<RefMeasPointCodeGroup>) => !selectedClass || !!selectedClass.classCodeGroups?.map(clCg => clCg.codeGroupId).includes(cg.id!))
      .map((cg: Partial<RefMeasPointCodeGroup>) => ({
        label: `[${cg.codeGroup}]${cg.description ? ` - ${cg.description}` : ''}`,
        value: cg.id,
        item: cg as RefMeasPointCodeGroup,
      }))?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
    console.log(selectedClass, codeGrps)
    setCodeGroups(codeGrps)
    setFormValue?.('classId', selectedClass?.id)
    if (codeGrps.length === 1) {
      setFormValue?.('codeGroupId', codeGrps[0].value)
    } else {
      setFormValue?.('codeGroupId', undefined)
    }
  }, [codeGroupsQuery, selectedClass?.id])

  useEffect(() => {
    if (flocs.length === 1) {
      setFormValue?.('flocId', flocs[0].value)
    }
  }, [flocs])

  return (
    <div className="flex flex-col px-0.5 mb-6">

      <div className="flex gap-12">

        <div className="flex-1 flex flex-col gap-4">

          {/* ANCHOR Identification */}
          <FormGroupHeader>
            {t('label.identification')}
          </FormGroupHeader>

          <div className="flex gap-4">

            <div className="w-[80%] overflow-hidden">
              {/* ANCHOR Floc */}
              <RadFormField
                name="flocId"
                render={({ field, fieldState }) => (
                  <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.functionalLocation')}>
                    <AppSelect
                      required
                      disabled={!!floc}
                      items={flocs}
                      placeholder={t('label.selectFloc')}
                      loading={flocsLoading}
                      {...field}
                    />
                  </InputLabelWrapper>
                )}
              />
            </div>

            <div className="flex-grow">
              {/* ANCHOR Position */}
              <RadFormField
                name="position"
                render={({ field, fieldState }) => (
                  <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.position')}>
                    <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.POSITION} />
                  </InputLabelWrapper>
                )}
              />
            </div>

          </div>

          {/* ANCHOR Description */}
          <RadFormField
            name="description"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.description')}>
                <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.DESCRIPTION} />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR Long Description */}
          <RadFormField
            name="longDescription"
            render={
              ({ field, fieldState }) => (
                <InputLabelWrapper fieldState={fieldState} className="w-full" label={t('label.longDescription')}>
                  <RadTextarea
                    {...field}
                    placeholder={t('label.longDescription')}
                    className="h-[148px]"
                    maxLength={MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION}
                  />
                </InputLabelWrapper>
              )
            }
          />

          <div className="flex flex-col">
            <FormGroupHeader>
              {t('label.attributes')}
            </FormGroupHeader>
            <div className="flex gap-4">
              <div className="w-[80%] overflow-hidden">
                <RadFormField
                  name="charId"
                  render={({ field, fieldState }) => (
                    <InputLabelWrapper fieldState={fieldState} label={t('label.char')}>
                      <AppSelect
                        placeholder={t('label.selectChar')}
                        loading={charsLoading}
                        items={chars}
                        {...field}
                      />
                    </InputLabelWrapper>
                  )}
                />
              </div>
              <div className="flex-grow">
                <RadFormField
                  name="externalRef"
                  render={({ field, fieldState }) => (
                    <InputLabelWrapper fieldState={fieldState} label={t('label.externalId')}>
                      <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.EXTERNAL_REF} />
                    </InputLabelWrapper>
                  )}
                />
              </div>
            </div>
          </div>

        </div>

        <div className="w-[400px] flex flex-col gap-4">
          {/* ANCHOR Classification */}
          <FormGroupHeader>
            {t('label.classification')}
          </FormGroupHeader>

          {/* ANCHOR Class */}
          <RadFormField
            name="classId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.class')}>
                <AppSelect
                  key="class"
                  required
                  items={classes}
                  placeholder={t('label.selectClass')}
                  loading={classesLoading}
                  {...field}
                  onChange={(val) => {
                    setSelectedClass(val ? classes.find(cl => cl.value === val as number)?.item as Partial<RefMeasPointClass> : undefined)
                  }}
                />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR Codegroup */}
          <RadFormField
            name="codeGroupId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.codeGroup')}>
                <AppSelect
                  key={`codegroup-${selectedClass}`}
                  required
                  items={codeGroups}
                  disabled={!selectedClass}
                  placeholder={selectedClass ? t('label.selectCodeGroup') : t('label.selectClass')}
                  loading={codeGroupsLoading}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR technique */}
          <RadFormField
            name="techniqueId"
            render={({ field, fieldState }) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.technique')}>
                <AppSelect
                  placeholder={t('label.selectTechnique')}
                  loading={techniquesLoading}
                  items={techniques}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />
        </div>

      </div>

    </div>
  )
}
