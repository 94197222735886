import DamageCard from '@app/components/Common/Block/Damage/DamageBlock/DamageCard'
import DamageListModal from '@app/components/Common/Block/Damage/DamageBlock/DamageListModal'
import DamageModal from '@app/components/Common/Block/Damage/DamageModal'
import IdwgDamageSelectionModal from '@app/components/Common/Block/Damage/IdwgDamageSelectionModal'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import SearchBar from '@app/components/Common/SearchBar'
import { DamageCreateModal } from '@app/components/Modal/Inspection/Damage/Create'
import { Damage, InspectionDrawing } from '@app/graphql/__types__/graphql'
import useDamageStore from '@app/stores/damage'
import useIdwgStore, { IDWG_PREFIX } from '@app/stores/idwg'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { searchArray } from '@app/utils/functions'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { LuList, LuPlusSquare, LuZap } from 'react-icons/lu'

type TDamageList = Readonly<{
  idwg: Partial<InspectionDrawing>
}>

export default function DamageList({ idwg }: TDamageList) {
  const { damages, fetchDamages, changeLastDamageUpdated, changeDamagesSelectionModalDisplay, damagesSelectionDisplayed, latestEventDamages } = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`)
  const { activeDamage, setActiveDamage } = useDamageStore()
  const [damageListModalDisplayed, changeDamageListModalDisplay] = useState<boolean>(false)

  const [filteredDamages, setFilteredDamages] = useState<Partial<Damage>[]>()
  const [filterString, setSearchInput] = useState<string>('')

  const onCreatedOrUpdated = (item?: Partial<Damage>) => {
    if (item) {
      changeLastDamageUpdated(item)
    }

    fetchDamages?.()
  }

  useEffect(() => {
    console.log('set filtered damages', damages, filterString)
    setFilteredDamages(
      searchArray(
        damages ?? [],
        filterString,
        undefined,
        ['__typename'],
      ),
    )
  }, [damages, filterString])

  const handleDamageModalClose = () => {
    setActiveDamage()
  }

  console.log(latestEventDamages, filteredDamages)

  return (
    <>
      <FormGroupHeader
        menuItems={[
          (
            <RadDropdownMenuItem key="manage-damage" onClick={() => changeDamagesSelectionModalDisplay(true)}>
              <LuPlusSquare className="mr-2" />

              {t('label.manageDamage')}
            </RadDropdownMenuItem>
          ),
          (
            <RadDropdownMenuItem key="display-list" onClick={() => changeDamageListModalDisplay(true)}>
              <LuList className="mr-2" />

              {t('label.displayList')}
            </RadDropdownMenuItem>
          ),
        ]}
        actions={(
          <SearchBar
            className="w-[300px]"
            onChange={e => setSearchInput(e.target?.value)}
          />
        )}
      >
        <div className="flex items-center">
          <LuZap size={20} className="mr-2" />

          {t('label.damage')}
        </div>
      </FormGroupHeader>

      <div className="mt-3">
        <div className="flex flex-col gap-1">
          {typeof latestEventDamages !== 'undefined' && filteredDamages?.map(dmg => <DamageCard key={dmg.id} hasMenuContext hideAddNotifBtn objectItem={idwg} dmgObject={latestEventDamages?.[dmg.id!] ?? dmg} />)}
        </div>
      </div>

      {!!activeDamage && activeDamage.id !== OBJ_NEW_ID && <DamageModal isOpen item={activeDamage} onCreatedOrUpdated={onCreatedOrUpdated} onClose={handleDamageModalClose} />}
      {!!activeDamage && activeDamage.id === OBJ_NEW_ID && <DamageCreateModal isOpen item={activeDamage} onCreated={onCreatedOrUpdated} onOpenChange={isOpen => !isOpen && handleDamageModalClose()} />}
      {damagesSelectionDisplayed && <IdwgDamageSelectionModal idwg={idwg} />}
      {damageListModalDisplayed && <DamageListModal open hasItems sortOrders={['asc']} items={damages} onClose={() => changeDamageListModalDisplay(false)} />}
    </>
  )
}
