import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Cml, CreateIdwgTechniquesMutation, Damage, GetAllRefEventTechniquesQuery, GetTechniquesByIdwgIdQuery, InspectionDrawing, RefEventTechnique } from '@app/graphql/__types__/graphql'
import { DWG_IDWG_TECHNIQUE_CREATE_MANY, WORKORDER_REF_EVENT_TECHNIQUES_GET_BY_IDWG_ID, WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY } from '@app/graphql/requests'
import { useEffect, useState } from 'react'
import { LuPlusSquare } from 'react-icons/lu'
import TechniqueFilterButton from './components/TechniqueFilterButton'
import { NO_TECHNIQUE_ID } from '@app/stores/idwg'
import _ from 'lodash'
import AppCombobox from '@app/components/Common/Form/AppCombobox'
import AppNotifications from '@app/services/notification'
import { useTranslation } from 'react-i18next'

type TIdwgTechniques = Readonly<{
  idwg: Partial<InspectionDrawing>
  cmlsDisplayed: boolean
  deleteTechniqueDisabled?: boolean
  techniqueCreateBtnHidden?: boolean
  damagesDisplayed: boolean
  damages?: Partial<Damage>[]
  cmls?: Partial<Cml>[]
  idwgTechniqueIdsHidden?: number[]
  setIdwgTechniqueIdsHidden?: (idwgTechniqueIdsHidden?: number[]) => void
}>
export default function IdwgTechniques({ damages, cmls, cmlsDisplayed, damagesDisplayed, idwg, idwgTechniqueIdsHidden, setIdwgTechniqueIdsHidden, deleteTechniqueDisabled, techniqueCreateBtnHidden }: TIdwgTechniques) {
  const { t } = useTranslation()
  const [refEventTechniques, setRefEventTechniques] = useState<Partial<RefEventTechnique>[]>()
  const [refEventTechniquesToAdd, setRefEventTechniquesToAdd] = useState<Partial<RefEventTechnique>[]>()
  const [refEventTechniquesByIdwg, setRefEventTechniquesByIdwg] = useState<Partial<RefEventTechnique>[]>()
  const [countersByTechnique, setCountersByTechnique] = useState<Record<number, number>>()
  const [realCountersByTechnique, setRealCountersByTechnique] = useState<Record<number, number>>()
  const { data: refEventTechniquesResult, loading: refEventTechniquesLoading } = useQuery<GetAllRefEventTechniquesQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY)
  const [getRefEventTechniquesByIdwgApi, { data: refEventTechniquesByIdwgResult, loading: refEventTechniquesResultByIdwgLoading }] = useLazyQuery<GetTechniquesByIdwgIdQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_BY_IDWG_ID, {
    variables: {
      idwgId: idwg.id!,
    },
    fetchPolicy: 'no-cache',
  })
  const [addIdwgTechniquesApi] = useMutation<CreateIdwgTechniquesMutation>(DWG_IDWG_TECHNIQUE_CREATE_MANY)

  const handleTechniqueFilterClick = (techniqueId: number) => {
    let hiddenIds = [...(idwgTechniqueIdsHidden ?? [])]
    if (hiddenIds.includes(techniqueId)) {
      hiddenIds = hiddenIds.filter(id => techniqueId !== id)
    } else {
      hiddenIds.push(techniqueId)
    }

    setIdwgTechniqueIdsHidden?.(hiddenIds)
  }

  const handleDeleteTechnique = (techniqueId: number) => {
    setRefEventTechniquesByIdwg(refEventTechniquesByIdwg?.filter(idwgTech => idwgTech.id !== techniqueId) ?? [])
  }

  const handleNewIdwgTechnique = (item: Partial<RefEventTechnique>) => {
    addIdwgTechniquesApi({
      variables: {
        data: [{
          idwgId: idwg.id!,
          techniqueId: item.id!,
        }],
      },
    }).then(() => setRefEventTechniquesByIdwg(_.sortBy(_.unionBy<Partial<RefEventTechnique>>([...(refEventTechniquesByIdwg ?? [])], [item], item => item.id), [item => item.technique]))).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    })
  }

  useEffect(() => {
    if (!refEventTechniquesLoading) {
      setRefEventTechniques((refEventTechniquesResult?.refEventTechniques ?? []) as RefEventTechnique[])
    }
  }, [refEventTechniquesResult, refEventTechniquesLoading])

  useEffect(() => {
    if (!refEventTechniquesResultByIdwgLoading) {
      setRefEventTechniquesByIdwg(_.sortBy((refEventTechniquesByIdwgResult?.refEventTechniques ?? []) as RefEventTechnique[], [item => item.technique]))
    }
  }, [refEventTechniquesByIdwgResult, refEventTechniquesResultByIdwgLoading])

  useEffect(() => {
    if (refEventTechniquesByIdwg?.length) {
      const counters: Record<number, number> = {}
      const realCounters: Record<number, number> = {}
      cmls?.forEach((cml) => {
        const { techniqueId } = cml
        realCounters[techniqueId ?? NO_TECHNIQUE_ID] = (realCounters[techniqueId ?? NO_TECHNIQUE_ID] ?? 0) + 1
        if (cmlsDisplayed) {
          counters[techniqueId ?? NO_TECHNIQUE_ID] = (counters[techniqueId ?? NO_TECHNIQUE_ID] ?? 0) + 1
        }
      })

      damages?.forEach((dmg) => {
        const { techniqueId } = dmg
        realCounters[techniqueId ?? NO_TECHNIQUE_ID] = (realCounters[techniqueId ?? NO_TECHNIQUE_ID] ?? 0) + 1
        if (damagesDisplayed) {
          counters[techniqueId ?? NO_TECHNIQUE_ID] = (counters[techniqueId ?? NO_TECHNIQUE_ID] ?? 0) + 1
        }
      })

      setCountersByTechnique(counters)
      setRealCountersByTechnique(realCounters)
    }
  }, [refEventTechniquesByIdwg, cmlsDisplayed, damagesDisplayed, cmls, damages])

  useEffect(() => {
    if (refEventTechniquesToAdd?.length !== 0 && (typeof cmls !== 'undefined' || typeof damages !== 'undefined') && getRefEventTechniquesByIdwgApi) {
      getRefEventTechniquesByIdwgApi()
    }
  }, [cmls, damages, getRefEventTechniquesByIdwgApi])

  useEffect(() => {
    if (typeof refEventTechniquesByIdwg?.length === 'number' && typeof refEventTechniques?.length === 'number') {
      setRefEventTechniquesToAdd(_.differenceBy(refEventTechniques, refEventTechniquesByIdwg, item => item.id))
    }
  }, [refEventTechniquesByIdwg, refEventTechniquesByIdwg])

  return (
    <div className="flex flex-col p-2 h-full items-center gap-7 overflow-y-auto overflow-x-hidden">
      {!!refEventTechniquesToAdd?.length && !techniqueCreateBtnHidden && (
        <div
          className="[&_svg:last-child]:hidden"
        >
          <AppCombobox<Partial<RefEventTechnique>>
            required
            triggerClassName="custom-trigger w-[30px] h-[30px] px-1 py-1 justify-center"
            triggerContent={<LuPlusSquare />}
            popOverWidth={300}
            items={refEventTechniquesToAdd.map(refEventTechniqueToAdd => ({
              ...refEventTechniqueToAdd,
              value: refEventTechniqueToAdd.id,
              label: `[${refEventTechniqueToAdd.technique}] - ${refEventTechniqueToAdd.description}`,
            }))}
            onSelectedItemChanged={item => handleNewIdwgTechnique(item as Partial<RefEventTechnique>)}
          />
        </div>
      )}
      {!!countersByTechnique?.[NO_TECHNIQUE_ID] && <TechniqueFilterButton key="tech-0" active={!idwgTechniqueIdsHidden?.includes(NO_TECHNIQUE_ID)} idwg={idwg} refEventTechnique={undefined} counter={countersByTechnique?.[NO_TECHNIQUE_ID]} deleteDisabled={deleteTechniqueDisabled} onFilterClick={() => handleTechniqueFilterClick(NO_TECHNIQUE_ID)} onDelete={deleteTechniqueDisabled ? undefined : handleDeleteTechnique} />}
      {refEventTechniquesByIdwg?.map(idwgTech => (
        <TechniqueFilterButton key={idwgTech.id!} active={!idwgTechniqueIdsHidden?.includes(idwgTech.id!)} idwg={idwg} refEventTechnique={idwgTech} counter={countersByTechnique?.[idwgTech.id!]} realCounter={realCountersByTechnique?.[idwgTech.id!]} deleteDisabled={deleteTechniqueDisabled} onFilterClick={() => handleTechniqueFilterClick(idwgTech.id!)} onDelete={deleteTechniqueDisabled ? undefined : handleDeleteTechnique} />
      ))}
    </div>
  )
}
