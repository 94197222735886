import { useQuery } from '@apollo/client'
import BlockTitle from '@app/components/Common/Text/BlockTitle'
import { Damage, GetNotificationsByDmgIdQuery, Notification } from '@app/graphql/__types__/graphql'
import { NOTIFICATIONS_GET_BY_DMG_ID } from '@app/graphql/requests'
import { useTranslation } from 'react-i18next'
import { LuClipboardList } from 'react-icons/lu'
import IanCard from '../../../Ian/IanCard'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'

type TNotificationBlock = Readonly<{
  damage: Partial<Damage>
}>

export default function NotificationBlock({ damage }: TNotificationBlock) {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery<GetNotificationsByDmgIdQuery>(NOTIFICATIONS_GET_BY_DMG_ID, {
    variables: {
      dmgId: damage.id!,
    },
    fetchPolicy: 'no-cache',
  })
  return (
    <div className="flex flex-col w-full">
      <BlockTitle className="uppercase text-primary px-2 font-bold">
        <LuClipboardList size={20} className="mr-2" />
        {' '}
        {t('label.ian')}
      </BlockTitle>
      <SpinnerLoaderComponent isLoading={loading} error={!!error} contentClassName="flex flex-col gap-2 p-4">
        {((data?.notifications ?? []) as Partial<Notification>[]).map((notif: Partial<Notification>) => <IanCard key={`notif-card-${notif.id}`} notification={notif} />)}
      </SpinnerLoaderComponent>
    </div>
  )
}
