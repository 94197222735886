import { Cml, CmlUncheckedCreateInput, CmlUncheckedUpdateInput, EFlagStatus, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import * as fabric from 'fabric'
import { TFunction } from 'i18next'
import { z } from 'zod'

type State = {
  cmlFieldErrors: Record<string, boolean>
  deleteCmlModalOpen: boolean
  editCml?: Partial<Cml> | null
  updateCmlData?: CmlUncheckedUpdateInput | CmlUncheckedCreateInput
  activeCml?: Partial<Cml>
  activeInspectionDrawing?: Partial<InspectionDrawing>
}

const initialState: State = {
  deleteCmlModalOpen: false,
  editCml: null,
  updateCmlData: {},
  cmlFieldErrors: {},
  activeCml: undefined,
  activeInspectionDrawing: undefined,
}

export const MAX_LENGTH_VALIDATORS = {
  POSITION: 10,
  DIAMETER: 5,
  DESCRIPTION: 50,
  LONG_DESCRIPTION: 500,
  EXTERNAL_REF: 45,
}

export const ZOD_CML_DATAS = (t: TFunction) => ({

  flocId: z.number({ required_error: t('message.error.form.required') }).int().positive({ message: t('message.error.form.required') }),

  position: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.POSITION).min(1, { message: t('message.error.form.required') }),

  description: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.DESCRIPTION).min(1, { message: t('message.error.form.required') }),
  diameter: z.string().max(MAX_LENGTH_VALIDATORS.DIAMETER).optional(),
  longDescription: z.string().max(MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION).optional(),
  externalRef: z.string().max(MAX_LENGTH_VALIDATORS.EXTERNAL_REF).optional(),

  codeGroupId: z.number({ required_error: t('message.error.form.required') }).int().positive({ message: t('message.error.form.required') }),
})

type Actions = {
  updateCml: (cml: Partial<Cml>, isNew?: boolean) => void
  deleteCml: (cml?: Partial<Cml>) => void
  setUpdateCmlData: (updateCmlData: CmlUncheckedUpdateInput | CmlUncheckedCreateInput) => void
  setEditCml: (cml?: Partial<Cml> | null) => void
  updateCmlDataField: (field: string, value: unknown) => void
  setActiveCml: (activeCml?: Partial<Cml>) => void
  changeDeleteCmlModalDisplay: (isOpen: boolean) => void
  isSaved: () => boolean
  hasError: () => boolean
  hasFieldError: (field: string, forceCheck?: boolean) => boolean
  cancelEditData: () => void
  createNewCmlMarkup: (position: fabric.Point, inspectionDrawing: Partial<InspectionDrawing>, fromEvent?: boolean) => void
  resetData: () => void
}

type CmlState = State & Actions

const useCmlStore = create<CmlState>()(
  immer((set, get) => ({
    ...initialState,
    resetData() {
      set({ ...initialState })
    },
    createNewCmlMarkup(position, inspectionDrawing, fromEvent = false) {
      const newState: Partial<State> = {}
      const coordinates: string = JSON.stringify({ x: position.x, y: position.y })
      const cml: Partial<Cml> = {
        id: OBJ_NEW_ID,
        coordinates2d: coordinates,
        markerCoordinates2d: coordinates,
        idwgId: inspectionDrawing!.id!,
        inspectionDrawing: { ...inspectionDrawing! } as InspectionDrawing,
        display2d: true,
        flagStatus: fromEvent ? EFlagStatus.Y : EFlagStatus.A,
      }
      newState.updateCmlData = {
        coordinates2d: coordinates,
        markerCoordinates2d: coordinates,
        idwgId: inspectionDrawing!.id!,
        display2d: true,
        flagStatus: fromEvent ? EFlagStatus.Y : EFlagStatus.A,
      } as CmlUncheckedCreateInput
      newState.activeCml = cml
      newState.editCml = { ...cml }
      set(newState)
    },
    cancelEditData: () => set((state) => {
      const newState: Partial<State> = {
        updateCmlData: {},
        cmlFieldErrors: {},
        editCml: { ...state.activeCml },
      }
      if (state.activeCml?.id === OBJ_NEW_ID) {
        newState.activeCml = undefined
        newState.editCml = undefined
      }

      return newState
    }),
    isSaved() {
      const state = get()
      if (state.activeCml) {
        return !(state.updateCmlData && Object.keys(state.updateCmlData).length > 0)
      }

      return true
    },

    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get()
      if (state.activeCml) {
        if (!Object.keys(state.updateCmlData ?? {}).includes(field) && !forceCheck) {
          return false
        }

        if (state.cmlFieldErrors?.[field] === true) {
          return true
        }

        switch (field) {
          case 'description':
          { const description = (state.editCml?.description ?? '').trim()
            return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '' }
          case 'longDescription':
          { const longDescription = (state.editCml?.description ?? '').trim()
            return longDescription.length > MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION }
          case 'position':
          { const position = (state.editCml?.position ?? '').trim()
            return position.length > MAX_LENGTH_VALIDATORS.POSITION || position === '' }
          case 'codeGroupId':
            return !state.editCml?.codeGroupId
          case 'nominal':
          case 'alarm1':
          case 'alarm2':
          case 'alarm3':
          { const value = state.editCml?.[field]?.trim() ?? ''
            return value !== '' && Number.isNaN(Number.parseFloat(value)) }
          case 'flocId':
          { const flocId = state.editCml?.flocId
            return !flocId }
          default:
            break
        }
      }

      return false
    },
    hasError() {
      const state = get()
      if (state.activeCml) {
        return ['description', 'position', 'longDescription', 'classId', 'codeGroupId', 'nominal', 'techniqueId', 'alarm1', 'alarm2', 'alarm3', 'flocId'].some((field: string) => state.hasFieldError(field, true))
      }

      return false
    },
    setActiveCml: activeCml => set({
      activeCml,
      editCml: activeCml ? { ...activeCml } : undefined,
      updateCmlData: undefined,
      cmlFieldErrors: undefined,
    }),
    updateCmlDataField: (field: string, value: unknown) => set((state) => {
      switch (field) {
        case 'nominal':
        case 'alarm1':
        case 'alarm2':
        case 'alarm3':
          value = String(value).trim()
          if (value === '') {
            value = null
          } else {
            value = (value as string).replace(/,/g, '.')
            value = Number.parseFloat(value as string)
            if (Number.isNaN(value)) {
              value = null
            } else {
              value = String(value)
            }
          }

          break
        default:
          break
      }

      return {
        updateCmlData: {
          ...state.updateCmlData,
          [field]: state.activeCml?.id === OBJ_NEW_ID
            ? value
            : {
                set: value,
              },
        },
        cmlFieldErrors: {
          ...state.cmlFieldErrors,
          [field]: false,
        },
      }
    }),
    setUpdateCmlData(updateCmlData: CmlUncheckedUpdateInput | CmlUncheckedCreateInput) {
      set({ updateCmlData })
    },
    setEditCml(editCml) {
      set({ editCml })
    },
    updateCml: (cml: Partial<Cml>, isNew?: boolean) => set(() => {
      const { activeCml } = get() ?? {}
      const cmlId: number = isNew ? OBJ_NEW_ID : cml.id!
      const newState: Partial<State> = {}

      if (activeCml && cmlId === activeCml.id!) {
        newState.activeCml = {
          ...activeCml,
          ...cml,
        }
        newState.editCml = { ...newState.activeCml }
      }

      return newState
    }),
    deleteCml: (cml?: Partial<Cml>) => set((state) => {
      const deletedCml: Partial<Cml> | undefined | null = cml ?? state.activeCml
      const newState: Partial<State> = {}
      if (deletedCml) {
        newState.deleteCmlModalOpen = false
        if (deletedCml === state.activeCml) {
          newState.activeCml = undefined
        }
      }

      return newState
    }),
    changeDeleteCmlModalDisplay: (isOpen: boolean) => set({
      deleteCmlModalOpen: isOpen,
    }),
  })),
)

export default useCmlStore
