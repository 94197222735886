import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteDocumentByIdMutation, Document } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_DOCUMENTS_DELETE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { ROUTE_DOCUMENTATION_DOCS } from '@app/utils/constants'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type TDeleteDocModal = Readonly<IConfirmModal & {
  doc: Partial<Document>
}>

export default function DeleteDocModal({ doc, ...restProps }: TDeleteDocModal) {
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteDocApi] = useMutation<DeleteDocumentByIdMutation>(DOCUMENTATION_DOCUMENTS_DELETE_BY_ID)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onConfirm = () => {
    startLoading()
    deleteDocApi({
      variables: { id: doc!.id },
    }).then(() => {
      navigate(ROUTE_DOCUMENTATION_DOCS)
      AppNotifications.success(t('message.success.deleteDoc'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteDoc'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      closeOnCancel
      description={t('message.questions.deleteDoc')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      {...restProps}
    />
  )
}
