import { gql } from '@apollo/client'
import { FLOCS_AUTOCOMPLETE_COMMON_FIELDS } from './flocs'
import { GRIDS_AUTOCOMPLETE_COMMON_FIELDS } from './grids'

const FILE_PICTURES_COMMON_FIELDS = gql`
  fragment FilePictureCommonFields on Picture {
    id
    docName
    description
    contentType
    extension
    date
    sort
    wrkoId
    inspId
    cmlId
    dmgeId
    flocId
    annotation
    gridId
    contentWithAnnotationType
    contentWithAnnotationExt
    createdAt
    updatedAt
    fsAdapter
    path
    pathWithAnnotation
  }
`

export const FILE_PICTURES_DETAIL_FIELDS = gql`
  ${FILE_PICTURES_COMMON_FIELDS}
  ${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  fragment FilePictureDetailFields on Picture {
    ...FilePictureCommonFields
    grid {
        ...GridAutocompleteCommonFields
    }
    functionalLocation {
        ...FlocAutocompleteCommonFields
    }
    inspectionPoint {
      position
    }
    damage {
      position
    }
    cml {
      position
    }
  }
`
