import { gql } from '@apollo/client'
import { USER_INFOS_COMMON_FIELDS, USER_ROLES_COMMON_FIELDS } from '../fragments/userRoles'

export const ROLES_GET_ALL = gql`
query GetAllRoles {
  roles {
    id
    role
    users {
      userId
    }
    permissions {
      id
      permissions
      resource
    }
  }
}`

export const USER_ROLES_GET_BY_USER_IDS = gql`
${USER_ROLES_COMMON_FIELDS}
query GetUserRolesByUserIds($ids: [String!]) {
  userRoles(where: {userId: {in: $ids}}) {
    ...UserRoleCommonFields
  }
}`

export const USER_CLEAR_ROLE = gql`
mutation ClearUserRole($userId: String!) {
  deleteManyUserRole(where: {userId: {equals: $userId}}) {
    count
  }
}
`

export const USER_ROLE_UPDATE = gql`
mutation UpdateUserRole($userId: String!, $roleId: Int!, $newRoleId: Int!, $notifyNewReport: Boolean!) {
  upsertOneUserRole(create: {
    roleId: $newRoleId,
    userId: $userId,
    notifyNewReport: $notifyNewReport
  }, update: {
    roleId: {
      set: $newRoleId
    },
    notifyNewReport: {
      set: $notifyNewReport
    }
  }, where: {userId_roleId: {userId: $userId, roleId: $roleId}}) {
    roleId
    userId
    notifyNewReport
  }
}
`

export const ROLE_UPDATE = gql`
mutation UpdateRole($id: Int!, $data: RoleUncheckedUpdateInput!) {
  updateOneRole(where: {id: $id}, data: $data) {
    id
  }
}
`

export const ROLE_CREATE = gql`
mutation CreateRole($data: RoleUncheckedCreateInput!) {
  createOneRole(data: $data) {
    id
  }
}
`

export const ROLE_DELETE = gql`
mutation DeleteRole($id: Int!) {
  deleteOneRole(where: {id: $id}) {
    id
  }
}
`

export const USER_GET_REGISTERED = gql`
${USER_INFOS_COMMON_FIELDS}
query GetRegisteredUsers {
  userRoles {
    userId
    role {
      permissions {
        resource,
        permissions
      }
    }
    userInfo {
      ...UserInfosCommonFields
    }
  }
}
`

export const USER_UPDATE_INFO = gql`
${USER_INFOS_COMMON_FIELDS}
mutation UpdateUserInfo($userId: String!, $data: UserInfoUncheckedCreateInput!) {
  deleteManyUserInfo(where: {userId: {equals: $userId}}) {
    count
  }
  createOneUserInfo(data: $data) {
    ...UserInfosCommonFields
  }
}
`
