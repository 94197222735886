import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu'
import { InspectionDrawing } from '@app/graphql/__types__/graphql'
import { ROUTE_DOCUMENTATION_IDWG_DETAIL } from '@app/utils/constants'
import { generatePathWithBaseUrl } from '@app/utils/functions'
import { RadButton, RadDropdownMenuItem } from '@holis/react-ui/rad'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { LuChevronsLeft, LuPictureInPicture2 } from 'react-icons/lu'
import { generatePath } from 'react-router-dom'

type THeader = Readonly<{
  idwg?: Partial<InspectionDrawing>
  actions?: ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
  goBack?: () => void
}>

export default function Header({ idwg, goBack, title, description, actions }: THeader) {
  const { t } = useTranslation()
  const openDrawing = () => {
    window.open(generatePathWithBaseUrl(generatePath(ROUTE_DOCUMENTATION_IDWG_DETAIL, { number: idwg!.idwg! })), '_blank')
  }

  return (
    <div className="text-gray-700 text-sm font-semibold flex items-center">
      {!!goBack && <RadButton variant="outline" className="mr-4" onClick={goBack}><LuChevronsLeft /></RadButton>}
      <div>
        <div className="flex gap-2 text-gray-700 text-sm font-semibold items-center -mt-1">
          {title ?? idwg?.idwg}
          {(idwg || actions) && (
            <div className="mt-1">
              <ActionThreeDotsMenu height={4}>
                {idwg && (
                  <RadDropdownMenuItem onClick={openDrawing}>
                    <LuPictureInPicture2 className="mr-2" />
                    {' '}
                    {t('label.openDrawing')}
                  </RadDropdownMenuItem>
                )}
                {actions}
              </ActionThreeDotsMenu>
            </div>
          )}
        </div>
        <div className="text-xs font-normal -mt-1">{description ?? idwg?.description}</div>
      </div>
    </div>
  )
}
