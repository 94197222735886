import SingleFormModal from '@app/components/Modal/SingleFormModal'
import { Cml, CreateCmlMutation, Event, FunctionalLocation } from '@app/graphql/__types__/graphql'
import { RadForm } from '@holis/react-ui/rad'
import { t } from 'i18next'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import Footer from './components/Footer'
import Form from './components/Form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import useCmlStore, { ZOD_CML_DATAS } from '@app/stores/cml'
import { useMutation } from '@apollo/client'
import { CMLS_CREATE } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'

type TCmlCreateModal = Readonly<{
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onCreated: (cml: Cml) => void
  item: Partial<Cml>
  event?: Partial<Event>
  floc?: Partial<FunctionalLocation>
}>

export function CmlCreateModal(props: TCmlCreateModal) {
  const { updateCmlDataField, updateCmlData, updateCml, setActiveCml } = useCmlStore()
  const [createCmlApi] = useMutation<CreateCmlMutation>(CMLS_CREATE)
  const { floc } = props

  const htmlForm = useRef<HTMLFormElement>(null)
  const cmlValidationDatas = ZOD_CML_DATAS(t)
  type validationFieldKeys = keyof typeof cmlValidationDatas
  const zodFormObject = z.object(cmlValidationDatas)
  const form = useForm<z.infer<typeof zodFormObject>>(
    { resolver: zodResolver(zodFormObject), mode: 'onSubmit' })

  const handleCreateCml = async () => {
    try {
      const newCmlResult = await createCmlApi({
        variables: {
          data: updateCmlData,
        },
      })
      const newCml = newCmlResult.data?.createOneCml as Cml
      updateCml(newCml, true)
      setActiveCml()
      AppNotifications.success(t('message.success.cmlCreated'))
      props.onCreated?.(newCml)
    } catch {
      AppNotifications.error(t('message.error.default.title'))
    }
  }

  form.watch((datas, { name, type }) => {
    if (type === 'change') {
      updateCmlDataField(name!, datas[name!])
    }
  })

  const setFormFieldValue = (name: string, value: unknown) => {
    form.clearErrors(name as validationFieldKeys)
    form.setValue(name as validationFieldKeys, value as string)
    updateCmlDataField(name, value)
  }

  return (
    <SingleFormModal
      isOpen={props.isOpen}
      title={t('label.cmlCreation')}
      description={t('label.itemCreateDescription')}
      footer={<Footer onSaveClick={() => htmlForm.current?.requestSubmit()} />}
      containerClassName="max-w-[1134px] w-full"
      onOpenChange={props.onOpenChange}
    >
      <RadForm {...form}>
        <form ref={htmlForm} onSubmit={form.handleSubmit(handleCreateCml, data => console.log('form is invalid', data))}>
          <Form setFormValue={setFormFieldValue} floc={floc} idwgId={props.item.idwgId as number} />
        </form>
      </RadForm>
    </SingleFormModal>
  )
}
