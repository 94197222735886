import TaskBlock from '@app/components/Common/Block/Event/EventModal/components/General/ObjectList/TaskBlock'
import CmlBlock from '@app/components/Common/Block/Event/EventModal/components/Inspection/CmlBlock'
import DamageBlock from '@app/components/Common/Block/Event/EventModal/components/Inspection/DamageBlock'
import InspectionPointBlock from '@app/components/Common/Block/Event/EventModal/components/Inspection/InspectionPointBlock'
import { useEventStore } from '@app/stores/event'
import React from 'react'
import { RefObject, UIEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReportSummary from '../RightPanel/pages/Report/components/ReportSummary'
import ReportHeader from '../RightPanel/pages/Report/components/ReportHeader'
import IanList from '../RightPanel/pages/Report/components/IanList/IanList'
import IntegrityStatuses from '../RightPanel/pages/Report/components/IntegrityStatuses'
import AttachmentList from '../RightPanel/pages/Report/components/AttachmentList'
import { ReportingAndInspectionDates } from './components/ReportingAndInspectionDates'
import SimplePageSelector from '@app/components/Common/Tabs/SimplePageSelector'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'

type LeftPanelProps = Readonly<{
  readonly?: boolean
}>

export default function LeftPanel({ readonly }: LeftPanelProps) {
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const [currentAnchor, setCurrentAnchor] = useState('report')
  const { activeEvent, eventNotifications, eventIntegrityStatuses, eventAttachments, eventTasks, eventInspectionPoints, eventCmls, eventDamages } = useEventStore()
  const scrollContainerRef = React.createRef<HTMLDivElement>()
  const anchRef: Record<string, RefObject<HTMLDivElement>> = {}

  const leftPanelTabs = [
    { key: 'report' },
    { key: 'IAN', counterValue: eventNotifications?.length },
    { key: 'integrityStatus', counterValue: eventIntegrityStatuses?.length },
    { key: 'attachments', counterValue: eventAttachments?.length },
    { key: 'task', counterValue: eventTasks?.length },
    { key: 'checklist', counterValue: eventInspectionPoints?.length },
    { key: 'cml', counterValue: eventCmls?.length },
    { key: 'damage', counterValue: eventDamages?.length },
  ]

  for (const k of leftPanelTabs) {
    anchRef[k.key] = React.createRef<HTMLDivElement>()
  }

  const handleSetAnchor = (anchor: string) => {
    if (anchRef[anchor]?.current) {
      const elPos = anchRef[anchor]!.current!.offsetTop - scrollContainerRef.current!.offsetTop
      scrollContainerRef.current?.scrollTo({
        top: elPos,
        behavior: 'smooth',
      })
    }

    setCurrentAnchor(anchor)
  }

  const handleInnerPanelScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollPos = e.currentTarget.scrollTop
    let newCurrTarget = leftPanelTabs[0].key
    for (let i = leftPanelTabs.length - 1; i >= 0; i--) {
      const k = leftPanelTabs[i]
      if (anchRef[k.key]?.current) {
        const elPos = anchRef[k.key]!.current!.offsetTop - e.currentTarget.offsetTop - 75
        if (elPos <= scrollPos) {
          newCurrTarget = k.key
          break
        }
      }
    }

    setCurrentAnchor(newCurrTarget)
  }

  if (!activeEvent) {
    return null
  }

  return (
    <div className="flex flex-col h-full w-full overflow-hidden">

      <div className="min-h-14 flex flex-col justify-center p-2 shadow-sm">
        <div className="-mb-1">
          <SimplePageSelector
            items={
              leftPanelTabs.map(tab => ({
                label: t(`label.${tab.key}`),
                value: tab.key,
                counterValue: tab.counterValue,
              }))
            }
            selectedValue={currentAnchor}
            onSelectedValueChanged={v => handleSetAnchor(v)}
          />
        </div>
      </div>

      <div ref={scrollContainerRef} className="flex-1 mt-4 px-4 pb-6 flex flex-col overflow-auto" onScroll={handleInnerPanelScroll}>

        {/* Task */}
        { !activeEvent?.status?.startsWith('REP_') && (
          <div ref={anchRef.task} className="mb-4">
            <TaskBlock event={activeEvent} readonly={readonly} />
          </div>
        )}

        {/* Report */}
        {!activeEvent?.status?.startsWith('PREP_')
          && (
            <div ref={anchRef.report} className="mt-3">
              <ReportSummary readonly={readonly} />
              <ReportingAndInspectionDates readonly={readonly} />
              <ReportHeader />
            </div>
          )}

        {/* IAN */}
        <div ref={anchRef.IAN} className="mt-6">
          <IanList readonly={readonly} />
        </div>

        {/* INTS */}
        <div ref={anchRef.integrityStatus} className="mt-6">
          <IntegrityStatuses readonly={readonly} />
        </div>

        {/* Attachments */}
        <div ref={anchRef.attachments} className="mt-6">
          <AttachmentList readonly={readonly} />
        </div>

        {/* Task */}
        { activeEvent?.status?.startsWith('REP_') && (
          <div ref={anchRef.task} className="mt-4">
            <TaskBlock event={activeEvent} readonly={readonly} />
          </div>
        )}

        {/* Checklist */}
        <div ref={anchRef.checklist} className="mt-6">
          <InspectionPointBlock event={activeEvent} readonly={readonly} />
        </div>
        {/* CML */}
        <div ref={anchRef.cml} className="mt-6">
          <CmlBlock event={activeEvent} readonly={readonly} />
        </div>

        {/* Damage */}
        <div ref={anchRef.damage} className="mt-6">
          <DamageBlock event={activeEvent} readonly={readonly} canAddIAN={config.getActionIsEnabled('event', 'notificationAdd', activeEvent?.status)} />
        </div>

      </div>

    </div>
  )
}
