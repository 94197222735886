import { gql } from '@apollo/client'
import { DAMAGE_REF_VAL_CODES_COMMON_FIELDS } from './refDamageValCodes'

export const WORKORDER_EVENT_DAMAGES_COMMON_FIELDS = gql`
  ${DAMAGE_REF_VAL_CODES_COMMON_FIELDS}
  fragment EventDamageCommonFields on EventDamage {
      id
      qualReadingId
      qualReading {
        ...RefDamageValCodeCommonFields
      }
      notes
      reader
      reportingDate
      wrkoId
      dmgeId
      event {
        id
        event
      }
  }
`
