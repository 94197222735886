import { useQuery } from '@apollo/client'
import AppGrid from '@app/components/Common/AppGrid'
import UserModal from '@app/components/Modal/Admin/User'
import { GetAllUserSitesQuery } from '@app/graphql/__types__/graphql'
import { USER_SITES_GET_ALL } from '@app/graphql/requests'
import useUsersStore, { UserWithRole } from '@app/stores/users'
import { CellRenderer, GridProps } from '@holis/react-ui'
import { RadButton, RadCheckbox } from '@holis/react-ui/rad'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOpenInNew } from 'react-icons/md'

export default function Users() {
  const { users, editingUser, setEditingUser, roles, fetchAppUsers } = useUsersStore()
  const { data: userSites, refetch: refetchUserSites } = useQuery<GetAllUserSitesQuery>(USER_SITES_GET_ALL, { fetchPolicy: 'no-cache' })
  const { t } = useTranslation()

  const rolesByUser: Record<string, string> = useMemo(() => {
    const result = {} as Record<string, string>
    roles?.forEach((role) => {
      role.usersIds?.forEach((user) => {
        result[user] = role.role!
      })
    })
    return result
  }, [roles])

  const refreshUserData = () => {
    refetchUserSites()
    fetchAppUsers?.()
  }

  const sitesByUser: Record<string, string[]> = useMemo(() => {
    console.log('sitesByUser')
    const result = {} as Record<string, string[]>
    userSites?.userSites.forEach((userSite) => {
      if (!result[userSite.userId]) {
        result[userSite.userId] = []
      }

      result[userSite.userId].push(userSite.site.site)
    })
    console.log(result)
    return result
  }, [userSites])

  const actionCellRenderer: CellRenderer<UserWithRole> = (val, node) => (
    <RadButton
      size="icon"
      variant="outline"
      className="size-fit p-1"
      onClick={() => setEditingUser(node.data)}
    >
      <MdOpenInNew size={18} />
    </RadButton>
  )

  const gridProps: GridProps<UserWithRole> = {
    columns: [
      {
        field: undefined,
        title: '',
        cellRenderer: actionCellRenderer,
        filter: false,
        cannotHide: true,
        draggable: false,
        resizable: false,
        disableOptions: true,
        defaultState: {
          width: 60,
        },
      },
      {
        field: 'username',
        title: t('label.username'),
        type: 'string',
        defaultState: {
          width: 200,
          sort: 'asc',
        },
      },
      {
        field: 'email',
        title: t('label.email'),
        type: 'string',
        defaultState: {
          width: 300,
        },
      },
      {
        field: 'firstName',
        title: t('label.firstName'),
        type: 'string',
        defaultState: {
          width: 200,
        },
      },
      {
        field: 'lastName',
        title: t('label.lastName'),
        type: 'string',
        defaultState: {
          width: 200,
        },
      },
      {
        field: undefined,
        title: t('label.role'),
        defaultState: {
          width: 200,
        },
        cellRenderer: (_, node) => rolesByUser[node.data.id] ?? '-',
      },
      {
        field: 'userRole.notifyNewReport',
        title: t('label.notifyNewReport'),
        type: 'boolean',
        defaultState: {
          width: 200,
        },
        cellRenderer: 'boolean',
      },
      {
        field: undefined,
        title: t('label.sites'),
        defaultState: {
          width: 200,
        },
        cellRenderer: (_, node) => sitesByUser[node.data.id]?.join(', ') ?? '',
      },
    ],
    cellRenderers: {
      boolean: val => (
        <>
          <RadCheckbox title={t(`label.${val ? 'yes' : 'no'}`)} checked={!!val} />
          <span className="hidden">{t(`label.${val ? 'yes' : 'no'}`)}</span>
        </>
      ),
    },
    data: users ?? [],
  }

  return (
    <div className="h-full">
      <AppGrid
        name="admin-usersGrid"
        isLoading={!users}
        {...gridProps}
      />

      {editingUser && (
        <UserModal
          open
          item={editingUser!}
          onClose={() => setEditingUser(undefined)}
          onSaved={refreshUserData}
        />
      )}
    </div>
  )
}
