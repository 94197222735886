import { gql } from '@apollo/client'
import { DAMAGE_REF_CODE_GROUPS_COMMON_FIELDS } from '../fragments'

export const DAMAGE_REF_CODE_GROUPS_GET_MANY = gql`
${DAMAGE_REF_CODE_GROUPS_COMMON_FIELDS}
query GetAllRefDamageCodeGroups($orderBy: [RefDamageCodeGroupOrderByWithRelationInput!], $where: RefDamageCodeGroupWhereInput) {
    refDamageCodeGroups(orderBy: $orderBy, where: $where) {
      ...RefDamageCodeGroupCommonFields
    }
}`

export const DAMAGE_REF_CODE_GROUPS_GET_COUNTER = gql`
query GetRefDamageCodeGroupsCounter {
  aggregateRefDamageCodeGroup {
    _count {
      id
    }
  }
}
`
