import { gql } from '@apollo/client'
import { WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS } from '../fragments'

export const WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY = gql`
${WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS}
query GetAllRefEventTechniques($orderBy: [RefEventTechniqueOrderByWithRelationInput!], $where: RefEventTechniqueWhereInput) {
    refEventTechniques(orderBy: $orderBy, where: $where) {
      ...RefEventTechniqueCommonFields
    }
}`

export const WORKORDER_REF_EVENT_TECHNIQUES_GET_BY_IDWG_ID = gql`
  ${WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS}
  query GetTechniquesByIdwgId($idwgId: Int!) {
    refEventTechniques(where: {idwgTechniques: {some: {idwgId: {equals: $idwgId}}}}) {
      ...RefEventTechniqueCommonFields
    }
  }
`

export const WORKORDER_REF_EVENT_TECHNIQUES_GET_COUNTER = gql`
query GetRefEventTechniquesCounter {
  aggregateRefEventTechnique {
    _count {
      id
    }
  }
}
`
