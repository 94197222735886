import moment from 'moment'
import { FORMAT_DATE_EU } from '../constants'

export const convertDateEuToDefault = (date: string) => {
  const dateMoment = moment.parseZone(date, FORMAT_DATE_EU)
  if (dateMoment.isValid()) {
    return dateMoment.format(FORMAT_DATE_EU)
  }

  return false
}

export const convertDateDefaultStrToDateObj = (date: string) => {
  const dateMoment = moment(date)
  if (dateMoment.isValid()) {
    return dateMoment.toDate()
  }

  return false
}

export const convertDateDefaultStrToDateEuStr = (date: string) => {
  const dateMoment = moment(date)
  if (dateMoment.isValid()) {
    return dateMoment.format(FORMAT_DATE_EU)
  }

  return false
}
