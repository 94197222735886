import { EventCountByStatusSitePlannedDateResponse, StageWorkOrder, StatusWorkOrder } from '@app/graphql/__types__/graphql'
import { EEventPerformanceDashboardPlannedDateFilter } from '@app/utils/enums'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type State = {
  plannedDateFilters?: EEventPerformanceDashboardPlannedDateFilter[]
  classFilters?: string[]
  data?: EventCountByStatusSitePlannedDateResponse[]
  filteredData?: EventCountByStatusSitePlannedDateResponse[]
  eventStatuses?: StatusWorkOrder[]
  eventStages?: StageWorkOrder[]
  availableStatuses?: string[]
  availableStages?: string[]
}

const initialState = {
  plannedDateFilters: undefined,
  data: undefined,
  eventStatuses: undefined,
  eventStages: undefined,
  availableStatuses: undefined,
  availableStages: undefined,
  classFilters: undefined,
}

type Actions = {
  setPlannedDateFilters: (plannedDateFilters?: EEventPerformanceDashboardPlannedDateFilter[]) => void
  setClassFilters: (classFilters?: string[]) => void
  setData: (data?: EventCountByStatusSitePlannedDateResponse[]) => void
  setFilteredData: (filteredData?: EventCountByStatusSitePlannedDateResponse[]) => void
  setEventStatuses: (eventStatuses?: StatusWorkOrder[]) => void
  setEventStages: (eventStages?: StageWorkOrder[]) => void
  setAvailableStatuses: (availableStatuses?: string[]) => void
  setAvailableStages: (availableStages?: string[]) => void
  getStatusColor: (status?: string) => string
  getStageColor: (stage?: string) => string
  getStatusStage: (status?: string) => string
}

type EventPerformanceDashboardState = State & Actions

export const useEventPerformanceDashboardStore = create<EventPerformanceDashboardState>()(
  immer((set, get) => ({
    ...initialState,
    setPlannedDateFilters(plannedDateFilters) {
      set({ plannedDateFilters })
    },
    setClassFilters(classFilters) {
      set({ classFilters })
    },
    setData(data) {
      set({ data })
    },
    setEventStages(eventStages) {
      set({ eventStages })
    },
    setAvailableStages(availableStages) {
      set({ availableStages })
    },
    setFilteredData(filteredData) {
      set({ filteredData })
    },
    setEventStatuses(eventStatuses) {
      set({ eventStatuses })
    },
    getStatusColor(status) {
      const { eventStatuses } = get()
      return eventStatuses?.find(evtStatus => evtStatus.status === status)?.displayColor ?? 'white'
    },
    getStageColor(stage) {
      const { eventStages } = get()
      return eventStages?.find(evtStag => evtStag.stage === stage)?.displayColor ?? 'white'
    },
    getStatusStage(status) {
      const { eventStatuses } = get()
      return eventStatuses?.find(evtStatus => evtStatus.status === status)?.stage ?? ''
    },
    setAvailableStatuses(availableStatuses) {
      set({ availableStatuses })
    },
  })),
)
