import * as fabric from 'fabric'
import colors from 'tailwindcss/colors'
import { TFabricObjectWithPrivate, TFabricProps, TLine, TMarkerAnnotationOptions, TMarkerAnnotationProps, TMarkupObjects } from '@app/types/app'
import { MutableRefObject } from 'react'
import { EVerticalPosition } from '../enums'

const DEFAULT_STROKE_WIDTH = 1
const DEFAULT_STROKE_COLOR = '#3F3F46'
const DEFAULT_FILL_COLOR = colors.blue[500]

export const LOCK_RESIZE_ROTATE: Partial<fabric.FabricObjectProps> = {
  lockScalingX: true,
  lockScalingY: true,
  lockSkewingX: true,
  lockSkewingY: true,
  lockRotation: true,
  hasBorders: false,
  hasControls: false,
  centeredScaling: true,
}

const createLine = (l: TLine, props?: TFabricProps): fabric.Line => new fabric.Line([l[0].x, l[0].y, l[1].x, l[1].y], {
  strokeWidth: DEFAULT_STROKE_WIDTH,
  stroke: DEFAULT_STROKE_COLOR,
  fill: DEFAULT_FILL_COLOR,
  ...props,
})

const createCircle = (center: fabric.Point, radius: number, props?: TFabricProps) => new fabric.Circle({
  top: center.y - radius,
  left: center.x - radius,
  radius,
  strokeWidth: DEFAULT_STROKE_WIDTH,
  stroke: DEFAULT_STROKE_COLOR,
  fill: DEFAULT_FILL_COLOR,
  ...props,
})

const createReactangle = (topLeftPoint: fabric.Point, width: number, height: number, props?: TFabricProps) => new fabric.Rect({
  top: topLeftPoint.y - (height / 2),
  left: topLeftPoint.x - (width / 2),
  width,
  height,
  strokeWidth: DEFAULT_STROKE_WIDTH,
  stroke: DEFAULT_STROKE_COLOR,
  fill: DEFAULT_FILL_COLOR,
  ...props,
})

const setFabricGroupObjectsProps = async (objGroup: TFabricObjectWithPrivate, props?: TFabricProps[], groupProps?: TFabricProps, markerElemId?: string) => {
  if (objGroup._objects && Array.isArray(objGroup._objects)) {
    objGroup._objects.forEach((obj: fabric.FabricObject, index: number) => {
      if (props?.[index]) {
        if (!markerElemId || (obj as { id?: string }).id === markerElemId) {
          obj.set({
            ...props[index],
          })
        }
      }
    })
  }

  objGroup.set({
    ...groupProps,
  })
}

const createSvgObjectFromString = async (canvas: fabric.Canvas, svg: string, center: fabric.Point, width: number, height: number, props?: TFabricProps[], groupProps?: TFabricProps, markerElemId?: string, moveDisabled?: boolean): Promise<TFabricObjectWithPrivate> => {
  const realWidth = width// / canvas.getZoom();
  const realHeight = height// / canvas.getZoom();
  const svgObj = await fabric.loadSVGFromString(svg)
  const objects: fabric.FabricObject[] = svgObj.objects.filter((obj: fabric.FabricObject | null) => obj !== null) as fabric.FabricObject[]
  if (Array.isArray(props)) {
    objects.forEach((obj: fabric.FabricObject, index: number) => {
      const objProps = props[index]
      if (!markerElemId || (obj as { id?: string }).id === markerElemId) {
        obj.set({
          strokeWidth: 1,
          ...objProps,
        })
      }
    })
  }

  const obj = fabric.util.groupSVGElements(svgObj.objects as fabric.FabricObject[])
  obj.set({
    width: realWidth,
    height: realHeight,
    top: center.y - (realHeight / 2),
    left: center.x - (realWidth / 2),
    ...groupProps,
  })
  const objSize = Math.max(realWidth, realHeight)
  const markerPositioner = createReactangle(obj.getCenterPoint(), objSize, objSize, {
    stroke: 'transparent',
    fill: 'transparent',
    strokeWidth: DEFAULT_STROKE_WIDTH,
    perPixelTargetFind: true,
    lockMovementX: moveDisabled,
    lockMovementY: moveDisabled,
    ...LOCK_RESIZE_ROTATE,
  })
  const objGroup = new fabric.Group([obj, markerPositioner], {
    width: objSize,
    height: objSize,
    stroke: 'transparent',
    fill: 'transparent',
    lockMovementX: moveDisabled,
    lockMovementY: moveDisabled,
    ...LOCK_RESIZE_ROTATE,
  })
  return objGroup
}

/**
 * Create an annotation with svg string
 * @param canvas
 * @param position
 * @param radius1
 * @param markerSvg
 * @param center2
 * @param width
 * @param height
 * @param text
 * @param options
 * @returns
 */

export const createSvgAnnotation = async (canvas: fabric.Canvas, position: fabric.Point, radius1: number, markerSvg: string, center2: fabric.Point, width: number, height: number, text: string, options?: TMarkerAnnotationOptions, addToCanvas: boolean = false, moveDisabled: boolean = false): Promise<TMarkupObjects> => {
  const { markerProps, markerGroupProps, markerElemId } = options?.props ?? {}
  const marker = await createSvgObjectFromString(canvas, markerSvg, center2, width, height, markerProps, markerGroupProps, markerElemId, moveDisabled)
  return createObjectAnnotation(canvas, position, radius1, marker, text, options, addToCanvas, moveDisabled)
}

/**
 * Create annotation from fabric object
 * @param canvas
 * @param position
 * @param radius1
 * @param marker
 * @param text
 * @param options
 * @returns
 */

const createObjectAnnotation = async (canvas: fabric.Canvas, position: fabric.Point, radius1: number, marker: TFabricObjectWithPrivate, text: string, options?: TMarkerAnnotationOptions, addToCanvas: boolean = false, moveDisabled: boolean = false): Promise<TMarkupObjects> => {
  const { props, mouseoverProps, events } = options ?? {}
  const { lineProps, positionerProps, markerProps, tooltipProps, markerGroupProps, markerElemId } = props ?? {}
  const { verticalPosition, ...restTooltipProps } = tooltipProps ?? {}
  let markerCenter = marker.getCenterPoint()
  const tooltip = new fabric.IText(text, {
    textAlign: 'center',
    evented: false,
    fontSize: marker.getBoundingRect().height / 4,
    fit: true,
    visible: text !== '',
    pathAlign: 'center',
    padding: 10,
    ...restTooltipProps,
  })
  const line = createLine([position, markerCenter], {
    hoverCursor: 'default',
    selectable: false,
    strokeWidth: DEFAULT_STROKE_WIDTH,
    perPixelTargetFind: false,
    evented: false,
    ...lineProps,
  })
  const positioner = createCircle(position, radius1, {
    stroke: DEFAULT_STROKE_COLOR,
    fill: DEFAULT_FILL_COLOR,
    strokeWidth: DEFAULT_STROKE_WIDTH,
    lockMovementX: moveDisabled,
    lockMovementY: moveDisabled,
    ...positionerProps,
  })
  tooltip.set({
    top: markerCenter.y - (tooltip.getBoundingRect().height / 2) + ((verticalPosition ?? EVerticalPosition.MIDDLE) * (marker.getBoundingRect().height / 2)),
    left: markerCenter.x - (tooltip.getBoundingRect().width / 2),
  })
  positioner.on('mousemove', (e: fabric.ObjectPointerEvents['mousemove']) => {
    if (moveDisabled) {
      e.e.preventDefault()
      return
    }

    const positionerCenter = positioner.getCenterPoint()
    line.set({ x1: positionerCenter.x, y1: positionerCenter.y })
    canvas.renderAll()
    events?.onObjectMoving?.(e, positioner)
  })

  positioner.on('mouseover', (e: fabric.ObjectPointerEvents['mouseover']) => {
    positioner.set({
      ...mouseoverProps?.positionerProps,
    })
    canvas.bringObjectToFront(positioner)
    canvas.renderAll()
    events?.onObjectMouseOver?.(e, positioner)
  })
  positioner.on('mouseout', (e: fabric.ObjectPointerEvents['mouseout']) => {
    positioner.set({
      ...positionerProps,
    })
    canvas.renderAll()
    events?.onObjectMouseOut?.(e, positioner)
  })
  marker.on('mouseover', (e: fabric.ObjectPointerEvents['mouseover']) => {
    if (marker._objects?.[0]) {
      setFabricGroupObjectsProps(marker._objects[0], mouseoverProps?.markerProps, mouseoverProps?.markerGroupProps, markerElemId)
      canvas.renderAll()
    }

    events?.onObjectMouseOver?.(e, marker)
  })
  marker.on('mouseout', (e: fabric.ObjectPointerEvents['mouseout']) => {
    canvas.discardActiveObject()
    if (marker._objects?.[0]) {
      setFabricGroupObjectsProps(marker._objects[0], markerProps, markerGroupProps, markerElemId)
    }

    canvas.renderAll()

    events?.onObjectMouseOut?.(e, marker)
  })
  marker.on('mousemove', (e: fabric.ObjectPointerEvents['mousemove']) => {
    if (moveDisabled) {
      e.e.preventDefault()
      return
    }

    markerCenter = marker.getCenterPoint()
    tooltip.setCoords()
    tooltip.set({
      top: markerCenter.y - (tooltip.getBoundingRect().height / 2) + ((tooltipProps?.verticalPosition ?? EVerticalPosition.MIDDLE) * (marker.getBoundingRect().height / 2)),
      left: markerCenter.x - (tooltip.getBoundingRect().width / 2),
    })
    line.set({ x2: markerCenter.x, y2: markerCenter.y })
    canvas.renderAll()
    line.setCoords()
    events?.onObjectMoving?.(e, marker)
  });

  [marker, positioner].forEach((item: fabric.FabricObject) => {
    item.on('mouseup:before', (e: fabric.ObjectPointerEvents['mouseup:before']) => {
      if (!moveDisabled && events?.onObjectMoved && item.isMoving) {
        if (item === marker) {
          events.onObjectMoved(e, marker)
        } else {
          events.onObjectMoved(e, positioner)
        }
      }
    })
  })
  const objects = { line, positioner, marker, tooltip }
  if (addToCanvas) {
    canvas.add(...Object.values(objects))
    Object.values(objects).forEach((obj: fabric.FabricObject) => {
      obj.setCoords()
    })
  }

  return objects
}

export const handleObjectMoved = async (dataObj: { id?: unknown }, canvas: fabric.Canvas, markups: TMarkupObjects, lastMarkupPositions: MutableRefObject<Record<string, Partial<Record<keyof TMarkupObjects, fabric.Point>>>>, e: fabric.ObjectPointerEvents['mouseup:before'], o?: fabric.FabricObject, prefixId?: string, markerFieldName?: string, positionerFieldName?: string, updateDataField?: (id: unknown, field: unknown, value: unknown) => Promise<boolean>, updateLastMarkupPosition?: (lastMarkupPositions: MutableRefObject<Record<string, Partial<Record<keyof TMarkupObjects, fabric.Point>>>>, markupId: string, markupName: keyof TMarkupObjects, markupPosition?: fabric.Point) => void, markupsProps?: TMarkerAnnotationProps): Promise<void> => {
  const movedObj: fabric.FabricObject | undefined = o ?? e.target
  if (movedObj && [markups.marker, markups.positioner].includes(movedObj) && dataObj.id) {
    const lastPosition = lastMarkupPositions.current[`${prefixId}${dataObj.id}`]?.[markups.marker === movedObj ? 'marker' : 'positioner']
    if (lastPosition) {
      movedObj.setCoords()
      // top-left  corner
      if (movedObj.getCenterPoint().y < 0) {
        movedObj.top = -(movedObj.height / 2)
      }

      if (movedObj.getCenterPoint().x < 0) {
        movedObj.left = -(movedObj.width / 2)
      }

      // bot-right corner
      if (movedObj.getCenterPoint().y > canvas!.backgroundImage!.height) {
        movedObj.top = canvas!.backgroundImage!.height - (movedObj.height / 2)
      }

      if (movedObj.getCenterPoint().x > canvas!.backgroundImage!.width) {
        movedObj.left = canvas!.backgroundImage!.width - (movedObj.width / 2)
      }

      const newPosition = movedObj.getCenterPoint()
      if (markerFieldName && positionerFieldName && (lastPosition.x !== newPosition.x || lastPosition.y !== newPosition.y)) {
        const updated = await updateDataField?.(dataObj.id, markups.marker === movedObj ? markerFieldName : positionerFieldName, JSON.stringify({
          x: movedObj.getCenterPoint().x,
          y: movedObj.getCenterPoint().y,
        }))
        if (!updated) {
          movedObj.set({
            top: lastPosition.y - (movedObj.height / 2),
            left: lastPosition.x - (movedObj.width / 2),
          })
          if (movedObj === markups.marker) {
            markups.tooltip.setCoords()
            markups.tooltip.set({
              top: lastPosition.y - (markups.tooltip.getBoundingRect().height / 2) + ((markupsProps?.tooltipProps?.verticalPosition ?? EVerticalPosition.MIDDLE) * (markups.marker.getBoundingRect().height / 2)),
              left: lastPosition.x - (markups.tooltip.getBoundingRect().width / 2),
            })
            markups.line.setCoords()
            markups.line.set({
              x2: lastPosition.x,
              y2: lastPosition.y,
            })
          } else {
            markups.line.setCoords()
            markups.line.set({
              x1: lastPosition.x,
              y1: lastPosition.y,
            })
          }

          canvas!.discardActiveObject()
          canvas!.renderAll()
        } else {
          if (movedObj === markups.marker) {
            markups.tooltip.setCoords()
            markups.tooltip.set({
              top: newPosition.y - (markups.tooltip.getBoundingRect().height / 2) + ((markupsProps?.tooltipProps?.verticalPosition ?? EVerticalPosition.MIDDLE) * (markups.marker.getBoundingRect().height / 2)),
              left: newPosition.x - (markups.tooltip.getBoundingRect().width / 2),
            })
            markups.line.setCoords()
            markups.line.set({
              x2: newPosition.x,
              y2: newPosition.y,
            })
          } else {
            markups.line.setCoords()
            markups.line.set({
              x1: newPosition.x,
              y1: newPosition.y,
            })
          }

          canvas!.discardActiveObject()
          canvas!.renderAll()
          updateLastMarkupPosition?.(lastMarkupPositions, `${prefixId}${dataObj.id}`, markups.marker === movedObj ? 'marker' : 'positioner', movedObj.getCenterPoint())
        }
      }
    }
  }
}

export const updateLastMarkupPosition = (lastMarkupPositions: MutableRefObject<Record<string, Partial<Record<keyof TMarkupObjects, fabric.Point>>>>, id: string, markupObjName: keyof TMarkupObjects, position?: fabric.Point) => {
  if (typeof position === 'undefined') {
    const lastMarkupPosition = lastMarkupPositions.current[id]?.[markupObjName]
    if (lastMarkupPosition) {
      delete lastMarkupPositions.current[id][markupObjName]
    }
  } else {
    if (!lastMarkupPositions.current[id]) {
      lastMarkupPositions.current[id] = {}
    }

    lastMarkupPositions.current[id][markupObjName] = position
  }
}
