import { gql } from '@apollo/client'
import { FLOC_REF_SECES_COMMON_FIELDS } from '../fragments'

export const FLOC_REF_SECES_GET_MANY = gql`
${FLOC_REF_SECES_COMMON_FIELDS}
query GetAllRefFlocSeces {
  refFlocSeces {
    ...RefFlocSeceCommonFields
  }
}`

export const FLOC_REF_SECES_GET_COUNTER = gql`
query GetRefFlocSecesCounter {
  aggregateRefFlocSece {
    _count {
      id
    }
  }
}
`
