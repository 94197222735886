import { Item, GetItemDetailByIdQuery, CreateItemMutation, UpdateItemByIdMutation } from '@app/graphql/__types__/graphql'
import { useEffect, useId } from 'react'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { useLazyQuery, useMutation } from '@apollo/client'
import { SCHEDULING_ITEMS_CREATE, SCHEDULING_ITEMS_GET_DETAIL_BY_ID, SCHEDULING_ITEMS_UPDATE_BY_ID } from '@app/graphql/requests'
import useItemStore, { ZOD_ITEM_DATAS } from '@app/stores/item'
import SingleContentModal from '@app/components/Modal/SingleFormModal'
import { useTranslation } from 'react-i18next'
import { RadButton, RadDropdownMenuItem, RadForm } from '@holis/react-ui/rad'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Form from './components/Form'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { setObjValueByPath } from '@app/utils/functions'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import TaskBlock from '../../MultiplePanels/Scheduling/Item/components/ObjectList/TaskBlock'
import { LuTrash2 } from 'react-icons/lu'
import DeleteItemModal from '../../MultiplePanels/Scheduling/Item/components/DeleteItemModal'

type TItemModal = Readonly<{
  item: Partial<Item>
  open: boolean
  onCreatedOrUpdated?: (item?: Partial<Item>) => void
}>

export default function ItemModal({ item, open, onCreatedOrUpdated }: TItemModal) {
  const formId = useId()
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLayoutStore()
  const zodFormObject = z.object(ZOD_ITEM_DATAS(t))

  const { updateItem, resetData, editItem, setEditItem, updateItemDataField, updateItemData, updateItemFieldError, setUpdateItemData, changeDeleteItemModalDisplay, deleteItemModalOpen } = useItemStore()
  const [updateItemByIdApi] = useMutation<UpdateItemByIdMutation>(SCHEDULING_ITEMS_UPDATE_BY_ID)
  const [createItemApi] = useMutation<CreateItemMutation>(SCHEDULING_ITEMS_CREATE)
  const [getItemDetail] = useLazyQuery<GetItemDetailByIdQuery>(SCHEDULING_ITEMS_GET_DETAIL_BY_ID, {
    fetchPolicy: 'no-cache',
  })

  const isNew = !editItem?.id || editItem.id === OBJ_NEW_ID

  const form = useForm<z.infer<typeof zodFormObject>>(
    {
      resolver: zodResolver(zodFormObject),
      defaultValues: {
        plannerGroupId: item.plannerGroupId ?? undefined,
        mainWorkCenterId: item.mainWorkCenterId ?? undefined,
      },
      mode: 'onSubmit',
    })

  const getAndSetItemDetail = async () => {
    const queryResult = await getItemDetail({
      variables: {
        id: editItem!.id!,
      },
    })

    const itemObj = (queryResult.data?.item ?? {}) as Partial<Item>
    updateItem(itemObj)
    return itemObj
  }

  const handleFieldChange = (field: string, value: unknown) => {
    console.log(field, value)
    const editedItem = { ...editItem }
    setObjValueByPath(editedItem, field, value)
    setEditItem(editedItem)
    updateItemDataField(field, value)
  }

  form.watch((datas, { name, type }) => {
    console.log(datas, name, type)
    if (type === 'change') {
      handleFieldChange(name!, datas[name!])
    }
  })

  const handleUpdateItem = () => {
    updateItemByIdApi({ variables: { id: item!.id, data: updateItemData } }).then((newData) => {
      const newItem = {
        ...editItem,
        ...newData?.data?.updateOneItem,
      } as Item
      // reset form with new info saved
      updateItem(newItem)
      setUpdateItemData({})
      onCreatedOrUpdated?.()
      AppNotifications.success(t('message.success.itemUpdated'))
    }).catch((err: Error) => {
      let errorMessage: string = t('message.error.default.title')
      if (err.message && err.message.includes('Unique constraint failed on the fields: (`planId`,`itemNumber`)')) {
        errorMessage = t('message.error.unique.scheduling.item.itemNumber')
        updateItemFieldError('itemNumber', true)
        form.setError('itemNumber', {
          message: errorMessage,
        })
      }

      AppNotifications.error(errorMessage)
    }).finally(() => {
      stopLoading()
      resetData()
    })
  }

  const handleCreateItem = () => {
    console.log(updateItemData)
    createItemApi({ variables: { data: updateItemData } }).then((newData) => {
      const newItem = {
        ...editItem,
        ...newData?.data?.createOneItem,
      } as Item
      // reset form with created item
      updateItem(newItem, true)
      setUpdateItemData({})
      onCreatedOrUpdated?.()
      AppNotifications.success(t('message.success.itemCreated'))
    }).catch((err: Error) => {
      let errorMessage: string = t('message.error.default.title')
      console.log(err.message)
      if (err.message && err.message.includes('Unique constraint failed on the fields: (`planId`,`itemNumber`)')) {
        errorMessage = t('message.error.unique.scheduling.item.itemNumber')
        updateItemFieldError('itemNumber', true)
        form.setError('itemNumber', {
          message: errorMessage,
        })
      }

      AppNotifications.error(errorMessage)
    }).finally(() => {
      stopLoading()
    })
  }

  const handleSubmitOk = () => {
    startLoading()
    if (!isNew) {
      handleUpdateItem()
    } else {
      handleCreateItem()
    }
  }

  useEffect(() => {
    if (!isNew) {
      getAndSetItemDetail().then((item: Partial<Item>) => {
        form.reset({
          ...item,
          description: item?.description ?? '',
          longDescription: item?.longDescription ?? '',
          plannerGroupId: item.plannerGroupId ?? undefined,
          mainWorkCenterId: item.mainWorkCenterId ?? undefined,
        })
      })
    } else {
      setEditItem()
    }
  }, [])

  return (
    <SingleContentModal
      isOpen={open}
      title={isNew ? t('label.itemCreation') : item.itemNumber}
      description={isNew ? t('label.itemCreateDescription') : undefined}
      itemDesctiption={isNew ? undefined : item.description}
      menuItems={isNew
        ? undefined
        : [
            <RadDropdownMenuItem key="delete" className="gap-2 item-destructive" onClick={() => changeDeleteItemModalDisplay(true)}>
              <LuTrash2 />
              {' '}
              {t('label.delete')}
            </RadDropdownMenuItem>,
          ]}
      containerClassName="max-w-[1400px] min-w-0 h-full"
      footer={(
        <div className="flex gap-2">
          <RadButton variant="outline" onClick={() => resetData()}>{t('label.cancel')}</RadButton>
          <RadButton form={formId}>{isNew ? t('label.create') : t('label.save')}</RadButton>
        </div>
      )}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          resetData()
        }
      }}
    >

      <RadForm {...form}>
        <form
          id={formId}
          className="h-full flex flex-col"
          onSubmit={form.handleSubmit(handleSubmitOk)}
        >

          <Form />
          <div className="my-4 mr-2 flex-1">
            {isNew
              ? (
                  <>
                    <FormGroupHeader>{t('label.taskList')}</FormGroupHeader>
                    <div className="text-gray-500 p-4 border border-gray-300 mt-4 rounded-md">
                      {t('label.pleaseCreateItemFirst')}
                    </div>
                  </>
                )

              : <TaskBlock />}
          </div>
        </form>
      </RadForm>
      <DeleteItemModal open={deleteItemModalOpen} />
    </SingleContentModal>
  )
}
