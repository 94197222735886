import { FetchResult, useLazyQuery, useQuery } from '@apollo/client'
import AppAutocomplete from '@app/components/Common/Form/Autocomplete'
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock'
import FlocSelectionModal from '@app/components/Common/Block/Floc/FlocBlock/FlocSelectionModal'
import { InspectionPoint, FunctionalLocation, GetAllFlocsAutocompleteQuery, GetAllRefEventTechniquesQuery, RefEventTechnique, GetAllRefMeasPointCharsQuery, GetAllRefMeasPointClassesQuery, GetAllRefMeasPointCodeGroupsQuery, RefMeasPointClass, RefMeasPointCodeGroup, RefMeasPointChar } from '@app/graphql/__types__/graphql'
import { FLOCS_GET_ALL_AUTOCOMPLETE, MEASPOINT_REF_CHARS_GET_MANY, MEASPOINT_REF_CLASSES_GET_MANY, MEASPOINT_REF_CODE_GROUPS_GET_MANY, WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY } from '@app/graphql/requests'
import useInspectionPointStore, { MAX_LENGTH_VALIDATORS, ZOD_INSP_DATAS } from '@app/stores/insp'
import { TFieldsBlock, TRenderAutocompleteProps } from '@app/types/app'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { EFieldType } from '@app/utils/enums'
import { handleFormInputKeydown, renderCodeAndDescription, setObjValueByPath } from '@app/utils/functions'
import React, { InputHTMLAttributes, TextareaHTMLAttributes, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { LuClipboardList } from 'react-icons/lu'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
type TGeneralInfo = Readonly<{
  inspectionPoint: Partial<InspectionPoint>
  readonly?: boolean
  onFieldChange?: (field: string, value: unknown) => void
  onFieldBlur?: (field: string, value: unknown) => void
}>

export default function GeneralInfo({ inspectionPoint, readonly, onFieldChange, onFieldBlur }: TGeneralInfo) {
  const { t } = useTranslation()
  const [submitRequested, setSubmitRequested] = useState<boolean>(false)
  const [flocSelectionModalDisplayed, changeFlocSelectionModalDisplay] = useState<boolean>(false)
  const { editInspectionPoint, setEditInspectionPoint, updateDataField, hasFieldError, updateData } = useInspectionPointStore()
  const [getFlocs, flocsResult] = useLazyQuery<GetAllFlocsAutocompleteQuery>(FLOCS_GET_ALL_AUTOCOMPLETE)
  const refInspectionPointClassResult = useQuery<GetAllRefMeasPointClassesQuery>(MEASPOINT_REF_CLASSES_GET_MANY)
  const refInspectionPointCodeGroupResult = useQuery<GetAllRefMeasPointCodeGroupsQuery>(MEASPOINT_REF_CODE_GROUPS_GET_MANY)
  const refEventTechniqueResult = useQuery<GetAllRefEventTechniquesQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY)
  const refMeasPointCharResult = useQuery<GetAllRefMeasPointCharsQuery>(MEASPOINT_REF_CHARS_GET_MANY)
  const isNewForm = inspectionPoint.id === OBJ_NEW_ID
  const zodFormObject = z.object(ZOD_INSP_DATAS(t))
  const form = useForm<z.infer<typeof zodFormObject>>(
    { resolver: zodResolver(zodFormObject), values: {
      position: editInspectionPoint?.position?.toString() ?? '',
      description: editInspectionPoint?.description?.toString() ?? '',
      longDescription: editInspectionPoint?.longDescription?.toString() ?? '',
      classId: editInspectionPoint?.classId ?? -1,
      codeGroupId: editInspectionPoint?.codeGroupId ?? -1,
    }, mode: 'onChange' })

  const htmlForm = useRef<HTMLFormElement | null>(null)

  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    const editedIdwg = { ...editInspectionPoint }
    setObjValueByPath(editedIdwg, field, value)
    setEditInspectionPoint(editedIdwg)
    if (update) {
      updateDataField(field, value)
    }

    onFieldChange?.(field, value)
  }

  const handleSelectFloc = (items: Partial<FunctionalLocation>[]) => {
    const item = items.length ? items[0] : null
    const editedInspectionPoint = { ...editInspectionPoint }
    setObjValueByPath(editedInspectionPoint, 'functionalLocation', item)
    setObjValueByPath(editedInspectionPoint, 'flocId', item?.id ?? null)
    setEditInspectionPoint(editedInspectionPoint)
    updateDataField('flocId', item?.id ?? null)
  }

  const renderAutocomplete = (props: TRenderAutocompleteProps): React.ReactNode => {
    const { fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps } = props ?? {}
    return (
      <AppAutocomplete
        onSelect={(item?: Record<string, unknown> | null) => {
          if ((updateData && Object.keys(updateData).includes(field!)) || (!(updateData && Object.keys(updateData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string)
            }

            const editedInspectionPoint = { ...editInspectionPoint }
            setObjValueByPath(editedInspectionPoint, foreignField!, item)
            setObjValueByPath(editedInspectionPoint, field!, item?.id ?? null)
            if (field === 'classId') {
              setObjValueByPath(editedInspectionPoint, 'codeGroup', null)
              setObjValueByPath(editedInspectionPoint, 'codeGroupId', null)
            }

            setEditInspectionPoint(editedInspectionPoint)
            if (field === 'classId') {
              updateDataField('codeGroupId', null)
            }

            updateDataField(field!, item?.id ?? null)
            if (field !== 'classId') {
              handleFieldBlur()
            }

            onFieldBlur?.(field!, item?.id ?? null)
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
        }}
      />
    )
  }

  const handleFieldBlur = () => {
  }

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit()

      setSubmitRequested(false)
    }
  }, [submitRequested])

  useEffect(() => {
    if (isNewForm) {
      getFlocs()
    }
  }, [])

  const BLOCK_IDENTIFICATION: TFieldsBlock = {
    title: 'label.identification',
    fields: [
      isNewForm
        ? {
            label: 'label.functionalLocation',
            field: 'flocId',
            dropdownTransitionClassName: 'top-auto bottom-6 hidden',
            initialValue: renderCodeAndDescription({ description: inspectionPoint!.functionalLocation?.description, code: inspectionPoint!.functionalLocation?.floc }),
            value: renderCodeAndDescription({ description: editInspectionPoint?.functionalLocation?.description, code: editInspectionPoint?.functionalLocation?.floc }),
            foreignObject: editInspectionPoint?.functionalLocation,
            foreignField: 'functionalLocation',
            isRequired: true,
            dbValue: inspectionPoint!.flocId as number,
            hasError: hasFieldError('flocId'),
            fieldType: EFieldType.autocomplete,
            itemsQueryResult: flocsResult,
            listProps: {
              hasVirtualScroll: true,
              virtualScrollItemSize: 50,
            },
            inputProps: {
              readOnly: true,
              onClick() {
                changeFlocSelectionModalDisplay(true)
              },
            },
            getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllFlocsAutocompleteQuery>)?.data?.functionalLocations ?? [],
            renderMenuItemLabel: field => renderCodeAndDescription({ description: (field as Partial<FunctionalLocation>).description, code: (field as Partial<FunctionalLocation>).floc }),
            renderInput: renderAutocomplete,
          }
        : {
            label: 'label.functionalLocation',
            field: 'floc.functionalLocation',
            fieldType: EFieldType.text,
            initialValue: inspectionPoint!.functionalLocation?.floc ?? '',
            value: editInspectionPoint?.functionalLocation?.floc ?? '',
            isDisabled: true,
          },
      {
        label: 'label.position',
        field: 'position',
        fieldType: EFieldType.text,
        initialValue: inspectionPoint?.position ?? '',
        value: editInspectionPoint?.position ?? '',
        hasError: hasFieldError('position'),
        isRequired: true,
        inputComponent: 'input',
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.POSITION,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.description',
        field: 'description',
        fieldType: EFieldType.text,
        hasError: hasFieldError('description'),
        initialValue: inspectionPoint?.description ?? '',
        value: editInspectionPoint?.description ?? '',
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.longDescription',
        field: 'longDescription',
        fieldType: EFieldType.text,
        hasError: hasFieldError('longDescription'),
        initialValue: inspectionPoint?.longDescription ?? '',
        value: editInspectionPoint?.longDescription ?? '',
        inputComponent: 'textarea',
        inputProps: {
          rows: 2,
          maxLength: MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION,
        } as TextareaHTMLAttributes<HTMLTextAreaElement>,
      },
    ],
  }

  const BLOCK_CLASSIFICATION: TFieldsBlock = {
    title: 'label.classification',
    fields: [
      {
        label: 'label.class',
        field: 'classId',
        initialValue: renderCodeAndDescription({ description: inspectionPoint!.class?.description, code: inspectionPoint!.class?.class }),
        value: renderCodeAndDescription({ description: editInspectionPoint?.class?.description, code: editInspectionPoint?.class?.class }),
        foreignObject: editInspectionPoint?.class,
        foreignField: 'class',
        isRequired: true,
        dbValue: inspectionPoint!.classId,
        hasError: hasFieldError('classId'),
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refInspectionPointClassResult,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefMeasPointClassesQuery>)?.data?.refMeasPointClasses ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({ description: (field as Partial<RefMeasPointClass>).description, code: (field as Partial<RefMeasPointClass>).class }),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.codeGroup',
        field: 'codeGroupId',
        initialValue: renderCodeAndDescription({ description: inspectionPoint!.codeGroup?.description, code: inspectionPoint!.codeGroup?.codeGroup }),
        value: renderCodeAndDescription({ description: editInspectionPoint?.codeGroup?.description, code: editInspectionPoint?.codeGroup?.codeGroup }),
        foreignObject: editInspectionPoint?.codeGroup,
        foreignField: 'codeGroup',
        hasError: hasFieldError('codeGroupId'),
        isRequired: true,
        dbValue: inspectionPoint!.codeGroupId,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refInspectionPointCodeGroupResult,
        getItemsFromResult: (result: FetchResult) => ((result as FetchResult<GetAllRefMeasPointCodeGroupsQuery>)?.data?.refMeasPointCodeGroups ?? []).filter((measPtCodeGroup: unknown) => !editInspectionPoint?.classId || !!editInspectionPoint.class?.classCodeGroups?.map(classCodeGr => classCodeGr.codeGroupId).includes((measPtCodeGroup as RefMeasPointCodeGroup).id)),
        renderMenuItemLabel: field => renderCodeAndDescription({ description: (field as Partial<RefMeasPointCodeGroup>).description, code: (field as Partial<RefMeasPointCodeGroup>).codeGroup }),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.technique',
        field: 'techniqueId',
        initialValue: renderCodeAndDescription({ description: inspectionPoint!.technique?.description, code: inspectionPoint!.technique?.technique }),
        value: renderCodeAndDescription({ description: editInspectionPoint?.technique?.description, code: editInspectionPoint?.technique?.technique }),
        foreignObject: editInspectionPoint?.technique,
        foreignField: 'technique',
        dbValue: inspectionPoint?.techniqueId,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refEventTechniqueResult,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefEventTechniquesQuery>)?.data?.refEventTechniques ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({ description: (field as Partial<RefEventTechnique>).description, code: (field as Partial<RefEventTechnique>).technique }),
        renderInput: renderAutocomplete,
      },
    ],
  }

  const BLOCK_ATTRIBUTES: TFieldsBlock = {
    title: 'label.attributes',
    fields: [
      {
        label: 'label.char',
        field: 'charId',
        initialValue: renderCodeAndDescription({ description: inspectionPoint!.char?.description, code: inspectionPoint!.char?.char }),
        value: renderCodeAndDescription({ description: editInspectionPoint?.char?.description, code: editInspectionPoint?.char?.char }),
        foreignObject: editInspectionPoint?.char,
        foreignField: 'char',
        dbValue: inspectionPoint?.charId,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refMeasPointCharResult,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefMeasPointCharsQuery>)?.data?.refMeasPointChars ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({ description: (field as Partial<RefMeasPointChar>).description, code: (field as Partial<RefMeasPointChar>).char }),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.externalID',
        field: 'externalRef',
        isDisabled: true,
        fieldType: EFieldType.text,
        initialValue: inspectionPoint?.externalRef ?? '',
        value: editInspectionPoint?.externalRef ?? '',
        inputComponent: 'input',
      },
    ],
  }
  const fieldBlocks = [BLOCK_IDENTIFICATION, BLOCK_CLASSIFICATION, BLOCK_ATTRIBUTES]
  return (
    <div className="gap-2 px-3 py-2 w-full overflow-auto">
      <RadForm {...form}>
        <form
          ref={htmlForm}
        >
          <FormFieldsBlock
            isDisabled={readonly}
            prefixNode={(
              <FormGroupHeader>
                <div className="flex items-center font-bold">
                  <LuClipboardList size={20} className="mr-2" />
                  {' '}
                  {t('label.headerData')}
                </div>
              </FormGroupHeader>
            )}
            fieldsBlocks={fieldBlocks}
            onFieldChange={handleFieldChange}
            onFieldBlur={onFieldBlur}
          />
        </form>
      </RadForm>
      {flocSelectionModalDisplayed && <FlocSelectionModal open hasItems queryResult={flocsResult} items={flocsResult.data?.functionalLocations ?? []} isMultiple={false} onValidate={handleSelectFloc} onClose={() => changeFlocSelectionModalDisplay(false)} /> }

    </div>
  )
}
