import { gql } from '@apollo/client'

export const NOTIFICATION_WORKFLOWS_COMMON_FIELDS = gql`
  fragment NotificationWorkflowCommonFields on NotificationWorkflow {
    id
    notifId
    status
    date
    userLogin
    isValid
    comment
    description
  }
`
