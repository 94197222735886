import { useLazyQuery, useMutation } from '@apollo/client'
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar'
import PageContent from '@app/components/Layout/PageContent'
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader'
import { AddIdwgFlocMutation, FunctionalLocation, GetFlocDetailByNumberQuery, GetInspectionDrawingsByFlocIdQuery, GetPicturesByFlocIdQuery, InspectionDrawing, Picture } from '@app/graphql/__types__/graphql'
import { FLOCS_GET_DETAIL_BY_NUMBER, INSPECTION_DRAWINGS_ADD_IDWG_FLOC, INSPECTION_DRAWINGS_GET_BY_FLOC_ID, PICTURES_GET_BY_FLOC_ID } from '@app/graphql/requests'
import Error404 from '@app/pages/Errors/NotFound'
import { useLayoutStore } from '@app/stores/layout'
import { useFlocStore } from '@app/stores/methodEngineering/floc'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Footer from './Footer'
import RightSide from './RightSide'
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels'
import CmlModal from '@app/components/Common/Block/Cml/CmlModal'
import DamageModal from '@app/components/Common/Block/Damage/DamageModal'
import InspectionPointModal from '@app/components/Common/Block/InspectionPoint/InspectionPointModal'
import { CmlCreateModal } from '@app/components/Modal/Inspection/Cml/Create'
import { DamageCreateModal } from '@app/components/Modal/Inspection/Damage/Create'
import useCmlStore from '@app/stores/cml'
import useDamageStore from '@app/stores/damage'
import useInspectionPointStore from '@app/stores/insp'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { EAdditionalAction } from '@app/utils/enums'
import { useTranslation } from 'react-i18next'
import { TAdditionalActions } from '@app/types/app'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuTrash2 } from 'react-icons/lu'
import DeleteFlocModal from '@app/components/Common/Block/Floc/FlocBlock/DeleteFlocModal'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
import IdwgModal from '@app/components/Modal/Documentation/idwg'
import AppNotifications from '@app/services/notification'
import { useInspectionDrawingStore } from '@app/stores/methodEngineering/inspectionDrawing'
import LeftSide from './LeftSide'
import { InspectionPointCreateModal } from '@app/components/Modal/Inspection/Insp/Create'

export default function FunctionalLocationDetailsPage() {
  const { startLoading, stopLoading } = useLayoutStore()
  const prms = useUserPermissions()
  const [deleteFlocModalDisplayed, changeDeleteFlocModalDisplay] = useState<boolean>(false)
  const { activeInspectionDrawing, setActiveInspectionDrawing } = useInspectionDrawingStore()
  const {
    editFloc,
    setActiveFloc,
    setPictures,
    setFetchPictures,
    setFlocInspectionDrawings,
    setFetchFlocInspectionDrawings,
    setRightSideWidth,
    fetchCmls,
    fetchPictures,
    fetchDamages,
    fetchInspectionPoints,
    fetchFlocInspectionDrawings,
    activeFloc,
    cmls,
    damages,
    inspectionPoints,
    resetData,
  } = useFlocStore()
  const { number: flocNumber } = useParams()
  const { t } = useTranslation()
  const [notFound, setNotFound] = useState<boolean>()
  const [getFlocDetailApi, { loading }] = useLazyQuery<GetFlocDetailByNumberQuery>(FLOCS_GET_DETAIL_BY_NUMBER)
  const [getFlocPicturesApi] = useLazyQuery<GetPicturesByFlocIdQuery>(PICTURES_GET_BY_FLOC_ID)
  const [getFlocInspectionDrawingsApi] = useLazyQuery<GetInspectionDrawingsByFlocIdQuery>(INSPECTION_DRAWINGS_GET_BY_FLOC_ID)
  const { activeInspectionPoint, setActiveInspectionPoint } = useInspectionPointStore()
  const { setActiveCml, activeCml } = useCmlStore()
  const { setActiveDamage, activeDamage } = useDamageStore()
  const onLeftSideResize = (size: number, _prevSize: number | undefined, containerRef: React.RefObject<HTMLDivElement>) => {
    setRightSideWidth((containerRef.current?.getBoundingClientRect().width ?? window.innerWidth - 250) * (100 - size) / 100)
  }

  const [addIdwgFlocApi] = useMutation<AddIdwgFlocMutation>(INSPECTION_DRAWINGS_ADD_IDWG_FLOC)

  const handleCmlCreatedOrUpdated = () => {
    fetchCmls?.()
  }

  const handleDamageCreatedOrUpdated = () => {
    fetchDamages?.()
  }

  const handleInspectionPointCreatedOrUpdated = () => {
    fetchInspectionPoints?.()
  }

  useEffect(() => {
    if (flocNumber) {
      getAndSetFlocDetail()
    }

    return () => {
      resetData()
    }
  }, [flocNumber])

  useEffect(() => {
    if (editFloc) {
      getFlocPictures()
      getFlocInspectionDrawings()
      setFetchPictures(getFlocPictures)
      setFetchFlocInspectionDrawings(getFlocInspectionDrawings)
    }
  }, [editFloc?.id])

  useEffect(() => {
    if (loading) {
      startLoading()
    } else {
      stopLoading()
    }
  }, [loading])

  const getAndSetFlocDetail = () => getFlocDetailApi({
    variables: { number: flocNumber },
    fetchPolicy: 'no-cache',
  }).then((queryResult) => {
    if (!queryResult.data?.functionalLocation) {
      setNotFound(true)
      return
    }

    const newFloc = { ...(queryResult.data?.functionalLocation ?? {}) } as Partial<FunctionalLocation>
    setActiveFloc(newFloc)
    return newFloc
  })

  const getFlocPictures = () => {
    getFlocPicturesApi({
      variables: {
        flocId: editFloc?.id,
        orderBy: [
          { id: 'desc' },
        ],
      },
      fetchPolicy: 'no-cache',
    }).then((queryResult) => {
      setPictures((queryResult.data?.pictures ?? []).map(pic => ({
        ...pic,
        functionalLocation: editFloc,
        flocId: editFloc?.id,
      } as Partial<Picture>)))
    })
  }

  const getFlocInspectionDrawings = () => {
    getFlocInspectionDrawingsApi({
      variables: {
        flocId: editFloc?.id,
      },
      fetchPolicy: 'no-cache',
    }).then((queryResult) => {
      setFlocInspectionDrawings((queryResult.data?.inspectionDrawings ?? []) as Partial<InspectionDrawing>[])
    })
  }

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DELETE]: (
      <RadDropdownMenuItem disabled={!prms.flocs.delete} className="gap-2 item-destructive" onClick={() => changeDeleteFlocModalDisplay(true)}>
        <LuTrash2 />

        {t('label.deleteFloc')}
      </RadDropdownMenuItem>
    ),
  }

  const handleImportIdwgFromDesktop = async (idwg: Partial<InspectionDrawing>) => {
    setActiveInspectionDrawing(null)
    startLoading()
    try {
      await addIdwgFlocApi({
        variables: {
          idwgId: idwg.id!,
          flocId: activeFloc?.id,
        },
      })
      AppNotifications.success(t('message.success.idwgImported'))
    } catch {
      AppNotifications.error(t('message.error.default.title'))
    } finally {
      stopLoading()
    }

    fetchFlocInspectionDrawings?.()
  }

  if (loading) {
    return <SpinnerLoader />
  }

  if (notFound) {
    return <Error404 />
  }

  return (
    <>
      <PageContent
        header={
          editFloc
            ? (
                <DetailsPageTitleBar
                  title={editFloc?.floc ?? ''}
                  subtitle={editFloc?.description ?? ''}
                  actionButtons={{ additionalActions }}
                />
              )
            : undefined
        }
        footer={editFloc ? <Footer floc={editFloc} /> : undefined}
      >
        {editFloc && (
          <MultipleResizablePanels
            autoSaveId="floc-detail"
            panelsDefaultSize={[40, 60]}
            panelsMinSize={[30, 40]}
            resizerClassName="left-0 panelResizer"
            panelsOnResize={[onLeftSideResize, undefined]}
          >
            <LeftSide floc={editFloc} />
            <RightSide floc={editFloc} />
          </MultipleResizablePanels>
        )}
      </PageContent>
      {activeCml && activeCml.id !== OBJ_NEW_ID && <CmlModal isOpen item={activeCml!} allItems={cmls} onCreatedOrUpdated={handleCmlCreatedOrUpdated} onClose={setActiveCml} onPicturesChanged={fetchPictures} />}
      {activeCml?.id === OBJ_NEW_ID && <CmlCreateModal isOpen item={activeCml} floc={activeFloc!} onCreated={() => handleCmlCreatedOrUpdated()} onOpenChange={isOpen => !isOpen && setActiveCml()} />}
      {activeDamage && activeDamage.id !== OBJ_NEW_ID && <DamageModal isOpen item={activeDamage!} allItems={damages} onCreatedOrUpdated={handleDamageCreatedOrUpdated} onClose={setActiveDamage} onPicturesChanged={fetchPictures} />}
      {activeDamage?.id === OBJ_NEW_ID && <DamageCreateModal isOpen item={activeDamage} floc={activeFloc!} onCreated={() => handleDamageCreatedOrUpdated()} onOpenChange={isOpen => !isOpen && setActiveDamage()} />}
      {!!activeInspectionPoint && activeInspectionPoint.id !== OBJ_NEW_ID && <InspectionPointModal isOpen item={activeInspectionPoint} allItems={inspectionPoints} onCreatedOrUpdated={handleInspectionPointCreatedOrUpdated} onClose={setActiveInspectionPoint} onPicturesChanged={fetchPictures} />}
      {!!activeInspectionPoint && activeInspectionPoint.id === OBJ_NEW_ID && <InspectionPointCreateModal isOpen item={activeInspectionPoint} onCreated={handleInspectionPointCreatedOrUpdated} onOpenChange={isOpen => !isOpen && setActiveInspectionPoint()} />}
      {deleteFlocModalDisplayed && <DeleteFlocModal open floc={editFloc!} onClose={() => changeDeleteFlocModalDisplay(false)} />}
      {activeInspectionDrawing && activeInspectionDrawing.id === OBJ_NEW_ID && <IdwgModal isOpen onCreated={handleImportIdwgFromDesktop} onOpenChange={isOpen => !isOpen && setActiveInspectionDrawing(null)} />}
    </>
  )
}
