import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateNotificationDamageMutation, CreateNotificationWorkflowMutation, Damage, Event, GetNotificationDetailByIdQuery, GetNotificationDetailByNumberQuery, Notification } from '@app/graphql/__types__/graphql'
import { NOTIFICATION_DAMAGES_CREATE, NOTIFICATION_WORKFLOWS_CREATE, NOTIFICATIONS_GET_DETAIL_BY_ID, NOTIFICATIONS_GET_DETAIL_BY_NUMBER } from '@app/graphql/requests'
import { TFormPage } from '@app/types/app'
import { APP_AUTO_NAMING, OBJ_NEW_ID } from '@app/utils/constants'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import LeftSide from '../NotificationBlock/NotificationInfo/components/LeftSide'
import RightSide from '../NotificationBlock/NotificationInfo/components/RightSide'
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels'
import useNotificationStore from '@app/stores/notification'
import { EApiOperator, ENOTIFICATION_NOTIF_STATUS } from '@app/utils/enums'
import DeleteNotificationModal from '../NotificationBlock/NotificationInfo/components/DeleteNotificationModal'
import NotificationWorkflowTableModal from '../NotificationBlock/NotificationInfo/components/Workflow'
import React from 'react'
import useDamageStore from '@app/stores/damage'
import DamageModal from '../../Damage/DamageModal'
import PageContent from '@app/components/Layout/PageContent'
import NotificationDamageSelectionModal from '../../Damage/NotificationDamageSelectionModal'
import { DamageCreateModal } from '@app/components/Modal/Inspection/Damage/Create'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'

type TNotificationDetail = Readonly<TFormPage & {
  event?: Partial<Event>
  onUpdated?: () => void
}>

export default function NotificationDetail({ id, number }: TNotificationDetail) {
  const config = useOptimusConfig()
  const prms = useUserPermissions()
  const [notification, setNotification] = useState<Partial<Notification> | null | undefined>()
  const { editNotification, activeNotification, setActiveNotification, resetData, deleteNotificationModalOpen, workflowModalDisplayed, setRightSideWidth, fetchNotificationDamages, fetchNotificationFlocDamages, setCreateNotificationWorkflowFunc, changeLastDamageUpdated, damageSelectionModalDisplayed, eventDamages, changeLastDamagePicUpdated } = useNotificationStore()
  const [createNotificationDamageApi] = useMutation<CreateNotificationDamageMutation>(NOTIFICATION_DAMAGES_CREATE)
  const [getNotificationByIdApi] = useLazyQuery<GetNotificationDetailByIdQuery>(NOTIFICATIONS_GET_DETAIL_BY_ID, {
    fetchPolicy: 'no-cache',
  })
  const [getNotificationByNumberApi] = useLazyQuery<GetNotificationDetailByNumberQuery>(NOTIFICATIONS_GET_DETAIL_BY_NUMBER, {
    fetchPolicy: 'no-cache',
  })
  const [createNotificationWorkflowApi] = useMutation<CreateNotificationWorkflowMutation>(NOTIFICATION_WORKFLOWS_CREATE)
  const location = useLocation()
  const { activeDamage, setActiveDamage } = useDamageStore()
  const { notificationDamages } = useNotificationStore()

  const onLeftSideResize = (size: number, _prevSize: number | undefined, containerRef: React.RefObject<HTMLDivElement>) => {
    setRightSideWidth((containerRef.current?.getBoundingClientRect().width ?? window.innerWidth - 210) * (100 - size) / 100)
  }

  const handleDamageModalClose = () => {
    setActiveDamage()
  }

  const handleDamagePicturesChanged = () => {
    console.log('damage pictures changed')
    changeLastDamagePicUpdated(activeDamage)
  }

  const handleDamageCreatedOrUpdated = (damage?: Partial<Damage>, operator?: EApiOperator) => {
    if (damage) {
      changeLastDamageUpdated(damage)
    }

    if (operator === EApiOperator.CREATE && notification) {
      createNotificationDamageApi({
        variables: {
          dmgId: damage!.id,
          notifId: notification?.id,
        },
      }).finally(() => {
        fetchNotificationDamages?.()
        fetchNotificationFlocDamages?.()
      })
    } else {
      fetchNotificationDamages?.()
      fetchNotificationFlocDamages?.()
    }
  }

  const userIsReadonly = useMemo(() => {
    switch (activeNotification?.status) {
      case ENOTIFICATION_NOTIF_STATUS.INIT:
        return !prms.notifications.review
      case ENOTIFICATION_NOTIF_STATUS.WAPP:
        return !prms.notifications.approve
      case ENOTIFICATION_NOTIF_STATUS.APPR:
        return !prms.notifications.correctiveRepair
      case ENOTIFICATION_NOTIF_STATUS.QAQC:
        return !prms.notifications.qaqc
      case ENOTIFICATION_NOTIF_STATUS.EXER:
        return !prms.notifications.relaunchExport
      default:
        return true
    }
  }, [prms, activeNotification?.status])

  const fetchNotificationDetails = () => {
    if (number) {
      getNotificationByNumberApi({
        variables: {
          number: String(number),
        },
        fetchPolicy: 'no-cache',
      }).then((queryResult) => {
        const notif = queryResult.data?.notification as Partial<Notification>
        setActiveNotification(notif)
      }).catch(() => {
        setNotification(null)
      })
    } else if (id) {
      if (id !== OBJ_NEW_ID) {
        getNotificationByIdApi({
          variables: {
            id: Number(id),
          },
          fetchPolicy: 'no-cache',
        }).then((queryResult) => {
          const notif = queryResult.data?.notification as Partial<Notification>
          setActiveNotification(notif)
        }).catch(() => {
          setNotification(null)
        })
      } else {
        setActiveNotification({
          id: OBJ_NEW_ID,
          status: ENOTIFICATION_NOTIF_STATUS.INIT,
          notif: APP_AUTO_NAMING,
          creationDate: new Date(),
          __typename: 'Notification',
        })
      }
    }
  }

  useEffect(() => {
    if (!activeNotification) {
      fetchNotificationDetails()
    }
  }, [number, id, location, activeNotification])

  useEffect(() => {
    if (typeof editNotification !== 'undefined') {
      setNotification(editNotification)
    }
  }, [editNotification])

  useEffect(() => {
    setCreateNotificationWorkflowFunc(createNotificationWorkflowApi)
    return () => {
      resetData!()
    }
  }, [])

  const missingMandatoryFields = [ENOTIFICATION_NOTIF_STATUS.INIT, ENOTIFICATION_NOTIF_STATUS.WAPP].includes(notification?.status as ENOTIFICATION_NOTIF_STATUS) ? config.checkMadatoryFields(notification, 'ian') : undefined

  return (
    <PageContent
      header={notification ? <Header notification={notification} readonly={userIsReadonly} /> : undefined}
      footer={notification ? <Footer notification={notification} forwardDisabled={!!missingMandatoryFields?.length} missingMandatoryFields={missingMandatoryFields} /> : undefined}
    >
      {!!notification && (
        <MultipleResizablePanels
          panelsDefaultSize={[40, 60]}
          panelsMinSize={[30, 40]}
          resizerClassName="left-0 panelResizer"
          autoSaveId="notif-detail"
          panelsOnResize={[onLeftSideResize, undefined]}
          onRender={(containerRef: React.RefObject<HTMLDivElement>) => {
            setRightSideWidth((containerRef.current?.getBoundingClientRect().width ?? window.innerWidth - 210) * 60 / 100)
          }}
        >

          <LeftSide notification={notification} readonly={userIsReadonly} refetch={fetchNotificationDetails} />
          <RightSide notification={notification} readonly={userIsReadonly} />
        </MultipleResizablePanels>
      )}
      <DeleteNotificationModal open={deleteNotificationModalOpen} />
      {!!notification && <NotificationWorkflowTableModal notification={notification} open={workflowModalDisplayed} />}
      {activeDamage && activeDamage.id !== OBJ_NEW_ID && <DamageModal isOpen item={activeDamage!} eventDamage={damageSelectionModalDisplayed ? undefined : eventDamages?.find(evtDmg => evtDmg.dmgeId === activeDamage.id) ?? undefined} notificationDamage={damageSelectionModalDisplayed ? undefined : activeDamage.notificationDamages?.find(nd => nd.notifId === activeNotification?.id)} event={damageSelectionModalDisplayed ? undefined : eventDamages?.find(evtDmg => evtDmg.dmgeId === activeDamage.id)?.event ?? undefined} allItems={damageSelectionModalDisplayed ? undefined : notificationDamages} onPicturesChanged={handleDamagePicturesChanged} onCreatedOrUpdated={handleDamageCreatedOrUpdated} onClose={handleDamageModalClose} />}
      {activeDamage?.id === OBJ_NEW_ID && <DamageCreateModal isOpen item={activeDamage} onCreated={dmg => handleDamageCreatedOrUpdated(dmg, EApiOperator.CREATE)} onOpenChange={isOpen => !isOpen && handleDamageModalClose()} />}
      {damageSelectionModalDisplayed && <NotificationDamageSelectionModal />}
    </PageContent>
  )
}
