import { UserPermissionsService } from '@app/services/permissions'
import UserPermissions from '@app/services/permissions/UserPermissions'
import { useState, useEffect } from 'react'

export default function useUserPermissions() {
  const [permissions, setPermissions] = useState<UserPermissions | null>(null)

  useEffect(() => {
    const setPermissionsFromService = () => {
      setPermissions(UserPermissionsService.instance?.permissions || new UserPermissions())
    }

    setPermissionsFromService()
    UserPermissionsService.instance?.addEventListener('loaded', setPermissionsFromService)

    return () => {
      UserPermissionsService.instance?.removeEventListener('loaded', setPermissionsFromService)
    }
  }, [])

  return permissions ?? new UserPermissions()
}
