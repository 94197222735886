import { DamageUncheckedCreateInput, DamageUncheckedUpdateInput, Damage, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { immer } from 'zustand/middleware/immer'
import { create } from 'zustand'
import * as fabric from 'fabric'
import { TFunction } from 'i18next'
import { z } from 'zod'

type State = {
  damageFieldErrors: Record<string, boolean>
  deleteDamageModalOpen: boolean
  editDamage?: Partial<Damage> | null
  updateDamageData?: DamageUncheckedUpdateInput | DamageUncheckedCreateInput
  activeDamage?: Partial<Damage>
}

const initialState: State = {
  deleteDamageModalOpen: false,
  editDamage: null,
  updateDamageData: {},
  damageFieldErrors: {},
  activeDamage: undefined,
}

export const MAX_LENGTH_VALIDATORS = {
  POSITION: 10,
  DESCRIPTION: 50,
  LONG_DESCRIPTION: 255,
}

export const ZOD_DAMAGE_DATAS = (t: TFunction) => ({

  flocId: z.number({ required_error: t('message.error.form.required') }).int().positive({ message: t('message.error.form.required') }),

  position: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.POSITION).min(1, { message: t('message.error.form.required') }),

  description: z.string({ required_error: t('message.error.form.required') }).max(MAX_LENGTH_VALIDATORS.DESCRIPTION).min(1, { message: t('message.error.form.required') }),
  longDescription: z.string().max(MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION).optional(),

  codeGroupId: z.number({ required_error: t('message.error.form.required') }).int().positive({ message: t('message.error.form.required') }),
})

type Actions = {
  updateDamage: (damage: Partial<Damage>, isNew?: boolean) => void
  deleteDamage: (damage?: Partial<Damage>) => void
  setUpdateDamageData: (updateDamageData: DamageUncheckedUpdateInput | DamageUncheckedCreateInput) => void
  setEditDamage: (damage?: Partial<Damage> | null) => void
  updateDamageDataField: (field: string, value: unknown) => void
  setActiveDamage: (activeDamage?: Partial<Damage>) => void
  changeDeleteDamageModalDisplay: (isOpen: boolean) => void
  isSaved: () => boolean
  hasError: () => boolean
  hasFieldError: (field: string, forceCheck?: boolean) => boolean
  cancelEditData: () => void
  createNewDamageMarkup: (position: fabric.Point, inspectionDrawing: Partial<InspectionDrawing>) => void
  resetData: () => void
}

type DamageState = State & Actions

const useDamageStore = create<DamageState>()(
  immer((set, get) => ({
    ...initialState,
    resetData() {
      set({ ...initialState })
    },
    createNewDamageMarkup(position, inspectionDrawing) {
      const newState: Partial<State> = {}
      const coordinates: string = JSON.stringify({ x: position.x, y: position.y })
      const damage: Partial<Damage> = {
        id: OBJ_NEW_ID,
        coordinates2d: coordinates,
        markerCoordinates2d: coordinates,
        idwgId: inspectionDrawing!.id!,
        inspectionDrawing: { ...inspectionDrawing! } as InspectionDrawing,
        display2d: true,
      }
      newState.updateDamageData = {
        coordinates2d: coordinates,
        markerCoordinates2d: coordinates,
        idwgId: inspectionDrawing!.id!,
        display2d: true,
      } as DamageUncheckedCreateInput
      newState.activeDamage = damage
      newState.editDamage = { ...damage }
      set(newState)
    },
    cancelEditData: () => set((state) => {
      const newState: Partial<State> = {
        updateDamageData: {},
        damageFieldErrors: {},
        editDamage: { ...state.activeDamage },
      }
      if (state.activeDamage?.id === OBJ_NEW_ID) {
        newState.activeDamage = undefined
        newState.editDamage = undefined
      }

      return newState
    }),
    isSaved() {
      const state = get()
      if (state.activeDamage) {
        return !(state.updateDamageData && Object.keys(state.updateDamageData).length > 0)
      }

      return true
    },

    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get()
      if (state.activeDamage) {
        if (!Object.keys(state.updateDamageData ?? {}).includes(field) && !forceCheck) {
          return false
        }

        if (state.damageFieldErrors?.[field] === true) {
          return true
        }

        switch (field) {
          case 'position':
          { const position = (state.editDamage?.position ?? '').trim()
            return position.length > MAX_LENGTH_VALIDATORS.POSITION || position === '' }
          case 'description':
          { const description = (state.editDamage?.description ?? '').trim()
            return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '' }
          case 'longDescription':
          { const longDescription = (state.editDamage?.longDescription ?? '').trim()
            return longDescription.length > MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION }
          case 'codeGroupId':
            return !state.editDamage?.codeGroupId
          case 'flocId':
            return !state.editDamage?.flocId
          default:
            break
        }
      }

      return false
    },
    hasError() {
      const state = get()
      if (state.activeDamage) {
        return ['position', 'description', 'codeGroupId', 'longDescription', 'flocId'].some((field: string) => state.hasFieldError(field, true))
      }

      return false
    },
    setActiveDamage(activeDamage) {
      set({
        activeDamage,
        editDamage: activeDamage ? { ...activeDamage } : undefined,
        updateDamageData: undefined,
        damageFieldErrors: undefined,
      })
    },
    updateDamageDataField: (field: string, value: unknown) => set(state => ({
      updateDamageData: {
        ...state.updateDamageData,
        [field]: state.activeDamage?.id === OBJ_NEW_ID
          ? value
          : {
              set: value,
            },
      },
      damageFieldErrors: {
        ...state.damageFieldErrors,
        [field]: false,
      },
    })),
    setUpdateDamageData(updateDamageData: DamageUncheckedUpdateInput | DamageUncheckedCreateInput) {
      set({ updateDamageData })
    },
    setEditDamage(editDamage) {
      set({ editDamage })
    },
    updateDamage: (damage: Partial<Damage>, isNew?: boolean) => set(() => {
      const { activeDamage } = get() ?? {}
      const damageId: number = isNew ? OBJ_NEW_ID : damage.id!
      const newState: Partial<State> = {}

      if (activeDamage && damageId === activeDamage.id!) {
        newState.activeDamage = {
          ...activeDamage,
          ...damage,
        }
        newState.editDamage = { ...newState.activeDamage }
      }

      return newState
    }),
    deleteDamage: (damage?: Partial<Damage>) => set((state) => {
      const deletedDamage: Partial<Damage> | undefined | null = damage ?? state.activeDamage
      const newState: Partial<State> = {}
      if (deletedDamage) {
        newState.deleteDamageModalOpen = false
        if (deletedDamage === state.activeDamage) {
          newState.activeDamage = undefined
        }
      }

      return newState
    }),
    changeDeleteDamageModalDisplay: (isOpen: boolean) => set({
      deleteDamageModalOpen: isOpen,
    }),
  })),
)

export default useDamageStore
