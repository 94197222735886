import { RadInput } from '@holis/react-ui/rad'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { LuUploadCloud } from 'react-icons/lu'

interface IProps {
  readonly onFileCb: (e: File) => void
  readonly accept?: string
}

export function FileUpload(props: IProps) {
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    props.onFileCb(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.accept,
  })

  return (
    <div>
      <div>
        <label
          {...getRootProps()}
          className="relative flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border py-6 hover:bg-accent transition-colors"
        >
          <div className="text-center">
            <div className="mx-auto max-w-min rounded-md border p-2">
              <LuUploadCloud size={20} />
            </div>

            <p className="mt-2 text-sm font-semibold">
              Drag files
            </p>

            <p className="text-xs text-muted-foreground">
              or click to upload files
            </p>
          </div>
        </label>

        <RadInput
          {...getInputProps()}
          id="dropzone-file"
          accept={props.accept}
          type="file"
          className="hidden"
        />
      </div>
    </div>
  )
}
