import { QueryResult, useMutation, useQuery } from '@apollo/client'
import AppAutocomplete from '@app/components/Common/Form/Autocomplete'
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { Document, GetAllRefDocsCatalogsQuery, GetAllSectorsQuery, RefDocsCatalog, Sector, UpdateDocumentByIdMutation } from '@app/graphql/__types__/graphql'
import { DOCUMENTATION_CATALOGS_GET_MANY, DOCUMENTATION_DOCUMENTS_UPDATE_BY_ID, SECTORS_GET_ALL } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { MAX_LENGTH_VALIDATORS, useDocumentStore, ZOD_DOCUMENT_DATAS } from '@app/stores/document'
import { useLayoutStore } from '@app/stores/layout'
import { TFieldsBlock, TMaybeCodeDescriptionDatas, TRenderAutocompleteProps } from '@app/types/app'
import { EDOCUMENTATION_CATALOGS_CATEGORY, EFieldType } from '@app/utils/enums'
import { setObjValueByPath } from '@app/utils/functions'
import { handleFormInputKeydown, renderCodeAndDescription } from '@app/utils/functions/forms'
import { RadForm } from '@holis/react-ui/rad'
import { zodResolver } from '@hookform/resolvers/zod'
import _ from 'lodash'
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LuClipboardList } from 'react-icons/lu'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'
import { ROUTE_DOCUMENTATION_DOC_DETAIL } from '@app/utils/constants'

type TDocumentInfo = Readonly<{
  doc: Partial<Document>
}>

export default function DocumentInfo({ doc }: TDocumentInfo) {
  const { t } = useTranslation()
  const { stopLoading } = useLayoutStore()
  const navigate = useNavigate()
  const { editDoc, activeDoc, setEditDoc, updateData, setUpdateData, updateDataField, updateDoc, fetchDocs, updateFieldError, hasFieldError } = useDocumentStore()
  const [submitRequested, setSubmitRequested] = useState<boolean>(false)

  const [updateDocByIdApi] = useMutation<UpdateDocumentByIdMutation>(DOCUMENTATION_DOCUMENTS_UPDATE_BY_ID)

  const docsCatalogsQueryResult = useQuery<GetAllRefDocsCatalogsQuery>(DOCUMENTATION_CATALOGS_GET_MANY)
  const sectorsQueryResult = useQuery<GetAllSectorsQuery>(SECTORS_GET_ALL)

  const zodFormObject = z.object(_.omit(ZOD_DOCUMENT_DATAS(t), 'flocId', 'typeId'))
  const form = useForm<z.infer<typeof zodFormObject>>({
    resolver: zodResolver(zodFormObject),
    values: {
      document: doc?.document ?? '',
      docName: doc?.docName?.toString() ?? '',
      description: doc?.description?.toString() ?? '',
      revision: doc?.revision?.toString() ?? '',
    },
    mode: 'onBlur',
  })

  const htmlForm = useRef<HTMLFormElement | null>(null)

  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    if (field === 'document') {
      value = (value as string).toUpperCase()
    }

    const editedDoc = { ...editDoc }
    setObjValueByPath(editedDoc, field, value)
    setEditDoc(editedDoc)
    if (update) {
      updateDataField(field, value)
    }
  }

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit()

      setSubmitRequested(false)
    }
  }, [submitRequested])

  if (!editDoc) {
    return null
  }

  const renderAutocomplete = (props: TRenderAutocompleteProps): React.ReactNode => {
    const { fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps, isDisabled } = props ?? {}
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((updateData && Object.keys(updateData).includes(field!)) || (!(updateData && Object.keys(updateData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string)
            }

            const editedDoc = _.cloneDeep(editDoc)
            if (foreignField) {
              setObjValueByPath(editedDoc!, foreignField!, item)
            }

            if (field) {
              setObjValueByPath(editedDoc!, field!, item?.id ?? null)
            }

            setEditDoc(editedDoc)
            updateDataField(field!, item?.id ?? null)

            handleFieldBlur()
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          disabled: isDisabled || inputProps?.disabled,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
        }}
      />
    )
  }

  const updateDocState = (newData: Partial<Document>, isNew?: boolean) => {
    const newDoc = {
      ...editDoc,
      ...newData,
    } as Partial<Document>

    updateDoc(newDoc, isNew)
    setUpdateData({})
    fetchDocs?.()
  }

  const handleDocUpdateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title')
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`document`)')) {
        if (AppNotifications.timeoutId) {
          clearTimeout(AppNotifications.timeoutId)
        }

        errorMessage = t('message.error.unique.documentation.document.reference')
        updateFieldError('document', true)
        form.setError('document', {
          message: errorMessage,
        })
        return
      }
    }

    AppNotifications.error(errorMessage)
  }

  const handleUpdateDoc = () => updateDocByIdApi({ variables: { id: doc!.id, data: updateData } }).then((newData) => {
    const updatedDoc = newData?.data?.updateOneDocument as Partial<Document>
    const docNumberChanged = updatedDoc.document && updatedDoc.document !== activeDoc?.document
    AppNotifications.success(t('message.success.documentUpdated'))
    // If doc number changed, modify last url segment to set the new doc number
    if (docNumberChanged) {
      navigate(ROUTE_DOCUMENTATION_DOC_DETAIL.replace(/:number/, updatedDoc.document!), {
        replace: true,
      })
      return
    }

    updateDocState(updatedDoc)
  }).catch((err) => {
    handleDocUpdateError(err)
  }).finally(stopLoading)

  const handleFieldBlur = () => {
    setSubmitRequested(true)
  }

  const handleFormSubmitSucess = () => {
    // Submit changes only if there are pending value changes
    if (updateData && Object.keys(updateData).length) {
      handleUpdateDoc()
    }
  }

  const fieldBlocks: TFieldsBlock[] = [
    {
      title: 'label.identification',
      fields: [
        {
          label: 'label.reference',
          field: 'document',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: doc!.document,
          value: editDoc!.document,
          isRequired: true,
          hasError: hasFieldError('document'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.DOCUMENT,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.description',
          field: 'description',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: doc!.description,
          value: editDoc!.description,
          isRequired: true,
          hasError: hasFieldError('description'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.revision',
          field: 'revision',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: doc!.revision,
          value: editDoc!.revision,
          hasError: hasFieldError('revision'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.REVISION,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.stage',
          field: 'stageId',
          initialValue: renderCodeAndDescription({ code: doc!.stage?.code, description: doc!.stage?.description } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({ code: editDoc!.stage?.code, description: editDoc!.stage?.description } as TMaybeCodeDescriptionDatas),
          foreignObject: editDoc.stage,
          foreignField: 'stage',
          className: 'flex w-full',
          dbValue: doc!.stageId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: docsCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => ((result as QueryResult<GetAllRefDocsCatalogsQuery>)?.data?.refDocsCatalogs ?? []).filter(item => (item as RefDocsCatalog).category === EDOCUMENTATION_CATALOGS_CATEGORY.STAGE),
          renderMenuItemLabel: field => renderCodeAndDescription({ code: (field as RefDocsCatalog)?.code, description: (field as RefDocsCatalog)?.description } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.sector',
          field: 'sectorId',
          initialValue: renderCodeAndDescription({ code: doc!.sector?.sector, description: doc!.sector?.description } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({ code: editDoc!.sector?.sector, description: editDoc!.sector?.description } as TMaybeCodeDescriptionDatas),
          foreignObject: editDoc.sector,
          foreignField: 'sector',
          className: 'flex w-full',
          dbValue: doc!.sectorId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: sectorsQueryResult,
          getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllSectorsQuery>)?.data?.sectors ?? [],
          renderMenuItemLabel: field => renderCodeAndDescription({ code: (field as Sector)?.sector, description: (field as Sector)?.description } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
      ],
    },
    {
      title: 'label.attributes',
      fields: [
        {
          label: 'label.discipline',
          field: 'disciplineId',
          initialValue: renderCodeAndDescription({ code: doc!.discipline?.code, description: doc!.discipline?.description } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({ code: editDoc!.discipline?.code, description: editDoc!.discipline?.description } as TMaybeCodeDescriptionDatas),
          foreignObject: editDoc.discipline,
          foreignField: 'discipline',
          className: 'flex w-full',
          dbValue: doc!.disciplineId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: docsCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => ((result as QueryResult<GetAllRefDocsCatalogsQuery>)?.data?.refDocsCatalogs ?? []).filter(item => (item as RefDocsCatalog).category === EDOCUMENTATION_CATALOGS_CATEGORY.DISCIPLINE),
          renderMenuItemLabel: field => renderCodeAndDescription({ code: (field as RefDocsCatalog)?.code, description: (field as RefDocsCatalog)?.description } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.type',
          field: 'typeId',
          initialValue: renderCodeAndDescription({ code: doc!.type?.code, description: doc!.type?.description } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({ code: editDoc!.type?.code, description: editDoc!.type?.description } as TMaybeCodeDescriptionDatas),
          foreignObject: editDoc.type,
          foreignField: 'type',
          className: 'flex w-full',
          dbValue: doc!.typeId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: docsCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => ((result as QueryResult<GetAllRefDocsCatalogsQuery>)?.data?.refDocsCatalogs ?? []).filter(item => (item as RefDocsCatalog).category === EDOCUMENTATION_CATALOGS_CATEGORY.TYPE),
          renderMenuItemLabel: field => renderCodeAndDescription({ code: (field as RefDocsCatalog)?.code, description: (field as RefDocsCatalog)?.description } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.phase',
          field: 'phaseId',
          initialValue: renderCodeAndDescription({ code: doc!.phase?.code, description: doc!.phase?.description } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({ code: editDoc!.phase?.code, description: editDoc!.phase?.description } as TMaybeCodeDescriptionDatas),
          foreignObject: editDoc.phase,
          foreignField: 'phase',
          className: 'flex w-full',
          dbValue: doc!.phaseId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: docsCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => ((result as QueryResult<GetAllRefDocsCatalogsQuery>)?.data?.refDocsCatalogs ?? []).filter(item => (item as RefDocsCatalog).category === EDOCUMENTATION_CATALOGS_CATEGORY.PHASE),
          renderMenuItemLabel: field => renderCodeAndDescription({ code: (field as RefDocsCatalog)?.code, description: (field as RefDocsCatalog)?.description } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.originator',
          field: 'originatorId',
          initialValue: renderCodeAndDescription({ code: doc!.originator?.code, description: doc!.originator?.description } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({ code: editDoc!.originator?.code, description: editDoc!.originator?.description } as TMaybeCodeDescriptionDatas),
          foreignObject: editDoc.originator,
          foreignField: 'originator',
          className: 'flex w-full',
          dbValue: doc!.originatorId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: docsCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => ((result as QueryResult<GetAllRefDocsCatalogsQuery>)?.data?.refDocsCatalogs ?? []).filter(item => (item as RefDocsCatalog).category === EDOCUMENTATION_CATALOGS_CATEGORY.ORIGINATOR),
          renderMenuItemLabel: field => renderCodeAndDescription({ code: (field as RefDocsCatalog)?.code, description: (field as RefDocsCatalog)?.description } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.catalog',
          field: 'catalogId',
          initialValue: renderCodeAndDescription({ code: doc!.catalog?.code, description: doc!.catalog?.description } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({ code: editDoc!.catalog?.code, description: editDoc!.catalog?.description } as TMaybeCodeDescriptionDatas),
          foreignObject: editDoc.catalog,
          foreignField: 'catalog',
          className: 'flex w-full',
          dbValue: doc!.catalogId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: docsCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => ((result as QueryResult<GetAllRefDocsCatalogsQuery>)?.data?.refDocsCatalogs ?? []).filter(item => (item as RefDocsCatalog).category === EDOCUMENTATION_CATALOGS_CATEGORY.CATALOG),
          renderMenuItemLabel: field => renderCodeAndDescription({ code: (field as RefDocsCatalog)?.code, description: (field as RefDocsCatalog)?.description } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.fileName',
          field: 'docName',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: doc!.docName,
          value: editDoc.docName,
          hasError: hasFieldError('docName'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.DOCNAME,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
      ],
    },
  ]

  return (
    <RadForm {...form}>
      <form
        ref={htmlForm}
        onSubmit={form.handleSubmit(handleFormSubmitSucess)}
      >
        <div className="flex flex-col">
          <FormGroupHeader>
            <div className="flex items-center gap-1">
              <LuClipboardList />

              {t('label.general')}
            </div>
          </FormGroupHeader>

          <FormFieldsBlock
            isFormContext
            className="text-gray-700"
            fieldsBlocks={fieldBlocks}
            onFieldChange={handleFieldChange}
            onFieldBlur={handleFieldBlur}
          />
        </div>
      </form>
    </RadForm>
  )
}
