import BorderedTable from '@app/components/Common/TreeList/BorderedTable'
import { Event } from '@app/graphql/__types__/graphql'
import { IColumn, TCellRenderer, TDataRowTreeList } from '@holis/react-ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EEventFilter, EWORKORDER_EVENT_STATUS } from '@app/utils/enums'
import Filters from './components/Filters'
import { TLabelValue } from '@app/types/app'

type TEventList = Readonly<{
  events?: Partial<Event>[]
  simulatingEvents?: Partial<Event>[]
  isLoading?: boolean
  error?: React.ReactNode
  columns: IColumn[]
  cellRenderers?: Record<string, TCellRenderer<TDataRowTreeList>>
}>

export default function EventList({ events, simulatingEvents, isLoading, error, columns, cellRenderers }: TEventList) {
  const { t } = useTranslation()
  const [filteredEvents, setFilteredEvents] = useState<Partial<Event>[]>()
  const [filterStatuses, setFilterStatuses] = useState<EEventFilter[]>([EEventFilter.PROCESSING, EEventFilter.COMPLETED])

  const filterItems = (data: TDataRowTreeList[], searchText: string) => {
    const token = searchText.trim().toLowerCase()
    return data.filter((item: TDataRowTreeList) => {
      const evt = item as Partial<Event>
      return evt.event?.toLowerCase().includes(token)
        || evt.description?.toLowerCase().includes(token)
        || evt.type?.type?.toLowerCase().includes(token)
    })
  }

  useEffect(() => {
    setFilteredEvents(events?.filter(event => (simulatingEvents?.find(item => item.id === event.id))
      || (filterStatuses.includes(EEventFilter.CANCELLED) && event.status === EWORKORDER_EVENT_STATUS.CANCELLED)
      || (filterStatuses.includes(EEventFilter.COMPLETED) && event.status === EWORKORDER_EVENT_STATUS.COMPLETED)
      || (filterStatuses.includes(EEventFilter.PROCESSING) && event.status !== EWORKORDER_EVENT_STATUS.COMPLETED && event.status !== EWORKORDER_EVENT_STATUS.CANCELLED)))
  }, [filterStatuses, simulatingEvents, events])

  return (
    <div className="flex flex-col w-full px-4">
      <BorderedTable
        hasSearchBar
        searchFilter={filterItems}
        leftSearchBarComponent={(
          <Filters
            value={filterStatuses}
            onChange={(items: TLabelValue<EEventFilter>[]) => setFilterStatuses(items.map(item => item.value))}
          />
        )}
        title={t('label.events')}
        className="flex-grow overflow-hidden w-full min-h-[150px]"
        listProps={{
          data: filteredEvents as unknown as TDataRowTreeList[],
          columns,
          cellRenderers,
          isLoading,
          error,
        }}
      />

    </div>
  )
}
