import { gql } from '@apollo/client'

export const USER_ROLES_COMMON_FIELDS = gql`
fragment UserRoleCommonFields on UserRole {
    userId
    roleId
    notifyNewReport
}
`

export const USER_INFOS_COMMON_FIELDS = gql`
    ${USER_ROLES_COMMON_FIELDS}
    fragment UserInfosCommonFields on UserInfo {
        userId
        username
        firstName
        lastName
        email
        userRole {
            ...UserRoleCommonFields
        }
    }
`
