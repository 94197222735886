import { useLazyQuery } from '@apollo/client'
import { Doc, Document, GetDocByNameQuery } from '@app/graphql/__types__/graphql'
import { FILE_DOCS_GET_BY_NAME } from '@app/graphql/requests'
import { OptimusClientConfig } from '@app/utils/clientConfig'
import { createObjUrlFromUrl } from '@app/utils/functions'
import { useHolisAuth } from '@holis/auth-client-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DocumentViewHeader from './components/DocHeader'
import PDFViewer from '@app/components/Common/Viewer/PDFViewer'
import ImgViewer from '@app/components/Common/Viewer/ImgViewer'
import DocumentList from './components/DocumentList'
import { LuLoader2 } from 'react-icons/lu'

type TDocumentListAndViewer = Readonly<{
  documents: Partial<Document>[]
  onManageDocumentsClick?: () => void
  onAddToAttachmentsClick?: (doc: Partial<Document>) => void
  readonly?: boolean
}>

export default function DocumentListWithViewer({ documents: docu, readonly, onManageDocumentsClick, onAddToAttachmentsClick }: TDocumentListAndViewer) {
  const [selectedDoc, setSelectedDoc] = useState<Partial<Document>>()
  const [selectedFileDoc, setSelectedFileDoc] = useState<Partial<Doc>>()
  const [docSrc, setDocSrc] = useState<string>()
  const [docIsPdf, setDocIsPdf] = useState<boolean>(false)
  const { getAccessToken } = useHolisAuth()
  const [getFileDocByDocNameApi] = useLazyQuery<GetDocByNameQuery>(FILE_DOCS_GET_BY_NAME)

  const getFileDoc = () => {
    getFileDocByDocNameApi({
      variables: {
        docName: selectedDoc?.docName,
      },
      fetchPolicy: 'no-cache',
    })
      .then(result => setSelectedFileDoc(result.data?.doc as Partial<Doc> | undefined))
      .catch(() => {
        setDocSrc(undefined)
        setSelectedFileDoc(undefined)
      })
  }

  useEffect(() => {
    if (selectedDoc?.docName) {
      getFileDoc()
    }
  }, [selectedDoc])

  useEffect(() => {
    createObjUrlFromUrl(
      `${OptimusClientConfig.current.fileBaseUrl}/${selectedFileDoc?.docName}?ts=${moment().toISOString()}`,
      { getAccessToken })
      .then((url) => {
        setDocSrc(url ?? undefined)
        setDocIsPdf(selectedFileDoc?.extension?.toLowerCase() === 'pdf')
      })
  }, [selectedFileDoc])

  const getViewer = () => {
    if (docIsPdf) {
      return (
        <PDFViewer
          isExternalSrc
          file={docSrc}
        />
      )
    }

    return (
      <ImgViewer
        isExternalSrc
        src={docSrc!}
      />
    )
  }

  const render = () => {
    if (selectedDoc) {
      return (
        <div className="h-full w-full flex flex-col">
          <DocumentViewHeader
            selectedDocument={selectedDoc}
            goBack={() => {
              setSelectedDoc(undefined)
              setSelectedFileDoc(undefined)
              setDocSrc(undefined)
            }}
          />

          {docSrc
            ? getViewer()
            : (
                <div className="flex flex-col justify-center items-center h-full">
                  <LuLoader2 className="text-primary animate-spin text-4xl" />
                </div>
              )}
        </div>
      )
    }

    return (
      <DocumentList
        documents={docu}
        readonly={readonly}
        docSelected={setSelectedDoc}
        manageDocsSelected={onManageDocumentsClick}
        addToAttachmentsSelected={onAddToAttachmentsClick}
      />
    )
  }

  return (
    <div className="h-full">
      {render()}
    </div>
  )
}
