import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { DeleteInspectionDrawingByIdMutation, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { INSPECTION_DRAWINGS_DELETE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { ROUTE_DOCUMENTATION_IDWG } from '@app/utils/constants'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
type TDeleteIdwgModal = Readonly<IConfirmModal & {
  idwg: Partial<InspectionDrawing>
}>

export default function DeleteIdwgModal({ idwg, ...restProps }: TDeleteIdwgModal) {
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteIdwgApi, { data, loading, error }] = useMutation<DeleteInspectionDrawingByIdMutation>(INSPECTION_DRAWINGS_DELETE_BY_ID)
  const navigate = useNavigate()
  const { t } = useTranslation()
  useEffect(() => {

  }, [data, loading, error])
  const onConfirm = () => {
    startLoading()
    deleteIdwgApi({
      variables: { id: idwg.id },
    }).then(() => {
      navigate(ROUTE_DOCUMENTATION_IDWG)
      AppNotifications.success(t('message.success.deleteIdwg'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteIdwg'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteIdwg')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      {...restProps}
    />
  )
}
