import { useLazyQuery } from '@apollo/client'
import InspectionPointItem from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/InspectionPointList/InspectionPointItem'
import { EventInspectionPoint, GetLatestEventInspectionPointByInspIdQuery, InspectionPoint } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID } from '@app/graphql/requests'
import useInspectionPointStore from '@app/stores/insp'
import { RadContextMenu, RadContextMenuTrigger, RadContextMenuContent, RadContextMenuItem } from '@holis/react-ui/rad'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuExternalLink } from 'react-icons/lu'

type TEventInspectionPointItem = Readonly<{
  inspectionPoint: Partial<InspectionPoint>
  eventInspectionPoint?: Partial<EventInspectionPoint>
  readonly?: boolean
  hasMenuContext?: boolean
}>

export default function EventInspectionPointItem({ inspectionPoint, eventInspectionPoint, hasMenuContext }: TEventInspectionPointItem) {
  const [evtInsp, setEvtInsp] = useState<Partial<EventInspectionPoint | undefined>>(eventInspectionPoint)
  const { setActiveInspectionPoint } = useInspectionPointStore()
  const { t } = useTranslation()
  const [getLatestEventInspectionPointByInspIdApi] = useLazyQuery<GetLatestEventInspectionPointByInspIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID)

  useEffect(() => {
    if (!eventInspectionPoint) {
      getLatestEventInspectionPointByInspIdApi({
        variables: {
          inspId: inspectionPoint.id,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setEvtInsp(queryResult.data?.findFirstEventInspectionPoint as Partial<EventInspectionPoint>)).catch(setEvtInsp)
    } else {
      setEvtInsp(eventInspectionPoint)
    }
  }, [eventInspectionPoint])

  return (
    <RadContextMenu key={`inspection-point-${inspectionPoint.id}`}>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <InspectionPointItem hasMenuContext inspectionPoint={inspectionPoint} eventInspectionPoint={evtInsp} onClick={() => setActiveInspectionPoint(inspectionPoint)} />
      </RadContextMenuTrigger>

      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveInspectionPoint(inspectionPoint)}>
          <LuExternalLink className="mr-2" />
          {' '}
          {t('label.open')}
        </RadContextMenuItem>
      </RadContextMenuContent>

    </RadContextMenu>
  )
}
