import { Notification, NotificationUncheckedUpdateInput, UpdateNotificationByIdMutation } from '@app/graphql/__types__/graphql'
import { useTranslation } from 'react-i18next'
import DetailsPageFooter from '@app/components/Layout/DetailsPagefooter'
import { useMutation } from '@apollo/client'
import Separator from '@app/components/Common/Separator'
import { NOTIFICATIONS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import useNotificationStore from '@app/stores/notification'
import { RadBadge, RadButton, RadDropdownMenuItem } from '@holis/react-ui/rad'
import { useLocation, useNavigate } from 'react-router-dom'
import { LuCheckCircle, LuInfo, LuXCircle } from 'react-icons/lu'
import { getWorkflowAvailableActions, WorkflowAction, WorkflowActionDirection } from '@app/utils/functions/workflows'
import { useMemo, useState } from 'react'
import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu'
import { useHolisAuth } from '@holis/auth-client-react'
import { navigateOneSegmentBelow } from '@app/utils/functions/navigation'
import ValidationWarning from '@app/pages/Scheduling/Events/Details/components/ReportValidationWarning'
import { DataExportService } from '@app/services/api/DataExportService'
import ExportStatusModal from '../../../../ExportStatusModal'
import { ENOTIFICATION_NOTIF_STATUS } from '@app/utils/enums'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
import { tailwindColorToBgFgStyle } from '@app/utils/functions'
type TFooter = Readonly<{
  notification: Partial<Notification>
  forwardDisabled?: boolean
  backwardDisabled?: boolean
  missingMandatoryFields?: string[]
}>

export default function Footer({ notification, forwardDisabled, missingMandatoryFields, backwardDisabled }: TFooter) {
  const { t } = useTranslation()
  const prms = useUserPermissions()
  const { activeNotification, updateNotificationState, createNotificationWorkflowFunc } = useNotificationStore()
  const { statusNotif } = activeNotification ?? {}
  const { startLoading, stopLoading } = useLayoutStore()
  const [updateNotificationByIdApi] = useMutation<UpdateNotificationByIdMutation>(NOTIFICATIONS_UPDATE_BY_ID)
  const navigate = useNavigate()
  const location = useLocation()
  const { user, getAccessToken } = useHolisAuth()
  const [exportStatusModalOpened, setExportStatusModalOpened] = useState(false)

  const updateIanStatus = async (status: string) => {
    const actionDate = new Date()
    const data: NotificationUncheckedUpdateInput = { status: { set: status } }
    switch (status) {
      case ENOTIFICATION_NOTIF_STATUS.WACK:
        data.approvedBy = { set: user?.username }
        break
      default:
        break
    }

    const queryResult = await updateNotificationByIdApi({ variables: { id: notification.id, data } })
    updateNotificationState(queryResult.data?.updateOneNotification as Partial<Notification>, actionDate, false)
    AppNotifications.success(t('message.success.notificationUpdated'))
  }

  const onIanAction = async (action: WorkflowAction) => {
    startLoading()

    try {
      // Update status
      await updateIanStatus(action.to)

      // Create workflow record
      await createNotificationWorkflowFunc?.({
        variables: {
          data: {
            notifId: notification.id,
            status: `${action.from ?? '*'}|${action.to}`,
            date: new Date(),
            userLogin: user?.username,
            description: t(`label.ianActions.descriptions.${action.key}`),
          },
        },
      })

      stopLoading()

      // Close form?
      if (action.closeForm) {
        navigateOneSegmentBelow(location, navigate)
      }

      // Launch export task?
      if (action.to === 'WACK') {
        new DataExportService(getAccessToken).launchExport('notifications', notification.id!.toString())
      }
    } catch {
      stopLoading()
      AppNotifications.error(t('message.error.default.title'))
    }
  }

  const availableActions = useMemo(() => getWorkflowAvailableActions('ian', notification?.status), [notification?.status])
  const backAction = availableActions.backward
  const forwardAction = availableActions.forward

  switch (backAction?.key) {
    case 'relaunch_ian_export':
      if (!prms.notifications.relaunchExport) {
        backwardDisabled = true
      }

      break
    default:
      break
  }

  switch (forwardAction?.key) {
    case 'approve_of_ian':
      if (!prms.notifications.approve) {
        forwardDisabled = true
      }
      break
    case 'review_of_ian':
      if (!prms.notifications.review) {
        forwardDisabled = true
      }
      break
    case 'corrective_repair':
      if (!prms.notifications.correctiveRepair) {
        forwardDisabled = true
      }
      break
    case 'qaqc_and_close':
      if (!prms.notifications.qaqc) {
        forwardDisabled = true
      }
      break
    default:
      break
  }

  const displayExportStatus = [ENOTIFICATION_NOTIF_STATUS.EXER, ENOTIFICATION_NOTIF_STATUS.APPR, ENOTIFICATION_NOTIF_STATUS.WACK].includes(notification.status as ENOTIFICATION_NOTIF_STATUS)

  return (
    <>
      <DetailsPageFooter
        actionButtons={(
          <>
            {missingMandatoryFields
              && (
                <ValidationWarning
                  warningsOrErrors={[t('message.error.missingMandatoryFields', { fields: missingMandatoryFields.join(', ') })]}
                  type="error"
                  label={t('label.mandatoryFieldsMissing')}
                />
              )}
            {/* Action buttons */}
            {backAction && (
              <RadButton className="bg-destructive hover:bg-destructive/90 gap-2" disabled={backwardDisabled} onClick={() => onIanAction(backAction)}>
                {backAction.icon ? <backAction.icon /> : <LuXCircle />}
                {' '}
                {t(`label.ianActions.buttons.${backAction.key}`)}
              </RadButton>
            )}
            {forwardAction && (
              <RadButton className="bg-primary gap-2" disabled={forwardDisabled} onClick={() => onIanAction(forwardAction)}>
                {forwardAction.icon ? <forwardAction.icon /> : <LuCheckCircle />}
                {' '}
                {t(`label.ianActions.buttons.${forwardAction.key}`)}
              </RadButton>
            )}
            { // Secondary actions
              (availableActions.secondary.length > 0 || displayExportStatus) && (
                <ActionThreeDotsMenu>
                  {
                    availableActions.secondary.map(action => (
                      <RadDropdownMenuItem key={action.key} className="gap-2" onClick={() => onIanAction(action)}>
                        {
                          action.icon
                            ? <action.icon />
                            : (
                                action.dir === WorkflowActionDirection.forward
                                  ? <LuCheckCircle />
                                  : <LuXCircle />
                              )
                        }
                        {t(`label.ianActions.buttons.${action.key}`)}
                      </RadDropdownMenuItem>
                    ))
                  }
                  {
                    displayExportStatus && (
                      <RadDropdownMenuItem className="gap-2" onClick={() => setExportStatusModalOpened(true)}>
                        <LuInfo />
                        {' '}
                        {t('label.showExportStatus')}
                      </RadDropdownMenuItem>
                    )
                  }
                </ActionThreeDotsMenu>
              )
            }
          </>
        )}
      >
        {statusNotif && <RadBadge variant="outline" className="py-1 font-normal" style={tailwindColorToBgFgStyle(statusNotif?.displayColor)}>{statusNotif?.description}</RadBadge>}
        <Separator />
        <span className="text-black">
          ID: #
          {notification.id}
        </span>
      </DetailsPageFooter>
      {exportStatusModalOpened && (
        <ExportStatusModal
          opened
          title={notification.notif}
          itemType="ian"
          itemId={notification.id!}
          onClose={() => setExportStatusModalOpened(false)}
        />
      )}
    </>
  )
}
