import { gql } from '@apollo/client'
import { CMLS_COMMON_FIELDS } from '../fragments'

export const CMLS_GET_BY_IDWG_ID = gql`
${CMLS_COMMON_FIELDS}
query GetCmlsByIdwgId($idwgId: Int!, $orderBy: [CmlOrderByWithRelationInput!]) {
  cmls(where: {idwgId: {equals: $idwgId}}, orderBy: $orderBy){
    ...CmlCommonFields
    _count {
      pictures
    }
  }
}`

export const CMLS_GET_BY_FLOC_IDS = gql`
${CMLS_COMMON_FIELDS}
query GetCmlsByFlocIds($flocIds: [Int!], $orderBy: [CmlOrderByWithRelationInput!]) {
  cmls(where: {flocId: {in: $flocIds}}, orderBy: $orderBy){
    ...CmlCommonFields
    _count {
      pictures
    }
  }
}`

export const CMLS_GET_BY_FLOC_ID = gql`
${CMLS_COMMON_FIELDS}
query GetCmlsByFlocId($flocId: Int!, $orderBy: [CmlOrderByWithRelationInput!]) {
  cmls(where: {flocId: {equals: $flocId}}, orderBy: $orderBy){
    ...CmlCommonFields
    _count {
      pictures
    }
  }
}`

export const CMLS_CREATE = gql`
${CMLS_COMMON_FIELDS}
mutation CreateCml($data: CmlUncheckedCreateInput!) {
  createOneCml(data: $data) {
    ...CmlCommonFields
  }
}`

export const CMLS_UPDATE_BY_ID = gql`
${CMLS_COMMON_FIELDS}
mutation UpdateCmlById($id: Int!, $data: CmlUncheckedUpdateInput!) {
  updateOneCml(data: $data, where: {id: $id}) {
    ...CmlCommonFields
  }
}`

export const CMLS_UPDATE_IDWG_MANY = gql`
mutation UpdateIdwgCmls($ids: [Int!], $idwg: Int) {
  updateManyCml(data: {idwgId: {set: $idwg}}, where: {id: {in: $ids}}) {
    count
  }
}`

export const CMLS_DELETE_BY_ID = gql`
mutation DeleteCmlById($id: Int!) {
  deleteOneCml(where: {id: $id}) {
    id
  }
}`

export const CMLS_GET_COUNTER = gql`
query GetCmlsCounter {
  aggregateCml {
    _count {
      id
    }
  }
}
`
