import { gql } from '@apollo/client'
import { FILE_ATTACHMENTS_COMMON_FIELDS, FLOC_CATALOGS_COMMON_FIELDS, FLOCS_DETAIL_COMMON_FIELDS, NOTIFICATION_CATALOGS_COMMON_FIELDS, NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS, NOTIFICATION_REF_CONSEQUENCE_COMMON_FIELDS, NOTIFICATION_REF_LIKELIHOOD_COMMON_FIELDS, NOTIFICATION_REF_PRIORITY_COMMON_FIELDS, NOTIFICATION_REF_SEVERITY_COMMON_FIELDS, NOTIFICATION_REF_TYPES_COMMON_FIELDS } from '.'

export const NOTIFICATIONS_COMMON_FIELDS = gql`
  fragment NotificationCommonFields on Notification {
      causeId
      causeText
      consequence
      creationDate
      damageId
      damageText
      deckLevel
      description
      detectionId
      effectId
      externalId
      externalStatus
      ffsRequired
      flocId
      id
      isBelowMawt
      isLeak
      isTempRepair
      jobTypeId
      likelihood
      longDescription
      mainWorkCenterId
      notif
      objectId
      plannerGroupId
      plantWorkCenterId
      priority
      repairSystemId
      reportedBy
      requiredEndDate
      requiredStartDate
      ressourceId
      severity
      shutdownId
      status
      statusExport
      typeId
      origin
      approvedBy
      userResponsibilityId
      statusNotif {
        description
        displayColor
      }
  }
`

export const NOTIFICATIONS_DETAIL_FIELDS = gql`
  ${NOTIFICATIONS_COMMON_FIELDS}
  ${NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS}
  ${NOTIFICATION_REF_TYPES_COMMON_FIELDS}
  ${FLOCS_DETAIL_COMMON_FIELDS}
  ${FLOC_CATALOGS_COMMON_FIELDS}
  ${NOTIFICATION_CATALOGS_COMMON_FIELDS}
  ${NOTIFICATION_REF_CONSEQUENCE_COMMON_FIELDS}
  ${NOTIFICATION_REF_SEVERITY_COMMON_FIELDS}
  ${NOTIFICATION_REF_PRIORITY_COMMON_FIELDS}
  ${NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS}
  ${NOTIFICATION_REF_LIKELIHOOD_COMMON_FIELDS}
  ${FILE_ATTACHMENTS_COMMON_FIELDS}
  fragment NotificationDetailFields on Notification {
      ...NotificationCommonFields
      notifType {
        ...RefNotifTypeCommonFields
      }
      detection {
        ...RefNotifAdditionalDataCommonFields
      }
      functionalLocation {
        ...FlocDetailFields
      }
      mainWorkCenter {
        ...RefFlocCatalogsCommonFields
      }
      plannerGroup {
        ...RefFlocCatalogsCommonFields
      }
      plantWorkCenter {
        ...RefFlocCatalogsCommonFields
      }
      userResponsibility {
        ...RefNotifCatalogCommonFields
      }
      effect {
        ...RefNotifAdditionalDataCommonFields
      }
      object {
        ...RefNotifAdditionalDataCommonFields
      }
      cause {
        ...RefNotifAdditionalDataCommonFields
      }
      damage {
        ...RefNotifAdditionalDataCommonFields
      }
      notifConsequence {
        ...RefNotifConsequenceCommonFields
      }
      notifLikelihood {
        ...RefNotifLikelihoodCommonFields
      }
      notifSeverity {
        ...RefNotifSeverityCommonFields
      }
      notifPriority {
        ...RefNotifPriorityCommonFields
      }
      shutdown {
        ...RefFlocCatalogsCommonFields
      }
      jobType {
        ...RefNotifCatalogCommonFields
      }
      ressource {
        ...RefNotifCatalogCommonFields
      }
      repairSystem {
        ...RefNotifCatalogCommonFields
      }
      attachments {
        ...AttachmentCommonFields
      }
  }
`

export const NOTIFICATION_VIEWS_COMMON_FIELDS = gql`
  fragment NotificationViewCommonFields on NotificationView {
      causeId
      causeText
      consequence
      creationDate
      damageId
      damageText
      deckLevel
      description
      detectionId
      effectId
      externalId
      externalStatus
      ffsRequired
      flocId
      id
      isBelowMawt
      isLeak
      isTempRepair
      jobTypeId
      likelihood
      longDescription
      mainWorkCenterId
      notif
      objectId
      plannerGroupId
      plantWorkCenterId
      priority
      repairSystemId
      reportedBy
      approvedBy
      requiredEndDate
      requiredStartDate
      ressourceId
      severity
      shutdownId
      status
      statusExport
      typeId
      origin
      userResponsibilityId
      userResponsibility_code
      userResponsibility_description
      status_description
      status_displayColor
      type_notifType
      type_description
      detection_code
      detection_codeGroup
      detection_shortText
      detection_shortTextCode
      schedulingTag_floc
      schedulingTag_description
      schedulingTag_techClass_techClass
      schedulingTag_techClass_description
      schedulingTag_techClass_class_class
      schedulingTag_techClass_class_description
      schedulingTag_sector_sector
      schedulingTag_sector_site_site
      schedulingTag_sector_site_description
      schedulingTag_sector_site_longDescription
      schedulingTag_classSece
      mainWorkCenter_code
      mainWorkCenter_description
      plannerGroup_code
      plannerGroup_description
      plantWorkCenter_code
      plantWorkCenter_description
      effect_code
      effect_codeGroup
      effect_shortText
      effect_shortTextCode
      object_code
      object_codeGroup
      object_shortText
      object_shortTextCode
      cause_code
      cause_codeGroup
      cause_shortText
      cause_shortTextCode
      damage_code
      damage_category
      damage_codeGroup
      damage_shortText
      damage_shortTextCode
      consequence_description
      consequence_displayColor
      consequence_matrixValue
      likelihood_description
      likelihood_matrixValue
      severity_matrixValue
      severity_description
      priority_code
      priority_description
      shutdown_code
      shutdown_description
      jobType_code
      jobType_description
      ressource_code
      ressource_description
  }
`
