import { ItemTask, ItemTaskUncheckedUpdateInput, UpdateOneItemTaskByIdMutation } from '@app/graphql/__types__/graphql'
import BadgeInput from '@app/components/Common/Form/BadgeInput'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FetchResult, useMutation } from '@apollo/client'
import { SCHEDULING_ITEM_TASKS_UPDATE_BY_ID } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useTranslation } from 'react-i18next'
import { useLayoutStore } from '@app/stores/layout'
import { EWORKORDER_REF_EVENT_TASK_CATEGORY } from '@app/utils/enums'
import useItemStore from '@app/stores/item'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { RadBadge, RadContextMenu, RadContextMenuContent, RadContextMenuItem, RadContextMenuTrigger, RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuTimer, LuTrash2, LuUsers } from 'react-icons/lu'
import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu'
import ItemCard, { ItemCardContent } from '@app/components/Common/Block/ItemCard'
import DragHandle from '@app/components/Common/Block/ItemCard/DragHandle'

type TTaskItem = Readonly<{
  category?: EWORKORDER_REF_EVENT_TASK_CATEGORY
  itemTask: Partial<ItemTask>
  hasActionButtons?: boolean
  onDeleteClick?: (task: Partial<ItemTask>) => void
  isDeleteButtonDisabled?: boolean
  hasDragBtn?: boolean
}>

export default function TaskItem({ itemTask, hasDragBtn, onDeleteClick }: TTaskItem) {
  const { t } = useTranslation()
  const [editTask, setEditTask] = useState<Partial<ItemTask>>()
  const { updateItemTask, newTaskData, setNewTaskData, fetchItemTasks } = useItemStore()
  const [updateItemTaskApi] = useMutation<UpdateOneItemTaskByIdMutation>(SCHEDULING_ITEM_TASKS_UPDATE_BY_ID)
  const { startLoading, stopLoading } = useLayoutStore()
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: itemTask?.id ?? '' })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  const handleDeleteTaskClick = () => {
    onDeleteClick?.(itemTask)
  }

  const handleDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseFloat(e.target.value)
    setEditTask({
      ...editTask,
      duration: !isNaN(newValue) ? newValue : undefined,
    })
  }

  const handleNbWorkersChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(e.target.value, 10)
    setEditTask({
      ...editTask,
      nbWorkers: !isNaN(newValue) ? newValue : undefined,
    })
  }

  const updateItemTaskHandler = (data: ItemTaskUncheckedUpdateInput) => {
    if (itemTask.id) {
      startLoading()
      updateItemTaskApi({
        variables: {
          id: itemTask.id,
          data,
        },
      }).then((fetchResult: FetchResult<UpdateOneItemTaskByIdMutation>) => {
        if (fetchResult.data?.updateOneItemTask) {
          updateItemTask(fetchResult.data.updateOneItemTask as Partial<ItemTask>)
          fetchItemTasks?.()
          AppNotifications.success(t(`message.success.itemTask${itemTask.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}Updated`))
        } else {
          throw new Error('Update failed')
        }
      }).catch((error: Error) => {
        console.log(error)
        AppNotifications.error(t('message.error.default.title'))
      }).finally(() => {
        stopLoading()
      })
    }
  }

  const handleDurationBlur = () => {
    const newValue = Number.parseFloat(String(editTask?.duration))
    const duration = !Number.isNaN(newValue) ? newValue : itemTask.duration
    setEditTask({
      ...editTask,
      duration,
    })
    if (duration !== itemTask.duration) {
      if (itemTask.id) {
        updateItemTaskHandler({
          duration: {
            set: String(duration),
          },
        })
      } else if (newTaskData?.task?.id && newTaskData?.task?.id === itemTask.task?.id) {
        setNewTaskData({
          ...newTaskData,
          duration,
        })
      } else {
        setNewTaskData({
          ...itemTask,
          duration,
        })
      }

      fetchItemTasks?.()
    }
  }

  const handleNbWorkersBlur = () => {
    const newValue = Number.parseInt(String(editTask?.nbWorkers), 10)
    const nbWorkers = !Number.isNaN(newValue) ? newValue : itemTask.nbWorkers
    setEditTask({
      ...editTask,
      nbWorkers,
    })
    if (nbWorkers !== itemTask.nbWorkers) {
      if (itemTask.id) {
        updateItemTaskHandler({
          nbWorkers: {
            set: nbWorkers,
          },
        })
      } else if (newTaskData?.task?.id && newTaskData?.task?.id === itemTask.task?.id) {
        setNewTaskData({
          ...newTaskData,
          nbWorkers,
        })
      } else {
        setNewTaskData({
          ...itemTask,
          nbWorkers,
        })
      }
    }
  }

  useEffect(() => {
    setEditTask(itemTask)
  }, [])

  const actionsMenu = [
    {
      key: 'delete',
      className: 'gap-2 item-destructive',
      onClick: handleDeleteTaskClick,
      children: <>
        <LuTrash2 />
        {' '}
        {t('label.delete')}
      </>,
    },
  ]

  return (
    <div ref={setNodeRef} style={style} className="flex flex-col items-stretch w-full h-full relative text-xs">
      <RadContextMenu>
        <RadContextMenuTrigger>

          <ItemCard>

            {!!hasDragBtn && <DragHandle attributes={attributes} listeners={listeners} />}

            <ItemCardContent className="flex items-center">
              <RadBadge className={`py-1.5 w-[69.5px] justify-center font-semibold text-sm ${itemTask.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'bg-yellow-200 text-black' : ''}`}>
                {itemTask.task?.task}
              </RadBadge>
              <div className="flex flex-col h-full justify-start items-start flex-grow ml-4">
                <div className="line-clamp-1 font-semibold">{itemTask.task?.description}</div>
                <div className="line-clamp-3 flex-grow">{itemTask.task?.longDescription}</div>
              </div>
              {!(itemTask.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY && !itemTask.task?.hasReporting) && (
                <div className="flex flex-row items-start gap-1 mr-2">
                  <div className="flex items-center justify-center gap-1 mr-2">
                    <LuTimer size={18} className="text-gray-600 mr-1" />
                    <BadgeInput
                      inputProps={{
                        type: 'number',
                        value: editTask?.duration ?? '',
                        onChange: handleDurationChange,
                        onBlur: handleDurationBlur,
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-center gap-1">
                    <LuUsers size={18} className="text-gray-600" />
                    <BadgeInput
                      inputProps={{
                        type: 'number',
                        value: editTask?.nbWorkers ?? '',
                        onChange: handleNbWorkersChange,
                        onBlur: handleNbWorkersBlur,
                      }}
                    />
                  </div>
                </div>
              )}
              <div onContextMenu={e => e.preventDefault()}>
                <ActionThreeDotsMenu>{actionsMenu.map(action => <RadDropdownMenuItem {...action} key={action.key} />)}</ActionThreeDotsMenu>
              </div>
            </ItemCardContent>
          </ItemCard>

        </RadContextMenuTrigger>
        <RadContextMenuContent>
          {actionsMenu.map(action => <RadContextMenuItem {...action} key={action.key} />)}
        </RadContextMenuContent>
      </RadContextMenu>

    </div>
  )
}
