/* eslint-disable react/prop-types */
import { IColumn, TDataRowTreeList, TTreeList } from '@holis/react-ui'
import { RadCheckbox, RadTable, RadTableCell, RadTableHead, RadTableRow } from '@holis/react-ui/rad'
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getSortedRowModel, SortingState, useReactTable, Table, Row, flexRender, ColumnFiltersState, SortDirection, RowSelectionState } from '@tanstack/react-table'
import React, { forwardRef, HTMLAttributes, useEffect, useId, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import _ from 'lodash'
import { cn } from '@holis/react-ui/utils'
import { TableVirtuoso } from 'react-virtuoso'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import { t } from 'i18next'
import FormGroupHeader from '../Form/FormGroupHeader'
import SearchBar from '../SearchBar'
import { TDbId, TObjId } from '@app/types/app'
import { getObjValueByPath, searchArray } from '@app/utils/functions'
import { FORMAT_DATE_EU } from '@app/utils/constants'
import moment from 'moment'
import NoResult from '../Text/NoResult'

const TableComponent = forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>

>(({ className, ...props }, ref) => (
  <div className="pr-2">
    <RadTable
      ref={ref}
      className={cn('w-full caption-bottom text-sm', className)}
      {...props}
    />
  </div>
))
TableComponent.displayName = 'TableComponent'

const getTdClassName = (colIndex: number, rowsCount: number) => {
  let cn = 'border-y my-0.5 h-full flex flex-col justify-center align-start p-0.5'
  if (colIndex === 0) {
    cn += ' border-l rounded-l-md'
  }

  if (colIndex === rowsCount - 1) {
    cn += ' border-r rounded-r-md'
  }

  return cn
}

const TableRowComponent = <TData, >(rows: Row<TData>[], rowClick?: (row: Row<TData>) => void, rowClass?: string, options?: TDataTable<TObjId>) =>
  function Row(props: HTMLAttributes<HTMLTableRowElement>) {
    // @ts-expect-error data-index is a valid attribute
    const index = props['data-index']
    const row = rows[index]

    if (!row) {
      return null
    }

    const visibleCells = row.getVisibleCells()

    return (
      <RadTableRow
        key={row.id}
        className={cn('border-0 hover:bg-inherit', rowClass)}
        data-state={(row.getIsSelected() || (options?.disabledIds?.includes(row.id.toString()) && options?.selectedIds?.includes(row.id.toString()))) && 'selected'}
        onClick={(e) => {
          if (options?.disabledIds?.includes(row.id.toString())) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
        {...props}
      >
        {visibleCells.map((cell, colIndex) => (
          <RadTableCell key={cell.id} className="border-0 p-0 h-11 py-0.5">
            <div className={'inner-td-div ' + getTdClassName(colIndex, visibleCells.length)} onClick={() => rowClick?.(row)}>
              {flexRender(
                cell.column.columnDef.cell,
                cell.getContext(),
              )}
            </div>
          </RadTableCell>
        ))}
      </RadTableRow>
    )
  }

function SortingIndicator({ isSorted }: { readonly isSorted: SortDirection | false }) {
  if (!isSorted) {
    return null
  }

  return (
    <div className="ml-1">
      {
        {
          asc: '↑',
          desc: '↓',
        }[isSorted]
      }
    </div>
  )
}

type TDataTable<TItem extends TObjId> = Readonly<{
  hasSearchBar?: boolean
  searchFilter?: (data: TDataRowTreeList[], searchText: string) => TDataRowTreeList[]
  title?: React.ReactNode | ((data: TDataRowTreeList[]) => React.ReactNode)
  singleRowSelection?: boolean
  leftSearchBarComponent?: ReactNode
  rightSearchBarComponent?: ReactNode
  stickyHeaders?: boolean
  searchBarClassName?: string
  headerClassName?: string
  hiddenIds?: TDbId[]
  disabledIds?: TDbId[]
  selectedIds?: TDbId[]
  onRowSelectionChanged?: (rowData: TItem[]) => void
  getRowId?: ((originalRow: TDataRowTreeList, index: number, parent?: Row<TDataRowTreeList> | undefined) => string) | undefined
  listProps: {
    minimumColumnWidth?: number
    filterTimeout?: number
    isLoading?: boolean
    error?: ReactNode
    className?: string
  } & Omit<TTreeList, 'minimumColumnWidth' | 'filterTimeout'>
}> & Omit<HTMLAttributes<HTMLDivElement>, 'title'>

type THeaderProps = Readonly<{ table: Table<TDataRowTreeList> }>
type TCellProps = Readonly<{ row: Row<TDataRowTreeList> }>

export default function DataTable<TItem extends TObjId>(props: TDataTable<TItem>) {
  const {
    listProps,
    className,
    title,
    hasSearchBar,
    headerClassName,
    leftSearchBarComponent,
    searchBarClassName,
    rightSearchBarComponent,
    searchFilter,
    singleRowSelection,
    onRowSelectionChanged,
    hiddenIds,
    selectedIds,
    disabledIds,
    getRowId,
  } = props
  const [tableHeight, setTableHeight] = useState<number>(100)
  const headerRef = React.useRef<HTMLDivElement>(null)
  const containerId = useId()
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [searchText, setSearchText] = React.useState<string>('')
  const [filteredData, setFilteredData] = React.useState<TDataRowTreeList[]>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [selectedInit, setSelectedInit] = React.useState<boolean>(false)
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const adjustTableHeight = () => {
    setTableHeight(Math.max(100, (document.getElementById(containerId)?.offsetHeight ?? 0) - (headerRef.current?.offsetHeight ?? 0) - 10))
  }

  useEffect(() => {
    window.addEventListener('resize', adjustTableHeight)
  }, [])

  useEffect(() => {
    adjustTableHeight()
  }, [filteredData])

  const handleRowClick = (row: Row<TDataRowTreeList>) => {
    if (disabledIds?.includes(row.id!)) {
      return
    }

    const isNowChecked = !row.getIsSelected()
    if (singleRowSelection && isNowChecked) {
      table.resetRowSelection(false)
    }

    row.toggleSelected()
  }

  const columns = useMemo<ColumnDef<TDataRowTreeList>[]>(() => [
    ...!onRowSelectionChanged
      ? []
      : [
          {
            id: 'select',
            maxSize: 0,
            header: singleRowSelection
              ? undefined
              : ({ table }: THeaderProps) => (
                  <div className="w-full flex justify-center">
                    <RadCheckbox
                      checked={
                        table.getIsAllPageRowsSelected()
                        || (table.getIsSomePageRowsSelected() && 'indeterminate')
                      }
                      disabled={_.concat(filteredData?.map(item => item.id) ?? [], disabledIds ?? []).length === (disabledIds?.length ?? 0)}
                      aria-label="Select all"
                      className="w-4 h-4 relative -left-[2px]"
                      onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
                    />
                  </div>
                ),
            cell: ({ row }: TCellProps) => (
              <div className="flex justify-center">
                <RadCheckbox
                  disabled={disabledIds?.includes(row.id)}
                  checked={row.getIsSelected()}
                  aria-label="Select row"
                  className="w-4 h-4"
                  onCheckedChange={value => row.toggleSelected(!!value)}
                />
              </div>
            ),
            enableSorting: false,
            enableHiding: false,
          },
        ],
    ...listProps.columns.map(col => ({
      accessorKey: col.field,
      maxSize: col.width,
      minSize: col.width,
      header: col.titleHidden ? '' : () => col.title,
      cell: ({ row }: TCellProps) => (
        (col.cellRenderer && listProps.cellRenderers)
          ? listProps.cellRenderers[col.cellRenderer]?.(_.get(row.original, col.field), row.original)
          : _.get(row.original, col.field)
      ),
    })),
  ], [listProps.columns, listProps.cellRenderers])

  useEffect(() => {
    const newFilteredData = searchText.trim() !== ''
      ? (searchFilter ?? searchArray)?.(listProps.data.map((rowData: TDataRowTreeList) => {
          const rowDataValue = { ...rowData }
          const { columns } = listProps
          columns.forEach((col: IColumn<TDataRowTreeList>) => {
            if (col.type === 'date') {
              const val = getObjValueByPath(rowDataValue, col.field)
              let dateMoment = null
              if (val) {
                dateMoment = moment.parseZone(val)
                if (moment.isMoment(dateMoment) && dateMoment.isValid()) {
                  rowDataValue[`${col.field}-date`] = dateMoment.format(FORMAT_DATE_EU)
                }
              }
            }
          })
          return rowDataValue
        }), searchText)
      : listProps.data
    setFilteredData(
      newFilteredData?.filter(r => !hiddenIds?.includes(r.id)) ?? [])
  }, [searchText, listProps.data])

  useEffect(() => {
    const selectedDisabledKeys = selectedIds?.filter(id => disabledIds?.includes(id)).map(id => String(id))
    if (selectedDisabledKeys?.length) {
      const copiedRowSelection: Record<string, boolean> = { ...rowSelection }
      let rowSelectionChanged = false
      selectedDisabledKeys.forEach((key) => {
        if (!copiedRowSelection[key]) {
          rowSelectionChanged = true
          copiedRowSelection[key] = true
        }
      })
      if (rowSelectionChanged) {
        setRowSelection(copiedRowSelection)
        return
      }
    }

    const selectedRowIds = Object.keys(rowSelection).filter(key => !!rowSelection[key])
    onRowSelectionChanged?.(listProps.data.filter(item => selectedRowIds.includes((item as TItem).id?.toString() ?? '')) as TItem[])
  }, [rowSelection])

  useEffect(() => {
    // Check datatable is loaded completely and selected rows have not been initialized
    if (!listProps.isLoading && !selectedInit) {
      const newRowSelection: Record<string, boolean> = {}
      selectedIds?.forEach((selected) => {
        newRowSelection[String(selected)] = true
      })
      setSelectedInit(true)
      setRowSelection(newRowSelection)
    }
  }, [selectedIds, listProps.data, listProps.isLoading])

  const table = useReactTable({
    data: filteredData,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: onRowSelectionChanged ? setRowSelection : undefined,
    onColumnFiltersChange: setColumnFilters,
    getRowId: getRowId ?? ((originalRow: TDataRowTreeList) => originalRow.id),
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
  })

  const { rows } = table.getRowModel()

  return (
    <div id={containerId} className="h-full w-full flex flex-col gap-1 overflow-hidden">
      {(title || hasSearchBar) && (
        <div ref={headerRef} className={cn('flex justify-between items-center pb-2', headerClassName)}>
          <FormGroupHeader>{typeof title === 'function' ? title(listProps.data) : title}</FormGroupHeader>
          <div className="flex justify-end items-center gap-2">
            {leftSearchBarComponent}
            {hasSearchBar && <SearchBar className={cn('min-w-[300px]', searchBarClassName)} value={searchText} onChange={handleSearch} />}
            {rightSearchBarComponent}
          </div>
        </div>
      )}
      <SpinnerLoaderComponent contentClassName="w-full h-full" className={cn('w-full bg-white/20 flex-grow flex-1', listProps.className)} isLoading={listProps.isLoading} error={listProps.error === true ? t('message.error.default.title') : listProps.error}>
        {!listProps.error
          && (
            <TableVirtuoso
              className={cn(className, `${onRowSelectionChanged ? ' selection-enabled [&_tr]:cursor-pointer [&_tr:hover]:bg-muted' : ''}`)}
              style={{ height: tableHeight }}
              totalCount={rows.length}
              components={{
                Table: TableComponent,
                TableRow: TableRowComponent(rows, handleRowClick, `${typeof listProps.rowClass === 'function' ? listProps.rowClass(listProps.data) : listProps.rowClass}`, props as TDataTable<TDataRowTreeList>),
                EmptyPlaceholder: () => (
                  <tr>
                    <td colSpan={columns!.length} className="text-center text-gray-400 pt-8">
                      { !listProps.isLoading && <NoResult />}
                    </td>
                  </tr>
                ),
              }}
              fixedHeaderContent={() =>
                table.getHeaderGroups().map(headerGroup => (
                  // Change header background color to non-transparent
                  <RadTableRow key={headerGroup.id} className="border-0 hover:bg-white bg-white">
                    {headerGroup.headers.map((header, index: number) => (
                      <RadTableHead
                        key={header.id}
                        colSpan={header.colSpan}
                        className={cn('font-normal text-xs text-gray-600 pb-1 pl-1 text-start align-bottom', listProps.columns[index]?.class ? `${listProps.columns[index].class}-head` : '', typeof listProps.headerClass === 'function' ? listProps.headerClass(listProps.data) : listProps.headerClass)}
                        style={{
                          width: header.getSize(),
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : (
                              <div
                                className="flex items-center"
                                {...{
                                  style: header.column.getCanSort()
                                    ? {
                                        cursor: 'pointer',
                                        userSelect: 'none',
                                      }
                                    : {},
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                                <SortingIndicator
                                  isSorted={header.column.getIsSorted()}
                                />
                              </div>
                            )}
                      </RadTableHead>
                    ))}
                  </RadTableRow>
                ))}
            />
          )}
      </SpinnerLoaderComponent>
    </div>
  )
}

/*

*/
