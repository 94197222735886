import { useLazyQuery } from '@apollo/client'
import AppGrid from '@app/components/Common/AppGrid'
import NotificationNewModal from '@app/components/Common/Block/Notification/NotificationNewModal'
import { GetAllNotificationViewsQuery, Notification, NotificationView } from '@app/graphql/__types__/graphql'
import { NOTIFICATION_VIEWS_GET_ALL } from '@app/graphql/requests'
import { useDataStore } from '@app/stores/data'
import useNotificationStore from '@app/stores/notification'
import { APP_AUTO_NAMING, FORMAT_DATE_EU, OBJ_NEW_ID, ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL } from '@app/utils/constants'
import { ENOTIFICATION_NOTIF_STATUS } from '@app/utils/enums'
import { generatePathWithBaseUrl, tailwindColorToBgFgStyle, textEllipsisCellRenderer, textEllipsisWrapper } from '@app/utils/functions'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'
import { CellRenderer, GridProps } from '@holis/react-ui'
import { RadBadge, RadButton, RadCheckbox } from '@holis/react-ui/rad'
import moment from 'moment'
import { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuPlusCircle } from 'react-icons/lu'
import { MdOpenInNew } from 'react-icons/md'
import { generatePath, useNavigate } from 'react-router-dom'

const GRID_NAME = 'anomalyManagement-notificationsGrid'

export default function AnomalyManagementNotificationPage() {
  const prms = useUserPermissions()
  const { setActiveNotification, activeNotification } = useNotificationStore()
  const [notifications, setNotifications] = useState<Partial<NotificationView>[]>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { selectedSites } = useDataStore()
  const [getNotificationsApi, { loading, error }] = useLazyQuery<GetAllNotificationViewsQuery>(NOTIFICATION_VIEWS_GET_ALL)

  const getNotifications = () => {
    if (selectedSites?.length) {
      getNotificationsApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResults => setNotifications(queryResults.data?.notificationViews))
    } else {
      setNotifications([])
    }
  }

  const openNotifDetail = (notifNumber: string, e: MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(generatePathWithBaseUrl(generatePath(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(':number', notifNumber))), '_blank')
      return
    }

    navigate(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(':number', notifNumber))
  }

  const handleNewNotif = () => {
    setActiveNotification({
      id: OBJ_NEW_ID,
      status: ENOTIFICATION_NOTIF_STATUS.INIT,
      notif: APP_AUTO_NAMING,
      creationDate: new Date(),
      __typename: 'Notification',
    })
  }

  const handleNewNotifCreated = (notification: Partial<Notification>) => {
    navigate(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(':number', notification.notif!))
  }

  useEffect(() => {
    getNotifications()
  }, [selectedSites])

  const actionCellRenderer: CellRenderer<Partial<NotificationView>> = (_, node) => (
    <RadButton
      size="icon"
      variant="outline"
      className="size-fit p-1"
      onClick={e => openNotifDetail(node.data.notif!, e)}
    >
      <MdOpenInNew size={18} />
    </RadButton>
  )

  const gridProps: GridProps<Partial<NotificationView>> = {
    columns: [
      {
        field: undefined,
        title: '',
        defaultState: {
          width: 60,
        },
        cellRenderer: actionCellRenderer,
        filter: false,
        cannotHide: true,
      },
      {
        field: 'schedulingTag_sector_site_site',
        title: t('label.site'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'set',
        defaultState: {
          width: 146,
          sort: 'asc',
        },
        cellRenderer: 'status',
        cannotHide: true,
      },
      {
        field: 'notif',
        title: t('label.notificationNumber'),
        type: 'string',
        defaultState: {
          width: 145,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'externalId',
        title: t('label.externalId'),
        type: 'string',
        defaultState: {
          width: 174,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'type_notifType',
        title: t('label.type'),
        type: 'set',
        defaultState: {
          width: 80,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_floc',
        title: t('label.schedulingTag'),
        type: 'string',
        defaultState: {
          width: 389,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {
          width: 354,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_techClass_techClass',
        title: t('label.technicalClass'),
        type: 'set',
        defaultState: {
          width: 150,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'creationDate',
        title: t('label.creationDate'),
        type: 'date',
        cellRenderer: 'date',
        defaultState: {
          width: 127,
        },
      },
      {
        field: 'reportedBy',
        title: t('label.reportedBy'),
        type: 'string',
        defaultState: {
          width: 129,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'approvedBy',
        title: t('label.approvedBy'),
        type: 'string',
        defaultState: {
          width: 123,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'isTempRepair',
        title: t('label.tempRepair'),
        type: 'boolean',
        cellRenderer: 'boolean',
        defaultState: {
          width: 127,
        },
      },
      {
        field: 'ffsRequired',
        title: t('label.ffsRequired'),
        type: 'boolean',
        defaultState: {
          width: 111,
        },
        cellRenderer: 'boolean',
      },
      {
        field: 'isBelowMawt',
        title: t('label.belowMAWT'),
        type: 'boolean',
        defaultState: {
          width: 98,
        },
        cellRenderer: 'boolean',
      },
      {
        field: 'isLeak',
        title: t('label.leak'),
        type: 'boolean',
        defaultState: {
          width: 80,
        },
        cellRenderer: 'boolean',
      },
      {
        field: 'plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'set',
        defaultState: {
          width: 121,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'set',
        defaultState: {
          width: 165,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'plantWorkCenter_code',
        title: t('label.plantWorkCenter'),
        type: 'set',
        defaultState: {
          width: 150,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'consequence',
        title: t('label.consequence'),
        type: 'set',
        defaultState: {
          width: 125,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'priority_code',
        title: t('label.priority'),
        type: 'set',
        defaultState: {
          width: 80,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'requiredEndDate',
        title: t('label.requiredEndDate'),
        type: 'date',
        cellRenderer: 'date',
        defaultState: {
          width: 164,
        },
      },
      {
        field: 'shutdown_code',
        title: t('label.shutdown'),
        type: 'set',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'jobType_code',
        title: t('label.jobType'),
        type: 'set',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'ressource_code',
        title: t('label.ressource'),
        type: 'set',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'repairSystem_code',
        title: t('label.repairSystem'),
        type: 'set',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'detection_code',
        title: t('label.detection'),
        type: 'set',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'severity',
        title: t('label.severity'),
        type: 'set',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'likelihood',
        title: t('label.likelihood'),
        type: 'set',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'requiredStartDate',
        title: t('label.requiredStartDate'),
        type: 'date',
        cellRenderer: 'date',
        defaultState: {
          width: 80,
          hidden: true,
        },
      },
      {
        field: 'externalStatus',
        title: t('label.externalStatus'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        defaultState: {
          width: 150,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_techClass_class_class',
        title: t('label.class'),
        type: 'set',
        defaultState: {
          width: 150,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_classSece',
        title: t('label.classSece'),
        type: 'set',
        defaultState: {
          width: 150,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_sector_sector',
        title: t('label.sector'),
        type: 'set',
        defaultState: {
          width: 100,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
    ],
    cellRenderers: {
      status(val, node) {
        if (!val) {
          return ''
        }

        const notif = node.data as Partial<NotificationView>

        return (
          <RadBadge variant="outline" className="py-1 font-normal" style={tailwindColorToBgFgStyle(notif.status_displayColor)}>
            {notif.status_description ?? '-'}
          </RadBadge>
        )
      },
      date: val => textEllipsisWrapper(val ? moment(val).format(FORMAT_DATE_EU) : ''),
      boolean: val => (
        <>
          <RadCheckbox title={t(`label.${val ? 'yes' : 'no'}`)} checked={!!val} />
          <span className="hidden">{t(`label.${val ? 'yes' : 'no'}`)}</span>
        </>
      ),
    },
    data: notifications ?? [],
  }

  return (
    <div className="h-full">
      {(error && <div>{error.message}</div>) || (
        <AppGrid
          name={GRID_NAME}
          fileNameOnExport={t('label.notifications')}
          title={t('label.notification')}
          isLoading={loading}
          {...gridProps}
          actions={prms.notifications.create && (
            <RadButton
              size="sm"
              className="flex gap-1 text-sm bg-sky-500 text-white hover:bg-sky-600"
              onClick={handleNewNotif}
            >
              <LuPlusCircle />
              {' '}
              {t('label.addNotification')}
            </RadButton>
          )}
        />
      )}
      {!!activeNotification && <NotificationNewModal isOpen onCreated={handleNewNotifCreated} onOpenChange={isOpen => !isOpen && setActiveNotification()} />}
    </div>
  )
}
