import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { useAdminImportExportStore } from '@app/stores/admin/importExport'
import { useTranslation } from 'react-i18next'

type TTableDataImportErrorsModal = IConfirmModal

export default function TableDataImportConfirmModal(props: TTableDataImportErrorsModal) {
  const { t } = useTranslation()
  const { onConfirmDataImportCallback } = useAdminImportExportStore()
  return (
    <WarningConfirmModal
      description={t('message.questions.importTableData')}
      onConfirm={onConfirmDataImportCallback}
      {...props}
    />
  )
}
