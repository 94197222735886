import ConfigService, { OptimusConfig } from '@app/services/config'
import { useEffect, useState } from 'react'

export default function useOptimusConfig() {
  const [config, setConfig] = useState<OptimusConfig>(ConfigService.instance?.config || new OptimusConfig())

  useEffect(() => {
    const setConfigFromService = () => {
      setConfig(ConfigService.instance?.config || new OptimusConfig())
    }

    setConfigFromService()
    ConfigService.instance?.addEventListener('loaded', setConfigFromService)

    return () => {
      ConfigService.instance?.removeEventListener('loaded', setConfigFromService)
    }
  }, [])

  return config
}
