import { gql } from '@apollo/client'

export const SCHEDULING_ITEM_TASKS_COMMON_FIELDS = gql`
  fragment ItemTaskCommonFields on ItemTask {
    id
    itemId
    taskId
    duration
    nbWorkers
  }
`
