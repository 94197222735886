import { gql } from '@apollo/client'

export const EXT_WORKORDERS_COMMON_FIELDS = gql`
  fragment ExtWorkorderCommonFields on ExtWorkorder {
    id
    woNumber
    type
    description
    dueDate
    reportNumber
    schedulingTag
    packId
    externalData
    controlStatus
  }
`

export const EXT_WORKORDERS_CONTROL_RESPONSE_FIELDS = gql`
  fragment ExtWorkorderControlResponseFields on EventControlDataExtResponse {
    id
    controlStatus
    controlMeasPoint {
      isOk
      measuringPointStatuses {
        location
        ptrid
        flocExists
        integrityStatusExists
      }
    }
    controlWorkorder {
      schedulingTag
      type
      dueDate
    }
  }
`

export const EXT_WORKORDERS_IMPORT_RESPONSE_FIELDS = gql`
  fragment ExtWorkorderImportResponseFields on EventImportDataExtResponse {
    id
    eventId
    eventFlocIds
  }
`
