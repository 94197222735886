import { gql } from '@apollo/client'
import { EXT_WORKORDERS_COMMON_FIELDS, EXT_WORKORDERS_CONTROL_RESPONSE_FIELDS, EXT_WORKORDERS_IMPORT_RESPONSE_FIELDS } from '../fragments'

export const EXT_WORKORDERS_GET_ALL = gql`
${EXT_WORKORDERS_COMMON_FIELDS}
query GetAllExtWorkorders($orderBy: [ExtWorkorderOrderByWithRelationInput!]) {
  extWorkorders (orderBy: $orderBy) {
    ...ExtWorkorderCommonFields
  }
}`

export const EXT_WORKORDERS_CONTROL = gql`
${EXT_WORKORDERS_CONTROL_RESPONSE_FIELDS}
mutation ControlExtWorkorders($ids: [Int!]!) {
  controlExtWorkpackData (data: {ids: $ids}) {
    ...ExtWorkorderControlResponseFields
  }
}`

export const EXT_WORKORDERS_IMPORT = gql`
${EXT_WORKORDERS_IMPORT_RESPONSE_FIELDS}
mutation ImportExtWorkorders($ids: [Int!]!) {
  importExtWorkpackData (data: {ids: $ids}) {
    ...ExtWorkorderImportResponseFields
  }
}`
