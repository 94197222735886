import { useTranslation } from 'react-i18next'
import AppNotifications from '@app/services/notification'
import React from 'react'
import { useMutation } from '@apollo/client'
import { CreateDamageMutation, Damage, UpdateDamageByIdMutation } from '@app/graphql/__types__/graphql'
import { OBJ_NEW_ID } from '@app/utils/constants'
import ActionButtons from '@app/components/Common/Form/ActionButtons'
import { EAdditionalAction, EApiOperator } from '@app/utils/enums'
import { TAdditionalActions } from '@app/types/app'
import useDamageStore from '@app/stores/damage'
import { DAMAGES_CREATE, DAMAGES_UPDATE_BY_ID } from '@app/graphql/requests'
import { LuTrash2 } from 'react-icons/lu'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'

type TActions = Readonly<{
  damage: Partial<Damage>
  disableDelete?: boolean
  onCreatedOrUpdated?: (damage: Partial<Damage>, operator?: EApiOperator) => void
}>

export default function Actions({ damage, onCreatedOrUpdated, disableDelete }: TActions) {
  const { t } = useTranslation()
  const { isSaved, hasError, setUpdateDamageData: setUpdateData, changeDeleteDamageModalDisplay, updateDamage, editDamage, updateDamageDataField: updateData, cancelEditData, setActiveDamage } = useDamageStore()
  const [updateDamageById] = useMutation<UpdateDamageByIdMutation>(DAMAGES_UPDATE_BY_ID)
  const [createDamage] = useMutation<CreateDamageMutation>(DAMAGES_CREATE)

  const handleCancelEditDataClick = () => {
    cancelEditData()
  }

  const submitChange = () => {
    const damageId = damage.id
    if (damageId === OBJ_NEW_ID) {
      createDamage({
        variables: {
          data: updateData,
        },
      }).then((newData) => {
        setActiveDamage()
        onCreatedOrUpdated?.(newData.data?.createOneDamage as Partial<Damage>, EApiOperator.CREATE)
        AppNotifications.success(t('message.success.damageCreated'))
      }).catch(() => {
        const errorMessage: string = t('message.error.default.title')

        AppNotifications.error(errorMessage)
      })
    } else {
      updateDamageById({ variables: { id: damage.id, data: updateData } }).then((newData) => {
        const newDamage = {
          ...editDamage,
          ...newData?.data?.updateOneDamage,
        } as Damage
        updateDamage(newDamage)
        setUpdateData({})
        onCreatedOrUpdated?.(newDamage, EApiOperator.UPDATE)
        AppNotifications.success(t('message.success.damageUpdated'))
      }).catch(() => {
        const errorMessage: string = t('message.error.default.title')

        AppNotifications.error(errorMessage)
      })
    }
  }

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DELETE]: (
      <RadDropdownMenuItem disabled={disableDelete} className="item-destructive gap-1 cursor-pointer" onClick={() => changeDeleteDamageModalDisplay(true)}>
        <LuTrash2 />
        {' '}
        {t('label.deleteDamage')}
      </RadDropdownMenuItem>
    ),
  }

  return (
    <ActionButtons
      isSaved={isSaved}
      dropdownClassName="action-dropdown"
      hasError={hasError}
      handleCancelEditDataClick={handleCancelEditDataClick}
      additionalActions={damage.id !== OBJ_NEW_ID ? additionalActions : undefined}
      submitChange={submitChange}
    />
  )
}
