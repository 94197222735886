import React from 'react'
import { useTranslation } from 'react-i18next'
import { cn } from '@holis/react-ui/utils'

type TNoResult = Readonly<{
  className?: string
  title?: React.ReactNode
}>

export default function NoResult({ title, className }: TNoResult) {
  const { t } = useTranslation()
  return (
    <div className={cn('text-center w-full min-h-[50px] flex flex-col items-center justify-center', className)}>{title ?? t('label.noResult')}</div>
  )
}
