import { getMobileConfQrCode } from '@app/utils/clientConfig'
import SingleContentModal from '../SingleFormModal'
import { useEffect, useState } from 'react'
import AppNotifications from '@app/services/notification'
import { LuXCircle } from 'react-icons/lu'

type MobileConfigQrCodeModalProps = {
  readonly isOpen?: boolean
  readonly onClose?: () => void
}
export default function MobileConfigQrCodeModal({ isOpen, onClose }: MobileConfigQrCodeModalProps) {
  const [qrCode, setQrCode] = useState<string>()
  const [error, setError] = useState<boolean>()

  useEffect(() => {
    getMobileConfQrCode()
      .then(setQrCode)
      .catch((err) => {
        AppNotifications.error(err.message)
        setError(true)
      })
  }, [])

  return (
    <SingleContentModal
      isOpen={isOpen}
      containerClassName="min-w-0"
      onOpenChange={(val) => {
        if (!val) {
          onClose?.()
        }
      }}
    >
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-96 h-96 flex flex-col items-center justify-center">
          {error && (
            <div className="text-red-500 flex flex-col items-center justify-center gap-2">
              <LuXCircle size={24} />
              Failed to generate QR code
            </div>
          )}
          {qrCode && <img src={qrCode} alt="Mobile configuration QR code" />}
        </div>
      </div>
    </SingleContentModal>
  )
}
