import { useMutation } from '@apollo/client'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning'
import { Attachment, DeleteAttachmentByIdMutation } from '@app/graphql/__types__/graphql'
import { FILE_ATTACHMENTS_DELETE_BY_ID } from '@app/graphql/requests/attachments'
import AppNotifications from '@app/services/notification'
import useAttachmentStore from '@app/stores/attachment'
import { useEventStore } from '@app/stores/event'
import { useLayoutStore } from '@app/stores/layout'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
type TDeleteAttachmentModal = IConfirmModal & Readonly<{
  attachment: Partial<Attachment>
  onDeleted?: () => void
}>

export default function DeleteAttachmentModal({ attachment, onDeleted, ...restProps }: TDeleteAttachmentModal) {
  const { setItemToDelete, fetchAttachments } = useAttachmentStore()
  const { fetchEventAttachments } = useEventStore()
  const { startLoading, stopLoading } = useLayoutStore()
  const [deleteAttachmentApi, { data, loading, error }] = useMutation<DeleteAttachmentByIdMutation>(FILE_ATTACHMENTS_DELETE_BY_ID)
  const { t } = useTranslation()
  useEffect(() => {

  }, [data, loading, error])
  const onConfirm = () => {
    startLoading()
    deleteAttachmentApi({
      variables: { id: attachment!.id },
    }).then(() => {
      setItemToDelete()
      fetchAttachments?.()
      fetchEventAttachments?.()
      onDeleted?.()
      AppNotifications.success(t('message.success.deleteAttachment'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteAttachment'))
    }).finally(() => {
      stopLoading()
    })
  }

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteAttachment')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => setItemToDelete()}
      {...restProps}
    />
  )
}
