import {
  Cml,
  Damage,
  Document,
  Event,
  EventCml,
  EventDamage,
  FlocView,
  FunctionalLocation,
  FunctionalLocationUncheckedCreateInput,
  FunctionalLocationUncheckedUpdateInput,
  InspectionDrawing,
  InspectionPoint,
  IntegrityStatus,
  Notification,
  Picture,
} from '@app/graphql/__types__/graphql'
import { TObjectDrawingViewerAction, TObjectDrawingViewerState } from '@app/types/app'
import { OBJ_NEW_ID } from '@app/utils/constants'
import { EDamageFilter, EFlocRightSideTab } from '@app/utils/enums'
import { generateCommonObjectDrawingStore } from '@app/utils/functions/stores'
import { z } from 'zod'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const MAX_LENGTH_VALIDATORS = {
  FLOC: 45,
  DESCRIPTION: 50,
  REFERENCE_DOCUMENT: 45,
}

export const ZOD_FLOC_DATAS = () => ({
  floc: z.string().max(MAX_LENGTH_VALIDATORS.FLOC),
  description: z.string().max(MAX_LENGTH_VALIDATORS.DESCRIPTION),
  sectorId: z.number().optional(),
  techClassId: z.number().optional(),
})

type State = TObjectDrawingViewerState & {
  creatingFloc: boolean
  documentSelectionModalDisplayed: boolean
  fieldErrors: Record<string, boolean>
  cmlInactiveShown: boolean
  damageInactiveShown: boolean
  documentInactiveShown: boolean

  activeFloc?: Partial<FunctionalLocation> | null
  editFloc?: Partial<FunctionalLocation> | null
  flocs?: Partial<FunctionalLocation>[]
  flocViews?: Partial<FlocView>[]
  updateData?: FunctionalLocationUncheckedUpdateInput | FunctionalLocationUncheckedCreateInput
  cmls?: Partial<Cml>[]
  damages?: Partial<Damage>[]
  inspectionPoints?: Partial<InspectionPoint>[]
  flocInspectionDrawings?: Partial<InspectionDrawing>[]
  documents?: Partial<Document>[]
  pictures?: Partial<Picture>[]
  fetchFlocs?: () => void
  fetchCmls?: () => void
  fetchDocuments?: () => void
  fetchDamages?: () => void
  fetchInspectionPoints?: () => void
  fetchFlocInspectionDrawings?: () => void
  fetchPictures?: () => void
  events?: Partial<Event>[]
  integrityStatus?: Partial<IntegrityStatus>
  notifications?: Partial<Notification>[]
  latestEventCmls?: Record<number, Partial<EventCml> | null>
  latestEventDamages?: Record<number, Partial<EventDamage> | null>
}

const initialState: State = {
  selectedDrawing: undefined,
  documentSelectionModalDisplayed: false,
  damageSearchInput: '',
  damageActiveFilter: [EDamageFilter.ACTIVE],
  damageSelectionModalDisplayed: false,

  creatingFloc: false,
  fieldErrors: {},
  cmlInactiveShown: false,
  damageInactiveShown: false,
  documentInactiveShown: false,

  activeFloc: null,
  editFloc: null,
  cmls: undefined,
  damages: undefined,
  inspectionPoints: undefined,
  fetchInspectionPoints: undefined,
  fetchDamages: undefined,
  flocInspectionDrawings: undefined,
  documents: undefined,
  pictures: undefined,
  fetchFlocs: undefined,
  fetchCmls: undefined,
  fetchDocuments: undefined,
  fetchFlocInspectionDrawings: undefined,
  fetchPictures: undefined,
  rightSideTabSelectedValue: EFlocRightSideTab.DRAWINGS,
  events: undefined,
  integrityStatus: undefined,
}

type Actions = TObjectDrawingViewerAction & {
  setFlocs: (flocs?: Partial<FunctionalLocation>[]) => void
  setFlocViews: (flocViews?: Partial<FlocView>[]) => void
  setFetchFlocs: (fetchFlocs?: () => void) => void
  setActiveFloc: (activeFloc?: Partial<FunctionalLocation> | null, isNew?: boolean) => void
  setEditFloc: (idwg: Partial<FunctionalLocation> | null | undefined) => void
  updateFloc: (floc: Partial<FunctionalLocation>) => void
  setCmls: (cmls?: Partial<Cml>[]) => void
  setFetchCmls: (fetchCmls?: () => void) => void
  setFetchDamages: (fetchDamages?: () => void) => void
  setFetchInspectionPoints: (fetchInspectionPoints?: () => void) => void
  changeCmlInactiveShown: (cmlInactiveShown: boolean) => void
  setDamages: (damages?: Partial<Damage>[]) => void
  changeDamageInactiveShown: (damageInactiveShown: boolean) => void
  setInspectionPoints: (inspectionPoints?: Partial<InspectionPoint>[]) => void
  setFlocInspectionDrawings: (flocInspectionDrawings: Partial<InspectionDrawing>[]) => void
  setFetchFlocInspectionDrawings: (fetchFlocInspectionDrawings?: () => void) => void
  setDocuments: (documents?: Partial<Document>[]) => void
  changeDocumentInactiveShown: (documentInactiveShown: boolean) => void
  setPictures: (pictures?: Partial<Picture>[]) => void
  setFetchPictures: (fetchPictures?: () => void) => void
  setUpdateData: (updateData?: FunctionalLocationUncheckedUpdateInput | FunctionalLocationUncheckedCreateInput) => void
  updateDataField: (field: string, value: unknown) => void
  setFieldErrors: (fieldErrors?: Record<string, boolean>) => void
  hasFieldError: (field: string, forceCheck?: boolean) => boolean
  updateFieldError: (field: string, value: boolean) => void
  resetData: () => void
  setEvents: (events?: Partial<Event>[]) => void
  changeDocumentSelectionModalDisplay: (documentSelectionModalDisplayed: boolean) => void
  setFetchDocuments: (fetchDocuments?: () => void) => void
  setIntegrityStatus: (integrityStatus?: Partial<IntegrityStatus>) => void
  setNotifications: (notifications?: Partial<Notification>[]) => void
  setLatestEventCmls: (latestEventCmls?: Record<number, Partial<EventCml> | null>) => void
  setLatestEventDamages: (latestEventDamages?: Record<number, Partial<EventDamage> | null>) => void
}

const getFlocUpdateState = (state: Partial<State>, flocChanges: Partial<FunctionalLocation>): Partial<State> => {
  const newActiveFloc: Partial<FunctionalLocation> = {
    ...state.activeFloc,
    ...flocChanges,
  }
  const flocs: Partial<FunctionalLocation>[] = [
    ...(state.flocs ?? []),
  ]
  const newState: Partial<State> = {}
  if (flocs) {
    const index = flocs.findIndex((item: Partial<FunctionalLocation>) => item.id === newActiveFloc.id)
    if (index > -1) {
      flocs[index] = { ...flocs[index], ...newActiveFloc } as FunctionalLocation
      newState.flocs = flocs
    }
  }

  newState.activeFloc = newActiveFloc
  newState.editFloc = {
    ...newState.activeFloc,
  }

  return newState
}

export type FlocState = State & Actions

export const useFlocStore = create<FlocState>()(
  immer((set, get) => ({
    ...initialState,
    ...generateCommonObjectDrawingStore(set),
    setIntegrityStatus(integrityStatus) {
      set({ integrityStatus })
    },
    setFetchDocuments(fetchDocuments) {
      set({ fetchDocuments })
    },
    changeDocumentSelectionModalDisplay(documentSelectionModalDisplayed) {
      set({ documentSelectionModalDisplayed })
    },
    setEvents(events) {
      set({ events })
    },
    setFetchDamages(fetchDamages) {
      set({ fetchDamages })
    },
    setFetchInspectionPoints(fetchInspectionPoints) {
      set({ fetchInspectionPoints })
    },
    prevSelectedDrawing() {
      const { selectedDrawing, flocInspectionDrawings } = get() ?? {}

      if (selectedDrawing && flocInspectionDrawings?.length) {
        const currentIndex = flocInspectionDrawings.findIndex((item: Partial<InspectionDrawing>) => item.id === selectedDrawing.id)

        if (currentIndex >= 0) {
          if (currentIndex !== 0) {
            set({ selectedDrawing: flocInspectionDrawings[currentIndex - 1] })
          } else {
            set({ selectedDrawing: flocInspectionDrawings[flocInspectionDrawings.length - 1] })
          }
        }
      }
    },
    nextSelectedDrawing() {
      const { selectedDrawing, flocInspectionDrawings } = get() ?? {}
      if (selectedDrawing && flocInspectionDrawings?.length) {
        const currentIndex = flocInspectionDrawings.findIndex((item: Partial<InspectionDrawing>) => item.id === selectedDrawing.id)

        if (currentIndex >= 0) {
          if (currentIndex === flocInspectionDrawings.length - 1) {
            set({ selectedDrawing: flocInspectionDrawings[0] })
          } else {
            set({ selectedDrawing: flocInspectionDrawings[currentIndex + 1] })
          }
        }
      }
    },
    setFlocs(flocs) {
      set({ flocs })
    },
    setFlocViews(flocViews) {
      set({ flocViews })
    },
    setFetchFlocs(fetchFlocs) {
      set({ fetchFlocs })
    },
    setActiveFloc: (floc?: Partial<FunctionalLocation> | null, isNew?: boolean) => set((state) => {
      state.activeFloc = floc
      state.editFloc = floc ? { ...floc } : undefined
      state.creatingFloc = (floc && isNew) === true
      state.updateData = undefined
    }),
    setEditFloc(editFloc) {
      set({ editFloc })
    },
    updateFloc: (floc: Partial<FunctionalLocation>) => set(getFlocUpdateState(get(), floc)),
    setCmls(cmls) {
      set({ cmls })
    },
    setFetchCmls(fetchCmls) {
      set({ fetchCmls })
    },
    changeCmlInactiveShown(cmlInactiveShown) {
      set({ cmlInactiveShown })
    },
    setDamages(damages) {
      set({ damages })
    },
    changeDamageInactiveShown(damageInactiveShown) {
      set({ damageInactiveShown })
    },
    setInspectionPoints(inspectionPoints) {
      set({ inspectionPoints })
    },
    setFlocInspectionDrawings(flocInspectionDrawings) {
      set({ flocInspectionDrawings })
    },
    setFetchFlocInspectionDrawings(fetchFlocInspectionDrawings) {
      set({ fetchFlocInspectionDrawings })
    },
    setDocuments(documents) {
      documents?.sort((a, b) => {
        // Sort on document name
        if (a.document && b.document) {
          return a.document.localeCompare(b.document)
        }

        return 0
      })
      set({ documents })
    },
    changeDocumentInactiveShown(documentInactiveShown) {
      set({ documentInactiveShown })
    },
    setPictures(pictures) {
      set({ pictures })
    },
    setFetchPictures(fetchPictures) {
      set({ fetchPictures })
    },
    setUpdateData(updateData?: FunctionalLocationUncheckedUpdateInput | FunctionalLocationUncheckedCreateInput) {
      set({ updateData })
    },
    setFieldErrors(fieldErrors?: Record<string, boolean>) {
      set({
        fieldErrors: fieldErrors ?? {},
      })
    },
    updateDataField: (field: string, value: unknown) => set(state => ({
      updateData: {
        ...state.updateData,
        [field]: state.activeFloc?.id === OBJ_NEW_ID
          ? value
          : {
              set: value,
            },
      },
      fieldErrors: {
        ...state.fieldErrors,
        [field]: false,
      },
    })),
    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get()
      if (!Object.keys(state.updateData ?? {}).includes(field) && !forceCheck) {
        return false
      }

      if (state.fieldErrors?.[field] === true) {
        return true
      }

      switch (field) {
        case 'floc':
        { const floc = (state.editFloc?.floc ?? '').trim()
          return floc === '' || floc.length > MAX_LENGTH_VALIDATORS.FLOC }
        case 'description':
        { const description = (state.editFloc?.description ?? '').trim()
          return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '' }
        case 'sectorId':
          return !state.editFloc?.sectorId
        default:
          break
      }

      return false
    },
    updateFieldError: (field: string, value: boolean) => set(state => ({
      fieldErrors: {
        ...state.fieldErrors,
        [field]: value,
      },
    })),
    resetData() {
      set({
        ...initialState,
      })
    },
    setNotifications(notifications) {
      set({ notifications })
    },
    setLatestEventCmls(latestEventCmls) {
      set({ latestEventCmls })
    },
    setLatestEventDamages(latestEventDamages) {
      set({ latestEventDamages })
    },
  })),
)
