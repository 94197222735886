import { useTranslation } from 'react-i18next'
import { renderCodeAndDescription } from '@app/utils/functions'
import React, { useEffect, useState } from 'react'
import { TFieldsBlock, TMaybeCodeDescriptionDatas } from '@app/types/app'
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock'
import { Notification, RefNotifConsequence, RefNotifSeverity, GetAllRefNotifConsequencesQuery, GetAllRefNotifLikelihoodsQuery, GetAllRefNotifPrioritiesQuery, GetAllRefNotifSeveritiesQuery, RefNotifLikelihood, GetOneParameterByCodeQuery, Parameter } from '@app/graphql/__types__/graphql'
import { QueryResult, useQuery } from '@apollo/client'
import { APP_PARAMTERS_GET_ONE_BY_CODE, NOTIFICATION_REF_CONSEQUENCES_GET_MANY, NOTIFICATION_REF_LIKELIHOODS_GET_MANY, NOTIFICATION_REF_PRIORITIES_GET_MANY, NOTIFICATION_REF_SEVERITIES_GET_MANY } from '@app/graphql/requests'
import useNotificationStore from '@app/stores/notification'
import { EFieldType } from '@app/utils/enums'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import { LuClipboardList } from 'react-icons/lu'
import { APP_PARAMETER_AUTO_CALCULATION_IAN_PRIORITY_REQUIRED_DATE } from '@app/utils/constants'

type TPriorityCompliance = Readonly<{
  notification: Partial<Notification>
  readonly?: boolean
}>

export default function PriorityCompliance({ notification, readonly }: TPriorityCompliance) {
  const { t } = useTranslation()
  const { editNotification, handleFieldChange, renderAutocomplete, setSubmitRequested } = useNotificationStore()
  const [priorityAndRequiredDateDisabled, setPriorityAndRequiredDateDisabled] = useState<boolean>(false)
  const refNotifConsequencesResult = useQuery<GetAllRefNotifConsequencesQuery>(NOTIFICATION_REF_CONSEQUENCES_GET_MANY)
  const refNotifLikelihoodsResult = useQuery<GetAllRefNotifLikelihoodsQuery>(NOTIFICATION_REF_LIKELIHOODS_GET_MANY)
  const refNotifPrioritiesResult = useQuery<GetAllRefNotifPrioritiesQuery>(NOTIFICATION_REF_PRIORITIES_GET_MANY)
  const refNotifSeveritiesResult = useQuery<GetAllRefNotifSeveritiesQuery>(NOTIFICATION_REF_SEVERITIES_GET_MANY)
  const autoCalculationPriorityAndRequiredDateParamResult = useQuery<GetOneParameterByCodeQuery>(APP_PARAMTERS_GET_ONE_BY_CODE, {
    variables: {
      code: APP_PARAMETER_AUTO_CALCULATION_IAN_PRIORITY_REQUIRED_DATE,
    },
  })

  useEffect(() => {
    setPriorityAndRequiredDateDisabled((autoCalculationPriorityAndRequiredDateParamResult.data?.parameter as Parameter)?.value === 'true')
  }, [autoCalculationPriorityAndRequiredDateParamResult])
  const isFormDisabled = false
  const BLOCK_PRIORITY_COMPLIANCE: TFieldsBlock = {
    title: false,
    fields: [
      {
        label: 'label.consequence',
        field: 'consequence',
        itemIdField: 'consequence',
        initialValue: renderCodeAndDescription({ description: notification?.notifConsequence?.description, code: notification?.notifConsequence?.consequence } as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({ description: editNotification?.notifConsequence?.description, code: editNotification?.notifConsequence?.consequence } as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.notifConsequence,
        foreignField: 'notifConsequence',
        dbValue: notification!.consequence,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifConsequencesResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifConsequencesQuery>)?.data?.refNotifConsequences ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({ description: (field as Partial<RefNotifConsequence>)?.description, code: (field as Partial<RefNotifConsequence>)?.consequence } as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.severity',
        field: 'severity',
        itemIdField: 'severity',
        initialValue: renderCodeAndDescription({ description: notification?.notifSeverity?.description, code: notification?.notifSeverity?.severity } as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({ description: editNotification?.notifSeverity?.description, code: editNotification?.notifSeverity?.severity } as TMaybeCodeDescriptionDatas),
        dbValue: notification.severity,
        foreignObject: editNotification?.notifSeverity,
        foreignField: 'notifSeverity',
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifSeveritiesResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifSeveritiesQuery>)?.data?.refNotifSeverities ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({ description: (field as Partial<RefNotifSeverity>)?.description, code: (field as Partial<RefNotifSeverity>)?.severity } as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.likelihood',
        field: 'likelihood',
        itemIdField: 'likelihood',
        initialValue: renderCodeAndDescription({ description: notification?.notifLikelihood?.description, code: notification?.notifLikelihood?.likelihood } as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({ description: editNotification?.notifLikelihood?.description, code: editNotification?.notifLikelihood?.likelihood } as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.notifLikelihood,
        foreignField: 'notifLikelihood',
        dbValue: notification.likelihood,
        fieldType: EFieldType.autocomplete,
        isDisabled: isFormDisabled,
        itemsQueryResult: refNotifLikelihoodsResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifLikelihoodsQuery>)?.data?.refNotifLikelihoods ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({ description: (field as Partial<RefNotifLikelihood>)?.description, code: (field as Partial<RefNotifLikelihood>)?.likelihood } as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.priority',
        field: 'priority',
        initialValue: renderCodeAndDescription(notification?.notifPriority as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification?.notifPriority as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.notifPriority,
        foreignField: 'notifPriority',
        dbValue: notification!.priority,
        isDisabled: isFormDisabled || priorityAndRequiredDateDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifPrioritiesResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifPrioritiesQuery>)?.data?.refNotifPriorities ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.requiredStartDate',
        field: 'requiredStartDate',
        fieldType: EFieldType.date,
        initialValue: notification.requiredStartDate,
        value: editNotification?.requiredStartDate,
        isDisabled: isFormDisabled || priorityAndRequiredDateDisabled,
      },
      {
        label: 'label.requiredEndDate',
        field: 'requiredEndDate',
        fieldType: EFieldType.date,
        initialValue: notification.requiredEndDate,
        value: editNotification?.requiredEndDate,
        isDisabled: isFormDisabled,
      },
    ],
    fieldsClassName: 'w-full mt-2 flex flex-row flex-wrap',
  }
  const fieldBlocks = [BLOCK_PRIORITY_COMPLIANCE]
  return (
    <FormFieldsBlock
      key="priority-compliance"
      isFormContext
      isDisabled={readonly}
      blockTitleClassName="text-primary1"
      className="text-gray-700"
      fieldsBlocks={fieldBlocks}
      prefixNode={(
        <FormGroupHeader>
          <div className="flex items-center font-bold">
            <LuClipboardList size={20} className="mr-2" />
            {' '}
            {t('label.priorityCompliance')}
          </div>
        </FormGroupHeader>
      )}
      onFieldChange={handleFieldChange}
      onFieldBlur={() => setSubmitRequested(true)}
    />
  )
}
