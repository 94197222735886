import { gql } from '@apollo/client'
import { NOTIFICATION_REF_SEVERITY_COMMON_FIELDS } from '../fragments'

export const NOTIFICATION_REF_SEVERITIES_GET_MANY = gql`
${NOTIFICATION_REF_SEVERITY_COMMON_FIELDS}
query GetAllRefNotifSeverities($orderBy: [RefNotifSeverityOrderByWithRelationInput!], $where: RefNotifSeverityWhereInput) {
    refNotifSeverities (orderBy: $orderBy, where: $where) {
      ...RefNotifSeverityCommonFields
    }
}`

export const NOTIFICATION_REF_SEVERITIES_GET_COUNTER = gql`
query GetRefNotifSeveritiesCounter {
  aggregateRefNotifSeverity {
    _count {
      _all
    }
  }
}
`
