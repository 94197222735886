import { DeleteEventCmlMutation, Event, EventCml, EventInspectionDrawing, InspectionDrawing } from '@app/graphql/__types__/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEventStore } from '@app/stores/event'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import SearchBar from '@app/components/Common/SearchBar'
import { RadButton, RadDropdownMenuItem } from '@holis/react-ui/rad'
import { LuPlusSquare, LuList, LuMapPin, LuCornerDownRight, LuHistory } from 'react-icons/lu'
import { searchArray } from '@app/utils/functions/strings'
import AppNotifications from '@app/services/notification'
import { useMutation } from '@apollo/client'
import { WORKORDER_EVENT_CMLS_DELETE } from '@app/graphql/requests'
import { EWorkpackRightSideTab } from '@app/utils/enums'
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig'
import EventCmlListModal from '@app/components/Common/Block/Cml/CmlBlock/EventCmlListModal'
import { NO_DRAWING_ID } from '@app/utils/constants'
import CmlList from '@app/components/Common/Block/Cml/CmlBlock/CmlList'
import InspectionDrawingAccordion from '@app/components/Common/Block/InspectionDrawing/Accordion'
type TCmlBlock = Readonly<{
  event: Partial<Event>
  inspectionDrawings?: Partial<InspectionDrawing>[]
  readonly?: boolean
}>

export default function CmlBlock({ event, readonly }: TCmlBlock) {
  const { setSelectedDrawing, fetchEventCmls, eventCmls, cmlSearchInput, setFilteredEventCmls, eventInspectionDrawings, filteredEventCmls, setCmlSearchInput, setRightSideTabSelectedValue, changeCmlSelectionModalDisplay, selectedDrawing } = useEventStore()
  const [deleteEventCmlApi] = useMutation<DeleteEventCmlMutation>(WORKORDER_EVENT_CMLS_DELETE)
  const { t } = useTranslation()
  const config = useOptimusConfig()
  const [grouped, setGrouped] = useState<boolean>(true)
  const [inspectionCmlListModalDisplayed, changeInspectionCmlListModalDisplay] = useState<boolean>(false)
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>()
  const [cmlDetailsShown, setCmlDetailsShown] = useState<boolean>(false)

  const handleDisplayDrawing = (idwg: Partial<InspectionDrawing>) => {
    setRightSideTabSelectedValue(EWorkpackRightSideTab.DRAWINGS)
    setSelectedDrawing(idwg)
  }

  const handleRemoveCml = (cmlId: number) => {
    deleteEventCmlApi({
      variables: {
        cmlId,
        evtId: event.id,
      },
    }).then(() => {
      fetchEventCmls?.()
      AppNotifications.success(t('message.success.deleteEventCml'))
    }).catch(() => {
      AppNotifications.error(t('message.error.deleteEventCml'))
    })
  }

  useEffect(() => {
    fetchEventCmls?.()
  }, [fetchEventCmls])

  useEffect(() => {
    setFilteredEventCmls(
      searchArray(eventCmls ?? [], cmlSearchInput, undefined, ['__typename', 'event']),
    )
  }, [eventCmls, cmlSearchInput])

  useEffect(() => {
    const idwgs = eventInspectionDrawings?.map((evtDrawing: Partial<EventInspectionDrawing>) => evtDrawing.inspectionDrawing!) ?? []
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      ...idwgs,
    ])
  }, [eventInspectionDrawings])

  const cmlsByIdwg = useMemo(() => (filteredEventCmls?.reduce((acc, ecml) => {
    const idwgId = ecml.cml?.idwgId ?? NO_DRAWING_ID

    if (!acc[idwgId]) {
      acc[idwgId] = []
    }

    acc[idwgId].push(ecml)
    return acc
  }, {} as Record<number, Partial<EventCml>[]>) ?? {}), [filteredEventCmls])

  return (
    <>
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem key="group-ungroup" onClick={() => setGrouped(!grouped)}>
            <LuCornerDownRight className="mr-2" />
            {' '}
            {t('label.groupUngroup')}
          </RadDropdownMenuItem>,
          <RadDropdownMenuItem key="manage-cml" disabled={readonly || config.getActionIsDisabled('event', 'cmlSelect', event.status)} onClick={() => changeCmlSelectionModalDisplay(true)}>
            <LuPlusSquare className="mr-2" />
            {' '}
            {t('label.manageCML')}
          </RadDropdownMenuItem>,
          <RadDropdownMenuItem key="display-list" onClick={() => changeInspectionCmlListModalDisplay(true)}>
            <LuList className="mr-2" />
            {' '}
            {t('label.displayList')}
          </RadDropdownMenuItem>,
        ]}
        actions={(
          <div className="flex gap-4 items-center">
            <RadButton
              className={'gap-2' + (cmlDetailsShown ? ' !bg-secondary !border-gray-300' : '')}
              variant="outline"
              onClick={() => setCmlDetailsShown(!cmlDetailsShown)}
            >
              <LuHistory />
              {t('label.showCmlDetails')}
            </RadButton>
            <SearchBar
              className="w-[300px]"
              onChange={e => setCmlSearchInput(e.target?.value)}
            />
          </div>
        )}
      >
        <div className="flex items-center">
          <LuMapPin size={20} className="mr-2" />
          {' '}
          {t('label.cml')}
        </div>
      </FormGroupHeader>

      <div className="mt-3">

        {
          grouped

            ? (
                <InspectionDrawingAccordion
                  selectedDrawing={selectedDrawing}
                  drawings={drawings}
                  getDrawingElementsCount={idwg => cmlsByIdwg[idwg.id!]?.length ?? 0}
                  renderDrawingElements={idwg => <CmlList hasMenuContext detailsShown={cmlDetailsShown} readonly={readonly} cmls={cmlsByIdwg[idwg.id!]} handleRemoveCml={handleRemoveCml} />}
                  onDisplayDrawingClick={handleDisplayDrawing}
                />
              )

            : <CmlList hasMenuContext detailsShown={cmlDetailsShown} readonly={readonly} cmls={filteredEventCmls} handleRemoveCml={handleRemoveCml} />
        }
      </div>
      {inspectionCmlListModalDisplayed && <EventCmlListModal open hasItems description={`${t('label.event').toUpperCase()} ${event.event}`} sortOrders={['asc']} items={eventCmls} onClose={() => changeInspectionCmlListModalDisplay(false)} />}

    </>
  )
}
