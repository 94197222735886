import { useQuery } from '@apollo/client'
import BorderedTable from '@app/components/Common/TreeList/BorderedTable'
import { IConfirmModal } from '@app/components/Modal/Confirm'
import { GetEventWorkflowsByEvtIdQuery, Event, EventWorkflow } from '@app/graphql/__types__/graphql'
import { WORKORDER_EVENT_WORKFLOWS_GET_BY_EVT_ID } from '@app/graphql/requests'
import { FORMAT_DATETIME } from '@app/utils/constants'
import { IColumn, TDataRowTreeList } from '@holis/react-ui'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useEventStore } from '@app/stores/event'
import SingleContentModal from '@app/components/Modal/SingleFormModal'

type TEventWorkflowTable = Readonly<IConfirmModal & {
  event: Partial<Event>
}>

export default function EventWorkflowTableModal({ event, ...restProps }: TEventWorkflowTable) {
  const { t } = useTranslation()
  const { changeWorkflowModalDisplay } = useEventStore()
  const { data, loading, error } = useQuery<GetEventWorkflowsByEvtIdQuery>(WORKORDER_EVENT_WORKFLOWS_GET_BY_EVT_ID, {
    variables: {
      evtId: event.id,
    },
    fetchPolicy: 'no-cache',
  })

  const cellRenderers = {
    date(_val: string, rowData: unknown) {
      const dataRow = rowData as Partial<EventWorkflow>
      return <span>{dataRow.actionDate ? moment(dataRow.actionDate).format(FORMAT_DATETIME) : ''}</span>
    },
    status(val: string) {
      return <>{val.replace(/\|/g, ' => ')}</>
    },
  }

  const columns: IColumn[] = [
    {
      field: 'actionDate',
      title: t('label.date'),
      type: 'string',
      cellRenderer: 'date',
      width: 100,
      filter: false,
    },
    {
      field: 'status',
      title: t('label.status'),
      type: 'string',
      width: 120,
      filter: false,
      cellRenderer: 'status',
    },
    {
      field: 'description',
      title: t('label.description'),
      type: 'string',
      width: 120,
      filter: false,
    },
    {
      field: 'userLogin',
      title: t('label.user'),
      type: 'string',
      width: 140,
      filter: false,
    },
    {
      field: 'comment',
      title: t('label.commentReason'),
      type: 'string',
      width: 140,
      filter: false,
    },
  ]

  const handleCloseModal = () => {
    changeWorkflowModalDisplay(false)
  }

  return (
    <SingleContentModal
      containerClassName="max-w-[1400px] min-w-[200px] max-h-[90%] h-full"
      isOpen={restProps.open}
      title={`${t('label.workflowForEvent')}: ${event.event}`}
      onOpenChange={(opened) => {
        if (!opened) {
          handleCloseModal()
        }
      }}
    >
      <BorderedTable
        hasSearchBar={false}
        listProps={{
          columns,
          isLoading: loading,
          error: !!error,
          data: (data?.eventWorkflows ?? []) as unknown as TDataRowTreeList[],
          cellRenderers,
        }}
      />
    </SingleContentModal>
  )
}
