import { gql } from '@apollo/client'
import { NOTIFICATION_REF_PRIORITY_COMMON_FIELDS } from '../fragments'

export const NOTIFICATION_REF_PRIORITIES_GET_MANY = gql`
${NOTIFICATION_REF_PRIORITY_COMMON_FIELDS}
query GetAllRefNotifPriorities($orderBy: [RefNotifPriorityOrderByWithRelationInput!], $where: RefNotifPriorityWhereInput) {
    refNotifPriorities (orderBy: $orderBy, where: $where) {
      ...RefNotifPriorityCommonFields
    }
}`

export const NOTIFICATION_REF_PRIORITIES_GET_COUNTER = gql`
query GetRefNotifPrioritiesCounter {
  aggregateRefNotifPriority {
    _count {
      _all
    }
  }
}
`
