import { useLazyQuery } from '@apollo/client'
import CmlCard from '@app/components/Common/Block/Cml/CmlBlock/CmlCard'
import CmlListModal from '@app/components/Common/Block/Cml/CmlBlock/CmlListModal'
import ObjectItemListByDrawing from '@app/components/Common/Block/InspectionDrawing/ObjectItemListByDrawing'
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent'
import { Cml, Damage, EventCml, EventDamage, FunctionalLocation, GetCmlsByFlocIdsQuery, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { CMLS_GET_BY_FLOC_ID } from '@app/graphql/requests/cmls'
import { useFlocStore } from '@app/stores/methodEngineering/floc'
import { NO_DRAWING_ID } from '@app/utils/constants'
import { EAppFlagStatus } from '@app/utils/enums'
import { searchArray } from '@app/utils/functions'
import { RadButton } from '@holis/react-ui/rad'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuHistory, LuMapPin } from 'react-icons/lu'

type TCmlList = Readonly<{
  floc: Partial<FunctionalLocation>
}>

/** Floc Cml list */
export default function CmlList({ floc }: TCmlList) {
  const [filteredCmls, setFilteredCmls] = useState<Partial<Cml>[]>()
  const [filterString, setSearchInput] = useState<string>('')
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>()
  const { t } = useTranslation()
  const [listModalDisplayed, changeListModalDisplay] = useState<boolean>(false)
  const [cmlDetailsShown, setCmlDetailsShown] = useState<boolean>(false)

  const {
    cmls,
    setCmls,
    fetchCmls,
    setFetchCmls,
    flocInspectionDrawings,
    selectedDrawing,
    setSelectedDrawing,
    latestEventCmls,
  } = useFlocStore()
  const [getCmlListApi] = useLazyQuery<GetCmlsByFlocIdsQuery>(CMLS_GET_BY_FLOC_ID)

  useEffect(() => {
    setFetchCmls(getCmls)
  }, [floc.id])

  useEffect(() => {
    fetchCmls?.()
  }, [fetchCmls])

  useEffect(() => {
    if (Array.isArray(cmls)) {
      setFilteredCmls(
        filterString !== ''
          ? searchArray(
              cmls,
              filterString,
              undefined,
              ['__typename'],
            ).filter((cml: Partial<Cml>) => cml.flagStatus === EAppFlagStatus.ACTIVE.toString())
          : cmls,
      )
    }
  }, [cmls, filterString])

  useEffect(() => {
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      ...(flocInspectionDrawings ?? []),
    ])
  }, [flocInspectionDrawings])

  const cmlsByIdwg = useMemo(() => (filteredCmls?.reduce((acc, ecml) => {
    const idwgId = ecml.idwgId ?? NO_DRAWING_ID

    if (!acc[idwgId]) {
      acc[idwgId] = []
    }

    acc[idwgId].push(ecml)
    return acc
  }, {} as Record<number, Partial<Cml>[]>) ?? {}), [filteredCmls])

  const getCmls = () => {
    if (floc.id) {
      getCmlListApi({
        variables: {
          flocId: floc.id,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResults => setCmls(_.sortBy(queryResults.data?.cmls as Cml[] ?? [], [cml => cml.position])))
    } else {
      setCmls([])
    }
  }

  return (
    <>
      <SpinnerLoaderComponent isLoading={typeof latestEventCmls === 'undefined' || !Array.isArray(filteredCmls)}>
        <ObjectItemListByDrawing
          drawings={drawings}
          itemsByIdwg={cmlsByIdwg}
          selectedDrawing={selectedDrawing}
          title={(
            <>
              <LuMapPin size={20} className="mr-2" />
              {' '}
              {t('label.cml')}
            </>
          )}
          actions={(
            <RadButton
              className={'mr-2 gap-2' + (cmlDetailsShown ? ' !bg-secondary !border-gray-300' : '')}
              variant="outline"
              onClick={() => setCmlDetailsShown(!cmlDetailsShown)}
            >
              <LuHistory />
              {t('label.showCmlDetails')}
            </RadButton>
          )}
          items={filteredCmls}
          changeItemListModalDisplay={changeListModalDisplay}
          searchInput={setSearchInput}
          handleDisplayDrawing={setSelectedDrawing}
          objectItem={floc}
          renderItem={(item: Partial<Cml | EventCml | Damage | EventDamage>) => (
            <CmlCard
              key={item.id}
              hasMenuContext
              detailsShown={cmlDetailsShown}
              eventCml={item as Partial<Cml>}
            />
          )}
        />
      </SpinnerLoaderComponent>
      {listModalDisplayed && <CmlListModal open hasItems title={t('label.cmlList')} description={`${t('label.functionalLocation').toUpperCase()} ${floc.floc}`} sortOrders={['asc']} items={cmls} onClose={() => changeListModalDisplay(false)} />}
    </>
  )
}
