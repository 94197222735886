import { gql } from '@apollo/client'
import { FLOC_CHARS_COMMON_FIELDS, FLOC_CHARACTERISTICS_COMMON_FIELDS } from '../fragments'

export const FLOC_CHARS_GET_BY_FLOC_ID = gql`
${FLOC_CHARS_COMMON_FIELDS}
${FLOC_CHARACTERISTICS_COMMON_FIELDS}
query GetFlocCharsByFlocId($flocId: Int!) {
  flocChars(where: {flocId: {equals: $flocId}}) {
    ...FlocCharCommonFields
    characteristic {
      ...RefFlocCharacteristicCommonFields
    }
  }
}
`
export const FLOC_CHARS_UPDATE_BY_ID = gql`
${FLOC_CHARS_COMMON_FIELDS}
${FLOC_CHARACTERISTICS_COMMON_FIELDS}
mutation UpdateFlocCharById($id: Int!, $data: FlocCharUncheckedUpdateInput!) {
  updateOneFlocChar(data: $data, where: {id: $id}) {
    ...FlocCharCommonFields
    characteristic {
      ...RefFlocCharacteristicCommonFields
    }
  }
}
`

export const FLOC_CHARS_GET_COUNTER = gql`
query GetFlocCharsCounter {
  aggregateFlocChar {
    _count {
      id
    }
  }
}
`
