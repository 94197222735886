import SpinnerLoader from '@app/components/Loaders/SpinnerLoader'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, DocumentProps, Page } from 'react-pdf'
import { DocumentCallback, PageCallback, RenderMode, Source } from 'node_modules/react-pdf/dist/cjs/shared/types'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import React from 'react'
import { Button } from '@holis/react-ui'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import LayoutViewer, { TLayoutViewer, TZoomProps } from '..'
import useResizeObserver from '@app/utils/hooks/useResizeObserver'
import { useHolisAuth } from '@holis/auth-client-react'
import { addTokenToUrl } from '@app/utils/functions'

const defaultOptions: DocumentProps['options'] = {
  standardFontDataUrl: '/standard_fonts/',
  stopAtErrors: false,
  isOffscreenCanvasSupported: false,
}

export type TPDFFile = string | ArrayBuffer | Blob | Source | null

type TPDFViewer = Readonly<{
  file?: TPDFFile
  options?: Record<string, unknown>
  pdfRef?: React.RefObject<HTMLDivElement>
  pageRef?: React.RefObject<HTMLDivElement>
  canvasRef?: React.RefObject<HTMLCanvasElement>
  width?: number
  height?: number
  pdfScale?: number
  renderMode?: RenderMode
  zoomProps?: TZoomProps
  onDocumentLoadError?: (error: Error) => void
  onDocumentLoadSuccess?: (doc: DocumentCallback) => void
  onDocumentLoadStart?: () => void
  onPageLoadStart?: () => void
  onPageLoadError?: (error: Error) => void
  onPageLoadSuccess?: (page: PageCallback) => void
  onPageRenderSuccess?: (page: PageCallback) => void
  layoutProps?: TLayoutViewer
  isExternalSrc?: boolean
}>

type PageDimension = {
  width: number
  height: number
  originalWidth: number
  originalHeight: number
  rotate: number
}

export default function PDFViewer({ file, isExternalSrc, zoomProps, pageRef, options, pdfRef, canvasRef, width, height, onPageLoadSuccess, onPageRenderSuccess, onPageLoadStart, onPageLoadError, onDocumentLoadError, onDocumentLoadSuccess, onDocumentLoadStart, pdfScale, renderMode, layoutProps }: TPDFViewer) {
  pageRef = pageRef ?? React.createRef<HTMLDivElement>()
  pdfRef = pdfRef ?? React.createRef<HTMLDivElement>()
  canvasRef = canvasRef ?? React.createRef<HTMLCanvasElement>()
  renderMode = renderMode ?? 'canvas'
  const viewerRef = React.createRef<HTMLDivElement>()
  const { t } = useTranslation()
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)
  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [scale, setScale] = useState<number>(pdfScale ?? 1)
  const [pdfFile, setPdfFile] = useState<TPDFFile>()
  const [pdfOptions, setPdfOptions] = useState<DocumentProps['options']>()
  const [pageDimension, setPageDimension] = useState<PageDimension>()
  const [pageWidth, setPageWidth] = useState<number | undefined>(width)
  const [pageHeight, setPageHeight] = useState<number | undefined>(height)
  const [renderedPageNumber, setRenderedPageNumber] = useState<number>(1)
  const [renderedScale, setRenderedScale] = useState<number>(1)
  const { getAccessToken } = useHolisAuth()

  const checkNewFile = async (file?: TPDFFile) => {
    if (!isExternalSrc && typeof file === 'string') {
      file = await addTokenToUrl(file, getAccessToken)
    }

    if (file !== pdfFile) {
      setPdfFile(file)
      setPdfOptions({
        ...defaultOptions,
        isOffscreenCanvasSupported: renderMode !== 'svg',
        ...options,
      })
    }
  }

  useEffect(() => {
    checkNewFile(file)
  }, [file])

  const handlePageRenderSuccess = (page: PageCallback) => {
    setRenderedPageNumber(page.pageNumber)
    setRenderedScale(scale)
    if (loading) {
      setLoading(false)
    }

    onPageRenderSuccess?.(page)
  }

  const handleDocumentLoadSuccess = async (doc: DocumentCallback) => {
    const { numPages } = doc
    setNumPages(numPages ?? 0)
    onDocumentLoadSuccess?.(doc)
  }

  const handleDocumentLoadStart = () => {
    setLoading(true)
    onDocumentLoadStart?.()
  }

  const handlePageLoadStart = () => {
    setLoading(true)
    onPageLoadStart?.()
  }

  /**
   * Fit pdf to screen on dbclick or panel size changed
   */
  const fitPdfToContainer = () => {
    if (pageDimension && viewerRef?.current) {
      let ratio = pageDimension.originalWidth / pageDimension.originalHeight
      if (pageDimension.rotate % 180 !== 0) {
        ratio = 1 / ratio
      }

      const containerHeight = viewerRef.current.clientHeight
      const containerWidth = viewerRef.current.clientWidth
      const newHeight = containerHeight * ratio > containerWidth ? Math.floor(containerWidth / ratio) : containerHeight
      const newWidth = newHeight * ratio
      if (newHeight !== pageHeight) {
        setPageHeight(newHeight)
      }

      if (newWidth !== pageWidth) {
        setPageWidth(newWidth)
      }
    }
  }

  const fitToScreen = () => {
    fitPdfToContainer()
  }

  const handleDocumentLoadError = (error: Error) => {
    setLoading(false)
    onDocumentLoadError?.(error)
  }

  const handlePageLoadError = (error: Error) => {
    setLoading(false)
    onPageLoadError?.(error)
  }

  const nextPage = () => {
    if (numPages && pageNumber && pageNumber < numPages) {
      setLoading(true)
      setPageNumber(pageNumber + 1)
    }
  }

  const prevPage = () => {
    if (pageNumber && numPages && numPages >= pageNumber && pageNumber > 1) {
      setLoading(true)
      setPageNumber(pageNumber - 1)
    }
  }

  const handlePageLoadSuccess = (page: PageCallback) => {
    setPageDimension({
      width: page.width,
      originalWidth: page.originalWidth,
      height: page.height,
      originalHeight: page.originalHeight,
      rotate: page.rotate,
    })
    onPageLoadSuccess?.(page)
  }

  const onScreenZoom = (ref: ReactZoomPanPinchRef, event: TouchEvent | MouseEvent) => {
    setScale(ref.state.scale)
    zoomProps?.onZoom?.(ref, event)
  }

  useEffect(() => {
    fitPdfToContainer()
  }, [pageDimension, pageNumber])

  useResizeObserver<HTMLDivElement>(viewerRef, () => {
    fitToScreen()
  })

  useEffect(() => {
    if (pdfScale && pdfScale !== scale) {
      setRenderedScale(pdfScale)
      setScale(pdfScale)
    }
  }, [pdfScale])

  const isRendering: boolean = renderedScale !== scale || renderedPageNumber !== pageNumber
  return (
    <LayoutViewer
      viewerRef={viewerRef}
      isLoading={!!pdfFile && loading}
      footer={(
        () => (
          numPages > 1 && (
            <div className="flex items-center">
              <Button disabled={pageNumber === 1} className={`px-0 mr-1${pageNumber <= 1 ? ' invisible' : ''}`} onClick={prevPage}><FaCaretLeft size={20} /></Button>
              <p>{t('label.pageNumTotal', { page: pageNumber, totalPages: numPages })}</p>
              <Button className={`px-0 ml-1${pageNumber >= numPages ? ' invisible' : ''}`} disabled={pageNumber === numPages} onClick={nextPage}><FaCaretRight size={20} /></Button>
            </div>
          )
        )
      )}
      zoomProps={{
        ...zoomProps,
        initialScale: pdfScale,
        onInit(ref) {
          transformComponentRef.current = ref
          zoomProps?.onInit?.(ref)
        },
        onZoom: onScreenZoom,
      }}
      onDoubleClick={fitToScreen}
      {...layoutProps}
    >
      {() => (
        <Document options={pdfOptions} externalLinkTarget="_blank" inputRef={pdfRef} error={t('message.error.pdfLoad')} noData={t('message.error.pdfDocNoData')} loading={<SpinnerLoader isLoading />} className="ICIIII relative h-full min-h-full w-full min-w-full justify-center flex flex-col" file={pdfFile} onLoadStart={handleDocumentLoadStart} onLoadError={handleDocumentLoadError} onLoadSuccess={handleDocumentLoadSuccess}>
          {!!numPages && isRendering
            && (
              <Page
                key={renderedPageNumber + '@' + renderedScale}
                noData={t('message.error.pdfPageNoData')}
                className="min-w-full min-h-full flex flex-col justify-center items-center"
                inputRef={pageRef}
                canvasRef={canvasRef}
                height={pageHeight}
                renderMode={renderMode}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                error={t('message.error.pagePdfLoad')}
                loading={<SpinnerLoader isLoading />}
                pageNumber={renderedPageNumber}
                devicePixelRatio={window.devicePixelRatio * renderedScale}
                width={pageWidth}
              />
            )}
          {!!numPages
            && (
              <Page
                key={pageNumber + '@' + scale}
                noData={t('message.error.pdfPageNoData')}
                width={pageWidth}
                height={pageHeight}
                className={`min-w-full min-h-full flex flex-col justify-center items-center ${isRendering ? 'hidden' : ''}`}
                renderMode={renderMode}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                inputRef={pageRef}
                canvasRef={canvasRef}
                devicePixelRatio={window.devicePixelRatio * scale}
                error={t('message.error.pagePdfLoad')}
                loading={<SpinnerLoader isLoading />}
                pageNumber={pageNumber}
                onLoadStart={handlePageLoadStart}
                onRenderSuccess={handlePageRenderSuccess}
                onLoadError={handlePageLoadError}
                onLoadSuccess={handlePageLoadSuccess}
              />
            )}
        </Document>
      )}
    </LayoutViewer>
  )
}
