import { useLazyQuery, useMutation } from '@apollo/client'
import GridSelectionModal from '@app/components/Common/Block/Grid/GridBlock/GridSelectionModal'
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader'
import SearchBar from '@app/components/Common/SearchBar'
import { CreateIdwgGridsMutation, DeleteIdwgGridsByIdwgIdAndGridIdsMutation, GetInspectionDrawingDetailQuery, Grid, IdwgGrid, InspectionDrawing } from '@app/graphql/__types__/graphql'
import { DWG_IDWG_GRID_CREATE_MANY, DWG_IDWG_GRID_DELETE_MANY_BY_IDWG_ID_AND_FLOC_IDS, INSPECTION_DRAWINGS_GET_DETAIL } from '@app/graphql/requests'
import AppNotifications from '@app/services/notification'
import { useLayoutStore } from '@app/stores/layout'
import { RadDropdownMenuItem } from '@holis/react-ui/rad'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuBox, LuPlusSquare } from 'react-icons/lu'
import GridItem from './GridItem'
import useIdwgStore, { IDWG_PREFIX } from '@app/stores/idwg'
import { searchArray } from '@app/utils/functions'
import { TDbId } from '@app/types/app'
import DeleteIdwgGridModal from '@app/components/Common/Block/InspectionDrawing/DrawingViewer/Grid/DeleteIdwgGridModal'
import useUserPermissions from '@app/utils/hooks/useUserPermissions'

type TGridList = Readonly<{
  idwg: Partial<InspectionDrawing>
}>

export default function GridList({ idwg }: TGridList) {
  const { t } = useTranslation()
  const prms = useUserPermissions()

  const { idwgGrids, setIdwgGrids, gridToDelete, grids, setGrids, gridsSelectionDisplayed, setGridsSelectionDisplay } = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`)
  const [searchInput, setSearchInput] = useState<string>('')
  const [filteredGrids, setFilteredGrids] = useState<Partial<Grid>[]>()
  const { startLoading, stopLoading } = useLayoutStore()
  const [getIdwgGridsById, { refetch, called }] = useLazyQuery<GetInspectionDrawingDetailQuery>(INSPECTION_DRAWINGS_GET_DETAIL, {
    variables: {
      id: idwg.id!,
    },
    fetchPolicy: 'no-cache',
  })

  const [addIdwgGridsApi] = useMutation<CreateIdwgGridsMutation>(DWG_IDWG_GRID_CREATE_MANY)
  const [deleteIdwgGridsApi] = useMutation<DeleteIdwgGridsByIdwgIdAndGridIdsMutation>(DWG_IDWG_GRID_DELETE_MANY_BY_IDWG_ID_AND_FLOC_IDS)

  const addItems = async (gridIds: TDbId[]) => addIdwgGridsApi({
    variables: {
      data: gridIds.map((gridId: TDbId) => ({
        idwgId: idwg.id,
        gridId,
      })),
    },
  })

  const deleteItems = async (gridIds: TDbId[]) => deleteIdwgGridsApi({
    variables: {
      gridIds,
      idwgId: idwg.id!,
    },
  })

  // Validate seletion modal (Add or delete grid )
  const onValidateSelection = async (selectedItems: Partial<Grid>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = []
    if (oldSelectedIds.length) {
      const idwgGridIds = (grids?.filter((item: Partial<Grid>) => oldSelectedIds.includes(item.id!)).map((item: Partial<Grid>) => item.id) ?? []) as TDbId[]
      if (idwgGridIds.length) {
        promises.push(deleteItems(idwgGridIds))
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds))
    }

    if (promises.length) {
      startLoading()
      try {
        for (const asyncCall of promises) {
          await asyncCall
        }

        setGridsSelectionDisplay(false)
        refreshIdwgGridsList?.()
        AppNotifications.success(t('message.success.idwgGridsUpdated'))
      } catch {
        AppNotifications.error(t('message.error.default.title'))
      }

      stopLoading()
    }
  }

  useEffect(() => {
    refreshIdwgGridsList()
  }, [])

  useEffect(() => {
    setGrids(idwgGrids?.map((item: Partial<IdwgGrid>) => item.grid!))
  }, [idwgGrids])

  useEffect(() => {
    setFilteredGrids(searchArray(grids ?? [], searchInput))
  }, [grids, searchInput])

  const refreshIdwgGridsList = () => {
    (called ? refetch : getIdwgGridsById)().then((queryResults) => {
      const newGrids = (queryResults.data?.inspectionDrawing?.idwgGrids ?? []) as IdwgGrid[]
      setIdwgGrids(newGrids)
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'))
    })
  }

  return (
    <>
      <FormGroupHeader
        menuItems={
          (!prms.drawings.create && !prms.drawings.update)
            ? undefined
            : [
                (
                  <RadDropdownMenuItem key="manage-cml" onClick={() => setGridsSelectionDisplay(true)}>
                    <LuPlusSquare className="mr-2" />

                    {t('label.manageGrid')}
                  </RadDropdownMenuItem>
                ),
              ]
        }
        actions={(
          <div className="flex items-center gap-2">
            <SearchBar
              className="w-[300px]"
              value={searchInput}
              onChange={e => setSearchInput(e.target?.value)}
            />
          </div>
        )}
      >
        <div className="flex items-center gap-1">
          <LuBox />

          {t('label.grid')}
        </div>
      </FormGroupHeader>

      <div className="flex flex-col gap-2">
        {filteredGrids?.map((grid: Partial<Grid>) => (
          <GridItem
            key={`grid-item-${grid.id!}`}
            hasActionButtons
            grid={grid}
            isDeleteButtonDisabled={!!gridToDelete}
          />
        ))}

        {gridsSelectionDisplayed && (
          <GridSelectionModal
            open
            selectedIds={grids?.map((item: Partial<Grid>) => item.id!)}
            closeOnConfirm={false}
            onValidate={onValidateSelection}
            onClose={() => setGridsSelectionDisplay(false)}
          />
        )}
      </div>

      <DeleteIdwgGridModal idwg={idwg} open={!!gridToDelete} onDeleted={() => refreshIdwgGridsList()} />
    </>
  )
}
