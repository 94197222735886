import { AuthUser } from '@holis/auth-client'
import Footer from './Components/Footer'
import Form from './Components/Form'
import useUsersStore from '@app/stores/users'
import { AddAssetSitesForUserMutation, ClearAssetSitesForUserMutation, ClearUserRoleMutation, UpdateUserInfoMutation, UpdateUserRoleMutation } from '@app/graphql/__types__/graphql'
import { useMutation } from '@apollo/client'
import { USER_ROLE_UPDATE, USER_CLEAR_ROLE, USER_UPDATE_INFO } from '@app/graphql/requests/usersRoles'
import AppNotifications from '@app/services/notification'
import { useTranslation } from 'react-i18next'
import SingleContentModal from '../../SingleFormModal'
import { ASSET_SITES_ADD_FOR_USER, ASSET_SITES_CLEAR_FOR_USER } from '@app/graphql/requests'

type TUserModal = Readonly<{
  item: AuthUser
  open?: boolean
  onClose?: () => void
  onSaved?: () => void
}>

export default function UserModal({ item, open, onClose, onSaved }: TUserModal) {
  const { editingUser, editingUserRoleId, setEditingUser, setUserRole: setUserRoleStore, editingUserSites, editingUserNotifyNewReport } = useUsersStore()
  const [clearUserRole] = useMutation<ClearUserRoleMutation>(USER_CLEAR_ROLE)
  const [setUserRoleGql] = useMutation<UpdateUserRoleMutation>(USER_ROLE_UPDATE)
  const [updateUserInfoGql] = useMutation<UpdateUserInfoMutation>(USER_UPDATE_INFO)
  const [clearUserSitesGql] = useMutation<ClearAssetSitesForUserMutation>(ASSET_SITES_CLEAR_FOR_USER)
  const [addUserSitesGql] = useMutation<AddAssetSitesForUserMutation>(ASSET_SITES_ADD_FOR_USER)
  const { t } = useTranslation()

  const handleSaveUser = async () => {
    if (editingUserRoleId) {
      // Add role
      await setUserRoleGql({
        variables: {
          userId: editingUser!.id!,
          roleId: editingUser!.userRole?.roleId ?? -1,
          newRoleId: editingUserRoleId,
          notifyNewReport: editingUserNotifyNewReport ?? false,
        },
      })

      // Update user sites
      await clearUserSitesGql({
        variables: {
          userId: editingUser!.id!,
        },
      })
      if (editingUserSites) {
        await addUserSitesGql({
          variables: {
            sites: editingUserSites.map(site => ({ siteId: site.id, userId: editingUser!.id! })),
          },
        })
      }

      // Set user infos
      await updateUserInfoGql({
        variables: {
          userId: editingUser!.id!,
          data: {
            userId: editingUser!.id!,
            username: editingUser!.username,
            email: editingUser!.email,
            firstName: editingUser!.firstName,
            lastName: editingUser!.lastName,
          },
        },
      })
    } else {
      // Clear user sites
      await clearUserSitesGql({
        variables: {
          userId: editingUser!.id!,
        },
      })
      // Clear roles user
      await clearUserRole({
        variables: {
          userId: editingUser!.id!,
        },
      })
    }

    // Update user role in store
    setUserRoleStore(editingUser!.id!, editingUserRoleId)

    // Close modal
    setEditingUser(undefined)

    AppNotifications.success(t('message.success.user.saveSuccess', { user: editingUser!.username }))

    onSaved?.()
  }

  return (
    <SingleContentModal
      title={t('label.user')}
      itemDesctiption={item.username?.toUpperCase()}
      footer={<Footer user={item} onSaveClicked={handleSaveUser} />}
      containerClassName="max-w-[600px]"
      isOpen={open}
      onOpenChange={(opened) => {
        if (!opened) {
          onClose?.()
        }
      }}
    >
      <div className="h-full w-full overflow-y-auto">
        <Form user={item} />
      </div>
    </SingleContentModal>
  )
}
